import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { StyledCrossIcon, StyledImageCont, StyledMainModalCont, StyledMargin, StyledText } from '../../../assets/styles/CommonStyles';
import strings from '../../../constant/strings';
import NormalUser from './NormalUser';
import Doctor from "./Doctor";
import { Heading, Modal, Title } from './styles';
import { FootText, ProceedBtn, User } from './utility';

const SignUpModal = ({
    selectUser,
    isMapping,
    isSignUpModal,
    setSignUpModal,
    toggleSignUp,
    setSelectUser,
    setMapping,
    handleSubmit = {}
}) => {

    React.useEffect(() => {
        if (isSignUpModal) return document.body.style.overflowY = 'hidden';
        else return document.body.style.overflowY = 'auto';
    }, [isSignUpModal])

    const handleProceedBtn = () => selectUser !== '' && setMapping(true);

    useEffect(() => {
        if (!isSignUpModal) {
            setSignUpModal(false)
            setSelectUser('')
            setMapping(false)
        }
    }, [isSignUpModal])
    return (

        isMapping && selectUser === 'Normal user' ?
            <NormalUser
                isSignUpModal={isSignUpModal}
                setSignUpModal={setSignUpModal}
                toggleSignUp={toggleSignUp}
                setMapping={setMapping}
                handleSubmit={handleSubmit} /> :

            isMapping && selectUser === "Clinician / Hospital" ?
                    <Doctor
                        isSignUpModal={isSignUpModal}
                        setSignUpModal={setSignUpModal}
                        toggleSignUp={toggleSignUp}
                        setMapping={setMapping}
                        handleSubmit={handleSubmit} />
                :
                <StyledMainModalCont>
                    <StyledImageCont>
                        <StyledCrossIcon
                            src={"/assets/img/x.png"}
                            alt="User"
                            onClick={() => setSignUpModal(false)}
                        />
                    </StyledImageCont>
                    <Title>{strings.createAccount}</Title>
                    <Heading>{strings.createOwnAccount}</Heading>
                    <User selectUser={selectUser} setSelectUser={setSelectUser} />
                    {selectUser !== '' && <ProceedBtn text={`${strings.proceed}`} onClick={handleProceedBtn} />}
                    {/* <FootText text={`${strings.alreadyAccount}  ${strings.signUpLogin}`} selectUser={selectUser} toggleSignUp={toggleSignUp} /> */}
                    <StyledMargin>
          <StyledText>{strings.alreadyAccount}{' '}
          <span className="sign-color" onClick={toggleSignUp}>
              {strings.login}
            </span></StyledText>
        </StyledMargin>
                </StyledMainModalCont>


    )
}

export default SignUpModal
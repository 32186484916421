import React from 'react';

import PhoneInput from "react-phone-number-input";
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
  getDoctorInfoAPI,
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import {
	StyledContainer,
	StyledButton
} from './styles';
import CardComponent from '../../../../components/Card';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';

import { globalValidator, getUser } from "../../../../utils";
import InsideMenuBar from '../../../../components/InsideMenus';

import {
	getInventoryByDoctor,
	getInventoryById,
	addInventoryDoctor,
	updateInventory
} from '../../../../api/organization';

import './index.css';

function Treatments(props) {
	const [state, dispatch] = React.useContext(Context);
    const [categoryForm, setCategoryForm] = React.useState("");
    const [treatmentForm, setTreatMentForm] = React.useState({
        name: "",
        price: "",
        cgst : "",
        sgst : '',
        igst: '',
        starred : false
    });
    const [categoryModal, setCategoryModal] = React.useState(false);
    const [treatmentModal, setTreatmentModal] = React.useState(false)
    const [selectedCategory, setSelectedCategory] = React.useState('');
    const [selectedTreatment, setSelectedTreatment] = React.useState('');
		const [categorys, setCategorys] = React.useState([]);
		const [categoryId, setCategoryId] = React.useState(null);

	React.useEffect(() => {

		// 
		getInventoryData();
	}, []);


	const getInventoryData = () => {
		loader(true);
		const org = JSON.parse(localStorage.getItem('doctor')).orgData;
		getInventoryByDoctor(org._id).then((res) => {
			loader(false);
			setCategorys(res.data);
		}).catch((err)=>{ })
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

  
    const handleTreatmentSubmit = () => {
			const keys = [
					{ name: 'name', title: "Treatment Name", validateKey: "required" },
					{ name: 'price', title: "Treatment Price", validateKey: "required" },
			];
			let valid = globalValidator(treatmentForm, keys);

			if (valid === true) {
				loader(true)

				updateInventory(categoryId, treatmentForm).then((res) => {
					loader(false);
					setSelectedTreatment('');
					setTreatmentModal(false);
					setTreatMentForm('');
					getInventoryData();

				}).catch((err)=>{
				})

				
			}else {
					toast.error(valid);
					dispatch({
							type: SET_ERROR,
							payload: valid
					});
			}

    }
    const handleCategorySubmit = () => {
        const keys = [
            { name: 'categoryForm', title: "Category Name", validateKey: "required" },
        ];
        let data = {
            categoryForm: categoryForm
        }
        let valid = globalValidator(data, keys);
        if (valid === true) {
					loader(true);
					const org = JSON.parse(localStorage.getItem('doctor')).orgData;

					addInventoryDoctor({name: categoryForm, orgId: org._id}).then((res) => {
						loader(false);
						getInventoryData();
						setCategoryModal(false);
						setSelectedCategory('');
						setCategoryForm('');

					}).catch((err)=>{ })
					
        } else {
            toast.error(valid);
            dispatch({
                type: SET_ERROR,
                payload: valid
            });
        }
    }

    const handlCategoryDelete = (item) => {

    }
	
    const handleTreatmentDelete = (item) => {

    }
    const validateFields = (e) => {
        const reg = /^(0|[1-9]\d*)$/
        if (reg.test(e.target.value) || e.target.value === '') {
            setTreatMentForm((value) => ({ ...value, [e.target.name]: e.target.value }));
        } else {
            toast.error('Please Input Only Numbers')
            dispatch({
                type: SET_ERROR,
                payload: 'Please Input Only Numbers'
            });
        }
    }
	
	return (
        <div>
            <InsideMenuBar
                props={props}
                type={'doctor'}
            />
            <StyledContainer>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-12">
                                <CardComponent className="p-4 mb-4 h-100">
                                    <div className="doctor-panel-header treatment-header">
                                        <div style={{display:"flex"}}>
                                            <h3 className="mb-1 treatment-main-heading">Treatments</h3>
                                            <button className="doctor-active-btn ml-auto" 
                                                onClick={()=>{
                                                    setSelectedCategory('');
                                                    setCategoryModal(true);
                                                }}
                                            >
                                                ADD CATEGORY
                                            </button>
                                        </div>
                                        <hr />
                                    </div>
                                    <div>
                                        {categorys.length ?
                                            categorys.map((item, id) => (
                                                <div key={item.id}>
                                                    <div style={{display:"flex"}} className='mb-2 align-items-baseline'>
                                                        <h4 className='text-purple treatment-heading'>{item.name}</h4>
                                                        <i class="fas fa-pen ml-3 btn" onClick={() => {
                                                            setSelectedCategory(item);
                                                            setCategoryModal(true);
                                                            setCategoryForm(item.name);
                                                        }}
                                                        >
                                                        </i>
                                                        <i class="fas fa-times btn" onClick={()=>handlCategoryDelete(item)}></i>
                                                        <h4 className='ml-auto text-purple btn treatment-heading' style={{ fontWeight: "500" }}
                                                            onClick={() => {
																															setSelectedTreatment('');
																															setTreatmentModal(true);
																															setTreatMentForm('');
																															setCategoryId(item._id)
                                                            }}
                                                        >
                                                            ADD TREATMENT
                                                        </h4>
                                                    </div>
                                                    <div className='table-responsive'>
                                                        <table class="table table-striped table-borderless">
                                                            <tbody>
                                                                {
                                                                    (item.categories && item.categories.length) ? item.categories.map((subItem, id) => (
                                                                        <tr>
                                                                            <td className='col-md-8'>{subItem.name}</td>
                                                                            <td className='col-md-4'>
                                                                                <div style={{display:"flex"}} className='col-md-5 ml-auto justify-content-between'>
                                                                                    {subItem.starred ?
                                                                                        <i class="fas btn fa-star"></i>
                                                                                        : <i class="far btn fa-star"></i>
                                                                                    }
                                                                                    <i class="fas fa-pen btn" onClick={() => {
                                                                                        setSelectedTreatment(subItem);
                                                                                        setTreatmentModal(true);
                                                                                        setTreatMentForm(subItem);
                                                                                    }}></i>
                                                                                    <i class="btn fas fa-times" onClick={()=>handleTreatmentDelete(subItem)}></i>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )) : null
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <hr />
                                                </div>
                                    )) : null}
                                    </div>
                                </CardComponent>
                            </div>

                        </div>
                    </div>
                </div>
            </StyledContainer>

            {/* category modal */}

            <div className="modal custom-modal fade none-border show" style={{
                display: categoryModal ? 'block' : 'none', backdropFilter: 'blur(1px)',
                background: 'rgb(104 71 99 / 24%)'
            }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{selectedCategory ? "Edit" : "Add"} Treatment Category </h4>
                        </div>
                        <div className="modal-body d-flex row ">
                            <div className="col-md-12 no-padding form-group ">
                                <label>Category Name</label>
                                <InputField name="categoryForm" value={categoryForm} onChange={(e) => setCategoryForm(e.target.value)} />
                            </div>
                        </div>
                        <div className="modal-footer justify-content-flexend">
                            <button type="button" className="btn btn-primary delete-event submit-btn" data-dismiss="modal" onClick={() => {
                                setCategoryModal(false);
                                setSelectedCategory('');
                                setCategoryForm('');
                            }}>Cancel</button>
                            <button type="button" className="btn btn-primary delete-event submit-btn" data-dismiss="modal" onClick={handleCategorySubmit}>{selectedCategory ? "Save" : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/*  treatment Modal */}

            <div className="modal custom-modal fade none-border show" style={{
                display: treatmentModal ? 'block' : 'none', backdropFilter: 'blur(1px)',
                background: 'rgb(104 71 99 / 24%)'
            }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{selectedTreatment ? "Edit" : "Add"} Treatment </h4>
                        </div>
                        <div className="modal-body d-flex row ">
                            <div className="col-md-4 no-padding form-group ">
                                <label>Name</label>
                                <InputField name="name" value={treatmentForm.name} onChange={(e) => setTreatMentForm((value) => ({...value, name : e.target.value}))} />
                            </div>
                            <div className="col-md-4 no-padding form-group ">
                                <label> Price (Rs.)</label>
                                <InputField name="price" value={treatmentForm.price} onChange={validateFields} />
                            </div>
                            <div className="col-md-4 cust-do-em-treatment cust-do-em row no-padding form-group">
									<input type="checkbox" id="html" value={treatmentForm.starred} onChange={validateFields} />
									<label for="html">Starred</label>
                            </div>
                            <div className="col-md-4 no-padding form-group ">
                                <label>CGST (%)</label>
                                <InputField name="cgst" value={treatmentForm.cgst} onChange={validateFields} />
                            </div>
                            <div className="col-md-4 no-padding form-group ">
                                <label>SGST (%)</label>
                                <InputField name="sgst" value={treatmentForm.sgst} onChange={validateFields} />
                            </div>
                            <div className="col-md-4 no-padding form-group ">
                                <label>IGST (%)</label>
                                <InputField name="igst" value={treatmentForm.igst} onChange={validateFields} />
                            </div>
                        </div>
                        <div className="modal-footer justify-content-flexend">
                            <button type="button" className="btn btn-primary delete-event submit-btn" data-dismiss="modal" onClick={() => {
                                setSelectedTreatment('');
                                setTreatmentModal(false);
                                setTreatMentForm('');
                            }}>Cancel</button>
                            <button type="button" className="btn btn-primary delete-event submit-btn" data-dismiss="modal" onClick={handleTreatmentSubmit}>{selectedTreatment ? "Save" : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Treatments


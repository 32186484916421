import React from 'react'
import { ReactComponent as Cross } from '../../../assets/img/cross.svg';
import { ReactComponent as Doctor } from '../../../assets/img/doctor.svg';
import { ReactComponent as Patient } from '../../../assets/img/patient.svg';
import { ReactComponent as UnChecked } from '../../../assets/img/unChecked.svg';
import { ReactComponent as Checked } from '../../../assets/img/checked.svg';
import { BottomColoredText, Button, ButtonWrapper, CheckBoxStyle, CrossButton, DisplayFlex, ImageWrapper, SingleUser, SubHeading, UserImage, UserWrapper } from './styles';

const borderStyle = { borderRadius: '8px' };
const userArray = [
	{
		index: 1,
		subheading: 'Clinician / Hospital',
		Image: Doctor,
		selected: false,
	},
	{
		index: 2,
		subheading: 'Normal user',
		Image: Patient,
		selected: false,
	}
]

export const Crossed = ({ isClose }) => <CrossButton onClick={() => isClose(false)}><Cross /></CrossButton>

const singleUser = (index, Image, subheading, selected, setSelectUser) => (
	<div key={index}>
		<ImageWrapper border={selected}>
			<UserImage onClick={() => setSelectUser(subheading)}>
				<CheckBoxStyle>
					{selected ? <Checked /> : <UnChecked />}
				</CheckBoxStyle>
				<Image style={borderStyle} />
			</UserImage>
		</ImageWrapper>
		<SubHeading>{subheading}</SubHeading>
	</div>
)

export const User = ({ selectUser = [], setSelectUser }) => {

	const [renderArray, setRenderArray] = React.useState(userArray);

	React.useEffect(() => {
		const newArray = renderArray.map((item) => {
			if (selectUser.includes(item.subheading)) return { ...item, selected: !item.selected };
			else return { ...item, selected: false };;
		})
		setRenderArray(newArray);
	}, [selectUser]);

	return (
		<UserWrapper>
			{renderArray.map(({ index, Image, subheading, selected }) => singleUser(index, Image, subheading, selected, setSelectUser))}
		</UserWrapper>
	)
}


export const FootText = ({ text, toggleSignUp }) => {
	const words = text.split('!!');
	return <DisplayFlex><BottomColoredText>{words[0]}</BottomColoredText><BottomColoredText check onClick={() => toggleSignUp()}>{words[1]}</BottomColoredText></DisplayFlex>
}

export const ProceedBtn = ({text, onClick}) => {
	return 	<ButtonWrapper><Button onClick={onClick}>{text}</Button></ButtonWrapper>
}

export const RenderForm = ({text}) => {
	return 	<ButtonWrapper><Button>{text}</Button></ButtonWrapper>
}

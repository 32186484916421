export const SET_COMMON_LOADING = "SET_COMMON_LOADING";
export const SET_ERROR = "SET_ERROR";

// Organization types
export const LOGIN_ORGANIZATION = "LOGIN_ORGANIZATION";
export const GET_DOCTORS = "GET_DOCTORS";
export const SET_INFO_ORGANIZATION = "SET_INFO_ORGANIZATION";

// Patient types
export const SET_DATES_APPOINTMENTS = "SET_DATES_APPOINTMENTS";
export const SET_WARD_PATIENT_DETAILS = "SET_WARD_PATIENT_DETAILS";
export const SET_WARD_PATIENT_INVOICE = "SET_WARD_PATIENT_INVOICE";
export const SET_CALLING_DATA = "SET_CALLING_DATA";
export const SET_USER_TYPE = "SET_USER_TYPE";
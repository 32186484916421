import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    loading: false,
    error: null,
    PatientList: [],
    DoctorList: [],
    SubAdminList: [],
  };

  const listSlice = createSlice({
    name: 'organizationList',
    initialState,
    reducers: {
      //patient list
      getPatientListRequest(state) {
        state.PatientList = [];
        state.loading = true;
        state.error = null;
      },
      getPatientListSuccess(state, { payload }) {
        state.PatientList = payload;
        state.loading = false;
        state.error = null;
      },
      getPatientListFailure(state, { payload }) {
        state.PatientList = [];
        state.loading = false;
        state.error = payload;
      },   
      
      //doctor list 
      getDoctorListRequest(state) {
        state.DoctorList = [];
        state.loading = true;
        state.error = null;
      },
      getDoctorListSuccess(state, { payload }) {
        state.DoctorList = payload;
        state.loading = false;
        state.error = null;
      },
      getDoctorListFailure(state, { payload }) {
        state.DoctorList = [];
        state.loading = false;
        state.error = payload;
      },

      // subadmin list 
      getSubadminListRequest(state) {
        state.SubAdminList = [];
        state.loading = true;
        state.error = null;
      },
      getSubadminListSuccess(state, { payload }) {
        state.SubAdminList = payload;
        state.loading = false;
        state.error = null;
      },
      getSubadminListFailure(state, { payload }) {
        state.SubAdminList = [];
        state.loading = false;
        state.error = payload;
      },
    },
  });
  
  export const {getPatientListRequest, getPatientListSuccess, getPatientListFailure,
    getDoctorListRequest, getDoctorListSuccess, getDoctorListFailure,
    getSubadminListRequest, getSubadminListSuccess, getSubadminListFailure} = listSlice.actions;
  
  export default listSlice.reducer;
import React, { useEffect, useRef, useState } from "react";
import { Grid, Modal } from "@mui/material";
import { useStyles } from "../../../assets/styles/PatientDashboardStyles";
import strings from "../../../constant/strings";
import HeadingComponent from "../HeadingComponent/index.js";
import {
  HealthParameterList,
  LipidProfileList,
} from "../../../constant/staticLists";
import { useDispatch, useSelector } from "react-redux";
import { Measurements } from "../Measurement";
import { usePatientHealthStyles } from "./style";
import TabComponent from "../../Tab";
import { LipidProfile } from "../LipidProfile";
import { modalStyle, StyledSecondaryButton, } from "../../../assets/styles/CommonStyles";
import { Box } from "@mui/system";
import colors from "../../../constant/colors";
import BloodSugar from "../../Modal/BloodSugar";
import BloodPressure from "../../Modal/BloodPressure";
import BloodOxygen from "../../Modal/BloodOxygen";
import BodyTemperature from "../../Modal/BodyTemperature";
import RespiratoryRate from "../../Modal/RespiratoryRate";
import HeartRate from "../../Modal/HeartRate";
import HealthParameters from "../HealthParameters";
import { useHealthParameterStyles } from "../HealthParameters/style";
import { bloodOxygen, bloodPressure, bloodSugar, bodyTemp, healthParams, heartRate, lipidProfile, patientProfile, respiratoryRate } from "../../../redux/user/userActions";
import LipidProfiles from "../../Modal/LipidProfile";
import moment from "moment";
import { updateProfile } from "../../../services/authService";
import { toast } from "react-toastify";
import ECGIcon from "../../../assets/img/ecg.png";

const actionSagaMapping = {
  "bloodPressureData": bloodPressure,
  "bloodSugarData": bloodSugar,
  "spo2sData": bloodOxygen,
  "bodyTemperatureData": bodyTemp,
  "respiratorieData": respiratoryRate,
  "heartRateData": heartRate
}

const tabs = [
  {
    id: 0,
    name: strings.healthParameter,
  },
  {
    id: 1,
    name: strings.lipidProfile,
  },
];

const PatientHealth = () => {
  const commonClasses = useStyles();
  const dispatch = useDispatch();
  const patientHealthClassses = usePatientHealthStyles();
  const useHealthParameterClasses = useHealthParameterStyles();
  const lipidProfileLists = useSelector(state => state.user);
  const [openModal, setOpenModal] = useState(false);
  const [getItemValue, setItemValue] = useState("");
  const classes = {
    ...commonClasses,
    ...patientHealthClassses, ...useHealthParameterClasses
  };
  const updatedLipidProfileList = LipidProfileList.map(item => {
    return {
      ...item,
      value: lipidProfileLists[item.value]
    }
  });
  const [tabValue, setTabValue] = React.useState(0);
  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const { bloodSugar, userData, patientProfileData } = useSelector((state) => ({
    bloodSugar: state.user.bloodSugar,
    userData: state.auth.userData || {},
    patientProfileData: state.user.patientProfile || {},
  }));

  const [duration, setDuration] = React.useState(HealthParameterList.reduce((prev, cur) => {
    prev[cur.primaryKey] = 'w'
    return prev
  }, {}))
  const [date, setDate] = React.useState(HealthParameterList.reduce((prev, cur) => {
    prev[cur.primaryKey] = moment(new Date()).format('YYYY-MM-DD')
    return prev
  }, {}))

  const prevDur = useRef(duration);
  const prevDate = useRef(date);

  useEffect(() => {
    // find the changed dependency and then call its api to fetch data from server as per the details
    let changed
    const preValues = Object.entries(prevDur.current)
    const values = Object.entries(duration)
    preValues.forEach((element, index) => {
      if (element[1] !== values[index][1]) changed = values[index]
    });
    prevDur.current = duration
    if (changed) {
      dispatch(actionSagaMapping[changed[0]].call(null, { token: userData.token, duration: changed[1], date: date[changed[0]] }))
    }
  }, [duration])

  useEffect(() => {
    // find the changed dependency and then call its api to fetch data from server as per the details
    let changed
    const preValues = Object.entries(prevDate.current)
    const values = Object.entries(date)
    preValues.forEach((element, index) => {
      if (element[1] !== values[index][1]) changed = values[index]
    });
    prevDate.current = date
    if (changed) {
      dispatch(actionSagaMapping[changed[0]].call(null, { token: userData.token, duration: duration[changed[0]], date: changed[1] }))
    }
  }, [date])

  const handleClose = () => {
    setOpenModal(false);
  }

  const handleClick = (item) => {
    setOpenModal(true);
    setItemValue(!!item.primaryHeading ? item.primaryHeading : "Lipid Profile")
  }

  const getUpdatedValue = (key, value) => {
    if (value) {
      dispatch(actionSagaMapping[key].call(null, { token: userData.token, duration: duration[key] }))
    }
  }

  useEffect(() => {
    // dispatch(bloodSugar({ token: userData.token, duration: 'w' }));
    setTimeout(() => dispatch(heartRate({ token: userData.token, duration: 'w' })), 0);
    dispatch(healthParams({ token: userData.token, duration: 'w' }));
    dispatch(lipidProfile({ token: userData.token, duration: 'w' }));
  }, [userData])

  const getLipidProfile = (value) => {
    if (value) {
      dispatch(lipidProfile({ token: userData.token, duration: 'w' }));
    }
  }

  const { height, weight, bmi } = React.useMemo(() => {
    const _bmi = patientProfileData.weight && patientProfileData.height ? (patientProfileData.weight / (Math.pow(patientProfileData.height / 100, 2)))?.toFixed(2) || '-' : '-'
    return { height: patientProfileData.height, weight: patientProfileData.weight, bmi: _bmi }
  }, [patientProfileData])

  const handleSubmitForm = async (data) => {
    const response = await updateProfile(data, userData.token)
    if (response.response_type === 'success') {
        toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
        dispatch(patientProfile({ token: userData.token }))
    } else {
        toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
    }
}

  return (
    <div>
      <Grid container>
        <Grid item xs={10} className={classes.mainCont}>
          <HeadingComponent
            text={{ primary: strings.health, secondary: strings.homeHeadingText }}
          />
        </Grid>
        <Grid item xs={2} className={classes.mainCont}>
          {/* <StyledSecondaryButton sx={{ textTransform: "capitalize" }}>
            {strings.weeklyAnalysis}
          </StyledSecondaryButton> */}
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={3} >
              <div className={classes.measurementStyle}>
                <Measurements title={strings.weight} keys={"weight"} handleSubmitForm={handleSubmitForm} measurement={weight || '-'} />
              </div>
              <div className={classes.measurementStyle}>
                <Measurements title={strings.height} keys={"height"} handleSubmitForm={handleSubmitForm} measurement={height || '-'} />
              </div>
              <Measurements title={strings.bmi} measurement={bmi || '-'} hide />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8} className={classes.ecgCont}>
            <img src={ECGIcon} style={{width: '100%', height: '100%'}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.marginTop}>
          <Grid item xs={12}>
            <TabComponent {...{ tabs, tabValue, setTabValue, handleTab }} />
          </Grid>
        </Grid>
        {tabValue === 1 ? <Grid container justifyContent={"flex-end"}>
          <Grid item xs={4} className={classes.buttonContainer}>
            <StyledSecondaryButton sx={{ textTransform: "capitalize" }} onClick={handleClick}>
              {strings.addFullLipidProfile}
            </StyledSecondaryButton>
          </Grid>
        </Grid> : null}
        {tabValue === 0 ? (
          <div className={classes.mainHealthCont}>
            <Grid container justify="center" className={classes.secondaryMainHealth}>
              <HealthParameters className={classes} list={HealthParameterList} duration={duration} setDuration={setDuration} handleClick={handleClick} date={date} setDate={setDate} />
            </Grid>
          </div>
        ) : tabValue === 1 ? (
          <Grid container sx={{ display: 'flex' }}>
            <LipidProfile className={classes} list={updatedLipidProfileList} data={bloodSugar} />
          </Grid>
        ) : null}
      </Grid>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={modalStyle}>
          {getItemValue === "Blood Sugar" ? <BloodSugar setOpenModal={setOpenModal} getUpdatedValue={getUpdatedValue.bind(this, 'bloodSugarData')} />
            : getItemValue === "Blood Pressure" ? <BloodPressure setOpenModal={setOpenModal} getUpdatedValue={getUpdatedValue.bind(this, 'bloodPressureData')} /> :
              getItemValue === "Blood Oxygen" ? <BloodOxygen setOpenModal={setOpenModal} getUpdatedValue={getUpdatedValue.bind(this, 'spo2sData')} /> :
                getItemValue === "Body Temperature" ? <BodyTemperature setOpenModal={setOpenModal} getUpdatedValue={getUpdatedValue.bind(this, 'bodyTemperatureData')} /> :
                  getItemValue === "Respiratory Rate" ? <RespiratoryRate setOpenModal={setOpenModal} getUpdatedValue={getUpdatedValue.bind(this, 'respiratorieData')} /> :
                    getItemValue === "Lipid Profile" ? <LipidProfiles setOpenModal={setOpenModal} getUpdatedValue={getLipidProfile} /> :
                    <HeartRate setOpenModal={setOpenModal} getUpdatedValue={getUpdatedValue.bind(this, 'heartRateData')} />
          }
        </Box>
      </Modal>
    </div>
  );
};

export default PatientHealth;

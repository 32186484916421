import React from "react";
import { Redirect, Route } from "react-router-dom";

import HeaderOrgConponent from "../components/HeaderOrg";

 const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => (
                <>
                    {rest.private ? (
                        <>
                            {rest.name.header && <HeaderOrgConponent {...props} {...rest} />}
                            <Component {...props} />
                        </>
                    ) : (
                        <Redirect to="/" />
                    )}
                </>
            )}
        />
    );
};

export default PrivateRoute;
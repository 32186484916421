import React, { useState } from "react";
import TabComponent from "../../Tab";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { IPDReportTabs } from "../../../constant/staticLists";
import Medication from "./Medication";
import PatientDashboard from "../Patient/PatientDashboard";
import PersonalMedicalDetails from "../PersonalMedicalDetails";
import MedicalReports from "../MedicalReports";
import EPriscriptions from "../EPriscriptions";

function Report({setOpenModal, handleOpen, setSelectedListItem}) {
    const styleClasses = commonMuiStyles();
    const [tabValue, setTabValue] = useState(0)
    const handleTab = (e,newValue) => {
        setTabValue(newValue)
    }
  return (
    <>
      <TabComponent
        tabs={IPDReportTabs}
        tabValue={tabValue}
        setTabValue={setTabValue}
        handleTab={handleTab}
        customTab={styleClasses.customTab}
        customTabIndicator={styleClasses.customTabIndicator}
      />
      <div style={{padding: tabValue !== 0 && '20px'}}>
      {tabValue === 0 ? <Medication setOpenModal = {setOpenModal} handleOpen= {handleOpen} setSelectedListItem={setSelectedListItem} /> : 
      tabValue === 1 ? <PatientDashboard /> : 
      tabValue === 2 ? <PersonalMedicalDetails navigate="doctor" /> : 
       <>
      <MedicalReports navigate="doctor" />
      <EPriscriptions navigate="doctor" />
    </> }
      </div>
      
      
    </>
  );
}

export default Report;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  doctorProfileData: {},
  loading: false,
  error: null,

};

const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    getProfileByDoctorRequest(state) {
      state.doctorProfileData = {};
      state.loading = true;
      state.error = null;
    },
    getProfileByDoctorSuccess(state, { payload }) {
      state.doctorProfileData = payload;
      state.loading = false;
      state.error = null;
    },
    getProfileByDoctorFailure(state, { payload }) {
      state.doctorProfileData = {};
      state.loading = false;
      state.error = payload;
    },

    updateProfileByDoctorRequest(state) {
      state.doctorProfileData = {};
      state.loading = true;
      state.error = null;
    },
    updateProfileByDoctorSuccess(state, { payload }) {
      state.doctorProfileData = payload;
      state.loading = false;
      state.error = null;
    },
    updateProfileByDoctorFailure(state, { payload }) {
      state.doctorProfileData = {};
      state.loading = false;
      state.error = payload;
    },

  },
});

export const { getProfileByDoctorRequest, getProfileByDoctorSuccess, getProfileByDoctorFailure, updateProfileByDoctorRequest, updateProfileByDoctorSuccess, updateProfileByDoctorFailure
} = doctorSlice.actions;

export default doctorSlice.reducer;

import styled from 'styled-components';

export const TextArea = styled.textarea`
border: 1px solid #CCCCCC;
    color: #333;
    font-size: 15px;
    min-height: 46px;
    padding: 6px 15px;
    border-radius: 8px !important;
    background: #F8F7F7 !important;
    resize: none;
    width: 100%;
`;

export const RadioItem =  styled.div`
// margin-left:20px;
@media only screen and (max-width: 700px) {
     
}
`;

export const CircleContainer =  styled.div`
    display: flex;
    justify-content: center;
    margin: 4px 0 0;
`;

export const RadioItemText =  styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5E5E5E;
    @media only screen and (max-width: 700px) {
    font-weight: 400;
font-size: 12px;
line-height: 16px;
    }
`;

export const RadioItemCirlce =  styled.div`
    display: flex;
    justify-content: center;
    width: 24px;
    cursor :pointer;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #5E5E5E;
    @media only screen and (max-width: 700px) {
        width: 16px;
        height: 16px;
    }
`;

export const RadioItemCirlceFilled =  styled.div`
    display: flex;
    justify-content: center;
    width: 24px;
    cursor :pointer;
    height: 24px;
    border-radius: 50%;
    // background: red;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    @media only screen and (max-width: 700px) {
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
`;

export const WhiteCirlce =  styled.div`
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-self: center;
    @media only screen and (max-width: 700px) {
        width: 7px;
        height: 7px;
        
    }
`;

import React, { useEffect, useMemo, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip, TextField } from "@mui/material";
import styled from "styled-components";

export default function SelectionDropdown({
  name,
  placeholder,
  list,
  label,
  value,
  navigate,
  className,
  disabled,
  handleDropdownValue
}) {
  const [values, setValues] = useState([]);

  const handleChange = (e, data) => {
    let uniqueArray = [];
    if (data) {
      if(navigate === "multiple"){
      let uniqueLabels = {};
      uniqueArray = data.filter(item => {
        if (!uniqueLabels.hasOwnProperty(item.label)) {
          uniqueLabels[item.label] = true;
          return true;
        }
        return false;
      });
    }
      setValues(navigate === "multiple" ? uniqueArray : data);
    }
    if (data === null) {
      setValues([]);
    }
    handleDropdownValue(navigate === "multiple" ? uniqueArray : data || []);
  };

  const selectedOptions = useMemo(() => {
    if (navigate === "multiple" && Array.isArray(value)) {
      return value.map((val) => list.find((option) => option.level === val)).filter(Boolean);
    } else {
      return value !== undefined ? list.find((option) => option.level === value) : [];
    }
  }, [list, navigate, value]);

  useEffect(() => {
    setValues(selectedOptions);
  }, [selectedOptions]);

  const renderInput = (params) => {
    const { InputProps, ...rest } = params;

    return (
      <TextField
        {...rest}
        name={name}
        label={label}
        placeholder={placeholder}
        InputProps={{
          ...InputProps,
          classes: {
            root: "custom-autocomplete-inputRoot",
          },
        }}
      />
    );
  };

  const getOptionLabel = (option) => {
    if (Array.isArray(option)) {
      return option.map((item) => item.label).join(", ");
    }
    return option.label || "";
  };

  const options = list?.map((option) => {
    const firstLetter = option.label[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };

  });
  return (
    <>
      <Autocomplete
        disablePortal
        id="tags-outlined"
        multiple={navigate === "multiple" ? true : false}
        label={label}
        freeSolo
        getOptionSelected={(option, value) => option.level === value.level}
        disabled={!!disabled ? true : false}
        value={values}
        disableClearable
        onChange={handleChange}
        options={!!options && options.length > 0 ? navigate === "multiple" ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : list : []}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        classes={{
          root: className,
          inputRoot: "custom-autocomplete-inputRoot",
        }}
      />
    </>
  );
}

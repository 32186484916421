import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getDoctorListFailure, getDoctorListRequest, getDoctorListSuccess, getPatientListFailure, getPatientListRequest, getPatientListSuccess, getSubadminListFailure, getSubadminListRequest, getSubadminListSuccess } from './listSlice';
import { getDoctorListAction, getPatientListAction, getSubadminListAction } from './listAction';
import { getDoctorListForOrganizationApi, getPatientListApi, getSubadminListForOrganizationApi } from '../../services/mainService';

  function* getPatientList(action) {
    try {
       yield put(getPatientListRequest()); 
      const apiResponse = yield call(getPatientListApi, action.payload);
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put(getPatientListSuccess(listData));
  
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getPatientListFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

function* getDoctorList(action) {

    try {
       yield put(getDoctorListRequest()); 
      
      const apiResponse = yield call(getDoctorListForOrganizationApi, action.payload);
      // console.log("iam here",apiResponse)
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put(getDoctorListSuccess(listData));
  
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getDoctorListFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  function* getSubadminList(action) {
  
    try {
       yield put(getSubadminListRequest()); 
      
      const apiResponse = yield call(getSubadminListForOrganizationApi, action.payload);
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put(getSubadminListSuccess(listData));
  
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getSubadminListFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  export function* listSaga() {
    yield takeLatest(getPatientListAction.type, getPatientList);
    yield takeLatest(getDoctorListAction.type, getDoctorList);
    yield takeLatest(getSubadminListAction.type, getSubadminList);   
  }
import styled from 'styled-components';

export const StyledFooterWrapper = styled.div`
	.certification2	 {
		border: 1px solid #0166FF;
	}
	.mobile-screen-icon {
		@media (max-width: 450px){ 
			width: 100%;
			display: flex;
			margin-bottom: 20px !important;
			justify-content: center;
		}
	}
	.hide-item {
		@media (max-width: 450px){ 
			display: none;
		}
	}
	.hide-item2 {
		@media (min-width: 450px){ 
			display: none;
		}
	}
	.text-content-center {
		@media (max-width: 450px){ 
			text-align: left !important;
		}
	}
	.footer-content-padding {
		padding: 15px 55px;
		@media (max-width: 450px){ 
			justify-content: left;
			padding: 0 15px;
		}
	}
	.footer-mobile-screen {
		padding: 0 15px;
	}
	.mobile-screen-padding {
		display: flex;
		justify-content: center;
		padding: 0 70px 0 0;
		@media (max-width: 450px){ 
			justify-content: left;
			padding: 0 70px 0 15px;
		}
	}

	.footer-screen {
		@media (max-width: 450px){ 
			justify-content: space-between;
			// align-items: end;
			// padding: 0 15px;
			padding: 0;
			margin: 0 15px;
		}
	}
	.margin-top-screen {
		@media (max-width: 450px){ 
			margin-top: 43px
		}
	}
	.apps-shows {
		margin-top: 15px;
		padding-left: 15px;
		a {
			// background: black;
			border-radius: 10px;
			margin-right: 10px;
		}
	}

	.footer-container-alignment {
		padding: 0 35px 0 70px;
		@media (max-width: 450px){ 
			padding: 0 15px !important;
		}
	}

	.footer-about-content {
		@media (max-width: 450px){ 
			width: 90% !important;
			margin-left: 0;
		}
		p {
			font-size: 16px;
		}
	}
	.footer-logo {
		img {
			@media (max-width: 450px){ 
				width: 80px !important;
			}
		}
		@media (max-width: 450px){ 
			margin-bottom: 10px !important;
		}
	}
	.cert-img {
		width: 150px;
		margin-top: 10px;

		img {
			width: 100%;
		}
	}

	// .btn-store2.apple {
    // background-image: url(/assets/img/apple.png);
	// }
	.btn-store {
    height: 33px;
	width: 97px;
    // width: 135px;
    // height: 40px;
    // float: left;
	}
	.btn-store2 {
		height: 33px;
		width: 100px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		padding-left: 15px;
		// width: 135px;
		// height: 40px;
		// float: left;
		}
	// .btn-store1.google {
    // background-image: url(/assets/img/android.png);
	// }
`;
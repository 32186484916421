export const prettyString = (str) => {
    var nextCapital = true;
    var newString = "";
    for (var i = 0; i < str.length; i++) {
        var cur = str.charAt(i);
        if (nextCapital) {
            newString += cur.toUpperCase();
        } else {
            newString += cur;
        }
        if (cur === " ") {
            nextCapital = true;
        } else {
            nextCapital = false;
        }
    }
    return newString;
};

export const textString = (str) => {
    var nextCapital = true;
    var newString = "";
    for (var i = 0; i < str.length; i++) {
        var cur = str.charAt(i);
        if (nextCapital) {
            newString += cur.toUpperCase();
        } else {
            newString += cur;
        }
        if (cur === " ") {
            nextCapital = false;
        } else {
            nextCapital = false;
        }
    }
    return newString;
};

export const oneSpace = (val1, ind, arr) => {
    if (ind === 0) return true;
    if (val1 !== ' ') return true;
    const val2 = arr[ind-1];
    if (val2 !== ' ') return true;
    return false;
  }
import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #fff;
    padding: 24px 16px 24px;
    // &:-webkit-scrollbar { 
    //     display: none;  /* Safari and Chrome */
    // }
`;

export const SectionHeading = styled.div``;

export const Heading3 = styled.h3`
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
`;

export const Heading1 = styled.h1`
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    margin: 8px 0 0;
`;

export const H3FAQ = styled(Heading3)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #2B2B2B;
`;

export const H1Ttitle = styled(Heading1)`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #2B2B2B;
    .colored & {
        background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
    }
`;

export const H3SubHeading = styled(Heading1)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #5E5E5E;
`;

export const WrapperInput = styled(Heading1)`
    margin: 24px 0 0;
`;

export const SectionFAQ = styled.div`
    margin: 41px 0 0;
`;

export const QuestionariesHeading = styled.div`
    display: flex;
`;

export const QuestionaryTitle = styled.div`
    width: -webkit-fill-available;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #2B2B2B;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    &.colored {
        background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }
`;

export const TextWrapper = styled.div`
    width: -webkit-fill-available;
    border-bottom: ${(props) => props.border ? '2px solid transparent' : '1px solid #CCCCCC'};
	background-image: linear-gradient(white, white), linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
`;

export const Question = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    color: #2B2B2B;
    width: -webkit-fill-available;
    font-size: 12px;
    line-height: 16px;
    margin: ${(props) => props.condition ? '16px 0 8px' : '16px 0 16px'};
`;

export const Answer = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #5E5E5E;
    width: calc(100% - 24px);
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 16px;
`;

export const SingleQuesWrapper = styled.div`
    border-bottom: 1px solid #CCCCCC;
`;




import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import Appointments from "../../components/ui/Doctor/Appointments";

const DoctorAppointment = () => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        <Appointments />
      </AppLayout>
    </Grid>
  )
}

export default DoctorAppointment;
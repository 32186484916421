import React, {useEffect, useContext, useState} from 'react';
import InputField from '../../../components/InputField';
import PhoneInput from "react-phone-number-input";
import { toast } from 'react-toastify';
import { Context } from '../../../store';
import {
	postLoginAPI
} from '../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import { globalValidator, saveUser, isLoggedIn } from "../../../utils";
import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledRightHeading,
	StyledRightSocialLogin,
	StyledSocialFB,
	StyledSocialGoogle,
	StyledRightDivider,
	StyledDividerLine,
	StyledDividerHeading,
	StyledLoginButton,
	StyledRightBottom,
	StyledBottomContent,
	StyledBottomLink,
	StyledForgotPassword,
	StyledForgotPasswordLink
} from './styles';

function Login(props) {

	const [state, dispatch] = useContext(Context);
	const [orgUser, SetOrgUser] = useState({email: '', password: ''});
	const [mobile, setMobile] = useState(false);

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('customer'));
		if(isLoggedIn() && user.org) props.history.push('/patient/dashboard');
		if(isLoggedIn() && !user.org) props.history.push('/patient/medical-record');
	}, [])

	const login = (e) => {
		e.preventDefault();
		const { email, password } = orgUser;
		const keys = [
			{ name: "email", title: "Email or phone number", validateKey: mobile ? '' : "email" },
			{ name: "password", title: "Password", validateKey: "password" }
		];
		let valid = globalValidator({ email, password }, keys);

		if(valid === true) {
			loader(true);
			postLoginAPI(orgUser).then(res => {
				saveUser(res?.data);
				loader(false);
				toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				if(res?.data?.termscondition == false) {
					props.history.push('/first-time-login');
				} else {
					if(res?.data?.org == true) {
						props.history.push('/patient/dashboard');
					} else {
						props.history.push('/patient/medical-record');
					}
				}
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const register = () => {
		props.history.push('/register');
	}

	const forgotPassword = () => {
		props.history.push('/forgot-password');
	}

	const onChange = (e) => {
		SetOrgUser((user) => ({...user, email: e.target.value}))
		
	}


	const onKeyDown = (e) => {
		if(isNaN(orgUser.email) ) {
			setMobile(false);
		} else 	if(!isNaN(orgUser.email) && orgUser.email.length > 5) {
			setMobile(true);
		} else {
			setMobile(false);
		}
	}	

	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
              <StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Login for patient</StyledLeftHeading>
										<StyledLeftSubHeading>
											Get connected with your doctor.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="https://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>

							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">
											{/* <StyledRightHeading>Login with Social Profile</StyledRightHeading> */}
											{/* <div className="d-flex  flex-column align-items-center mb-3">
												<StyledRightSocialLogin>
													<StyledSocialFB>
														<i className="fab fa-facebook-f mr-1" />
													</StyledSocialFB>
													<StyledSocialGoogle>
														<i className="fab fa-google mr-1" />
													</StyledSocialGoogle>
												</StyledRightSocialLogin>
											</div> */}
											
											{/* <StyledRightDivider className="d-flex align-items-center my-3">
												<StyledDividerLine />
												<StyledDividerHeading className="mx-2">
													Or use your email account
												</StyledDividerHeading>
												<StyledDividerLine />
											</StyledRightDivider> */}

											<form className="form-border info-widget">

												{
													mobile ? 
													<div className="form-group card-label phone-eme">
														<label>Phone number</label>
														<PhoneInput
															placeholder="Enter phone number"
															international
															defaultCountry="IN"
															value={orgUser.email}
															onChange={e => SetOrgUser((user) => ({...user, email: e}))}
														/>
													</div>
													:
													<div className="form-group card-label">
														<label>Email / Phone number</label>
														<InputField 
															value={orgUser.email} 
															onChange={(e) => onChange(e)}  
															onKeyDown={onKeyDown}
														/>
													</div>
												}


												<div className="form-group card-label">
													<label>Password</label>
													<InputField value={orgUser.password} password={true}  type="password" onChange={e => SetOrgUser((user) => ({...user, password: e.target.value}))} />
												</div>

												<StyledForgotPassword  onClick={() => forgotPassword()}>
													<StyledForgotPasswordLink>
														Forgot Password ?
													</StyledForgotPasswordLink>
												</StyledForgotPassword>

												<StyledLoginButton onClick={(e) => login(e)}>
													Sign In Now
												</StyledLoginButton>
											</form>

											<StyledRightBottom>
												<StyledBottomContent>
													I haven't an account with Emed4u? - 
													<StyledBottomLink onClick={() => register()}>
														Sign Up Now
													</StyledBottomLink> 
												</StyledBottomContent>
											</StyledRightBottom>											
										</div>
									</div>
								</div>
							</StyledRightPanel>
            </div>
          </div>
        </div>
		  </div>
	  </section>  
	)
}

export default Login

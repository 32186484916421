import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pathName: window.location.pathname
};

const routerSlice = createSlice({
    name: 'router',
    initialState,
    reducers: {
        push(state, action) {
            localStorage.routeToBeUpdate = 1
            state.pathName = action.payload;
        },
    },
});

export const { push } = routerSlice.actions;

export default routerSlice.reducer;

import React from 'react';

import {
	StyledContainer
} from './styles';

function SecondOpinion() {
	return (
		<StyledContainer>
			<h1>Coming Soon...</h1>
		</StyledContainer>
	)
}

export default SecondOpinion

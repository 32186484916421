import React from 'react';
import { Link } from 'react-router-dom';
import {
	StyledContainer,
	StyledHeading,
	StyledAddPatient
} from './styles';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getSubAdminAPI
} from '../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import CardComponent from '../../../../components/Card'
import TableComponent from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import Search from '../../../../components/Search';
import InsideMenuBar from '../../../../components/InsideMenus';

const tableHeading = [
	{id: 1, title: 'Name'},
	{id: 2, title: 'Designation'},
	{id: 3, title: 'Email'},
	{id: 4, title: 'Mobile'},
	{id: 5, title: 'Address'},
	{id: 8, title: 'Status'},
	{id: 6, title: 'lastLogin'},
	{id: 7, title: 'Actions'}
];

const options = [
	{ id: 0, label: 'Id', value: '_id' },
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 2, label: 'Email', value: 'email' },
	// { id: 3, label: 'Status', value: 'status' },
	{ id: 4, label: 'Contact Number', value: 'contact' }
]

function PatientListing(props) {
	const [state, dispatch] = React.useContext(Context);
	const [patients, setPatients] = React.useState([]);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('_id');

	const fetchSubAdmin = () => {
		loader(true);
		getSubAdminAPI().then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setPatients(res.data);
		
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			setPatients([])
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	React.useEffect(() => {
		fetchSubAdmin();
	}, [])

	const searchAdministrator = () => {
		loader(true);
		const payload = {
            [selectValue]: search
        }
		getSubAdminAPI(payload).then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setPatients(res.data);
		
		}).catch(err => {
			loader(false);
			setPatients([])
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	

	const [patientPaginate, setPatientPaginate] = React.useState([]);
	const pageChangePatient  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setPatientPaginate(data);
	}

	React.useEffect(() => {
		fetchSubAdmin();
	}, [selectValue])

	React.useEffect(() => {
        if(search === '') {
			fetchSubAdmin();
        }
    }, [search])

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-2">
								<StyledHeading></StyledHeading>
								<div className="doctor-panel-filters row col-md-12 p-4">
									<Search
										selectValue={selectValue} 
										setSelectValue={setSelectValue} 
										search={search} 
										setSearch={setSearch} 
										searchPatient={searchAdministrator} 
										name={"sub-administrator"}
										options={options}
									/>
									<div className="col-md-2 mt-5 mb-0 no-padding form-group doctor-active-btn-div">
										<Link to={'/organization/add-sub-admin'} style={{width: '100%'}}>
											<StyledAddPatient>
												Add a sub-administrator
											</StyledAddPatient>
										</Link>
									</div>
									
								</div>
								{/* <StyledPatient>
								<Link to={'/organization/add-sub-admin'} style={{width: '100%'}}>
											<StyledAddPatient>
												Add a sub-administrator
											</StyledAddPatient>
										</Link>
								</StyledPatient> */}
								<TableComponent tableHeading={tableHeading}>
									{
										patients?.length ? patientPaginate.map(item => {
											return (
												<tr key={item._id}>
													<td>
														{/*<h2 className="table-avatar">
															<a className="avatar avatar-sm mr-2">
																	<img className="avatar-img rounded-circle" src={item.profileImage ? item.profileImage : "/assets/img/doctors/doctor-thumb-01.jpg"} alt="User Image" />
															</a>
															<a>{item.name}</a>
														</h2>*/}
														{item.name || '-'}
													</td>
													<td>{item.designation || '-'}</td>
													<td>{item.email || '-'}</td>
													<td>{item.phoneNumber || '-'}</td>
													<td>{item.address || '-'}</td>
													<td><span className={item.status === 0 ? 'badge badge-pill bg-success-light' : 'badge badge-pill bg-danger-light'}>{item.status === 0 ? 'Active' : 'Deactive'}</span></td>
													<td>{item.lastLogin || '-'} </td>
													<td>
														<div className="table-action">
															<Link to={`/organization/update-sub-admin/${item._id}`} className="btn btn-sm bg-info-light-blue">
																<i className="far fa-eye"></i> View
															</Link>
														</div>
													</td>
												</tr>
											)
										}): null
									}
								</TableComponent>
								{patients?.length == 0 ? (<h5 style={{width: '100%', textAlign: 'center', margin: '2% 0 1%'}}>Sorry! There are no Invoices to display!</h5>) : null}
								{patients?.length > 0 && <Pagination items={patients} onChangePage={pageChangePatient} />}
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default PatientListing

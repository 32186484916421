import React from "react";
import { useStyles } from "../../assets/styles/RadioButtonStyles";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { StyledProceedButton, StyledSecondaryButton } from "../../assets/styles/CommonStyles";
import strings from "../../constant/strings";

function RadioButtonBox1(props) {
  const { handleProceed = {}, activeRadio, setActiveRadio, list } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    setActiveRadio(event.target.value);
  };

  return (
    <>
      <div className={classes.root}>
        <RadioGroup value={activeRadio} onChange={handleChange}>
          {list.map((option, index) => (
            <FormControlLabel
              key={option.value}
              className={`${classes.radiobuttoncontainer} ${activeRadio === option.value ? classes.selectedRadio : ''
                }`}
              value={option.value}
              control={<Radio color="default" classes={{ root: classes.root, checked: classes.checked }} />}
              classes={{ label: classes.label }}
              checked={activeRadio === option.value}
              label={<div className={classes.radiobutton}>
                <span className={classes.radiolabelfont}>{option.label}</span>
                <img loading="lazy" className={classes.image} src={option.img} alt="logo" />
              </div>}
            />
          ))}
        </RadioGroup>
        {activeRadio !== "" && (
          <div className={classes.buttonContainer}>
            <StyledProceedButton variant="contained" onClick={handleProceed}>
              {strings.proceed}
            </StyledProceedButton>
          </div>
        )}
      </div>
    </>
  );
}

export default RadioButtonBox1;

import React, { useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getAllInvoices
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import CardComponent from '../../../../components/Card';
import TableComponent from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import Search from '../../../../components/Search';
import InsideMenuBar from '../../../../components/InsideMenus';

const options = [
	{ id: 0, label: 'Id', value: '_id' },
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 2, label: 'Email', value: 'email' },
	{ id: 4, label: 'Contact number', value: 'phoneNumber' },
	{ id: 5, label: 'Invoice number', value: 'invoice_number'}
]

function DoctorInvoices(props) {
	const [state, dispatch] = useContext(Context);

	const tableHeading = [
		{id: 1, title: 'Invoice number'},
		{id: 2, title: 'Patient Id'},
		{id: 3, title: 'Patient'},
		{id: 4, title: 'Date'},
		{id: 5, title: 'Amount'},
		{id: 5, title: 'Status'},
        // {id: 5, title: 'Actions'}
	];

	const [invoices, setinvoices] = React.useState([]);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('_id');
	const [invoicePaginate, setInvoicePaginate] = React.useState([]);
	const pageChangeAppointment  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setInvoicePaginate(data);
	}

	const renderAppointmentAmount = (item) => {
		const amount = item?.amount.split('');
        const index = amount?.findIndex((char) => /^[0-9]$/.test(char))
		if(item?.amount && item?.pendingAmount){
			if(!index){
			    return `${parseInt(item?.amount) + item?.pendingAmount}` 
			}else {
			    return `${item?.amount.slice(0,index)}${parseInt(item?.amount.slice(index)) + item?.pendingAmount}` 
			}
		} else {
			return item?.amount
		}
        
    }

	useEffect(() => {
		getInvoices();
	}, [])

	useEffect(() => {
        if(search === '') {
            getInvoices();
        }
    }, [search])

	useEffect(() => {
		getInvoices();
	}, [selectValue])

	const getInvoices = () => {
		loader(true);
		getAllInvoices().then(res => {
			setinvoices(res.data);
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
		}).catch(err => {
			setinvoices([])
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const searchPatient = () => {
		loader(true);

		const payload = {
			[selectValue]: search
		}
		
		getAllInvoices(search.length > 0 ? payload : '').then(res => {
			setinvoices(res.data);
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
		}).catch(err => {
			loader(false);
			setinvoices([])
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	
	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'doctor'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">

								<div className="doctor-panel-header">
									<h3 className="mb-3">Invoices</h3>
									<hr/>
									<div className="doctor-panel-filters row col-md-12">
										<Search
											selectValue={selectValue} 
											setSelectValue={setSelectValue} 
											search={search} 
											setSearch={setSearch} 
											searchPatient={searchPatient} 
											name={"Patient's"}
											options={options}
										/>
									</div>
								</div>

								<TableComponent tableHeading={tableHeading}>
									{
										invoices.length ? invoicePaginate.map(item => {
											return <tr>
												<td>#{item.invoiceNumber}</td>
												<td>#{item.patient ? item.patient._id : ''}</td>
												<td>
													<h2 className="table-avatar">
														<a className="avatar avatar-sm mr-2">
															<img className="avatar-img rounded-circle" src={item.patient?.profileImage ? item.patient?.profileImage : "/assets/img/doctor-empty.png"} alt="User" />
														</a>
														<a>{item.patient? item.patient.name : ''} </a>
													</h2>
												</td>
												
												<td>{moment(item.createdAt).format('Do MMMM, YYYY')}</td>
												<td>{renderAppointmentAmount(item)} </td>
												<td><span className="badge badge-pill text-white bg-success-blue inv-badge">{item.status == 0 ? 'Paid' : 'Refund'}</span></td>
											</tr>
										}) : (
											<tr>
												<td colSpan="12">
													<h5 style={{ textAlign: "center", margin: '20px 0' }}>
														Sorry! There are no results to display
													</h5>
												</td>
											</tr>
										)
									} 
								</TableComponent>
								

								{invoices.length > 0 && <Pagination items={invoices} onChangePage={pageChangeAppointment} />}

							</CardComponent>
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

export default DoctorInvoices

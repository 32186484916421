import React, {useEffect, useContext, useState} from 'react';

import InputField from '../../../components/InputField';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	firstTimeUser
} from '../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import { globalValidator, saveUser, isLoggedIn } from "../../../utils";

import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledLoginButton
} from './styles';

function FirstTimeUserLogin(props) {

	const [state, dispatch] = useContext(Context);

	const [password, setPassword] = useState('');
	const [termscondition, setTermscondition] = useState(false);


	const login = (e) => {
		e.preventDefault();
	
    const keys = [
      { name: "password", title: "Password", validateKey: "password" },
    ];
		let valid = globalValidator({ password }, keys);

		if(termscondition && valid === true) {
			loader(true);
			firstTimeUser({password: password, termscondition: true}).then(res => {
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				// if(!orgUser.org) {
					props.history.push('/patient/medical-record');
				// } else {
				// 	props.history.push('/patient/dashboard');
				// }
				
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			if(!termscondition) {
				toast.error("Please agree to terms & condition for login!")
				dispatch({
					type: SET_ERROR,
					payload: "Please agree to terms & condition for login!"
				});
			} else {
				toast.error(valid)
				dispatch({
					type: SET_ERROR,
					payload: valid
				});
			}
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
              <StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Change your password</StyledLeftHeading>
										<StyledLeftSubHeading>
											Get connected with your doctor.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="http://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>

							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">

											<form className="form-border info-widget">

												<div className="form-group card-label">
													<label>New Password</label>
													<InputField value={password} password={true}  type="password" onChange={e => setPassword(e.target.value)} />
												</div>

												<div className="">
													<label class="container-checkbox">
														By clicking on this you agree to our 	<a target="_blank" href="https://emed4u.com/terms-and-conditions">terms of use</a>. You can find out how we collect, use and share your data in our <a target="_blank" href="https://emed4u.com/privacy-policy">privacy policy</a>. Our cookie policy explains how we use cookies and similar technologies. 
														<input type="checkbox" value={termscondition} checked={termscondition} onChange={e => setTermscondition(e.target.value)} />
														<span class="checkmark"></span>
													</label>
												</div>

												<StyledLoginButton onClick={(e) => login(e)}>
													Submit
												</StyledLoginButton>
											</form>

										</div>
									
									</div>

								</div>
							
								
							</StyledRightPanel>

            </div>
          </div>
        </div>
		  </div>
	  </section> 
	)
}

export default FirstTimeUserLogin

import { Grid } from "@mui/material";
import React from "react";
import strings from "../../../../constant/strings";
import HeadingComponent from "../../HeadingComponent";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";
import { useState } from "react";
import OpdAppointmentList from "./OpdAppointmentList";
import IpdAppointmentList from "./IpdAppointmentList";

function AppointmentList({
  step,
  handleForward,
  setSelectedListItem,
  selectedListItem,
}) {
  const classes = useStyles();
  const [appointmentListType, setAppointmentListType] = useState(0);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.mainCont}>
          <HeadingComponent
            navigate="organisation"
            text={{
              primary:
                appointmentListType === 0
                  ? strings.home
                  : appointmentListType === 1
                  ? strings.appointments
                  : strings.appointmentsIPD,
              secondary:
                appointmentListType === 0
                  ? strings.opdIpdList
                  : strings.opdList,
            }}
          />
        </Grid>
      </Grid>

      {appointmentListType === 0 ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OpdAppointmentList
                step={step}
                handleForward={handleForward}
                appointmentListType={appointmentListType}
                setAppointmentListType={setAppointmentListType}
                setSelectedListItem={setSelectedListItem}
                selectedListItem={selectedListItem}
              />
            </Grid>
            <Grid item xs={12} mt={4}>
              <IpdAppointmentList
                step={step}
                appointmentListType={appointmentListType}
                setAppointmentListType={setAppointmentListType}
              />
            </Grid>
          </Grid>
        </>
      ) : appointmentListType === 1 ? (
        <OpdAppointmentList
          step={step}
          appointmentListType={appointmentListType}
          handleForward={handleForward}
          setAppointmentListType={setAppointmentListType}
          setSelectedListItem={setSelectedListItem}
          selectedListItem={selectedListItem}
        />
      ) : appointmentListType === 2 ? (
        <IpdAppointmentList
          step={step}
          appointmentListType={appointmentListType}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AppointmentList;

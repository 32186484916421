import LandingPage from "../pages/LandingPage";
import PatientInfo from '../pages/PatientPanel/PatientInfo';
import LandingPageLead from "../pages/WebsiteLinks/LandingPage";
import SecondOpinion from '../pages/Telehealth/SecondOpinion';
import Telemedicine from '../pages/Telehealth/Telemedicine';
import Telemonitoring from '../pages/Telehealth/Telemonitoring';
import BlogList from '../pages/HealthNetwork/BlogList';
import BlogDetail from '../pages/HealthNetwork/BlogDetail';
import Youtube from '../pages/HealthNetwork/Youtube';
import Yoga from '../pages/HolisticMedicine/Yoga';
import NLP from '../pages/HolisticMedicine/NLP';
import Login from '../pages/Auth/Login';
import FirstTimeUserLogin from '../pages/Auth/Login/FirstTimeUserLogin';
import RegisterPatient from '../pages/Auth/Register';
//import ForgotPassword from '../pages/Auth/ForgotPassword';
import Contactus from '../pages/WebsiteLinks/Contactus';
import Aboutus from '../pages/WebsiteLinks/Aboutus';
import FAQs from '../pages/WebsiteLinks/FAQs';
import PrivacyPolicy from '../pages/WebsiteLinks/PrivacyPolicy';
import TermsConditions from '../pages/WebsiteLinks/Terms&Conditions';
import HowItWorks from '../pages/HowItWorks';
import AppointmentLand from '../pages/VirtualPractice/AppointmentLand';
import BillingReportLand from '../pages/VirtualPractice/BillingReportLand';
import MobileAppsLand from '../pages/VirtualPractice/MobileAppsLand';
import PatientQuestionsLand from '../pages/VirtualPractice/PatientQuestionsLand';
import RemoteLand from '../pages/VirtualPractice/RemoteLand';
import VideoConsultationLand from '../pages/VirtualPractice/VideoConsultationLand';
import PatientDashboard from '../pages/PatientPanel/Dashboard';
// import PatientAppointments from '../pages/PatientPanel/Appointments';
import PatientInvoices from '../pages/PatientPanel/Invoices';
import PatientSettings from '../pages/PatientPanel/Settings';
import PatientChat from '../pages/PatientPanel/Chat';
import PatientReport from '../pages/PatientPanel/Patient/PatientReport';
import ListingDoctors from '../pages/PatientPanel/ListingDoctors';
import CreateQr from '../pages/PatientPanel/QR/CreateQr';
import ShareQr from '../pages/PatientPanel/QR/ShareQr';
import BookingSlots from '../pages/PatientPanel/ListingDoctors/BookingSlots';
import PatientCheckout from '../pages/PatientPanel/ListingDoctors/Checkout';
import BookSuccess from '../pages/PatientPanel/ListingDoctors/BookSuccess';
import ViewInvoice from '../pages/PatientPanel/Invoices/ViewInvoice';
import HealthParameters from '../pages/PatientPanel/HealthParameters';
import PatientFormBooking from '../pages/PatientPanel/ListingDoctors/PatientFormBooking';
import ViewAppointment from '../pages/PatientPanel/Appointments/ViewAppointment';
import ForgotPasswordOrgination from '../pages/Organization/Login/ForgotPassword';
import OrganizationRegistration from '../pages/Organization/Registration';
import SuccessMsgAfterRegister from '../pages/Organization/SuccessMsgAfterRegister';
import OrganizationLogin from '../pages/Organization/Login';
import OrganizationDashboard from '../pages/Organization/Panel/Dashboard';
import DoctorListing from '../pages/Organization/Panel/DoctorListing';
import DoctorAddEdit from '../pages/Organization/Panel/DoctorListing/DoctorAddEdit';
import PatientListing from '../pages/Organization/Panel/PatientListing';
import PatientAddEdit from '../pages/Organization/Panel/PatientListing/PatientAddEdit';
import Appointments from '../pages/Organization/Panel/Appointments';
import Invoices from '../pages/Organization/Panel/Invoices';
import Settings from '../pages/Organization/Panel/Settings';
import OrgSuccessBooking from '../pages/Organization/Panel/SuccessBooking';
import InvoiceViewOrg from '../pages/Organization/Panel/Invoices/InvoiceView';
import ViewPatientDetails from '../pages/Organization/Panel/ViewPatientDetails';
import Coupons from '../pages/Organization/Panel/Coupons';
import ViewCoupon from '../pages/Organization/Panel/Coupons/ViewCoupon';
import SubAdmin from '../pages/Organization/Panel/SubAdmin';
import ViewSubAdmin from '../pages/Organization/Panel/SubAdmin/ViewSubAdmin';
import OrgTerms from '../pages/Organization/Panel/Terms';
import HospitalRooms from '../pages/Organization/Panel/Rooms';
import AddRoom from '../pages/Organization/Panel/Rooms/AddRoom';
import DoctorLogin from '../pages/DoctorPanel/Auth/Login';
import ForgotPasswordDoctor from '../pages/DoctorPanel/Auth/ForgotPassword';
import RegisterDoctor from '../pages/DoctorPanel/Auth/Register';
//import DoctorSettings from '../pages/DoctorPanel/Panel/Settings';
import DoctorReviews from '../pages/DoctorPanel/Panel/Reviews';
import DoctorPatients from '../pages/DoctorPanel/Panel/Patients';
import DoctorInvoices from '../pages/DoctorPanel/Panel/Invoices';
import DoctorTreatment from '../pages/DoctorPanel/Panel/Treatments';
import DoctorCompletedAppointments from '../pages/DoctorPanel/Panel/CompletedAppointments';
import DoctorCalendar from '../pages/DoctorPanel/Panel/Calender';
import DoctorChat from '../pages/DoctorPanel/Panel/Chat';
import DoctorViewAppointment from '../pages/DoctorPanel/Panel/Appointments/ViewAppointment';
import DoctorViewIPD from '../pages/DoctorPanel/Panel/IPD';
import DoctorViewPatient from '../pages/DoctorPanel/Panel/Patients/ViewPatient';
import AddPatientDoctor from '../pages/DoctorPanel/Panel/Patients/AddPatient';
import BookAppointmentForPatient from '../pages/DoctorPanel/Panel/Patients/BookAppointmentForPatient';
import PaymentMethod from '../pages/DoctorPanel/Panel/PaymentMethod';
import SubUserLogin from '../pages/SubAdmin/Auth/Login';
import SubAdminForgotPassword from '../pages/SubAdmin/Auth/ForgotPassword';
import SubAdminDashbaord from '../pages/SubAdmin/Panel/Dashboard';
import SubAdminIpdWard from '../pages/SubAdmin/Panel/IpdWard';
import SubAdminIpdWardAddPatientDetails from '../pages/SubAdmin/Panel/IpdWard/Patient/IpdWardPatientFlow';
import SubAdminIpdWardAddPatient from '../pages/SubAdmin/Panel/IpdWard/Patient';
import SubAdminApointments from '../pages/SubAdmin/Panel/Appointment';
import SubAdminPatients from '../pages/SubAdmin/Panel/Patient';
import SubAdminSettings from '../pages/SubAdmin/Panel/Settings';
import SubAdminViewAppt from '../pages/SubAdmin/Panel/Appointment/ViewAppointment';
import SubAdminListingDoctors from '../pages/SubAdmin/Panel/BookAppointment';
import SubAdminBookingSlots from '../pages/SubAdmin/Panel/BookAppointment/BookingSlots';
import SubAdminPatientFormBooking from '../pages/SubAdmin/Panel/BookAppointment/PatientFormBooking';
import SubAdminDoctor from '../pages/SubAdmin/Panel/ViewDoctor';
import Test from '../pages/DoctorPanel/Panel/Test';
import SubAdminViewPatient from '../pages/SubAdmin/Panel/IpdWard/ViewPatient';
import ResetPassword from '../pages/ResetPassword';
import UploadDocuments from '../pages/UploadDocuments';
import SubAdminPatient from '../pages/SubAdmin/Panel/ViewPatient';
import EmergencyContactLogin from '../pages/EmergencyContactPanel/Login';
import HealthParametersEmergency from '../pages/EmergencyContactPanel/HealthData';
import Patient from '../pages/PatientPanel/Patient/index';
import PatientHealth from '../pages/PatientPanel/PatientHealth';
import About from '../pages/LandingPage/About';
import RoomListing from '../pages/SubAdmin/Panel/IpdWard/roomListing';
import EventPage from '../pages/Telehealth/EventPage';
import ConductStudy from '../pages/ConductStudy';
import HMS from '../pages/HMS';
import Support from '../pages/Support';
import Question from '../pages/ConductStudy/Question';
import HMSPackages from '../pages/HMSPackages';
import Thankyou from '../pages/thankyou';
import Failed from '../pages/fail';
import PatientMedicalHistory from '../pages/PatientPanel/MedicalHistory';
import ProfileSettings from "../pages/PatientPanel/ProfileSettings";
import PatientAppointments from "../components/ui/Patient/PatientAppointments";
import DoctorAppointment from "../pages/Doctor/DoctorAppointment";
import QRcode from "../pages/QRcodePage/QRcode";
import PatientInformation from "../components/QRcodeComponent/PatientInformation";
import PatientMessages from "../components/ui/Patient/PatientMessages";
import DoctorMessages from "../pages/Doctor/DoctorMessages";
import BillingInvoice from "../pages/PatientPanel/BillingInvoice";
import ForgotPassword from "../components/ForgotPassword";
import DoctorSettings from '../pages/Doctor/DoctorSettings'
import DoctorSchedule from "../pages/Doctor/DoctorSchedule";
import DoctorSlots from "../pages/Doctor/DoctorSlots";
import ViewFile from "../components/controls/ViewFile";
import OrganisationDashboard from "../pages/EMD_Organisation/OrganisationDashboard";
import OrganisationPatientList from "../pages/EMD_Organisation/OrganisationPatientList";
import OrganisationDoctorList from "../pages/EMD_Organisation/OrganisationDoctorList";
import OrganisationSubAdminList from "../pages/EMD_Organisation/OrganisationSubAdminList";
import OrganisationProfileSetting from "../pages/EMD_Organisation/OrganisationProfileSetting";
import SubAdminDashboard from "../pages/EMED_Subadmin/Home";
import SubadminOPDAppointment from "../pages/EMED_Subadmin/Appointment";
import IPD from "../pages/EMED_Subadmin/IPD";
import SubadminBillingInvoice from "../components/ui/Subadmin/BillingInvoice";
import SubadminProfileSetting from "../components/ui/Subadmin/SubadminProfileSetting";
import Rooms from "../pages/EMED_Subadmin/Rooms";
import { components } from "react-select/dist/react-select.cjs.prod";
import OrganizationSubscription from "../pages/EMD_Organisation/OrganizationSubscription";
import RefundPolicy from "../pages/WebsiteLinks/RefundPolicy";

export let indexRoutes = [
    {
        path: '/',
        component: LandingPage,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/thankyou',
        component: Thankyou,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/fail',
        component: Failed,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/patient/medical-info/:id',
        component: PatientInfo,
        private: false,
        header: false,
        footer: false
    },
    {
        path: '/landing-page',
        component: LandingPageLead,
        private: false,
        header: false,
        footer: false
    },
    {
        path: '/test-video',
        component: Test,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/bio-hacking',
        component: Telemedicine,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/how-it-works',
        component: HowItWorks,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/yoga-nutrition',
        component: Yoga,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/nlp',
        component: NLP,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/tele-medicine',
        component: Telemedicine,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/tele-monitoring',
        component: Telemonitoring,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/second-opinion',
        component: SecondOpinion,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/blogs',
        component: BlogList,
        private: false,//BlogList
        header: true,
        footer: true
    },
    {
        path: '/about-us',
        component: Aboutus,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/blogs/:id',
        component: BlogDetail,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/youtube',
        component: Youtube,
        private: false,
        header: true,
        footer: true//Youtube
    },
    {
        path: '/events',
        component: EventPage,
        private: false,
        header: true,
        footer: true//Youtube
    },
    {
        path: '/management-team',
        component: Telemedicine,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/investor-relations',
        component: Telemedicine,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/yoga',
        component: Yoga,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/faqs',
        component: FAQs,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/terms-and-conditions',
        component: TermsConditions,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/manage-your-appointments',
        component: AppointmentLand,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/billing-and-reports',
        component: BillingReportLand,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/provide-better-health',
        component: MobileAppsLand,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/communication-made-simple',
        component: PatientQuestionsLand,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/tele-monitoring-virtual-clinical',
        component: RemoteLand,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/video-consultations',
        component: VideoConsultationLand,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/login',
        component: Login,
        private: false
    },
    {
        path: '/first-time-login',
        component: FirstTimeUserLogin,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/register',
        component: RegisterPatient,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/doctor/reset-password',
        component: ResetPassword,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/organization/reset-password',
        component: ResetPassword,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/contact-us',
        component: Contactus,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/patient/update-password',
        component: ForgotPassword,
        private: false,
        header: false,
        footer: false,
        query:{
            verifyToken: ''
        }
    },
    {
        path: '/emergency-contact/login',
        component: EmergencyContactLogin,
        private: false,
        header: false,
        footer: true
    },
    {
        path: '/emergency-contact/health-parameters',
        component: HealthParametersEmergency,
        private: true,
        header: true,
        footer: true
    },
    {
        path: '/patient/messages',
        component: PatientMessages,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/doctor/messages',
        component: DoctorMessages,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/patient/dashboard',
        component: Patient,
        private: true,
        header: false,
        footer: false,
        subHeader: false,
    },
    {
        path: '/doctor/slots',
        component: DoctorSlots,
        private: true,
        header: false,
        footer: false,
        subHeader: false,
    },
    {
        path: '/patient/health',
        component: PatientHealth,
        private: true,
        header: false,
        footer: false,
        subHeader: false,
    },

    {
        path: '/view',
        component: ViewFile,
        private: true,
        header: false,
        footer: false,
        subHeader: false,
    },
    {
        path: '/patient/appointment',
        component: PatientDashboard,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/appointment-listing',
        component: PatientAppointments,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/patient/view-appointment/:id',
        component: ViewAppointment,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/patient/invoices',
        component: PatientInvoices,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/view-invoice/:id',
        component: ViewInvoice,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/patient/settings',
        component: ProfileSettings,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/billing_invoice',
        component: BillingInvoice,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/chat',
        component: PatientChat,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/weeklyreport',
        component: PatientReport,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/listing-practitioners',
        component: ListingDoctors,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/patient/create-qr',
        component: CreateQr,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/share-qr/:id',
        component: ShareQr,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/medical-record',
        component: PatientMedicalHistory,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/health-parameters',
        component: HealthParameters,
        private: true,
        header: false,
        subHeader: false,
        footer: false
    },
    {
        path: '/patient/book-appointment/:id',
        component: BookingSlots,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/patient/reschedule-book-appointment/:id/:appId',
        component: BookingSlots,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/patient/book-appointment-form',
        component: PatientFormBooking,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/patient/checkout-payment',
        component: PatientCheckout,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/patient/payment-success-booking/:id',
        component: BookSuccess,
        private: true,
        header: false,
        footer: true
    },
    
    {
        path: '/public/patient-info',
        component: PatientInformation ,
        private: false,
        header: false,
        footer: false,
        query:{
            token: ''
        }
    },
    {
        path: '/organization/register',
        component: OrganizationRegistration,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/organization/registration-success',
        component: SuccessMsgAfterRegister,
        private: false
    },
    {
        path: '/organization/login',
        component: OrganizationLogin,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/organization/forgot-password',
        component: ForgotPasswordOrgination,
        private: false,
        header: true,
        footer: true
    },

    {
        path: '/organization/dashboard',
        component: OrganisationDashboard,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/patient-list',
        component: OrganisationPatientList,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/doctor-list',
        component: OrganisationDoctorList,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/sub-admin-list',
        component: OrganisationSubAdminList,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/settings',
        component: OrganisationProfileSetting,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/billing_invoice',
        component: BillingInvoice,
        private: true,
        header: false,
        footer: false
    },
    
    {
        path: '/organization/view-patient/:id/:patientId',
        component: ViewPatientDetails,
        private: true,
        header: false,
        footer: false
    },
    // {
    //     path: '/organization/doctor-listing',
    //     component: DoctorListing,
    //     private: true,
    //     header: false,
    //     footer: false
    // },
    {
        path: '/organization/doctor-add',
        component: DoctorAddEdit,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/doctor-edit/:id',
        component: DoctorAddEdit,
        private: true,
        header: false,
        footer: false
    },
    // {
    //     path: '/organization/patient-listing',
    //     component: PatientListing,
    //     private: true,
    //     header: false,
    //     footer: false
    // },
    {
        path: '/organization/patient-add',
        component: PatientAddEdit,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/patient-edit/:id',
        component: PatientAddEdit,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/appointments',
        component: Appointments,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/coupons',
        component: Coupons,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/add-coupon',
        component: ViewCoupon,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/view-coupon/:id',
        component: ViewCoupon,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/booking-appointment/:id',
        component: OrgSuccessBooking,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/invoices',
        component: Invoices,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/view-invoice/:id',
        component: InvoiceViewOrg,
        private: true,
        header: false,
        footer: false
    },
    // {
    //     path: '/organization/settings',
    //     component: Settings,
    //     private: true,
    //     header: false,
    //     footer: false
    // },
    {
        path: '/organization/sub-admin',
        component: SubAdmin,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/add-sub-admin',
        component: ViewSubAdmin,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/update-sub-admin/:id',
        component: ViewSubAdmin,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/terms-conditions',
        component: OrgTerms,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/rooms',
        component: HospitalRooms,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/rooms/add',
        component: AddRoom,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/organization/rooms-edit/:id',
        component: AddRoom,
        private: true,
        header: false,
        footer: false
    },

//doctor 
    {
        path: '/doctor/login',
        component: DoctorLogin,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/doctor/forgot-password',
        component: ForgotPasswordDoctor,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/doctor/register',
        component: RegisterDoctor,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/doctor/appointments',
        component: DoctorAppointment,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/settings',
        component: DoctorSettings,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/billingInvoice',
       component: BillingInvoice,
        private: true,
        header: false,
        footer: false
    },
//subadmin
    {
        path: '/doctor/reviews',
        component: DoctorReviews,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/add-patient-form',
        component: AddPatientDoctor,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/book-new-appointment-form/:id',
        component: BookAppointmentForPatient,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/re-book-appointment-form/:id/:apptId',
        component: BookAppointmentForPatient,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/patient-listing',
        component: DoctorPatients,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/ipd-patient-listing',
        component: DoctorPatients,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/view-patient/:id',
        component: DoctorViewPatient,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/invoices',
        component: DoctorInvoices,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/treatment',
        component: DoctorTreatment,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/completed-appointments',
        component: DoctorCompletedAppointments,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/view-appointment/:id/:patientId',
        component: DoctorViewAppointment,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/view-ipd-patient/:id/:patientId',
        component: DoctorViewIPD,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/calendars',
        component: DoctorCalendar,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/schedule',
        component: DoctorSchedule,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/ipd',
        component: IPD,
        private: true,
        header: false,
        footer: false
    },
    
    {
        path: '/patient/qr-code',
        component: QRcode,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/doctor-chat/:id?',
        component: DoctorChat,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/doctor/payment-method',
        component: PaymentMethod,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/subuser/login',
        component: SubUserLogin,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/subuser/forgot-password',
        component: SubAdminForgotPassword,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/subuser/dashboard',
        component: SubAdminDashbaord,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/subuser/ipdward',
        component: SubAdminIpdWard,
        private: true,
        header: false,
    },
    {
        path: '/subuser/ipdward/:tabActive',
        component: SubAdminIpdWard,
        private: true,
        header: false,
    },
    {
        path: '/subuser/ipdward/room-listing/:id',
        component: RoomListing,
        private: true,
        header: false,
    },
    {
        path: '/subuser/ipdward/add-patient/:id',
        component: SubAdminIpdWardAddPatient,
        private: true
    },
    {
        path: '/subuser/ipdward/add-patient',
        component: SubAdminIpdWardAddPatient,
        private: true
    },
    {
        path: '/subuser/ipdward/add-patient/form-details/:id',
        component: SubAdminIpdWardAddPatientDetails,
        private: true
    },
    {
        path: '/subuser/appointments',
        component: SubAdminApointments,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/subuser/patient-listing',
        component: SubAdminPatients,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/subuser/settings',
        component: SubAdminSettings,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/subuser/view-appointment/:id',
        component: SubAdminViewAppt,
        private: true
    },
    {
        path: '/subuser/view-appointment/:id/:patientId',
        component: DoctorViewAppointment,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/subuser/view-patient/:id/:patientId',
        component: SubAdminViewPatient,
        private: true
    },
    {
        path: '/subuser/patient/:id',
        component: SubAdminPatient,
        private: true
    },
    {
        path: '/subuser/doctor/:id',
        component: SubAdminDoctor,
        private: true
    },
    {
        path: '/subuser/doctor-listing',
        component: SubAdminListingDoctors,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/subuser/doctor-listing/:id',
        component: SubAdminListingDoctors,
        private: true,
        header: false,
        footer: false
    },
    {
        path: '/subuser/book-appointment/:doctorId/:patientId',
        component: SubAdminBookingSlots,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/subuser/book-appointment-form/:id',
        component: SubAdminPatientFormBooking,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/subuser/book-appointment-form',
        component: SubAdminPatientFormBooking,
        private: true,
        header: false,
        footer: true
    },
    {
        path: '/upload-documents/:id',
        component: UploadDocuments,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/about',
        component: About,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/conduct-study',
        component: ConductStudy,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/conduct-study/ques',
        component: Question,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/hms',
        component: HMS,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/support',
        component: Support,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/hmspackages',
        component: HMSPackages,
        private: false,
        header: true,
        footer: true
    },
    {
        path: '/refund-&-cancellation-policy',
        component: RefundPolicy,
        private: false,
        header: true,
        footer: true
    },
    {
        path:'/organization/subscription',
        component:OrganizationSubscription,
        private: true,
        header: false,
        footer:false

    },

//subadmin
{
    path: '/subadmin/home',
    component: SubAdminDashboard,
    private: true,
    header: false,
    footer: false
},
{
    path: '/subadmin/appointments',
    component: SubadminOPDAppointment,
    private: true,
    header: false,
    footer: false
},
{
    path: '/subadmin/ipd',
    component: IPD,
    private: true,
    header: false,
    footer: false
},
{
    path: '/subadmin/rooms',
    component: Rooms,
    private: true,
    header: false,
    footer: false
},
{
    path: '/subadmin/settings',
    component: SubadminProfileSetting,
    private: true,
    header: false,
    footer: false
},
{
    path: '/subadmin/billingInvoice',
    component: SubadminBillingInvoice,
    private: true,
    header: false,
    footer: false
},

];




import React from "react";
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import colors from "../../../src/constant/colors";
import {
  ButtonStyle,
  ButtonWithBorderStyle,
  StyledProceedButton,
} from "../../assets/styles/CommonStyles";
import { commonMuiStyles } from "../../assets/styles/CommonMUIStyles";

const StyledListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    color: var(--Black, #2b2b2b);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
  }

  .MuiListItemText-secondary {
    .MuiTypography-root {
        color: var(--Black-2, #5E5E5E);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }
  }
`;
const ItemList = ({ data, navigate }) => {
  const commonStyleClasses = commonMuiStyles();
  return (
    <>
      <List sx={{bgcolor: "background.paper", overflow: 'auto', height: '283px' }}>
        {!!data &&
          data.map((item) => (
            <>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <StyledListItemText
                  primary={item.name}
                  secondary={
                    <>                    
                      <Typography>
                        ID: {navigate === "doctor" ? item._id : item.uniqueID}
                      </Typography>
                    </>
                  }
                />
                {/* <ButtonWithBorderStyle
                  className={commonStyleClasses.textGradientColor}
                >
                  View
                </ButtonWithBorderStyle> */}
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))}
      </List>
    </>
  );
};

export default ItemList;

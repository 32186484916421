import styled from 'styled-components';

export const StyledHomeSlider = styled.div`

.mobile-screen-desktop {
	@media (max-width: 450px){
		display: none;
	}
}

.mobile-screen-steps {
	@media (min-width: 450px){
		display: none;
	}
}
.m-h-2018 {
	@media (max-width: 450px){
		position: relative;
		bottom: 54px;
		right: 6px;
	}	
}
.m-h-2019 {
	@media (max-width: 450px){
		position: relative;
		bottom: 54px;
		right: 6px;
	}	
}
.m-h-2020 {
	@media (max-width: 450px){
		position: relative;
		bottom: 54px;
		right: 6px;
		font-size: 16px !important;
		line-height: 18px !important;
		color: #fff! important;
	}	
}
.m-h-2021 {
	@media (max-width: 450px){
		position: relative;
		bottom: 54px;
		right: 6px;
	}	
}
.m-h-2022 {
	@media (max-width: 450px){
		position: relative;
		bottom: 54px;
		right: 6px;
	}	
}

.m-h-mobile-screen-list:after {
	@media (max-width: 450px){
		background-color: #2B2B2B;
		box-shadow: 0 0 0 2px #CCCCCC, 0 0 0 2px #CCCCCC;
		height: 20px!important;
		top: -17px!important;
		width: 20px!important;
		border-radius: 100%!important;
		content: ""!important;
		left: calc(40% - 7px)!important;
		line-height: 14px!important;
		position: absolute!important;
		text-align: center!important;
		transition: all 0.25s ease 0s!important;
		vertical-align: middle!important;
	}
}
.current-m-screen:after {
	@media (max-width: 450px){
		background-color: #fff!important;
		box-shadow: 0 0 0 2px #fff, 0 0 0 2px #fff!important;
	}
}
.m-h-mobile-screen-list {
	@media (max-width: 450px){
		padding: 6px 0 !important;
	}
}
.m-h-mobile-screen-list:not(:last-child):before {
	@media (max-width: 450px){
		background-color: #a6a6a6!important;
		content: ""!important;
		height: 2px!important;
		left: calc(40% + 7px)!important;
		position: absolute!important;
		top: -7px!important;
		width: calc(100% - 14px)!important;
	}
}

.h-member {
	position: relative;
    right: 168px;
}
.h-adding {
	position: relative;
    right: 328px;
}
.h-2018{
    position: relative;
    bottom: 65px;
}
.h-2019{
	position: relative;
    bottom: 65px;
}
.h-2020{
	position: relative;
    bottom: 65px;
}
.h-2021{
	position: relative;
    bottom: 65px;
	right: 280px;
	color: #a6a6a6;
}
.active{
	font-size: 30px !important;
	line-height: 40px !important;
	color: #fff! important;
    bottom: 81px;
}
.h-2022{
	position: relative;
    bottom: 65px;
}
.h-steps-bar {
	margin: 0 auto;
	width: 100%;
   }
   ul.h-steps-indicator {
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	left: 0;
	list-style: outside none none;
	margin: 0;
	right: 0;
	width: 100%;
	padding: 24px 0 10px;
	@media (max-width: 450px){
		padding: 10px;
	}
   }
   ul.h-steps-indicator.steps-3:before {
	left: 16.6667%;
	right: 16.6667%;
   }
   ul.h-steps-indicator li {
	padding: 10px 0 0;
	margin: 0;
	position: relative;
	width: 33.3333%;
	pointer-events: none;
   }
   ul.h-steps-indicator li:after {
	background-color: #2B2B2B;
	box-shadow: 0 0 0 2px #CCCCCC, 0 0 0 2px #CCCCCC;
    height: 20px;
    top: -17px;
    width: 20px;
	border-radius: 100%;
	content: "";
	left: calc(-67% - 7px);
	line-height: 14px;
	position: absolute;
	text-align: center;
	transition: all 0.25s ease 0s;
	vertical-align: middle;
   }
   .h-first:after{
	background-color: #2B2B2B!important;
	box-shadow: 0 0 0 2px #CCCCCC, 0 0 0 2px #CCCCCC!important;
    height: 20px!important;
    top: -17px!important;
    width: 20px!important;
	border-radius: 100%!important;
	content: ""!important;
	left: calc(50% - 7px)!important;
	line-height: 14px!important;
	position: absolute!important;
	text-align: center!important;
	transition: all 0.25s ease 0s!important;
	vertical-align: middle!important;
   }
   .h-first:not(:last-child):before {
	background-color: #a6a6a6!important;
	content: ""!important;
	height: 2px!important;
	left: calc(50% + 7px)!important;
	position: absolute!important;
	top: -7px!important;
	width: calc(40% - 14px)!important;
   }
   .h-second:not(:last-child):before {
	background-color: #a6a6a6!important;
	content: ""!important;
	height: 2px!important;
	left: calc(-7% + 7px)!important;
	position: absolute!important;
	top: -7px!important;
	width: calc(40% - 14px)!important;
   }
   .h-second:after{
	background-color: #2B2B2B!important;
	box-shadow: 0 0 0 2px #CCCCCC, 0 0 0 2px #CCCCCC!important;
    height: 20px!important;
    top: -17px!important;
    width: 20px!important;
	border-radius: 100%!important;
	content: ""!important;
	left: calc(-10% - 7px)!important;
	line-height: 14px!important;
	position: absolute!important;
	text-align: center!important;
	transition: all 0.25s ease 0s!important;
	vertical-align: middle!important;
   }
   .h-end:after {
	background-color: #2B2B2B !important;
	box-shadow: 0 0 0 2px #CCCCCC, 0 0 0 2px #CCCCCC !important;
    height: 20px!important;
    top: -17px!important;
    width: 20px!important;
	border-radius: 100%!important;
	content: ""!important;
	left: calc(47% - 7px)!important;
	line-height: 14px!important;
	position: absolute!important;
	text-align: center!important;
	transition: all 0.25s ease 0s!important;
	vertical-align: middle!important;
   }
   .h-end:not(:last-child):before {
	background-color: #a6a6a6!important;
	content: ""!important;
	height: 2px!important;
	left: calc(-66% + 7px)!important;
	position: absolute!important;
	top: -7px!important;
	width: calc(100% - 14px)!important;
   }
   .h-current:after{
	background-color: #fff!important;
    box-shadow: 0 0 0 2px #fff,0 0 0 2px #fff!important;
    height: 20px!important;
    top: -17px!important;
    width: 20px!important;
	border-radius: 100%!important;
	content: ""!important;
	left: calc(-51% - 7px)!important;
	line-height: 14px!important;
	position: absolute!important;
	text-align: center!important;
	transition: all 0.25s ease 0s!important;
	vertical-align: middle!important;
   }
   ul.h-steps-indicator li:not(:last-child):before {
	background-color: #a6a6a6;
	content: "";
	height: 2px;
	left: calc(-66% + 7px);
	position: absolute;
	top: -7px;
	width: calc(212% - 14px);
   }
   ul.h-steps-indicator li div {
	align-items: center;
	display: flex;
	flex-direction: column;
   }
   ul.h-steps-indicator li div a {
	color: #a6a6a6;
	cursor: pointer;
	font-family: arial;
	font-size: 11px;
	font-weight: normal;
	line-height: 14px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.25s ease 0s;
   }
   
   ul.h-steps-indicator li.h-done:after,
   ul.h-steps-indicator li.h-current:after,
   ul.h-steps-indicator li.h-done:not(:last-child):before {
	background-color: #2384f7;
   }
   ul.h-steps-indicator li.h-current div a {
	// color: #3e4350;
   }

.emed4u-silder-main {
	display: flex;
    justify-content: center;
	margin-top: 10px;
	position: relative;
	@media (max-width: 450px){
		padding: 10px 15px 0;
	}
}
.emed4u-silder{
	width: 455px;
    color: #444;
	background: #fff;
    border-radius: 4px;
	box-shadow: 4px -4px 47px rgba(217, 217, 217, 0.15);
	position: relative;
	.triangle-top{
		position: absolute;
		top: -20px;
		left: calc(50% - 10px);
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 20px solid white;
	}

	@media (max-width: 450px){
		padding: 15px;
	}
}
.mobile-screen-frame {
	@media (max-width: 450px){
		width: 100%;
	}
}
.vector-icon {
	right: 20px;
    position: absolute;
    bottom: 10px;
	display: flex;
    gap: 25px;
	@media (max-width: 450px){
		right: 0;
		left: 0;
		justify-content: space-between;
		padding: 0 15px;
		position: absolute;
		bottom: -67px;
	}
}

.team-heading {
    color: #444;
}
.padding-emed4u {
	padding: 15px;
}
.emed4u-Wearables {
	width: 100%;
    text-align: center;
	font-weight: 500;
	font-size: 64px;
	line-height: 94px;
	padding: 35px 0 75px;
	color: #fff;
	@media (max-width: 450px){
		font-size: 24px;
		line-height: 32px;
	}
}
.bg-img-wearables {
	margin-top: 50px;
	flex-direction: column;
	background: #2B2B2B;
	@media (max-width: 450px){
		height: 690px !important;
	}
}
.emed4u-Wearables-title {
	width: 100%;
    text-align: center;
	font-weight: 500;
	font-size: 64px;
	line-height: 94px;
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	@media (max-width: 450px){
		font-size: 24px;
		line-height: 32px;
		padding: 30px 0;
	}
}
.color-ful {
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 500;
	font-size: 60px;
}
.heading-link-two-mobile {
	text-align: center;
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	margin-top: 15px;
	@media (min-width: 450px){
		display: none;
	}
}
.heading-link-two {
	text-align: center;
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 500;
	font-size: 60px;
	margin-top: 15px;
	@media (max-width: 450px){
		display: none;
	}
}
.p-detail {
	font-weight: 400;
	font-size: 24px;
	font-family: 'Poppins';
	line-height: 32px;
	margin-top: 35px;
	@media (max-width: 450px){
		margin-top: 0;
		font-size: 14px;
		line-height: 18px;
		padding: 0 15px;
	}
 }
 .p-mobile-screen {
	@media (max-width: 450px){
		display: none !important;
	}
 }
 .p-mobile-disktop {
	@media (min-width: 450px){
		display: none !important;
	}
 }
.emed4u-link {
	display: flex !important;
	flex-direction: column;
	align-items: center;
	margin: 60px 0;
	@media (max-width: 450px){
		margin: 10px 0;
	}
	.heading-link {
		font-family: Poppins;
		font-weight: 500;
		font-size: 60px;
		line-height: 90px;
		text-align: center;
		width: 60%;
		margin-bottom: 25px;
		@media (max-width: 450px){
			width: 60%;
			margin: 15px 0 20px;
			font-size: 24px;
			line-height: 32px;
		}
	}
	.heading-link1 {
		background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 500;
		font-size: 60px;
		@media (max-width: 450px){
			width: 60%;
			font-size: 24px;
			line-height: 32px;
		}
	}
	.about-details {
		font-family: Poppins;
		font-weight: 500;
		font-size: 22px;
		text-align: center;
		line-height: 32px;
		width: 70%;
		@media (max-width: 450px){
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			text-align: left;
			width: 100%;
			padding: 0 15px;
		}
	}
  }

  .main-div {
	  display: flex;
	  padding: 80px;
	  gap: 100px;
	  @media (max-width: 450px){
		display: flex;
    	flex-direction: column;
		padding: 0;
		gap: 16px;
	}
	img {
		@media (max-width: 450px){
			width: 100%;
		}
	}
  }
	.bd-home-slider {
		height: 88vh;
		@media (max-width: 767px){
			// max-height: 100%;
			// height: 100%;
			padding: 0px;
			height: 50vh;
		}
		@media (max-width: 450px){
			// height: 200px;
			height: 35vh;
		}

		span {
			color: #fff !important;
			@media (max-width: 767px){
				color: #6c757d!important;
			}
		}
		h2 {
			color: #fff !important;
			font-size: 40px;
      line-height: 52px;
			font-weight: 400;
			@media (max-width: 767px){
				color: #272b41!important;
			}
		}
		li {
			color: #fff !important;
			@media (max-width: 767px){
				color: #757575!important;
			}
		}
	}

	.about-details {
		font-family: 'Poppins';
		font-weight: 500;
		font-size: '24px';
		line-height: '32px';
		width: '50%';
	}

	.slide-image {
		@media (max-width: 767px){
			padding: 0px 15px;
		}
	}

  .bg-img-watch-001 {
		background: url(/assets/img/watch1.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-img-work-001 {
		background: url(/assets/img/hero.png);
		background-repeat: no-repeat;
    	background-size: contain;
		position: relative;
		background-size: 100% 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }

		.click-able{
			position:absolute;
			width: 100px;
			height: 100px;
			cursor:pointer;
			bottom:0;
			left:calc(50% - 50px)
		}
	}

	.bg-img-001 {
		background: url(/assets/img/emed4u3rd1.svg);
		background-repeat: no-repeat;
    background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		@media (max-width: 767px){
			background: none !important;
		}
	}

	.bg-img-002 {
		background: url(/assets/img/emed4u3rd2.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		@media (max-width: 767px){
			background: none !important;
		}
	}

	.bg-img-003 {
		background: url(/assets/img/emed4u3rd3.png);
		//background-repeat: no-repeat;
		background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		@media (max-width: 767px){
			background: none !important;
		}
	}

	.bg-img-004 {
		background: url(/assets/img/4thbg.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		@media (max-width: 767px){
			background: none !important;
		}
	}

	.bg-home-mobile-001 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4u3rd1.svg) !important;
			background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-002 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4u3rd2.svg) !important;
			background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-003 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4u3rd3.svg) !important;
			//background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-004 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/4thbg.png) !important;
			//background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.img-dsk-sh-book {
		display: block !important;
		@media (max-width: 767px){
			display: none !important;
		}
	}

	.img-dsk-sh-book-01 {
		@media (max-width: 767px){
			z-index: 999;
			height: 115px;
			a {
				z-index: 999;
				width: 120px;
				margin-top: 39px;
				font-size: 14px;
			}
		}
	}

	.img-dsk-sh {
		display: none !important;

		img {
			width: 150%;
			height: 100%;
			margin-left: -238px;
		}
		@media (max-width: 767px){
			display: flex !important;
		}
	}
`;

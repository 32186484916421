import { createAction } from "@reduxjs/toolkit";

export const doctorProfileAction = createAction('user/doctor/get-profile', (payload) => {
   return {
      payload:payload,
    };
  });

  export const updateDoctorProfileAction = createAction('user/doctor/update-profile', (payload) => {
       return {
       payload:payload,
     };
   });
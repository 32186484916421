import React, { useEffect, useState } from "react";
import { commonMuiStyles } from "../../assets/styles/CommonMUIStyles";
import { Box, Dialog,Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import strings from "../../constant/strings";
import {
  ContainerStyle,
  StyledCrossIcon,
  StyledSlotDivider,
} from "../../assets/styles/CommonStyles";
import colors from "../../constant/colors";
import { IPDdashoboardUseStyles } from "../../assets/styles/IPDDashboard";
import userProfile from "../../../src/assets/img/userProfile.png";
import {
  basicPatientDetail,
  detailedDoctorDetails,
  detailedPatientDetails,
  detailedSubadminDetails,
  patientInformation,
  patientMedicalDetails,
} from "../../constant/staticLists";
import { basicPatientData } from "../ui/IPDPatientDetails.js/PatientDetails";
import InformationPair from "../controls/InformationPair";
import { useSelector } from "react-redux";
import * as mainService from "../../services/mainService";
import { toast } from "react-toastify";

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { color } from "@mui/system";
const useStyles = makeStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Full viewport height to vertically center
    },})
export const patientMedicalDetailsData = {
  provisional: "12134",
  bp_dia: "455",
  bp_sys: "100",
  temp: "99",
  spo2: "98",
  pulse: "125",
  rr: "43",
  illness_summary: "Sick very sick very very sick",
};

const Subadmininfo = ({ setOpenModal, setScreen, id,setLoading }) => {
  console.log("id", id);
  const classes = commonMuiStyles();
  const [isActive, setIsActive] = useState();
  const [open, setOpen] = useState(false);
  const dashboardClasses = IPDdashoboardUseStyles();
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));
  console.log("userData", userData.userType);
  const [patientDetails, setPatientDetails] = useState({});
  const [isStatus, setIsStatus] = useState(true);
  useEffect(async () => {
    const payload = {
      id: id,
      token: userData.token,
    };
    console.log(payload);
    const res2 = await mainService.getSubadminDetailsForDoctorApi(payload);
    // console.log("res20",res2)
    if (res2.response_type == "fail") {
      toast.error(res2?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 1000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      console.log("res", res2.response.data);
      setPatientDetails(res2.response.data);
      setIsActive(res2.response.data.status === 1?true :false );
    }
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleToggle =()=>{
    setOpen(true)
  }
  const handleClose = async(confirmed) => {
    setOpen(false);
    const payload = {
      id: id,
      status :isActive==1?0:1
    }
    if (confirmed) {
     
      setIsActive(!isActive)
      const res = await mainService.putUpdateStatusSubadmin({payload:payload,token:userData.token});
      
      if (res.response_type =="fail"){
        // setButtonLoading(false)
        toast.error(res?.response?.message,{position:"bottom-center",closeButton:false,autoClose:1000,style:{width:"auto", borderRadius:8}});

    }else{
       
      setIsActive(!isActive)
        toast.success("Status Changed sucesssfully",{position:'bottom-center',closeButton:false,autoClose:1000,style:{width:"auto",borderRadius:8}});}
     
    } else {
      console.log("Action canceled!");
    }
  };
  const patientDetailsData = {
    name: "gautam",
    admission_date: "13/04/2023",
    room_no: "120",
    room_type: "General ward",
    admission_consult: "Dr. Pramit Gahlot",
    reason: "Sick",
    ref_phone_number: "+91-8976545678",
    mlc: "",
    fir: "",
  };
  const data = {
    name: patientDetails.name,
    email: patientDetails.email,
    phone_number: `${patientDetails.country_code}-${patientDetails.phone_number}`,
    gender:
      patientDetails?.gender == 1
        ? "Male"
        : patientDetails.gender == 2
        ? "Female"
        : "Other",
    dob: JSON.stringify(patientDetails?.dob),
    address: patientDetails.address,
    designation: patientDetails.designation ==1
    ?"Accountant"
    :patientDetails.designation ==2
    ? "Staff Nurse"
    :patientDetails.designation ==3
    ?"Componder"
    :"Laundry Boy",
    //   experience:patientDetails.experience,
    permission: patientDetails.permission ==1
    ?"IPD"
    :patientDetails.permission ==2
    ?"OPD"
    :"BOTH(IPD/OPD)",
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Typography className={classes.fontText24} color={colors.grey1}>
            {strings.subadminInformation}
          </Typography>
       
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="cross"
            onClick={() => {
              setOpenModal(false);
              setScreen((prev) => prev - 1);
            }}
          />
         

        </Grid>
        <Grid item xs={12}>
          <StyledSlotDivider />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <img
                src={patientDetails?.profile_image || userProfile}
                alt="User"
                className={dashboardClasses.image160}
              />
            </Grid>
            <Grid item xs={8}>
              <DetailsView
                heading={strings.personalInfo}
                fieldOrder={detailedSubadminDetails}
                data={data}
                size="half"
                patientDetails={patientDetails}
                id={id}
                isActi={isActive}
                setLoading={setLoading}
                setOpenModal={setOpenModal}
                setScreen={setScreen}
              />
            </Grid>
          
            
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const DetailsView = ({ heading, fieldOrder, data, size ,id,patientDetails,isActi,setOpenModal,setScreen , setLoading}) => {
  const dashboardClasses = IPDdashoboardUseStyles();
  const [isActive, setIsActive] = useState(true);
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(true);
  };
  useEffect(()=>{
    setIsActive(patientDetails.status === 1 ? true : false);
  },[])
  const handleClose = async (confirmed) => {
    setOpen(false);
    if (confirmed) {
      if (patientDetails.status == 1) {
         const payload = {
          id: id,
          status: 0,
        };
        setIsActive(!isActive);
        const res = await mainService.putUpdateStatusSubadmin({
          payload: payload,
          token: userData.token,
        });

        if (res.response_type == "fail") {
          // setButtonLoading(false)
          toast.error(res?.response?.message, {
            position: "bottom-center",
            closeButton: false,
            autoClose: 1000,
            style: { width: "auto", borderRadius: 8 },
          });
        } else {
          toast.success("Status Updated Sucessfully", {
            position: "bottom-center",
            closeButton: false,
            autoClose: 1000,
            style: { width: "auto", borderRadius: 8 },
          });
          setLoading(true);
          setOpenModal(false);
          setScreen((prev) => prev - 1);
        }
      }
      else {
        const value = {
          id: id,
          status: 1,
        };
        setIsActive(!isActive);
        const res = await mainService.putUpdateStatusSubadmin({
          payload: value,
          token: userData.token,
        });

        if (res.response_type == "fail") {
          // setButtonLoading(false)
          toast.error(res?.response?.message, {
            position: "bottom-center",
            closeButton: false,
            autoClose: 1000,
            style: { width: "auto", borderRadius: 8 },
          });
        } else {
          toast.success("Status Updated Sucessfully", {
            position: "bottom-center",
            closeButton: false,
            autoClose: 1000,
            style: { width: "auto", borderRadius: 8 },
          });
          setLoading(true);
          setOpenModal(false);
          setScreen((prev) => prev - 1);
        }
      }
    }

     else {
      console.log("Action canceled!");
    }
  };
  const Android12Switch = styled(({ isActive, ...props }) => <Switch {...props} />)(
    ({ theme, isActive }) => ({
      padding: 8,
      '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        backgroundColor: '#e36b40', // Background color of the track (default inactive)
        '&::before': {
          position: 'absolute',
          top: '50%',
          left: 12,
          transform: 'translateY(-50%)',
          fontSize: 7,
          color: '#fff',
        },
        '&::after': {
          position: 'absolute',
          top: '50%',
          right: 12,
          transform: 'translateY(-50%)',
          fontSize: 7,
          color: '#fff',
        },
      },
      '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
        backgroundColor: isActive ? '#1dd16e' : '#e36b40', // Dynamic color based on isActive prop
      },
      '& .Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#1dd16e', // Track color when the switch is checked
      },
      '& .Mui-checked .MuiSwitch-thumb': {
        backgroundColor: isActive ? '#e36b40' : '#1dd16e', // Thumb color when the switch is checked, based on isActive
      },
    })
  );
  
  
  return (
    <>
      <Typography
        className={dashboardClasses.font16}
        sx={{ fontWeight: "700 !important", color: colors.black1 }}
        mb={1}
      >
        {heading}
      </Typography>
      <InformationPair fieldOrder={fieldOrder} data={data} size={size} />
      <Grid container direction="row" alignContent={"center"}>
      <Typography mr={14} mt={1} fontWeight={"400"}>Status</Typography>
      <FormControlLabel
      
        control={<Android12Switch  checked={isActi} isActive={isActive} onChange={handleToggle}/>}
        label={isActi== true ?"Active":"Inactive"}
      />
      </Grid>
      <Grid
              item
              xs={12}
              alignItems="center"
              display="flex"
              justifyContent={"center"}
              mt={10}
            >
              <Dialog
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
              >
                <DialogTitle id="confirmation-dialog-title">
                  {"Confirm Action"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="confirmation-dialog-description">
                    Are you sure you want to proceed with this action?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleClose(false)} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleClose(true)}
                    color="primary"
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
    </>
  );
};

const Summary = ({ head, content }) => {
  const dashboardClasses = IPDdashoboardUseStyles();
  return (
    <>
      <div className={`${dashboardClasses.cont}`}>
        <Typography
          className={dashboardClasses.typographyText}
          sx={{
            borderBottom: "2px solid #CCCCCC",
            color: colors.black1,
            padding: "5px 5px",
          }}
        >
          {head}
        </Typography>
        <Typography
          sx={{ color: colors.grey1, padding: "5px 5px" }}
          className={dashboardClasses.typographyText}
        >
          {content}
        </Typography>
      </div>
    </>
  );
};

const MedicalDetailView = ({ title01, title02, data01, data02 }) => {
  const dashboardClasses = IPDdashoboardUseStyles();
  return (
    <>
      <Grid
        container
        spacing={0}
        className={`${dashboardClasses.cont} ${dashboardClasses.typographyText}`}
      >
        <Grid
          item
          xs={6}
          className={dashboardClasses.typographyText}
          sx={{
            borderRight: "2px solid #F5F5F7",
            borderBottom: "2px solid #F5F5F7",
            padding: "5px",
          }}
        >
          {title01}
        </Grid>
        <Grid
          item
          xs={6}
          className={dashboardClasses.typographyText}
          sx={{ borderBottom: "2px solid #F5F5F7", padding: "5px" }}
        >
          {title02}
        </Grid>
        <Grid
          item
          xs={6}
          className={dashboardClasses.typographyText}
          sx={{
            borderRight: "2px solid #F5F5F7",
            borderRight: "2px solid #F5F5F7",
            padding: "5px",
            color: colors.grey1,
          }}
        >
          {data01}
        </Grid>
        <Grid
          item
          xs={6}
          className={dashboardClasses.typographyText}
          sx={{ padding: "5px", color: colors.grey1 }}
        >
          {data02}
        </Grid>
      </Grid>
    </>
  );
};

export default Subadmininfo;

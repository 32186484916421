import React from 'react';
import { Grid } from '@mui/material';
import AppLayout from '../../../pages/SideBar';
import Message from '../Message';
import { useSelector } from 'react-redux';

const PatientMessages = () => {

    const { userData } = useSelector((state) => ({
        userData: state.auth.userData
    }));

    return (
        <Grid container spacing={0}>
            <AppLayout>
                <Message userType={"patient"} userData={userData} />
            </AppLayout>
        </Grid>
    )
}

export default PatientMessages;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    loading: false,
    error: null,
    availableTimeSlotList: [],
    addSlotsDoctor:[],
    getSlotsByDoctorList:[]
  };

  const doctorScheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
      getDoctorAvailableTimeRequest(state) {
        state.availableTimeSlotList = [];
        state.loading = true;
        state.error = null;
      },
      getDoctorAvailableTimeSuccess(state, { payload }) {
        state.availableTimeSlotList = payload;
        state.loading = false;
        state.error = null;
      },
      getDoctorAvailableTimeFailure(state, { payload }) {
        state.availableTimeSlotList = [];
        state.loading = false;
        state.error = payload;
      },

      addSlotDoctorRequest(state) {
        state.addSlotsDoctor = [];
        state.loading = true;
        state.error = null;
      },
      addSlotDoctorSuccess(state, { payload }) {
        state.addSlotsDoctor = payload;
        state.loading = false;
        state.error = null;
      },
      addSlotDoctorFailure(state, { payload }) {
        state.addSlotsDoctor = [];
        state.loading = false;
        state.error = payload;
      },
      getSlotsByDoctorRequest(state) {
        state.getSlotsByDoctorList = [];
        state.loading = true;
        state.error = null;
      },
      getSlotsByDoctorSuccess(state, { payload }) {
        state.getSlotsByDoctorList = payload;
        state.loading = false;
        state.error = null;
      },
      getSlotsByDoctorFailure(state, { payload }) {
        state.getSlotsByDoctorList = [];
        state.loading = false;
        state.error = payload;
      },

      

    },
  });
  
  export const { getDoctorAvailableTimeRequest, getDoctorAvailableTimeSuccess, getDoctorAvailableTimeFailure ,addSlotDoctorRequest ,addSlotDoctorSuccess ,addSlotDoctorFailure, getSlotsByDoctorRequest,getSlotsByDoctorSuccess,getSlotsByDoctorFailure} = doctorScheduleSlice.actions;
  
  export default doctorScheduleSlice.reducer;
import React, { useState } from "react";
import { Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import styled from "styled-components";
import MedicalReports from "../MedicalReports";
import { Box } from "@mui/system";
import EPriscriptions from "../EPriscriptions";
import PersonalMedicalDetails from "../PersonalMedicalDetails";

const Container = styled(Grid)(({ theme }) => ({
    '& .MuiPaper-root': {
        width: '100%',
        minHeight: 'calc(100vh - 240px)',
        marginBottom: 1,
    },
    '& .MuiTypography-root': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
    },
    '& .pagetitle': {
        paddingTop: 70,
        '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: '32px',
            lineHeight: '42px',
            color: '#2B2B2B',
            '& + .MuiTypography-root': {
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '18px',
                color: '#5E5E5E'
            }
        },
        '& + .MuiGrid-root': {
            // paddingTop: 0,
        }
    }
}))

const StyledTabs = styled(Tabs)(({ theme, selectedItem, index }) => ({
    borderBottom: '1.2px solid #F5F5F7',
    '& .MuiTab-root': {
        justifyContent: 'start',
        alignItems: 'start',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '18px',
        color: '#5E5E5E',
    },
    '& .Mui-selected': {
        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        border: '1px solid',
        borderImageSlice: 1,
        borderWidth: '0px 0px 1.2px',
        borderImageSource: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)'
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, ...index === 2 && { pt: 0 } }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


const MedicalHistory = () => {
    const [value, setValue] = useState(0)

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Container container spacing={4}>
            <Grid item xs={12} className="pagetitle">
                <Typography>Medical History</Typography>
                <Typography>All Personal meidcal data, prescription & medical reports.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={0} sx={{ paddingBottom: '20px'}}>
                    <Paper elevation={1}>
                        <Grid item xs={12}>
                            <StyledTabs value={value} onChange={handleChange} variant="scrollable">
                                {['Medical Reports', 'Personal Medical Details', 'E-Prescription'].map((item, index) =>
                                    <Tab label={item} {...a11yProps(index)} />
                                )}
                            </StyledTabs>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingLeft: '16px' }}>
                            <TabPanel value={value} index={0}>
                                <MedicalReports />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <PersonalMedicalDetails />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <EPriscriptions />
                            </TabPanel>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default MedicalHistory;
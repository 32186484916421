import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: null,
  bloodSugar: [],
  total_cholesterol: 4,
  LDL_C: 10,
  VLDL: 60,
  HDL_C: 10,
  triglycerides: 20,
  bloodPressure: [],
  bloodOxygen: [],
  bodyTemp: [],
  heartRate: [],
  respiratoryRate: [],
  healthParam: [{}],
  qrCodeDetails: {},
  patientInvoiceList: [],
  patientInvoiceDetail: {},
  healthConditions: [],
  medication: [],
  surgury: [],
  allergy: [],
  vaccination: [],
  medicalReport : [],
  patientEprescription: [],
  medicalHistory: [], 
  patientAppointmentInfoListLoader: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getBloodSugarRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getBloodSugarSuccess(state, action) {
      if (state.healthParam[0]) state.healthParam[0].bloodSugarData = action.payload;
      state.bloodSugar = action.payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getBloodSugarFailure(state, action) {
      state.bloodSugar = null;
      state.loading = false;
      state.error = action.payload;
      state.qrCodeDetails = {};
    },
    getBloodPressureRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getBloodPressureSuccess(state, action) {
      if (state.healthParam[0]) state.healthParam[0].bloodPressureData = action.payload;
      state.bloodSugar = action.payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getBloodPressureFailure(state, action) {
      state.bloodSugar = null;
      state.loading = false;
      state.error = action.payload;
      state.qrCodeDetails = {};
    },
    getBloodOxygenRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getBloodOxygenSuccess(state, action) {
      if (state.healthParam[0]) state.healthParam[0].spo2sData = action.payload;
      state.bloodSugar = action.payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getBloodOxygenFailure(state, action) {
      state.bloodSugar = null;
      state.loading = false;
      state.error = action.payload;
      state.qrCodeDetails = {};
    },
    getBodyTempRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getBodyTempSuccess(state, action) {
      if (state.healthParam[0]) state.healthParam[0].bodyTemperatureData = action.payload;
      state.bloodSugar = action.payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getBodyTempFailure(state, action) {
      state.bloodSugar = null;
      state.loading = false;
      state.error = action.payload;
      state.qrCodeDetails = {};
    },
    getHeartRateRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getHeartRateSuccess(state, action) {
      if (state.healthParam[0]) state.healthParam[0].heartRateData = action.payload;
      state.bloodSugar = action.payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getHeartRateFailure(state, action) {
      state.bloodSugar = null;
      state.loading = false;
      state.error = action.payload;
      state.qrCodeDetails = {};
    },
    getBloodSugarRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getRespiratoryRateRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getRespiratoryRateSuccess(state, action) {
      if (state.healthParam[0]) state.healthParam[0].respiratorieData = action.payload;
      state.bloodSugar = action.payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getRespiratoryRateFailure(state, action) {
      state.bloodSugar = null;
      state.loading = false;
      state.error = action.payload;
      state.qrCodeDetails = {};
    },
    getHealthParamRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getHealthParamSuccess(state, action) {
      state.healthParam = action.payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getHealthParamFailure(state, action) {
      state.healthParam = null;
      state.loading = false;
      state.error = action.payload;
      state.qrCodeDetails = {};
    },
    getLipidRequest(state) {
      state.lipidProfile = null;
      state.loading = true;
      state.error = null;

    },
    getLipidSuccess(state, action) {
      state.lipidProfile = action.payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getLipidFailure(state, action) {
      state.lipidProfile = null;
      state.loading = false;
      state.error = action.payload;
      state.qrCodeDetails = {};
    },
    getPatientProfileSuccess(state, { payload }) {
      state.patientProfile = payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getPatientProfileFailure(state, { payload }) {
      state.patientProfile = {};
      state.loading = false;
      state.error = payload
      state.qrCodeDetails = {};
    },
      // medical details
  getMedicalHistoryRequest(state) {
    state.medicalHistory = [];
    state.patientAppointmentInfoListLoader = true;
    state.error = null;
  },
    getMedicalHistorySuccess(state, { payload }) {
      state.medicalHistory = payload;
      state.patientAppointmentInfoListLoader = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getMedicalHistoryFailure(state, { payload }) {
      state.patientProfile = {};
      state.medicalHistory = [];
      state.patientAppointmentInfoListLoader = false;
      state.error = payload
      state.qrCodeDetails = {};
    },
    getHealthConditionsSuccess(state, { payload }) {
      state.healthConditions = payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getHealthConditionsFailure(state, { payload }) {
      state.healthConditions = [];
      state.loading = false;
      state.error = payload
      state.qrCodeDetails = {};
    },
    getMedicationSuccess(state, { payload }) {
      state.medication = payload;
      state.loading = false;
      state.error = null;
      state.qrCodeDetails = {};
    },
    getMedicationsFailure(state, { payload }) {
      state.medication = [];
      state.loading = false;
      state.error = payload
    },
    getSurgurySuccess(state, { payload }) {
      state.surgury = payload;
      state.loading = false;
      state.error = null;
    },
    getSurguryFailure(state, { payload }) {
      state.surgury = [];
      state.loading = false;
      state.error = payload
    },
    getAllergySuccess(state, { payload }) {
      state.allergy = payload;
      state.loading = false;
      state.error = null;
    },
    getAllergyFailure(state, { payload }) {
      state.allergy = [];
      state.loading = false;
      state.error = payload
    },
    getVaccinationSuccess(state, { payload }) {
      state.vaccination = payload;
      state.loading = false;
      state.error = null;
    },
    getVaccinationFailure(state, { payload }) {
      state.vaccination = [];
      state.loading = false;
      state.error = payload
    },
    //qrcode
    getQRcodeDetailsSuccess(state, { payload }) {
      state.qrCodeDetails = payload;
      state.loading = false;
      state.error = null;
    },
    getQRcodeDetailsFailure(state, { payload }) {
      state.qrCodeDetails = {};
      state.loading = false;
      state.error = payload
    },
  //invoice lsit
  getInvoiceListRequest(state) {
    state.patientInvoiceList = [];
    state.patientAppointmentInfoListLoader = true;
    state.error = null;
  },
    getInvoiceListSuccess(state, { payload }) {
      state.patientInvoiceList = payload;
      state.patientAppointmentInfoListLoader = false;
      state.error = null;
    },
    getInvoiceListFailure(state, { payload }) {
      state.patientInvoiceList = [];
      state.patientAppointmentInfoListLoader = false;
      state.error = payload
    },
    //single invoice
    getPatientInvoiceDetailRequest(state, { payload }) {
      state.patientInvoiceDetail = [];
      state.patientAppointmentInfoListLoader = true;
      state.error = null;
    },
    getPatientInvoiceDetailSuccess(state, { payload }) {
      state.patientInvoiceDetail = payload;
      state.patientAppointmentInfoListLoader = false;
      state.error = null;
    },
    getPatientInvoiceDetailFailure(state, { payload }) {
      state.patientInvoiceDetail = {};
      state.patientAppointmentInfoListLoader = false;
      state.error = payload
    },

//medical reports 
    getPatientMedicalReportRequest(state) {
      state.medicalReport = [];
      state.patientAppointmentInfoListLoader = true;
      state.error = null;
    },
    getPatientMedicalReportSuccess(state, { payload }) {
      state.medicalReport = payload;
      state.patientAppointmentInfoListLoader = false;
      state.error = null;
    },
    getPatientMedicalReportFailure(state, { payload }) {
      state.medicalReport = [];
      state.patientAppointmentInfoListLoader = false;
      state.error = payload;
    },
//eprescription
    getPatientEprescriptionRequest(state) {
      state.patientEprescription = [];
      state.patientAppointmentInfoListLoader = true;
      state.error = null;
    },
    getPatientEprescriptionSuccess(state, { payload }) {
      state.patientEprescription = payload;
      state.patientAppointmentInfoListLoader = false;
      state.error = null;
    },
    getPatientEprescriptionFailure(state, { payload }) {
      state.patientEprescription = [];
      state.patientAppointmentInfoListLoader = false;
      state.error = payload;
    },
  },
});

export const { getHealthParamRequest, getHealthParamSuccess, getHealthParamFailure,
  getBloodSugarRequest, getBloodSugarSuccess, getBloodSugarFailure,
  getBloodPressureRequest, getBloodPressureSuccess, getBloodPressureFailure,
  getBloodOxygenRequest, getBloodOxygenSuccess, getBloodOxygenFailure,
  getBodyTempRequest, getBodyTempSuccess, getBodyTempFailure,
  getHeartRateRequest, getHeartRateSuccess, getHeartRateFailure,
  getRespiratoryRateRequest, getRespiratoryRateSuccess, getRespiratoryRateFailure,
  getLipidRequest, getLipidSuccess, getLipidFailure, 
  getPatientProfileSuccess, getPatientProfileFailure,
  getMedicalHistoryRequest,getMedicalHistorySuccess, getMedicalHistoryFailure, 
  getHealthConditionsFailure, getHealthConditionsSuccess,
  getAllergyFailure, getAllergySuccess, 
  getMedicationSuccess, getMedicationsFailure,
  getSurguryFailure, getSurgurySuccess, 
  getVaccinationFailure, getVaccinationSuccess, 
  getQRcodeDetailsSuccess, getQRcodeDetailsFailure,
  getInvoiceListRequest, getInvoiceListSuccess, getInvoiceListFailure,
  getPatientInvoiceDetailSuccess, getPatientInvoiceDetailFailure, getPatientInvoiceDetailRequest, 
  getPatientEprescriptionRequest, getPatientEprescriptionSuccess, getPatientEprescriptionFailure, 
  getPatientMedicalReportRequest, getPatientMedicalReportSuccess, getPatientMedicalReportFailure
} = userSlice.actions;

export default userSlice.reducer;

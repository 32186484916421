import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  customAccordian: {
    background: '#F8F7F7',
    border: '1px solid #CCCCCC',
    borderRadius: '8px',
    boxShadow: 'none',
  },
  customAccordianText: {
    color: '#5E5E5E',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
  },
  customExpandIcon: {
    maskImage: 'linear-gradient(to bottom, red, blue)', // Replace with your gradient
    maskSize: 'cover',
    backgroundColor: 'currentColor',
  }
}))

const StyledAccordion = ({ dayName, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Accordion className={classes.customAccordian} expanded={expanded} onChange={handleExpand}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={
            <svg
              width="24"
              height="24"
              fill="currentColor"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
                  <stop offset="0%" stopColor="rgba(9, 126, 183)" />
                  <stop offset="100%" stopColor="rgba(42, 171, 108)" />
                </linearGradient>
              </defs>
              <path d="M7 10l5 5 5-5H7z" stroke="url(#gradient)" />
            </svg>
          }
        >
          <Typography className={classes.customAccordianText}>{dayName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>


    </div>

  );
}

export default StyledAccordion

import { apiPost, apiGet, apiPut, apiDelete } from "../utils";

export function postLoginAPI(data) {
	return apiPost(`/api/patient/login`, data);
}

export function postRegisterAPI(data) {
	return apiPost('/api/patient/signup', data);
}

export function forgotPasswordAPI(data) {
	return apiPost('/api/patient/forgotpassword', data);
}

export function firstTimeUser(data) {
	return apiPut('/api/patient/first-time-patient', data);
}

export function doctorEMed4uListingAPI(query) {
	return apiGet('/api/admin/existing/doctors', query);
}

export function getDoctorsListing(id, query) {
	return apiGet(`/api/organization/patient-doctor-listing/${id}`, query);
}

export function getFileLinkAPI(data) {
	return apiPost('/api/referral-file-link', data);
}

export function resetPasswordAPI(data) {
	return apiPut('/api/patient/resetpassword', data);
}
 
export function getDoctorSlotsListingAPI(id) {
	return apiGet(`/api/organization/patient-doctor-slots/${id}`);
}

export function BookedAppointmentAPI(data) {
	return apiPost("/api/organization/patient-booked-appointment-form", data);
}

export function getDashAppointmentsAPI(query) {
	return apiGet(`/api/organization/patient-dashboard-appointments`, query);
}

export function getAppointmentsAPI() {
	return apiGet(`/api/organization/patient-appointments`);
}

export function getAppointmentDetailsAPI(id) {
	return apiGet(`/api/organization/patient-booked-appointment-detail/${id}`);
}

export function getInvoices(query) {
	return apiGet(`/api/organization/patient-invoices`, query);
}

export function getInvoiceDetailsAPI(id) {
	return apiGet(`/api/organization/patient-invoice-detail/${id}`);
}

export function getPatientInfoAPI() {
	return apiGet(`/api/get/patient`);
}

export function getPatientDataInfoAPI() {
	return apiGet(`/api/get/patient-data`);
}

export function updatePatientInfoAPI(id, data) {
	return apiPut(`/api/update/patient/${id}`, data);
}

export function cancelAppointmentAPI(id, data) {
	return apiPut(`/api/organization/patient-cancel-appointment/${id}`, data);
}

export function reBookAppointmentAPI(id, data) {
	return apiPut(`/api/organization/patient-rebooking-appointment/${id}`, data);
}

// Get medical history
export function getMedicalHistoryDataAPI() {
	return apiGet(`/api/organization/patient-medical-history`);
}

// Add medical history
export function addHealthConditionAPI(data) {
	return apiPost(`/api/organization/patient-add-health-condition`, data);
}
export function addAllergieAPI(data) {
	return apiPost(`/api/organization/patient-add-allergy`, data);
}
export function addSurgeriesAPI(data) {
	return apiPost(`/api/organization/patient-add-surgery`, data);
}
export function addMedicationAPI(data) {
	return apiPost(`/api/organization/patient-add-medication`, data);
}
export function addVaccinationAPI(data) {
	return apiPost(`/api/organization/patient-add-vaccination`, data);
}

// update medical history
export function updateHealthConditionAPI(id, data) {
	return apiPut(`/api/organization/patient-update-health-condition/${id}`, data);
}
export function updateAllergieAPI(id, data) {
	return apiPut(`/api/organization/patient-update-allergy/${id}`, data);
}
export function updateSurgeriesAPI(id, data) {
	return apiPut(`/api/organization/patient-update-surgery/${id}`, data);
}
export function updateMedicationAPI(id, data) {
	return apiPut(`/api/organization/patient-update-medication/${id}`, data);
}
export function updateVaccinationAPI(id, data) {
	return apiPut(`/api/organization/patient-update-vaccination/${id}`, data);
}

export function updatePatientImage(id, data) {
	return apiPut(`/api/update/patient-image/${id}`, data);
}

export function submitFeedbackAPI(id, data) {
	return apiPut(`/api/organization/patient-submit-feedback/${id}`, data);
}

// Heath parameters
export function addBPAPI(data, type) {
	return apiPost(`/api/heath_parameter/${type}`, data);
}
export function getBPAPI(data, type) {
	// return apiGet(`/api/heath_parameter/${type}`, data);
	return apiGet(`/api/heath_parameter/${type}${data?.start && data?.end ? `?start=${data?.start}&end=${data?.end}` : ''}`);
}

export function getEcgAPI(id){
	return apiGet(`/api/heath_parameter/ecg/${id}`)
}

export function getBPAPIByFilter(type,query) {
	return apiGet(`/api/heath_parameter/${type}${query.start && query.end ? `?start=${query.start}&end=${query.end}` : ''}`);
}

export function addBSAPI(data) {
	return apiPost(`/api/heath_parameter/add_Bsugar`, data);
}
// export function getBSAPI() {
// 	return apiGet(`/api/heath_parameter/all_Bsugar`, );
// }

export function addOxyAPI(data) {
	return apiPost(`/api/heath_parameter/add_OxygenLevel`, data);
}
// export function getOxyAPI() {
// 	return apiGet(`/api/heath_parameter/all_OxygenLevel`, );
// }

export function addPluseAPI(data) {
	return apiPost(`/api/heath_parameter/add_Pluse`, data);
}
// export function getPluseAPI() {
// 	return apiGet(`/api/heath_parameter/all_Pluse`, );
// }

export function addRespiratoryAPI(data) {
	return apiPost(`/api/heath_parameter/add_RespiratoryRates`, data);
}
// export function getRespiratoryAPI() {
// 	return apiGet(`/api/heath_parameter/all_RespiratoryRates`, );
// }

export function addTemperatureAPI(data) {
	return apiPost(`/api/heath_parameter/add_Temperature`, data);
}
// export function getTemperatureAPI() {
// 	return apiGet(`/api/heath_parameter/all_Temperature`, );
// }

export function addCholesterolAPI(data) {
	return apiPost(`/api/heath_parameter/add_Cholesterol`, data);
}
// export function getCholesterolAPI() {
// 	return apiGet(`/api/heath_parameter/all_Cholesterol`, );
// }

export function addJointAPI(data) {
	return apiPost(`/api/heath_parameter/add_Joint`, data);
}
// export function getJointAPI() {
// 	return apiGet(`/api/heath_parameter/all_Joint`, );
// }

export function addPainAPI(data) {
	return apiPost(`/api/heath_parameter/add_Pain`, data);
}
// export function getPainAPI() {
// 	return apiGet(`/api/heath_parameter/all_Pain`, );
// }

export function addExerciseAPI(data) {
	return apiPost(`/api/heath_parameter/add_Exercise`, data);
}
// export function getExerciseAPI() {
// 	return apiGet(`/api/heath_parameter/all_Exercise`, );
// }

export function addFoodAPI(data) {
	return apiPost(`/api/heath_parameter/add_Food`, data);
}
// export function getFoodAPI() {
// 	return apiGet(`/api/heath_parameter/all_Food`, );
// }

export function addHeightAPI(data) {
	return apiPost(`/api/patient/bmi-calculate`, data);
}
export function getHeightAPI() {
	return apiGet(`/api/patient/bmiall`, );
}

export function addWaistAPI(data) {
	return apiPost(`/api/heath_parameter/add_Waist`, data);
}
// export function getWaistAPI() {
// 	return apiGet(`/api/heath_parameter/all_Waist`, );
// }

export function addWeightAPI(data) {
	return apiPost(`/api/heath_parameter/add_Weight`, data);
}
// export function getWeightAPI() {
// 	return apiGet(`/api/heath_parameter/all_Weight`, );
// }

export function genrateFIleLinkAPI(file) {
	return apiPost(`/api/referral-file-link`, file);
}

export function getAllPaymentMethod(id) {
	return apiGet(`/api/doctor/get/all-payment-methods-doctor/${id}`);
}



export function deleteBp(id, type) {
  return apiPut(`/api/heath_parameter/remove/${type}/${id}`);
}
// export function deleteBs(id) {
// 	return apiPut(`/api/heath_parameter/remove/bsugar/${id}`);
// }
// export function deleteOxygen(id) {
// 	return apiPut(`/api/heath_parameter/remove/oxygen_level/${id}`);
// }
// export function deletePluse(id) {
// 	return apiPut(`/api/heath_parameter/remove/pluse/${id}`);
// }
// export function deleteRespiratory(id) {
// 	return apiPut(`/api/heath_parameter/remove/respiratory_rate/${id}`);
// }
// export function deleteTemperature(id) {
// 	return apiPut(`/api/heath_parameter/remove/temperature/${id}`);
// }
// export function deleteCholesterol(id) {
// 	return apiPut(`/api/heath_parameter/remove/cholesterol/${id}`);
// }
// export function deleteJoint(id) {
// 	return apiPut(`/api/heath_parameter/remove/joint/${id}`);
// }
// export function deletePain(id) {
// 	return apiPut(`/api/heath_parameter/remove/pain/${id}`);
// }
// export function deleteExercise(id) {
// 	return apiPut(`/api/heath_parameter/remove/exercise/${id}`);
// }
// export function deleteFood(id) {
// 	return apiPut(`/api/heath_parameter/remove/food/${id}`);
// }
// export function deleteHeight(id) {
// 	return apiPut(`/api/heath_parameter/remove/height/${id}`);
// }
// export function deleteWaist(id) {
// 	return apiPut(`/api/heath_parameter/remove/waist/${id}`);
// }
// export function deleteWeight(id) {
// 	return apiPut(`/api/heath_parameter/remove/weight/${id}`);
// }


// Upload multiple documents
export function uploadMultipleDocuments(data) {
	return apiPost(`/api/organization/file-uploader-links`, data);
}

//upload patient report 
export function uploadPatientReport(data) {
	return apiPost(`/api/patient/upload_patient_report`,data);
}

// get patient reports
export function getPatientReports() {
	return apiGet(`/api/patient/patient_reports`);
}

export function getPatientReportDelete(id) {
	return apiDelete(`/api/patient/patient_report/delete/${id}`);
}



// 	Emergency contact
export function postEmergencyContactLoginAPI(data) {
	return apiPost(`/api/patient/emergency-login`, data);
}

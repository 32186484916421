import React from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';

import {
  StyledVirtualSlider
} from './styles';

function Virtual(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className : 'home-main-slider-full'
  };


  const Virtual = [
    {id: 1, description: 'APPOINTMENTS', title: 'Manage Your Appointments on the Go', icon: '/assets/img/icons/icon1.png', image: '/assets/img/land1.png', text: 'With the appointments, you can streamline consultation on the go, whether online or in-clinic.', route: '/manage-your-appointments'},
    {id: 2, description: 'VIDEO CONSULTATION', title: 'Video / Text Consultations in your Virtual Practice', icon: '/assets/img/icons/icon2.png', image: '/assets/img/land2.png', text: 'Using the Virtual Practice tool, you can meet more patients every day. An excellent, quick, and efficient tool helps follow-up with your patients, wherever they are located. Elderly patients and others with disabilities who are located in a distant place can easily connect with you. With greater collaboration, you can follow up with them for proper health care and monitor their health in a better way. ', route: '/video-consultations'},
    {id: 3, description: 'PATIENT QUESTIONS', title: 'Communication Made Simple', icon: '/assets/img/icons/icon3.png', image: '/assets/img/land3.png', text: 'You can communicate with your patients with just a simple click. Tell them about what’s new and keep them updated with the events relevant to them.', route: '/communication-made-simple'},
    {id: 4, description: 'MOBILE APPS', title: 'Provide Better Health Outcomes – Using Patient Health Record Management (PRM)', icon: '/assets/img/icons/icon4.png', image: '/assets/img/land4.png', text: 'Using the Patient Health Record Management (PRM), you can know more about the patient’s health reports and comprehensive medical history right at your fingertips.', route: '/provide-better-health'},
    {id: 6, description: 'REMOTE MONITORING', title: 'Tele Monitoring In Your Virtual Clinical Practice/ Clinical Institute', icon: '/assets/img/icons/icon5.png', image: '/assets/img/land5.png', text: 'Ensure that your patients get the best health care. Custom care plans that monitor the patients can reduce the risk of health complications between visits.', route: '/tele-monitoring-virtual-clinical'},
    {id: 5, description: 'BILLING & REPORTS', title: 'Billing & Reports', icon: '/assets/img/icons/icon6.png', image: '/assets/img/land6.png', text: 'Keep track of your growing practice by collecting payments online. Manage online billing and revenue reports that help in tracking your practice revenue. ', route: '/billing-and-reports'}
  ];

  const [currentTool, setCurrentTool] = React.useState({
    id: 1,
    text: 'With the appointments, you can streamline consultation on the go, whether online or in-clinic.',
    image: '/assets/img/land1.png',
    route: '/manage-your-appointments'
  })

  const [toolId, setToolId] = React.useState(1);

  const changePage = (route) => {
    props.history.push(route);
  }

  const onSetCurrentTool = (item) => {
    setToolId(item.id)

    setCurrentTool({
      id: item.id,
      text: item.text,
      image: item.image,
      route: item.route
    })
  }

  const changePageTele = (route) => {
    props.props.history.push(route);
  }

  return (
    <StyledVirtualSlider>
       <Slider {...settings}>
          {
         Virtual.map(item => {
              return  <div className="virtual-emed4u">
												<div className="row">
													<div className="col-md-6">
														<div className="virtual-img">
															<img src={item.image} />
														</div>
													</div>
													<div className="col-md-6">
														<div className="virtual-icon">
															<img src={item.icon} />
														</div>
														<h2>{item.title}</h2>
														<p>{item.text}</p>
														<button onClick={() => changePageTele(item.route)}>Read More <i className="fa fa-chevron-right"></i></button>
													</div>
											</div>
										</div>
            })
          }

        </Slider>
    </StyledVirtualSlider>
  )
}

export default Virtual

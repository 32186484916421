import React, { useEffect, useState, useRef, useContext } from 'react';
import { isMobile } from "react-device-detect";

import AgoraRTC from "agora-rtc-sdk-ng";

import { Context } from '../../../../store';

import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import { toast } from 'react-toastify';
import moment from 'moment';

import './index.css';

import { getUser } from '../../../../utils';

import {startBasicCall, leaveCall} from '../../Panel/Chat/Agora_RTC';

import {
	genrateFIleLinkAPI
} from '../../../../api/doctor';
import Card from '../../../../components/Card';
import DoctorFinalReport from '../../Panel/FinalReport';

function ChatBodyDashBoard (props) {
	const [state, dispatch] = useContext(Context);
	const bottomRef = useRef();

	const [isModalVisible, setIsModalVisible] = React.useState(false)
	const fileInput = useRef(null);
	const [id, setId] = React.useState(props.id)

	const { className = '', mobile = false, setAppointment, patientId} = props;

	const [currentChat, setCurrentChat] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
	const [message, setMessage] = useState(null);
	const blankMedication = {
		medication_name: '',
		medicine_type: 'Tablet',
		strength_name: '',
		strength_type: 'mg',
		// method_intake: '',
		// dos_quantity_count: '',
		dos_quantity_name: '',
		// dos_quantity_when: ''

	};

	const [report, setReport] = React.useState({
		impression_diagnosis: '',
		// medication_name: '',
		// medicine_type: 'Tablet',
		// strength_name: '',
		// strength_type: 'mg',
		// method_intake: '',
		// dos_quantity_count: '',
		// dos_quantity_name: '',
		// dos_quantity_when: '',
		notes: '',
		pendingAmount: 0
		// duration_start: new Date(),
		// duration_end: new Date()
	});

	const [fields, setFields] = React.useState([
		{ ...blankMedication },
	]);

	const [callLoading, setCallLoading] = useState(false);
	
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
    }
	};
	
	const [appid, setAppid] = useState('f5a79afbfcce4005bc94277dbd7fe04b');
	const appId = props.id;
	const [channel, setChannel] = useState(null);
	const [refreshToken, setRefreshToken] = useState(null);
	const [uuid, setUuid] = useState(1);
	const [isjoin, setIsJoin] = useState(false);

	useEffect(() => {
		const userId = getUser()._id;
		setCurrentUserId(userId);
		
		if(props.chatType == 'patient') {
			// const startChat = {
			// 	appointmentId: props.patientId,
			// 	role: "Doctor"
			// };
			// socket.emit("Start_Chat_WITH_PATIENT", startChat);
		} else {
				// const startChat = {
				// 	appointmentId: id,
				// 	role: "Doctor"
				// };
				// socket.emit("Start_Chat", startChat);
		}


		// socket.on("messages", data => {
		// 	setId(data._id)
		// 	const rToken = {
		// 		appointmentId: data._id
		// 	};
		// 	setAppid(data._id);
		// 	socket.emit("rtctoken-doctor", rToken);
		// 	setCurrentChat(data);
		// 	scrollToBottom();
		// });

		// socket.on("rtctoken-doctor", data => {
		// 	setChannel(id);
		// 	setRefreshToken(data);
		// })

		// socket.on("endCall", data => {

		// 	leaveCall(callType);
		// 	setIsJoin(false);
		// 	// toast.success('Patient end the call!')
		// 	dispatch({
		// 		type: SET_ERROR,
		// 		payload: 'Patient end the call!'
		// 	});

		// 	setTimeout(() => {
		// 		window.location.reload();
		// 	}, 1500)
		// })


	}, [id]);

	const sendMessage = () => {
		if (message) {
			const user = getUser();
			// const socketMsg = {
			// 	message: message,
			// 	appointmentId: id,
			// 	userId: user._id,
			// 	receiverId: currentChat?.patientId._id ? currentChat.patientId._id : patientId,
			// 	phoneNumber: currentChat?.patientId?.phoneNumber,
			// 	role: "Doctor",
			// 	email: user.email,
			// 	name: user.name,
			// 	link: "https://emed4u.com/patient/chat"
			// };
			// socket.emit("Send_Message_Chat", socketMsg);
			setMessage("");
		}
	};
	
	const sendReport = () => {
		document.body.style.overflowY = 'hidden'
		setIsModalVisible(true)
		// props.props.history.push(`/doctor/final-report/${id}/${currentChat.patientId._id}`);
	}

  const [errMessage, setErrMessage] = useState(null);
  const [callType, setCallType] = useState('audio');

	const allowVideo = (type) => {
    setCallType(type)

		AgoraRTC.getDevices()
		.then(devices => {
      let audioDevices, videoDevices;
      var selectedMicrophoneId, selectedCameraId
      if(type == 'audio') {
        audioDevices = devices.filter(function(device){
					return device.kind === "audioinput";
        });
        
        selectedMicrophoneId = audioDevices[0].deviceId;
        
        return Promise.all([
          AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedMicrophoneId }),
        ]);

      } else {
        audioDevices = devices.filter(function(device){
					return device.kind === "audioinput";
			  });
        videoDevices = devices.filter(function(device){
					return device.kind === "videoinput";
        });
        
        selectedMicrophoneId = audioDevices[0].deviceId;
        selectedCameraId = videoDevices[0].deviceId;

        return Promise.all([
          AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
          AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedMicrophoneId }),
        ]);
      }
			
		})
		.then(res => {
      if(type == 'audio') {
        const [audioTrack] = res;
  
        let options = {
          appId: appid,
          channel: channel,
          token: refreshToken,
          uid: uuid
        }
        startBasicCall(options, null, audioTrack, type).then(res => {
        //   socket.emit("doctor-call", { 
        //     userId: currentChat.patientId._id, 
        //     appointmentId: id, 
        //     // doctorId: currentUserId,
        //     // appointmentId: id,
        //     doctorName: currentChat.doctorId.name,
        //     doctorImage: currentChat.doctorId.profileImage ? currentChat.doctorId.profileImage : 'https://thehealthmaster.com/wp-content/uploads/2019/10/doctor-1699656_1920.jpg',
        //     callType: type
        //   })
        });
      } else {
        const [videoTrack, audioTrack] = res;
  
        let options = {
          appId: appid,
          channel: channel,
          token: refreshToken,
          uid: uuid
        }
        startBasicCall(options, videoTrack, audioTrack, type).then(res => {
        //   socket.emit("doctor-call", { 
        //     userId: currentChat.patientId._id, 
        //     appointmentId: id, 
        //     // doctorId: currentUserId,
        //     // appointmentId: id,
        //     doctorName: currentChat.doctorId.name,
        //     doctorImage: currentChat.doctorId.profileImage ? currentChat.doctorId.profileImage : 'https://thehealthmaster.com/wp-content/uploads/2019/10/doctor-1699656_1920.jpg',
        //     callType: type
        //   })
        });
      }
      
		
			setIsJoin(true);
		

		}).catch(e => {
			toast.error('Permission denied')
			dispatch({
				type: SET_ERROR,
				payload: 'Permission denied'
			});
		});

		// let options = {
    //   appId: appid,
    //   channel: channel,
    //   token: refreshToken,
    //   uid: uuid
    // }
		// startBasicCall(options).then(res => {
		// 	socket.emit("doctor-call", { 
		// 		userId: currentChat.patientId._id, 
		// 		appointmentId: id, 
		// 		// doctorId: currentUserId,
		// 		// appointmentId: id,
		// 		doctorName: currentChat.doctorId.name,
		// 		callType: type
		// 	})
		// });
		// setIsJoin(true);


	}

	const handleClickLeave = () => {
    leaveCall(callType)
		setIsJoin(false)
		// const endCall = {
		// 	appointmentId:  id,
		// 	role: 'Doctor',
		// 	doctorId: currentChat?.doctorId._id,
		// 	patientId: currentChat?.patientId._id,
		// };
		// socket.emit("endCall", endCall);
  }


  const unselected = () => {
		props.unSelect();
	}
	
	const sendFile = (event) => {
    var type = event.target.files[0].type;
    if (event.target.files[0]) {
      const formData = new FormData();
			formData.append("documents", event.target.files[0]);
			const user = getUser();
			loader(true);
			genrateFIleLinkAPI(formData).then(res => {
				loader(false);
		// 		const socketMsg = {
        //   document: res,
        //   documentType: type,
        //   appointmentId: id,
		// 			userId: user?._id,
		// 			receiverId: currentChat?.patientId._id,
		// 			phoneNumber: currentChat?.patientId.phoneNumber,
		// 			role: "Doctor",
		// 			email: user?.email,
		// 			name: user?.name,
		// 			link: "https://emed4u.com/patient/chat"
		// 		};
		// 		socket.emit("Send_Message_Chat", socketMsg);
			})

      // actions.genrateFIleLink(formData).then(res => {
      //   const socketMsg = {
      //     document: res,
      //     documentType: type,
      //     referralId: referralId,
      //     userId: id,
      //     role: role
      //   };
      //   socket.emit("Send_Message_Doctor", socketMsg);
      //   this.state.message = "";
      // });
    }
	};
	
	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const onEnter = (e) => {
		if (e.key === "Enter" && message) {
			const user = getUser();
    //   const socketMsg = {
    //     message: message,
    //     appointmentId: id,
	// 			userId: user?._id,
	// 			receiverId: currentChat?.patientId?._id,
	// 			phoneNumber: currentChat?.patientId?.phoneNumber,
    //     role: "Doctor",
    //     email: user?.email,
    //     name: user?.name,
    //     link: "https://emed4u.com/patient/chat"
    //   };
    //  socket.emit("Send_Message_Chat", socketMsg);
      setMessage("");
		}
	}

	const [onSetDelete, setOnSetDelete] = React.useState({
		appointmentId: null,
		userId: null,
		receiverId: null,
		msgId: null
	})

	const onSetMessage = (msg, currentChat) => {
		setOnSetDelete({
			appointmentId: currentChat._id,
			userId: msg.userId,
			receiverId: currentChat.doctorId._id,
			msgId: msg._id
		});
	}

	const onDeleteMsg = () => {
	//	socket.emit("delete_message", onSetDelete);
	}

	return (
		<Card className={`chat-cont-right chat-cont-dashboard ${className}`}>
			<div className="chat-header p-0">
				{
					mobile && <a id="back_user_list" href="javascript:void(0)" className="back-user-list" onClick={() => unselected()}>
								<i className="material-icons">chevron_left</i>
							</a>
				}
				
				<div className="media col-md-5">
					<div className="media-img-wrap">
						<div className="avatar">
							<img src={currentChat && currentChat.patientId.profileImage ? currentChat.patientId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" className="avatar-img rounded-circle" />
						</div>
					</div>
					<div className="media-body">
						<div className="user-name">{currentChat && currentChat.patientId.name}</div>
						
					</div>
				</div>
				<div className="chat-options col-md" style={{ alignItems: 'center', justifyContent: "flex-end" }}>

					<a href="javascript:void(0)" className="mr-1" data-toggle="modal" data-target="#video_call" onClick={() => allowVideo('audio')}>
						<i className="material-icons">call</i>
					</a>

					<a href="javascript:void(0)" data-toggle="modal" data-target="#video_call" onClick={() => allowVideo('video')}>
						<i className="material-icons">videocam</i>
					</a>
					<button className="ml-2 btn-patient-light doctor-active-btn bg-red" onClick={() => sendReport()}>
						Generate final report
						{/* Send final report */}
					</button>

				</div>
				</div>

			<div className="chat-body">
				<div className="chat-scroll" style={{height:"400px", overflow:"auto"}} ref={bottomRef}>
					<ul className="list-unstyled">
						{
							currentChat && currentChat.conversions.map(item => (
								item.role === 'Doctor' ? (
									<li className="media sent" key={item.id}>
										<div className="media-body">
											<div className="msg-box">
												<div>
													{
														item.text && <p style={{overflowWrap: 'anywhere'}}>{item.text}</p>
													}

													{
														item.documents && <a
																								target="_blank"
																								href={item.documents}
																								download
																								style={{ color: '#5156be' }}
																								>
																								Click here to show document
																							</a>
													}
															
													<ul className="chat-msg-info">
														<li>
															<div className="chat-time">
																<span>{moment(item.createdAt).fromNow()}</span>
															</div>
														</li>
													</ul>
												</div>

												<div className="ml-1" data-toggle="modal" data-original-title="test" data-target="#deleteChatModal" onClick={() => onSetMessage(item, currentChat)}>
													<i className="fa fa-trash-alt" />
												</div>

											</div>
										</div>
									</li>
								) : (
									<li className="media received" key={item.id}>
										<div className="avatar">
												<img src={currentChat.patientId.profileImage ? currentChat.patientId.profileImage : '/assets/img/doctor-empty.png'} alt="User Image" className="avatar-img rounded-circle" />
											</div>
											<div className="media-body">
												<div className="msg-box">
													<div>
														{
															item.text && <p style={{overflowWrap: 'anywhere'}}>{item.text}</p>
														}

														{
															item.documents && <a
																target="_blank"
																href={item.documents}
																download
																style={{ color: '#5156be' }}

															>
																Click here to show document
															</a>
														}

														<ul className="chat-msg-info">
															<li>
																<div className="chat-time">
																	<span>{moment(item.createdAt).fromNow()}</span>
																</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</li>
									)
							))
						}
					{/* {
						chat.map(item => 
					} */}
					</ul>
					</div>
				</div>
				<div className="chat-footer">
					<div className="input-group">
						<div className="input-group-prepend">
							<div className="btn-file btn">
								<i className="fa fa-paperclip"></i>
								<input
									type="file"
									ref={fileInput}
									onChange={sendFile}
								/>
							</div>
						</div>
						<input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} />
						<div className="input-group-append">
							<button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button>
						</div>
					</div>
				</div> 

			<div class="modal fade call-modal" id="video_call">
				<div class="modal-dialog video-call-modal modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-body">

					<div class="call-wrapper">
						<div class="call-main-row">
							<div class="call-main-wrapper">
								<div class="call-view">
									<div class="call-window">
									
										<div class="fixed-header">
											<div class="navbar">
												<div class="user-details">
													<div class="float-left user-img">
														<a class="avatar avatar-sm mr-2" title="Charlene Reed">
															<img src={currentChat && currentChat.patientId.profileImage ? currentChat.patientId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" class="rounded-circle" />
															<span class="status online"></span>
														</a>
													</div>
													<div class="user-info float-left">
														<a><span>{currentChat && currentChat.patientId.name}</span></a>
													</div>
												</div>
											</div>
										</div>
										
										<div class="call-contents">
											<div class="call-content-wrap">
												<div class="user-video">
												{/* {	errMessage && <h1 style={{color: 'red', padding: 25}}> {errMessage}</h1>} */}
                          {/* <img src="/assets/img/video-call.jpg" alt="User Image" /> */}
                          
                          {
                            callType === 'audio' ?
                              <img src="/assets/img/audio.png" class="img-fluid" alt="User Image" /> 
                            :
                              <div className='video-agora-box'>
                              </div>
                          }

														
													
												</div>
												<div class="my-video">
													<ul>
														<li style={{height: isMobile ? '50px' : '120px'}}>
                              {
                                callType === 'audio' ?
                                  <img src="/assets/img/audio.png" class="img-fluid" alt="User Image" /> 
                                :
                                <div id='video-agora-local'></div>
                              }
															{/* <img src="/assets/img/audio.png" class="img-fluid" alt="User Image" /> */}
															
														</li>
													</ul>
												</div>
											</div>
										</div>
										
										<div class="call-footer">
											<div class="call-icons">
												<ul class="call-items">
													{/* {videoSetting && <li class="call-item">
														<a href="javascript:void(0);" title="Enable Video" data-placement="top" data-toggle="tooltip" >
															<i class="fas fa-video camera"></i>
														</a>
													</li>} */}
													{/* <li class="call-item">
														<a href="javascript:void(0);" title="Mute Audio" data-placement="top" data-toggle="tooltip" >
															<i class="fa fa-microphone microphone"></i>
														</a>
													</li> */}
												</ul>
												<div class="end-call" style={{top: '-10px'}}>
													<a href="javascript:void(0);"  class="btn call-item call-end" data-dismiss="modal" aria-label="Close" onClick={() => handleClickLeave()}>
														<i class="material-icons">call_end</i>
													</a>
												</div>
											</div>
										</div>
										
									</div>
								</div>
								
							</div>
						</div>
					</div>
						
						
					</div>
				</div>
			</div>
		</div>
			
		<div class="modal fade" id="deleteChatModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div class="modal-dialog" role="document">
						<div class="modal-content">
								<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel">Are you really want to delete this message?</h5>
										<button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""><span aria-hidden="true">×</span></button>
								</div>
								{/* <div class="modal-body">...</div> */}
								<div class="modal-footer">
										
										<button class="btn btn-primary" type="button" data-dismiss="modal" data-original-title="" title="" onClick={() => onDeleteMsg()}>Yes</button>

										<button class="btn btn-danger" type="button" data-dismiss="modal" data-original-title="" title="">No</button>
								</div>
						</div>
				</div>
			</div>
			
				{
					isModalVisible && 
					<DoctorFinalReport 
						setIsModalVisible={setIsModalVisible} 
						blankMedication={blankMedication} 
						setFields={setFields}  
						report={report} 
						setReport={setReport} 
						fields={fields}
						apptId={appId}
						setAppointment={setAppointment}
					/>
				}
			
		</Card>

	)
}

export default ChatBodyDashBoard 

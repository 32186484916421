import React, { useEffect, useState } from "react";
import { profileStyles } from "../../../../assets/styles/DoctorProfileSettingStyles";
import { CircularProgress, Grid } from "@mui/material";
import { Formik } from "formik";
import FieldWrapper from "../../../InputField/TextFieldWrapper";
import PhoneField from "../../../PhoneField";
import { StyledSecondaryButton } from "../../../../assets/styles/CommonStyles";
import strings from "../../../../constant/strings";
import { subadminProfile } from "../../../../utils/forms/schema.profileSettings";
import { useDispatch, useSelector } from "react-redux";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import {
  designationLists,
  genderLists,
} from "../../../../utils/GenericFunctions";
import SelectionDropdown from "../../../control/SelectionDropdown";
import { permission, status } from "../../../../constant/staticLists";
import { updateProfile } from "../../../../services/authService";
import { toast } from "react-toastify";
import { subadminProfileAction } from "../../../../redux/user/subadmin/subadminAction";

const SubadminDetails = () => {
  const dispatch =  useDispatch();
  const styleClasses = commonMuiStyles();
  const profileSettingClasses = profileStyles();
  const [onLoading, setLoading] = useState(false);

  const { userData, subadminProfileData } = useSelector((state) => ({
    subadminProfileData: state.subadmin.subadminProfileData,
    userData: state.auth.userData
  }));

  let initialValues = {

    email: subadminProfileData?.email || "",
    name: subadminProfileData?.name || "",
    gender: subadminProfileData?.gender || "",
    address: subadminProfileData?.address || "",
    country_code: subadminProfileData?.country_code || '',
    phone_number:`${subadminProfileData?.phone_number}` ||"",
    designation: subadminProfileData?.designation || "",
    permission: subadminProfileData?.permission || '',
    status: subadminProfileData?.status || '',
  };

  const handleSubmit = async (values, {resetForm}) => {
   const {email, gender, designation, permission, status, ...data} = values
    const res = await updateProfile(data, userData.token);
    if (res.response_type === "fail") {
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      resetForm();
      dispatch(subadminProfileAction(userData.token));
      toast.success(
        "Profile updated successfully",
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 2000,
          style: { width: "auto", borderRadius: 8 },
        }
      );
    }
  };

  return (
    <>
      <div className={`${profileSettingClasses.mainCont}`}>
        <Grid
          container
          spacing={2}
          className={profileSettingClasses.formikCont}
          width="70%"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={subadminProfile}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
              setTouched,
              handleChange,
            }) => {
              const handlePhoneNumber = (number, code) => {
                setFieldValue("phone_number", number);
                setFieldValue("country_code", code);
              };

              const handleDropdownValue = (data, fieldName) => {
                setFieldValue(fieldName, data.level);
              };

              return (
                <>
                  <Grid item lg={6} md={6} xs={12}>
                    <FieldWrapper
                      type="text"
                      name="name"
                      label={`${strings.enterSubadminName}`}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <FieldWrapper
                      type="text"
                      name="email"
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <FieldWrapper
                      type="text"
                      name="org_name"
                      label={`${strings.orgName}`}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <PhoneField
                      name="phone_number"
                      label="Enter mobile number"
                      value={`${values.country_code} ${values.phone_number}`}
                      onChange={(value, { dialCode }) => {
                        handlePhoneNumber(
                          value.slice(dialCode.length),
                          dialCode
                        );
                      }}
                      onBlur={() =>
                        setTouched({ ...touched, phone_number: true })
                      }
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <SelectionDropdown
                      height={"48px"}
                      className={styleClasses.customAutocomplete}
                      name="gender"
                      value={values.gender}
                      disabled
                      //label={`${strings.selectGender}`}
                      list={genderLists}
                      // handleDropdownValue={(data) =>
                      //   handleDropdownValue(data, "gender")
                      // }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <SelectionDropdown
                      height={"48px"}
                      className={styleClasses.customAutocomplete}
                      name="designation"
                      value={values.designation}
                      disabled
                      //label={`${strings.selectDesignation}`}
                      list={designationLists}
                      // handleDropdownValue={(data) =>
                      //   handleDropdownValue(data, "designation")
                      // }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <SelectionDropdown
                      height={"48px"}
                      className={styleClasses.customAutocomplete}
                      name="permission"
                      value={values.permission}
                      disabled
                      //label={`${strings.selectPermission}`}
                      list={permission}
                      // handleDropdownValue={(data) =>
                      //   handleDropdownValue(data, "permission")
                      // }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <SelectionDropdown
                      height={"48px"}
                      className={styleClasses.customAutocomplete}
                      name="status"
                      value={values.status}
                      disabled
                      //label={`${strings.selectStatus}`}
                      list={status}
                      // handleDropdownValue={(data) =>
                      //   handleDropdownValue(data, "status")
                      // }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper
                      type="text"
                      name="address"
                      label={`${strings.selectAddress}`}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <StyledSecondaryButton
                      disabled={Object.keys(errors).length > 0}
                      style={{ marginBottom: 24 }}
                      onClick={handleSubmit}
                    >
                      {onLoading ? (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={20}
                          thickness={5}
                        />
                      ) : (
                        " Update Profile"
                      )}
                    </StyledSecondaryButton>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </Grid>
      </div>
    </>
  );
};

export default SubadminDetails;

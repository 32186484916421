import styled from 'styled-components';

export const StyledTestimonialWrapper = styled.div`
   width: 680px !important;
   @media (max-width: 767px){
    width: 300px !important;
  }
`;

export const StyledTestimonialContent = styled.div`
  text-align: center;
  display: flex !important;
  min-height : 220px;
  .pro-content {
      text-align: center;
  }
`;
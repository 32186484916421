import { FormControlLabel, Grid, Radio, FormControl, RadioGroup, Box } from "@mui/material";
import React, { useState } from 'react'
import { DeptHeading } from '../../../../../../assets/styles/CommonStyles';
import { useStyles } from './RadioButtonStyle';
import styled from "styled-components";
import colors from "../../../../../../constant/colors";
import { useEffect } from "react";

const RadioContainer =  styled(Grid)`
border: 2px solid;
border-radius: 8px;
font-family: Poppins;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
`;


const RadioButtonBox = ({data, setPractictionerRange, practictioner_range}) => {
    const classes= useStyles();
    const [selectedValue, setSelectedValue] = useState('');

    const handleRange = (item) =>{
    setSelectedValue(item.range)

    switch(item.value){
      case '1': setPractictionerRange({from:1, to: 1}); break;
      case '5': setPractictionerRange({from:1, to: 5}); break;
      case '20': setPractictionerRange({from:5, to: 20}); break;
      case '50': setPractictionerRange({from:20, to: 50}); break;
      case '100': setPractictionerRange({from:50, to: 100}); break;
      case '500': setPractictionerRange({from:100, to: 500}); break;
    }
    }   
              
  return (    
      <Grid container spacing={2} justifyContent='center'>
          {data.map((item, index)=> <RadioContainer item xs={3.8} key={index}
          className={`${(selectedValue === item.range) ? classes.selectedRadio: ''}`}
          style={{margin: '2px 2px'}}
          >          
                <FormControlLabel
                control={<Radio checked={selectedValue === item.range } 
                // onChange={()=>setSelectedValue(item.range)} 
                onChange={()=>handleRange(item)}
                 hidden />}
                label= { <div> {item.range} </div>} 
             />
        </RadioContainer>)}
        </Grid>    

  )
}

export default RadioButtonBox;


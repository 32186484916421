import { object, string, number , ref} from "yup";


export const loginSchema = object({
  email: string()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required"),
  password: string()
    .required('Please Enter your password')
    .min(8, "Minimum 5 characters required"),
    
  phone: number()
    .positive("The number must be positive")
    .integer("The number must be an integer")
    .typeError("Please enter a valid phone number")
    // .required("Phone number required"),
});

    // .min(8, "Minimum 7 characters required")
    // .required("Password is required"),

export const forgotPasswordSchema = object({
  email: string()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required")
});

export const resetPasswordSchema = object({
  password: string()
  .required('Please Enter your password')
  .min(7, "Minimum 7 characters required")
  .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,30})/,
      "Must Contain 5 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: string()
  .required("Please re-type your password")
  .oneOf([ref("password")], "Passwords does not match"),
});
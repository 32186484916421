import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import styled from 'styled-components'
import { resetPasswordSchema } from '../../utils/forms/schema.loginSchema';
import { Grid, CircularProgress, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import FieldWrapper from '../InputField/TextFieldWrapper';
import { StyledText, StyledSecondaryButton } from '../../assets/styles/CommonStyles';
import strings from '../../constant/strings'
import { commonMuiStyles } from '../../assets/styles/CommonMUIStyles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import * as authService from '../../services/authService.js';
import { toast } from 'react-toastify';
import colors from '../../constant/colors';

const StyledCont = styled.div`    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const StyledDiv = styled.div`
    box-shadow: 4px 4px 15px 0px rgba(192, 192, 192, 0.25), -4px -4px 15px 0px rgba(192, 192, 192, 0.25);
    overflow: auto;
    display: flex-wrap;
    flex-direction: column;
    background: ${colors.white};
    border-radius: 8px;
    min-width: 300px;
    max-width: 600px;
`;

const StyledTopCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 60px;
    padding-bottom: 20px;
    border-bottom: 1.5px solid #F5F5F7`
  ;

const ContDiv = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .padding-cont {
        padding: 25px 80px;
        @media only screen and (max-width: 414px) {
            padding: 25px;
        }
    }
    .padding-cont2 {
        padding-top: 10px; 
    }
`;

const StyledImg = styled.img`
    height: 40px;
    width: auto;
`;

const StyledTextCont = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const ForgotPassword = () => {
  const classes = commonMuiStyles();
  const history = useHistory();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [token, setToken] = useState("")
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    const queryParams = new URLSearchParams(window.location.search).get('token');
    setToken(queryParams)
    const res = await authService.verifyTokenAPI(queryParams);
    if (res.response_type === "fail") {
      setOpen(true);
    }
  }, [])

  const handleUpdatePassword = async (values) => {
    setButtonLoading(true);
    delete values.confirmPassword;
    const res = await authService.updatePasswordAPI({ password: values, token: token });
    if (res.response_type === "fail") {
      setButtonLoading(false);
      toast.error(res?.response?.message, { position: "bottom-center", closeButton: false })
    } else {
      setButtonLoading(false);
      history.push('/');
    }
  }

  const handleSignIn = () => {
    history.push({
      pathname: '/',
      state: { isLoginPopup: true }
    })
  }

  return (
    <>
      <StyledCont>
        <StyledDiv>
          <StyledTopCont>
            <StyledImg
              src={"/assets/img/image1.png"}
              alt="User" />
          </StyledTopCont>
          <ContDiv >
            <div className='padding-cont' >
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: ""
                }}
                validationSchema={resetPasswordSchema}
                onSubmit={(values) => handleUpdatePassword(values)}>
                {({ errors, handleSubmit }) => (
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FieldWrapper
                          name="password"
                          label="Enter new Password" />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldWrapper
                          name="confirmPassword"
                          label="Confirm Password" />
                      </Grid>
                    </Grid>
                    <ContDiv>
                      <div className='padding-cont2'>
                        <StyledSecondaryButton
                          name="submit"
                          disabled={Object.keys(errors).length > 0 || buttonLoading}
                          onClick={handleSubmit}
                          variant="contained"
                          sx={{ marginTop: "10px" }}>
                          {buttonLoading ? (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={20}
                              thickness={5} />
                          ) : "Submit"}
                        </StyledSecondaryButton>
                      </div>
                    </ContDiv>
                  </div>
                )}
              </Formik>
            </div>
          </ContDiv>
          <StyledTextCont>
            <StyledText className={classes.textStyle}>{strings.haveAnAccount}{' '}
              <span className="sign-color" onClick={handleSignIn} >{strings.signInNow}</span></StyledText>
          </StyledTextCont>

        </StyledDiv>
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Reset link has been expired. Click Okay to reset again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSignIn}>OKAY</Button>
            </DialogActions>
          </Dialog>
        </div>
      </StyledCont>
    </>
  )
}

export default ForgotPassword;
import {object, string, number, array} from 'yup';

export const addMedicineSchema = object({
    name: string()
    .min(4, 'length is too short')
    .max(40, 'length is too long')
    .required('name is required'),
    form: string()
    .required('Form of medicine is required'),
    strength: number()
    .typeError('Strength should be number')
    .min(0, 'Strength cannot be less than 0')
    .required('Medicine strength is required'),
    strengthType: string()
    .required('Medicine strength type is required'),
    duration: string()
    .required('Time interval type is required')

})
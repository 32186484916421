import React, { useContext, useEffect } from 'react';

import moment from 'moment';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getTermsAPI,
	addTermsAPI,
	updateTermsAPI
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import CardComponent from '../../../../components/Card';
import InsideMenuBar from '../../../../components/InsideMenus';

function OrgTerms(props) {
	const contentBlock = htmlToDraft(`
		<h1>Hello...</h1>
	`);
	const contentState = ContentState.createFromBlockArray(
		contentBlock.contentBlocks
	);

	const [editorState, setEditorState] = React.useState(EditorState.createWithContent(contentState));//React.useState(EditorState.createEmpty());

	const onEditorStateChange = (editorStates) => {
		
		if(editorStates && editorStates.getCurrentContent()) {
			// setEditorState(draftToHtml(convertToRaw(editorStates.getCurrentContent())))
		}
		
	}

	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">

							<CardComponent className="p-4 mb-4">
								<Editor
									wrapperStyle={{ minHeight: 200 }}
									editorState={editorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									toolbar={{
										inline: { inDropdown: true },
										list: { inDropdown: true },
										textAlign: { inDropdown: true },
										link: { inDropdown: true },
										history: { inDropdown: true },
										// image: {
										// 	uploadCallback: this.uploadImageCallBack,
										// 	alt: { present: true, mandatory: true }
										// }
									}}
									editorClassName="editorClassName"
									onEditorStateChange={(editorStates) => onEditorStateChange(editorStates)}
								/>
							</CardComponent>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default OrgTerms
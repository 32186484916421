import React, {useEffect, useContext, useState} from 'react';

import InputField from '../../../components/InputField';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	postEmergencyContactLoginAPI
} from '../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import { globalValidator, saveUser, isLoggedIn } from "../../../utils";

import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledLoginButton,
} from './styles';
import PhoneInput from 'react-phone-number-input';

function EmergencyContactLogin(props) {


	const [state, dispatch] = useContext(Context);

	const [orgUser, SetOrgUser] = useState({emergency_number: ''});

	const login = (e) => {
		e.preventDefault();
		const { emergency_number} = orgUser;
    const keys = [
      { name: "emergency_number", title: "Contact number", validateKey: "required" }
    ];
		let valid = globalValidator({ emergency_number }, keys);

		if(valid === true) {
			loader(true);
			postEmergencyContactLoginAPI(orgUser).then(res => {
				saveUser(res.data);
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				
				props.history.push('/emergency-contact/health-parameters');
					
				
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

		const onChange = (e) => {
		SetOrgUser((user) => ({...user, emergency_number: e.target.value}))
	}

	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
              <StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Emergency Contact</StyledLeftHeading>
										<StyledLeftSubHeading>
											Check your relative health data.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="http://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>

							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">
											<form className="form-border info-widget">
												<div className="form-group card-label">
													<label>Contact number</label>
													{/*<InputField 
														value={orgUser.emergency_number} 
														onChange={(e) => onChange(e)}  
													/>*/}
													<PhoneInput
														placeholder="Enter phone number"
														international
														defaultCountry="IN"
														value={orgUser.emergency_number} 
														onChange={(e) => onChange(e)} 
													/>
													
													</div>
									
												<StyledLoginButton onClick={(e) => login(e)}>
													Submit
												</StyledLoginButton>
											</form>
										</div>
									</div>
								</div>
							</StyledRightPanel>
            </div>
          </div>
        </div>
		  </div>
	  </section>  
	)
}

export default EmergencyContactLogin

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: null,
    roomList: [],
    admittedPatient: [],
    admittedPatientService: []
  };

  const ipdSlice = createSlice({
    name: 'ipd',
    initialState,
    reducers: {

      //room list
      getRoomRequest(state) {
        state.roomList = [];
        state.loading = true;
        state.error = null;
      },
      getRoomSuccess(state, { payload }) {
        state.roomList = payload;
        state.loading = false;
        state.error = null;
      },
      getRoomFailure(state, { payload }) {
        state.roomList = [];
        state.loading = false;
        state.error = payload;
      },

      //admitted patient list
      getAdmittedPatientRequest(state) {
        state.admittedPatient = [];
        state.loading = true;
        state.error = null;
      },
      getAdmittedPatientSuccess(state, { payload }) {
        state.admittedPatient = payload;
        state.loading = false;
        state.error = null;
      },
      getAdmittedPatientFailure(state, { payload }) {
        state.admittedPatient = [];
        state.loading = false;
        state.error = payload;
      },

      //admitted patient service
      getAdmittedPatientServiceRequest(state) {
        state.admittedPatientService = [];
        state.loading = true;
        state.error = null;
      },
      getAdmittedPatientServiceSuccess(state, { payload }) {
        state.admittedPatientService = payload;
        state.loading = false;
        state.error = null;
      },
      getAdmittedPatientServiceFailure(state, { payload }) {
        state.admittedPatientService = [];
        state.loading = false;
        state.error = payload;
      },

    },
  });
  
  export const { getRoomRequest, getRoomSuccess, getRoomFailure, getAdmittedPatientRequest, getAdmittedPatientSuccess, getAdmittedPatientFailure, getAdmittedPatientServiceRequest, getAdmittedPatientServiceSuccess, getAdmittedPatientServiceFailure  } = ipdSlice.actions;
  
  export default ipdSlice.reducer;
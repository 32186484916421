import React, { useState } from "react";
import IPDlist from "../../IPD/IPDlist";
import IPDPatientDetails from "../../IPDPatientDetails.js";

const SubadminIPD = () => {
  const [screen, setScreen] = useState(0)
  const [selectedListItem, setSelectedListItem] = useState('');

  const handleForward = () => {
    setScreen((prev)=> prev+1)
  }
  const handleBackward = () => {
    setScreen((prev)=> prev-1)
  }

  return (
    <>
    {screen === 0 ? <IPDlist handleForward = { handleForward } handleBackward = {handleBackward} setScreen={setScreen} setSelectedListItem = {setSelectedListItem} /> : 
    screen === 1 ? <IPDPatientDetails handleBackward = {handleBackward} handleForward = { handleForward } selectedListItem = {selectedListItem} setSelectedListItem = {setSelectedListItem} /> : '' }      
    </>
  );
};

export default SubadminIPD;

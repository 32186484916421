import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import { resetDoctoPassword } from "../../utils/forms/schema.doctorProfileSettingSchema";
import FieldWrapper from "../InputField/TextFieldWrapper";
import strings from "../../constant/strings";
import { profileStyles } from "../../assets/styles/DoctorProfileSettingStyles";
import { Formik } from "formik";
import { StyledSecondaryButton } from "../../assets/styles/CommonStyles";
import { resetOrgPasswordAction, resetPasswordAction } from "../../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";

const ResetPassword = ({onLoading, setLoading}) => {
  const dispatch = useDispatch();
  
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));

  const profileSettingClasses = profileStyles();
  let initialValues1 = {
    password: "",
    newPassword: "",
  };

  const handleSubmit = async(values) => {
    delete values.confirmPassword;
    dispatch(resetOrgPasswordAction(values, userData.token))
  }

  return (
    <>
      <div className={`${profileSettingClasses.mainCont}`}>
        <Grid
          container
          spacing={2}
          className={profileSettingClasses.formikCont}
          width="70%"
        >
          <Formik
            initialValues={initialValues1}
            validationSchema={resetDoctoPassword}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, handleSubmit, errors }) => {
              return (
                <>
                  <Grid item xs={12}>
                    <FieldWrapper
                      name="password"
                      label={`${strings.enterCurrentPassword}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper
                      name="newPassword"
                      label={`${strings.enterNewPassword}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper
                      name="confirmPassword"
                      label={`${strings.enterConfirmPassword}`}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <StyledSecondaryButton
                      disabled={Object.keys(errors).length > 0}
                      style={{ marginBottom: 24 }}
                      onClick={handleSubmit}
                    >
                      {onLoading ? (
                      <CircularProgress
                        style={{ color: "white" }}
                        size={20}
                        thickness={5}
                      />
                    ) : (
                     'Update Password'
                    )}
                      
                    </StyledSecondaryButton>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </Grid>
      </div>
    </>
  );
};

export default ResetPassword;

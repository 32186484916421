import React, { useMemo, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Grid, IconButton, Paper, Tab, Tabs, Typography, CircularProgress } from '@mui/material';
import styled from "styled-components";
import { Box } from "@mui/system";
import MedicalHistory from "../MedicalHistory";
import HealthParameters from "../HealthParameters";
import PatientDetails from "../PatientDetails";
import { useSelector } from "react-redux";
import * as authService from "../../../services/mainService";
import { toast } from "react-toastify";
import { StyledLogo } from "../../../assets/styles/CommonStyles";
import { useStyles } from "../../../assets/styles/SidebarStyles";


const Container = styled(Grid)(({ theme }) => ({
    '& .MuiPaper-root': {
        width: '100%',
        minHeight: 'calc(100vh - 240px)',
        marginBottom: 1,
    },
    '& .MuiTypography-root': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
    },
    '& .pagetitle': {
        paddingTop: 70,
        '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: '32px',
            lineHeight: '42px',
            color: '#2B2B2B',
            '& + .MuiTypography-root': {
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '18px',
                color: '#5E5E5E'
            }
        },
        '& + .MuiGrid-root': {
            // paddingTop: 0,
        }
    }
}))

const StyledTabs = styled(Tabs)(({ theme, selectedItem, index }) => ({
    borderBottom: '1.2px solid #F5F5F7',
    '& .MuiTab-root': {
        justifyContent: 'start',
        alignItems: 'start',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '18px',
        color: '#5E5E5E',
    },
    '& .Mui-selected': {
        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        border: '1px solid',
        borderImageSlice: 1,
        borderWidth: '0px 0px 1.2px',
        borderImageSource: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)'
    }
}))

const StyledGrid = styled(Grid)`
&.css-480o17-MuiGrid-root>.MuiGrid-item {
    margin-left: '70px';
}
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3, ...index === 2 && { pt: 0 } }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const UserDetails = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0)
    const [qrData, setQRData] = useState([]);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const queryParams = new URLSearchParams(window.location.search)
    const token = "token=" + queryParams.get('token');
    const { qrCodeDetails, userData } = useSelector((state) => ({
        qrCodeDetails: state.user.qrCodeDetails,
        userData: state.auth.userData
    }));
    const payroleId = useMemo(() => qrCodeDetails, [qrCodeDetails])

    useEffect(async () => {
        const res = await authService.qrcodeDetailPageAPI(token);
        if (res.response_type === "fail") {
            toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        } else {
            setQRData(res?.response?.data)            
        }
    }, [])

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container container spacing={4}>
            {/* <div style={{ marginTop: '40px' }}>
                <StyledLogo
                    src={"/assets/img/image1.png"}
                    className={classes.iconStyle}
                    alt="User"
                />
            </div> */}

            <div className={classes.titleCont} style={{ marginTop: '40px', marginLeft: '70px' }}>
                <StyledLogo
                    src={"/assets/img/image1.png"}
                    alt="User"
                />
            </div>
            <Grid item xs={12} className="pagetitle" sx={{ marginLeft: '40px' }}>
                <Typography>Medical Details</Typography>
            </Grid>
            <Grid item xs={12} margin="0px 40px">
                <Grid container spacing={0} sx={{ paddingBottom: '20px' }}>
                    <Paper elevation={1}>
                        <Grid item xs={12}>
                            <StyledTabs value={value} onChange={handleChange} variant="scrollable">
                                {['Patient Details', 'Medical History', 'Health Parameters'].map((item, index) =>
                                    <Tab label={item} {...a11yProps(index)} />
                                )}
                            </StyledTabs>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingLeft: '16px' }}>
                            <TabPanel value={value} index={0}>
                                {qrData.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                                    <CircularProgress
                                        size={50}
                                        thickness={5}
                                         /> </div> : <PatientDetails data={qrData.PersonalDetails} />}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <MedicalHistory data={qrData.MedicalHistory} />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <HealthParameters data={qrData.HealthParameters} />
                            </TabPanel>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default UserDetails;
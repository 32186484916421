import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HeadingComponent from "../../HeadingComponent";
import strings from "../../../../constant/strings";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";
import { profileStyles } from "../../../../assets/styles/DoctorProfileSettingStyles";
import { StyledCrossIcon, StyledSecondaryButton, UserImage } from "../../../../assets/styles/CommonStyles";
import { useHistory } from 'react-router-dom';
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import { genderList, settingTabs } from "../../../../constant/staticLists";
import defaultUserPng from "../../../../assets/img/user.png"
import TabComponent from "../../../Tab";
import { doctorProfileSchema, resetDoctoPassword } from "../../../../utils/forms/schema.doctorProfileSettingSchema";
import PhoneField from "../../../PhoneField";
import { Formik } from "formik";
import FieldWrapper from "../../../InputField/TextFieldWrapper";
import SelectionDropdown from "../../../control/SelectionDropdown";
import { capitalizeFirstLetter } from "../../../../constant/staticHelper";
import { doctorProfileAction, updateDoctorProfileAction } from "../../../../redux/user/doctor/doctorAction";
import { useDispatch, useSelector } from "react-redux";
import * as mainService from "../../../../services/mainService.js";
import { toast } from "react-toastify";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UploadFile from "../../../controls/UploadFile.js";
import { doctorlogoutAction } from "../../../../redux/auth/authActions.js";

import axios from "axios";
import { DOCTOR_UPLOAD } from "../../../../services/apiUrl.js";
const DoctorProfileSetting = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const styleClasses = commonMuiStyles();
  const profileSettingClasses = profileStyles();
  const inputRefs = useRef();
  const [onLoading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [fileType, setFileType] = useState();
  const [values, setValues] = useState();
  const [tabValue, setTabValue] = useState(0);
  const { doctorProfileData, userData } = useSelector((state) => ({
    userData: state.auth.userData,
    doctorProfileData: state.doctor.doctorProfileData,
  }));
const url = DOCTOR_UPLOAD
  let initialValues = {
    name: doctorProfileData?.name || '',
    profile_image: doctorProfileData?.profile_image || '',
    gender: doctorProfileData?.gender || '',
    phone_number: doctorProfileData?.phone_number || '',
    country_code: doctorProfileData?.country_code || '',
    address: doctorProfileData?.address || '',
    signature: doctorProfileData?.signature || '',
  }

  let initialValues1 = {
    password: '',
    newPassword: '',
  }
  useEffect(()=>{
    if (image){
      handleSubmit()
    }
  },[fileType])
console.log("image",image)
  // useEffect(() => {
  //   const data = { name: "Doctor144",
  //   address: "jaipur",
  //   dob: "2023-09-14",
  //   gender: "1",
  //   profile_image: "abc",
  //   phone_number: "787687",
  //   country_code: "+91" ,  
  //     signature: "ttttt"}

  //   dispatch(updateDoctorProfileAction({ token: userData.token,data:data }))
  // },[]);

  useEffect(() => {
    dispatch(doctorProfileAction(userData.token))

  }, [])

  const genderLists = Object.keys(genderList).map((key) => ({
    label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
    level: genderList[key],
  }));

  const handleCross = () => {
    history.goBack();
  }

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleValues = async (values, { resetForm }) => {
    if(tabValue ===1){
      delete values.confirmPassword;
    }
    // dispatch(updateDoctorProfileAction({ token: userData.token, data: values }))
    const res = await mainService.updateProfileByDoctorAPI({ token: userData.token, data: values });
    if (res.response_type === "fail") {
      toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
    } else {
      resetForm();
      dispatch(doctorProfileAction(userData.token))
      toast.success(tabValue === 0 ? "Profile updated successfully.": "Password updated successfully.", { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
    }
  }
  const handleSubmit = async (event) => {
    // event.preventDefault();
    
    const formData = new FormData();
    formData.append('file', image);

    try {
     
      const response = await mainService.fileUpload(url, formData,userData.token) 
      // const response = await axios.post('https://dev.emed4u.com/api/v1/doctor/upload-file', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     'Accept':'application/json',
      //     'Authorization': `Bearer ${userData.token}`, // Include the token in the headers
      //   },
      // });
     
      await setValues(response?.response?.data)
      if (image){
        await  handleSubmitupdate(response?.response?.data)
      }
  
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleSubmitupdate = async (values) => {
    setLoading(true);
    const profileimg = {
      profile_image:values
    }
    console.log("profileimg",profileimg)
    
    const res = await mainService.updateProfileByDoctorAPI({
      token: userData.token,
      data: profileimg,
    });
    console.log("res",res)
   
    if (res.response_type === "fail") {
      setLoading(false);
      console.log("jjj")
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      setLoading(false);
      await dispatch(doctorProfileAction(userData.token));
      toast.success("Profile updated successfully.", {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
      dispatch(doctorlogoutAction(userData.token))
    }

  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={`${classes.mainCont} ${profileSettingClasses.mainSecCont}`}>
        <HeadingComponent navigate="doctor_profile" text={{ primary: strings.doctorsProfile }} />
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="x"
          onClick={handleCross}
        />
      </Grid>
      <Grid item xs={12} className={profileSettingClasses.imageCont}>
        <div>
          <IconButton
            sx={{
              position: "absolute",
              // top: "200px",
              right: "calc(50% - 55px)",
            }}
          >
            <BorderColorIcon onClick={() => inputRefs.current.click()} />
          </IconButton>

          <UploadFile
            inputRefs={inputRefs}
            image={image}
            setImage={setImage}
            fileType={fileType}
            setFileType={setFileType}
            limit={5}
            navigate="single"
          />
          <UserImage
          // url={userData.logo}
            // src={ (userData.logo) || defaultUserPng }
            src={image||userData.profile_image || defaultUserPng}
            className={profileSettingClasses.imageStyle}
            alt="Doctor Profile"
          />
        </div>
      </Grid>
     
      <Grid item xs={12}>
        <TabComponent tabs={settingTabs} tabValue={tabValue} setTabValue={setTabValue} handleTab={handleTab}
          customTab={`${styleClasses.customTab} ${classes.tabFontSize}`}
          customTabIndicator={styleClasses.customTabIndicator}
        />
      </Grid>
      <Grid container spacing={2} className={profileSettingClasses.formikCont}>
        <Formik
          initialValues={tabValue === 0? initialValues: initialValues1}
          validationSchema={tabValue === 0 ? doctorProfileSchema : resetDoctoPassword}
          onSubmit={handleValues}
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldValue, errors, touched, setTouched, handleChange }) => {
            const handlePhoneNumber = (number, code) => {
              setFieldValue("phone_number", number);
              setFieldValue("country_code", code);
            }
            const handleDropdownValue = (data, fieldName, values) => {
              if (data) {
                if (fieldName == "gender") {
                  const ids = data.level;
                  setFieldValue("gender", ids);
                }
              }
            }
            return (
              <> {tabValue === 0 ?
                <>
                  <Grid item lg={4} md={6} xs={12}>
                    <FieldWrapper
                      type="text"
                      name="name"
                      label={`${strings.doctorName}`}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <PhoneField
                      name="phone_number"
                      value={`${values.country_code} ${values.phone_number}`}
                      onChange={(value, { dialCode }) => {
                        handlePhoneNumber(value.slice(dialCode.length), dialCode)
                      }}
                      onBlur={() => setTouched({ ...touched, phone_number: true })}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <SelectionDropdown
                      height={"48px"}
                      className={styleClasses.customAutocomplete}
                      name="gender"
                      value={values.gender}
                      label={`${strings.selectGender}`}
                      list={genderLists}
                      handleDropdownValue={(data) =>
                        handleDropdownValue(data, "gender")
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper
                      type="text"
                      name="address"
                      label={`${strings.selectAddress}`}
                    />
                  </Grid>

                </>
                :
                <>
                  <Grid item xs={12}>
                    <FieldWrapper
                      name="password"
                      label={`${strings.enterCurrentPassword}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper
                      name="newPassword"
                      label={`${strings.enterNewPassword}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper
                      name="confirmPassword"
                      label={`${strings.enterConfirmPassword}`}
                    />
                  </Grid>
                </>
              }
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <StyledSecondaryButton
                    disabled={Object.keys(errors).length > 0}
                    style={{ marginBottom: 24 }}
                    onClick={handleSubmit}
                  >
                    Update Profile
                  </StyledSecondaryButton>
                </Grid>
              </>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default DoctorProfileSetting;
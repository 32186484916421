import { Grid, Stack, Paper, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from "react";
import { BarChart, Bar, XAxis, YAxis } from 'recharts';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';

import DownloadIcon from '../../../assets/img/DownloadIcon'
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';

const DateRangeGrid = styled(Grid)(({ }) => ({
  height: '30px',
  '& .react-date-picker__calendar': {
    width: '260px',
    minHeight: '256px'
  },
  '& .react-date-picker__clear-button, .react-date-picker__calendar-button, .react-date-picker__inputGroup__input, .react-date-picker__inputGroup__divider, .react-date-picker__inputGroup__leadingZero': {
    display: 'none'
  },
  '& .react-date-picker__wrapper': {
    minHeight: 20,
    maxHeight: 30,
    border: 'none !important',
    justifyContent: 'center',
    alignContent: 'center',
    margin: 'auto',
    '& .react-date-picker__inputGroup': {
      height: '20px',
      cursor: 'pointer',
      // background: 'brown',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& input[name="date"]': {
        height: '20px',
      }
    }
  },
  '& .react-calendar__month-view__weekdays': {
    display: 'none !important'
  },
  '& .react-calendar__tile--now': {
    background: '#FFF !important',
    color: '#000 !important'
  },
  '& .react-calendar__tile--active': {
    background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%) !important',
    borderRadius: '50%',
    color: "#FFF !important"
  },
  '& .react-calendar': {
    width: '260px',
  },
  '& .react-calendar__navigation': {
    marginBottom: '10px'
  },
  '& .react-calendar__year-view__months': {
    '& .react-calendar__year-view__months__month': {
      height: '50px',
      padding: '0px !important'
    }
  },
  '& .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button': {
    display: 'none'
  },
  '& .react-calendar__month-view__days__day--weekend': {
    color: '#000'
  },
  '& .react-calendar__month-view__days__day--neighboringMonth': {
    opacity: 0,
    pointerEvents: 'none'
  },
  '& .react-calendar__decade-view__years__year, .react-calendar__century-view__decades__decade': {
    height: '46px !important',
    padding: '1.5em 0.5em !important'
  },
  '& .react-calendar__century-view__decades__decade': {
    height: '58px !important',
  },
  '& .react-date-picker__calendar--open': {
    position: 'absolute',
    top: '0px !important',
    '& .react-calendar': {
      minHeight: '250px',
      border: 'none'
    }
  },
}))

const diaColors = {
  "1": "#2AAB6C",
  "2": "#EAAD11",
  "3": "#F36363",
}
const DiabetesRange = styled('span', { shouldForwardProp: (prop) => prop !== 'type' })(({ type }) => ({
  height: '16px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '16px',
  '-webkit-text-fill-color': 'initial',
  color: diaColors[type],
}))
const customShape = (key, props) => {
  const { x, y, width, height, graphvalue } = props;
  const borderRadius = 5;
  let fillColor = "url(#colorUv)"
  if (key === 'bloodPressureData') {
    fillColor = "url(#colorUvBloodPressure)"
  }
  if (graphvalue === 0) {
    return <path />
  }
  return (
    <path d={`M${x},${y + borderRadius}a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${-borderRadius}h${width - borderRadius * 2}a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}v${height - borderRadius * 2}a${borderRadius},${borderRadius} 0 0 1 ${-borderRadius},${borderRadius}h${-width + borderRadius * 2}a${borderRadius},${borderRadius} 0 0 1 ${-borderRadius},${-borderRadius}z`} fill={fillColor} />
  );
};

const names = {
  T: ['3', '6', '9', '12', '15', '18', '21', '24'],
  W: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
  M: ['5', '10', '15', '20', '25', '30'],
  Y: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
}

const findUpperAndLowerRange = (num, maxVal, isMin) => {
  let min = num - (maxVal > 500 ? 3.5 : 3.1) * (maxVal / 100);
  let max = num + (maxVal > 500 ? 3.5 : 3.1) * (maxVal / 100)
  if (min < 0) {
    max += min
    min = 0
  }
  return isMin ? min : max
}

function formatGraphData(key, duration = 'W', minMaxDate, noAvg) {
  if (duration) duration = duration.toUpperCase()
  let data = JSON.parse(JSON.stringify(this))
  const allKeys = key.split('&')
  if (duration === 'W' && minMaxDate[0] && minMaxDate[1]) {
    data = data.filter(x => new Date(x.createdAt).getTime() >= new Date(minMaxDate[0]).getTime() && new Date(x.createdAt).getTime() <= new Date(minMaxDate[1]).getTime())
  }
  data.forEach(x => {
    if (!x[allKeys[0]] && !noAvg) return
    x.graphvalue = []
    if (noAvg) {
      x.graphvalue = [x[key], x[key]]
    } else if (allKeys.length > 1) {
      allKeys.forEach(element => {
        x.graphvalue.push(x[element])
      });
    } else {
      x.graphvalue = x[key]
    }
  })
  let result = []
  names[duration].forEach((x, i) => {
    result[i] = { name: x, graphvalue: 0, uniqueIdentifies: i, count: 0 }
  })

  const recordCount = {}
  data.forEach((cur, ind) => {
    let selector = new Date(cur.createdAt).getDay()
    if (duration === 'Y') {
      selector = new Date(cur.createdAt).getMonth()
    } else if (duration === 'M' || duration === 'T') {
      let selected = duration === 'T' ? new Date(cur.createdAt).getHours() : new Date(cur.createdAt).getDate()
      const upper = +names[duration][names[duration].length - 1]
      const lower = +names[duration][0]
      if (selected && duration === 'M' && selected > 30) selected = 30
      if (selected >= upper) selector = `${upper}`
      else if (selected <= lower) selector = `${lower}`
      const { [duration]: _name } = names
      let i = 0
      while (++i < _name.length) {
        const selection = +_name[i]
        const preSelection = +_name[i - 1]
        if (selected >= preSelection && selected <= selection) {
          selector = i
          break;
        }
      }
    } else if (duration === 'W') {
      if (selector === 0) selector = 6
      else selector -= 1
    }
    cur.name = names[duration][selector]
    if (!result[selector]?.graphvalue) {
      result[selector] = cur
      if (!result[selector].count) result[selector].count = 1
    }
    if (Object.prototype.toString.call(cur.graphvalue) === '[object Array]') {
      if (result[selector]?.graphvalue[0] > cur.graphvalue[0]) {
        result[selector].graphvalue[0] = cur.graphvalue[0]
      } else if (result[selector]?.graphvalue[1] < cur.graphvalue[1]) {
        result[selector].graphvalue[1] = cur.graphvalue[1]
      }
    } else {
      if (result[selector]._id !== cur._id) {
        result[selector].graphvalue += cur.graphvalue
        result[selector].count += 1
      }
      recordCount[selector] = recordCount[selector] ? recordCount[selector] + 1 : 1
    }
  })
  let max = 0;
  result.forEach(x => {
    const value = Object.prototype.toString.call(x.graphvalue) === '[object Number]' ? x.graphvalue : Object.prototype.toString.call(x.graphvalue) === '[object Array]' ? (x.graphvalue[0] + x.graphvalue[1]) / 2 : 0
    if ((value / x.count) > max) {
      max = (value / x.count)
    }
  })
  const finalResult = result.map(({ graphvalue, ...rest }) => {
    let currentValue = []
    if (Object.prototype.toString.call(graphvalue) === '[object Number]' && graphvalue !== 0) {
      const value = graphvalue / (rest.count || 1)
      currentValue[0] = findUpperAndLowerRange(value, max, true)
      currentValue[1] = findUpperAndLowerRange(value, max, false)
    } else if (Object.prototype.toString.call(graphvalue) === '[object Array]' && graphvalue[0] === graphvalue[1]) {
      currentValue[0] = findUpperAndLowerRange(graphvalue[0], max, true)
      currentValue[1] = findUpperAndLowerRange(graphvalue[1], max, false)
    } else {
      currentValue = graphvalue
    }
    return { ...rest, graphvalue: currentValue }
  })
  return finalResult.filter((x) => x)
}

function getAvg(key, isTemp = false, duration = 'W', minMaxDate = [], isRange, formatGraphDataValues, isRangeAvg) {
  let data = JSON.parse(JSON.stringify(this))
  if (duration.toUpperCase() === 'W' && minMaxDate[0] && minMaxDate[1]) {
    data = data.filter(x => new Date(x.createdAt).getTime() >= new Date(minMaxDate[0]).getTime() && new Date(x.createdAt).getTime() <= new Date(minMaxDate[1]).getTime())
  }
  if (isTemp) {
    data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    const scale = +data?.[0]?.temperature_unit === 3 ? 'F' : 'C'
    const value = data?.[0]?.['temperature']
    if (!(value && +value)) return '-'
    if (key === 'fahrenheit') {
      return scale === 'F' ? value : ((value * 9 / 5) + 32).toFixed(2)
    }
    return scale === 'F' ? ((value - 32) * 5 / 9).toFixed(2) : value
  } else if (isRange || isRangeAvg) {
    let min
    let max
    let count = 0
    if (key === 'sys_blood_pressure') {
      const systolicKey = 'sys_blood_pressure';
      const diastolicKey = 'dia_blood_pressure';
       min = +((data.reduce((prev, curr) => prev + curr[systolicKey], 0) / data.length).toFixed(2)) || '-';
       max = +((data.reduce((prev, curr) => prev + curr[diastolicKey], 0) / data.length).toFixed(2)) || '-';
  
     }
  else{
    formatGraphDataValues.forEach((x) => {
      if (x.graphvalue[0]) {
        let changes = false
        if (isRangeAvg) {
          if (x.graphvalue[0] && x.graphvalue[1]) {
            min = (min || 0) + x.graphvalue[0]
            max = (max || 0) + x.graphvalue[1]
            count += 1
          }
        } else {
          if (!min) min = x.graphvalue[0]
          if (!max) max = x.graphvalue[1]
          if (min > x.graphvalue[0]) {
            min = x.graphvalue[0]
          }
          if (max < x.graphvalue[1]) {
            max = x.graphvalue[1]
          }
        }
      }
    })
  }
    if (count > 0) {
      return min && max ? `${Math.round(min / count)}-${Math.round(max / count)}` : '-'
    }
    return min && max ? `${Math.round(min)}-${Math.round(max)}` : '-'
  }
  return +((data.reduce((prev, curr) => prev + curr[key], 0) / data.length).toFixed(2)) || '-'
}

const headingText = {
  'w': 'Weekly',
  'm': 'Monthly',
  'y': 'Yearly',
  't': 'Today'
}

const getBottomValueForPendulam = (value) => {
  if (value <= 30) {
    return 4
  } else if (value <= 38) {
    const oneDegreeInPx = 56 / 8
    return (oneDegreeInPx * (value - 29.5))
  } else if (value <= 39) {
    const oneDegreeInPx = 146 - 73
    return 60 + (oneDegreeInPx * ((value - 38) || .01))
  } else if (value <= 41) {
    const oneDegreeInPx = 175 - 146
    return 146 + (oneDegreeInPx * ((value - 39) || .01))
  }
  return 204
}

const RenderList = React.memo(({ item, className, duration, setDuration, handleClick, date, setDate }) => {
  const userType = localStorage.getItem("userType");
  const [isOpen, setIsOpen] = useState(false)
  const healthParam = useSelector((state) => state?.user?.healthParam?.[0] || {});
  const getDuration = (item, _item) => {
    setDuration(prev => ({ ...prev, [item.primaryKey]: _item }))
  }

  const getDateValue = React.useCallback((key, isMinMaxDate) => {
    const value = date[key]
    const dateValue = moment(value, 'YYYY-MM-DD')?._isValid ? new Date(moment(value, 'YYYY-MM-DD').format()) : new Date(value)
    const months = ['Jan', 'Fab', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',"Oct", 'Nov', 'Dec']
    if (duration[key].toLowerCase() === 'm') {
      return months[dateValue.getMonth()]
    }
    if (duration[key].toLowerCase() === 'y') {
      return dateValue.getFullYear()
    }
    if (duration[key].toLowerCase() === 'w') {
      const day = dateValue.getDay();
      const oneDayValue = 1000 * 60 * 60 * 24
      let lower = dateValue
      let higher = dateValue
      if (day > 1) {
        lower = new Date(dateValue.getTime() - oneDayValue * (day - 1))
      }
      if (day < 7 && day > 0) {
        higher = new Date(dateValue.getTime() + oneDayValue * (7 - day))
      }
      if (day == 0){
        lower = new Date(dateValue.getTime() - oneDayValue * (6 - day))
      }
      if (isMinMaxDate) return [lower, higher]
      return `${lower.getDate()} ${months[lower.getMonth()]} - ${higher.getDate()} ${months[higher.getMonth()]}`
    }
    return '-'
  }, [date, duration])

  const handleClickArrow = React.useCallback((accumulator, key) => {
    const _dur = duration[key].toLowerCase()
    const aDay = 1000 * 60 * 60 * 24
    const value = date[key]
    const dateValue = moment(value, 'YYYY-MM-DD')?._isValid ? new Date(moment(value, 'YYYY-MM-DD').format()) : new Date(value)
    let formatValue = value
    const isLeap = dateValue.getFullYear % 4 === 0 && (dateValue.getFullYear() % 100 !== 0 || dateValue.getFullYear() % 100 === 0 && dateValue.getFullYear() % 400 === 0)
    if (_dur === 'y') {
      formatValue = dateValue.getTime() + aDay * (isLeap ? 365 : 364) * accumulator
    } else if (_dur === 'm') {
      const monthDays = [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      formatValue = dateValue.getTime() + aDay * monthDays[dateValue.getMonth()] * accumulator
    } else if (_dur === 'w') {
      formatValue = dateValue.getTime() + aDay * 7 * accumulator
    }
    setDate(prev => ({ ...prev, [key]: moment(formatValue).format('YYYY-MM-DD') }))
  }, [duration, date])

  const formatGraphDataValues = React.useMemo(() => {
    return formatGraphData.call(healthParam[item.primaryKey], item.dataKey, duration[item.primaryKey], getDateValue(item.primaryKey, true), ['heartRateData', 'respiratorieData'].includes(item.primaryKey))
  }, [healthParam, duration])

  const calculateAvg = React.useCallback((index) => {
    const isTopMost = ['bodyTemperatureData', 'bloodPressureData'].includes(item.primaryKey)
    const minMaxDate = getDateValue(item.primaryKey, true)
    const isRange = ['spo2sData', 'bloodPressureData'].includes(item.primaryKey)
    const isRangeAvg = ['respiratorieData', 'heartRateData'].includes(item.primaryKey)
    return getAvg.call(healthParam[item.primaryKey], item.dataKey.split('&')[index], isTopMost, duration[item.primaryKey], minMaxDate, isRange, formatGraphDataValues, isRangeAvg);
  }, [duration, healthParam, formatGraphDataValues])

  const DiabeleteRangeByCalculatons = React.useCallback(({ index }) => {
    const value = calculateAvg(index)
    if (!value || value === '-') return ''
    if (value < 100) {
      return <DiabetesRange type="1">Normal</DiabetesRange>
    } else if (value < 126) {
      return <DiabetesRange type="2">Prediabetes</DiabetesRange>
    } else {
      return <DiabetesRange type="3">Diabetes</DiabetesRange>
    }
  }, [calculateAvg])


  return (
    <Paper className={className.healthInnerCont} width='260px' elevation={1}>
      <>
        <Box className={!!item.secondaryHeading ? className.listHeading : `${className.listHeading} ${className.lipidTextStyle}`} display={'flex'} justifyContent={'space-between'}>
          <span>{item.primaryHeading}</span>
          {item.primaryKey === 'bloodSugarData' && <DiabeleteRangeByCalculatons index={0} />}
        </Box>
        <Grid container className={className.secondaryCont}>
          <Grid item xs={10} className={`${className.secondaryHeadingStyle} ${className.multipleSecondaryValue}`}>
            {item.secondaryHeading.split('&').map((_item, index) => (
              <Grid container key={_item}>
                <Grid item xs={12}>{_item.replace('Weekly', headingText[duration[item.primaryKey].toLowerCase()] || 'Weekly')}</Grid>
                <Grid item xs={12} className='averageUnit'>{calculateAvg(index)} {item.primaryKey === 'bodyTemperatureData' ? _item?.toLowerCase() === 'fahrenheit' ? <>&#8457;</> : <>&#8451;</> : item.unit}
                </Grid>
              </Grid>
            ))}
            {/* <Grid className='downloadIcon'><DownloadIcon /></Grid> */}
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              {['W', 'M', 'Y'].map((_item) => (
                <Grid key={_item} item xs={2} sx={{ cursor: 'pointer' }} onClick={() => getDuration(item, _item)} className={`rangeSelectorDiv ${duration[item.primaryKey].toLowerCase() !== _item.toLowerCase() ? 'notSelectedRangeSelectorDiv' : ''}`}>
                  <Grid>{_item}</Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
            <DateRangeGrid>
              {isOpen ? <DatePicker
                onChange={(e) => {
                  if (e) {
                    setDate(prev => ({ ...prev, [item.primaryKey]: moment(e).format('YYYY-MM-DD') }))
                  }
                }}
                format='YYYY-MM-DD'
                value={(moment(date[item.primaryKey], 'YYYY-MM-DD')?._isValid && new Date(moment(date[item.primaryKey], 'YYYY-MM-DD').format())) || ''}
                isOpen={isOpen}
                onCalendarClose={setIsOpen.bind(this, false)}
              /> :
                <Box sx={{
                  display: 'flex',
                  height: '30px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '& svg': {
                    width: '12px',
                    height: '12px',
                    fill: '#5E5E5E'
                  },
                  '& .dateRangeText': {
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#5E5E5E',
                  }
                }}>
                  <IconButton disableRipple onClick={handleClickArrow.bind(null, -1, item.primaryKey)}>
                    <ArrowBackIosOutlined />
                  </IconButton>
                  <IconButton onClick={setIsOpen.bind(null, true)} disableRipple className='dateRangeText'>
                    {getDateValue(item.primaryKey)}
                  </IconButton>
                  <IconButton disableRipple onClick={handleClickArrow.bind(null, +1, item.primaryKey)}>
                    <ArrowForwardIosOutlined />
                  </IconButton>
                </Box>
              }
            </DateRangeGrid>
          {!item.graphType ?
            <BarChart margin={{ top: 15, right: 0, bottom: 0, left: 0 }} width={275} height={220} data={formatGraphDataValues}>
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis orientation="right" axisLine={false} tickLine={false} />
              <Bar dataKey="graphvalue" barSize={10} shape={customShape.bind(this, item.primaryKey)} />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#097EB7" />
                  <stop offset="100%" stopColor="#2AAB6C" />
                </linearGradient>
                <linearGradient id="colorUvBloodPressure" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="50%" stopColor="#FF5C5C" />
                  <stop offset="50%" stopColor="#3E8EDE" />
                </linearGradient>
              </defs>
            </BarChart>
            :
            <Grid className={className.temperatureGraph}>
              <Grid>
                <Stack>
                  <Stack>41 <span>&#8451;</span></Stack>
                  <Stack>High Fever</Stack>
                </Stack>
                <Stack>
                  <Stack>105.8 <span>&#8451;</span>
                  </Stack>
                </Stack>
              </Grid>
              <Grid>
                <Stack>
                  <Stack>39 <span>&#8451;</span></Stack>
                  <Stack>Mild Fever</Stack>
                </Stack>
                <Stack>
                  <Stack>102.2 <span>&#8451;</span>
                  </Stack>
                </Stack>
              </Grid>
              <Grid sx={{ ...window.location.pathname !== "/patient/health" && { borderRadius: '0px 0px 8px 8px' } }}>
                <Stack>
                  <Stack>38 <span>&#8451;</span></Stack>
                  <Stack>No Fever</Stack>
                </Stack>
                <Stack>
                  <Stack>
                    100.4 <span>&#8457;</span>
                  </Stack>
                </Stack>
              </Grid>
              <Stack
                sx={{
                  bottom: getBottomValueForPendulam(getAvg.call(healthParam[item.primaryKey], item.dataKey.split('&')[0], true)),
                  '& .vertical-lines': {
                    bottom: 2 - getBottomValueForPendulam(getAvg.call(healthParam[item.primaryKey], item.dataKey.split('&')[0], true)),
                    height: getBottomValueForPendulam(getAvg.call(healthParam[item.primaryKey], item.dataKey.split('&')[0], true)),
                  }
                }}
                className="temp-pendulam">
                <Stack className='vertical-lines' />
              </Stack>
            </Grid>
          }
        </Grid>
        {window.location.pathname !== "/patient/dashboard" ?
          <Grid container spacing={0}
            onClick={() =>
              handleClick(item)
            }>
            {userType === 'patient' &&  <Grid className={className.addMannualyButton} borderRadius={'0px 0px 4px 4px'}>
              <Stack className='mannualIcon'><AddIcon /></Stack>
              <Stack className="mannualText">ADD MANUALLY</Stack>
            </Grid>}
          </Grid>
          : null
        }
      </>
    </Paper>
  )
})

export default function HealthParameters({ list, className, duration, setDuration, handleClick, date, setDate }) {
  const healthParam = useSelector((state) => state?.user?.healthParam?.[0] || {});

  return (
    !!list && healthParam && Object.keys(healthParam).length > 0 ?
      list.map((item, index) => (
        healthParam[item.primaryKey] ?
          <Box key={item.primaryKey} minWidth='280px' marginBottom={window.location.pathname === "/patient/health" ? '15px' : '1px'} marginTop={'1px'}>
            <RenderList item={item} {...{ className, duration, setDuration, handleClick, date, setDate }} />
          </Box>
          : null
      )) : null

  );
}


import {Grid} from '@mui/material';
import React from "react";
import styled from "styled-components";

import Sidebar from "./SideBar";

const StyledContainer = styled.div`
    margin: 0 auto;
    padding: 0;
    display: flex;
    overflow: auto;
    width: 100%;
`;

const StyledGridMainContainer = styled(Grid)`
    padding: 0px;
    margin: 0px;
    width: calc(100% - 112px);
    margin-left: 112px;
`;

const ChildrenDiv = styled(Grid)`
    margin-top: 55px;
    padding-left: 0 !important;
`;

const AppLayout = (props) => {
    return (
        <StyledContainer container spacing={0} className="rootContainer">
            <Grid sx={{ position: 'fixed', zIndex: 99 }}>
            <Sidebar/>
            </Grid>
            <StyledGridMainContainer  className="mainContainer">
                <ChildrenDiv item={true} xs={11} className="contentContainer">
                    {props.children}
                </ChildrenDiv>
            </StyledGridMainContainer>
        </StyledContainer>
    )
}

export default AppLayout;
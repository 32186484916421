import React, { useEffect,useContext, useState } from 'react';
import { Context } from '../../../../store';
import { toast } from 'react-toastify';
import { getOrgPatientReport } from '../../../../api/organization';
import {
	StyledContainerReport,
	StyledButtonGroup,
	StyledButton 
} from './styles';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

const MedicalReports = ({patiendId}) => {
	const [state, dispatch] = useContext(Context);
	const [currentTab, SetCurrentTab] = useState(1);
    const [patientReports, setPatientReports] = useState({
		Prescription: [],
		medicalHistory: []
	});


	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

    useEffect(() => {
        patiendId && getOrgPatientReport(patiendId).then((res) => {
			const medicalHistory = res.data.filter((item) => (item.type !== 'Previous prescription'))
			const Prescription = res.data.filter((item) => (item.type === 'Previous prescription'))
			setPatientReports({
				Prescription: Prescription,
				medicalHistory: medicalHistory
			})
		}).catch((err) => {
			loader(false)
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
    }, [patiendId])
    

    return (
        <StyledContainerReport style={{marginTop: '20px',borderRadius: '10px'}} className="mb-4 current-vitals-chart-card">
            <div className="d-flex row ml-1 current-vitals-chart-card-heading">
                <h4 className="pt-3 pl-1">Reports</h4>
                <div className="ml-auto">
                    <StyledButtonGroup>
                        <StyledButton
                            className={currentTab === 1 ? "active-btn mb-2 mt-2 emed4u-pa-btn" : ' mb-2 mt-2 emed4u-pa-btn'}
                            style={{ margin: '0 5px 0 0' }}
                            onClick={() => SetCurrentTab(1)}
                        >
                            Medical history
                        </StyledButton>
                        <StyledButton
                            className={currentTab === 2 ? "active-btn mb-2 mt-2 emed4u-pa-btn" : ' mb-2 mt-2 emed4u-pa-btn'}
                            onClick={() => SetCurrentTab(2)}
                        >
                            Prescription
                        </StyledButton>
                    </StyledButtonGroup>
                </div>
            </div>
            <hr style={{ margin: '0px' }} />
            {
                currentTab === 1 &&

                <ul className="list-unstyled p-3" style={{ height: "250px", overflow: "auto" }} >
                    {
                        patientReports && patientReports?.medicalHistory?.length ? patientReports?.medicalHistory.map((item) => (
                            <li key={item._id} className="media received d-flex m-0 row current-vitals-chart-card-li" style={{ alignItems: "center" }}>
                                <div className="p-0 col-md-8 no-padding">
                                    {item.name ? item.name : ''}
                                </div>
                                <div
                                    className="btn-patient-primary btn-patient-light submit-btn p-2 mt-2 mb-2 ml-auto button-text-white patient-emed4u-sml-btn"
                                >
                                    <a
                                        target="_blank"
                                        href={item.report}
                                        download
                                        rel="noopener noreferrer"
                                        style={{ color: '#5156be', backgroundColor: "#c8c9ee" }}
                                    >
                                        View
                                    </a>
                                </div>
                            </li>
                        ))
                            :
                            <div className="d-flex justify-content-center" style={{ marginTop: "21%", textAlign: "center", padding: "20px" }}>Sorry! There are no reports to be displayed</div>
                    }
                </ul>
            }
            {
                currentTab === 2 &&

                <ul className="list-unstyled p-3" style={{ height: "250px", overflow: "auto" }} >
                    {
                        patientReports && patientReports?.Prescription?.length ? patientReports?.Prescription.map((item) => (
                            <li key={item._id} className="media received d-flex m-0 row current-vitals-chart-card-li" style={{ alignItems: "center" }}>
                                <div className="p-0 col-md-8 no-padding">
                                    {item.name ? item.name : ''}
                                </div>
                                <div
                                    className="btn-patient-primary btn-patient-light submit-btn p-2 mt-2 mb-2 ml-auto button-text-white patient-emed4u-sml-btn"
                                >
                                    <a
                                        target="_blank"
                                        href={item.report}
                                        download
                                        rel="noopener noreferrer"
                                        style={{ color: '#5156be', backgroundColor: "#c8c9ee" }}
                                    >
                                        View
                                    </a>
                                </div>
                            </li>
                        ))
                            :
                            <div className="d-flex justify-content-center" style={{ marginTop: "21%", textAlign: "center", padding: "20px" }}>Sorry! There are no Reports to display</div>
                    }
                </ul>
            }
        </StyledContainerReport>
    )
}

export default MedicalReports
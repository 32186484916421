import React from 'react';
import { toast } from 'react-toastify';
import { globalValidator } from "../../../../utils";
import { addData  } from '../../../../graphUtils';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import { Context } from '../../../../store';
import {
	SET_ERROR,
} from '../../../../store/types';

const AddDetailsModal = (props) => {
    const { showModal, renderInfo, addDetails, setShowModal} = props;
	const [formData, setFormData] = React.useState({});
	const [state, dispatch] = React.useContext(Context);
    const validationInputFeild = (e, type) => {
		if (type === 'oxygen') {
			if (e.target.value <= 100) {
				setFormData((user) => ({ ...user, level: e.target.value }))
			} else {
				toast.error("% has to be under 100")
				dispatch({
					type: SET_ERROR,
					payload: "% has to be under 100"
				});
			}
		} else if (type === 'pulse') {
			if (e.target.value <= 300) {
				setFormData((user) => ({ ...user, pluse: e.target.value }))
			} else {
				toast.error("Pluse has to be maximum 300")
				dispatch({
					type: SET_ERROR,
					payload: "Pluse has to be maximum 300"
				});
			}
		} else if (type === 'systolic') {
			if (e.target.value <= 250) {
				if (formData.dia_blood_pressure < formData.sys_blood_pressure) {
					setFormData((user) => ({ ...user, sys_blood_pressure: e.target.value }))
				} else {
					setFormData((user) => ({ ...user, sys_blood_pressure: e.target.value }))
					// toast.error("Systolic can not be lower than diastolic!")
					dispatch({
						type: SET_ERROR,
						payload: "Systolic can not be lower than diastolic!"
					});
				}
			} else {
				toast.error("Systolic has to be maximum 250")
				dispatch({
					type: SET_ERROR,
					payload: "Systolic has to be maximum 250"
				});
			}
		} else if (type === 'diastolic') {
			if(parseInt(e.target.value) >= parseInt(formData.sys_blood_pressure)) {
				toast.error(`Diastolic can not be greater than systolic!`)
				dispatch({
					type: SET_ERROR,
					payload: 'Diastolic can not be greater than systolic!'
				});
				return;
			}
			if (e.target.value <= 130) {
				setFormData((user) => ({ ...user, dia_blood_pressure: e.target.value }))
				return;
			} else {
				toast.error("Diastolic has to be maximum 130")
				dispatch({
					type: SET_ERROR,
					payload: "Diastolic has to be maximum 130"
				});
			}
		} else if (type ==='respiratory') {
			if (e.target.value <= 25) {
				setFormData((user) => ({ ...user, respiratory_rates: e.target.value }))
			} else {
				toast.error("Respiratory Rate (breaths/min) has to be maximum 25")
				dispatch({
					type: SET_ERROR,
					payload: "Respiratory Rate (breaths/min) has to be maximum 25"
				});
			}
		} else if (type === 'temperature') {
			if (e.target.value <= 41) {
				setFormData((user) => ({ ...user, temperature: e.target.value }))
			} else {
				toast.error("Temperature has to be maximum 41 degree")
				dispatch({
					type: SET_ERROR,
					payload: "Temperature has to be maximum 41 degree"
				});
			}
		} else if (type === 'blood_sugar'){
			if(e.target.value >= 0 && e.target.value <= 300){
				setFormData((user) => ({ ...user, how_much: e.target.value }))
			} else {
				toast.error("blood sugar has to be minimum 0 and maximum 300")
				dispatch({
					type: SET_ERROR,
					payload: "blood sugar has to be minimum 0 and maximum 300"
				});
			}
		} else if (type === 'total_cholesterol') {
			if (e.target.value >= 0 && e.target.value <= 300) {
				setFormData((user) => ({ ...user, total_cholesterol: e.target.value }))
			} else {
				toast.error("cholesterol has to be minimum 0 and maximum 300")
				dispatch({
					type: SET_ERROR,
					payload: "cholesterol has to be minimum 0 and maximum 300"
				});
			}
		} else if (type === 'HDL_C') {
			if (e.target.value >= 0 && e.target.value <= 70) {
				setFormData((user) => ({ ...user, HDL_C: e.target.value }))
			} else {
				toast.error("HDL_C has to be minimum 0 and maximum 70")
				dispatch({
					type: SET_ERROR,
					payload: "HDL_C has to be minimum 0 and maximum 70"
				});
			}
		} else if (type === 'LDL_C') {
			if (e.target.value >= 0 && e.target.value <= 200) {
				setFormData((user) => ({ ...user, LDL_C: e.target.value }))
			} else {
				toast.error("LDL_C has to be minimum 0 and maximum 200")
				dispatch({
					type: SET_ERROR,
					payload: "LDL_C has to be minimum 0 and maximum 200"
				});
			}
		} else if (type === 'triglycerides') {
			if (e.target.value >= 0 && e.target.value <= 500) {
				setFormData((user) => ({ ...user, triglycerides: e.target.value }))
			} else {
				toast.error("triglycerides has to be minimum 0 and maximum 500")
				dispatch({
					type: SET_ERROR,
					payload: "triglycerides has to be minimum 0 and maximum 500"
				});
			}
		} else if (type === 'VLDL') {
			if (e.target.value >= 0 && e.target.value <= 35) {
				setFormData((user) => ({ ...user, VLDL: e.target.value }))
			} else {
				toast.error("VLDL has to be minimum 0 and maximum 35")
				dispatch({
					type: SET_ERROR,
					payload: "VLDL has to be minimum 0 and maximum 35"
				});
			}
		}
	}

	const handleSubmit = (renderDetails,formData) => {
		if(renderDetails?.title === "Blood pressure"){
			if(parseInt(formData?.sys_blood_pressure) < 80){
				toast.error("Systolic has to be minimum 80")
				dispatch({
					type: SET_ERROR,
					payload: "Systolic has to be minimum 80"
				});
				return 
			}
			if(parseInt(formData?.dia_blood_pressure) < 30){
				toast.error("Diastolic has to be minimum 30")
				dispatch({
					type: SET_ERROR,
					payload: "Diastolic has to be minimum 30"
				});
				return 
			}
			if(parseInt(formData?.dia_blood_pressure) >= parseInt(formData?.sys_blood_pressure)) {
				toast.error("Systolic can not be lower than diastolic!")
				dispatch({
					type: SET_ERROR,
					payload: "Systolic can not be lower than diastolic!"
				});
				return
			}
		}
		let keys = []
		renderDetails.inputFields.map((item) => {
			keys.push({ name: item.name, title: item.label, validateKey: item.selectType || item.name === 'notes' ? '' : "required" })
		})
        let valid = globalValidator(formData, keys);
		if(valid === true) {
			addData(renderDetails.title, formData, setFormData, addDetails)
        }else {
            toast.error(valid)
        }
	}
	
    return (
        <div className="modal custom-modal fade none-border show" style={{
            display: showModal ? 'block' : 'none', backdropFilter: 'blur(1px)',
            background: 'rgb(104 71 99 / 24%)'
        }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Add {renderInfo.title} </h4>
                    </div>
                    <div className="modal-body d-flex row ">
                        {renderInfo && renderInfo.inputFields && renderInfo.inputFields.map((item) =>
                            <div className="col-md-6 no-padding form-group ">
                                <label>{item.label}</label>
                                {item.selectType ?
                                    <SelectField name={item.name} value={formData[item.name]} onChange={e => setFormData((user) => ({ ...user, [item.name]: e.target.value }))} options={item.options} />
                                    :<InputField name={item.name} value={formData[item.name]} onChange={(e) => item.validation ? validationInputFeild(e, item.validation) : setFormData((user) => ({ ...user, [item.name]: e.target.value }))} />
                                }
                            </div>
                        )}
                    </div>
                    <div className="modal-footer justify-content-flexend">
                        <button type="button" className="btn btn-primary delete-event submit-btn" data-dismiss="modal" onClick={() => {
							setShowModal(false);
							document.body.style.overflowY = 'auto'
						}}>Cancel</button>
                        <button type="button" className="btn btn-primary delete-event submit-btn" data-dismiss="modal" onClick={(e) => handleSubmit(renderInfo,formData)}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddDetailsModal;
import React, { useEffect, useState } from "react";
import { tabs } from "../../../constant/staticLists";
import { useDispatch, useSelector } from "react-redux";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import {
  LoadingContainer,
  NoDetailsContainer,
} from "../../../assets/styles/CommonStyles";
import strings from "../../../constant/strings";
import Tables from "../../controls/Tables";
import { getOrganizationAppointmentListAction } from "../../../redux/appointment/organization/organizationAppointmentActions";
import PatientInformation from "../Doctor/Appointments/PatientInformation";
import PatientDashboard from "../Patient/PatientDashboard";
import PersonalMedicalDetails from "../PersonalMedicalDetails";
import MedicalReports from "../MedicalReports";
import EPriscriptions from "../EPriscriptions";
import PreviousAppointments from "../Doctor/Appointments/PreviousAppointments";
import {
  getPatientDetailsForDoctorAction,
  getPatientPreviousAppointmentForDoctorAction,
} from "../../../redux/appointment/doctor/doctorAppointmentActions";
import moment from "moment";
import {
  getMedicalHistoryAction,
  getPatientEprescriptionAction,
  getPatientMedicalReportAction,
} from "../../../redux/user/userActions";
import TabComponent from "../../Tab";
import SearchFields from "../../../pages/PatientPanel/PatientAppointments/SearchFields";
import { Grid, Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const OPDappointment = ({ fieldOrder, navigate }) => {
  const dispatch = useDispatch();
  const styleClasses = commonMuiStyles();
  const [tabValue, setTabValue] = useState(0);
  const [list, setList] = useState([]);
  const [selectedListItem, setSelectedListItem] = useState("");
  const [appointmentType, setAppointmentType] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(7); // Adjust this as needed

  const { userData, organizationAppointmentList, loading } = useSelector(
    (state) => ({
      userData: state.auth.userData,
      organizationAppointmentList: state.organizationAppointment.organizationAppointmentList,
      loading: state.organizationAppointment.loading,
    })
  );

  useEffect(() => {
    const getOrganizationAppointments = (status) => {
      dispatch(
        getOrganizationAppointmentListAction({
          token: userData.token,
          status: status,
        })
      );
    };

    switch (tabValue) {
      case 0:
        getOrganizationAppointments(0);
        break;
      case 1:
        getOrganizationAppointments(1);
        break;
      case 2:
        getOrganizationAppointments(4);
        break;
      case 3:
        getOrganizationAppointments(2);
        break;
      default:
        break;
    }
  }, [tabValue, dispatch, userData.token]);
  const renderAppointmentType = () => {
    switch (appointmentType) {
      case 0:
        return <PatientInformation item={selectedListItem} />;
      case 1:
        return (
          <PatientDashboard
            navigate="doctor"
            item={selectedListItem}
            patientID={selectedListItem.patientInfo._id}
          />
        );
      case 2:
        return (
          <PersonalMedicalDetails navigate="doctor" item={selectedListItem} />
        );
      case 3:
        return (
          <>
            <MedicalReports navigate="doctor" />
            <EPriscriptions navigate="doctor" />
          </>
        );
      default:
        return <PreviousAppointments />;
    }
  };
  useEffect(() => {
    setList(organizationAppointmentList);
    setPage(1); // Reset page number when list changes
  }, [organizationAppointmentList]);

  const handleValue = (value) => {
    const searchResults = organizationAppointmentList?.filter((item) =>
      item.doctorInfo.name.toLowerCase().includes(value.toLowerCase()) ||
      item.patientInfo.name.toLowerCase().includes(value.toLowerCase())
    );
    setList(searchResults);
    if (value === "") {
      setList(organizationAppointmentList);
    }
  };

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const selectedItem = (item) => {
    if (item._id !== selectedListItem._id) {
      setAppointmentType(0);
    }
    if (appointmentType === 0) {
      dispatch(
        getPatientDetailsForDoctorAction({
          token: userData.token,
          id: item.patientInfo._id,
        })
      );
    }
    setSelectedListItem(item);
  };

  const handleAppointmentTab = (event, newValue) => {
    setAppointmentType(newValue);
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    const value = {
      token: userData.token,
      patientId: selectedListItem.patientInfo._id,
      date: todayDate,
    };

    const getValue = () => {
      dispatch(getPatientEprescriptionAction(value));
      dispatch(getPatientMedicalReportAction(value));
    };

    if (newValue !== 1 && newValue !== 0) {
      newValue === 2
        ? dispatch(getMedicalHistoryAction(value))
        : newValue === 3
        ? getValue()
        : dispatch(getPatientPreviousAppointmentForDoctorAction(value));
    }
  };

  // Calculate the start and end index for pagination
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedList = list.slice(startIndex, endIndex);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={navigate === "subadminDashboard" ? 12 : 8}>
          <TabComponent
            tabs={tabs}
            tabValue={tabValue}
            setTabValue={setTabValue}
            handleTab={handleTab}
            customTab={styleClasses.customTab}
            customTabIndicator={styleClasses.customTabIndicator}
          />
        </Grid>
        {navigate !== "subadminDashboard" && (
          <Grid item xs={3}>
            <SearchFields
              placeholder="search doc's name or patient's name"
              handleValue={handleValue}
            />
          </Grid>
        )}
      </Grid>

      {loading ? (
        <LoadingContainer>{strings.loading}</LoadingContainer>
      ) : !!organizationAppointmentList && organizationAppointmentList.length > 0 ? (
        <>
          <Tables
            data={paginatedList}
            fieldsOrder={fieldOrder}
            tabValue={appointmentType}
            setTabValue={setAppointmentType}
            handleTab={handleAppointmentTab}
            selectedItem={selectedItem}
          >
            <>{renderAppointmentType()}</>
          </Tables>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Pagination
              count={Math.ceil(list.length / rowsPerPage)}
              page={page}
              onChange={(event, value) => setPage(value)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          </div>
        </>
      ) : (
        <NoDetailsContainer>No appointments available</NoDetailsContainer>
      )}
    </>
  );
};

export default OPDappointment;

import { createAction } from "@reduxjs/toolkit";

export const getDoctorAppointmentAction = createAction('doctor/appointment/list', (payload) => ({ payload }))

export const getPatientDetailsForDoctorAction = createAction('doctor/appointment/patient-details', (payload) => ({ payload }))

export const getPatientPreviousAppointmentForDoctorAction = createAction('doctor/appointment/previous-appointment', (payload) => ({ payload }))

export const confirmAppointmentAction = createAction('doctor/appointment/confirm-appointment', (credentials) => {
  return {
    payload: credentials,
  };
});



import styled from 'styled-components';

export const StyledCard = styled.div`
	.doctor-book-card-content.tile-card-content-1:hover {
		background: #19ce67;
		opacity: 0.8;
		transition: 0.5s;
	}

	a {
		background-image: linear-gradient(to bottom right,#077fb7,#27aa6b);
		border: none;
	}
`;
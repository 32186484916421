import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Context } from '../../../../store';
import {
	getInvoicesOrg
} from '../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import {
	StyledContainer,
	StyledHeading
} from './styles';
import CardComponent from '../../../../components/Card';
import TableComponent from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import Search from '../../../../components/Search';
import InsideMenuBar from '../../../../components/InsideMenus';

const tableHeading = [
	{id: 1, title: 'Invoice number'},
	{id: 2, title: 'Patient ID'},
	{id: 3, title: 'Name'},
	{id: 4, title: 'Date'},
	{id: 5, title: 'Amount'},
	{id: 5, title: 'Status'},
	{id: 5, title: 'Actions'}
];

const options = [
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 4, label: 'Contact Number', value: 'contact' },
	{ id: 2, label: 'Email', value: 'email' },
	{ id: 3, label: 'Status', value: 'status' },
	{ id: 0, label: 'Id', value: '_id' },
]

const statusOptions = [
	{ id: 0, label: 'Paid', value: '0' },
	{ id: 1, label: 'Refund', value: '1' }
]
function Invoices(props) {
	const [state, dispatch] = React.useContext(Context);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('name');
	const [invoices, setInvoices] = React.useState([]);
	
	const fetchGetInVoicesOrg = () => {
		loader(true);
		getInvoicesOrg().then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setInvoices(res?.data);
		
		}).catch(err => {
			loader(false);
			setInvoices([]);
			// toast.error(err?.response?.data?.error)
			// dispatch({
			// 	type: SET_ERROR,
			// 	payload: err?.response?.data?.error
			// });
		})
	}

	React.useEffect(() => {
		fetchGetInVoicesOrg();
	}, []);

	const searchInvoice = () => {
		loader(true);
		const payload = {
			[selectValue]: search
		}
		getInvoicesOrg(payload).then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setInvoices(res?.data);
		}).catch(err => {
			loader(false);
			setInvoices([]);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const [invoicePaginate, setInvoicePaginate] = React.useState([]);
	const pageChangeInvoice  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setInvoicePaginate(data);
	}

	React.useEffect(() => {
        fetchGetInVoicesOrg();
    }, [selectValue])

	React.useEffect(() => {
        if(search === '') {
            fetchGetInVoicesOrg();
        }
    }, [search])

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<StyledHeading>
									Invoice Listing
								</StyledHeading>
								<hr />
								<div className="doctor-panel-filters row col-md-12">
									<Search
										selectValue={selectValue} 
										setSelectValue={setSelectValue} 
										search={search} 
										setSearch={setSearch} 
										searchPatient={searchInvoice} 
										name={"Invoice"}
										options={options}
										statusOptions={statusOptions}
									/>
								</div>
								<TableComponent tableHeading={tableHeading}>
								{invoices?.length ? invoicePaginate.map(item => {
										return <tr>
											<td>#{item.invoiceNumber}</td>
												<td>#{item.patient ? item.patient._id : ''}</td>
												<td>
													<h2 className="table-avatar">
														<a className="avatar avatar-sm mr-2">
															<img className="avatar-img rounded-circle" src={item.patient?.profileImage ? item.patient?.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
														</a>
														<a>{item.patient? item.patient.name : ''} </a>
													</h2>
												</td>
												
												<td>{moment(item.createdAt).format('Do MMMM, YYYY')}</td>
												<td>{item.amount} </td>
												<td><span className="badge badge-pill bg-success inv-badge">{item.status == 0 ? 'Paid' : 'Refund'}</span></td>
												<td>
													<div className="table-action">
														<Link to={`/organization/view-invoice/${item._id}`} className="btn btn-sm bg-info-light-blue">
															<i className="far fa-eye"></i> View
														</Link>
													</div>
												</td>
										</tr>
									}): null
									}
								</TableComponent> 
								{invoices.length == 0 ? (<h5 style={{width: '100%', textAlign: 'center', margin: '2% 0 1%'}}>Sorry! There are no Invoices to display!</h5>) : null}

								{invoices.length > 0 && <Pagination items={invoices} onChangePage={pageChangeInvoice} />}

							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default Invoices
import { createAction } from '@reduxjs/toolkit';

export const bloodSugar = createAction('user/patient/sugar', (credentials) => {
  return {
    payload: credentials,
  };
});

export const bloodPressure = createAction('user/patient/pressure', (credentials) => {
  return {
    payload: credentials,
  };
});

export const qrCodeAction = createAction('user/patient/qr-code', (credentials) => {
  return {
    payload: credentials,
  };
});

export const bloodOxygen = createAction('user/patient/oxygen', (credentials) => {
  return {
    payload: credentials,
  };
});

export const bodyTemp = createAction('user/patient/temperature', (credentials) => {
  return {
    payload: credentials,
  };
});

export const heartRate = createAction('user/patient/heart', (credentials) => {
  return {
    payload: credentials,
  };
});

export const respiratoryRate = createAction('user/patient/respiratory', (credentials) => {
  return {
    payload: credentials,
  };
});

export const healthParams = createAction('user/patient', (credentials) => {
    return {
      payload: credentials,
    };
  });

export const lipidProfile = createAction('user/patient', (payload) => {
    return {
      payload,
    };
  });

export const patientProfile = createAction('user/patient', (payload) => ({ payload }))

export const healthConditions = createAction('user/healthConditions', (payload) => ({ payload }))
export const medications = createAction('user/medications', (payload) => ({ payload }))
export const surguries = createAction('user/surguries', (payload) => ({ payload }))
export const allergies = createAction('user/allergies', (payload) => ({ payload }))
export const vaccination = createAction('user/vaccination', (payload) => ({ payload }))
export const patientInvoiceListAction = createAction('user/patient/invoice', (payload) => ({ payload }))
export const patientInvoiceDetail = createAction('user/patient/invoice/detail', (payload) => ({ payload }))

export const getMedicalHistoryAction = createAction('doctor/appointment/medical-details-by-doctor', (payload) => {
  return {
    payload: payload,
  };
});

export const getPatientEprescriptionAction = createAction('user/patient/eprescription', (payload) => {
  return {
    payload: payload,
  };
});

export const getPatientMedicalReportAction = createAction('user/patient/medical-report', (credentials) => {
  return {
    payload: credentials,
  };
});
import { makeStyles } from "@mui/styles";

import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    minHeight: 84,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 0px 32px 0px", //top right bottom left
  },
  buttonContainer: {
    width: "50%",
    height: "15vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  healthListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    paddingRight: 10,
  },
  firstInnerCont: {
    width: "100%",
    display: "flex",
  },
  appointmentCont: {
    width: "100%",
    height: 240,
    border: `2px solid ${colors.white1}`,
    borderRadius: 8,
    margin: "0px 8px 0px 8px", //top right bottom left
  },
  appointmentTextStyle: {
    color: colors.black1,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight500,
    fontSize: "14px",
    lineHeight: "16px",
    width: "100%",
    margin: "8px",
  },
  measurementText: {
    color: colors.black1,
    fontFamily: "Poppins",
    fontStyle: "normal",
    border: `2px solid ${colors.white1}`,
    fontWeight: fontWeights.fontWeight500,
    fontSize: "14px",
    lineHeight: "16px",
    width: "100%",
    margin: "8px",
  },
  headingAppointmentCont: {
    width: "100%",
    display: "flex",
    borderTop: `2px solid ${colors.white1}`,
  },
  headingAppointmentStyle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight500,
    fontSize: "12px",
    lineHeight: "18px",
    color: colors.grey1,
    marginLeft: 8,
  },
  detailCont: {
    height: "175px",
    width: "100%",
  },
  fontSize: { fontSize: '12px'},
  appointMainCont : {
    borderRadius: '8px 8px 0px 0px',
    border: '2px solid var(--grey-2, #F5F5F7)',
    borderBottom: '0px',
    background: 'var(--white, #FFF)',
  },
  buttonCont: {
    width: "100%",
    height: 32,
    cursor: "pointer",
    color: colors.white,
    background: colors.primary,
    //backgroundColor: colors.primary,
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ecgCont: {
    width: "90%",
    height: "240px",
    background: colors.black2,
    margin: "0px 8px 0px 8px",
    border: `1px solid ${colors.black2}`,
    borderRadius: "8px",
  },
  measurementContainer: {
    border: `2px solid ${colors.grey}`,
    borderRadius: 9,
    padding: 10,
    height: 62,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  measurementStyle: {
    margin: "0px 0px 24px 0px", //top right bottom left
  },
  ecgWaveformContainer: {
    border: `1px solid ${colors.black2}`,
    borderRadius: "8px",
    background: colors.black2,
    margin: "21px 7px 6px 3px",
    height: "34vh",
  },
  innerCont: {
    height: 96,
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 32px 0px 32px",
    justifyContent: "space-between",
  },
  healthParameterCont: {
    minHeight: 266,
    width: "100%",
    display: 'grid',
    overflowX: 'auto', /* Enable horizontal scrolling if necessary */
  },
  healthInnerCont: {
    display: "flex",
    opacity: 1,
    padding: 0,
    width: "260px",
    // boxShadow: `-4px -4px 15px ${colors.shadowGradient}, 4px 4px 15px ${colors.shadowGradient}`,
    borderRadius: 8,
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  listHeading: {
    height: 36,
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight500,
    fontSize: "16px",
    lineHeight: "24px",
    // marginTop: 8,
    // marginBottom : 8,
    background: colors.textColor,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    paddingLeft: 16,
    paddingRight: 8
  },
  healthListHeading: {
    height: 36,
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight500,
    fontSize: "16px",
    lineHeight: "24px",
    background: colors.primary,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  content : {
    color: 'var(--black-2, #5E5E5E)',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px', /* 133.333% */
  },
  appointmentCont : {
    paddingLeft: '10px',
    paddingTop: '10px',
  },

  secondaryCont: {
    minHeight: 76,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    background: colors.white1,
    '& .rangeSelectorDiv': {
      display: 'flex',
      margin: '0px 5px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px 2px',
      gap: '8px',
      width: '32px',
      height: '16px',
      left: '64px',
      top: '54px;   ',
      background: colors.lightBackground,
      borderRadius: '40px',
      '& .MuiGrid-root': {
        background: colors.primary,
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      }
    },
    '& .notSelectedRangeSelectorDiv': {
      background: 'transparent !important',
      '& .MuiGrid-root': {
        color: `${colors.grey1} !important`,
        background: 'inherit !important',
        backgroundClip: "inherit !important",
        textFillColor: "inherit !important",
        "-webkit-background-clip": "inherit !important",
        "-webkit-text-fill-color": "inherit !important",
      }
    },
    '& + .MuiGrid-root >.recharts-wrapper > .recharts-surface': {
      // width: 315,
      // marginRight: -35
    }
  },
  secondaryHeadingStyle: {
    fontafamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight500,
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "start",
    color: colors.grey1,
    textTransform: 'none',
    padding: "8px 0px 2px 10px",
    '& .MuiGrid-root > .MuiGrid-root': {
      fontSize: 14,
      lineHeight: '19px'
    },
    '& .averageUnit': {
      textTransform: 'none',
      fontFamily: 'Poppins',
      fontSize: '19px',
      fontWeight: '500',
      lineHeight: '19px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#2B2B2B'
    },
    '& .downloadIcon': {
      width: '18px',
      height: '18px',
      position: 'relative',
      top: '0px',
      left: '25px',
      '& svg': {
        height: '24px',
        width: '24px'
      }
    }
  },
  temperatureGraph: {
    height: 220,
    width: 260,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    // marginTop: 30,
    '& .temp-pendulam': {
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      height: 16,
      width: 16,
      borderRadius: '50%',
      color: '#FFF',
      background: '#FFF',
      // bottom: 10,
      '& .vertical-lines': {
        left: 7,
        right: 0,
        width: 2,
        // bottom: -32,
        // height: 35,
        position: 'absolute',
        background: '#FFF',
      },
    },
    '& .MuiGrid-root': {
      height: 'calc(33%)',
      width: '100%',
      color: '#FFF',
      display: 'flex',
      justifyContent: 'space-between',
      '& > .MuiStack-root':{
        // width: '100%',
        '&:nth-child(1)': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'start',
          justifyContent: 'end',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 12,
          lineHeight: '16px',
          color: '#FFFFFF',
          textTransform: 'none',
          margin: '0px 0px 8px 10px',
          '& > .MuiStack-root:nth-child(1)': {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 'auto',
            marginTop: '4px',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 12,
            lineHeight: '16px',
          },
          '& + .MuiStack-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            margin: '4px 8px 8px 0px',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 12,
            lineHeight: '16px',
            color: '#FFFFFF',
            '& .MuiStack-root': {
              flexDirection: 'row'
            }
          }
        }
      },
      '&:nth-child(1)': {
        background: '#F36363',
        '& + .MuiGrid-root': {
          background: '#EAAD11',
          '& + .MuiGrid-root': {
            background: '#2AAB6C'
          }
        }
      }
    }
  },
  lipidTextStyle: {
    background: colors.black1,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    margin: "8px 0px 8px 16px"
  },
  marginTop: {
    marginTop: "4vh",
  },
  multipleSecondaryValue: {
    display: 'flex'
  },
  addMannualyButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    background: colors.primary,
    // backgroundColor: ' linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
    width: '100%',
    '& .mannualIcon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: 19,
        height: 19,
        fill: '#FFFFFF'
      }
    },
    '& .mannualText': {
      display: 'flex',
      cursor: "pointer",
      flexDirection: 'row',
      justifyContent: 'center',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      color: '#FFFFFF',
      textTransform: 'uppercase',
    }
  }
}));

export { useStyles };

import React from 'react';

import QRCode from "qrcode.react";
import { Context } from '../../../store';
import {
	StyledContainer,
	StyledButton
} from './styles';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import { toast } from 'react-toastify';
import CardComponent from '../../../components/Card';
import {
	EmailShareButton,
	FacebookShareButton,
	TelegramShareButton,
	TumblrShareButton,
	TwitterShareButton,
	WhatsappShareButton,
  } from "react-share";

  import {
	EmailIcon,
	FacebookIcon,
	FacebookMessengerIcon,
	TelegramIcon,
	TumblrIcon,
	TwitterIcon,
	WhatsappIcon,
  } from "react-share";
  import InsideMenuBar from '../../../components/InsideMenus';

  function ShareQr(props) {
	const [state, dispatch] = React.useContext(Context);
	const [qrCodeUrl, setqrCodeUrl] = React.useState('');
		
	const [modalOpen, setModalOpen] = React.useState(null);
	const download = () => {
		console.log("qrCodeUrl",qrCodeUrl)
		const qrCodeURL = document.getElementById('qrCodeEl')
		  .toDataURL("image/png")
		  .replace("image/png", "image/octet-stream");
		setqrCodeUrl(qrCodeURL)
		let aEl = document.createElement("a");
		aEl.href = qrCodeURL;
		aEl.download = "QR_Code.png";
		document.body.appendChild(aEl);
		aEl.click();
		document.body.removeChild(aEl);
	}

	const handleCopy = (item) => {
		if(navigator.clipboard){ // to prevent crashing on http
			navigator.clipboard.writeText(item); // only works on https or localhost
			toast.success('Text Copied!')
			dispatch({
				type: SET_ERROR,
				payload: 'Text Copied!'
			});
		}
	}
	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>

			<div className="content">
				<div className="container-fluid">
					<div className="row">
						

						<div className="col-md-12">
							<CardComponent className="p-4 mb-4 row">
								<div className="col-md-12 text-center">
									<QRCode id="qrCodeEl"
										value={window.location.href.replace('share-qr','medical-info')} style={{ marginRight: 50, width: "300px", height: "300px", }} />

									<h1>Click to copy below Link</h1>
									<div className='col-md-10 m-auto text-center'>
										<h5 style={{
											color: '#5156be',
											padding: '30px 0',
											cursor: 'pointer',
											wordBreak:'break-all'
										}} onClick={() => handleCopy(window.location.href.replace('share-qr','medical-info'))}>{window.location.href.replace('share-qr','medical-info')}</h5>

									</div>
								</div>
								<div className="d-flex col-md-12">
									<div className="row-pt-3 text-center" style={{width: '100%'}}>
									{/* <StyledButton className="m-3" onClick={()=>setModalOpen(true)}>Share</StyledButton> */}
									<StyledButton className="m-3" onClick={download}>Download</StyledButton>
									
								
										{modalOpen && <div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
											<div class="modal-dialog" role="document">
												<div class="modal-content">
													<div class="modal-header">
														<h5 class="modal-title" id="addFollowupAppointmentLabel">Share </h5>
														<button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" onClick={()=>setModalOpen(false)}><span aria-hidden="true">×</span></button>
													</div>
													<div class="modal-body d-flex row p-3 align-center m-auto" style={{ minHeight: "200px" }}>
														<div style={{ padding: "10px" }}>
															<FacebookShareButton url={qrCodeUrl}>
																<FacebookIcon round={true} />
															</FacebookShareButton>

														</div>
														<div style={{ padding: "10px" }}>
															<WhatsappShareButton url={qrCodeUrl}>
																<WhatsappIcon round={true} />
															</WhatsappShareButton>

														</div>
														<div style={{ padding: "10px" }}>
															<TwitterShareButton url={qrCodeUrl}>
																<TwitterIcon round={true} />
															</TwitterShareButton>

														</div>
														<div style={{ padding: "10px" }}>
															<EmailShareButton url={qrCodeUrl}>
																<EmailIcon round={true} />
															</EmailShareButton>

														</div>

													</div>
												</div>
											</div>
										</div>}
									</div>
								</div>
							</CardComponent>
						</div>

					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default ShareQr

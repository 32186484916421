import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { push } from "../router/routerSlice";

import {
  bloodOxygenAPI,
  bloodPressureAPI,
  bloodSugarAPI,
  bodyTempAPI,
  getAllergy,
  getHealthCondition,
  getInvoiceDetailApi,
  getInvoiceListApi,
  getMedicalDetailByDoctorAPI,
  getMedicalDetailByPatientAPI,
  getMedicalHistoryAPI,
  getMedicalReport,
  getMedication,
  getPatientEprescriptionApi,
  getPatientPrescriptionApi,
  getSurgury,
  getVaccinations,
  healthParamsAPI,
  heartRateAPI,
  lipidProfileApi,
  qrcodeAPI,
  respiratoryRateAPI,
} from "../../services/mainService";
import {
  getBloodSugarSuccess,
  getBloodSugarFailure,
  getHealthParamSuccess,
  getHealthParamFailure,
  getLipidSuccess,
  getLipidFailure,
  getPatientProfileSuccess,
  getPatientProfileFailure,
  getHeartRateFailure,
  getRespiratoryRateFailure,
  getRespiratoryRateSuccess,
  getHeartRateSuccess,
  getBodyTempSuccess,
  getBodyTempFailure,
  getBloodOxygenSuccess,
  getBloodOxygenFailure,
  getBloodPressureSuccess,
  getBloodPressureFailure,
  getMedicalHistoryRequest,
  getMedicalHistoryFailure,
  getMedicalHistorySuccess,
  getHealthConditionsSuccess,
  getHealthConditionsFailure,
  getMedicationSuccess,
  getMedicationsFailure,
  getSurgurySuccess,
  getSurguryFailure,
  getAllergySuccess,
  getAllergyFailure,
  getVaccinationSuccess,
  getVaccinationFailure,
  getQRcodeDetailsSuccess,
  getQRcodeDetailsFailure,
  getPatientInvoiceDetailSuccess,
  getPatientInvoiceDetailFailure,
  getPatientEprescriptionSuccess,
  getPatientEprescriptionFailure,
  getPatientEprescriptionRequest,
  getPatientMedicalReportRequest,
  getPatientMedicalReportSuccess,
  getPatientMedicalReportFailure,

  getInvoiceListSuccess,
  getInvoiceListFailure,
  getPatientInvoiceDetailRequest,
  getInvoiceListRequest,
} from "./userSlice";
import {
  bloodSugar,
  bloodOxygen,
  bloodPressure,
  bodyTemp,
  heartRate,
  respiratoryRate,
  healthParams,
  lipidProfile,
  patientProfile,
  healthConditions,
  medications,
  surguries,
  allergies,
  vaccination,
  qrCodeAction,
  patientInvoice,
  patientInvoiceDetail,
  getPatientEprescriptionAction,
  getPatientMedicalReportAction,
  getMedicalHistoryAction,
  patientInvoiceListAction,
} from "./userActions";
import { LipidProfileList } from "../../constant/staticLists";
import { getProfile } from "../../services/authService";

function* bloodSugarSaga(action) {
  try {
    const apiResponse = yield call(bloodSugarAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        yield put(getBloodSugarSuccess(userData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getBloodSugarFailure(apiResponse.response.error));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* healthParamsSaga(action) {
  try {
    const apiResponse = yield call(healthParamsAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        yield put(getHealthParamSuccess(userData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getHealthParamFailure(apiResponse.response.error));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* bloodPressureSaga(action) {
  try {
    const apiResponse = yield call(bloodPressureAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        yield put(getBloodPressureSuccess(userData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getBloodPressureFailure(apiResponse.response.error));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* bloodOxygenSaga(action) {
  try {
    const apiResponse = yield call(bloodOxygenAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        yield put(getBloodOxygenSuccess(userData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getBloodOxygenFailure(apiResponse.response.error));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* bodyTempSaga(action) {
  try {
    const apiResponse = yield call(bodyTempAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        yield put(getBodyTempSuccess(userData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getBodyTempFailure(apiResponse.response.error));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* heartRateSaga(action) {
  try {
    const apiResponse = yield call(heartRateAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        yield put(getHeartRateSuccess(userData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getHeartRateFailure(apiResponse.response.error));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* respiratoryRateSaga(action) {
  try {
    const apiResponse = yield call(respiratoryRateAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        yield put(getRespiratoryRateSuccess(userData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getRespiratoryRateFailure(apiResponse.response.error));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* lipidProfileSaga(action) {
  try {
    const apiResponse = yield call(lipidProfileApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const { data } = apiResponse.response;
        const length = Array.isArray(data) && data.length;
        const payload = JSON.parse(JSON.stringify(LipidProfileList)).map(
          (x) => {
            if (length && length > 0) {
              x.value = data?.[length > 0 ? length - 1 : 0]?.[x.key] || 0;
            } else {
              x.value = data?.[x.key] || 0;
            }
            return x;
          }
        );
        yield put(getLipidSuccess(payload));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getLipidFailure(apiResponse.response.error));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* getProfileSaga(action) {
  try {
    const apiResponse = yield call(getProfile, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        yield put(getPatientProfileSuccess(userData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getPatientProfileFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}


function* getHealthConditions(action) {
  try {
    const apiResponse = yield call(getHealthCondition, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const medicalData = apiResponse.response.data;
        yield put(getHealthConditionsSuccess(medicalData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getHealthConditionsFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* getMedications(action) {
  try {
    const apiResponse = yield call(getMedication, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const medicalData = apiResponse.response.data;
        yield put(getMedicationSuccess(medicalData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getMedicationsFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* getSurguries(action) {
  try {
    const apiResponse = yield call(getSurgury, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const medicalData = apiResponse.response.data;
        yield put(getSurgurySuccess(medicalData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getSurguryFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* getAllergies(action) {
  try {
    const apiResponse = yield call(getAllergy, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const medicalData = apiResponse.response.data;
        yield put(getAllergySuccess(medicalData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getAllergyFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* getVaccination(action) {
  try {
    const apiResponse = yield call(getVaccinations, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const medicalData = apiResponse.response.data;
        yield put(getVaccinationSuccess(medicalData));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getVaccinationFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function* getQRcode(action) {
  try {
    const apiResponse = yield call(qrcodeAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const data = apiResponse.response.data;
        yield put(getQRcodeDetailsSuccess(data));
        yield put(push(`/patient/patient-info/${data}`));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getQRcodeDetailsFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}
// invoice list
function* getInvoiceList(action) {
  try {
    yield put(getInvoiceListRequest());
    const apiResponse = yield call(getInvoiceListApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const data = apiResponse.response.data;
        yield put(getInvoiceListSuccess(data));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getInvoiceListFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}
// single invoice list
function* getPatientInvoiceDetail(action) {
  try {
    yield put(getPatientInvoiceDetailRequest());
    const apiResponse = yield call(getInvoiceDetailApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const data = apiResponse.response.data;
        yield put(getPatientInvoiceDetailSuccess(data));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getPatientInvoiceDetailFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}


function* getMedicalHistory(action) {
  try {
     yield put(getMedicalHistoryRequest()); 
    const apiResponse = yield call(getMedicalHistoryAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const listData = apiResponse.response.data[0];
        yield put(getMedicalHistorySuccess(listData));
        let user =localStorage.getItem("userType") 
        if (user === "patient") {
          yield put(getMedicationSuccess(listData.medicationData));
          yield put(getHealthConditionsSuccess(listData.healthconditionData));
          yield put(getSurgurySuccess(listData.surgeryData));
          yield put(getAllergySuccess(listData.allergieData));
          yield put(getVaccinationSuccess(listData.vaccinationData));
        }
      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(getMedicalHistoryFailure(apiResponse.response.message))
      }
    }
  } catch (error) { }
}

// eprescription

function* getPatientEprescription(action) {
  try {
    yield put(getPatientEprescriptionRequest());
    const apiResponse = yield call(getPatientPrescriptionApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const data = apiResponse.response.data;
        yield put(getPatientEprescriptionSuccess(data));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getPatientEprescriptionFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

//medical report 

function* getPatientMedicalReport(action) {
  try {
    yield put(getPatientMedicalReportRequest());
    const apiResponse = yield call(getMedicalReport, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const data = apiResponse.response.data;
        yield put(getPatientMedicalReportSuccess(data));
      } else {
        toast.error(apiResponse.response.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        yield put(getPatientMedicalReportFailure(apiResponse.response.message));
      }
    }
  } catch (error) {
    console.log("Error:", error);
  }
}



export function* userSaga() {
  yield takeLatest(bloodSugar.type, bloodSugarSaga);
  yield takeLatest(bloodPressure.type, bloodPressureSaga);
  yield takeLatest(healthParams.type, healthParamsSaga);
  yield takeLatest(bloodOxygen.type, bloodOxygenSaga);
  yield takeLatest(bodyTemp.type, bodyTempSaga);
  yield takeLatest(heartRate.type, heartRateSaga);
  yield takeLatest(respiratoryRate.type, respiratoryRateSaga);
  yield takeLatest(lipidProfile.type, lipidProfileSaga);
  yield takeLatest(patientProfile.type, getProfileSaga);
  yield takeLatest(healthConditions.type, getHealthConditions);
  yield takeLatest(medications.type, getMedications);
  yield takeLatest(surguries.type, getSurguries);
  yield takeLatest(allergies.type, getAllergies);
  yield takeLatest(vaccination.type, getVaccination);
  yield takeLatest(qrCodeAction.type, getQRcode);
  yield takeLatest(patientInvoiceListAction.type, getInvoiceList);
  yield takeLatest(patientInvoiceDetail.type, getPatientInvoiceDetail);
  yield takeLatest(getPatientMedicalReportAction.type,getPatientMedicalReport );
  yield takeLatest(getPatientEprescriptionAction.type, getPatientEprescription);
  yield takeLatest(getMedicalHistoryAction.type, getMedicalHistory);
}
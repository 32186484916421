import { Grid } from '@mui/material';
import React from 'react';
import MedicalHistory from '../../../components/ui/Patient/MedicalHistory';
import AppLayout from '../../SideBar';

const PatientMedicalHistory = (props) => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        <MedicalHistory />
      </AppLayout>
    </Grid>
  )
}

export default PatientMedicalHistory;
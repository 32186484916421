import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Context } from '../../../../../store';
import {
	getDoctorSlotsListingAPI,
	reBookAppointmentAPI
} from '../../../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_DATES_APPOINTMENTS
} from '../../../../../store/types';

import moment from 'moment';


import CardComponent from '../../../../../components/Card';

import {
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledAddEditPatient,
	StyledSlots,
	StyledButtonPay
} from './styles';
import InsideMenuBar from '../../../../../components/InsideMenus';
import { getPatientInfo } from '../../../../../api/organization';

function SubAdminBookingSlots(props) {

	const [state, dispatch] = React.useContext(Context);
	const [id, setId] = useState(null);
	const [slots, setSlots] = useState([]);

	const [doctor, setDoctor] = useState(null);
	
	const [reBookAppointment, setReBookAppointment] = useState(false);
	const [appId, setAppId] = useState(null);


	useEffect(() => {

		if(props.match.params && props.match.params.doctorId) {
			setId(props.match.params.doctorId);
			const allCookieData = Cookies.get();
			if(allCookieData && allCookieData.doctorInfo) {
				setDoctor(JSON.parse(allCookieData.doctorInfo))
			}
			
			loader(true);
			getPatientInfo(props.match.params.patientId).then((res) => {
				Cookies.set('setPatientInfo', JSON.stringify({
					name: res.data.name,
					address: res.data.address,
					gender: res.data.gender,
					dob: res.data.dob,
					weight: res.data.weight
				}), { path: '' });
			}).catch((err) => {
				loader(false);
			})
			getDoctorSlotsListingAPI(props.match.params.doctorId).then(res => {
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				

				var slotMap = {};
				for(let slot of res.data){
						const date = moment(slot.start).utc().format('DD/MM/YYYY');
						if(moment(slot.start).utc().format() > moment().format()) {
							if(slotMap[date]) {
									slotMap[date].push(slot);
							}
							else {
									slotMap[date] = [slot];
							}
						}
						
				}

				var result = Object.keys(slotMap).map(slotDate => {
						return {
								date: slotDate,
								slots: slotMap[slotDate]
						}
				});
				
				setSlots(result);
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		}
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const [selectedSlot, setSelectedSlot] = useState({_id: null,slot: null, date: null});

	const setSlot = (value) => {
		setSelectedSlot({_id: value.slot._id, slot: value.slot, date: value.date});
	}

	const gotoCheckout = () => {
		if(selectedSlot._id && selectedSlot.date) {
			
			Cookies.set('slot', JSON.stringify(selectedSlot.slot), { path: '' });

			dispatch({
				type: SET_DATES_APPOINTMENTS,
				payload: {
					slot: selectedSlot.slot,
					date: selectedSlot.date,
					doctor: id,
					patientId: props.match.params.patientId
				}
			});
			props.history.push(`/subuser/book-appointment-form/${props.match.params.patientId}`);

		}
	}
	
	return (
		<div>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<StyledHeader>
									
									<StyledBackTo>
										<Link to={`/subuser/doctor-listing/${props.match.params.patientId}`}>
											<i className="fa fa-arrow-left" />
											Back to listing page
										</Link>
									</StyledBackTo>
										
									
									<StyledTitle>
										{doctor ? doctor.name+'(Available Slots)' : ''}
									</StyledTitle>
									<StyledAddEditPatient></StyledAddEditPatient>
								</StyledHeader>
							</CardComponent>

							<div className="row mt-3">
								<div className="col-12 col-sm-4 col-md-6">
									<h4 className="mb-1">Today - {moment().format('Do MMMM YYYY')}</h4>
									<p className="text-muted">{moment().format('dddd')}</p>
								</div>
							</div>

							<CardComponent className="p-4">
								<div class="schedule-header">
									<div class="row">
										<div class="col-md-12">
											<div class="day-slot">
												<ul>
													{
														slots && slots.map((item, i) => {
															return <li key={i}>
																				<span>{item.date}</span>
																				<StyledSlots>
																					{
																						item.slots && item.slots.map((slot, index) => {
																							return 	<li key={index}><a className={selectedSlot._id === slot._id && selectedSlot.date === item.date ? 'selected' : ''} onClick={() => setSlot({slot: slot, date: item.date})}>{moment(slot.start).utc().format('hh:mm a')} - {moment(slot.end).utc().format('hh:mm a')}</a></li>
																						})
																					}
																				</StyledSlots>
																			</li>
																	
														})
													}
												</ul>
												
												{
													slots.length === 0 && (
														<h5>There is no slot available</h5>
													)
												}

												
											</div>
										</div>
									</div>
								</div>
							</CardComponent>

							<div class="submit-section proceed-btn text-right">
								{
									slots.length > 0 && (
									<StyledButtonPay class="btn btn-primary submit-btn" onClick={gotoCheckout}>
										Proceed
									</StyledButtonPay>
									)
								}
								
							</div>

						</div>
					</div>
				</div>
			</div>	
		</div>
	)
}

export default SubAdminBookingSlots

import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Tables from '../../controls/Tables';
import strings from '../../../constant/strings';
import { ePrescriptionHeadingList } from '../../../constant/staticLists';
import { dashoboardUseStyles } from '../../../assets/styles/DoctorDashboardStyles';
import { getPatientEprescriptionAction } from '../../../redux/user/userActions';
import { LoadingContainer, NoDetailsContainer } from '../../../assets/styles/CommonStyles';

export default React.memo(function EPrescription({ navigate }) {
  const classes = dashoboardUseStyles();
  const dispatch = useDispatch()
  const { userData, patientEprescription, patientAppointmentInfoListLoader } = useSelector((state) => ({
    userData: state.auth.userData,
    patientEprescription: state.user.patientEprescription, 
    //patientPrescription: state.doctorAppointment.patientPrescription,
    //ePrescriptionByPatient: state.user.patientEprescription,
    patientAppointmentInfoListLoader: state.user.patientAppointmentInfoListLoader
  }));

  console.log('patientEprescription', patientEprescription)

  useEffect(() => {
   navigate!== "doctor" && dispatch(getPatientEprescriptionAction({ token: userData.token }))
  }, [])

  return <>
    {navigate === 'doctor' &&
      <div className={`${classes.headingTextStyle} ${classes.margin}`}>
        {strings.prevPrescription}
      </div>
    }
    {
       patientAppointmentInfoListLoader ? <LoadingContainer>{strings.loading}</LoadingContainer> : !!patientEprescription && patientEprescription.length > 0 ?
        <Tables
          data={patientEprescription}
          fieldsOrder={ePrescriptionHeadingList}
        />
        :
        <NoDetailsContainer className={classes.noDataTextStyle}>{strings.npPrescriptionAvailable}</NoDetailsContainer>
    }
  </>
})
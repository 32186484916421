import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import defaultUserPng from "../../../../src/assets/img/user.png";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { StyledCrossIcon, StyledHeadingText01, UserImage } from "../../../assets/styles/CommonStyles";
import { profileStyles } from "../../../assets/styles/DoctorProfileSettingStyles";
import { useStyles } from "../../../assets/styles/PatientDashboardStyles";
import strings from "../../../constant/strings";
import TabComponent from "../../Tab";
import UploadFile from "../../controls/UploadFile";
import { subadminlogout, updateProfile, updateProfileSubadmin } from '../../../services/authService';
import { toast } from "react-toastify";
import { patientProfile } from "../../../redux/user/userActions";
import { logoutAction, subadminlogoutAction } from "../../../redux/auth/authActions";
import axios from "axios";
import { subadminProfileAction } from "../../../redux/user/subadmin/subadminAction";
import { PATIENT_UPLOAD, SUBADMIN_UPLOAD } from "../../../services/apiUrl";
import * as mainService from "../../../services/mainService"
const ProfileSetting = ({tabValue, setTabValue, tabs, profileHead}) => {
  const dispatch = useDispatch();
  const styleClasses = commonMuiStyles();
  const profileSettingClasses = profileStyles();
  const classes = useStyles();
  const history = useHistory();
  const inputRefs = useRef();
  const [image, setImage] = useState();
  const [values, setValues] = useState();
  const [onLoading, setLoading] = useState(false);
  const [fileType, setFileType] = useState();
  const { patientProfileData, userData,subadminProfileData } = useSelector(state => ({
    patientProfileData: state.user.patientProfile || {},
    userData: state.auth.userData,
    subadminProfileData: state.subadmin.subadminProfileData,
}))
// useEffect(() => {
//   dispatch(subadminProfileAction(userData.token));
// }, []);
// console.log("subadminProfileData",subadminProfileData)
  const handleCross = () => {
    history.goBack();
  };
  const userType = localStorage.getItem("userType");
const url = userType === 'patient'?PATIENT_UPLOAD:SUBADMIN_UPLOAD
  const handleTab = (e, newValue) => {
    setTabValue(newValue);
  };
  useEffect(()=>{
    if (image){
      handleSubmit()
    }
  },[fileType])
  const handleSubmit = async (event) => {
    // event.preventDefault();
    
    const formData = new FormData();
    formData.append('file', image);

    try {
      console.log("formdata",formData)
      const response = await mainService.fileUpload(url, formData,userData.token) 
     
      await setValues(response?.response?.data)
      if (image){
        await  handleSubmitupdate(response?.response?.data)
      }
  
     
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleSubmitupdate = async (values) => {
    setLoading(true);
    if (userType ==="patient"){
      const profileimg ={
        profileImage:values
      }

 
      const res = await updateProfile({
        token: userData.token,
        data: profileimg,
      });
      console.log("res",res)
     
      if (res.response_type === "fail") {
        setLoading(false);
        console.log("jjj")
        toast.error(res?.response?.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 2000,
          style: { width: "auto", borderRadius: 8 },
        });
      } else {
        setLoading(false);
        await dispatch(patientProfile(userData.token));
        toast.success("Profile updated successfully.", {
          position: "bottom-center",
          closeButton: false,
          autoClose: 2000,
          style: { width: "auto", borderRadius: 8 },
        });
        if (userType==="patient"){
          dispatch(logoutAction(userData.token))
        }else {
          dispatch(subadminlogoutAction(userData.token))
        }
     
      }
  

    }else {
      console.log("values",values)
      const profileimg ={
        profile_image:values
      }
      const res = await updateProfileSubadmin({
        token: userData.token,
        data: profileimg,
      });
      console.log("res",res)
     
      if (res.response_type === "fail") {
        setLoading(false);
        console.log("jjj")
        toast.error(res?.response?.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 2000,
          style: { width: "auto", borderRadius: 8 },
        });
      } else {
        setLoading(false);
        // await dispatch(patientProfile(userData.token));
        toast.success("Profile updated successfully.", {
          position: "bottom-center",
          closeButton: false,
          autoClose: 2000,
          style: { width: "auto", borderRadius: 8 },
        });
        if (userType==="patient"){
          dispatch(logoutAction(userData.token))
        }else {
          dispatch(subadminlogoutAction(userData.token))
        }
     
      }
  

    }
  
    // console.log("profileimg",profileimg)
    
  //   const res = await updateProfile({
  //     token: userData.token,
  //     data: profileimg,
  //   });
  //   console.log("res",res)
   
  //   if (res.response_type === "fail") {
  //     setLoading(false);
  //     console.log("jjj")
  //     toast.error(res?.response?.message, {
  //       position: "bottom-center",
  //       closeButton: false,
  //       autoClose: 2000,
  //       style: { width: "auto", borderRadius: 8 },
  //     });
  //   } else {
  //     setLoading(false);
  //     await dispatch(patientProfile(userData.token));
  //     toast.success("Profile updated successfully.", {
  //       position: "bottom-center",
  //       closeButton: false,
  //       autoClose: 2000,
  //       style: { width: "auto", borderRadius: 8 },
  //     });
  //     if (userType==="patient"){
  //       dispatch(logoutAction(userData.token))
  //     }else {
  //       dispatch(subadminlogoutAction(userData.token))
  //     }
   
  //   }

  };

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        className={`${classes.mainCont} ${profileSettingClasses.mainSecCont}`}
      >
        <StyledHeadingText01>{profileHead}</StyledHeadingText01>
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="x"
          onClick={handleCross}
        />
      </Grid>
      <Grid item xs={12} className={profileSettingClasses.imageCont}>
        <div>
          <IconButton
            sx={{
              position: "absolute",
              // top: "200px",
              right: "calc(50% - 55px)",
            }}
          >
            <BorderColorIcon onClick={() => inputRefs.current.click()} />
          </IconButton>

          <UploadFile
            inputRefs={inputRefs}
            image={image}
            setImage={setImage}
            fileType={fileType}
            setFileType={setFileType}
            limit={5}
            navigate="single"
          />
          <UserImage
            // src={ image || orgProfileData?.logo || defaultUserPng }
            src={image ||subadminProfileData?.profile_image  || defaultUserPng}
            className={profileSettingClasses.imageStyle}
            alt="Profile Picture"
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TabComponent
          tabs={tabs}
          tabValue={tabValue}
          setTabValue={setTabValue}
          handleTab={handleTab}
          customTab={styleClasses.customTab}
          customTabIndicator={styleClasses.customTabIndicator}
        />
      </Grid>
    </Grid>
  );
};

export default ProfileSetting;

import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import CardComponent from '../../components/Card';

function VideoConsultationLand() {
	return (
		<div>

			<Breadcrumb
				backRoute={[{id: 1, route: 'Home'}]}
				currentRoute='Video / Text Consultations in your Virtual Practice'
				title='Video / Text Consultations in your Virtual Practice' />

			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-lg-8">
							
							<CardComponent  className="p-4 mb-4">
								<div className="widget about-widget">
									<h4>Video / Text Consultations in your Virtual Practice</h4>
									<p>Using the Virtual Practice tool, you can meet more patients every day. An excellent, quick, and efficient tool helps follow-up with your patients, wherever they are located. Elderly patients and others with disabilities who are located in a distant place can easily connect with you. With greater collaboration, you can follow up with them for proper health care and monitor their health in a better way.
									</p>
									<br />
									<h4>How Video Consultation works using Virtual practice?</h4>
									<div className="experience-box">
										<ul className="">
											<li>Set up your timings</li>
											<li>Define the payments</li>
											<li>Manage video appointments</li>
											<li>Send consultation summaries</li>
											<li>Collect the payments</li>
										</ul>
									</div>

									<p>The main benefit of video consultation is that it helps you manage your time effectively while seeing more patients at the same time. You can define suitable timings for video consultation sessions. Telemedicine is an excellent addition to the source of revenue, which you can add to your practice. Setup the payments and make sure to receive the fees for the same from the patients. Further, you can schedule appointments for telemedicine sessions online or using the mobile app. Secondly, you are at liberty to reschedule or even cancel the appointments if any circumstances arise. The patients will be automatically notified of the status and plan accordingly. </p>
									<br />

									<p>As the patients will require an email consultation summary after the session is complete, they can be shared with e-prescriptions, appointment reminders, treatment goals, invoices, monitoring plans, etc. For the payments, the patients can pay you using the website or through the mobile app. Earn, more as you see more patients daily. </p>
									<br />

									<p>Talking about accessibility and other terms, you can answer patient queries without affecting your time. It helps the patients about their health or medication they need.  </p>
									<br />

									<p>Look at how conveniently the communication takes place when patients put forward their health queries.  </p>
									<div className="experience-box">
										<ul className="">
											<li>Setup text consultation</li>
											<li>Define your consultation charges</li>
											<li>Answer patient queries</li>
											<li>Receive payments online</li>
										</ul>
									</div>

									<p>You can define your response time for the next consultation, which helps the patients put forward to follow up queries—Setup the charges for text consultation that again is additional revenue for your practice. The patients will need to pay consultation charges beforehand, ensuring they are duly compensated for your time.   </p>
									<br />

									<p>You can also collect the payments for remote monitoring subscriptions, possibly using the Virtual Practice communication app. It is a great tool, which reduces the need to invest in different communication mechanisms with patients.    </p>
									<br />

									<p>Are you practicing at a different location or going away on vacation? You can quickly inform your patients via other notifications, which they will receive using email or SMS—an easy way to keep your patients informed - The Virtual Practice tool.     </p>
									<br />
									
									
								</div>
							</CardComponent>
						</div>

						<div className="col-lg-4 features-img mt-5 pt-5">
							<img src="/assets/img/land2.png" className="img-fluid" alt="Feature" />
						</div>

					</div>
				</div>
			</div>

		</div>
	)
}

export default VideoConsultationLand

import styled from "styled-components";
import { ReactComponent as RightArrow } from '../../assets/img/right-arrow1.svg';


export const CardContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	border: 0;
	width: -webkit-fill-available;
	height: -webkit-fill-available;

	display: flex;
    align-items: center;
	margin: 0 0 0 70px;
	@media (max-width: 1050px) {
		justify-content: center;
		margin: 0;
	}
	&.other {
		margin: 0;	
	}
`;

export const HoverCard = styled.div`
	box-sizing: border-box;

	width: 501px;
	height: 493px;
	// left: 70px;
	// top: 116px;

	background: linear-gradient(106.69deg, rgba(255, 255, 255, 0.4) 1.18%, rgba(255, 255, 255, 0.2) 100%);
	border: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
	border-radius: 18px;

	padding: 48px 32px 41px 24px;
	@media (max-width: 640px) {
		// left: 6px;
		// width: calc(100% - 12px);
		// top: calc(100% - 250px);
		// height: fit-content;
		// bottom: 6px;
		// right:6px;
		// padding: 8px 5px 8px;
		// border-radius: 5px;
		border-radius: 5.2211px;
	}
	@media (min-width: 400px) and (max-width: 1050px) {
		padding: 18px 14px;
		height: auto;
	}
	
	@media (min-width: 530px) and (max-width: 600px) {
		padding: 10px 10px 8px;
		height: auto;
	}

	@media (min-width: 400px) and (max-width: 530px) {
		padding: 8px 8px 6px;
		margin: 1vw;
	}
	
	@media (min-width: 330px) and (max-width: 400px) {
		padding: 8px 8px 5px;
		height: auto;
		margin: 6px;
		width: calc(100% - 40px);
	}
	
	@media (max-width: 330px) {
		padding: 8px 8px 5px;
		height: auto;
		margin: 6px;
	}
	@media (max-width: 320px) {
		padding: 8px 8px 5px;
		height: auto;
		margin: 6px;
	}

`;
export const HoverCardOne = styled.div`
	box-sizing: border-box;

	position: absolute;
	width: 501px;
	height: 259px;
	left: 80px;
	top: calc(50% - 120px);

	background: linear-gradient(106.69deg, rgba(255, 255, 255, 0.6) 1.18%, rgba(255, 255, 255, 0.2) 100%);
	border: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
	border-radius: 18px;
	padding: 48px 24px 49px 24px;
	@media (max-width: 640px) {
		left: 20px;
		width: calc(100% - 40px);
		top: calc(50% - 50px);
		height: fit-content;
		bottom: 16px;
		padding: 25px 5px 8px;
		
		border-radius: 6px;
		padding: 25px 4vw 11px;
	}
`;

export const HoverCardTwo = styled.div`
box-sizing: border-box;

position: absolute;
width: 501px;
height: 259px;
 right: 80px;
 top: calc(50% - 120px);

background: linear-gradient(106.69deg, rgba(255, 255, 255, 0.6) 1.18%, rgba(255, 255, 255, 0.2) 100%);
border: 1px solid rgba(255, 255, 255, 0.2);
box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
border-radius: 18px;
padding: 48px 24px 49px 24px;
@media (max-width: 640px) {
	left: 36px;
	height: fit-content;
	left: 20px;
	width: calc(100% - 40px);
	top: calc(50% - 50px);
	padding: 25px 5px 8px;
	
	border-radius: 6px;
	padding: 25px 4vw 11px;
}
`;


export const CardTitle = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 42px;
color: #FFFFFF;

// @media (max-width: 640px) {
	// color: #FFFFFF;
	// font-weight: 700;
	// font-size: 16px;
	// line-height: 18px;
	// font-size: 3vw;
	// }

	@media (min-width: 530px) and (max-width: 850px) {
		font-size: 28px;
		line-height: 30px;
	}

	@media (min-width: 400px) and (max-width: 530px) {
		font-size: 18px;
		line-height: 20px;
	}

	@media (max-width: 400px) {
		font-size: 14px;
		line-height: 18px;
	}

	@media (max-width: 320px) {
		font-size: 14px;
		line-height: 18px;
	}
`;

export const ImageMain = styled.img`
	position: absolute;
	width: 90px;
	height: 90px;
	top: -45px;
	left: calc(50% - 45px);

	@media (max-width: 640px) {
		width: 60px;
		height: 60px;
		top: -30px;
		left: calc(50% - 30px);
	
	}
`;


export const ButtonTitle = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;
	color: #FFFFFF;
	margin:0;

	// @media (max-width: 640px) {
	// 	font-weight: 400;
	// 	font-size: 12px;
	// 	line-height: 16px;
	// }

	

	@media (min-width: 400px) and (max-width: 530px) {
		font-size: 14px;
    	line-height: 22px;
	}

	@media (max-width: 400px) {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}

	@media (max-width: 320px) {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}
`;

export const CardTitleTwo = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */


color: #FFFFFF;

`;
export const CardSubPoints = styled.li`
color: #FFFFFF;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 32px;
@media (max-width: 640px) {
	font-weight: 500;
font-size: 12px;
line-height: 24px;

	
	}

`;
export const CardSub = styled.ul`
	padding-inline-start:24px;
	margin: 0;
	@media (max-width: 640px) {
		padding-inline-start:16px;
		}
`;

export const CardDescription = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #FFFFFF;
	
	margin: 16px 0 0;
	// @media (max-width: 640px) {
	// 	font-weight: 500;
	// 	font-size: 11px;
	// 	line-height: 12px;
	// 	margin: 2px 0 0;
	// }

	@media (min-width: 530px) and (max-width: 850px) {
		font-size: 14px;
    	margin: 7px 0 0;
    	line-height: 20px;
	}

	@media (min-width: 460px) and (max-width: 530px) {
		font-size: 14px;
		margin: 5px 0 0;
		line-height: 17px;
	}

	@media (min-width: 400px) and (max-width: 460px) {
		font-size: 12px;
		margin: 5px 0 0;
		line-height: 14px;
	}
	

	@media (max-width: 400px) {
		font-size: 8px;
    	line-height: 12px;
		margin: 2px 0 0;
	}

	@media (max-width: 320px) {
		font-size: 8px;
    	line-height: 12px;
		margin: 2px 0 0;
	}
`;

export const CardButton = styled.div`
	width: 100%;
	height: 48px;
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	border-radius: 8px;
	margin: 25px 0 0;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	color: #FFFFFF;

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	
	// @media (max-width: 640px) {
	// 	width: 90%;
	// 	height: 24px;
	// }

	@media (min-width: 530px) and (max-width: 850px) {
		height: 40px;
    	margin: 10px 0 0;
	}

	@media (min-width: 400px) and (max-width: 530px) {
		height: 35px;
		margin: 6px 0 0;
	}
	
	@media (max-width: 400px) {
		margin: 6px 0 0;
		height: 24px;
		left: 8px;
		background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
		border-radius: 3.30787px;
	}

	@media (max-width: 320px) {
		margin: 6px 0 0;
		height: 24px;
		left: 8px;
		background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
		border-radius: 3.30787px;
	}
`;
export const RightArrowIcon = styled(props => <RightArrow {...props}/>)`
	margin-left: 11px;
	// @media (max-width: 640px) {
	// 	margin-left: 5px;
	// }

	@media (max-width:400px) {
		height: 9px;
	}

	@media (max-width:320px) {
		height: 9px;
	}
`;

export const PlayIcon = styled.div`
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Image = styled.img`
	width: 10vw;	
`;

export const BackgroundImage = styled.img`
	width:100%;
	height: 100%;
	filter: drop-shadow(-10.662px -10.662px 39.9826px rgba(192, 192, 192, 0.25)) drop-shadow(10.662px 10.662px 39.9826px rgba(192, 192, 192, 0.25));
`;

export const TrailButton = styled.div`
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	border-radius: 8px;
	width: 445px;
	height: 48px;
	display: flex;
    justify-content: center;
    align-items: center;

	text-transform: uppercase;
	color: #FFFFFF;

	@media (max-width:450px) {
		width: 100%;
    	min-width: 250px;
	}
`;

export const Text = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	// font-size: 56px;
	font-size: 4vw;
	// line-height: 72px;
	line-height: 7vw;
	text-align: center;
	color: #2B2B2B;
	margin: 12px 0;
`;

export const CardBlock = styled.div`
	position: relative;
`;


// height: 9px;
import { useEffect, useState } from "react";
import { ArrowIcon, BottomBar, ContentArea, CrossButton, Down, Input, NavigateArrow, ProgressBar, QuestionTitle, QuestionWrapper, RightArrow, SubmitButton, Up } from "./styles";

export const Crossed = ({ isClose }) => <CrossButton onClick={() => isClose(false)} />

export const UpAndDown = () => (
    <NavigateArrow>
        <Down left check={true} onClick={() => console.log('Working')}><RightArrow down /></Down>
        <Up check={true} onClick={() => console.log('Working')}><RightArrow up /></Up>
    </NavigateArrow>
)

export const BigInputField = ({answer, setAnswer}) => (
    <>
        <Input placeholder='Type your answer here...' value={answer} onChange={(e)=> setAnswer(e.target.value)} />
        <BottomBar />
    </>
)


export const SingleQuestions = ({ question, setQuestion }) => {
    const [answer, setAnswer] = useState('');
    useEffect(() => {
        setAnswer('')
    },[question?.idx])
    return (
        <QuestionWrapper>
            <ProgressBar>
                <ProgressBar className='colored'></ProgressBar>
            </ProgressBar>
            <Crossed isClose={setQuestion} />
            <ContentArea>
                <QuestionTitle>{question?.ques}</QuestionTitle>
                <BigInputField answer={answer} setAnswer={setAnswer} />
                <SubmitButton className='centered' isVisible={true}>Submit</SubmitButton>
            </ContentArea>
            <UpAndDown />
        </QuestionWrapper>
    )
}
import React from 'react';

import {
	StyledContainer
} from './styles';

function TableComponent(props) {
	return (
		<StyledContainer className="table-responsive">
			<table className="table table-hover table-center mb-0">
				<thead>
					<tr>
						{props.tableHeading.map((item) => (
							<th key={item.id}>{item.title}</th>
						))}
					</tr>
				</thead>
				<thead>
					{props.children}
				</thead>
			</table>
		</StyledContainer>
	)
}

export default TableComponent

import React, { useEffect } from 'react'
import InputSearch from '../../../components/InputSearch';
import { H1Ttitle, H3FAQ, H3SubHeading, NoResultText, QuestionariesHeading, QuestionaryTitle, SectionFAQ, SectionHeading, TextWrapper, Wrapper, WrapperInput } from './styles';
import { SingleQuestion } from './utility';

const Question = 'What is an electronic or telemedicine consultation?'
const Question2 = 'What is watch about'
const Question3 = 'What is medication'
const Question4 = 'What is IPD ward'
const Answer = 'An electronic or telemedicine consultation is a medical review which involves remote communication between patients and clinicians which can include medical specialists or allied health professionals. In some common medical conditions telemedicine consultation could prove to be the quickest way to consult a doctor or a health professional and receive a safe and unbiased health advice in the comfort of your home.'

const data = {
  AboutDevices:
  {
    title: 'About Devices',
    questions: [{ question: Question, answer: Answer }, { question: Question2, answer: Answer }, { question: Question3, answer: Answer }, { question: Question4, answer: Answer }, { question: Question, answer: Answer }]
  },
  AboutHMS:
  {
    title: 'About HMS',
    questions: [{ question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }]
  }
}

const styles = { marginLeft: '10px' }

function AboutPage() {
  const aboutDevices = data.AboutDevices.title;
  const aboutDevicesQues = data.AboutDevices.questions;

  const aboutHMS = data.AboutHMS.title;
  const aboutHMSQues = data.AboutHMS.questions;

  const [initial, changeInitial] = React.useState(aboutHMS);
  const [search, setSearch] = React.useState('');
  const [collapsible, setCollapsible] = React.useState(false);
  const current = 'AboutHMS';
  const [collapsibleData, setCollapsibleData] = React.useState(data[current]?.questions);
  const handleCollapsible = (item) => {
    const singleUpdateData = collapsibleData.map((ques) => {
      if (ques.index === item) return { ...ques, open: !ques.open }
      else return ques;
    });
    setCollapsibleData(singleUpdateData);
  }
  useEffect(() => {
    const newData = data[current]?.questions.map((ques, index) => ({ ...ques, open: false, index: index + 1 }));
    setCollapsibleData(newData);
  }, [current])

  const getCollapsibleData = () => {
    return search.length ? collapsibleData.filter((val) => val.question.toLowerCase().includes(search.toLowerCase())) : collapsibleData
  }

  return (
    <Wrapper>
      <SectionHeading>
        <H3FAQ>FAQs</H3FAQ>
        <H1Ttitle className="colored">Frequently Asked <H1Ttitle style={styles}> Questions </H1Ttitle> ?</H1Ttitle>
        <H3SubHeading>Have questions? We’re here to help.</H3SubHeading>
        <WrapperInput>
          <InputSearch setSearch={(val) => setSearch(val)} search={search}/>
        </WrapperInput>
      </SectionHeading>
      <SectionFAQ>
        <QuestionariesHeading>
          {/* <TextWrapper border={aboutDevices === initial}><QuestionaryTitle className={(aboutDevices === initial) && 'colored'} onClick={() => changeInitial(aboutDevices)}>{aboutDevices}</QuestionaryTitle></TextWrapper> */}
          <TextWrapper border={aboutHMS === initial}><QuestionaryTitle className={(aboutHMS === initial) && 'colored'} onClick={() => changeInitial(aboutHMS)}>{aboutHMS}</QuestionaryTitle></TextWrapper>
        </QuestionariesHeading>
        {getCollapsibleData()?.length ? (
          getCollapsibleData()?.map(({ question, answer, open, index }) => <SingleQuestion key={index} question={question} answer={answer} condition={open} index={index} setCondition={handleCollapsible} />)
        ) : (
          <NoResultText>No exact match found</NoResultText>
        )}
      </SectionFAQ>

    </Wrapper>
  )
}

export default AboutPage;

import React, { useRef, useState } from "react";
import { Grid, Typography, Box, Button, IconButton } from "@mui/material";
import { UploadFileOutlined, CloseOutlined } from "@mui/icons-material";
import { Formik, Field, Form } from "formik";
import { makeStyles } from "@mui/styles";
import { DOCTOR_UPLOAD } from "../../../../services/apiUrl.js";
import * as mainService from "../../../../services/mainService.js"
import { useDispatch, useSelector } from "react-redux";
import { getDoctorAppointmentAction } from "../../../../redux/appointment/doctor/doctorAppointmentActions.js";
import { toast } from "react-toastify";
import colors from "../../../../constant/colors.js";
const useStyles = makeStyles((theme) => ({
  mainCont: {
    padding: 2,
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    position: "relative", // Position relative for the close button
  },
  fileUploadCont: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    border: "2px dashed #d0d0d0",
    padding: 2,
    borderRadius: "8px",
    textAlign: "center",
  },
  filetypes: {
    marginTop: 1,
    color: "#888",
    fontSize: "14px",
  },
  closeButton: {
    position: "absolute",
    top: 0, // Adjusted to move the button higher
    right: 8,
    transform: "translateY(-50%)", // Center the button vertically relative to its position
  },
  submitButton: {
    marginTop: 16,
    background: colors.green, // Gradient color
    color: "#fff",
    '&:hover': {
      background: "linear-gradient(to right, #FF7E5F, #FEB47B)", // Maintain gradient on hover
    }
  },
}));

const SendPrescription = ({setOpenModal,selectedListItem}) => {
    console.log("item",selectedListItem)
    // console.log("setTabvalue",setTabValue)
    
  const classes = useStyles();
  const fileInputRef = useRef();
  const { doctorProfileData, userData } = useSelector((state) => ({
    userData: state.auth.userData,
    doctorProfileData: state.doctor.doctorProfileData,
  }));
  const [value,setvalues]= useState();
  const url = DOCTOR_UPLOAD
  const dispatch = useDispatch()
  const handleFileUpload = async(event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File selected:", file);
    
      const formData = new FormData();
      formData.append('file', file);
  
      try {
       
        const response = await mainService.fileUpload(url, formData,userData.token) 
      
       console.log("response",response)

        await setvalues(response?.response?.data)
        // if (image){
        //   await  handleSubmitupdate(response?.response?.data)
        // }
    
      } catch (error) {
        console.error('Error:', error);
      }
      // Handle file upload logic here
    }
  };
 const submitprecription=async()=>{
    const data = {
        appointmentId:selectedListItem?._id,
        prescriptionUrl:value
      }
      const res = await mainService.getSendMedicationApi({payload:data,token:userData.token});
      if (res.response_type === "fail") {
      
        
        toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, x: { width: 'auto', borderRadius: 8 } });
      } else {
       
        setOpenModal(false);
       
        toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
        
        dispatch(getDoctorAppointmentAction({ token: userData.token, status: 4 }));
      }

 }
  const handleBoxClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  return (
    <div className={classes.mainCont}>
      <IconButton className={classes.closeButton} onClick={() => setOpenModal(false)}>
        <CloseOutlined />
      </IconButton>
      <Typography variant="h6">
        Upload Documents (you can upload up to 5 files and file size must be less than 10MB)
      </Typography>
      <Formik
        initialValues={{ documents: [] }}
        onSubmit={(values) => {
          // Handle form submission
          console.log("Form values:", values);
        }}
      >
        {() => (
          <Form>
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <Box className={classes.fileUploadCont} onClick={handleBoxClick}>
                  <Box>
                    <UploadFileOutlined />
                  </Box>
                  <Typography className={classes.filetypes}>
                    pdf, jpg, jpeg & png files
                  </Typography>
                  <input
                    type="file"
                    ref={fileInputRef}
                    name="documents"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Button type="submit" className={classes.submitButton} onClick={()=>{submitprecription()}}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SendPrescription;

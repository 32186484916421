import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { Context } from '../../store';
import {
	SET_USER_TYPE
} from '../../store/types';
import { Howl } from 'howler';
import ringtone from '../../ringtone.mp3';

import { leaveCall, setUser, unMuteAudioToggle, muteAudioToggle, resumeVideo, pauseVideo } from './Agora_RTC'
import './test.css';
import {
	MenuButton,
	MenuHeader,
	MobileFooterIcon,
	MobileScreenIcon,
	NavBar,
	StyledContainer,
} from './styles';
import CommonSignUpModal from './CommonSignUpModal';
import ContactUsModal from '../Contact';
import LoginModal1 from './LoginModal1';
import { useStyles } from '../../assets/styles/LoginStyles';
import { StyledModal } from '../../assets/styles/CommonStyles';
import { logoutAction ,doctorlogoutAction} from '../../redux/auth/authActions';
import { useDispatch,  useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';


// const apiUrl = 'https://api.emed4u.com';
// const socket = io(apiUrl);

const ringtoneSound = new Howl({
	src: [ringtone],
	loop: true,
	preload: true
})

const flexSpaceBetween = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}
const width = { width: '30%' }

const menus = [
	{ id: 2, title: 'Technology', route: '/hms', submenu: false },
	{ id: 3, title: 'About Us', route: '/about', submenu: false },
	{ id: 4, title: 'Contact Us', route: '/contact-us', submenu: false },
]

function HeaderComponent(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	// const [state, dispatch] = useContext(Context);
	const [channel, setChannel] = useState('')
	const [token, setToken] = useState('')
	const [isjoin, setIsJoin] = useState(false)
	const [uuid, setUuid] = useState('');
	const [doctorName, setDoctorName] = useState('');
	const [tableToggle1, setTableToggle1] = useState(false);
	const [loginToggle, setLoginToggle] = useState(false);
	const location = window.location.pathname;
	const [doctorProifleImage, setDoctorProifleImage] = useState('');
	const [callType, setCallType] = useState('audio');
	const [isSignUpModal, setSignUpModal] = useState(false);
	const [userLogin, setUserLogin] = useState(false);
	const [userType, setUserType] = useState('');
	const [userName, setUserName] = useState('');
	const [muteAudio, setMuteAudio] = useState(false);
	const [stopVideo, setStopVideo] = useState(false);
	const [currentSubAdmin, setCurrentSubAdmin] = useState(null);
	const [loggedInUser,setloggedInUser]=React.useState(localStorage.getItem('userType'));
    const history = useHistory();

	const pathMain = window.location.pathname;
	const { isAuthenticated,userData } = useSelector((state) => ({
		isAuthenticated: state.auth.isAuthenticated,
		userData: state.auth.userData
	}));

	const handleOpen = () => {
		document.body.style.overflowY = 'auto'
		setTableToggle1(tableToggle1 => !tableToggle1);
	}

	const handleLoginOpen = () => {
		document.body.style.overflowY = 'auto'
		setLoginToggle(loginToggle => !loginToggle);
	}

	useEffect(()=> {
		if(history.location.state!== undefined)
		setLoginToggle(true);
	}, [history.location.state])

	const handleSignUpOpen = () =>{
		setSignUpModal(false);
	}
	useEffect(() => {
		if (JSON.parse(localStorage.getItem('customer'))) {
			setUserLogin(true);
			setUserType('customer');
			// dispatch({
			// 	type: SET_USER_TYPE,
			// 	payload: 'customer'
			// });
			setUserName(JSON.parse(localStorage.getItem('customer')).name)
		} else if (JSON.parse(localStorage.getItem('doctor'))) {
			setUserLogin(true);
			setUserType('doctor');
			// dispatch({
			// 	type: SET_USER_TYPE,
			// 	payload: 'doctor'
			// });
			setUserName(JSON.parse(localStorage.getItem('doctor')).name)
		} else if (JSON.parse(localStorage.getItem('organization'))) {
			setUserLogin(true);
			setUserType('organization');
			// dispatch({
			// 	type: SET_USER_TYPE,
			// 	payload: 'organization'
			// });
			setUserName(JSON.parse(localStorage.getItem('organization')).org_name)
		} else if (JSON.parse(localStorage.getItem('subuser'))) {
			setUserLogin(true);
			setUserType('subuser');
			// dispatch({
			// 	type: SET_USER_TYPE,
			// 	payload: 'subuser'
			// });
			setUserName(JSON.parse(localStorage.getItem('subuser')).name)
		}
	}, [])

	useEffect(() => {
		// const data = state.callingData;
		// if (data) {
		// 	setChannel(data.channel)
		// 	setToken(data.refreshToken)
		// 	setUuid(data.uuid)
		// 	setDoctorProifleImage(data.doctorImage);
		// 	setDoctorName(data.doctorName);
		// 	setCallType(data.type)
		// 	setUser('Patient');
		// }
	}, [])

	const handleClickLeave = () => {
		// const user = {
		// 	appointmentId: channel,
		// 	from: 'Patient'
		// };
		// socket.emit("rejectCall", user);
		leaveCall(callType)
		setIsJoin(false)
		ringtoneSound.unload();
		ringtoneSound.stop();
		setTimeout(() => {
			window.location.reload();
		}, 1000)
	}

	const onSetTitle = (title) => {
		if (currentSubAdmin === title) setCurrentSubAdmin(null);
		else setCurrentSubAdmin(title)
	}

	const getDashboardRoute = () => {
	//let user = localStorage.getItem("userType")
  let path = "";
	switch(loggedInUser) {
    case "patient" : path="patient/dashboard"
    break;
    case "doctor" : path="doctor/schedule"
    break;
  }
  history.push(path)
	}

	const onSetLogout = async() => {
		dispatch(logoutAction(userData.token));	
		(loggedInUser==="doctor") ? dispatch(doctorlogoutAction(userData.token)):dispatch(logoutAction(userData.token));	
		switch(loggedInUser) {
			
			case "doctor" : dispatch(doctorlogoutAction(userData.token))
			break;
			case "patient" : dispatch(logoutAction(userData.token));
			break;
		}
	}

	const handleMuteAudio = () => {
		if (muteAudio) {
			setMuteAudio(false);
			unMuteAudioToggle()
		} else {
			setMuteAudio(true);
			muteAudioToggle()
		}
	};

	const handleStopVideo = () => {
		if (stopVideo) {
			setStopVideo(false);
			resumeVideo()
		} else {
			setStopVideo(true);
			pauseVideo()
		}
	};

	return (
		<StyledContainer>
			<header className="header">
				<NavBar className={`navbar navbar-expand-lg header-nav ${location == '/' || location == '/doctor' || location == '/organization' || location == '/subuser' ? 'landing-page-header' : ''}`}>
					<div className="navbar-header">
						<a id="mobile_btn" href="javascript:void(0);">
							<MenuButton />
						</a>
						<Link to='/' className="navbar-brand logo">
							<img src="/assets/img/logo.png" className="img-fluid" alt="Logo" />
						</Link>
					</div>
					<div className="main-menu-wrapper">
						<div className="menu-header">
							<a id="menu_close" className="menu-close" href="javascript:void(0);">
								<i style={{ fontSize: '28px' }} className="fas fa-times"></i>
							</a>
							<Link id="menu_close" to='/' className="menu-logo">
								<img src="/assets/img/logo.png" className="img-fluid" alt="Logo" />
							</Link>
							<div className="menu-logo"></div>
						</div>
						<div>
							<ul className="main-nav home-nav-bar">
								<MenuHeader className="heading-navbar hide-screen">Profile</MenuHeader>
								{isAuthenticated ? (
									<>
										<li className="login-link hide-screen">
											<Link className="padding-remove" to style={{ cursor: 'pointer' }} onClick={getDashboardRoute} >Dashboard</Link>
										</li>
										<li className="login-link hide-screen">
											<a className="padding-remove" href onClick={() => onSetLogout()}>Logout</a>
										</li>
									</>
								) : (
									<>
										<li id='menu_close' className="login-link hide-screen">
											<div
												className="padding-remove"
												href
												onClick={() => {
													setLoginToggle(loginToggle => !loginToggle);
													document.body.style.overflowY = 'hidden';
												}}
											>
												Login
											</div>
										</li>
										<li id="menu_close" className="login-link hide-screen">
											<a
												className="padding-remove"
												href
												onClick={() => setSignUpModal(!isSignUpModal)}>Signup</a>
										</li>
									</>
								)}
								<MenuHeader className="heading-navbar hide-screen">Features</MenuHeader>
								{menus.map(menu => {
									return <>
										{!menu.submenu
											? <li className={menu.title == "Contact Us" ? '' : pathMain === menu.route ? "active" : ''}>
												{menu.title == "Contact Us" ?
													<Link
														to = {menu.route}
														className="padding-remove"
														
													>
														{menu.title}
													</Link> :
													<Link className="padding-remove"
														id="menu_close"
														to={menu.route}>{menu.title}</Link>
												}
											</li>
											: <li className="has-submenu" onClick={() => onSetTitle(menu.title)}>
												<a href style={{ cursor: 'pointer' }} className={currentSubAdmin === menu.title ? 'submenu' : ''}>{menu.title}</a>
											</li>
										}
									</>
								})}
							</ul>
							<MobileFooterIcon className='mobile-screen-footer-icon hide-screen'>
								<MobileScreenIcon className="mobile-screen-icon">
									<li>
										<a href="https://www.facebook.com/emed4u" target="_blank">
											<img src="/assets/img/Frame5.svg" />
										</a>
									</li>
									<li>
										<a href="https://twitter.com/emed4u" target="_blank">
											<img src="/assets/img/Frame3.png" />
										</a>
									</li>
									<li>
										<a href="https://www.instagram.com/emed4u/" target="_blank">
											<img src="/assets/img/Frame2.svg" />
										</a>
									</li>
									<li>
										<a href="https://www.instagram.com/emed4u/" target="_blank">
											<img src="/assets/img/Frame4.png" />
										</a>
									</li>
								</MobileScreenIcon>
							</MobileFooterIcon>
						</div>
					</div>
					<ul className="nav main-nav header-navbar-rht">
						{!isAuthenticated && <li className="nav-item contact-item">
							<div className="header-contact-detail">
								<span
									className="contact-header link-style"
									onClick={() => {
										setLoginToggle(loginToggle => !loginToggle);
										document.body.style.overflowY = 'hidden'
									}}
								>
									Login
								</span>
								<span className="contact-header" style={{ margin: '0 7px', fontWeight: 500 }}>|</span>
								<span className="contact-header link-style" onClick={() => setSignUpModal(!isSignUpModal)} >Signup</span>
							</div>
						</li>}

						{
							isAuthenticated && <li className="has-submenu" >
								<a href className='submenu log-emed'style={{ cursor: 'pointer' }} >Welcome  {userName}<i className="fas fa-chevron-down"></i></a>
								<ul className="submenu">
									<li><div style={{ cursor: 'pointer' }} onClick={() => getDashboardRoute()}>Dashboard</div></li>
									<li><div style={{ cursor: 'pointer' }} onClick={() => onSetLogout()} >Logout</div></li>
								</ul>
							</li>
						}
					</ul>
				</NavBar>

				{/* <div className="modal custom-modal fade none-border show handleLoginOpen" style={{
					display: loginToggle ? 'block' : 'none', backdropFilter: 'blur(1px)',
					background: 'rgb(104 71 99 / 24%)'
				}}> */}
				{/* <div className="overlay-background-disable" /> */}
				<StyledModal
					open={loginToggle}
					onClose={handleLoginOpen}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<LoginModal1
						isSignUpModal={isSignUpModal}
						setLoginToggle={setLoginToggle}
						loginToggle={loginToggle}
						toggleLogin={() => {
							handleLoginOpen()
							setSignUpModal(true)
						}}
					/>
				</StyledModal>

				{/* </div> */}

				<ContactUsModal
					tableToggle1={tableToggle1}
					setTableToggle1={setTableToggle1}
					handleOpen={handleOpen} />
				<div class="modal fade call-modal" id="video_call">
					<div class="modal-dialog video-call-modal modal-dialog-centered" role="document">
						<div class="modal-content">
							<div class="modal-body">
								<div class="call-wrapper">
									<div class="call-main-row">
										<div class="call-main-wrapper">
											<div class="call-view">
												<div class="call-window">
													<div class="fixed-header">
														<div class="navbar">
															<div class="user-details">
																<div class="float-left user-img">
																	<a class="avatar avatar-sm mr-2" title="Charlene Reed">
																		<img src={doctorProifleImage ? `${doctorProifleImage}` : "/assets/img/doctor-empty.png"} alt="User Image" class="rounded-circle" />
																		<span class="status online"></span>
																	</a>
																</div>
																<div class="user-info float-left">
																	<a><span>{doctorName}</span></a>
																</div>
															</div>
														</div>
													</div>
													<div class="call-contents">
														<div class="call-content-wrap">
															<div class="user-video">
																{
																	callType === 'audio' ?
																		<img src="/assets/img/audio.png" class="img-fluid" alt="User Image" />
																		:
																		<div className='video-agora-box'></div>
																}
															</div>
															<div class="my-video">
																<ul>
																	<li style={{ height: isMobile ? '50px' : '120px' }}>
																		{
																			callType === 'audio' ?
																				<img src="/assets/img/audio.png" class="img-fluid" alt="User Image" />
																				:
																				<div id='video-agora-local'></div>
																		}
																	</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="call-footer" style={flexSpaceBetween}>
														<div style={width} />
														<div class="call-icons">
															<ul class="call-items">
																{callType === 'video' && <li class="call-item">
																	<a href="javascript:void(0);" title="Enable Video" onClick={() => handleStopVideo()} data-placement="top" data-toggle="tooltip" className={stopVideo ? 'unMuteMicrophone' : 'muteMicrophone'} >
																		{stopVideo ? <i class="fa fa-video-slash "></i> : <i class="fa fa-video"></i>}
																	</a>
																</li>}
																<li class="call-item">
																	<a href="javascript:void(0);" title="Mute Audio" onClick={() => handleMuteAudio()} data-placement="top" data-toggle="tooltip" className={muteAudio ? 'unMuteMicrophone' : 'muteMicrophone'} >
																		{muteAudio ? <i class="fa fa-microphone-slash "></i> : <i class="fa fa-microphone"></i>}
																	</a>
																</li>
															</ul>
														</div>
														<div class="end-call" style={width}>
															<a href="javascript:void(0);" class="btn call-item end-call" data-dismiss="modal" aria-label="Close" onClick={() => handleClickLeave()}>
																<i class="material-icons">call_end</i>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<StyledModal
					open={isSignUpModal}
					onClose={handleSignUpOpen}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<CommonSignUpModal
						isSignUpModal={isSignUpModal}
						setSignUpModal={setSignUpModal}
						toggleSignUp={() => {
							setSignUpModal(false);
							handleLoginOpen();
						}}
					/>
				</StyledModal>

			</header>
		</StyledContainer>
	)
}

export default HeaderComponent;

import React, { useEffect, useState } from 'react';
import { youtube } from '../../../HealthNetwork/BlogList/youtubeList';

import {
	EventModeTitle,
	EventsHeading,
	EventTitle,
	EventWrapper,
	HeaderContainer,
	SearchField,
	SectionEvents,
	TextWrapper,
} from './styles';
import { DesktopInputSearch, SingleQuestion } from './utility';

const dataObj = (index, link) => ({
	idx: index,
	link: link,
	time: 'SUN, SEPT 18 · 8:30 AM - 10:00 IST',
	event: 'Event on Health And Nutrition',
	location: 'Colambia  University',
	host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
})

const eventsArray = youtube?.map((item, index) => dataObj(index, item))

const data = {
	Offline:
	{
	  title: 'ONLINE EVENTS',
	  questions: eventsArray
	},
	Online:
	{
	  title: 'INFORMATIVE VIDEOS',
	  questions: eventsArray?.map((item) => ({...item, online: true, time: 'SUN, SEPT 18 · 8:30 AM IST'}))
	}
  }

function Events({initial, collapsibleData, changeInitial}) {
	// const aboutDevices = data.Offline.title;
	// const [initial, changeInitial] = React.useState(aboutDevices);
	// const current = aboutDevices === initial ? 'Offline' : 'Online';

	// const [collapsibleData, setCollapsibleData] = React.useState(data[current]?.questions);
	// useEffect(() =>{
	// 	setCollapsibleData(data[current]?.questions)
	// },[current])
	return (
		<EventWrapper>
			<HeaderContainer>
				<EventTitle>Events</EventTitle>
				{/* <SearchField> <DesktopInputSearch /> </SearchField> */}
			</HeaderContainer>
			<SectionEvents>
				<EventsHeading>
					<TextWrapper border={initial === 'ONLINE EVENTS'}><EventModeTitle className={(initial === 'ONLINE EVENTS') && 'colored'} onClick={() => changeInitial('ONLINE EVENTS')}>ONLINE EVENTS</EventModeTitle></TextWrapper>
					<TextWrapper border={initial === 'INFORMATIVE VIDEOS'}><EventModeTitle className={(initial === 'INFORMATIVE VIDEOS') && 'colored'} onClick={() => changeInitial('INFORMATIVE VIDEOS')}>INFORMATIVE VIDEOS</EventModeTitle></TextWrapper>
				</EventsHeading>
				{collapsibleData?.map(({ idx, link, time, event, location, host, online }) => <SingleQuestion key={idx} link={link} time={time} event={event} location={location} host={host} online={online} index={idx} />)}
			</SectionEvents>
		</EventWrapper>
	)
}

export default Events

import React, { useContext } from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
	resetPasswordAPI
} from '../../../../api/subadmin';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_INFO_ORGANIZATION
} from '../../../../store/types';

import {
	StyledContainer,
	StyledButton
} from './styles';

import CardComponent from '../../../../components/Card';
import InputField from '../../../../components/InputField';

import { globalValidator, getUser } from "../../../../utils";
import InsideMenuBar from '../../../../components/InsideMenus';

function SubAdminSettings(props) {
	const [state, dispatch] = useContext(Context);

	const [orgPassword, SetOrgPassword] = React.useState({
		password: '',
		confirmPassword: ''
	});

	React.useEffect(() => {

	}, []);

	const resetPwd = (e) => {
        e.preventDefault();
        
        const id = getUser()._id
	
    const keys = [
      { name: "password", title: "Password", validateKey: "password" },
      {
        name: "confirmPassword",
        title: "Repeat password",
        validateKey: "required"
			}
		];
		let valid = globalValidator(orgPassword, keys);
		if(valid === true) {
			loader(true);
			resetPasswordAPI(id, {password: orgPassword.password}).then(res => {
				loader(false);
				// toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'subadmin'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">

								<div className="d-flex align-center">
									<h3>Reset password</h3>
									<StyledButton onClick={(e) => resetPwd(e)}>Reset</StyledButton>
								</div>
									<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Password</label>
										<InputField value={orgPassword.password} password={true}  type="password" onChange={e => SetOrgPassword((user) => ({...user, password: e.target.value}))}  />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Confirm password</label>
										<InputField value={orgPassword.confirmPassword} password={true}  type="password" onChange={e => SetOrgPassword((user) => ({...user, confirmPassword: e.target.value}))}  />
									</div>

								</div>

							</CardComponent>
						</div>

					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default SubAdminSettings

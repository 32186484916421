import styled from "styled-components";
export const ItemCard = styled.div`
	width: 397px;
	height: 583px;
	background: #FFFFFF;
	box-shadow: -4px -4px 15px rgba(192, 192, 192, 0.25), 4px 4px 15px rgba(192, 192, 192, 0.25);
	border-radius: 8px;
	
	padding: 16px 16px 18px;
	margin: 0 0 24px;

	@media (max-width: 397px) {
		width: 100%;
		height: 474px;
	}
	@media only screen and (min-width: 1000px) and (max-width: 1350px) {
		width: 32%;
		height: 474px;
	}
	@media only screen and (min-width: 800px) and (max-width: 1000px) {
		width: 48%;
		height: 474px;
	}
	@media only screen and (min-width: 600px) and (max-width: 800px) {
		width: 48%;
		height: 474px;
	}

	@media (max-width: 600px) {
		// width: 48%;
		height: 474px;
	}
`;

export const CommonFieldStyle = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
	color: #CCCCCC;
	margin: 16px 0 0;

	&.style24 {
		margin: 24px 0 0;
	}
	@media (max-width: 397px) {
		font-size: 12px;
		line-height: 16px;
		
		&.style24 {
			margin: 24px 0 0;
		}
	}
	@media (max-width: 1350px) {
		font-size: 12px;
		line-height: 16px;
		
		&.style24 {
			margin: 24px 0 0;
		}
	}
`;

export const FieldValue = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-transform: capitalize;
	color: #2B2B2B;

	@media (max-width: 397px) {
		font-size: 12px;
		line-height: 16px;
	}

	@media (max-width: 1350px) {
		font-size: 12px;
		line-height: 16px;
	}
`;

export const Description = styled.div`
	margin: 16px 0 0;

	font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    color: #2B2B2B;
    margin: 16px 0 0;
    
	height: 170px;
    overflow: hidden;
    text-overflow: ellipsis;

	@media (max-width: 397px) {
		font-size: 16px;
		line-height: 24px;
		height: 120px;
	}
	@media (max-width: 1350px) {
		font-size: 16px;
		line-height: 24px;
		height: 120px;
	}
	
`;

export const DescriptionHeader = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 24px;
	text-transform: uppercase;
	color: #5E5E5E;

	margin: 24px 0 0;

	@media (max-width: 397px) {
		font-size: 16px;
		line-height: 24px;
	}

	@media (max-width: 1350px) {
		font-size: 16px;
		line-height: 24px;
	}
`;

export const Date = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-transform: uppercase;
	color: #2B2B2B;

	display: flex;
    justify-content: end;
	
	@media (max-width: 397px) {
		font-size: 12px;
		line-height: 17px;
	}

	@media (max-width: 1350px) {
		font-size: 12px;
		line-height: 17px;
	}
	
`;

export const ItemCardButton = styled.div`
	width: 128px;
	height: 33px;
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	border-radius: 8px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-transform: capitalize;
	color: #FFFFFF;

	display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 0;
	cursor: pointer;

	@media (max-width: 397px) {
		font-size: 12px;
		line-height: 16px;
		width: 100%;
	}

	@media (max-width: 1350px) {
		font-size: 12px;
		line-height: 16px;
		width: 100%;
	}
`;
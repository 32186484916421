import { Formik } from 'formik';
import React from 'react';
import { ButtonImage, StyledFormik, StyledSecondaryButton } from '../../../../assets/styles/CommonStyles';
import FieldWrapper from '../../../InputField/TextFieldWrapper';
import { CircularProgress, Grid, Box, Button } from '@mui/material';
import PhoneField from '../../../PhoneField';
import CountryField from "../../../CountryField";
import { doctorSignUpSchema } from '../../../../utils/forms/schema.doctorSignUpSchema';
import strings from '../../../../constant/strings';
import { useStyles } from '../../../../assets/styles/RadioButtonStyles';
import { toast } from 'react-toastify';
import { organizationsignupApi } from '../../../../services/authService';



const OrganizationForm = ({initialValues, handleForward, data, setData}) => {
    const classes = useStyles();

    const handleSubmit = (values) => {
      let phone = values.country_code.replace("+", "");
      const phone1 = values.phone_number.replace(phone, "");
      const dataValues = { ...values, phone_number: phone1 }
      setData(dataValues)      
      handleForward();      
    }

    return (<>
     <Formik
          initialValues={initialValues}
          validationSchema={doctorSignUpSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            handleChange,
            errors,
            values,
            touched,
            setTouched,
            handleSubmit,
            setFieldValue
          }) => (
            <StyledFormik>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="org_name"
                    type="text"
                    placeholder={`${strings.orgName}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="name"
                    type="text"
                    placeholder={`${strings.fullName}`}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FieldWrapper
                    name="email"
                    placeholder={`${strings.emailAddress}`}
                    type="email"
                  />
                </Grid>

                <Grid item xs={6}>
                  <PhoneField
                    value={initialValues.phone_number}
                    name="phone_number"
                    //onChange={(e, value)=>handlePhone(e,value.dialCode)}
                    onChange={(e, value) => {
                      handleChange("phone_number")(e);
                      values.phone_number = e;
                      values.country_code = `+${value.dialCode}`
                    }}
                    onBlur={() => setTouched({ ...touched, phone_number: true })}
                    touched={touched}
                    errors={errors}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldWrapper
                    name="address"
                    type="text"
                    placeholder={`${strings.address}`}
                  />

                </Grid>

                <Grid item xs={12} >
                  <CountryField
                    name="country"
                    type="text"
                    value="IN"
                    placeholder={`${strings.country}`}
                    // onChange={(e) => {
                    //    values.country = e
                    //   console.log('e',e)
                    // }}
                    // onBlur={() => setTouched({ ...touched, country: true })}
                    // touched={touched}
                    // errors={errors}
                  />
                </Grid>
              </Grid>
            
              <Box m={1} pt={1} >
                <div className={classes.buttonContainerRight}>
                  <StyledSecondaryButton
                    disabled={Object.keys(errors).length > 0  }
                    onClick={handleSubmit}
                    variant="contained"
                    style={{background: Object.keys(errors).length >  0 ? '#F5F7F8':''}}
                    >                     
                    <ButtonImage
                        src={"/assets/img/forward.png"}
                        alt="forwardButton"
                      ></ButtonImage>

                  </StyledSecondaryButton>
                </div>
              </Box>
            </StyledFormik>
          )}
        </Formik>
        </>)
}

export default OrganizationForm;

// import React from 'react';

// const Verification = () => {
//     return (<></>)
// }

// export default Verification;
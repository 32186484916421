import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledContainer = styled.div`
  .mob-chat-right-emed {
    height: 100vh;
    .chat-header {
        height: 10vh;
    }
    .chat-body {
        height: 56vh;
    }
    .chat-footer {
        height: 10vh;
    }
  }

  .chat-user-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .chat-window {
    height: 100vh;
  }
  .chat-cont-left {
    background-color: #fff;s
  }
  .chat-content-left-side {
    max-height: calc(100vh - 75px);
  }
  .chat-window .chat-scroll {
    min-height: calc(100vh - 140px);
  }

  @media (max-width: 767px){
    .chat-scroll {
			min-height: 400px;
			max-height: calc(100vh - 200px);
			overflow-y: auto;
		}
		.chat-footer {
			position: absolute;
			bottom: -75px;
			width: 100%;
		}
		.chat-cont-right .chat-header .media .media-img-wrap {
			margin-right: 6px;
		}
		.chat-cont-right .chat-header .media .media-body .user-name {
			font-size: 14px;
		}
		.chat-options .doctor-active-btn.bg-red {
			font-size: 10px;
			padding: 5px !important;
			margin-right: 0px;
		}
	}
`;

export const StyledBackSection = styled.div`
  width: 100%;
  margin: 20px 0;
  
`;

export const StyledBackTo = styled(Link)`
    border: 2px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #5156be;
    font-weight: 500;
    cursor: pointer;
    float: right;
    

    i {
        padding-right: 10px;
    }
`;

export const MissedMessages = styled.div`
    display: flex;
    justify-content: center;
    border-radius: 5px;
    background-color: #e3e3e3;
    width: 250px;
    height: 24px;
    font-size: 12px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 3%);
    align-items: center;
    margin-bottom: 12px;
`;

export const MissedWraper = styled.div`
    display: flex;
    justify-content: center;
`;

export const Icon = styled.i`
    font-size: 14px;
    margin-left: 10px;
    color: #E74848;
`;

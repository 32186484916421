import React, { useEffect, useState } from "react";
import { commonMuiStyles } from "../../assets/styles/CommonMUIStyles";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import strings from "../../constant/strings";
import {
  ContainerStyle,
  StyledCrossIcon,
  StyledSlotDivider,
} from "../../assets/styles/CommonStyles";
import colors from "../../constant/colors";
import { IPDdashoboardUseStyles } from "../../assets/styles/IPDDashboard";
import userProfile from "../../../src/assets/img/userProfile.png";
import {
  basicPatientDetail,
  detailedPatientDetails,
  patientInformation,
  patientMedicalDetails,
} from "../../constant/staticLists";
import { basicPatientData } from "../ui/IPDPatientDetails.js/PatientDetails";
import InformationPair from "../controls/InformationPair";
import { useSelector } from "react-redux";
import * as mainService from "../../services/mainService";
import { toast } from "react-toastify";

export const patientMedicalDetailsData = {
  provisional: "12134",
  bp_dia: "455",
  bp_sys: "100",
  temp: "99",
  spo2: "98",
  pulse: "125",
  rr: "43",
  illness_summary: "Sick very sick very very sick",
};

const PatientInfo = ({ setOpenModal, setScreen, id, setLoading }) => {
  const classes = commonMuiStyles();
  const dashboardClasses = IPDdashoboardUseStyles();
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState();
  const [patientDetails, setPatientDetails] = useState({});
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData
  }));
  console.log("userData", userData.userType);

  useEffect(() => {
    const fetchPatientDetails = async () => {
      const payload = {
        id: id,
        token: userData.token
      };
      console.log(payload);
      const res2 = await mainService.getPatientDetailsForDoctorApi(payload);
      if (res2.response_type === 'fail') {
        toast.error(res2?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: { width: "auto", borderRadius: 8 } });
      } else {
        console.log("res", res2.response.data);
        setPatientDetails(res2.response.data);
      }
    };

    fetchPatientDetails();
  }, [id, userData.token]);

  const resetForm = () => {
    setPatientDetails({});
  };

  const handleToggle = () => {
    setOpen(true);
  };

  const handleClose = async (confirmed) => {
    setOpen(false);
    if (confirmed) {
      const payload = {
        id: id,
      };
      const res = await mainService.deletePatient({
        payload: payload,
        token: userData.token,
      });

      if (res.response_type === "fail") {
        toast.error(res?.response?.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
      } else {
        toast.success("Patient deleted successfully", {
          position: "bottom-center",
          closeButton: false,
          autoClose: 1000,
          style: { width: "auto", borderRadius: 8 },
        });
        setLoading(true);
        setOpenModal(false);
        setScreen((prev) => prev - 1);
        resetForm(); // Clear form data after successful deletion
      }
    } else {
      console.log("Action canceled!");
    }
  };

  const patientDetailsData = {
    name: patientDetails.name,
    admission_date: "13/04/2023",
    room_no: "120",
    room_type: "General ward",
    admission_consult: "Dr. Pramit Gahlot",
    reason: "Sick",
    ref_phone_number: "+91-8976545678",
    mlc: "",
    fir: "",
  };

  const result = patientDetails?.emergencyContacts ? patientDetails?.emergencyContacts.map(item => `${item.name}, ${item.country_code}-${item.phone_number}, ${item.relation} `).join(', ') : "No Emergency Contact found";

  const data = {
    name: patientDetails.name,
    email: patientDetails.email,
    phone_number: `${patientDetails.countryCode}-${patientDetails.phoneNumber}`,
    gender: patientDetails?.gender === 1 ? "Male" : patientDetails.gender === 2 ? "Female" : "Other",
    dob: JSON.stringify(patientDetails?.dob),
    address: patientDetails.address,
    emergency_contact: patientDetails.emergencyContacts ? result : "No Emergency Contact found"
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Typography className={classes.fontText24} color={colors.grey1}>
            {strings.patientInformation}
          </Typography>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="cross"
            onClick={() => {
              setOpenModal(false);
              setScreen((prev) => prev - 1);
              resetForm(); // Clear form data on close
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledSlotDivider />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <img
                src={patientDetails?.profileImage || userProfile}
                alt="User"
                className={dashboardClasses.image160}
              />
            </Grid>
            <Grid item xs={8}>
              <DetailsView
                heading={strings.personalInfo}
                fieldOrder={detailedPatientDetails}
                data={data}
                size="half"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} mt={3}>
          <DetailsView
            heading={strings.patientInformation}
            fieldOrder={patientInformation}
            data={patientDetailsData}
            size="full"
          />
        </Grid> */}
        {/* <Grid item xs={12} mt={3}>
          <DetailsView
            heading={strings.patientMedical}
            fieldOrder={patientMedicalDetails}
            data={patientMedicalDetailsData}
            size="full"
          />
          <MedicalDetailView title01 = "Chief Complaints And Duration" title02 = "Duration" data01="hdfcjhdkjc" data02="1 Day" />
          <MedicalDetailView title01 = "General Appearance" title02 = "Comment" data01="hdfcjhdkjc" data02="1 Day" />

        </Grid>
        <Grid item xs={12} mt={3}>
          <Typography
            className={dashboardClasses.font16}
            sx={{ fontWeight: "700 !important", color: colors.black1 }}
          >
            {strings.patientMedHistory}
          </Typography>
          <Summary
            head="Significant past medical & surgical History"
            content="sdfghjdfghj sdfghdfg hjsdfghsdfg hjwerty jdtysdfgh dfdfghj dfgdfgdfg
          hdf gdfghcvhsdf hsertysdfg"
          />
        </Grid>
        <Grid item xs={12}>
          <Summary
            head="Family medical History"
            content="sdfghjdfghjsdf ghdfg hjsdfgh sdfghjwertyjdtysdfgh"
          />
          <Summary
            head="History of Alcohalism , Tobacco or Substances"
            content="sdfghjdfghjs dfghdfghjsdfg hsdfgh jwertyj dtysdfgh
            dfdfghjdf gdfgdfghd fgdfghcvhsdfhsertysdfg"
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent={"center"}
          mt={10}
        >
          {/* <Button
                className={`${classes.rootSubadmin} ${
                   classes.deactive
                }`}
                onClick={()=>{handleToggle()}}
              >
                { `Remove Patient`}
              </Button> */}
          <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
          >
            <DialogTitle id="confirmation-dialog-title">{"Confirm Action"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="confirmation-dialog-description">
                Are you sure you want to proceed with this action?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

const DetailsView = ({ heading, fieldOrder, data, size }) => {
  const dashboardClasses = IPDdashoboardUseStyles();
  return (
    <>
      <Typography
        className={dashboardClasses.font16}
        sx={{ fontWeight: "700 !important", color: colors.black1 }}
        mb={1}
      >
        {heading}
      </Typography>
      <InformationPair fieldOrder={fieldOrder} data={data} size={size} />
    </>
  );
};

const Summary = ({ head, content }) => {
  const dashboardClasses = IPDdashoboardUseStyles();
  return (
    <>
      <div className={`${dashboardClasses.cont}`}>
        <Typography
          className={dashboardClasses.typographyText}
          sx={{
            borderBottom: "2px solid #CCCCCC",
            color: colors.black1,
            padding: "5px 5px",
          }}
        >
          {head}
        </Typography>
        <Typography
          sx={{ color: colors.grey1, padding: "5px 5px" }}
          className={dashboardClasses.typographyText}
        >
          {content}
        </Typography>
      </div>
    </>
  );
};

const MedicalDetailView = ({ title01, title02, data01, data02 }) => {
  const dashboardClasses = IPDdashoboardUseStyles();
  return (
    <>
      <Grid
        container
        spacing={0}
        className={`${dashboardClasses.cont} ${dashboardClasses.typographyText}`}
      >
        <Grid
          item
          xs={6}
          className={dashboardClasses.typographyText}
          sx={{
            borderRight: "2px solid #F5F5F7",
            borderBottom: "2px solid #F5F5F7",
            padding: '5px'
          }}
        >
          {title01}
        </Grid>
        <Grid item xs={6}
          className={dashboardClasses.typographyText}
          sx={{ borderBottom: "2px solid #F5F5F7", padding: '5px' }}>
          {title02}
        </Grid>
        <Grid
          item
          xs={6}
          className={dashboardClasses.typographyText}
          sx={{ borderRight: "2px solid #F5F5F7", borderRight: "2px solid #F5F5F7", padding: '5px', color: colors.grey1 }}
        >
          {data01}
        </Grid>
        <Grid item xs={6}
          className={dashboardClasses.typographyText}
          sx={{ padding: '5px', color: colors.grey1 }}>
          {data02}
        </Grid>
      </Grid>
    </>
  );
};

export default PatientInfo;

import styled from 'styled-components';

export const StyledInputContainer = styled.div`
    position: relative;
    i {
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
    }
`;

export const EyeIcon = styled.i`
    position: absolute;
    top: 15px;
    right: 15px !important;
    cursor: pointer;
    display: block !important;
`;

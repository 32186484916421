import styled from "styled-components";
export const LinkCustom = styled.div`
	background-color: #5156be;
	border: 2px solid #5156be;
	color: #fff;
	font-size: 13px;
	text-align: center;
	display: block;
	font-weight: 500;
	padding: 6px;
	line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	cursor: pointer;
	&:hover {
		background-color: #5156be;
		border-color: #5156be;
		color: #fff;
	}
	&:focus {
		background-color: #5156be;
		border-color: #5156be;
		color: #fff;
	}
`;
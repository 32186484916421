import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Icon,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Stack, width } from "@mui/system";
import { useHistory } from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import defaultUserPng from "../../assets/img/user.png";
import { useStyles } from "../../assets/styles/SidebarStyles";
import colors from "../../constant/colors";
import { StyledLogo } from "../../assets/styles/CommonStyles";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction, doctorlogoutAction, organizationlogoutAction, subadminlogoutAction } from "../../redux/auth/authActions";
import {
  DoctorSidebarData,
  DoctorUserProfile,
  PatientSidebarData,
  PatientUserProfile,
  selectIconIndexAsPerRoutePatient,
  selectIconIndexAsPerRouteDoctor,
  OrganisationSidebarData,
  OrganisationProfile,
  selectIconIndexAsPerRouteOrganisation,
  SubadminUserProfile,
  SubadminSidebarData,
  selectIconIndexAsPerRouteSubadmin,
} from "./SidebarData";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import { imageStyle } from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/components/Messages/CometChatMessageComposer/style";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    background: colors.white,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      background: colors.white,
    }),
  }),
}));

const UserProfileGridStyled = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  paddingTop: 10,
  paddingBottom: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: open ? colors.lightBackground : "#FFFFFF",
  "& .MuiListItemButton-root": {
    background:
      "linear-gradient(#fff 0 0) padding-box, #0490bf border-box !important",
    border: open ? "2px solid transparent" : null,
    borderRadius: 8,
    padding: 0,
    textAlign: "center",
    height: "40px",
    color: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
    "& .MuiListItemIcon-root": {
      marginLeft: "10px",
      marginRight: "-12px",
    },
  },
  "& .image-style": {
    width: 24,
    height: 24,
    opacity: 1
  },
  "& .text": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "18px",
    textAlign: "left",
    marginLeft: "-10px",
    paddingRight: "10px",
    background:
      "linear-gradient(90deg, rgba(14,96,122,1) 5%, rgba(15,166,217,1) 54%, rgba(128,165,179,1) 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    " -webkit-text-fill-color": "transparent",
  },
  "& img": {
    height: open ? 48 : 32,
    width: open ? 48 : 32,
    borderRadius: open ? 37.5 : 25,

    "& + .MuiGrid-root": {
      display: open ? "flex" : "none",
      flexDirection: "column",
      alignItems: "center",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "18px",
      letterSpacing: "0em",
      marginTop: "6px",
      marginBottom: "auto",
      "& .text-style": {
        color: "#5E5E5E",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " 18px",
      },
      "& .profile-image-icon": {
        height: 18,
        width: 18,
        borderRadius: "0px",
        paddingRight: "4px",
      },
    },
  },
  "& .user-text": {
    color: "#2B2B2B",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "900",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    marginRight: "2px",
  },
  "& .org-profile-cont": {
    alignItems: "left",
    marginLeft: 0,
    marginTop: 4,
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  overflow: "auto",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "& > div": {
    height: "calc(100% - 64px)",
    top: 64,
  },
  "& > .MuiPaper-root > .MuiGrid-root + ul": {
    // maxHeight: 'calc(100% - 500px)',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 0,
    "& + ul": {
      margin: "auto 0px 10px 4px",
    },
  },
  "& > .MuiPaper-root > .MuiGrid-root + .sidebar_menu_list": {
    marginBottom: "auto",
  },
  "& > div ul": {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      paddingBottom: 0,
      margin: 0,
      marginTop: "2.5px",
    },
  },
}));

const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (prop) => !["selectedItem", "index"].includes(prop),
})(({ theme, selectedItem, index, open }) => ({
  background: selectedItem === index ? colors.lightBackground : "#00000000",
}));

const ListItemTextStyled = styled(ListItemText, {
  shouldForwardProp: (prop) => !["selectedItem", "index"].includes(prop),
})(({ theme, selectedItem, index }) => ({
  fontFamily: "Poppins",
  height: "48px",
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  background:
    selectedItem === index ? colors.primary : "var(--black-2, #5E5E5E)",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

const user = localStorage.getItem("userType");

export default React.memo(function SideBar(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [profileList, setProfileList] = React.useState([]);
  const [sidebarList, setsidebarList] = React.useState([]);
  const [loggedInUser, setloggedInUser] = React.useState(
    window.localStorage.getItem("userType")
  );
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [isCopy, setCopy] = React.useState(false);

  const { userData, patientProfileData, doctorProfileData,subadminProfileData } = useSelector(
    (state) => ({
      userData: state.auth.userData,
      patientProfileData: state.user.patientProfile || {},
      doctorProfileData: state.doctor.doctorProfileData,
      subadminProfileData: state.subadmin.subadminProfileData,
    })
  );
console.log("userData",userData)
  React.useEffect(() => {
    let selectIconIndexAsPerRoute = "";
    user === "patient"
      ? (selectIconIndexAsPerRoute = selectIconIndexAsPerRoutePatient)
      : user === "doctor"
      ? (selectIconIndexAsPerRoute = selectIconIndexAsPerRouteDoctor)
      : user === "organization"
      ? (selectIconIndexAsPerRoute = selectIconIndexAsPerRouteOrganisation)
      : (selectIconIndexAsPerRoute = selectIconIndexAsPerRouteSubadmin);
    if (window?.location?.pathname)
      setSelectedItem(selectIconIndexAsPerRoute[window?.location?.pathname]);
  }, []);

  React.useEffect(() => {
    if (user === "doctor") {
      setProfileList(DoctorUserProfile);
      setsidebarList(DoctorSidebarData);
    } else if (user === "patient") {
      setProfileList(PatientUserProfile);
      setsidebarList(PatientSidebarData);
    } else if (user === "organization") {
      setProfileList(OrganisationProfile);
      setsidebarList(OrganisationSidebarData);
    } else if (user === "subadmin") {
      setProfileList(SubadminUserProfile);
      setsidebarList(SubadminSidebarData);
    }
  }, [profileList, sidebarList]);

  const renderTime = (date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY");
    return formattedDate;
  };

  const copyID = async () => {
    await navigator.clipboard.writeText(!!userData && userData.uniqueID);
    setCopy(true);
  };

  const handleLogout = () => {
    switch (loggedInUser) {
      case "patient":
        dispatch(logoutAction(userData.token));
        break;
      case "doctor":
        dispatch(doctorlogoutAction(userData.token));
        break;
      case "organization":
        dispatch(organizationlogoutAction(userData.token));
        break;
      case "subadmin":
        dispatch(subadminlogoutAction(userData.token));
        break;
    }
  };

  const handlePath = (index, path) => {
    history.push(path);
    setSelectedItem(index);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      {/* <LinearGradianForSvg /> */}
      <AppBar position="fixed" elevation={0}>
        <Toolbar className={classes.titleCont}>
          <Icon
            color="primary"
            aria-label="open drawer"
            opacity={ 1}
            edge="start"
            // fontSize="large"
            style={
              {
                width: 240, height: 70,
               
                opacity:1,
                marginTop:8
              }
            }
            sx={{
              marginRight: 5,
            }}


            onClick={() => history.push(sidebarList[0].path)}
          >
            <img src="/assets/img/image1.png"  alt="User"  />
            {/* <StyledLogo src={"/assets/img/image1.png"} alt="User" /> */}
          </Icon>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={setOpen.bind(this, true)}
        onMouseLeave={setOpen.bind(this, false)}
      >
        <Divider />
        <UserProfileGridStyled open={open}>
          <img
            alt="user-profile"
            src={
              user === "doctor"
                ? userData.profile_image
                :user === 'patient'? userData.profileImage:user =='organization'?userData.logo :userData.profile_image|| defaultUserPng
            }
          />
          <Grid>
            <Stack>{!!userData && userData.name}</Stack>
            {user === "doctor" ? (
              <Stack className="text-style">
                {" "}
                {!!userData && userData.department}
              </Stack>
            ) : user === "patient" ? (
              <Box display="flex">
                <Stack noWrap className="user-text">
                  {!!userData && userData.uniqueID}
                </Stack>
                {!isCopy ? (
                  <FileCopyOutlinedIcon
                    onClick={copyID}
                    sx={{ cursor: "pointer", height: "16px", width: "16px" }}
                  />
                ) : (
                  <DoneIcon
                    sx={{ cursor: "pointer", height: "16px", width: "16px" }}
                    onClick={() => setCopy(false)}
                  />
                )}
              </Box>
            ) : (
              <Box className="org-profile-cont">
                <div>
                  <span>
                    <img
                      className="profile-image-icon"
                      src="/assets/img/calender.svg"
                      alt="date"
                    ></img>
                  </span>
                  <span className="text-style">
                    {!!userData && renderTime(userData.createdAt)}
                  </span>
                </div>
                <div>
                  <span>
                    <img
                      src="/assets/img/location.svg"
                      alt="date"
                      className="profile-image-icon"
                    ></img>
                  </span>
                  <span className="text-style">
                    {!!userData && userData.address}
                  </span>
                </div>
                <div>
                  <span>
                    <img
                      src="/assets/img/phone.svg"
                      alt="date"
                      className="profile-image-icon"
                    ></img>
                  </span>
                  <span className="text-style">
                    {!!userData && userData.phone_number}
                  </span>
                </div>
              </Box>
            )}
          </Grid>
          {open ? (
            <List sx={{ paddingTop: 20 }}>
              {profileList.map((item, index) => {
                return (
                  <ListItem key={item.name}>
                    <ListItemButton
                      onClick={() => {
                        history.push(item.path);
                      }}
                    >
                      <Grid
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <ListItemIcon>
                          <img src={item.img1} className="image-style" />
                        </ListItemIcon>
                      </Grid>
                      <ListItemText
                        primaryTypographyProps={{ className: "text" }}
                        primary={item.name}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            ""
          )}
        </UserProfileGridStyled>

        <List sx={{ paddingTop: 20 }} className="sidebar_menu_list">
          {sidebarList.map((item, index) => {
            return (
              <ListItem
                key={index}
                valueselected={selectedItem === index}
                onClick={() => {
                  handlePath(index, item.path);
                }}
                disablePadding
                sx={{ height: 50, display: "block", margin: 0.3 }}
              >
                <ListItemButtonStyled
                  sx={{
                    height: 40,
                    justifyContent: open ? "initial" : "center",
                    paddingTop: "25px",
                    paddingBottom: "25px",
                    paddingLeft: open ? "0px" : "",
                    margin: open ? "0px 25px" : "0px 8px",
                    borderRadius: "8px",
                  }}
                  selected={selectedItem === index}
                  {...{ selectedItem, index }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      padding: "0px 11px",
                    }}
                    selected={selectedItem === index}
                    {...{ selectedItem, index }}
                  >
                    {selectedItem === index ? (
                      <img src={item.img2}></img>
                    ) : (
                      <img src={item.img1}></img>
                    )}
                  </ListItemIcon>
                  <ListItemTextStyled
                    primary={item.title}
                    sx={{
                      opacity: open ? 1 : 0,
                      padding: "5px 0px",
                      marginLeft: open ? "-15px" : "",
                    }}
                    selected={selectedItem === index}
                    {...{ selectedItem, index }}
                  />
                </ListItemButtonStyled>
              </ListItem>
            );
          })}
        </List>
        <List sx={{ paddingTop: 20 }}>
          <ListItem
            key="logout"
            disablePadding
            sx={{ display: "block", paddingBottom: 2 }}
          >
            <ListItemButton
              onClick={handleLogout}
              sx={{
                height: 40,
                justifyContent: open ? "initial" : "center",
                paddingTop: "25px",
                paddingBottom: "25px",
                paddingLeft: open ? "0px" : "",
                margin: open ? "0px 25px" : "0px 8px",
                borderRadius: "8px",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  padding: "0px 10px",
                }}
                style={{ background: "#00000000" }}
              >
                <LogoutOutlinedIcon style={{ color: "red" }} />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                style={{ color: "red" }}
                sx={{ opacity: open ? 1 : 0, marginLeft: open ? "-20px" : "" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
});

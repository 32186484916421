import React from 'react';
import CardComponent from '../Card';
import GaugeChart from '../Charts/GaugeChart';

const LipidChart = ({item, className, minHeight}) => {
    const reverse = item.lineData.length ? [...item.lineData].reverse() : [];
    const [width, setWidth] = React.useState();
    React.useEffect(()=>{
		const k = document.getElementById('Lipid-chart-card').offsetWidth;
		setWidth(k)
	},[])

    const Lipid = [
        {
            id :1,
            heading : 'Total Cholesterol',
            lineData : reverse.length ? reverse[0].total_cholesterol || reverse[0].Details[0].total_cholesterol : 0,
            maxValue : 300,
            segmentStops : [0, 100, 200, 300],
            healthInfo: {
                optimal: 'Optimal : 0-200',
                high: 'High : 200-240',
                veryhigh: 'Very high : 240-300',
            }
        },
        {
            id :2,
            heading : 'HDL-C',
            lineData : reverse.length ? reverse[0].HDL_C || reverse[0].Details[0].HDL_C : 0,
            maxValue : 70,
            segmentStops : [0, 23, 47, 70],
            healthInfo: {
                optimal: 'Optimal : 0-45',
                high: 'High : 46-60',
                veryhigh: 'Very high : 61-70',
            }
        },
        {
            id :3,
            heading : 'LDL_C',
            lineData : reverse.length ? reverse[0].LDL_C || reverse[0].Details[0].LDL_C : 0,
            maxValue : 200,
            segmentStops : [0,66, 133, 200],
            healthInfo: {
                optimal: 'Optimal : 0-100',
                high: 'High : 100-140',
                veryhigh: 'Very high : 140-200',
            }
        },
        {
            id :4,
            heading : 'Triglycerides',
            lineData : reverse.length ? reverse[0].triglycerides || reverse[0].Details[0].triglycerides : 0,
            maxValue : 500,
            segmentStops : [0, 166, 333, 500],
            healthInfo: {
                optimal: 'Optimal : 0-200',
                high: 'High : 200-260',
                veryhigh: 'Very high : 260-500',
            }
        },
        {
            id :5,
            heading : 'VLDL',
            lineData : reverse.length ? reverse[0].VLDL || reverse[0].Details[0].VLDL : 0,
            maxValue : 35,
            segmentStops : [0,11,23,35],
            healthInfo: {
                optimal: 'Optimal : 0-5',
                high: 'High : 5-18',
                veryhigh: 'Very high : 18-35',
            }
        },
    ]
    return (
        Lipid && Lipid.map((sub) => {
            return (
                <div  style={{minWidth:"200px"}} className={className}>
                    <CardComponent className="mb-4 health-chart-card " id='Lipid-chart-card'>
                        <div className='col-md-12 d-flex p-0'>
                        <h5>{sub.heading}</h5>
                        <h5 className='ml-auto'>
                        <i style={{ color: "#5156be" }} type="button" class="fa fa-info-circle" aria-hidden="true" data-toggle="tooltip" data-placement="top"
                            title={`${sub.healthInfo.optimal}
											${sub.healthInfo.high}
											${sub.healthInfo.veryhigh}`
                            }
                        >
                        </i>
                        </h5>
                        </div>
                        <div className="col-md-12 pt-4" style={{ padding: "5px", minHeight: minHeight || '', alignItems:'center', justifyContent:'center', display:'flex'  }}>
                           {width && <GaugeChart
                                key={`gaugeChart-${sub.id}`}
                                Linedata={sub.lineData}
                                label={item.title}
                                maxValue = {sub.maxValue}
                                // height={150}
                                segmentStops={sub.segmentStops}
                                width={180}
                            />
                           }
                        </div>
                    </CardComponent>
                </div>

            )
        })
    )
}

export default LipidChart;
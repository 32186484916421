import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { FormControl, FormControlLabel, Grid, Checkbox, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import IndeterminateCheckbox from '../../components/IndeterminateComponent';
import { StyledButton, StyledCrossIcon, StyledSecondaryButton } from '../../assets/styles/CommonStyles';
import { useStyles } from './QrcodeStyles';
import strings from '../../constant/strings';
import { healthParameter, PatientDetails, MedicalHistory } from './QRcodeData';
import QRcodeComponent from '../../components/QRcodeComponent';
import { TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from "react-redux";
import * as authService from "../../services/mainService.js";
import { toast } from "react-toastify";
import Calendar from "../../assets/img/calendar.png";
import Clock from "../../assets/img/clock.png";
import { checkStyle, ContainerDiv, ContentText, DateContainer, HeadingText, MainContainer, SecondaryContainer, SecondHeadingText, StyledDatePicker, StyledImg, StyledQrCodeGrid, StyledTimePicker, TopSection } from '../../assets/styles/QRCodeStyle';
import moment from 'moment';


const QRcode = () => {
  const classes = useStyles();
  const history = useHistory();
  const [sdate, setDate] = useState('');
  const [stime, setTime] = useState('');
  const [checked, setChecked] = useState(false);
  const [isTicked, setTicked] = useState(true);
  const [url, setUrl] = useState("");
  const [qrList, setQRList] = useState({ healthParam: [''], PDetails: [""], MHistory: [""] })
  const [qr_data, setQR_data] = useState({ PersonalDetails: {}, MedicalHistory: {}, HealthParameters: {} })
  const [isNext, setNext] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const now = moment();

  const { userData, qrCodeDetails } = useSelector((state) => ({
    userData: state.auth.userData,
    qrCodeDetails: state.user.qrCodeDetails

  }));

  const handleCheck = (e) => {
    const check = e.target.checked;
    setChecked(check);
  }

  const fun = (param, parentName) => {
    if (parentName === "Health Parameter") {
      setQRList((prev) => {
        return {
          ...prev,
          healthParam: param
        }
      })
    }
    else if (parentName === "Patient Details") {
      setQRList((prev) => {
        return {
          ...prev,
          PDetails: param
        }
      })
    }
    else if (parentName === "Medical History") {
      setQRList((prev) => {
        return {
          ...prev,
          MHistory: param
        }
      })
    }
  }

  const settingParameter = (list) => {
    qr_data.PersonalDetails = Object.fromEntries(
      list.PDetails.map(obj => [obj.value, obj.isChecked])
    )
    qr_data.HealthParameters = Object.fromEntries(
      list.healthParam.map(obj => [obj.value, obj.isChecked])
    )
    qr_data.MedicalHistory = Object.fromEntries(
      list.MHistory.map(obj => [obj.value, obj.isChecked])
    )
    callQRcodeApi(qr_data);
  }

  const callQRcodeApi = async (data) => {
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    setButtonLoading(true);
    const duration = calculateDuration(sdate, stime)
    const value = {
      data,
      token: userData.token,
      duration : duration,
      timeDetails: {
        timezone: timezone,
        duration: 'y',
        date: moment(new Date()).format("YYYY-MM-DD"),
      },
    }
    if(sdate != '' || stime !=''){
    const res = await authService.qrcodeAPI(value);
    if (res.response_type === "fail") {
      setButtonLoading(false)
      toast.error(res?.response?.message, { position: "bottom-center", closeButton: false })
    } else {
      setUrl(`${process.env.REACT_APP_BASE_URL}public/patient-info?token=${res.response.data}`)
      setButtonLoading(false)
      setNext(!isNext);
    }
  }else{
    toast.error("Please select date.", { position: "bottom-center", closeButton: false })
    setButtonLoading(false)
  }
  }

  const handleCross = () => {
    history.push('/patient/dashboard');
  }

  const handleTick = () => {
    setTicked(!isTicked);
  }

  const handleQR = async () => {
    settingParameter(qrList);
  }

  const handleDate = (date) => {
    const formattedDate = moment(date.$d).format('YYYY-MM-DD')
    setDate(formattedDate);
  }

  const calculateDuration = (date, time) => {   
    const selectedDateTime = moment(`${date} ${time}`, 'YYYY-MM-DD hh:mm A');
    const diffInMinutes = selectedDateTime.diff(now, 'minutes')+'m';
    return diffInMinutes;
  }

  const handleTime = (time) => {
    const formattedTime = moment(time.$d).format('HH:mm')
    setTime(formattedTime);
  }

  useEffect(() => {
    handleTick();
  }, [url])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
      {!isNext ?
        <StyledQrCodeGrid>
          <ContainerDiv>
            <TopSection container spacing={2}>
              <HeadingText>Generate QR</HeadingText>
              <StyledCrossIcon
                src={"/assets/img/x.png"}
                alt="x"
                onClick={handleCross}
              />
            </TopSection>
            <MainContainer>
              <SecondaryContainer className={classes.infoContainer}>
                <StyledImg
                  src={"/assets/img/info.png"}
                  alt="info" />
                <ContentText className={classes.textStyle1}>{strings.generateQRinfo}</ContentText>
              </SecondaryContainer>
              <div>
                <SecondHeadingText>Select Time interval for the QR</SecondHeadingText>
                <DateContainer>
                  <div className={classes.secondContainer}>
                    <ContentText><img src={Calendar} className='image-style' />Valid till date</ContentText>
                    <DemoContainer components={['DatePicker']}>
                      <StyledDatePicker
                        placeholder="Select date"
                        onChange={handleDate}
                        format='DD-MM-YYYY'
                        disablePast
                        components={{
                          OpenPickerIcon: KeyboardArrowDownIcon
                        }}
                        renderInput={(props) => <TextField {...props} variant="outlined" />} />
                    </DemoContainer>
                  </div>
                  <div>
                    <img src={"/assets/img/doubleRightArrow.png"} alt="doubleArrow" />
                  </div>
                  <div className={classes.secondContainer} >
                    <ContentText><img src={Clock} className='image-style' />Valid till time</ContentText>
                    <DemoContainer components={['TimePicker']}>
                      <StyledTimePicker
                        placeholder="Select Time"
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        onChange={handleTime}
                       // views={['date', 'hours', 'minutes']}
                        renderInput={(props) => <TextField {...props} variant="outlined" />}
                        components={{
                          OpenPickerIcon: KeyboardArrowDownIcon
                        }}
                      />
                    </DemoContainer>
                  </div>
                </DateContainer>
              </div>
              <div>
                <SecondHeadingText>Select Features to be converted</SecondHeadingText>
                <Grid container spacing={2} sx={{ "&.MuiGrid-root": { marginTop: "-10px", marginLeft: "-10px" } }}>
                  <Grid item xs={12} md={4} lg={4}>
                    <IndeterminateCheckbox list={healthParameter} paramName="Health Parameter" fun={fun} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <IndeterminateCheckbox list={PatientDetails} paramName="Patient Details" fun={fun} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} >
                    <IndeterminateCheckbox list={MedicalHistory} paramName="Medical History" fun={fun} />
                  </Grid>
                </Grid>
              </div>
              <div>
                <FormControl sx={{ marginTop: "10px" }}>
                  <FormControlLabel
                    label={strings.acknowledgement}
                    control={<Checkbox checked={checked}
                      onChange={handleCheck}
                      sx={{
                        "&.Mui-checked": {
                          color: "rgba(42, 171, 108, 1)"
                        }
                      }} />}
                    sx={checkStyle}></FormControlLabel>
                </FormControl>
              </div>
              <Grid display={"flex"} justifyContent={"center"} width={"100%"} height={"48px"}>
                <StyledSecondaryButton
                  name="submit"
                  disabled={!checked || sdate === '' || stime=== ''}
                  onClick={handleQR}
                  style={{width: '200px'}}
                  variant="contained">
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : " Generate QR"}

                </StyledSecondaryButton>
              </Grid>
            </MainContainer>
          </ContainerDiv>
        </StyledQrCodeGrid>
        : <StyledQrCodeGrid>
          <QRcodeComponent
            url={url}
            handleTick={handleTick}
            setNext = {setNext}
            isNext = {isNext}
            handleCross={handleCross}
            setChecked= {setChecked}
            checked = {checked} /> </StyledQrCodeGrid>
      }

    </LocalizationProvider >
  )
}

export default QRcode;
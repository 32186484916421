import React from 'react';

import {
	StyledPricing,
	StyledContainer,
	StyledPackage,
	StyledTitle,
	StyledSubTitle,
	StyledTrail,
	StyledHr,
	StyledPriceListing,
	StyledListingValue,
	StyledPriceButton
} from './styles';

function PricingComponent() {
	return (
		<StyledPricing>
			<StyledContainer>
				<StyledPackage>
					<StyledTitle>
						Limited
					</StyledTitle>
					<StyledSubTitle>
						$10
					</StyledSubTitle>
					<StyledTrail>
						Available for a month
					</StyledTrail>
					<StyledHr />

					<StyledPriceListing>
						<StyledListingValue>
							<i className="fa fa-check" />Lorem Ipsum is simply
						</StyledListingValue>
						<StyledListingValue>
							<i className="fa fa-check" />Lorem Ipsum is simply
						</StyledListingValue>
						<StyledListingValue>
							<i className="fa fa-check" />Lorem Ipsum is simply
						</StyledListingValue>
						<StyledListingValue>
							<i className="fa fa-check" />Lorem Ipsum is simply
						</StyledListingValue>
					</StyledPriceListing>
					
					<StyledPriceButton>
						Buy plan     
						<i className="fa fa-chevron-right" />
					</StyledPriceButton>
				</StyledPackage>
			</StyledContainer>
		</StyledPricing>
	)
}

export default PricingComponent

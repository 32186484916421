import { makeStyles } from "@mui/styles";
import colors from "../../../constant/colors";
const useHealthParameterStyles = makeStyles((theme) => ({
  paper: {
    height: "40vh",
    padding: 20,
    margin: 8,
    boxShadow: `-4px -4px 15px ${colors.shadowGradient}, 4px 4px 15px ${colors.shadowGradient}`,
    borderRadius: 8,
  },
  mainHealthCont: {
    width: "100%",
  },
  secondaryMainHealth: {
    width: "100%",
    marginTop: "16px"
  }
}));
export { useHealthParameterStyles };

import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import DocMessages from "../../components/ui/Doctor/DocMessages";

const DoctorMessages = () => {
  return (
    <Grid container spacing={0}>
      <AppLayout>
        <DocMessages />
      </AppLayout>
    </Grid>
  )
}

export default DoctorMessages;

import styled from 'styled-components';

export const StyledContainer = styled.div`
    .align-center {
        align-items: center;
    }
`;

export const StyledButton = styled.button`
    background-color: #5156be;
    color: #fff;
    border: 2px solid #5156be;
    margin-top: 20px;
    padding: 12px 25px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    margin-left: auto;
    width: 100%;
`;
import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment";
import { StyledCalender } from "../../../assets/styles/CommonStyles";
import { Typography } from "@mui/material";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { IPDdashoboardUseStyles } from "../../../assets/styles/IPDDashboard";

function DateOfObservation({ selectedListItem }) {
  const classes = commonMuiStyles();
  const dashboardClass = IPDdashoboardUseStyles();
  const today = moment().format('YYYY-MM-DD');

  const daysCount = () => {
    const admittedDate =  moment(selectedListItem?.admission_date).format("YYYY-MM-DD")
    return moment().diff(admittedDate, 'days');
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          height: '230px',
          position: 'relative'
        }}
      >
        <DemoContainer
          components={["DateCalendar"]}
          sx={{
            padding: "0px",
          }}
        >
          <DemoItem>
            <StyledCalender defaultValue={dayjs(today)} />
          </DemoItem>
        </DemoContainer>
        <Typography className={`${classes.fontText12} ${dashboardClass.placeText}`}>Admitted Day count : {daysCount()}</Typography>
      </div>
      
    </LocalizationProvider>
  );
}

export default DateOfObservation;

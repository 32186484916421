import React from 'react';
import { IconMobileWrapper, IconWrapper, Input, InputWrapper } from './styles';
import { ReactComponent as SearchIcon } from '../../assets/img/search.svg';

function DesktopInputSearch(props) {
	return (
		<InputWrapper>
			<IconWrapper className='position'><SearchIcon /></IconWrapper>
			<Input placeholder='Search' value={props.search} onChange={(e) => props.setSearch(e.target.value)}/>
		</InputWrapper>
	)
}

const iconStyle = {width: '16px', height: '16px'}

function MobileInputSearch(props) {
	return (
		<InputWrapper>
			<IconMobileWrapper><SearchIcon style={iconStyle} /></IconMobileWrapper>
			<Input className='input' placeholder='Search' value={props.search} onChange={(e) => props.setSearch(e.target.value)} />
		</InputWrapper>
	)
}

function InputSearch(props) {
	return props?.isMobile ? 
		<MobileInputSearch setSearch={(val) => props.setSearch(val)} search={props.search}/> 
		: 
		<DesktopInputSearch setSearch={(val) => props.setSearch(val)} search={props.search}/>
}

export default InputSearch

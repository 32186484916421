import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { subadminProfileAction, updateSubadminProfileAction } from './subadminAction';
import { getProfileBySubadminFailure, getProfileBySubadminRequest, getProfileBySubadminSuccess, updateProfileBySubadminFailure, updateProfileBySubadminRequest, updateProfileBySubadminSuccess } from './subadminSlice';
import { getProfileBySubadminApi, updateProfileByOrganizationAPI } from '../../../services/mainService';


  function* getProfileBySubadmin(action) {
    try {
       yield put(getProfileBySubadminRequest()); 
      const apiResponse = yield call(getProfileBySubadminApi, action.payload);
     if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
         yield put(getProfileBySubadminSuccess(listData));
  
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getProfileBySubadminFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  
  function* updateProfileBySubadmin(action) {
    try {
       yield put(updateProfileBySubadminRequest()); 
      const apiResponse = yield call(updateProfileByOrganizationAPI, action.payload);
     if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
         yield put(updateProfileBySubadminSuccess(listData));
         getProfileBySubadmin(action.payload.token)
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put( updateProfileBySubadminFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  export function* subadminSaga() {
    yield takeLatest(subadminProfileAction.type, getProfileBySubadmin);  
    yield takeLatest(updateSubadminProfileAction.type, updateProfileBySubadmin);     
  }

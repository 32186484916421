import React, { useContext, useEffect } from 'react';

import moment from 'moment';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getAppointmentDetailsAPI,
	getMedicalHistoryDataAPI
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import {
	StyledContainer,
	StyledButton
} from './styles';

import CardComponent from '../../../../components/Card';
import InsideMenuBar from '../../../../components/InsideMenus';

function ViewPatientDetails(props) {
	const [state, dispatch] = useContext(Context);
	const [currentTab, setCurrentTab] = React.useState(1);
	const [id, setId] = React.useState(null);
	const [patientId, setPatientId] = React.useState(null);

	const [appointment, setAppointment] = React.useState({
		OrganizationId: null,
		SecondOpinionId: {},
		amount: null,
		appointmentType: null,
		conversions: [],
		createdAt: null,
		doctorId: {},
		patientId: {},
		slot: {},
		status: 0,
		_id: "601c0dfdfcfe01d6ad88b3ed"
	});

	const [medicalHistory, setMedicalHistory] = React.useState({
		allergieData: [],
		healthConditionData: [],
		medicationData: [],
		surgeriesData: [],
		vaccinationData: []
	});

	useEffect(() => {
		if(props.match.params && props.match.params.id) { 
			setId(props.match.params.id);
			setPatientId(props.match.params.patientId);
			loader(true);
			
			getAppointment(props.match.params.id);
			getMedicalHistoryDataAPI(props.match.params.patientId).then(res => {
				setMedicalHistory(res.data);
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		}
	}, [])

	const getAppointment = (id) => {
		getAppointmentDetailsAPI(id).then(res => {
			setAppointment(res.data);
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">

							<CardComponent className="p-4 mb-4">
								<div className="d-flex btns-group-patient">
									<StyledButton className={currentTab === 1 ? 'active-btn' : ''} onClick={() => setCurrentTab(1)}>Patient Info / Booking info</StyledButton>
									{/* <StyledButton className={currentTab === 2 ? 'active-btn' : ''} onClick={() => setCurrentTab(2)}>Medical history</StyledButton>
									<StyledButton className={currentTab === 3 ? 'active-btn' : ''} onClick={() => setCurrentTab(3)}>Heath parameters</StyledButton> */}
									<StyledButton className={currentTab === 4 ? 'active-btn' : ''} onClick={() => setCurrentTab(4)}>Feedback</StyledButton>
								</div>

								{
									currentTab === 1 && (<PatientInfoBookingInfo  appointment={appointment} />)
								}

								{
									currentTab === 2 && (<MedicalHistory medicalHistory={medicalHistory} />)
								}

								{
									currentTab === 3 && (<HeathParameters appointment={appointment} />)
								}

								{
									currentTab === 4 && (<Feedback appointment={appointment} />)
								}
								

							</CardComponent>
							
						</div>

					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default ViewPatientDetails

function PatientInfoBookingInfo(props) {

	const getStatusClass = (item) => {
		if(item === 0) {
			return 'badge badge-pill bg-warning-light'
		} else if(item === 1 || item === 4) {
			return 'badge badge-pill bg-success-blue text-white'
		} else if(item === 2 || item === 3) {
			return 'badge badge-pill bg-danger-light'
		}
	}
	
	const getStatus = (item) => {
		if(item === 0) {
			return 'Pending'
		} else if(item === 1) {
			return 'Confirm'
		} else if(item === 2) {
			return 'Rejected by doctor'
		}  else if(item === 3) {
			return 'Rejected by patient'
		}  else if(item === 4) {
			return 'Completed'
		}
	}

	const getApptStatus = (item) => {
		if(item === 0) {
			return 'In-progress'
		} else if(item === 1) {
			return 'Completed'
		} 	
	}

	return (
		<div className="row">
			<div className="col-md-6">

				<CardComponent className="p-4 mt-4">
					<div className="card-header">
						<h4 className="card-title">Appt. Booking Summary</h4>
					</div>
					
					<div className="card-body">
						<div className="booking-doc-info">
							<a className="booking-doc-img">
								<img src={props.appointment.doctorId.profileImage ? props.appointment.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
							</a>
							<div className="booking-info">
								<h4><a >{props.appointment.doctorId.name}</a></h4>
								<div className="rating">
									<span className="d-inline-block average-rating">{props.appointment.doctorId.department}</span>
								</div>
								<div className="clinic-details">
									<p className="doc-location"><i className="fas fa-map-marker-alt"></i> {props.appointment.doctorId.address}</p>
								</div>
							</div>
						</div>

						<div className="booking-summary">
							<div className="booking-item-wrap">
								<ul className="booking-fee">
									<li>
										Appt. Date 
										<span style={{color: '#56be73'}}>
											{props.appointment.slot.start ?  moment(props.appointment.slot.start).utc().format('DD/MM/YYYY') : '-'}
										</span>
									</li>
									<li>Appt. Time 
										<span style={{color: '#56be73'}}>
										{props.appointment.slot.start ?  moment(props.appointment.slot.start).utc().format('hh:mm A') : '-'} - {props.appointment.slot.end ?  moment(props.appointment.slot.end).utc().format('hh:mm A') : '-'}
										</span>
									</li>
									<li>Last Updated <span style={{color: '#56be73'}}>{props.appointment.last_updated ? moment(props.appointment.last_updated).utc().format('DD/MM/YYYY') : '-'}</span></li>
									<li>Consulting Fee <span style={{color: '#56be73'}}>{props.appointment.amount ? props.appointment.amount : '-'}</span></li>
								</ul>
								<div className="booking-total">
									<ul className="booking-total-list">
										<li>
											<span>Booking Status</span>
											<span className={`total-cost ${getStatusClass(props.appointment.status)}`}>{getStatus(props.appointment.status)}</span>
										</li>
										<li>
											<span>Appt. Status	</span>
											<span className="total-cost">{getApptStatus(props.appointment.appointmentType)}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</CardComponent>

			</div>

			<div className="col-md-6">

				<CardComponent className="p-4 mt-4">
					<div className="card-header">
						<h4 className="card-title">Patient info</h4>
					</div>
					
					<div className="card-body">
						<div className="booking-doc-info">
							<a className="booking-doc-img">
								<img src={props.appointment.patientId.profileImage ? props.appointment.patientId.profileImage : "/assets/img/loading-img.jpg"} alt="User Image" />
							</a>
							<div className="booking-info">
								<h4><a >{props.appointment.patientId.name} {props.appointment.patientId.bloodGroup && ('('+props.appointment.patientId.bloodGroup+')')}</a></h4>
								<div className="rating">
									<span className="d-inline-block average-rating">
										<i className="fas fa-birthday-cake pr-2"></i>
										{props.appointment.patientId.dob ? moment(props.appointment.patientId.dob).format('Do MMMM YYYY') : '-'}{props.appointment.patientId.dob && ','} {props.appointment.patientId.dob ? moment().diff(props.appointment.patientId.dob, 'years',false) : null }{props.appointment.patientId.dob ? 'years' : null}
									</span>
								</div>
								<div className="clinic-details">
									<p className="doc-location"><i className="fas fa-map-marker-alt"></i> {props.appointment.patientId.address ? props.appointment.patientId.address : '-'}</p>
								</div>
							</div>
						</div>

						<div className="booking-summary">
							<div className="booking-item-wrap">
							<ul className="booking-date">
									<li>Gender <span>{props.appointment.patientId.gender ? props.appointment.patientId.gender : '-'}</span></li>
									<li>Contact number<span>{props.appointment.patientId.address ? props.appointment.patientId.address : '-'}</span></li>
								</ul>
								<hr />
								<h3>Emergency contacts</h3>
								<ul className="booking-fee">
									<li>Name <span>{props.appointment.patientId.emergency_name ? props.appointment.patientId.emergency_name : '-'}</span></li>
									<li>Relation <span>{props.appointment.patientId.emergency_relation ? props.appointment.patientId.emergency_relation : '-'}</span></li>
									<li>Contact number <span>{props.appointment.patientId.emergency_number ? props.appointment.patientId.emergency_number : '-'}</span></li>
								</ul>
								
							</div>
						</div>
					</div>
				</CardComponent>
			</div>
		</div>
	)
}

function MedicalHistory(props) {
	return (
	<div>
		<div className="row mb-4 mt-5">
			<h3 className="ml-3">Health conditions</h3>
			<div className="col-md-12">
					{
						props.medicalHistory.healthConditionData && props.medicalHistory.healthConditionData.map(item => {
							return <CardComponent className="p-4 mt-2"><div className="row" style={{ marginBottom: '10px'}}>
											<div className="col-md-6">
					
												<ul className="booking-date bor-right-1">
													<li>Name <span>{item.name}</span></li>
													<li>Status <span>{item.status === 0 ? 'Ongoing' : '-'}</span></li>
													<li>Medication <span>{item.medication}</span></li>
												</ul>
					
											</div>
					
											<div className="col-md-6">
					
												<ul className="booking-date">
													<li>Diagnosed on <span>{item.diagnosed_on}</span></li>
													<li>Treated by <span>{item.treated_by}</span></li>
													<li>Notes <span>{item.notes}</span></li>
												</ul>
					
												</div>
										</div></CardComponent>
						})
					}
					
			</div>
		</div>
		<hr />

		<div className="row mb-4">
			<h3 className="ml-3">Allergies</h3>
			<div className="col-md-12">
					{
						props.medicalHistory.allergieData && props.medicalHistory.allergieData.map(item => {
							return <CardComponent className="p-4 mt-2"><div className="row" style={{marginBottom: '10px'}}>
											<div className="col-md-6">
					
												<ul className="booking-date bor-right-1">
													<li>Name <span>{item.name}</span></li>
													<li>Reaction <span>{item.reaction}</span></li>
													<li>First diagnosed on <span>{item.first_diagnosed}</span></li>
													<li>Notes <span>{item.notes}</span></li>
												</ul>
					
											</div>
					
											<div className="col-md-6">
					
												<ul className="booking-date">
													<li>Triggered by <span>{item.triggered_by}</span></li>
													<li>How often does it occur <span>{item.how_often_does}</span></li>
													<li>Medication <span>{item.medication}</span></li>
												</ul>
					
												</div>
										</div></CardComponent>
						})
					}
					
			</div>
		</div>
		<hr />

		<div className="row mb-4">
			<h3 className="ml-3">Surgeries</h3>
			<div className="col-md-12">
					{
						props.medicalHistory.surgeriesData && props.medicalHistory.surgeriesData.map(item => {
							return <CardComponent className="p-4 mt-2"><div className="row" style={{borderBottom: '1px solid #ccc', marginBottom: '10px'}}>
											<div className="col-md-6">
					
												<ul className="booking-date bor-right-1">
													<li>Name <span>{item.name}</span></li>
													<li>Date <span>{item.date}</span></li>
													<li>Notes <span>{item.notes}</span></li>
												</ul>
					
											</div>
					
											<div className="col-md-6">
					
												<ul className="booking-date">
													<li>Implants / Support devices<span>{item.implants}</span></li>
													<li>Treated by <span>{item.by}</span></li>
												</ul>
					
												</div>
										</div></CardComponent>
						})
					}
					
			</div>
		</div>
		<hr />

		<div className="row mb-4">
			<h3 className="ml-3">Medications</h3>
			<div className="col-md-12">
					{
						props.medicalHistory.medicationData && props.medicalHistory.medicationData.map(item => {
							return <CardComponent className="p-4 mt-2"><div className="row" style={{ marginBottom: '10px'}}>
											<div className="col-md-6">
					
												<ul className="booking-date bor-right-1">
													<li>Medication name <span>{item.name}</span></li>
													<li>Quantity <span>{item.quantity}</span></li>
													<li>Duration (Start on) <span>{item.start_date}</span></li>
													<li>Prescribed by <span>{item.by && item.by}</span></li>
													<li>Other instructions <span>{item.instructions}</span></li>
												</ul>
					
											</div>
					
											<div className="col-md-6">
					
												<ul className="booking-date">
													<li>Dosage<span>{item.method_intake} ({item.method_intake}) / {item.form_medicine}</span></li>
													<li>When <span>{item.when}</span></li>
													<li>Duration (End on) by <span>{item.end_date}</span></li>
													<li>Notes / Side effects <span>{item.notes}</span></li>
												</ul>
					
												</div>
										</div></CardComponent>
						})
					}
		</div>
		</div>
		<hr />

		<div className="row mb-4">
			<h3 className="ml-3">Vaccinations</h3>
			<div className="col-md-12">
				
					{
						props.medicalHistory.vaccinationData && props.medicalHistory.vaccinationData.map(item => {
							return <CardComponent className="p-4 mt-2"><div className="row" style={{marginBottom: '10px'}}>
											<div className="col-md-6">
					
												<ul className="booking-date bor-right-1">
													<li>Vaccination for <span>{item.for}</span></li>
													<li>Vaccine name <span>{item.vaccine_name}</span></li>
													<li>Lot number <span>#{item.lot_number}</span></li>
												</ul>
					
											</div>
					
											<div className="col-md-6">
					
												<ul className="booking-date">
													<li>Taken on <span>{item.taken_on}	</span></li>
													<li>Vaccine details <span>{item.vaccine_details}</span></li>
													<li>Notes <span>{item.notes}</span></li>
												</ul>
					
											</div>
					
										</div></CardComponent>
						})
					}
					
			</div>
		</div>
	</div>
	)
}

const menus1 = [
	{id: 1, name: 'Blood pressure'},
	{id: 2, name: 'Blood sugar'},
	{id: 7, name: 'Oxygen level'},
	{id: 9, name: 'Pulse'},
	{id: 10, name: 'Respiratory rates'},
	{id: 3, name: 'Temperature'}
];

const menus2 = [
	{id: 1, name: 'Cholesterol'}
];

const menus3 = [
	{id: 1, name: 'Joint stiffness'},
	{id: 2, name: 'Pain'}
];

const menus4 = [
	{id: 1, name: 'Exercise'},
	{id: 2, name: 'Food'},
	{id: 7, name: 'Height'},
	{id: 9, name: 'Sleep'},
	{id: 10, name: 'Waist measurement'},
	{id: 3, name: 'Weight'}
];

function HeathParameters() {
	const [currentTitle, setCurrentTitle] = React.useState('Blood pressure');

	return (
		<div className="row mt-4">
			<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
				<div className="profile-sidebar">
					
					<div className="dashboard-widget">
						<nav className="dashboard-menu">
							<h4 className="ml-3 mt-3">Vital signs</h4>
							<ul>
								{
									menus1.map(item => (
										<li key={item.id} className={item.name === currentTitle ? 'active' : ''}>
											<a onClick={() => setCurrentTitle(item.name)}>
												<span>{item.name}</span>
											</a>
										</li>
									))
								}
							</ul>

							<h4 className="ml-3 mt-3">Lipid profile</h4>
							<ul>
								{
									menus2.map(item => (
										<li key={item.id} className={item.name === currentTitle ? 'active' : ''}>
											<a onClick={() => setCurrentTitle(item.name)}>
												<span>{item.name}</span>
											</a>
										</li>
									))
								}
							</ul>

							<h4 className="ml-3 mt-3">Symptoms</h4>
							<ul>
								{
									menus3.map(item => (
										<li key={item.id} className={item.name === currentTitle ? 'active' : ''}>
											<a onClick={() => setCurrentTitle(item.name)}>
												<span>{item.name}</span>
											</a>
										</li>
									))
								}
							</ul>

							<h4 className="ml-3 mt-3">Fitness and well-being</h4>
							<ul>
								{
									menus4.map(item => (
										<li key={item.id} className={item.name === currentTitle ? 'active' : ''}>
											<a onClick={() => setCurrentTitle(item.name)}>
												<span>{item.name}</span>
											</a>
										</li>
									))
								}
							</ul>

						</nav>
					</div>
				</div>
			</div>

			<div className="col-md-7 col-lg-8 col-xl-9">
				<h2>{currentTitle}</h2>
				<CardComponent className="p-4 mb-4">
				</CardComponent>

			</div>
		</div>
	)
}

function Feedback(props) {
	return (
		<div>
			<CardComponent className="mt-4 p-4 mb-4">
				{props.appointment.feedback ? props.appointment.feedback : '-'}

			</CardComponent>
		</div>
	)
}
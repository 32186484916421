import React from "react";
import HeadingComponent from "../../HeadingComponent";
import { Button, Grid } from "@mui/material";
import SearchFields from "../../../../pages/PatientPanel/PatientAppointments/SearchFields";
import ItemList from "../../../controls/ItemList";
import { StyledViewMoreButton } from "../../../../assets/styles/CommonStyles";
import { subadminDashboardStyles } from "../../../../assets/styles/subadminDashboardStyle";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";

const ListComponent = ({
  listHeading,
  searchPlaceholder,
  totals,
  list,
  viewText,
  navigate,
  setScreen,
}) => {
  const classes = subadminDashboardStyles();
  const commonStyleClasses = commonMuiStyles();

  const handleList = () => {
    if (navigate === "patient") {
      setScreen(2);
    }
    if (navigate === "doctor") {
      setScreen(3);
    }
  };
  // const handleValue = (value) => {
   
  //   const searchResults = data?.filter((item) =>
  //     item.name.toLowerCase().includes(value.toLowerCase())
  //   );
  //   console.log('searchResults', searchResults)
  //   setList(searchResults)
  //   if (value === "") {
  //     setList(data);
  //   }
  // };
  return (
    <>
      <HeadingComponent
        navigate="organisation"
        text={{
          opd: listHeading,
        }}
      />
      <Grid
        container
        className={`${classes.contBorderStyle} ${classes.contStyle}`}
      >
        {/* <Grid item xs={12}>
          <SearchFields placeholder={searchPlaceholder} handleList={} />
        </Grid> */}
        <Grid
          item
          xs={12}
          mt={2}
          textAlign={"center"}
          className={`${commonStyleClasses.fontText14} ${commonStyleClasses.textGradientColor}`}
        >
          {totals} {!!list && list.length}
        </Grid>
        <Grid item xs={12}>
          <ItemList data={list} navigate={navigate} />
        </Grid>
        <StyledViewMoreButton onClick={handleList}>
          {viewText}
        </StyledViewMoreButton>
      </Grid>
    </>
  );
};

export default ListComponent;

import React, { useState } from "react";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import {
  StyledCrossIcon,
  StyledSecondaryButton,
} from "../../assets/styles/CommonStyles";
import { Formik } from "formik";
import { CircularProgress, Grid } from "@mui/material";
import strings from "../../constant/strings";
import FieldWrapper from "../InputField/TextFieldWrapper";
import SelectionDropdown from "../control/SelectionDropdown";
import { roomAvailability, roomTypeList } from "../../constant/staticLists";
import { addRoomSchema } from "../../utils/forms/schema.addRoom";
import { medicationStyles } from "../../assets/styles/MedicationStyles";
import * as mainService from "../../services/mainService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getRoomListAction } from "../../redux/ipd/ipdAction";

const AddRoomForm = ({ setOpenModal, roomForm, selectedRoom }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const medicationClasses= medicationStyles();
  const [onLoading, setOnLoading] = useState(false);
  const { userData,  } = useSelector((state) => ({
    userData: state.auth.userData
  }))
  
  const initialValues = {
    number: selectedRoom?.number ,
    type:  selectedRoom?.type ,
    status:  selectedRoom?.status,
  };

  const handleSubmit = async (values, { resetForm }) => {
    let res = '';
    if(roomForm === 0) {
      res = await mainService.addRoomApi(values, userData.token,);
    }
    else {
      if(selectedRoom?.number === values.number){
        delete values.number
      }
      values = {...values, roomID: selectedRoom?._id}
      res = await mainService.updateRoomApi(values, userData.token,);
    }
    if (res.response_type === "fail") {
      setOnLoading(false);
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      resetForm();
      setOnLoading(false);
      setOpenModal(false);
      toast.success(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
      dispatch(getRoomListAction(userData.token))
    }
    resetForm();
    setTimeout(()=>{
      setOpenModal(false);
    },2000)
   
  };

  return (
    <>
      <div>
        <Grid className={classes.secondayCont}>
          <h2 className={classes.mainCont}>{roomForm === 0 ? "Add room" : strings.editRoom}</h2>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={() => setOpenModal(false)}
          />
        </Grid>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={addRoomSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              errors,
              handleBlur,
              values,
              setFieldValue,
              setTouched,
              touched,
              resetForm,
            }) => {
              const handleDropdownValue = (fieldName, data) => {
                if (data) {
                  setFieldValue(fieldName, data.level);
                }
              };

              return (
                <>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="number"
                        type="text"
                        value = { values.number}
                        label={values.number === '' ? `${strings.roomNumber}` : ''}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="type"
                        label={`${strings.selectType}`}
                        list={roomTypeList}
                        value={values.type}
                        height= '36px'
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("type", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="status"
                        label={`${strings.availability}`}
                        list={roomAvailability}
                        height= '36px'
                        value = {values.status}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("status", data)
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      display="flex"
                      justifyContent={"center"}
                    >
                      <StyledSecondaryButton
                        name="submit"
                        disabled={Object.keys(errors).length > 0 || onLoading}
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        {onLoading ? 
                         ( <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                            thickness={5}
                          />)
                         : (roomForm ===  0 ? 'Add room' : strings.done)
                        }
                      </StyledSecondaryButton>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddRoomForm;

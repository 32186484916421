import React, { useEffect, useState, useRef, useContext } from 'react';
import moment from 'moment';
import Peer from "simple-peer";

import { Howl } from 'howler';

import { Context } from '../../../store';


import {
	genrateFIleLinkAPI
} from '../../../api/patient';

import {
	SET_COMMON_LOADING, SET_ERROR
} from '../../../store/types';

import './chat.css';

import { getUser } from '../../../utils';

import ringtone from '../../../ringtone.mp3';
import { toast } from 'react-toastify';
import { Icon, MissedMessages, MissedWraper } from './styles';

const ringtoneSound = new Howl({
	src: [ringtone],
	loop: true,
	preload: true
})

function split_at_index(str, length){
	if(length) return str.match(new RegExp('.{1,' + parseInt(length) + '}', 'g')).join(' ');
}
function ChatBody(props) {
	const [state, dispatch] = useContext(Context);

	const { id, className = '', mobile = false } = props;

	const fileInput = useRef(null);

	const [currentChat, setCurrentChat] = useState(null);
	const [currentUserId, setCurrentUserId] = useState(null);
	const [message, setMessage] = useState(null);
	const bottomRef = useRef();

	const scrollToBottom = () => {
		if (bottomRef.current) {
			bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
		}
	};

	useEffect(() => {
		const userId = getUser()._id;
		setCurrentUserId(userId);
		const startChat = {
			appointmentId: id,
			role: "Patient"
		};
		// socket.emit("Start_Chat_new", startChat);
		// socket.on("messages", data => {
		// 	setCurrentChat(data);
		// 	scrollToBottom();
		// });
	}, [id]);

	const sendMessage = () => {
		if (message) {
			// const user = getUser();
			// const socketMsg = {
			// 	message: message,
			// 	appointmentId: id,
			// 	userId: user._id,
			// 	receiverId: currentChat.doctorId._id,
			// 	role: "Patient",
			// 	email: user.email,
			// 	name: user.name,
			// 	link: "https://emed4u.com/patient/chat"
			// };
			// socket.emit("Send_Message_Chat", socketMsg);
			setMessage("");
		}
	};

	const sendFile = (event) => {
		var type = event.target.files[0].type;
		if(event.target.files[0].size > 5242880){
			toast.error('Please upload a file smaller than 5 MB')
			dispatch({
				type: SET_ERROR,
				payload: 'Please upload a file smaller than 5 MB'
			});
            return
        }
		if (event.target.files[0]) {
			const formData = new FormData();
			formData.append("documents", event.target.files[0]);
			const user = getUser();
			loader(true);
			genrateFIleLinkAPI(formData).then(res => {
				loader(false);
				// const socketMsg = {
				// 	document: res,
				// 	documentType: type,
				// 	appointmentId: id,
				// 	userId: user._id,
				// 	receiverId: currentChat.doctorId._id,
				// 	role: "Patient",
				// 	email: user.email,
				// 	name: user.name,
				// 	link: "https://emed4u.com/patient/chat"
				// };
				// socket.emit("Send_Message_Chat", socketMsg);
			})
		}
	};

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const unselected = () => {
		props.unSelect();
	}

	const onEnter = (e) => {
		if (e.key === "Enter" && message) {
			// const user = getUser();
			// const socketMsg = {
			// 	message: message,
			// 	appointmentId: id,
			// 	userId: user._id,
			// 	receiverId: currentChat.doctorId._id,
			// 	role: "Patient",
			// 	email: user.email,
			// 	name: user.name,
			// 	link: "https://emed4u.com/patient/chat"
			// };
			//socket.emit("Send_Message_Chat", socketMsg);
			setMessage("");
		}
	}

	const [audio, setAudio] = useState(true);
	const [video, setVideo] = useState(true);
	const [receivingCall, setReceivingCall] = useState(false);
	const [caller, setCaller] = useState("");
	const [callerSignal, setCallerSignal] = useState();
	const [callAccepted, setCallAccepted] = useState(false);

	const userVideo = useRef();
	const partnerVideo = useRef();

	// useEffect(() => {
	// 	socket.on("doctor_calling", data => {
	// 		ringtoneSound.play();
	//   setReceivingCall(true);
	//   setCaller(data.from);
	// 		setCallerSignal(data.signal);
	// 	})


	// 	socket.on("endCall", data => {
	// 		window.location.reload();
	// 	});
	// }, [])

	const acceptCall = () => {
		ringtoneSound.unload();
		setCallAccepted(true);
		setReceivingCall(false);

		const constraints = {
			audio: audio,
			video: video,
			options: {
				muted: true,
				mirror: true
			}
		};

		navigator.mediaDevices.getUserMedia(constraints).then(stream => {
			if (userVideo.current) {
				userVideo.current.srcObject = stream;
			}

			const peer = new Peer(undefined, {
				initiator: false,
				trickle: false,
				stream: stream,
			});

			peer.on("signal", data => {
				// socket.emit("acceptCall", {
				// 	signal: data,
				// 	appointmentId: id,
				// 	stream: stream,
				// 	doctorId: currentChat.doctorId._id
				// })
			})

			peer.on("stream", stream => {

				setTimeout(() => {
					partnerVideo.current.srcObject = stream;
				}, 200)

			});

			peer.signal(callerSignal);
		})

	}

	const endCall = () => {
		// socket.emit("endCall", {
		// 	patientId: currentChat.patientId._id,
		// 	doctorId: currentUserId,
		// 	appointmentId: id
		// })
	}

	const [onSetDelete, setOnSetDelete] = React.useState({
		appointmentId: null,
		userId: null,
		receiverId: null,
		msgId: null
	})

	const onSetMessage = (msg, currentChat) => {
		setOnSetDelete({
			appointmentId: currentChat._id,
			userId: msg.userId,
			receiverId: currentChat.doctorId._id,
			msgId: msg._id
		});
	}

	const onDeleteMsg = () => {
		// socket.emit("delete_message_chat", onSetDelete);
	}
	const chatOffsetWidth = document.getElementById("yourDiv") && document.getElementById("yourDiv").offsetWidth;
	const splitText = (text) => {
		const splitPointchat = chatOffsetWidth / 50;
		const sentence = split_at_index(text, splitPointchat);
		return sentence;
	}
	return (
		<div className={`chat-cont-right ${className}`}>
			<div className="chat-header">
				{
					mobile && <a id="back_user_list" href="javascript:void(0)" className="back-user-list" onClick={() => unselected()}>
						<i className="material-icons">chevron_left</i>
					</a>
				}

				<div className="media">
					<div className="media-img-wrap">
						<div className="avatar">
							<img src={currentChat && currentChat.doctorId.profileImage ? currentChat.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" className="avatar-img rounded-circle" />
						</div>
					</div>
					<div className="media-body">
						<div className="user-name">{currentChat && currentChat.doctorId.name}</div>
						{/* <div className="user-status">active</div> */}
					</div>
				</div>
				{/* <div className="chat-options">
					<a href="javascript:void(0)" data-toggle="modal" data-target="#voice_call">
						<i className="material-icons">local_phone</i>
					</a>
					<a href="javascript:void(0)" data-toggle="modal" data-target="#video_call">
						<i className="material-icons">videocam</i>
					</a>
					<a href="javascript:void(0)">
						<i className="material-icons">more_vert</i>
					</a>
				</div> */}
			</div>


			{receivingCall ? (

				<div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body">

								<div className="call-box incoming-box">
									<div className="call-wrapper">
										<div className="call-inner">
											<div className="call-user">
												<img className="call-avatar" src={currentChat.doctorId.profileImage ? currentChat.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
												<h4>{currentChat.doctorId.name}</h4>
												<span>Calling ...</span>
											</div>
											<div className="call-items">
												<a href="javascript:void(0);" className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons" onClick={() => endCall()}>call_end</i></a>
												<a href="javascript:void(0);" onClick={() => acceptCall()} className="btn call-item call-start"><i className="material-icons">videocam</i></a>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						{/* <div className="on-call-ring">
					<p>
						<span className="caller">Doctor is calling</span>
					</p>
					<button
						type="button"
						className="btn-action fa fa-video-camera"
						onClick={() => acceptCall()}
					></button>
					<button
						type="button"
						className="btn-action hangup fa fa-phone"
					></button>
				</div> */}
					</div>
				</div>
			) : null}




			{
				callAccepted ?
					<div class="call-wrapper patient-call-wrapper-mo">
						<div class="call-main-row">
							<div class="call-main-wrapper">
								<div class="call-view">
									<div class="call-window">

										<div class="fixed-header">
											<div class="navbar">
												<div class="user-details">
													<div class="float-left user-img">
														<a class="avatar avatar-sm mr-2" href="patient-profile.html" title="Charlene Reed">
															<img src={currentChat && currentChat.doctorId.profileImage ? currentChat.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" class="rounded-circle" />
															<span class="status online"></span>
														</a>
													</div>
													<div class="user-info float-left">
														<a href="patient-profile.html"><span>{currentChat && currentChat.doctorId.name}</span></a>
														{/* <span class="last-seen">Online</span> */}
													</div>
												</div>

											</div>
										</div>

										<div class="call-contents">
											<div class="call-content-wrap">
												<div class="user-video">
													{/* <img src="/assets/img/video-call.jpg" alt="User Image" /> */}

													<video
														ref={partnerVideo}
														autoPlay
														style={{ width: "100%", height: "100%" }}
													></video>
												</div>
												<div class="my-video">
													<ul>
														<li>

															{/* <img src="/assets/img/patients/patient1.jpg" class="img-fluid" alt="User Image" /> */}
															<video
																ref={userVideo}
																autoPlay
																style={{ width: "100%", height: "100%" }}
																muted
															></video>
														</li>
													</ul>
												</div>
											</div>
										</div>

										<div class="call-footer">
											<div class="call-icons">
												{/* <span class="call-duration">00:59</span> */}
												<ul class="call-items">
													<li class="call-item">
														<a href="javascript:void(0);" title="Enable Video" data-placement="top" data-toggle="tooltip">
															<i class="fas fa-video camera"></i>
														</a>
													</li>
													<li class="call-item">
														<a href="javascript:void(0);" title="Mute Audio" data-placement="top" data-toggle="tooltip">
															<i class="fa fa-microphone microphone"></i>
														</a>
													</li>
												</ul>
												<div class="end-call">
													<a href="javascript:void(0);" class="btn call-item call-end" data-dismiss="modal" aria-label="Close" onClick={() => endCall()}>
														<i class="material-icons">call_end</i>
													</a>
												</div>
											</div>
										</div>

									</div>
								</div>

							</div>
						</div>
					</div>

					: null
			}

			<div className="chat-body">
				<div id="yourDiv" className="chat-scroll mobile-screen-chat-scroll" ref={bottomRef}>
					<ul className="list-unstyled">
						{
							currentChat && currentChat.conversions.map(item => {
								if (item.role != 'Patient' && item.role != 'Doctor' && item.role) {
									if(item.role == 'dmissedAudioCallByDoctor-custom-msg-emed4u') return <MissedWraper><MissedMessages><>Missed audio call at {moment(item.createdAt).format('hh:mm A')}</><Icon className="material-icons">phone_missed</Icon></MissedMessages></MissedWraper> // phone_missed
									return <MissedWraper><MissedMessages><>Missed vidio call at {moment(item.createdAt).format('hh:mm A')}</><Icon className="material-icons">phone_missed</Icon></MissedMessages></MissedWraper>
								}
								return (
									item.role === 'Patient' ? (
										<li className="media sent" key={item.id}>
											<div className="media-body">
												<div className="msg-box">
													<div>
														{
															item.documents && <a
																target="_blank"
																href={item.documents}
																download
																style={{ color: '#5156be' }}
															>
																Click here to show document
															</a>
														}
														{false ? <p>{splitText(item.text)}</p> : <p>{item.text}</p>}
														<ul className="chat-msg-info">
															<li>
																<div className="chat-time">
																	<span>{moment(item.createdAt).fromNow()}</span>
																</div>
															</li>
														</ul>
													</div>
													<div className="ml-1" data-toggle="modal" data-original-title="test" data-target="#deleteChatModal" onClick={() => onSetMessage(item, currentChat)}>
														<i className="fa fa-trash-alt" />
													</div>
												</div>
											</div>
										</li>
									) : (
										<li className="media received" key={item.id}>
											<div className="avatar">
												<img src={currentChat.doctorId.profileImage ? currentChat.doctorId.profileImage : '/assets/img/doctor-empty.png'} alt="User Image" className="avatar-img rounded-circle" />
											</div>
											<div className="media-body">
												<div className="msg-box">
													<div>
														{
															item.documents && <a
																target="_blank"
																href={item.documents}
																download
																style={{ color: '#5156be' }}
															>
																Click here to show document
															</a>
														}
														{false ? <p>{splitText(item.text)}</p> : <p>{item.text}</p>}
														<ul className="chat-msg-info">
															<li>
																<div className="chat-time">
																	<span>{moment(item.createdAt).fromNow()}</span>
																</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</li>
									)
								)
							})
						}

					</ul>
				</div>
			</div>

			<div className="chat-footer mobile-screen-chat-footer">
				<div className="input-group">
					<div className="input-group-prepend">
						<div className="btn-file btn">
							<i className="fa fa-paperclip"></i>
							<input
								type="file"
								ref={fileInput}
								onChange={sendFile}
							/>
						</div>
					</div>
					<input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} />
					<div className="input-group-append">
						<button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button>
					</div>
				</div>
			</div>

			<div class="modal fade" id="deleteChatModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div class="modal-dialog" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">Are you really want to delete this message?</h5>
							<button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""><span aria-hidden="true">×</span></button>
						</div>
						{/* <div class="modal-body">...</div> */}
						<div class="modal-footer">

							<button class="btn btn-primary" type="button" data-dismiss="modal" data-original-title="" title="" onClick={() => onDeleteMsg()}>Yes</button>

							<button class="btn btn-danger" type="button" data-dismiss="modal" data-original-title="" title="">No</button>
						</div>
					</div>
				</div>
			</div>

		</div>

	)
}

export default ChatBody

import { makeStyles } from "@mui/styles";

import colors from "../../../constant/colors";
import fontWeights from "../../../constant/fontWeights";

const useMeasurementStyles = makeStyles((theme) => ({
  editIcon:{
    marginLeft: 10
  },
  gridItem: {
    height: "2vw",
  },
  title: {
    fontWeight: fontWeights.fontWeight500,
    fontFamily: "Poppins",
    fontStyle: "normal",
    textTransform: "uppercase !important",
    color: colors.black1,
    fontSize: "16px",
    lineHeight: "24px",
  },
  measurement: {
    fontWeight: fontWeights.fontWeight700,
    fontFamily: "Poppins",
    fontStyle: "normal",
    textTransform: "uppercase !important",
    color: "#097EB7",
    fontSize: "16px",
    lineHeight: "24px",
    background: colors.primary,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  imageItem: {
    height: "1vw",
  },
}));

export { useMeasurementStyles };

import { createAction } from "@reduxjs/toolkit";

export const subadminProfileAction = createAction('user/subadmin/get-profile', (payload) => {
    return {
       payload:payload,
     };
   });
 
export const updateSubadminProfileAction = createAction('user/subadmin/update-profile', (payload) => {
        return {
        payload:payload,
      };
});
import { Grid, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { medicationStyles } from "../../../assets/styles/MedicationStyles";
import { roomTypeList } from "../../../constant/staticLists";
import { getRoomListAction } from "../../../redux/ipd/ipdAction";
import FieldWrapper from "../../InputField/TextFieldWrapper";
import PhoneField from "../../PhoneField";
import SelectionDropdown from "../../control/SelectionDropdown";

const AdmissionDetails = ({
  admissionInitialValues,
  handleDropdownValue,
  setFieldValue,
  values,
  errors,
  setTouched,
  touched,
  doctorLists
}) => {
  const dispatch = useDispatch();
  const medicationClasses = medicationStyles();
  const [filteredRoomList, setFilteredRoomList] = useState([]);
  const [selectedRoomList, setSelectedRoomList] = useState([]);

  const { roomList, userData } = useSelector((state) => ({
    roomList: state.ipd.roomList,
    userData: state.auth.userData,
  }));

  useEffect(() => {
    let list = roomList
      .filter((room) => room.status === 0)
      .map((room) => ({
        label: `Room number: ${room.number}`,
        level: room.number,
        type: room.type,
      }));
    setFilteredRoomList(list);
  }, []);

  const handleDate = (date) => {
    let data = dayjs(date.$d).format("YYYY-MM-DD");
    setFieldValue("admission_date", data);
  };

  const handleRoomType = (fieldName, value) => {
    setFieldValue(fieldName, value.level);
    const matchedField = filteredRoomList.filter(
      (room) => room.type === value.level
    );
    setSelectedRoomList(matchedField);
  };

  useEffect(async () => {
    roomList.length === 0 && dispatch(getRoomListAction(userData.token));
  }, []);
 
  const handlePhone = (e, value) => {
    setFieldValue("countryCode", value);
    setFieldValue("referernceContactNo", e);
  };

  return (
    <>
      <Grid item xs={12}>
        <FieldWrapper
          name="patientUniqueId"
          type="text"
          label={"Enter patients's unique ID"}
        />
      </Grid>
      <Grid item xs={12}>
      <SelectionDropdown
          name = "doctorId"
          label = "Select Doctor"
          list = {doctorLists}
          height = {"36px"}
          className = {medicationClasses.customAutocomplete}
          handleDropdownValue = {(data) => handleDropdownValue("doctorId", data)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        name="admission_date"
        sx={{
          width: "100% !important",
          "& .MuiStack-root": {
            flexDirection: "column",
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              disablePast
              //value={dayjs(values.admission_date)}
              onChange={handleDate}
              renderInput={(params) => <TextField {...params} />}
              label="Admission date"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <SelectionDropdown
          name="roomType"
          label="Room type"
          list={roomTypeList} 
          height={"36px"}
          className={medicationClasses.customAutocomplete}
          handleDropdownValue={(data) => handleRoomType("roomType", data)}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectionDropdown
          name="roomNumber"
          label="Room Number"
          list={
            selectedRoomList?.length === 0 ? filteredRoomList : selectedRoomList
          }
          height={"36px"}
          disabled={selectedRoomList.length === 0}
          className={medicationClasses.customAutocomplete}
          handleDropdownValue={(data) =>
            handleDropdownValue("roomNumber", data)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWrapper name="reason" type="text" label="Reason for admission" />
      </Grid>
      <Grid item xs={12}>
        <FieldWrapper
          name="referernceName"
          type="text"
          label="Reference name (optional)"
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneField
          //value={admissionInitialValues.referernceContactNo}
          name="referernceContactNo"
          onChange={(e, value) => handlePhone(e, value.dialCode)}
          onBlur={() => setTouched({ ...touched, referernceContactNo: true })}
          touched={touched}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} lg={6} md={12}>
        <FieldWrapper name="mlcNo" type="text" label="MLC no. (optional)" />
      </Grid>
      <Grid item xs={12} lg={6} md={12}>
        <FieldWrapper name="firNo" type="text" label="FIR no. (optional)" />
      </Grid>
    </>
  );
};

export default AdmissionDetails;

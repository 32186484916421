import React from 'react';

import Breadcrumb from '../../../components/Breadcrumb';
import StyledBlogCard from '../components/BlogCard';

import {
	StyledYoutube
} from './styles';

function Youtube() {

	const videos = [
		{
			id: 1,
			title: 'Excessive menstrual bleeding and hysteroscopy',
			date: 'Feb 3, 2021',
			totalViews: '520 views',
			video: 'NQ6hkU5Yl-c',
			live: false
		},
		{
			id: 2,
			title: 'What is Stroke?',
			date: 'Jan 8, 2021',
			totalViews: '118 views',
			video: 'PEPr7eK-a',
			live: false
		},
		{
			id: 3,
			title: 'Stroke Part 2',
			date: 'Jan 11, 2021',
			totalViews: '46 views',
			video: 'EcJrktc3RqM',
			live: false
		},
		{
			id: 4,
			title: 'What is Addiction? ',
			date: 'Feb 3, 2021',
			totalViews: '286 views',
			video: '58tK4tdoi40',
			live: false
		},
		{
			id: 5,
			title: 'Erectile Dysfunction',
			date: 'Dec 8, 2020',
			totalViews: '102 views',
			video: 'dmbL9Ak6q7Y',
			live: false
		},
		{
			id: 6,
			title: 'Erectile Dysfunction Part 2',
			date: 'Dec 14, 2020',
			totalViews: '90 views',
			video: 'BErTOBcV4kc',
			live: false
		},
		{
			id: 7,
			title: 'What is Vaginismus',
			date: 'Nov 26, 2020',
			totalViews: '639 views',
			video: 'bT2PZ6q-n_o',
			live: false
		},
		{
			id: 8,
			title: 'Vaginismus Treatment and Methods',
			date: 'Nov 30, 2020',
			totalViews: '1,346 views',
			video: 'yeYgdMIz_WQ',
			live: false
		},
		{
			id: 9,
			title: 'Public Health Education',
			date: 'Nov 23, 2020',
			totalViews: '54 views',
			video: 'J0VwfLjM9sM',
			live: false
		},{
			id: 10,
			title: "Episode 1. Open health forum",
			totalViews: "759 views",
			date: "Jul 18, 2020",
			video: "lhpMDWxdJzU",
			live: true
			},{
			id: 11,
			video: "qTkJm71VnnA",
			title: "Episode 2. Open health forum",
			totalViews: "818 views", 
			date: "Jul 26, 2020",
			live: true
			},{
			id: 12,
			video: "YvzDY4Zn_d0",
			title: "Episode 3. Open health forum",
			totalViews: "343 views", 
			date: "Aug 2, 2020",
			live: true
			},{
			id: 13,
			video: "scNudrv7-4U",
			title: "Episode 4: Open Health Forum",
			totalViews: "441 views", 
			date: "Aug 8, 2020",
			live: true
			},{
			id: 14,
			video: "cCQJRcZ8DU8",
			title: "Episode 5: Open Health Forum",
			totalViews: "478 views", 
			date: "Aug 23, 2020",
			live: true
			},{
			id: 15,
			video: "e8Opwn46Lts",
			title: "Episode 6: Open Health Forum",
			totalViews: "511 views", 
			date: "Aug 30, 2020",
			live: true
			},{
			id: 16,
			video: "TGna1cIQR0E",
			title: "Episode 7: Open Health Forum",
			totalViews: "433 views", 
			date: "Sep 6, 2020",
			live: true
			},{
			id: 17,
			video: "1YcVJ4UU1Lw",
			title: "Episode 8: Open Health Forum",
			totalViews: "220 views", 
			date: "Sep 20, 2020",
			live: true
			},{
			id: 18,
			video: "VjDybfg7_r0",
			title: "Episode 9: Open Health Forum",
			totalViews: "521 views", 
			date: "Sep 27, 2020",
			live: true
			},{
			id: 19,
			video: "DfARMpPevlg",
			title: "Episode 10: Open Health Forum",
			totalViews: "278 views", 
			date: "Oct 4, 2020",
			live: true
		},
		{
			id: 20,
			title: "Myths and Rumours: Episode 1",
			totalViews: "474 views",
			date: "Oct 18, 2020",
			video: "V-njwW0F8Cc",
			live: true
		},
		{
			id: 21,
			title: "Myths and Rumours: Episode 2",
			totalViews: "410 views",
			date: "Oct 25, 2020",
			video: "FZmxCpWI10E",
			live: true
		},
		{
			id: 22,
			title: "Myths and Rumours: Episode 3",
			totalViews: "302 views",
			date: "Nov 01, 2020",
			video: "Fb42qpM02HM",
			live: true
		},
		{
			id: 23,
			title: "Myths and Rumours: Episode 4",
			totalViews: "118 views",
			date: "Nov 08, 2020",
			video: "a-M3hA2MvfY",
			live: true
		},
		{
			id: 24,
			title: "Myths and Rumours: Episode 5",
			totalViews: "215 views",
			date: "Nov 29, 2020",
			video: "ui_UipiFi9o",
			live: true
		},
		{
			id: 25,
			title: "Myths and Rumours: Episode 6",
			totalViews: "262 views",
			date: "Dec 06, 2020",
			video: "EAF9fCMdxL8",
			live: true
		},
		{
			id: 26,
			title: "Myths and Rumours: Episode 7",
			totalViews: "479 views",
			date: "Dec 13, 2020",
			video: "jlQPWZoyLVQ",
			live: true
		},
		{
			id: 27,
			title: "What is Vaginismus",
			totalViews: "912 views",
			date: "Nov 26, 2020",
			video: "bT2PZ6q-n_o",
			live: false
		},
		{
			id: 28,
			title: "Vaginismus Treatment and Methods",
			totalViews: "2934 views",
			date: "Nov 30, 2020",
			video: "yeYgdMIz_WQ",
			live: false
		},
		{
			id: 29,
			title: "5 Tips for Improving Sleep",
			totalViews: "1045 views",
			date: "Nov 19, 2020",
			video: "kMpAcuq_QqM",
			live: false
		},
		{
			id: 30,
			title: "7 Tips for Mental Wellbeing in this Pandemic",
			totalViews: "145 views",
			date: "Nov 08, 2020",
			video: "bESF2xBK-wI",
			live: false
		},
	];

	return (
		<StyledYoutube>
			<Breadcrumb
				backRoute={[{id: 1, route: 'Home'}]}
				currentRoute='Youtube'
				title='Youtube listing' />
			
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						{
							videos.map(item => {
								return <div className="col-md-4">
												<StyledBlogCard 
													title={item.title}
													// doctorName="Dr Subani Maheshwari"
													date={item.date}
													totalViews={item.totalViews}
													yotube={true}
													video={item.live ? `https://www.youtube-nocookie.com/embed/${item.video}?controls=0` : `https://www.youtube.com/embed/${item.video}?rel=0&controls=0&showinfo=0`}
												/>
											</div>
							})
						}
						
					</div>
				</div>
			</div>
		</StyledYoutube>
	)
}

export default Youtube

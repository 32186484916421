import React, { useMemo } from "react";
import { Box } from "@mui/system";
import { Grid, CircularProgress } from "@mui/material";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { CircularProgressContainer } from "../../assets/styles/CommonStyles";
import colors from "../../constant/colors";

const StyledSlotsGrid = styled(Grid)(() => ({
  maxHeight: "450px",
 // marginTop: "24px",
  overflow: 'scroll',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  '& .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    background: 'green'
  },
  "& .MuiGrid-container": {
    width: "100%",
    marginLeft: 0,
    marginTop: 0,

    "& .MuiGrid-item": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background:
        colors.lightBackground,
      margin: "4px 8px",
      borderRadius: "28px",
      width: '180px',  
      height: '36px',   
      "& .MuiBox-root": {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "16px",
        background: colors.textColor,
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
    "& .selected": {
      cursor: "pointer",
      background: colors.primary,
      '& span': {
        background: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "white",
        backgroundClip: "text",
        textFillColor: "transparent",
      }
    },
  },
}));

export const TextStyled = styled.div`
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #5e5e5e;
`;

function Slot({ list, selectedItems, setSelectedItems }) {

  const data = useMemo(() => list, [list])
  const { loading } = useSelector((state) => ({
    loading: state.schedule.loading
  }));

  const handleSelectedSlots = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  }

  return (
    <>
      {/* <TextStyled style={{ marginTop: "24px" }}>{name}</TextStyled> */}
      <StyledSlotsGrid>
        <Grid container>
          {loading ?
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
            : data?.length > 0 ?
              data.map((slotItem, index) => {
                return <Grid item onClick={() => handleSelectedSlots(slotItem.time_enum)} className={!!selectedItems && selectedItems.includes(slotItem.time_enum) ? 'selected' : ''}>
                  <Box style={{ cursor: "pointer" }} >
                    <span>{moment(slotItem.start_time, 'HH:mm:ss').format('h:mm A')} - {moment(slotItem.end_time, 'HH:mm:ss').add(1, 'seconds').format('h:mm A')}</span>
                  </Box>
                </Grid>
              }) :
              <CircularProgressContainer>No slots left</CircularProgressContainer>}
        </Grid>
      </StyledSlotsGrid>
    </>
  );
}

export default React.memo(Slot);

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import CardComponent from '../../../../../components/Card';
import InputField from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';
import DatePicker from 'react-date-picker';
import "react-datepicker/dist/react-datepicker.css";
import './index.css'
import { toast } from 'react-toastify';
import { globalValidator } from "../../../../../utils";

import { Context } from '../../../../../store';

import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_WARD_PATIENT_DETAILS
} from '../../../../../store/types';

import {
	addPatient, getPatientInfo, getWardRoomPatients
} from '../../../../../api/organization';

import {
	StyledContainer,
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledAddEditPatient
} from './styles';

import InsideMenuBar from '../../../../../components/InsideMenus';
import PhoneInput from 'react-phone-number-input';

function SubAdminAddPatient(props) {
	const [state, dispatch] = useContext(Context);
	const [patientIdE, setPatientIdE] = React.useState(false);
	const [patientId, setPatientId] = React.useState('');
	const [wardRoomPatients, setWardRoomPatients] = React.useState([]);
	const pathname = window.location.pathname;
	const [patient, setPatient] = useState({ name: '', email: '', phoneNumber: '', city: '', dob: '', occupation: '', bloodGroup: '', address: '',gender: 'male' });
	const [id, setId] = useState(null);

	React.useEffect(() => {
		if(props.match.params && props.match.params.id){
			setPatientIdE(true);
			setPatientId(props.match.params.id)
		}
	},[props.match.params])


	useEffect(() => {
		// getWardRoomPatients();
		getWardRoomPatients().then(res => {
			let filterData = (res.data && res.data.length) ? res.data.filter((item) => !item.dischargeByDoctor || !item.dischargeByFinancial) : []
			setWardRoomPatients(filterData);
			loader(false)
		}).catch(err => {
			loader(false)
			toast.error(err?.response?.data?.error);
		})
	}, [])
	


	const submitPatient = (e) => {
		e.preventDefault();
		const currentPatient = wardRoomPatients.filter((item) => item.patient._id == patientId )
		if(currentPatient?.length) {
			toast.error("Patient already exists")
			dispatch({
				type: SET_ERROR,
				payload: 'Patient already exists'
			});
			return false
		}
		if(patientIdE) {
			const keys = [
				{ name: "patientId", title: "Patient's Id", validateKey: "required" },
			];
			let valid = globalValidator({patientId}, keys);
			// "61c946bd86385f7537abb8f8"
			
			if (valid === true) {
				getPatientInfo(patientId).then(res=> {
					loader(false);
					// toast.success(res.message)
					dispatch({
						type: SET_ERROR,
						payload: res.message
					});

					dispatch({
						type: SET_WARD_PATIENT_DETAILS,
						payload: {...res.data,patientIdE:patientIdE}
					});
					props.history.push(`/subuser/ipdward/add-patient/form-details/${patientId}`);
				}).catch(err => {
					loader(false);
					toast.error(err.response.data.error)
					dispatch({
						type: SET_ERROR,
						payload: err.response.data.error
					});
				})
			}

			else {
				toast.error(valid)
				dispatch({
					type: SET_ERROR,
					payload: valid
				});
			}
		} else {
			const keys = [
				{ name: "name", title: "Name", validateKey: "required" },
				{ name: `${patient.email !== '' ? 'email' : 'phoneNumber'}`, title: `${patient.email !== '' ? 'Email' : 'Email Or Phone Number '}`, validateKey: `${patient.email !== '' ? 'email' : 'required'}`},
			];
			let valid = globalValidator(patient, keys);
			if (valid === true) {
			
			let data = {
				name: patient.name, 
				email: patient.email,
				phoneNumber: patient.phoneNumber, 
				bloodGroup: patient.bloodGroup, 
				address: patient.address,
				password: 'Emed4u@12345#!',
				verifyed: true,
				dob: patient.dob,
				gender: patient.gender,
				occupation: patient.occupation,
				city: patient.city
			}

			addPatient(data).then(res => {
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});

				dispatch({
					type: SET_WARD_PATIENT_DETAILS,
					payload: {...patient,patientIdE:patientIdE}
				});
				props.history.push(`/subuser/ipdward/add-patient/form-details/${res.data._id}`);
				
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})

		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
		}
			
		
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<StyledContainer>
			{patientIdE && <InsideMenuBar 
				props={props} 
				type={'subadmin'}
				/>}
			{/* className="content" */}
			{!patientIdE && <div className="d-flex container-fluid">
				<div className="d-flex mb-4">
					<StyledBackTo>
						<Link to={'/subuser/ipdward'}>
							<i className="fa fa-arrow-left" />
							Back to listing page
						</Link>
					</StyledBackTo>
				</div>
				<div className="col-md-2 ml-auto">
					<button className="doctor-active-btn doctor-w-100" onClick={(e) => submitPatient(e)} >Add Patient</button>
				</div>
			</div>}
			<div> 
				<div className="container-fluid mt-4">
					<div className="row">

						<div className="col-md-12">
							<CardComponent className="p-4">
								<StyledHeader>
									<StyledTitle>
										Add a patient in IPD
									</StyledTitle>
								</StyledHeader>

								{pathname == `/subuser/ipdward/add-patient/${props.match.params.id}` ? null : <div class="form-group col-md-7 cust-do-em row ml-2">
									<input type="checkbox" id="html" checked={patientIdE} value={patientIdE} onChange={(e) => setPatientIdE(e.target.checked)} />
									<label for="html">Do you have patient Id ?</label>
								</div>}

								{patientIdE && <div className="row pt-3">
									<div className="col-md-12 no-padding form-group ">
										<label>Patient's Id</label>
										<InputField value={patientId} onChange={e => 
											setPatientId(e.target.value)} />
											
									</div>
								</div>}


								{!patientIdE &&<> <div className="row pt-3">
									
									<div className="col-md-6 no-padding form-group ">
										<label>Name</label>
										<InputField value={patient.name} onChange={e => setPatient((patient) => ({ ...patient, name: e.target.value }))} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Email</label>
										<InputField value={patient.email} onChange={e => setPatient((patient) => ({ ...patient, email: e.target.value }))} />
									</div>
								</div>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Gender</label>
										<SelectField value={patient.gender} onChange={e => setPatient((patient) => ({...patient, gender: e.target.value}))} options={[{id: 1, label: 'Male', value: 'male'}, {id: 2, label: 'Female', value: 'female'}, {id: 3, label: 'Others', value: 'other'}]} />
									</div>

									<div className="col-md-6 no-padding form-group" style={{display:'flex', flexDirection:'column'}}>
										<label>Date of birth</label>
										<DatePicker value={patient.dob} className="ipdwardDatePicker" selected={patient.dob} onChange={date => setPatient((patient) => ({...patient, dob: date}))} />
									</div>
								
								</div>

								<div className="row pt-3">
									<div className="col-md-12 no-padding form-group ">
										<label>Address</label>
										<InputField value={patient.address} onChange={e => setPatient((patient) => ({ ...patient, address: e.target.value }))} />
									</div>
								</div>

								<div className="row pt-3">

									<div className="col-md-6 no-padding form-group ">
										<label>Contact number</label>
										<PhoneInput
											placeholder="Enter phone number"
											international
											defaultCountry="IN"
											value={patient.phoneNumber}
											onChange={e => setPatient((patient) => ({ ...patient, phoneNumber: e }))}
										/>
									</div>
									<div className="col-md-6 no-padding form-group ">
										<label>City</label>
										<InputField value={patient.city} onChange={e => setPatient((patient) => ({ ...patient, city: e.target.value }))} />
									</div>
								</div>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Blood group</label>
										<SelectField value={patient.bloodGroup} onChange={e => setPatient((patient) => ({ ...patient, bloodGroup: e.target.value }))} options={[{ id: 1, label: 'A-', value: 'A-' }, { id: 2, label: 'A+', value: 'A+' },
										{ id: 3, label: 'B-', value: 'B-' }, { id: 6, label: 'B+', value: 'B+' },
										{ id: 4, label: 'AB-', value: 'AB-' }, { id: 7, label: 'AB+', value: 'AB+' },
										{ id: 5, label: 'O-', value: 'O-' }, { id: 8, label: 'O+', value: 'O+' }]} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Occupation</label>
										<InputField value={patient.occupation} onChange={e => setPatient((patient) => ({ ...patient, occupation: e.target.value }))} />
									</div>

								</div></>}

								<StyledHeader>
									{patientIdE ?
										<StyledAddEditPatient onClick={(e) => submitPatient(e)}>
											Next
										</StyledAddEditPatient>
										: null
										// <StyledAddEditPatient onClick={(e) => submitPatient(e)}>
										// 	Add Patient
										// </StyledAddEditPatient>
									}
								</StyledHeader>
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default SubAdminAddPatient

import React, { useEffect, useState } from "react";
import { Grid, IconButton, Button } from "@mui/material";
import TabComponent from "../../../Tab";
import { appointmentTabList } from "../../../../constant/staticLists";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import {
  getDoctorAppointmentAction,
  getPatientDetailsForDoctorAction,
  getPatientPreviousAppointmentForDoctorAction,
} from "../../../../redux/appointment/doctor/doctorAppointmentActions";
import PatientInformation from "../../Doctor/Appointments/PatientInformation";
import PatientDashboard from "../../Patient/PatientDashboard";
import PersonalMedicalDetails from "../../PersonalMedicalDetails";
import MedicalReports from "../../MedicalReports";
import EPriscriptions from "../../EPriscriptions";
import PreviousAppointments from "../../Doctor/Appointments/PreviousAppointments";
import AppointmentBookingSummary from "./AppointmentBookingSummary";
import { useDispatch, useSelector } from "react-redux";
import {
  getMedicalHistoryAction,
  getPatientEprescriptionAction,
  getPatientMedicalReportAction,
} from "../../../../redux/user/userActions";
import moment from "moment";
import strings from "../../../../constant/strings";
import { dashoboardUseStyles } from "../../../../assets/styles/DoctorDashboardStyles";

function AppointmentDetails({
  step,
  handleForward,
  patientID,
  handleBackward,
  selectedListItem
}) {
  const styleClasses = commonMuiStyles();
  const classes = dashoboardUseStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);

  const { userData, patientInfo } = useSelector((state) => ({
    userData: state.auth.userData,
    patientInfo : state.doctorAppointment.patientInfo
  }));

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const value = {
        token: userData.token,
        patientId: patientID,
        date: todayDate,
      };

      const getValue = () => {
        dispatch(getPatientEprescriptionAction(value))
        dispatch(getPatientMedicalReportAction(value))
      }

    if (newValue !== 1 && newValue !== 0) {
          newValue === 2 ? dispatch(getMedicalHistoryAction(value)) :
            newValue === 3 ? getValue() :
              dispatch(getPatientPreviousAppointmentForDoctorAction(value))
      }
  };

  return (
    <>
      <Grid container mt={2} spacing={2}>
        <Grid item xs={12}>
        <Button className={`${classes.fontStyle400} ${classes.textColor}`} onClick={handleBackward}>{strings.backToAppointment}</Button>
        </Grid>
        <Grid item={12}>
          <TabComponent
            tabs={appointmentTabList}
            tabValue={tabValue}
            setTabValue={setTabValue}
            handleTab={handleTab}
            customTab={styleClasses.customTab}
            customTabIndicator={styleClasses.customTabIndicator}
          />
        </Grid>
        <Grid item xs={12}>
          { tabValue === 0 ? <PatientInformation item={selectedListItem} /> :
                    tabValue === 1 ? <PatientDashboard  item={selectedListItem} patientID={patientID} /> : 
                    tabValue === 2 ? <PersonalMedicalDetails navigate="doctor" item={selectedListItem} patientID={patientID} /> : 
                    tabValue === 3 ?  <> <MedicalReports navigate="doctor" /> 
                         <EPriscriptions navigate="doctor" /></> : 
                          <PreviousAppointments />                         
                         }       
        </Grid>
      </Grid>
    </>
  );

}

export default AppointmentDetails;

import React, {useContext,useState, useEffect} from 'react';
import LineChartComponent from '../../../../components/Charts/LineChart'
import { determineSubAdminLabel, renderData, getHealthStatus,getHealthInfoDetails, getBpHealthStatus,getAllType } from '../../../../graphUtils';
import moment from 'moment';
import CardComponent from '../../../../components/Card';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getSubBPAPIByFilter,
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import GraphDetailsModal from '../../../../components/Charts/GraphDetailsModal';
import LipidChart from '../../../../components/LipidChart';

const HealthRecordsChart = (props) => {
	const [state, dispatch] = useContext(Context);
	const { patientId, showAdd, showLipidProfile, hideHealthReport,hideBp,hidePulse, hidetemp, hideBs, hideRr, hideOxygen} = props
	const [getBPData, setGetBPData] = React.useState(false);
    const [lineData, setBpLineData] = useState([]);
    const [filter, setFilter] = useState('Today');
    const [resRateLineData, setResRateLineData] = useState([]);
    const [heartRateLineData, setHeartRateLineData] = useState([]);
    const [spo2LineData, setSpo2LineData] = useState([]);
    const [tempLineData, setTempLineData] = useState([]);
    const [bloodSugarLineData, setBloodSugarLineData] = useState([])
	const [showDetailsModal, setShowDetailsModal] = React.useState(false);
	const [allData, setData] = React.useState([]);
	const [paginateData, setPaginateData] = React.useState([]);
	const [allSearchData, setAllSearchData] = React.useState([]);
	const [currentItem, setCurrentItem] = React.useState('');
	const [renderInfo,setrenderInfo] = React.useState({})
	const [searchDate, setSearchDate] = React.useState('');
	const [copyData, setCopyData] = React.useState([]);
	const [groupName, setGroupName] = React.useState(undefined);
	const [showModal, setShowModal] = React.useState(false);
	const [choleSterolData,setCholeSterolData] = React.useState([]);
	const [currentFilter, setCurrentFilter] = useState(1);

	const findAvg = (data) => {
		let avg = 0;
		if(data.length > 0){
		//  avg = data.reduce((x, y) => (x ? parseInt(x) : 0) + (y ? parseInt(y) : 0), 0) / data.length;
			avg = data[0]
		}
		return avg;
	}

	let Graphdata = [
		{
			id: 1,
			title: 'Blood pressure',
			heading: 'Blood pressure',
			sysValue: findAvg(lineData.map((item)=>item.sys_blood_pressure)),
			lineData: lineData,
			diaValue: findAvg(lineData.map((item)=>item.dia_blood_pressure)),
			name: 'all_bp_detail',
			color: {
				borderSysColor: '#cc0000',
				fillSysColor:'#db5454',
				borderDiaColor:'#013b61',
				fillDiaColor:'#6181ca'
			},
			type:"area",
			healthInfo : getHealthInfoDetails("Blood pressure")
		},
		{
			id: 2,
			title: 'Pulse',
			heading: 'Heart rate',
			value: findAvg(heartRateLineData.map((item) => item.pluse)),
			lineData: heartRateLineData,
			name: 'all_Pluse_detail',
			color: {
				borderColor:'#cc0000',
				fillColor:'#db5454'
			},
			type:"area",
			healthInfo : getHealthInfoDetails("Pulse")
		},
		{
			id: 3,
			title: 'SPO2',
			heading: 'Oxygen saturation',
			value: findAvg(spo2LineData.map((item) => item.level)),
			lineData: spo2LineData,
			name: 'all_OxygenLevel_detail',
			color: {
				borderColor:'#013b61',
				fillColor:'#6181ca'
			},
			type:"area",
			healthInfo : getHealthInfoDetails("SPO2")
		},
		{
			id: 4,
			title: 'Blood Sugar',
			heading: 'Blood sugar',
			value: findAvg(bloodSugarLineData.map((item) => item.how_much)),
			lineData: bloodSugarLineData,
			name: 'all_Bsugar_detail',
			color: {
				borderColor:'#f50a60',
				fillColor:'#fb9dbf'
			},
			type:"area",
			healthInfo : getHealthInfoDetails("Blood Sugar")
		},
		{
			id: 5,
			title: 'Temperature',
			heading: 'Temperature',
			value: findAvg(tempLineData.map((item) => item.temperature)),
			lineData: tempLineData,
			name: 'all_Temperature_detail',
			color: {
				borderColor:'#008ffb',
				fillColor:'#d5eaff'
			},
			type:"bar",
			healthInfo : getHealthInfoDetails("Temperature")
		},
		{
			id: 6,
			title: 'Respiratory Rate',
			heading: 'Respiratory rate',
			value: findAvg(resRateLineData.map((item) => item.respiratory_rates)),
			lineData: resRateLineData,
			name: 'all_RespiratoryRates_detail',
			color: {
				borderColor:'#5156be',
				fillColor:'#6c70e0'
			},
			type:"bar",
			healthInfo : getHealthInfoDetails("Respiratory Rate")
		},
	]

    useEffect(() => {
			loader(true);
        if(!hideBp){
			getBP("all_bp_detail", 'Today');
        }
        if(!hidePulse){
            getBP("all_Pluse_detail", 'Today')
        }
        if(!hidetemp){
			getBP("all_Temperature_detail", 'Today')
        }
        if(!hideBs){
            getBP("all_Bsugar_detail", 'Today')
        }
        if(!hideRr){
            getBP("all_RespiratoryRates_detail", 'Today')
        }
        if(!hideOxygen){
            getBP("all_OxygenLevel_detail", 'Today')
        }

		if (showLipidProfile) {
			getBP("all_Cholesterol_detail");
		}
		
    }, [patientId])

	const lipidsAndSymtomsData = [
		{
			id : 1,
			title: 'Lipid Profile',
			heading: 'Lipid profile',
			name : 'all_Cholesterol_detail',
			lineData: choleSterolData,
		},
		// {
		// 	id : 2,
		// 	title: 'Joint Movements',
		// 	heading: 'Joint movements',
		// 	name : 'all_Cholesterol',
		// 	lineData: choleSterolData,
		// },
		// {
		// 	id : 3,
		// 	title: 'Pain level',
		// 	heading: 'Pain level',
		// 	name : 'all_Cholesterol',
		// 	lineData: choleSterolData,
		// },
	]


    const getBP = (healthType,filter) => {
		!healthType && localStorage.setItem('Health_Parameter', 'all_Bp')
		const type = healthType ? healthType : 'all_Bp';
		let responseData = []
		loader(true);
		let today = new Date();
		let query = {};
		if (filter === 'Today') {
			query = {
				start: new Date(today.setHours(0, 0, 0, 0)),
				end: new Date(today.setHours(23, 59, 59, 999))
			}
		} else {
			const startDate = new Date(today - 1000 * 60 * 60 * 24 * 6);
			query = {
				start: new Date(startDate.setHours(0, 0, 0, 0)),
				end: new Date(today.setHours(23, 59, 59, 999))
			}
		}
		getSubBPAPIByFilter(type, patientId,query).then(res => {
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});

			switch (type) {
				case 'all_bp_detail':
					responseData = res?.data;
					break;
				default:
					responseData = res?.data
					break;
			}

			if (responseData?.length) {

				let filteredData = [];
				if (filter === "This Week") {
					filteredData = responseData.filter(item => moment(new Date()).diff(moment(item.createdAt), 'day') <= moment(new Date()).format("d"));
				}
				else {
					filteredData = responseData.filter(item => moment(new Date()).diff(moment(new Date(item.createdAt)), 'hours') < new Date().getHours());
				}
				let filteredDataReverse = filteredData.length > 0 ? filteredData.reverse() : [];
				if (type === 'all_bp_detail') {
					setBpLineData(filteredDataReverse);
				}
				if (type === 'all_RespiratoryRates_detail') {
					setResRateLineData(filteredDataReverse);
				}
				if (type === 'all_Temperature_detail') {
					setTempLineData(filteredDataReverse);
				}
				if (type === 'all_OxygenLevel_detail') {
					setSpo2LineData(filteredDataReverse);
				}
				if (type === 'all_Pluse_detail') {
					setHeartRateLineData(filteredDataReverse);
				}
				if (type === 'all_Bsugar_detail') {
					setBloodSugarLineData(filteredDataReverse);
				}
				if (type === 'all_Cholesterol_detail') {
					setCholeSterolData(responseData.length ? responseData.reverse() : [])
				}
			}
			loader(false);
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const getFullBP = (healthType, pastdays) => {
		const type = healthType;
		let responseData = []
		loader(true);
		setGetBPData(false);
		let query = {}
		let today = new Date()
		const pastDay = () => {
			if(pastdays == 30 || pastdays == 365){
				return pastdays
			} else {
				return pastdays - 1 
			}
		}
		if(pastdays){
			const startDate = new Date(today - 1000 * 60 * 60 * 24 * pastDay());
			query = {
				start : new Date(startDate.setHours(0,0,0,0)),
				end : new Date(today.setHours(23,59,59,999))
			}
		}

		getSubBPAPIByFilter(type, patientId,query).then(res => {
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});

			switch (type) {
				case 'all_bp_detail':
					responseData = res?.data;
					break;
				default:
					responseData = res?.data;
					break;
			}

			if (responseData?.length) {
				setCopyData(responseData)
				setData(responseData);
				setPaginateData(responseData.slice(0, 10))
				setGetBPData(true);
			} else {
				setCopyData([])
				setData([]);
				setPaginateData([])
				setGetBPData(true);
			}
			loader(false);
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}


    const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	const renderFullInfo = renderData(currentItem.title); 


	const onChangeDate = async (value) => {
		setSearchDate(value)
		const sortData = copyData.filter((item) => moment(item.createdAt).format('YYYY-MM-DD') === value);
		setAllSearchData(sortData?.length > 10 ? sortData.slice(0, 10) : sortData);
		setData(sortData);
	}

	const handleAddIconClick = (title) => {
		const data = renderData(title); 
		setrenderInfo(data)
		document.body.style.overflowY='hidden'
		setShowModal(true);
	}

	const onReset = () => {
		setSearchDate('');
		getFullBP(currentItem.name,1);
		setCurrentFilter(1);
		setGroupName('');
	}

	const handleClearValue = () => {
		setCurrentItem('');
		setCurrentFilter(1);
	};

	const applyFilter = (days) => {
		getFullBP(currentItem.name,days);
		setCurrentFilter(days);
	}

	const handleShowDetailsModal = (item) => {
		getFullBP(item.name,1);
		document.body.style.overflowY = 'hidden';
		setShowDetailsModal(true);
		setCurrentItem(item)
	}



	if(hideBp){
		let d = Graphdata.findIndex((item) => item.name === 'all_bp_detail');
		Graphdata.splice(d,d+1);
	}
	if(hidePulse){
		let d = Graphdata.findIndex((item) => item.name === 'all_Pluse_detail');
		Graphdata.splice(d,d+1);
	}
	if(hidetemp){
		let d = Graphdata.findIndex((item) => item.name === 'all_Temperature_detail');
		Graphdata.splice(d,d+1);
	}
	if(hideBs){
		let d = Graphdata.findIndex((item) => item.name === 'all_Bsugar_detail');
		Graphdata.splice(d,d+1);
	}
	if(hideRr){
		let d = Graphdata.findIndex((item) => item.name === 'all_RespiratoryRates_detail');
		Graphdata.splice(d,d+1);
	}
	if(hideOxygen){
		let d = Graphdata.findIndex((item) => item.name === 'all_OxygenLevel_detail');
		Graphdata.splice(d,d+1);
	}

    const deleteItem = (id, name) => {
		// loader(true);
		// deleteBp(id, name).then(res => {
		// 	loader(false);
		// 	toast.success(res.message);
		// 	dispatch({
		// 		type: SET_ERROR,
		// 		payload: res.message
		// 	});
		// 	getBP(currentItem.name)
		// 	getFullBP(currentItem.name);
		// }).catch(err => {
		// 	loader(false);
		// 	toast.error(err.response.data.error)
		// 	dispatch({
		// 		type: SET_ERROR,
		// 		payload: err.response.data.error
		// 	});
		// })
	}

    return (
		<>
			{!hideHealthReport && <>
            {/* <div className="col-md-12 d-flex mb-2 health-records-main-heading"> */}
				{/* <h2>Health parameters</h2>
				<div className="d-flex ml-auto health-records-heading">
					<div className={`btn btn-primary submit-btn ml-auto filter-emed4u-sml-btn ${filter === "Today" ? '' : 'activebutton'}`} style={{ marginRight: "1.25rem", backgroundColor: "#5156be", border: "1px solid #5156be" }} onClick={() => { setFilter('Today'); handleChangeFilter('Today') }}>Today</div>
					<div className={`btn btn-primary submit-btn ml-auto filter-emed4u-sml-btn ${filter === "This Week" ? '' : 'activebutton'}`} style={{ marginRight: "0.25rem", backgroundColor: "#5156be", border: "1px solid #5156be" }} onClick={() => { setFilter('This Week'); handleChangeFilter('This Week') }}>Week</div>
					{Graphdata && Graphdata.length <= 3 ? null :
					<>
					<i style={{ marginLeft: "20px", marginRight: "5px" }} onClick={executePreviousScroll} class="fas fa-chevron-circle-left scroll-icon"></i>
					<i style={{ marginLeft: "5px" }} onClick={executeNextScroll} class="fas fa-chevron-circle-right scroll-icon"></i>
					</>
					}
				</div> */}
			{/* </div> */}
				{/* <div className="col-md-12 d-flex row flex-nowrap overflow-x-scroll emed4u-patient-health-graph"> */}
					{Graphdata && Graphdata.map((item, id) =>
						<div key={id} className={"col-md-3 postion-relative"}>
							<CardComponent className="mb-4 health-chart-card">
								<h5>{item.heading}</h5>
								{showAdd ? <i class="fas fa-plus add-details-icon" onClick={() => handleAddIconClick(item.title)}></i> : null}
								<div className='d-flex justify-content-between' style={{ padding: "10px 20px 0px 20px" }}>
									{
										item.title === 'Blood pressure' ?
											<h6>{parseInt(item.sysValue)}/{parseInt(item.diaValue)}</h6>
											:
											<h6>{parseInt(item.value)}</h6>
									}
									{
										<h6>
											{
												item.title === 'Blood pressure' ?
													getBpHealthStatus(item.sysValue, item.diaValue)
													:
													getHealthStatus(item.title, item.value)
											}
											<i style={{ color: "#5156be" }} type="button" class="fa fa-info-circle ml-1" aria-hidden="true" data-toggle="tooltip" data-placement="top"
												title={`${item.healthInfo.Normal}
											${item.healthInfo.High}
											${item.healthInfo.AtRisk}`
												}
											>
												{/* <i class="fa fa-info-circle" aria-hidden="true"></i> */}
											</i>
										</h6>
									}
								</div>
								<div className="col-md-12 mt-2" style={{ minHeight: "200px" }}>
									{
										item.title === 'Blood pressure' ?
											<LineChartComponent key={`garph-${item.id}`} keyItem={item.id} Linedata={item.lineData} type={item.type} color={item.color} label={item.title} showfilter={false} doubleLineChart height={250} /> :
											<LineChartComponent key={`garph-${item.id}`} keyItem={item.id} Linedata={item.lineData} type={item.type} color={item.color} label={item.title} showfilter={false} determineLabel={determineSubAdminLabel(item.name)} height={250} />
									}
								</div>
								<div className='graph-div-button col-md-12 d-flex justify-content-center'>
									<div className="btn btn-primary submit-btn p-2 patient-emed4u-sml-btn" style={{ fontSize: '.78rem' }} onClick={() => handleShowDetailsModal(item)}> View full graph</div>
								</div>
							</CardComponent>
						</div>
					)}
				{/* </div> */}
			</>
			}
			{
				showLipidProfile ?
					// <div className={"col-md-12 col-xs-12 col-lg-12 mt-3 postion-relative d-flex padding-0"}>
						// <div className="col-md-12 col-xs-12  p-0 col-xl-12 col-lg-12 mt-4 lipid-profile-div">
							// <div className="col-md-12 d-flex" style={{ padding: "0px", display: "flex !important" }}>
							// 	<h4 style={{ marginLeft: "17px" }}>Lipid Profile</h4>
							// 	<div className="col-md d-flex" style={{ justifyContent: "flex-end", padding: "0px", display: "flex !important" }}>
							// 		{/* <i class="fa fa-plus-circle scroll-icon" onClick={() => handleAddIconClick('Lipid Profile')} aria-hidden="true"></i> */}
							// 		<i style={{ marginLeft: "20px", marginRight: "5px" }} onClick={prevScroll} class="fas fa-chevron-circle-left scroll-icon"></i>
							// 		<i style={{ marginLeft: "5px" }} onClick={nextScroll} class="fas fa-chevron-circle-right scroll-icon"></i>
							// 	</div>
							// </div>
							// <div className="col-md-12 p-0 pt-4 d-flex row flex-nowrap overflow-x-scroll-symtoms emed4u-patient-health-graph">
								lipidsAndSymtomsData && lipidsAndSymtomsData.map((item, id) =>
									<LipidChart minHeight={'340px'} item={item} className={"col-md-3 postion-relative"} />
								)
						// 	</div>
						// </div>
					// </div> 
                    : null
			}
			{showDetailsModal &&
					<GraphDetailsModal
						showDetailsModal={showDetailsModal}
						paginateData={paginateData}
						allSearchData={allSearchData}
						currentItem={currentItem}
						setShowDetailsModal={setShowDetailsModal}
						renderFullInfo={renderFullInfo}
						allData={allData}
						onChangeDate={onChangeDate}
						searchDate={searchDate}
						setSearchDate={setSearchDate}
						onReset={onReset}
						deleteItem={deleteItem}
						setPaginateData={setPaginateData}
						subAdmin = {true}
						currentFilter={currentFilter}
						applyFilter={applyFilter}
						handleClearValue={handleClearValue}
					/>
				}
		</>
    );
}
export default HealthRecordsChart
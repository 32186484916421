import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import { StyledHomeSlider } from './styles';
import { useMediaQuery } from 'react-responsive'
import MobileAboutPage from '../MobileAboutPage';
import AboutPage from '../AboutPage';
import History from './History';
import { isMobile } from "react-device-detect";

function About() {

     const executeScroll = () => {
      document.querySelector('body').scrollTo({
          // top: isMobile ? 3100 : 1600, 
          top: document.getElementById("scrollable-custom").offsetTop,
          behavior: 'smooth'
        })
     }

  return (
    <StyledHomeSlider>
        <div style={{justifyContent: 'end'}} className='col-12 d-flex bd-home-slider bg-img-work-001'>

          <div className='click-able' onClick={() => executeScroll()}/>
          {/*<div className="slide-image col-12 col-lg-8">
            <h2 className="mb-3" style={{ textAlign: 'left' }}>
              Integrated system entwined with Rich Data, Patient Management, Intuitive Analytics, Lead Tracking, and Patient Monitoring
            </h2>
            <div className="d-inline-block img-dsk-sh-book" style={{ textAlign: 'left' }}>
              <Link to className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</Link>
            </div>
          </div>
          <div className={`col-12 col-lg-6 d-flex img-dsk-sh bg-home-mobile-001`}>
            <div className="d-inline-block img-dsk-sh-book-01">
              <Link to className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</Link>
            </div>
  </div>*/}
        </div>
        {/*Working on Our History page*/}
        {/* <div className='col-12 d-flex bd-home-slider bg-img-wearables'>
          <h1 className='emed4u-Wearables'>Our <span className='emed4u-Wearables-title'>History</span></h1>
          <div class="h-steps-bar mobile-screen-desktop">
            <ul class="h-steps-indicator">
              <li class="h-first">
              <div>
                <a className='h-2018' href="">2018</a>
                <a href="">The Start</a>
              </div>
              </li>
              <li class="h-second">
              <div>
                <a className='h-member h-2019' href="">2019</a>
                <a className='h-member' href="">Member</a>
              </div>
              </li>
              <li class="">
              <div>
                <a className='h-adding h-2020' href="">2020</a>
                <a className='h-adding' href="">Adding</a>
              </div>
              </li>
              <li class="h-current">
              <div>
                <a className='h-2021' href="">2021</a>
                <a href=""></a>
              </div>
              </li>
              <li class="h-end">
              <div>
                <a className='h-2022 active' href="">2022</a>
                <a href="">Development</a>
              </div>
              </li>
            </ul>
          </div>
          <div class="h-steps-bar mobile-screen-steps">
            <ul class="h-steps-indicator">
              <li class="m-h-mobile-screen-list">
              <div>
                <a className='m-h-2018' href="">2018</a>
                <a href="">The Start</a>
              </div>
              </li>
              <li class="m-h-mobile-screen-list">
              <div>
                <a className='m-h-2019' href="">2019</a>
                <a className='' href="">Member</a>
              </div>
              </li>
              <li class="m-h-mobile-screen-list current-m-screen">
              <div>
                <a className='m-h-2020' href="">2020</a>
                <a className='' href=""></a>
              </div>
              </li>
              <li class="m-h-mobile-screen-list">
              <div>
                <a className='m-h-2021' href="">2021</a>
                <a href="">Adding</a>
              </div>
              </li>
              <li class="m-h-mobile-screen-list">
              <div>
                <a className='m-h-2022' href="">2022</a>
                <a href="">Development</a>
              </div>
              </li>
            </ul>
          </div>
          <div className='emed4u-silder-main'>
              <div className='emed4u-silder'>
                <div className='triangle-top' />
                <div>
                  <img className='mobile-screen-frame' src="/assets/img/Frame57.png" alt='logo' />
                  <div className='padding-emed4u'>
                    <h5 className='team-heading'>Team building</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type .</p>
                  </div>
                </div>
              </div>
              <div className='vector-icon'>
                  <img src="/assets/img/vector-back.png" alt='logo' />
                  <img src="/assets/img/vector-next.png" alt='logo' />
                </div>
          </div>
      </div> */}

        <div className='emed4u-link'>
            <h1 className='heading-link'>Emed4u <span className='heading-link1'>Story</span></h1>
            <p className='about-details'>
              It all began over a cup of coffee when two of our co-founders were discussing the challenges people experience in the healthcare system as patients and doctors. The conversation gave rise to the idea of developing an online platform for patients and doctors to access healthcare innovatively. On this platform, patients can get access to medical consultations and get a second opinion on a diagnosis and course of treatment already prescribed by another physician. Our team of four directors got together and started working on the idea and that's how EMED4U was born.
              <br />
              <br />
              We collaborated with medical professionals across the world to offer patients high-quality consultations. The epidemic began in 2020 and drastically altered the healthcare system. We launched our YouTube channel, organized online events, and webinars to promote healthcare wellness. Around this time, our directors came together and discussed ways we could be more effective. This inspired us to modify in a way that will create a digital healthcare ecosystem. This system's main goal is to provide collaborative multidisciplinary health services. We have developed cutting-edge healthcare management software and integrated it with innovative wearables which will transform the healthcare system by making it more patient-centered. We are constantly evolving our healthcare solutions and expanding our product line so we can better meet the needs of healthcare providers and patients.
            </p>
        </div>

        <div id="scrollable-custom">
          <History />
        </div>

        <div className='main-div'>
          <h1 className='heading-link-two-mobile'>Vision</h1>
          <div>
            <img src="/assets/img/Frame51.png" alt="" />
          </div>
          <div>
            <h1 className='heading-link-two'>Vision</h1>
            <p className='p-detail'>In the coming years, we aim to be the leading healthcare solutions provider. Along with our healthcare management software, we also have a range of wearable gadgets for remote vital sign monitoring. To better serve the needs of key stakeholders in the healthcare industry, we will be expanding our product line in the future.</p>
          </div>
        </div>

        <div className='main-div'>
          <h1 className='heading-link-two-mobile'>Mission</h1>
          <div className='p-mobile-screen'>
            <h1 className='heading-link-two'>Mission</h1>
            <p className='p-detail'>Our mission is to revolutionize the healthcare industry by creating a digital healthcare ecosystem. We want to change the landscape of healthcare by addressing the critical needs of healthcare providers and patients. We will empower individuals, clinics, and hospitals of all sizes by giving them access to innovative and futuristic solutions.</p>
          </div>
          <div>
            <img src="/assets/img/Frame52.png" alt="" />
          </div>
          <div className='p-mobile-disktop'>
            <h1 className='heading-link-two'>Mission</h1>
            <p className='p-detail'>Our mission is to revolutionize the healthcare industry by creating a digital healthcare ecosystem. We want to change the landscape of healthcare by addressing the critical needs of healthcare providers and patients. We will empower individuals, clinics, and hospitals of all sizes by giving them access to innovative and futuristic solutions.</p>
          </div>
        </div>
    </StyledHomeSlider>
  )
}

export default About;

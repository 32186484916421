export const textArray = [
    'Upload logo',
    'Upload medical lisence*',
    'Upload registeration lisence*',
    'Upload address proof*',
    'Upload identity proof',
    'Upload other documents',
    
];

export const PDData = [
    { title: 'IPD', fullName: '(In Patient Department)' },
    { title: 'OPD', fullName: '(Out Patient Department)' }
]

export const ranges = [
    { range: 'Only 1', value: '1' },
    { range: '1- 5', value: '5' },
    { range: '5 - 20', value: '20' },
    { range: '20- 50', value: '50' },
    { range: '50- 100', value: '100' },
    { range: 'Above 100', value: '500' },
]

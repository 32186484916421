import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	SET_COMMON_LOADING,
} from '../../../../store/types';
import moment from 'moment';
import {
	StyledContainer,
} from './styles';
import './index.css';
import CardComponent from '../../../../components/Card';
import TableComponent from '../../../../components/Table';
import { getWardRoomAPI, getWardRoomPatients } from '../../../../api/organization';
import { Link, useHistory, useParams } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';

import InsideMenuBar from '../../../../components/InsideMenus';


function RoomListing(props) {
	const [ state, dispatch] = useContext(Context);
	const { id } = useParams();
	const history = useHistory();
	const [currentTab, SetCurrentTab] = React.useState(1);
	const [wardRoomList,setWardRoomList] = React.useState([]);
	const [patients,setpatients] = React.useState([]);
	const [patientPaginate, setPatientPaginate] = React.useState([]);
	// const [permissions,setPermissions] = React.useState([]);
	const permissions = JSON.parse(localStorage.getItem('subUserPermission'))
    const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	useEffect(() => {
		loader(true);
		getWardRoomAPI().then(res=>{
			setWardRoomList(res.data)
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
		})
		getWardRoomPatients().then(res => {
            const filterWardRoom = res.data.filter((item) => item.roomWard.roomNo == id)
			setpatients(filterWardRoom);
			loader(false)
		}).catch(err => {
			loader(false)
			toast.error(err?.response?.data?.error);
		})
		// let des = JSON.parse(localStorage.getItem('subuser'))
		// setPermissions(des.permission);
	}, [])

	const pageChangePatients  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setPatientPaginate(data);
	}

	const tableHeading = [
		{id: 1, title: 'Patient'},
		{id: 2, title: 'Alot. Date'},
        {id: 3, title: 'Discharge Date'},
        {id: 4, title: 'Status'},
		{id: 5, title: 'Actions'}
	];

	const getStatusClass = (item) => {
		if(item === 0) {
			return 'badge badge-pill bg-warning-light'
		} else if(item === 1) {
			return 'badge badge-pill bg-success-light'
		} else if(item === 2) {
			return 'badge badge-pill bg-danger-light'
		}  else if(item === 3) {
			return 'badge badge-pill bg-danger-light'
		}	else if(item === 4) {
			return 'badge badge-pill bg-success-light'
		}
	}

	const getStatus = (item) => {
		if(item === 0) {
			return 'Occupied'
		} else if(item === 1) {
			return 'Completed'
		}
	}




	const addPatient = () => {
		props.history.push('/subuser/ipdward/add-patient');
	}
	
	return (
		<StyledContainer>
			<InsideMenuBar 
				props={props} 
				type={'subadmin'}
				/>
				
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div>
                            <div className="col-md-2 ml-auto">
								{permissions.includes("Admin") || permissions.includes("Admit a patient into IPD") ?
									<button className="doctor-active-btn doctor-w-100" onClick={() => addPatient()} >Add a patient </button>
									: null}
							</div>
							<div className="d-flex mb-4">
								<button onClick={() => {history.push('/subuser/ipdward/1'); SetCurrentTab(1)}} className={currentTab === 1 ? 'doctor-active-btn' : "doctor-btn-outline"}>
									Room View
								</button>

								<button onClick={() => {history.push('/subuser/ipdward/2');SetCurrentTab(2)}} className={currentTab === 2 ? 'doctor-active-btn' : "doctor-btn-outline"}>
									List View
								</button>
							</div>
									<div>
										<CardComponent className="p-4">
											<div className="doctor-panel-header">
												<h3 className="mb-3">Patients</h3>
											</div>

											<TableComponent tableHeading={tableHeading}>
												{
													patientPaginate && patientPaginate.map(item => {
														return <tr>
															<td>
																<h2 className="table-avatar">
																	<a className="avatar avatar-sm mr-2">
																		<img className="avatar-img rounded-circle" src={item.patient && (item.patient.profileImage ? item.patient.profileImage : "/assets/img/doctors/doctor-thumb-02.jpg")} alt="User Image" />
																	</a>
																	<a>{item.patient ? item.patient.name : ''} </a>
																</h2>
															</td>
															<td>{item.admissionDateTime ? moment(item.admissionDateTime).utc().format('DD/MM/YYYY'): '--'}<span class="d-block color-blue">{item.admissionDateTime && moment(item.admissionDateTime).format('hh:mm a')}</span></td>
															<td>{item.dischargeDate ? moment(item.dischargeDate).utc().format('DD/MM/YYYY'): '--'}<span class="d-block color-blue">{item.dischargeDate ? moment(item.dischargeDate).format('hh:mm a'): '--'}</span></td>
															<td><span className={getStatusClass(item.status)}>{getStatus(item.status)}</span></td>
															<td>
																<div className="table-action">
																	<Link to={`/subuser/view-patient/${item._id}/${item.patient._id}`} className="btn btn-sm bg-info-light-blue">
																		<i className="far fa-eye"></i> View
																	</Link>
																</div>
															</td>
														</tr>
													})
												}
											</TableComponent>
											{patients.length > 0 && <Pagination items={patients} onChangePage={pageChangePatients} />}

										</CardComponent>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div>

				</div>
			</div>
		</StyledContainer>
	)
}

export default RoomListing

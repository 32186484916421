import React from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	getDashAppointmentsAPI
} from '../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import CardComponent from '../../../components/Card';
import TableComponent from '../../../components/Table';
import Pagination from '../../../components/Pagination';
import Search from '../../../components/Search';

import {
	StyledContainer,
	StyledButtonGroup,
	StyledButton
} from './styles';
import InsideMenuBar from '../../../components/InsideMenus';

const options = [
	// { id: 0, label: 'Id', value: '_id' },
	{ id: 1, label: 'Name', value: 'name' },
	// { id: 2, label: 'Email', value: 'email' },
	// { id: 3, label: 'Status', value: 'status' },
	// { id: 4, label: 'Contact Number', value: 'contact' }
]

function PatientDashboard(props) {
	const todayDate =  moment().format('HH:mm:ss')
	const [state, dispatch] = React.useContext(Context);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('name');
	const [currentTab, SetCurrentTab] = React.useState(1);
	const [todayAppointments, setTodayAppointments] = React.useState([]);
	const [upcomingAppointments, setUpcomingAppointments] = React.useState([]);
	const [historyAppointments, setHistoryAppointments] = React.useState([]);
	const [completedAppointments, setCompletedAppointments] = React.useState([]);

	const fetchDashAppointmentsAPI = () => {
		loader(true);
		getDashAppointmentsAPI().then(res => {
			loader(false);
			// toast.success(res?.message)
			const fetchHistoryAppointments = res.data.historyAppointments.filter((appointment) => appointment.status != 4)
			// const todayAppoint = res.data.todaysAppointments.filter((item) => moment(item.slot.start).utc().format('HH:mm:ss') >= todayDate && item.status != 4)
			const todayAppoint = res.data.todaysAppointments.filter((item) => item.status != 4)
			const upcomingAppoint = res.data.upcomingAppointments.filter((item) => item.status != 4)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});

			setTodayAppointments(todayAppoint);
			setUpcomingAppointments(upcomingAppoint);
			setHistoryAppointments(fetchHistoryAppointments);
			setCompletedAppointments(res.data.completedAppointments);
			
		}).catch(err => {
			loader(false);
			setTodayAppointments([]);
			setUpcomingAppointments([]);
			setHistoryAppointments([]);
			setCompletedAppointments([]);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	React.useEffect(() => {
		document.body.className = "";
		fetchDashAppointmentsAPI()
		
	}, [])

	const searchPatient = () => {
		loader(true);
		const payload = {
			[selectValue]: search
		}
		getDashAppointmentsAPI(payload).then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});

			setTodayAppointments(res?.data?.todaysAppointments);
			setUpcomingAppointments(res?.data?.upcomingAppointments);
			setHistoryAppointments(res?.data?.historyAppointments);
			setCompletedAppointments(res?.data?.completedAppointments);
		}).catch(err => {
			setTodayAppointments([]);
			setUpcomingAppointments([]);
			setHistoryAppointments([]);
			setCompletedAppointments([]);
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}


	const getStatusClass = (item) => {
		if(item === 0) {
			return 'badge badge-pill bg-warning-light'
		} else if(item === 1 || item === 4) {
			return 'badge badge-pill bg-success-blue text-white'
		} else if(item === 2 || item === 3) {
			return 'badge badge-pill bg-danger-light'
		}
	}

	const getStatus = (item) => {
		if(item === 0) {
			return 'Pending'
		} else if(item === 1) {
			return 'Confirm'
		} else if(item === 2) {
			return 'Rejected by doctor'
		}  else if(item === 3) {
			return 'Rejected by patient'
		}  else if(item === 4) {
			return 'Completed'
		}
	}
	
	const renderAppointmentAmount = (item) => {
		const amount = item?.amount.split('');
		const index = amount?.findIndex((char) => /^[0-9]$/.test(char))
		if(item?.amount && item?.pendingAmount){
			if(!index){
			    return `${parseInt(item?.amount) + item?.pendingAmount}` 
			}else {
			    return `${item?.amount.slice(0,index)}${parseInt(item?.amount.slice(index)) + item?.pendingAmount}` 
			}
		} else {
			return item?.amount
		}
        
    }

	const tableHeading = [
		{id: 1, title: 'Doctor'},
		{id: 3, title: 'Date'},
		// {id: 4, title: 'Last modify Date'},
		{id: 5, title: 'Amount'},
		// {id: 5, title: 'Booking Status'},
		{id: 5, title: 'Status'},
		{id: 5, title: 'Actions'}
	];
	const tableHeadingReport = [
		{id: 1, title: 'Doctor'},
		{id: 3, title: 'Date'},
		{id: 5, title: 'Status'},
		{id: 5, title: 'View pdf'},
		{id: 5, title: 'Actions'}
	];

	const [completedAppointmentsPaginate, setCompletedAppointmentsPaginate] = React.useState([]);
	const pageChangeCompleted  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setCompletedAppointmentsPaginate(data);
	}


	const [todayAppointmentsPaginate, setTodayAppointmentsPaginate] = React.useState([]);
	const pageChangeToday  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setTodayAppointmentsPaginate(data);
	}

	const [upcomingAppointmentsPaginate, setUpcomingAppointmentsPaginate] = React.useState([]);
	const pageChangeUpcoming  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setUpcomingAppointmentsPaginate(data);
	}

	const [historyAppointmentsPaginate, setHistoryAppointmentsPaginate] = React.useState([]);
	const pageChangeHistory  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setHistoryAppointmentsPaginate(data);
	}

	React.useEffect(() => {
		if(search === '') {
            fetchDashAppointmentsAPI();
        }
	}, [search])


	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>

			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12">
							<CardComponent className="p-4">
								<StyledButtonGroup>
									<StyledButton className={currentTab === 1 ? "active-btn mb-2" : ' mb-2'} onClick={() => SetCurrentTab(1)}>
										Today 
									</StyledButton>

									<StyledButton className={currentTab === 2 ? "active-btn mb-2" : ' mb-2'} onClick={() => SetCurrentTab(2)}>
										Upcoming 
									</StyledButton>

									<StyledButton className={currentTab === 3 ? "active-btn mb-2" : ' mb-2'} onClick={() => SetCurrentTab(3)}>
										 History
									</StyledButton>

									<StyledButton className={currentTab === 4 ? "active-btn mb-2" : ' mb-2'} onClick={() => SetCurrentTab(4)}>
										Completed Appointments
									</StyledButton>

								</StyledButtonGroup>
								<div className="doctor-panel-filters row col-md-12">
									<Search 
										selectValue={selectValue} 
										setSelectValue={setSelectValue} 
										search={search} 
										setSearch={setSearch} 
										searchPatient={searchPatient} 
										name={"Doctor's"}
										options={options}
									/>
								</div>

								{
									currentTab === 4 && <div><TableComponent tableHeading={tableHeadingReport}> {completedAppointments.length ? completedAppointmentsPaginate.map(item => {
											return <tr key={item._id}>
															<td>
																<h2 className="table-avatar">
																	<a className="avatar avatar-sm mr-2">
																		<img className="avatar-img rounded-circle" src={item.doctorId.profileImage ? item.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
																	</a>
																	<a>{item.doctorId.name} </a>
																</h2>
															</td>
															<td>{moment(item.slot.start).utc().format('DD/MM/YYYY')}<span class="d-block color-blue">{moment(item.slot.start).utc().format('hh:mm a')} - {moment(item.slot.end).utc().format('hh:mm a')}</span></td>
														
														

															<td><span className={getStatusClass(item.status)}>{
																getStatus(item.status)
															}</span></td>

															<td>
																{item.pdf ? <a style={{color: 'rgb(81 86 190)'}} href={item.pdf} target="_blank">View pdf</a> : '-'}
															</td>

															{/* <td><span className="badge badge-pill bg-success-light">In-progress</span></td> */}
															{/* 	<td><span class="badge badge-pill bg-danger-light">Cancelled</span></td> */}
															{/* <td><span class="badge badge-pill bg-warning-light">Pending</span></td> */}
															<td>
																<div className="table-action">
																	<Link to={`/patient/view-appointment/${item._id}`} className="btn btn-sm bg-info-light">
																		<i className="far fa-eye"></i> View Report
																	</Link>
																</div>
															</td>
														</tr>
										}): null
									}

										{
										currentTab === 4 && completedAppointments.length === 0 && (
											<tr>
												<td colSpan="12">
													<h5 style={{ textAlign: "center" }}>
														Sorry! There are no results to display
													</h5>
												</td>
											</tr>
										)
									}
									</TableComponent>

									{completedAppointments && completedAppointments.length > 0 && <Pagination items={completedAppointments} color={'#5156be'} onChangePage={pageChangeCompleted} />} 
									</div>
								}

							{
									currentTab != 4 && 	<div><TableComponent tableHeading={tableHeading}>
									{
										currentTab === 1 && todayAppointments?.length ? todayAppointmentsPaginate.map(item => {
											return <tr key={item._id}>
															<td>
																<h2 className="table-avatar">
																	<a className="avatar avatar-sm mr-2">
																		<img className="avatar-img rounded-circle" src={item.doctorId.profileImage ? item.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
																	</a>
																	<a>{item.doctorId.name} </a>
																</h2>
															</td>
															<td>{moment(item.slot.start).utc().format('DD/MM/YYYY')}<span class="d-block color-blue">{moment(item.slot.start).utc().format('hh:mm a')} - {moment(item.slot.end).utc().format('hh:mm a')}</span></td>
															{/* <td>{item.last_updated ? moment(item.last_updated).format('DD/MM/YYYY') : '-'} </td> */}
															<td>{renderAppointmentAmount(item)} </td>
															<td><span className={getStatusClass(item.status)}>{
																getStatus(item.status)
															}</span></td>
															{/* <td><span className="badge badge-pill bg-success-light">In-progress</span></td> */}
															{/* 	<td><span class="badge badge-pill bg-danger-light">Cancelled</span></td> */}
															{/* <td><span class="badge badge-pill bg-warning-light">Pending</span></td> */}
															<td>
																<div className="table-action">
																	<Link to={`/patient/view-appointment/${item._id}`} className="btn btn-sm bg-info-light">
																		<i className="far fa-eye"></i> View
																	</Link>
																</div>
															</td>
														</tr>
										}): null
									}

									{
										currentTab === 2 && upcomingAppointments?.length ? upcomingAppointmentsPaginate.map(item => {
											return <tr key={item._id}>
															<td>
																<h2 className="table-avatar">
																	<a className="avatar avatar-sm mr-2">
																		<img className="avatar-img rounded-circle" src={item.doctorId.profileImage ? item.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
																	</a>
																	<a>{item.doctorId.name} </a>
																</h2>
															</td>
															<td>{moment(item.slot.start).utc().format('DD/MM/YYYY')}<span class="d-block color-blue">{moment(item.slot.start).utc().format('hh:mm a')} - {moment(item.slot.end).utc().format('hh:mm a')}</span></td>
															{/* <td>{item.last_updated ? moment(item.last_updated).format('DD/MM/YYYY') : '-'} </td> */}
															<td>{renderAppointmentAmount(item)} </td>
															<td><span className={getStatusClass(item.status)}>{
																getStatus(item.status)
															}</span></td>
															{/* <td><span className="badge badge-pill bg-success-light">In-progress</span></td> */}
															{/* 	<td><span class="badge badge-pill bg-danger-light">Cancelled</span></td> */}
															{/* <td><span class="badge badge-pill bg-warning-light">Pending</span></td> */}
															<td>
																<div className="table-action">
																	<Link to={`/patient/view-appointment/${item._id}`} className="btn btn-sm bg-info-light">
																		<i className="far fa-eye"></i> View
																	</Link>
																</div>
															</td>
														</tr>
										}): null
									}

									{
										currentTab === 3 && historyAppointments?.length ? historyAppointmentsPaginate.map(item => {
											return <tr key={item._id}>
															<td>
																<h2 className="table-avatar">
																	<a className="avatar avatar-sm mr-2">
																		<img className="avatar-img rounded-circle" src={item.doctorId.profileImage ? item.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
																	</a>
																	<a>{item.doctorId.name} </a>
																</h2>
															</td>
															<td>{moment(item.slot.start).utc().format('DD/MM/YYYY')}<span class="d-block color-blue">{moment(item.slot.start).utc().format('hh:mm a')} - {moment(item.slot.end).utc().format('hh:mm a')}</span></td>
															{/* <td>{item.last_updated ? moment(item.last_updated).format('DD/MM/YYYY') : '-'} </td> */}
															<td>{renderAppointmentAmount(item)} </td>
															<td><span className={getStatusClass(item.status)}>{
																getStatus(item.status)
															}</span></td>
															{/* <td><span className="badge badge-pill bg-success-light">In-progress</span></td> */}
															{/* 	<td><span class="badge badge-pill bg-danger-light">Cancelled</span></td> */}
															{/* <td><span class="badge badge-pill bg-warning-light">Pending</span></td> */}
															<td>
																<div className="table-action">
																	<Link to={`/patient/view-appointment/${item._id}`} className="btn btn-sm bg-info-light">
																		<i className="far fa-eye"></i> View
																	</Link>
																</div>
															</td>
														</tr>
										}) : null
									}

									

									{
										currentTab === 1 && todayAppointments?.length === 0 && (
											<tr>
												<td colSpan="12">
													<h5 style={{ textAlign: "center" }}>
														Sorry! There are no results to display
													</h5>
												</td>
											</tr>
										)
									}

									{
										currentTab === 2 && upcomingAppointments?.length === 0 && (
											<tr>
												<td colSpan="12">
													<h5 style={{ textAlign: "center" }}>
														Sorry! There are no results to display
													</h5>
												</td>
											</tr>
										)
									}

									{
										currentTab === 3 && historyAppointments?.length === 0 && (
											<tr>
												<td colSpan="12">
													<h5 style={{ textAlign: "center" }}>
														Sorry! There are no results to display
													</h5>
												</td>
											</tr>
										)
									}

										


								</TableComponent>

								{currentTab === 1 && todayAppointments?.length > 0 && <Pagination items={todayAppointments} color={'#5156be'} onChangePage={pageChangeToday} />} 

								{currentTab === 2 && upcomingAppointments?.length > 0 && <Pagination items={upcomingAppointments} color={'#5156be'} onChangePage={pageChangeUpcoming} />} 

								{currentTab === 3 && historyAppointments?.length > 0 && <Pagination items={historyAppointments} color={'#5156be'} onChangePage={pageChangeHistory} />} 

								</div>
							}
							
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default PatientDashboard

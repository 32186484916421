import { Box, Grid, Typography, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledCrossIcon,
  StyledDaysGrid,
  StyledHeadingText2,
  StyledRecurrenceGrid,
  StyledSecondaryButton,
  StyledSlotDivider,
  TopCont,
} from "../../../assets/styles/CommonStyles";

import { makeStyles } from "@mui/styles";
import Slot, { TextStyled } from "../../controls/Slot";
import { Formik } from "formik";
import { doctorSlotSchema } from "../../../utils/forms/schema.slotsSchema";
import FieldWrapper from "../../InputField/TextFieldWrapper";
import SelectionDropdown from "../../control/SelectionDropdown";
import { daysOfWeek, shiftData, slotType } from "../../../../src/constant/staticLists";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StyledAccordion from "../../Accordion";
import { useSelector } from "react-redux";
import * as authService from "../../../services/mainService";
import moment from "moment";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export const useStyles = makeStyles(() => ({
  text: {
    color: "#5E5E5E",
  },
}));

const DoctorSlotCreation = ({
  setSlotClicked,
  isSlotClicked,
  scheduleSelection,
 
}) => {
  const currentDay = new Date().getDay();
  const formattedOneYearLater = moment().add(1, "years").format("YYYY-MM-DD");
  const classes = useStyles();
  const [currencyList, setCurrencyList ] = useState([]);
  const initialDataValues = {
    currency: "",
    price: "",
    time_slots: [],
    recurrence: 0,
    day_of_week: [currentDay],
    // start_date: moment(new Date()).format("YYYY-MM-DD"),
    start_date: dayjs().format("YYYY-MM-DD"),
    type: scheduleSelection,
    end_date: moment(new Date()).format("YYYY-MM-DD"),
    timezone: localStorage.getItem('timezone')
  };
  const [firstShift, setFirstShift] = useState([]);
  const [secondShift, setSecondShift] = useState([]);

  const { doctorAvailableTime, userData, currencieslist } = useSelector((state) => ({
    doctorAvailableTime: state.schedule.availableTimeSlotList || [],
    userData: state.auth.userData,
    currencieslist: state.public.currencies

  }));

  const handleCross = () => {
    setSlotClicked(!isSlotClicked);
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (values.recurrence === 0) {
      values.start_date = dayjs().format("YYYY-MM-DD");
      values.end_date = moment(new Date()).format("YYYY-MM-DD");
      let currentDay = new Date(values.start_date).getDay();
      values.day_of_week = currentDay === 0 ? [6] : [currentDay - 1]
    }
    let data = values;
    const res = await authService.addSlotDoctorApi({ data, token: userData.token });
    if (res.response_type === "fail") {
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
      });
    } else {
      toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, });
      handleCross();
      resetForm();
    }
  };

  useEffect(() => {
    setAvailableTime(doctorAvailableTime);
  }, [doctorAvailableTime]);

  const setAvailableTime = (doctorAvailableTime) => {
    Object.values(doctorAvailableTime).forEach((item) => {
      const startMoment = moment(item.start_time, "HH:mm:ss");
      if (startMoment.format("A") === "AM") {
        setFirstShift((prev) => [...prev, item]);
      } else {
        setSecondShift((prev) => [...prev, item]);
      }
    });
  };

  useEffect(async () => {
   const res = await authService.getCurrencyApi();
    if (res.response_type === "fail") {
    } else {
      const list = res?.response?.data
      list?.map((item) => {
        const temp = { label: item.name, level: item.enum };
        setCurrencyList((prev) => [...prev, temp]);
       }); 
    }
  },[]);

  return (
    <>
      <Grid container>
        <TopCont item xs={12} mt="24px">
          <StyledHeadingText2 className={classes.text}>
            Create time slots
          </StyledHeadingText2>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="x"
            onClick={handleCross}
          />
        </TopCont>
        <Grid item xs={12}>
          <StyledSlotDivider />
        </Grid>
        <Grid>
        </Grid>
      </Grid>
      <div style={{ marginTop: "32px" }}>
        <Formik
          validationSchema={doctorSlotSchema}
          initialValues={initialDataValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleSubmit, setFieldValue, errors, handleReset }) => {
            const handleDropdownValue = (data, fieldName) => {
              const id = data.level;
              if (fieldName === "currency") {
                setFieldValue("currency", id);
              } else {
                setFieldValue("type", id);
              }
            };
            const setSelectedItems = (slotList) => {
              setFieldValue("time_slots", slotList);
            };
            const handleRecurrence = (values, value) => {
              if (value === 6) {
                setFieldValue("day_of_week", []);
              }
              if (values.recurrence === value) {
                setFieldValue("recurrence", 0);
              } else {
                setFieldValue("recurrence", value);
              }
              if (value !== 0) {
                setFieldValue("end_date", formattedOneYearLater);
                if (value == 1) {
                  setFieldValue("day_of_week", [1, 2, 3, 4, 5, 6, 0]);
                }
              }
            };
            const daySelected = (values, day, e) => {
              if (values.day_of_week.includes(day.level)) {
                setFieldValue(
                  "day_of_week",
                  values.day_of_week.filter((d) => d !== day.level)
                );
              } else {
                setFieldValue("day_of_week", [
                  ...values.day_of_week,
                  day.level,
                ]);
              }
            };
            const handleDate = (date) => {
              let data = moment(date.$d).format("YYYY-MM-DD");
              setFieldValue('end_date', data);
            };

            const handleStartDate = (date) => {
              // let data = moment(date.$d).format("YYYY-MM-DD");
              let data = dayjs(date.$d).format("YYYY-MM-DD")
              setFieldValue('start_date', data);
            }
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                    <SelectionDropdown
                      name="currency"
                      height={"48px"}
                      className={classes.customAutocomplete}
                      label={`Select currency type`}
                      handleDropdownValue={(data) =>
                        handleDropdownValue(data, "currency")
                      }
                      list={currencyList}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <FieldWrapper
                      type="number"
                      name="price"
                      label="Enter consultation amount"
                      value={values.price}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <SelectionDropdown
                      name="type"
                      height={"48px"}
                      className={classes.customAutocomplete}
                      label={`Select slot Type`}
                      handleDropdownValue={(data) =>
                        handleDropdownValue(data, "type")
                      }
                      list={slotType}
                    />
                  </Grid>
                  <Grid item xs={12} justifyContent={"center"}>
                    <TextStyled
                      style={{ marginTop: "16px", marginBottom: "8px" }}
                    >
                      Repeat Schedule
                    </TextStyled>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledRecurrenceGrid container spacing={2}>
                      <Grid onClick={() => handleRecurrence(values, 1)} className={values.recurrence == 1 ? "selected" : ""}>
                        <span name="recurrence">Daily</span>
                      </Grid>
                      <Grid onClick={() => handleRecurrence(values, 6)} className={values.recurrence == 6 ? "selected" : ""} >
                        <span name="recurrence">Custom </span>
                      </Grid>
                    </StyledRecurrenceGrid>
                  </Grid>
                 
                  {values.recurrence === 6 && (
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "left" }}
                      >
                        <TextStyled style={{ marginTop: "16px" }}>
                          Repeat on
                        </TextStyled>
                      </Grid>
                      <StyledDaysGrid item>
                        {daysOfWeek.map((day) => (
                          <Box className={values.day_of_week.includes(day.level) ? "selected" : ''} name="day_of_week"
                            onClick={() => daySelected(values, day)}>
                            <Typography>
                              {day.label}
                            </Typography>
                          </Box>
                        ))}

                      </StyledDaysGrid>
                    </>
                  )}
                  {(values.recurrence === 1 || values.recurrence === 6) &&
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "left" }}
                      >
                        <TextStyled>Start on</TextStyled>
                      </Grid>
                      <Grid item style={{ paddingTop: "4px" }} name="start_date">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker disablePast value={dayjs(values.start_date)} onChange={handleStartDate} renderInput={(params) => <TextField {...params} />} />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "left" }}
                      >
                        <TextStyled>End on</TextStyled>
                      </Grid>
                      <Grid item style={{ paddingTop: "4px" }} name="end_date">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker disablePast value={dayjs(values.end_date)} onChange={handleDate} renderInput={(params) => <TextField {...params} />} />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </>}
                  {shiftData.map((shift, index) =>
                    <Grid item xs={12}>
                      <StyledAccordion dayName={shift.label}>
                        <Slot
                          name="time_slots"
                          list={shift.label === "First Shift" ? firstShift : secondShift}
                          setSelectedItems={setSelectedItems}
                          selectedItems={values.time_slots}
                        />
                      </StyledAccordion>
                    </Grid>)}

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <StyledSecondaryButton
                      disabled={Object.keys(errors).length > 0}
                      style={{ marginBottom: 24 }}
                      onClick={() => handleSubmit()}
                    >
                      Create Slot
                    </StyledSecondaryButton>
                  </Grid>
                </Grid>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  )
}

export default DoctorSlotCreation;

import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import { StyledButton, StyledCrossIcon, StyledFormik } from "../../assets/styles/CommonStyles";
import strings from "../../constant/strings";
import FieldWrapper from "../InputField/TextFieldWrapper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { HEALTH_CONDITION_STATUS_ENUM, MEDICINE_FORM_ENUM, QUANTITY_ENUM, STRENGTH_TYPE_ENUM } from "../../constants";
import styled from "styled-components";
// import DatePicker from "react-date-picker";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const HealthCondtionsForm = ({ values, handleChange, handleBlur }) => {
    return (<>
        <Grid item xs={12}>
            <FieldWrapper
                name="diagnosis"
                label={strings.enterDignosis}
                type="text"
                value={values.diagnosis}
                onChange={(e) => {
                    handleChange("diagnosis")(e);
                }}
                onBlur={handleBlur}
            />

        </Grid>
        <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel>Select Status</InputLabel>
                <Select className="select-input-field" name="type" label="Select Status" onChange={handleChange} value={values.type} onBlur={handleBlur} fullWidth>
                    {HEALTH_CONDITION_STATUS_ENUM.map((x, i) => (
                        <MenuItem key={x} value={i + 1}>{x}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="treated_by"
                label={`${strings.treatedBy}`}
                type="text"
                value={values.treated_by}
                onChange={(e) => {
                    handleChange("treated_by")(e);
                }}
                onBlur={handleBlur}
            />

        </Grid>
    </>)
}

const MedicationForm = ({ values, handleChange, handleBlur }) => {
    return (<>
        <Grid item xs={12}>
            <FieldWrapper
                name="name"
                label="Enter medication name"
                type="text"
                value={values.name}
                onChange={(e) => {
                    handleChange("name")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel>Select Form of Medicine</InputLabel>
                <Select className="select-input-field" name="medicine_form" label='Select Form of Medicine' onChange={handleChange} value={values.medicine_form} onBlur={handleBlur} fullWidth>
                    {MEDICINE_FORM_ENUM.map((x, i) => (
                        <MenuItem key={x} value={x}>{x}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="medication_strength"
                label="Enter medicine strength"
                type="text"
                value={values.medication_strength}
                onChange={(e) => {
                    handleChange("medication_strength")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel>Select Strength Type</InputLabel>
                <Select className="select-input-field" name="strength_type" label='Select Strength Type' onChange={handleChange} value={values.strength_type} onBlur={handleBlur} fullWidth>
                    {STRENGTH_TYPE_ENUM.map((x, i) => (
                        <MenuItem key={x} value={x}>{x}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel>Select Quantity</InputLabel>
                <Select className="select-input-field" name="quantity" label='Select Quantity' onChange={handleChange} value={values.quantity} onBlur={handleBlur} fullWidth>
                    {QUANTITY_ENUM.map((x, i) => (
                        <MenuItem key={x} value={x}>{x}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    </>)
}

const SurguryForm = ({ values, handleChange, handleBlur, setFieldValue }) => {
    const classes = useStyles();
       const handleStartDate = (date) => {
        let data = dayjs(date.$d).format("YYYY-MM-DD")
        setFieldValue('date', data);
    }

    return (<>
        <Grid item xs={12}>
            <FieldWrapper
                name="name"
                label="Enter Surgury name"
                type="text"
                value={values.name}
                onChange={(e) => {
                    handleChange("name")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={12}>
            <FieldWrapper
                name="implants"
                label="Enter name of any implants or supported devices"
                type="text"
                value={values.implants}
                onChange={(e) => {
                    handleChange("implants")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6} mt={-1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                    <DatePicker 
                        label="Select surgery date"
                        className= {classes.customDatePicker}
                        disableFuture
                        onChange={handleStartDate}
                        renderInput={(params) => <TextField {...params} />} />
                </DemoContainer>
            </LocalizationProvider>

        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="surgeon_name"
                label="Enter surgeon name"
                type="text"
                value={values.surgeon_name}
                onChange={(e) => {
                    handleChange("surgeon_name")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={12}>
            <FieldWrapper
                name="notes"
                label="Enter any note (options)"
                type="text"
                value={values.notes}
                onChange={(e) => {
                    handleChange("notes")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
    </>)
}

const AllergyForm = ({ values, handleChange, handleBlur }) => {
    return (<>
        <Grid item xs={6}>
            <FieldWrapper
                name="name"
                label="Enter Allergy name"
                type="text"
                value={values.name}
                onChange={(e) => {
                    handleChange("name")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="triggered_by"
                label="Allergies Triggered By?"
                type="text"
                value={values.triggered_by}
                onChange={(e) => {
                    handleChange("triggered_by")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="reaction"
                label="Allergies Reactions"
                type="text"
                value={values.reaction}
                onChange={(e) => {
                    handleChange("reaction")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="how_often"
                label="How often does it occur"
                type="text"
                value={values.how_often}
                onChange={(e) => {
                    handleChange("how_often")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="first_diagnosed"
                label="First diagnosed on?"
                type="text"
                value={values.first_diagnosed}
                onChange={(e) => {
                    handleChange("first_diagnosed")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="medication"
                label="Enter medication"
                type="text"
                value={values.medication}
                onChange={(e) => {
                    handleChange("medication")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={12}>
            <FieldWrapper
                name="notes"
                label="Enter any note (optional)"
                type="text"
                value={values.notes}
                onChange={(e) => {
                    handleChange("notes")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
    </>)
}

const VaccinationsForm = ({ values, handleChange, handleBlur, setFieldValue }) => {
    const classes = useStyles();
    const handleStartDate = (date) => {
        let data = dayjs(date.$d).format("YYYY-MM-DD")
        setFieldValue('date', data);
    }
    return (<>
        <Grid item xs={6}>
            <FieldWrapper
                name="for"
                label="This vaccination is for ..."
                type="text"
                value={values.for}
                onChange={(e) => {
                    handleChange("for")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6} mt={-1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                    <DatePicker 
                        label="Select vaccination date"
                        className= {classes.customDatePicker}
                        disableFuture
                        onChange={handleStartDate}
                        renderInput={(params) => <TextField {...params} />} />
                </DemoContainer>
            </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="name"
                label="Enter vaccination name"
                type="text"
                value={values.name}
                onChange={(e) => {
                    handleChange("name")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="details"
                label="Enter vaccination detail"
                type="text"
                value={values.details}
                onChange={(e) => {
                    handleChange("details")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="lot_number"
                label="Enter lot number"
                type="text"
                value={values.lot_number}
                onChange={(e) => {
                    handleChange("lot_number")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={6}>
            <FieldWrapper
                name="notes"
                label="Enter any note (optional)"
                type="text"
                value={values.notes}
                onChange={(e) => {
                    handleChange("notes")(e);
                }}
                onBlur={handleBlur}
            />
        </Grid>
    </>)
}

const StyledBoxContainer = styled(Box)(() => ({
    '& .select-input-field': {
        background: '#F8F7F7',
        borderRadius: '8px'
    },
    '& .react-date-picker': {
        '& .react-date-picker__wrapper': {
            padding: 0,
            border: '1px solid #CCCCCC',
            background: '#F8F7F7',
            borderRadius: '8px',
            height: '48px',
            paddingLeft: '10px',
            '& + span': {
                '& .react-date-picker__calendar--open': {
                    '& .react-calendar__tile--now': {
                        background: 'linear-gradient(90deg, rgba(9, 126, 183, 0.05) 0%, rgba(42, 171, 108, 0.05) 100%)',
                    },
                    '& .react-calendar__tile--active': {
                        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                    }
                },
            }
        },
        '& input': {
            height: '48px',
            background: 'transparent',
            minWidth: 20,
            textAlign: 'center',
            '&:focus': {
                border: 'none'
            }
        },
        '& .react-date-picker__clear-button': {
            display: 'none'
        },
        '& .react-date-picker__calendar-button': {
            '& svg': {
                fill: 'rgba(0, 0, 0, 0.54)'
            }
        }

    }
}))

const AddPersonalMedicalDetails = ({ type, setOpenModal, getUpdatedValue, title, schemaValidations, initialValues = {}, apiService = async () => true }) => {
    const classes = useStyles();
    const [buttonLoading, setButtonLoading] = useState(false);
    const { userData } = useSelector((state) => ({
        userData: state.auth.userData
    }));
    const handleSubmit = async (values) => {
        setButtonLoading(true);
        const data = {
            values,
            token: userData.token
        }
        const res = await apiService(data);
        if (res.response_type === "fail") {
            setButtonLoading(false);
            toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
        } else {
            setButtonLoading(false);
            getUpdatedValue();
            setOpenModal({ state: false });
            toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
        }
    }

    return (
        <StyledBoxContainer>
            <Grid className={classes.secondayCont}>
                <h2 className={classes.mainCont}>{title}</h2>
                <StyledCrossIcon
                    src={"/assets/img/x.png"}
                    alt="User"
                    onClick={() => setOpenModal({ state: false })}
                />

            </Grid>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schemaValidations}
                    onSubmit={handleSubmit}
                >
                    {({ errors, handleSubmit, ...rest }) => (
                        <StyledFormik>
                            <Grid container spacing={2}>
                                {type === 1 && <HealthCondtionsForm {...rest} />}
                                {type === 2 && <MedicationForm {...rest} />}
                                {type === 3 && <SurguryForm {...rest} />}
                                {type === 4 && <AllergyForm {...rest} />}
                                {type === 5 && <VaccinationsForm {...rest} />}
                            </Grid>
                            <Box m={1} pt={1}>
                                <StyledButton
                                    name="submit"
                                    disabled={Object.keys(errors).length > 0 || buttonLoading}
                                    onClick={handleSubmit}
                                    variant="contained">
                                    {buttonLoading ? (
                                        <CircularProgress
                                            style={{ color: "white" }}
                                            size={20}
                                            thickness={5}
                                        />
                                    ) : `${strings.save}`}

                                </StyledButton>
                            </Box>
                        </StyledFormik>
                    )}
                </Formik>
            </div>
        </StyledBoxContainer>
    )
}

export default AddPersonalMedicalDetails;
import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import {
	Link, useHistory
} from 'react-router-dom';

import ReactStars from "react-rating-stars-component";

import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
	getDashboardAppointmentAPI,
	getDoctorsListing
} from '../../../../api/subadmin';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import InsideMenuBar from '../../../../components/InsideMenus';
import { LinkCustom } from './styled';
import { getWardRoomPatients } from '../../../../api/organization';

function SubAdminListingDoctors(props) {
	const [state, dispatch] = React.useContext(Context);

	const [id, setId] = useState(null);
	const [patientId, setPatientId] = useState(null);

	const [doctors, setDoctors] = useState(null);
	const permissions = JSON.parse(localStorage.getItem('subUserPermission'));
	const history = useHistory();
	const [wardRoomPatients, setWardRoomPatients] = React.useState([]);
	const [todayAppointment, setTodayAppointment] = React.useState([]);
	const [upcomingAppointment, setUpcomingAppointment] = React.useState([]);
	React.useEffect(() => {
		document.body.className = "";

	}, [])
	
	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('subuser'));
		
		if(props.match.params && props.match.params.id) 	{
			setPatientId(props.match.params.id)
		}

	
		if(user.OrganizationId) {
			loader(true);
			setId(user.OrganizationId);
			getDoctorsListing(user.OrganizationId).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				setDoctors(res.data);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}, []);

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const saveData = (e, data) => {
		e.preventDefault();
		Cookies.set('doctorInfo', JSON.stringify({
			name: data.name,
			address: data.address,
			department: data.department,
			amount: 300,
			profileImage: data.profileImage
		}), { path: '' });
	}
	const handleView = (item) => {
		history.push(`/subuser/doctor/${item._id}`,{
			wardRoomPatients: wardRoomPatients,
			todayAppointment: todayAppointment,
			upcomingAppointment: upcomingAppointment
		})
	}

	const getWardRoompatients = () => {
		getWardRoomPatients().then(res => {
			let filterData = (res.data && res.data.length) ? res.data.filter((item) => !item.dischargeByDoctor || !item.dischargeByFinancial) : []
			setWardRoomPatients(filterData);
			loader(false)
		}).catch(err => {
			loader(false)
			toast.error(err?.response?.data?.error);
		})
	}

	const getAppointmentData = () => {
		loader(true);
		getDashboardAppointmentAPI().then(res => {
			setTodayAppointment(res.data.todaysAppointments);
			setUpcomingAppointment(res.data.upcomingAppointments);
			loader(false);
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	useEffect(() => {
		getWardRoompatients();
		getAppointmentData();
	}, [])

	return (
		<div>
			<InsideMenuBar 
				props={props} 
				type={'subadmin'}
				/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">	
							<div className="row row-grid">
								{
									doctors && doctors.map(item => {
										return 	<div className="col-md-6 col-lg-4 col-xl-3" key={item._id}>
															<div className="profile-widget">
																<div className="doc-img">
																	<div style={{height: '180px', overflow: 'hidden'}}>
																		<img className="img-fluid" alt="User Image" src={item.profileImage ? item.profileImage : "/assets/img/doctor-empty.png"} />
																	</div>
																</div>
																<div className="pro-content">
																	<h3 className="title">
																		<a>{item.name}</a> 
																		<i className="fas fa-check-circle verified"></i>
																	</h3>
																	<p className="speciality">{item.department}</p>
																	
																	{/* <div className="rating" style={{display: 'flex', alignItems: 'center'}}>
																		<ReactStars
																			count={5}
																			value={4}
																			isHalf={true}
																			edit={false}
																			size={20}
																			activeColor="#ffd700"
																		/> */}
																		{/* <span className="d-inline-block average-rating">(17)</span> */}
																	{/* </div> */}
																	<ul className="available-info">
																		<li>
																			<i className="fas fa-hospital-alt"></i> {item.OrganizationId.org_name}
																		</li>
																		<li>
																			<i className="fas fa-map-marker-alt"></i> {item.address}
																		</li>
																		{/* <li>
																			<i className="far fa-clock"></i> Available on Fri, 22 Mar
																		</li> */}
																		<li style={{color: '#2c7fb7'}}>
																			<i className="far fa-money-bill-alt"></i>Online price :  {item.curreny} {item.appointmentPrice ? item.appointmentPrice : '-'} <i className="fas fa-info-circle" data-toggle="tooltip" title="Lorem Ipsum"></i>
																		</li>
																		<li style={{color: '#56be73'}}>
																			<i className="far fa-money-bill-alt"></i>In clinic price : {item.curreny} {item.clinicPrice ? item.clinicPrice : '-'} <i className="fas fa-info-circle" data-bs-toggle="tooltip" data-bs-placement="right" title={item.curreny}></i>
																		</li>
																	</ul>
																	<div className="row row-sm">
																		<div className="col-12" onClick={(e) => saveData(e, item)}>
																			{permissions?.includes("Admin") || permissions?.includes("OPD")  ? (
																				patientId && <Link to={`/subuser/book-appointment/${item._id}/${patientId}`} className="btn book-btn">Book Now</Link>
																			) : (
																				<LinkCustom onClick={()=>handleView(item)}>View</LinkCustom>
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													
									})
								}
							
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubAdminListingDoctors

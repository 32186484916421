import React, {useContext, useEffect,useState} from 'react';
import { StyledContainer } from './styles'
import {
	Link
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../../../../store';
import {
	getAppointmentDetailsAPI,
	updateCashPaymentAPI,
} from '../../../../../api/subadmin';
import {
	getDashAppointmentsAPI
} from '../../../../../api/patient';
import {
	getMedicalHistoryDataAPI
} from '../../../../../api/doctor';
import { 
	getPatientInfo,
	getIpdHistoryOfPatient,
	getAppointmentHistory
} from '../../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';
import HealthRecordsChart from '../../../Components/HealthRecordsChart';
import PatientInfo from '../../../Components/PatientInfo';
import MedicalHistoryComponent from '../../../../../components/medicalHistory';
import AppointmentCard from '../../../../../components/Appointments';
import IpdList from '../../../../../components/IpdList';
import ECGchart from '../../../../../components/EcgGraph';
import CardComponent from '../../../../../components/Card';
import MedicalReports from '../../ViewPatient/MedicalReports';

function SubAdminViewAppt(props) {
	const [state, dispatch] = React.useContext(Context);

	const [id, setId] = React.useState(null);
	const [appointment, setAppointment] = React.useState({});
	const [ipdHistory, setIpdHistory] = React.useState([]);
	const [patientId,setPatientId] = React.useState('')
	const [historyAppointments,setHistoryAppointments] = React.useState([])
	const [cashPaymentStatus, setCashPaymentStatus] = React.useState(0);
	const [ecgWidth, setEcgWidth] = useState();
	const [patientInfo, SetPatientInfo] = React.useState({
		dob: '',
		gender: '',
		phoneNumber: '',
		name: '',
		address: '',
		profileImage: null,
		org_name: '',
		weight: '',
		_id: null
	});
	const [medicalHistory, setMedicalHistory] = React.useState({
		allergieData: [],
		healthConditionData: [],
		medicationData: [],
		surgeriesData: [],
		vaccinationData: []
	});
	useEffect(() => {
		if(props.match.params && props.match.params.id) { 
			setId(props.match.params.id);
			loader(true);
			getAppointmentDetailsAPI(props.match.params.id).then(res => {
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				setPatientId(res.data.patientId._id)
				getMedicalHistory(res.data.patientId._id)
				getpatientInfo(res.data.patientId._id)
				getIpdHistory(res.data.patientId._id);
				getAppointments(res.data.patientId._id);
				setAppointment(res.data);
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		}
	}, [props.match.params.id])

	useEffect(()=>{
		const k = document.getElementById('ecg-graph-width-appointmentId').offsetWidth;
		setEcgWidth(k-20)
	},[])

	const getIpdHistory = (patientId) => {
		getIpdHistoryOfPatient(patientId).then((res) => {
			const filterIpd = res.data.filter((item)=>item._id !== props.match.params.id);
			setIpdHistory(filterIpd)
		}).catch((err) => {
			loader(false)
		})
	}
	const getpatientInfo = (patientId) => {
		getPatientInfo(patientId).then((res) => {
			// loader(false);
			SetPatientInfo({
				dob: res.data.dob,
				gender: res.data.gender,
				email: res.data.email,
				phoneNumber: res.data.phoneNumber,
				name: res.data.name,
				address: res.data.address,
				profileImage: res.data.profileImage,
				org_name: res.data.org_name,
				_id: res.data._id,
				weight: res.data.weight,
				bloodGroup : res.data.bloodGroup
			})
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
		})
	}

	const getMedicalHistory = (patientId) => {
		getMedicalHistoryDataAPI(patientId).then(res => {
			setMedicalHistory(res.data);
			// loader(false);
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}
	const getAppointments = (patientId) => {
		// getDashAppointmentsAPI({search: '', key: patientId}).then(res => {
		// 	loader(false);
		// 	// toast.success(res.message)
		// 	dispatch({
		// 		type: SET_ERROR,
		// 		payload: res.message
		// 	});
		// 	setHistoryAppointments(res.data.historyAppointments);
		// }).catch(err => {
		// 	loader(false);
		// 	toast.error(err.response.data.error)
		// 	dispatch({
		// 		type: SET_ERROR,
		// 		payload: err.response.data.error
		// 	});
		// })

		getAppointmentHistory(patientId).then(res => {
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
			setHistoryAppointments(res.data);
		}).catch(err => {
			loader(false);
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	const openIpdPatientView = (id) => {
		props.history.push(`/subuser/view-patient/${id}/${props.match.params.id}`)
	}

	const updateCashPayment = () => {
		loader(false);
		updateCashPaymentAPI(id, {cashPaymentStatus: cashPaymentStatus}).then(res => {
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
			getAppointmentDetailsAPI(id).then(res => {
				setAppointment(res.data);
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})

		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}

	const handleClickAppointment = (data) => {
		props.history.push(`/subuser/view-appointment/${data?._id}/${data?.patientId?._id}`);
	}
	
	return (
		<div>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
					<div className="col-md-12">
                            <div className="col-md-12 row">
							<Link
								to={'/subuser/dashboard'}
								style={{
									border: '1px solid #5156be',
									borderRadius: '5px',
									padding: '10px',
									marginBottom: '20px',
									color: '#5156be',
									fontSize: '17px',
									fontWeight: '500'
								}}
							>
								<i className="fa fa-arrow-left pr-2" />
								Back To Dashboard
							</Link>
                            </div>

							<h3 className="mb-4 mt-4">Patient details</h3>
							<StyledContainer>
								<div className="content">
									<div className="container-fluid pr-0">
										<div className="container-fluid-card row d-flex col-md-12">
											<div className='col-md-9'>
												<div className="col-md-12">
													

												</div>
												<HealthRecordsChart showAdd={true} patientId={patientId} />
												<div className="p-3">
													<h2 className = "cards-main-heading mb-3">Medical History</h2>
													<MedicalHistoryComponent medicalHistory={medicalHistory} />
												</div>
											</div>
											<div className='col-md' style={{ padding: "0px" }}>
												<PatientInfo patientInfo={patientInfo} {...props} showInfoButton = {false} appointment={appointment}/>
												<AppointmentCard
													heading='Previous appointments'
													data={historyAppointments}
													keyItem="Previous"
													handleClickAppointment={handleClickAppointment}
												/>
												<IpdList data={ipdHistory} openIpdPatientView={openIpdPatientView} />
												<CardComponent className='p-2 ecg-graph-width-appointment ecg-min-height' id='ecg-graph-width-appointmentId'>
													{ecgWidth && patientInfo._id && <ECGchart
														width={ecgWidth}
														id={patientInfo._id}
													/>}
												</CardComponent>
												<MedicalReports patiendId={patientInfo._id} />
											</div>
										</div>
										</div>
									</div>
									{/* <PatientInfoModal/> */}
							</StyledContainer>
						</div>

						{/* 
						<div className="col-md-7 col-lg-8 col-xl-9">
							
								{
									appointment && 
									<div className="row mb-4">
										<div className="col-md-5">
											<CardComponent>
												<div className="card-header">
													<h4 className="card-title">Appt. Booking Summary</h4>
												</div>
												
												<div className="card-body">
													<div className="booking-doc-info">
														<a className="booking-doc-img">
															<img src={appointment.doctorId && appointment.doctorId.profileImage ? appointment.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
														</a>
														<div className="booking-info">
															<h4><a >{appointment.doctorId && appointment.doctorId.name}</a></h4>
															<div className="rating">
																<span className="d-inline-block average-rating">{appointment.doctorId && appointment.doctorId.department}</span>
															</div>
															<div className="clinic-details">
																<p className="doc-location"><i className="fas fa-map-marker-alt"></i> {appointment.doctorId && appointment.doctorId.address}</p>
															</div>
														</div>
													</div>


													<div className="booking-summary">
														<div className="booking-item-wrap">
															
															<div className="booking-total">
																<ul className="booking-total-list">
																	<li>
																		<span>Booking Status</span>
																		<span className={getStatusClass(appointment.status)} style={{float: 'right'}}>{getStatus(appointment.status)}</span>
																	</li>
																</ul>
															</div>
														</div>
													</div>

													<div style={{height: '20px'}}></div>

													<div className="booking-summary">
														<div className="booking-item-wrap">
															<ul className="booking-date">
																<li>Patient Name <span>{appointment.patientId.name ? appointment.patientId.name : '-'}</span></li>
																<li>Gender <span>{appointment.patientId.gender ? appointment.patientId.gender : '-'}</span></li>
																<li>Contact number<span>{appointment.patientId.address ? appointment.patientId.address : '-'}</span></li>
															</ul>
															<hr />
															<h3>Emergency contacts</h3>
															<ul className="booking-fee">
																<li>Name <span>{appointment.patientId.emergency_name ? appointment.patientId.emergency_name : '-'}</span></li>
																<li>Relation <span>{appointment.patientId.emergency_relation ? appointment.patientId.emergency_relation : '-'}</span></li>
																<li>Contact number <span>{appointment.patientId.emergency_number ? appointment.patientId.emergency_number : '-'}</span></li>
															</ul>

															<hr />
															<h3>Appt. Booking Summary</h3>
															<ul className="booking-fee">
																<li>Appointment type <span style={{color: '#fff', background: '#60c27b', padding: '2px 10px'}}>{appointment.slot.type === 1 ? 'Online appt.' : 'In clinic appt.'}</span></li>
																<li>Appointment pay type <span style={{color: '#fff', background: '#60c27b', padding: '2px 10px'}}>{appointment.appointmentType === 0 ? 'Online' : 'In cash'}</span></li>
																{
																	appointment.appointmentType === 1 && (
																		<li>Payment status <span style={{color: '#fff', background:appointment.cashPaymentStatus === 0 ? 'red' : '#60c27b', padding: '2px 10px'}}>{
																			appointment.cashPaymentStatus === 0 ? 'Not paid' : 'Paid'
																		}</span></li>
																	) 
																}

																<li>
																	Appointment  
																	<span style={{color: '#56be73'}}>
																		{appointment.slot.start ?  moment(appointment.slot.start).format('DD/MM/YYYY') : '-'} {appointment.slot.start ?  moment(appointment.slot.start).format('hh:mm A') : '-'} 
																	</span>
																</li>
																<li>Last Updated <span style={{color: '#56be73'}}>{appointment.last_updated ? moment(appointment.last_updated).format('DD/MM/YYYY') : '-'}</span></li>
																<li>Consulting Fee <span style={{color: '#56be73'}}>{appointment.amount ? appointment.amount : '-'}</span></li>
																{
																	appointment.appointmentType === 0 && 
																	<li>Payment method type <span style={{color: '#56be73'}}>{appointment.paymentMode ?  <><img src={getPayLogo(appointment.paymentMode)} height="30px" style={{paddingRight: '10px'}} />{appointment.paymentMode}</> : '-'}</span></li>
																			
																}

																{
																	appointment.appointmentType === 0 && 
																	<li>Payment method code<span style={{color: '#56be73'}}>{appointment.paymentMode ?  <><img src={getPayLogo(appointment.paymentMode)} height="30px" style={{paddingRight: '10px'}} />{appointment.paymentModeCode}</> : '-'}</span></li>
																			
																}
															</ul>
														</div>
													</div>
												</div>
											</CardComponent>
										</div>

										{
											appointment.cashPaymentStatus === 0 && appointment.appointmentType === 1 && (
											<div className="col-md-7 text-center">
												<label>Payment status <small>(Payment status for cash)</small></label>
												<SelectField value={cashPaymentStatus} onChange={e => setCashPaymentStatus(e.target.value)} options={[{id: 1, label: 'Status' }, {id: 2, label: 'Paid', value: 1}, {id: 3, label: 'No paid', value: 0}]} />
												<button className="org-emd-active-btn mt-3 text-center" onClick={() => updateCashPayment()}> Update</button>
											</div>
											)
										}
									</div>
								}
						</div> */}
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubAdminViewAppt

import React from "react";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";

import colors from "../../constant/colors";

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

function Day(props) {
  const { day, selectedDay, ...other } = props;

  if (selectedDay == null) {
    return <PickersDay day={day} {...other} />;
  }

  const start = selectedDay.startOf('week');
  const end = selectedDay.endOf('week');

  const dayIsBetween = day.isBetween(start, end, null, '[]');
  const isFirstDay = day.isSame(start, 'day');
  const isLastDay = day.isSame(end, 'day');

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
}

const CalenderBlock = ({ dateValue, date, scheduleSelection }) => {
  const handleDate = (data) => {
    dateValue(data);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <Grid
        alignItems="center"
        justifyContent="center"
        style={{
          width: 320, height: 320,
          borderRadius: 8,
          boxShadow: `-4px -4px 15px ${colors.shadowGradient}, 4px 4px 15px ${colors.shadowGradient}`,
        }}
      >
        <DateCalendar
          defaultValue={dayjs(date)}
          style={{ width: 320, height: 320 }}
          onChange={handleDate}
          disablePast
          slots={!!scheduleSelection && scheduleSelection == 2 && { day: Day }}
          slotProps={!!scheduleSelection && scheduleSelection == 2 && {
              day: {
                selectedDay: dayjs(date),
              },
            }
          }
        />
      </Grid>
    </LocalizationProvider>
  );
};

export default React.memo(CalenderBlock);

import styled from 'styled-components';

export const StyledContainer = styled.div`
	padding-top: 12px;
	.unread-msg {
		background: #19ce67;
	}
	background: #fff;
	width :100%;
	height:110px;
	padding:5px;
	display: flex;
	flex-direction: row;
	ul {
		justify-content: space-around;
		width: 100%;
	}

	.patient-menubar-emed4u-link {
		color: #5156be !important;
		i {
			color: #5156be !important;
		}
	}

	.active-patients {
		.patient-menubar-emed4u-link {
			background: #5156be;
			color: #fff !important;
			border-radius: 15px;

			i {
				color: #fff !important;
			}
		}
	}
`;

import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import AppLayout from '../SideBar';
import Pricing from './Pricing';
import SubscriptionPage from './SubscriptionPage';
import { useSelector } from 'react-redux';
import * as mainServices from "../../services/mainService";

import SubscriptionPending from './SubscriptionPending';
function OrganizationSubscription() {
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));

  const [subscriptionDetails, setSubscriptionDetails] = useState(null); // Initially set to null to indicate loading
  const [loading, setLoading] = useState(true); // Loading state to control the page rendering

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const response = await mainServices.getProfileByOrganizationAPI(userData.token);
        setSubscriptionDetails(response?.response?.data);
        console.log("hello world",response?.response?.data)
      } catch (error) {
        console.log("Error fetching subscription details", error);
      } finally {
        setLoading(false); // Set loading to false once the request is completed
      }
    };

    fetchSubscriptionDetails();
  }, [userData.token]);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    ); // Show a loading spinner while fetching data
  }

  return (
    <Grid container spacing={0}>
    
      <AppLayout>
        {subscriptionDetails?.subscription ? (
          subscriptionDetails.subscription.id && subscriptionDetails.subscription.id !== "" ? (
            <SubscriptionPage status={"Active"} />
          ) : subscriptionDetails.subscription.id === "" ? (
            <Pricing />
          ) : subscriptionDetails.subscription.payment_status === "authorized" ? (
            <SubscriptionPending status={"Pending"} />
          ) : (
            <SubscriptionPending status={"Failed"} />
          )
        ) : (
          <Pricing />
          
        )}
      </AppLayout>
    </Grid>
    
  );
}

export default OrganizationSubscription;

import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
//import DatePicker from 'react-date-picker';
// import 'react-date-picker/dist/DatePicker.css';
// import 'react-calendar/dist/Calendar.css';

import { userProfile } from '../../../utils/forms/schema.profileSettings';
import { useDispatch, useSelector } from 'react-redux';
import { patientProfile } from '../../../redux/user/userActions';
import { updateProfile } from '../../../services/authService';
import PhoneField from '../../../components/PhoneField';
import moment from 'moment';
import { toast } from 'react-toastify';
import { handleFileUploadToS3 } from '../../../utils'
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useStyles } from '../../../assets/styles/BloodSugarStyles';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    height: '100%',
}))

const allowKeys = [...new Array(10).fill().map((x, index) => 96 + index), ...new Array(10).fill().map((x, index) => 48 + index), 8, 9, 13, 37, 39, 46, 190]

const NumberTextFieldWrapper = ({ ...rest }) => {
    const { type } = rest
    const handleInputNumber = (e) => {
        if (type === 'number' && !allowKeys.includes(e.keyCode)) {
            return e.preventDefault()
        }
    }
    return <TextField {...rest} onKeyDown={handleInputNumber} />
}

function PersonalDetails({ screenWidth, file }) {
    const classes =  useStyles();
    const today = new Date();
    const { patientProfileData, userData } = useSelector(state => ({
        patientProfileData: state.user.patientProfile || {},
        userData: state.auth.userData
    }))
    const dispatch = useDispatch()
    const initialValues = React.useMemo(() => {
        return ({
            name: patientProfileData.name || "",
            address: patientProfileData.address || "",
            countryCode: patientProfileData.countryCode || "",
            phoneNumber: patientProfileData.phoneNumber || "",
            height: patientProfileData.height || "",
            dob: (patientProfileData.dob && moment(patientProfileData.dob, 'YYYY-MM-DD')._isValid && moment(patientProfileData.dob, 'YYYY-MM-DD')) || "",
            gender: patientProfileData.gender || "",
            weight: patientProfileData.weight || "",
            bloodGroup: patientProfileData.bloodGroup || "",
        })
    }, [patientProfileData])

    const handleSubmitForm = async (data) => {
        //data = { ...data, ...data.dob && { dob: moment(data.dob, 'DD-MM-YYYY').format('YYYY-MM-DD') } }
        data = Object.entries(data).reduce((pre, [key, value]) => {
            pre[key] = Object.prototype.toString.call(value) !== '[object String]' ? value.toString() : value
            return pre
        }, {})
        data.dob = moment(data.dob).format("YYYY-MM-DD")
        if (file !== undefined) {
            const imageExtension = file.name.split(".")
            handleFileUploadToS3(file, `Patient/Profile-Image/${userData.uniqueID + "." + imageExtension[1]}`);
            data.profileImage = `Patient/Profile-Image/${userData.uniqueID + "." + imageExtension[1]}`
        }
        const response = await updateProfile(data, userData.token)
        if (response.response_type === 'success') {
            toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: { width: 'auto', borderRadius: 8 } });
            dispatch(patientProfile({ token: userData.token }))
        } else {
            toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: { width: 'auto', borderRadius: 8 } });
        }
    }

    return (
        <StyledGridContainer container spacing={0} justifyContent={'space-between'} alignItems={"flex-start"} minHeight={350} display={'flex'} position={'relative'}>
            <Formik
                initialValues={initialValues}
                validationSchema={userProfile}
                onSubmit={handleSubmitForm}
                enableReinitialize
            >
                {({ handleChange, handleBlur, errors, handleSubmit, values, touched, setFieldValue }) => {

                    const handleStartDate = (date) => {
                        //let data = moment(date.$d).format("YYYY-MM-DD");
                        let data = dayjs(date.$d).format("YYYY-MM-DD")
                        setFieldValue('dob', data);

                    }
                    return (<>
                        <Grid item xs={12}>
                            <Grid container spacing={4} className='form-container'>

                                <Grid item xs={12} md={4} style={{ marginTop: '16px', paddingTop: '16px' }}>
                                    <TextField id="name" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} label='Name' fullWidth />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <NumberTextFieldWrapper name="address" value={values.address} onChange={handleChange} onBlur={handleBlur} label='Address' fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel shrink sx={{
                                            position: 'absolute',
                                            zIndex: 1,
                                            left: '-4px',
                                            top: '3px',
                                            background: '#FFF !important',
                                            paddingRight: '2px',
                                            paddingLeft: '7px',
                                            lineHeight: '1rem',
                                            color: '#CCCCCC'
                                        }}>Phone number</InputLabel>
                                        <PhoneField
                                            value={`${values.countryCode}${values.phoneNumber}`}
                                            name="phoneNumber"
                                            onChange={(value, { dialCode }) => {
                                                handleChange("countryCode")(dialCode)
                                                handleChange("phoneNumber")(value.slice(dialCode.length))
                                            }}
                                            onBlur={handleBlur('phoneNumber')}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid container justifyContent={'space-between'} spacing={4}>
                                        <Grid item xs={6}>
                                            <NumberTextFieldWrapper
                                                type="number"
                                                name="height"
                                                value={values.height}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                label='Height (CM)'
                                                fullWidth
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} mt={-1}>
                                            <FormControl sx={{ width: '100%'}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={["DatePicker"]}>
                                                    <DatePicker
                                                        label="DOB"
                                                        disableFuture
                                                        value={dayjs(values.dob)}
                                                        onChange={handleStartDate}
                                                        //sx={{ background: "#F8F7F7" }}
                                                        className= {classes.customDatePicker}
                                                        renderInput={(params) => <TextField {...params} />} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </FormControl>
                                       </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel>Gender</InputLabel>
                                        <Select className="gender-select-box" name="gender" onChange={handleChange} value={values.gender} onBlur={handleBlur} label='Gender' fullWidth>
                                            {['Male', 'Female', 'Other'].map((x, i) => (
                                                <MenuItem key={x} value={i + 1}>{x}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid container justifyContent={'space-between'} spacing={4}>
                                        <Grid item xs={6}>
                                            <NumberTextFieldWrapper
                                                type="number"
                                                name="weight"
                                                onChange={handleChange}
                                                value={values.weight}
                                                onBlur={handleBlur}
                                                label='Weight (KG)'
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel>Blood Group</InputLabel>
                                                <Select className="gender-select-box" name="bloodGroup" onChange={handleChange} value={values.bloodGroup} onBlur={handleBlur} label='Blood Group' fullWidth>
                                                    {['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'].map((x, i) => (
                                                        <MenuItem key={x} value={i + 1}>{x}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                            ...screenWidth <= 900 && { marginTop: '32px' },
                        }}>
                            <Button type="submit" onClick={handleSubmit} disabled={Object.keys(errors).length > 0} sx={{
                                color: '#FFF',
                                padding: '4px 32px',
                                background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                                borderRadius: '8px',
                                height: 40,
                                minWidth: '201px'
                            }}>
                                <Typography sx={{
                                    height: 24,
                                    fontFamily: 'Poppins',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: '24px'
                                }}>
                                    Update Profile
                                </Typography>
                            </Button>
                        </Grid>
                    </>)
                }}
            </Formik>
        </StyledGridContainer>
    )
}

export default React.memo(PersonalDetails)
import styled from "styled-components";
import {
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  tableCellClasses,
  Modal,
  Button,
  Divider,
  Grid,
  CircularProgress,
} from "@mui/material";
import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";
import { Box } from "@mui/system";
import { types } from "../../components/controls/Tables";
import { DateCalendar } from "@mui/x-date-pickers";

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledMainModalCont = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${colors.white};
  border-radius: 5px;
  overflow: auto;
  display: flex-wrap;
  padding: 20px;

  @media (min-width: 3840px) {
    min-width: 50max;
    max-width: 60vmax;
    max-height: 40vmax;
  }

  @media only screen and (max-width: 414px) {
    /* CSS styles go here */
    max-width: 417px;
    min-width: 340px;
    min-height: 100vmax;
    max-height: 100vmax;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    /* CSS styles go here */
    min-width: 30vmax;
    max-width: 50vmax;
    max-height: 50vmax;

    div#my-modal.css-79ws1d-MuiModal-root {
      bottom: 323px;
    }
  }

  @media only screen and (min-width: 992px) {
    /* CSS styles go here */
    max-width: 50vmax;
    min-width: 40vmax;
    height: 41vmax;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledCrossIcon = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const StyledLogo = styled.img`
  max-height: 60px;
  padding: 8px 0px;
  width: auto;
  @media (max-width: 414px) {
    height: 40px;
  }
`;

export const StyledMargin = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  color: ${colors.white};
  background: ${colors.primary};
  border-radius: 8px;
`;

export const StyledViewMoreButton = styled(Button)`
  width: 100%;
  height: 48px;
  color: ${colors.white};
  background: ${colors.primary};
  border-radius: 0px 0px 8px 8px;
  text-transform: none;
`;

export const StyledSecondaryButton = styled(Button)`
  width: 70%;
  height: 42px;
  font-size: 14px;
  color: ${colors.white};
  background: ${colors.primary};
  font-weight: ${fontWeights.fontWeight500};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
`;

export const StyledWithBorderButton = styled(Button)`
  height: 40px;
  font-size: 14px;
  border: 2px solid linear-gradient(90deg, #097eb7 0%, #2aab6c 100%);
  background: ${colors.primary};
  font-weight: ${fontWeights.fontWeight500};
  border-radius: 8px;
  display: flex;
  width: 100%;
  text-transform: none;
  justify-content: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const StyledProceedButton = styled(Button)`
  min-height: 46px;
  min-width: 160px;
  font-size: 16px;
  color: ${colors.white};
  background: ${colors.primary};
  font-weight: ${fontWeights.fontWeight500};
  background-color: ${colors.primary};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  text-transform: none;
`;

export const RightButton = styled(Button)`
  min-height: 48px;
  font-size: 14px;
  color: ${colors.white};
  background: ${colors.primary};
  font-weight: ${fontWeights.fontWeight500};
  background-color: ${colors.primary};
  border-radius: 8px;
  display: flex;
`;

export const StyledFormik = styled.div`
  margin-top: 32px;
  max-width: 500px;
`;

export const StyledText = styled(Grid)`
  font-style: normal;
  font-weight: ${fontWeights.fontWeight400};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.black};
`;

export const StyledDivider = styled(Divider)`
  margin-top: 32px;
  color: ${colors.black};
`;

export const StyledImageCont = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledErrorMessage = styled.span`
  color: ${colors.errorTextColor};
  font-size: 10px;
  margin-left: 10px;
`;

export const StyledPadding = styled.div`
  @media only screen and (min-width: 992px) {
    padding: 90px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media only screen and (max-width: 414px) {
    padding: 0px 22px;
  }
`;

export const StyledTextCenter = styled.div`
  width: 100%;
  margin-top: 16px;
  text-align: center;
`;

export const StyledHeading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: ${fontWeights.fontWeight500};
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black1};
`;

export const StyledHeadingText01 = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: ${fontWeights.fontWeight500};
  font-size: 32px;
  line-height: 42px;
  color: ${colors.grey1};
`;

export const StyledHeadingText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: ${fontWeights.fontWeight400};
  font-size: 10px;
  line-height: 10px;
  color: ${colors.black1};
  margin-top: 7px;
`;

export const StyledInnerHeadingText = styled(Grid)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: ${fontWeights.fontWeight500};
  font-size: 24px;
  line-height: 32px;
  color: ${colors.grey1};
  @media only screen and (min-width: 992px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const StyledHeadingText2 = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
`;

export const UploadDocumentStyle = styled.div`
  width: 188;
  height: 96px;
  border-radius: 8px;
  display: flex;
  text-align: center;
  justify-content: center;
  border: 2px dashed grey;
  cursor: pointer;
`;

export const UploadStyledText = styled(Grid)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background: ${colors.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const TextStyle = styled.div`
  background: ${colors.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const SuccessLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const CircularProgressContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonImage = styled.img`
  width: 8px;
  height: 16px;
  color: white;
`;

export const DeptHeading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  text-align: center;
  color: ${colors.black2};
`;

export const HeadingText = styled.div`
  color: var(--Black-2, #5e5e5e);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

export const GridBox = styled(Grid)`
  min-height: 300px;
  min-width: 200px;
`;

export const DocImage = styled.img`
  height: 96px;
  widht: 188px;
  border-radius: 10px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  height: 200px;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
`;

export const NoDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: 24,
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  background: colors.white,
  border: "2px solid #000",
  boxShadow: 24,
  border: "none",
  outline: "none",
  borderRadius: 8,
  p: 4,
  border: "none",
  maxHeight: "80vh",
  overflowY: "auto",
};

export const StyledTabs = styled(Tabs)(({ theme, selectedItem, index }) => ({
  borderBottom: "1.2px solid #F5F5F7",
  "& .MuiTab-root": {
    justifyContent: "start",
    alignItems: "start",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "18px",
    color: "#5E5E5E",
  },
  "& .Mui-selected": {
    background: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
    backgroundClip: "text",
    textFillColor: "transparent",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    border: "1px solid",
    borderImageSlice: 1,
    borderWidth: "0px 0px 1.2px",
    borderImageSource: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
  },
}));

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "actionCell",
})(({ theme, actionCell }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#2B2B2B",
  [`&.${tableCellClasses.head}`]: {
    boxSizing: "border-box",
    height: "38px",
    background: "#F8F9FC",
    // borderWidth: "1px 0px",
    // borderStyle: "solid",
    // borderColor: "#F5F5F7",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#5E5E5E",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledActionCells = styled(StyledTableCell)(() => ({
  display: "flex",
  justifyContent: "space-evenly",
  "& svg": {
    width: "18px",
    height: "18px",
    color: "#5E5E5E",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StyledTableContainer = styled(TableContainer)(() => ({
  width: "100%",
}));

export const actionCellStyles = {
  "& .MuiIconButton-root": {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    background: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
    "& svg": {
      width: "18px",
      height: "18px",
      marginRight: "5px",
      fill: "url(#header-shape-gradient)",
    },
  },
};

export const nameCellStyles = {
  "& > span": {
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
  "& img": {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    marginRight: "10px",
    "& + span": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "500",
      color: "#2B2B2B",
      lineHeight: "18px",
      "& > span:first-child": {
        "& + span": {
          textTransform: "capitalize",
          fontWeight: 400,
          color: "#5E5E5E",
        },
      },
    },
  },
};

export const TopCont = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSlotDivider = styled(Divider)`
  height: 2px;
  background: #f5f5f7;
  margin-top: 14px;
`;

export const StyledDaysGrid = styled(Grid)(() => ({
  display: "flex",
  cursor: "pointer",
  "& .MuiBox-root": {
    padding: "4px 12px",
    marginRight: "16px",
    borderRadius: "100%",
    background: colors.lightBackground,
  },
  "& .MuiTypography-root": {
    background: colors.textColor,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  "& .selected": {
    background: colors.primary,
    "& .MuiTypography-root": {
      background: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "white",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
  },
}));

export const StyledRecurrenceGrid = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  textAlign: "center",
  "& .MuiGrid-root": {
    width: "104px",
    padding: "4px 0px",
    borderRadius: "28px",
    margin: "4px",
    background: colors.lightBackground,
    "& span": {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: "500",
      lineWeight: "16px",
      letterSpacing: "0em",
      background: colors.textColor,
      backgroundClip: "text",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    },
    "&.selected": {
      background: colors.primary,
      "& span": {
        background: colors.textColor,
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "white",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
  },
}));

export const ButtonStyleContainer = styled(Grid)(() => ({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "left",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  "& .accept": {
    background: colors.primary,
    borderRadius: "8px",
    color: `${colors.white}`,
    border: `1.5px solid `,
  },
  "& .accept: hover": {
    background: colors.textColor,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    border: `1.5px solid ${colors.blue}`,
  },
  "& .decline": {
    background: colors.warning,
    borderRadius: "8px",
    color: `${colors.white}`,
    border: `1.5px solid `,
  },
  "& .decline: hover": {
    background: colors.white,
    color: colors.warning,
    border: `1.5px solid ${colors.warning}`,
  },
}));

export const IpdActionCell = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  "& .actionButton": {
    cursor: "pointer",
    padding: "8px",
    borderRadius: "8px", 
    color: `${colors.white}`,
  },
  "& .delete": {
    background: `${colors.warning}`,
    marginRight: '16px'
  },
  "& .delete: hover": {
color: `${colors.warning}`,
background: `${colors.white}`
  },
  "& .edit": {
    background: `${colors.primary}`
  },
  "& .edit: hover": {
    background: colors.textColor,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

export const ButtonWithBorderStyle = styled(Button)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  textTransform: "none",
  border: `1px solid ${colors.blue}`,
}));

export const StyledTypeBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "row",
})(({ row }) => ({
  background:
    types[row.type].toLowerCase() === "letter"
      ? "rgba(255, 205, 78, 0.2)"
      : types[row.type].toLowerCase() === "report"
      ? "rgba(11, 139, 202, 0.2)"
      : "rgba(93, 206, 193, 0.2)",
  borderRadius: 12,
  width:
    types[row.type].toLowerCase() === "letter"
      ? "55px"
      : types[row.type].toLowerCase() === "report"
      ? "62px"
      : "99px",
  color:
    types[row.type].toLowerCase() === "letter"
      ? "#F0AF06"
      : types[row.type].toLowerCase() === "report"
      ? "#0B8BCA"
      : "#389A8F",
  padding: 8,
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "18px",
  padidng: 8,
}));

export const StyledCalender = styled(DateCalendar)(() => ({
  height: "230px",
  "& .MuiPickersCalendarHeader-root": {

    ".MuiPickersCalendarHeader-label": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "18px",
      color: colors.grey1,
    },
  },
  "& .MuiPickersDay-today": {
    '&.Mui-selected': {
      background: colors.primary,
    }
  },
  '& .MuiDayCalendar-weekDayLabel': {
    width: '24px',
    height: '24px',
    margin: '0px 4px',
  },
  '& .MuiPickersDay-root': {
    width: '24px',
    height: '24px',
    margin: '0px 4px',    
  },
}));

export const UserImage = styled("img")`
  height: 88px;
  width: 88px;
  border-radius: 50%;
`;
export const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const MainContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerCentreStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

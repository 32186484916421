import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { commonMuiStyles } from "../../assets/styles/CommonMUIStyles";
import colors from "../../constant/colors";
import {
  StyledCrossIcon,
  StyledSecondaryButton,
  StyledSlotDivider,
} from "../../assets/styles/CommonStyles";
import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import strings from "../../constant/strings";
import { Formik } from "formik";
import FieldWrapper from "../InputField/TextFieldWrapper";
import {
  MEDICINE_FORM_ENUM,
  QUANTITY_ENUM,
  STRENGTH_TYPE_ENUM,
} from "../../constants";
import SelectionDropdown from "../control/SelectionDropdown";
import { medicationStyles } from "../../../src/assets/styles/MedicationStyles";
import { capitalizeFirstLetter } from "../../constant/staticHelper";
import { addMedicineSchema } from "../../utils/forms/schema.addMedicine";

function AddMedication({ setOpenModal, selectedListItem }) {
  const classes = commonMuiStyles();
  const medicationClasses = medicationStyles();
  const [onLoading, setOnLoading] = useState(false);

  let initialValues = {
    name: selectedListItem?.name || "",
    form: selectedListItem?.form || "",
    strength: selectedListItem?.strength || "",
    strengthType: selectedListItem?.strengthType || "",
    duration: selectedListItem?.duration || "",
  }

  const strengthList = STRENGTH_TYPE_ENUM.map((item) => ({
    label: capitalizeFirstLetter(item.replace(/_/g, " ").toLowerCase()),
    level: item,
  }));

  const medicineList = MEDICINE_FORM_ENUM.map((item) => ({
    label: capitalizeFirstLetter(item.replace(/_/g, " ").toLowerCase()),
    level: item,
  }));

  const timeIntervalList = QUANTITY_ENUM.map((item) => ({
    label: capitalizeFirstLetter(item.replace(/_/g, " ").toLowerCase()),
    level: item,
  }));

  const handleSubmit = (values, { resetForm }) => {
    setOnLoading(true);
    resetForm();
    setTimeout(() => {
      setOnLoading(false);
      setOpenModal(false);
    }, 3000);
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={0}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Typography className={classes.fontText24} color={colors.grey1}>
              {strings.medications}
            </Typography>
            <StyledCrossIcon
              src={"/assets/img/x.png"}
              alt="cross"
              onClick={() => setOpenModal(false)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledSlotDivider />
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={addMedicineSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                handleSubmit,
                errors,
                values,
                setFieldValue,
                setTouched,
                touched,
                resetForm,
              }) => {
                const handleDropdownValue = (fieldName, value) => {
                  setFieldValue(fieldName, value.level);
                };
                return (
                  <>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12}>
                        <FieldWrapper
                          name="name"
                          type="text"
                          label="Enter medicine name"
                          value = {values.name}
                        ></FieldWrapper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <SelectionDropdown
                          height={"36px"}
                          name={`form`}
                          label={`${strings.formOfMedication}`}
                          list={medicineList}
                          value = {values.form}
                          className={medicationClasses.customAutocomplete}
                          handleDropdownValue={(data) =>
                            handleDropdownValue(`form`, data)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <FieldWrapper
                          name="strength"
                          type="text"
                          label="Enter medicine strength"
                        ></FieldWrapper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <SelectionDropdown
                          height={"36px"}
                          name={`strengthType`}
                          label={`${strings.enterStrengthType}`}
                          list={strengthList}
                          className={medicationClasses.customAutocomplete}
                          handleDropdownValue={(data) =>
                            handleDropdownValue(`strengthType`, data)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <SelectionDropdown
                          height={"36px"}
                          name={`duration`}
                          label={`${strings.selectTime}`}
                          list={timeIntervalList}
                          value = {values.duration}
                          className={medicationClasses.customAutocomplete}
                          handleDropdownValue={(data) =>
                            handleDropdownValue(`duration`, data)
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        alignItems="center"
                        display="flex"
                        justifyContent={"center"}
                      >
                        <StyledSecondaryButton
                          name="submit"
                          disabled={Object.keys(errors).length > 0 || onLoading}
                          onClick={handleSubmit}
                          variant="contained"
                        >
                          {onLoading ? (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={20}
                              thickness={5}
                            />
                          ) : (
                            "Save"
                          )}
                        </StyledSecondaryButton>
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
}

export default AddMedication;

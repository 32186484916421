import styled from 'styled-components';

export const StyledContainer = styled.div`
  .btns-group-patient {
    justify-content: space-around;
  }

  .bor-right-1 {
    border-right: 1px solid #ccc;
    padding-right: 20px;
  }

  .active-btn {
    background: #5156be;
    color: #fff !important;
  }
`;

export const StyledButton = styled.button`
    border: 2px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #5156be;
    font-weight: 500;
    cursor: pointer;
    background: #fff;
`;
import React from 'react';
import { useSelector } from "react-redux";
import Message from '../Message';

const DocMessages = () => {
    const { userData } = useSelector((state) => ({
        userData: state.auth.userData
    }));

    return (
        <Message userType={"doctor"} userData={userData} />
    )
}

export default DocMessages;
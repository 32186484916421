import React from 'react';
import { Link } from 'react-router-dom';

import {
	StyledBlogCard,
	StyledBlogYoutube,
	StyledYoutibeDoctors
} from './styles';

function BlogCard(props) {
	return (
		<StyledBlogCard className="blog">
			
			
			<h3 className="blog-title mt-3">
				<Link to={`/blogs/${props.item ? props.item.slugurl : ""}`}>{props.title}</Link>
			</h3>
			<div className="blog-info clearfix">
				{
					props.yotube ? <StyledBlogYoutube>
													<div className="post-left">
														<ul>
															<li><i className="far fa-clock"></i>{props.date}</li>
															<li><i className="fa fa-tags"></i>{props.totalViews}</li>
														</ul>
													</div>
													{/* <StyledYoutibeDoctors className="post-left">
														<h5>Doctors</h5>
														<div className="post-author">
															<a>
																<img src="/assets/img/doctors/doctor-thumb-01.jpg" alt="Post Author" />
																<span>{props.doctorName}</span>
															</a>
														</div>
														<div className="post-author">
															<a>
																<img src="/assets/img/doctors/doctor-thumb-01.jpg" alt="Post Author" />
																<span>{props.doctorName}</span>
															</a>
														</div>
														<div className="post-author">
															<a>
																<img src="/assets/img/doctors/doctor-thumb-01.jpg" alt="Post Author" />
																<span>{props.doctorName}</span>
															</a>
														</div>
													</StyledYoutibeDoctors> */}
												 </StyledBlogYoutube>
											 : <div className="post-left">
														<ul>
															<li>
																<div className="post-author">
																	<Link to={'/blogs/oxford-universitys-vaccine'}>
																		<img src={props.image} alt="Post Author" />
																		<span>{props.doctorName}</span>
																	</Link>
																</div>
															</li>
															<li><i className="far fa-clock"></i>{props.date}</li>
															<li><i className="fa fa-tags"></i>{props.totalViews}</li>
														</ul>
													</div>
				}
				
			</div>
			{
				props.yotube ? <div className="video">
												<iframe src={props.video} width="940" allowfullscreen></iframe>
											</div>
										: <div className="blog-image">
													<Link to={`/blogs/${props.item ? props.item.slugurl : ""}`}>
														<img className="img-fluid" src={props.blogImage} alt="Post Image" />
													</Link>
												</div>
			}
			<div className="blog-content">
				{props.children}
			</div>
		</StyledBlogCard>
	)
}

export default BlogCard

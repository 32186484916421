import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import InputField from '../../../../../components/InputField';
import "react-datepicker/dist/react-datepicker.css";
import './index.css'
import { toast } from 'react-toastify';
import { globalValidator } from "../../../../../utils";
import { Context } from '../../../../../store';

import {
    SET_ERROR,
	SET_WARD_PATIENT_DETAILS,
} from '../../../../../store/types';
import { addPatientToWard } from '../../../../../api/organization';

import {
	StyledContainer,
	StyledHeader,
	StyledAddEditPatient
} from './styles';
import PdfPreview from '../../../../../components/PdfPreview';

function PatientHistory (props) {
	const [state, dispatch] = useContext(Context);
    const {patientDetails, setPatientDetails, SetCurrentTab} = props;
    const [previewPdf,setPreviewPdf] = useState(false);

    const handlechiefComplaints = (e,id,type) => {
        let data;
        switch(type){
            case 'surgicalHistory':
                data = patientDetails.signification;
                data[id] = e.target.value;
                setPatientDetails({...patientDetails,signification:data});
                return;
            case 'familyHistory':
                data = patientDetails.familyHistory;
                data[id] = e.target.value;
                setPatientDetails({ ...patientDetails, familyHistory: data });
                return;
            case 'alcohalHistory':
                data = patientDetails.historyOfAlcohalism;
                data[id] = e.target.value;
                setPatientDetails({ ...patientDetails, historyOfAlcohalism: data });
                return;
            default:
                return
        }
    }

    const handleAddchiefComplaints = (itemType) => {
        let data;
        switch(itemType){
            case 'surgicalHistory':
                data = patientDetails.signification[patientDetails.signification.length-1];
                if(data==='')
                {
                    return;
                }
                setPatientDetails({...patientDetails,signification:[...patientDetails.signification,'']})
                return
            case 'familyHistory':
                data = patientDetails.familyHistory[patientDetails.familyHistory.length-1];
                if(data==='')
                {
                    return;
                }
                setPatientDetails({...patientDetails,familyHistory:[...patientDetails.familyHistory,'']})
                return
            case 'alcohalHistory':
                data = patientDetails.historyOfAlcohalism[patientDetails.historyOfAlcohalism.length-1];
                if(data==='')
                {
                    return;
                }
                setPatientDetails({...patientDetails,historyOfAlcohalism:[...patientDetails.historyOfAlcohalism,'']})
                return
            default:
        }
    }

    const handleDeleteComplaints = (id,itemType) => {
        let data;
        switch(itemType){
            case 'surgicalHistory':
                data = patientDetails.signification;
                data.splice(id,1);
                setPatientDetails({ ...patientDetails, signification: data });
                return
            case 'familyHistory':
                data = patientDetails.familyHistory;
                data.splice(id,1);
                setPatientDetails({ ...patientDetails, familyHistory: data });
                return
            case 'alcohalHistory':
                data = patientDetails.historyOfAlcohalism;
                data.splice(id,1);
                setPatientDetails({ ...patientDetails, historyOfAlcohalism: data });
                return
            default:
        }
        
    }
	const Submit = (e) => {
		e.preventDefault();
        const data = localStorage.getItem('subuser')
        const id = JSON.parse(data)._id
        patientDetails.addmitBy = id;
        patientDetails.patient = props.match.params.id;
        addPatientToWard(patientDetails).then(res=>{
            toast.success(res?.message || 'Patient admit successfully!');
            props.history.push('/subuser/ipdward');
        }).catch(err => {
			toast.error(err?.response?.data?.error);
            dispatch({
                type: SET_ERROR,
                payload: err?.response?.data?.error
            });
        })
	}

    const PreviewPdf = (e) => {
        e.preventDefault();
        setPreviewPdf(!previewPdf);
        // Preview Pdf
    }


	return (
		<StyledContainer>
			<div className="content">
				<div className="container-fluid">
                    {state.patient.patientIdE &&
                        <>
                            <i style={{ color: "#b7b74b", fontWeight: 'bold', fontSize: "18px" }} class="fas fa-plus"></i> <a style={{ color: "#6400ff", fontSize: "18px" }} target="_blank" href="https://emed4u.com/privacy-policy">Copy From Last OPD Visit</a>&nbsp;
                        </>
                    }
                    <div>
                        <div className="pt-3 no-padding form-group" >
                            <div style={{ display: "flex" }}>
                                <p style={{ fontSize: "18px", fontWeight: "500" }}>Signification Past Medical and Surgical History, id any</p>
                                <i onClick={()=>handleAddchiefComplaints('surgicalHistory')} class="fa fa-plus todo-add-icon" aria-hidden="true"></i>
                            </div>
                            <div style={{boxShadow: "0px 0px 5px 2px #e3dddd",borderRadius:"5px" }}>

                                <div style={{maxHeight:"200px",overflowY:"auto"}}>
                                    <table className="table table-bordered ">
                                        <thead className="thead-light to-do-thead" style={{position:"sticky",top:-1}}>
                                            <tr>
                                                <th>Enter one per row. Press Enter when doneor delete to remove selected row. </th>
                                                <th style={{maxWidth: "30px"}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{overflow:"scroll"}}>
                                            {patientDetails && patientDetails.signification.map((text, i) => {
                                                return (
                                                    <tr key={"item-" + i} className="to-do-table-row">
                                                        <td>
                                                            <InputField value={text} onChange={(e) => handlechiefComplaints(e, i, 'surgicalHistory')} />
                                                        </td>
                                                        <td style={{textAlign:'center'}}>
                                                            <i style={{margin:"auto",padding:"inherit",cursor:"pointer"}} class="fa fa-times" aria-hidden="true" onClick={()=>handleDeleteComplaints(i,'surgicalHistory')}></i>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3 no-padding form-group" >
                            <div style={{ display: "flex" }}>
                                <p style={{ fontSize: "18px", fontWeight: "500" }}>Family History if significant/relevent to diagnosis or treatment</p>
                                <i onClick={()=>handleAddchiefComplaints('familyHistory')}  class="fa fa-plus todo-add-icon" aria-hidden="true"></i>
                            </div>
                            <div style={{boxShadow: "0px 0px 5px 2px #e3dddd",borderRadius:"5px" }}>

                                <div style={{maxHeight:"200px",overflowY:"auto"}}>
                                    <table className="table table-bordered ">
                                        <thead className="thead-light to-do-thead" style={{position:"sticky",top:-1}}>
                                            <tr>
                                                <th>Enter one per row. Press Enter when doneor delete to remove selected row. </th>
                                                <th style={{maxWidth: "30px"}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{overflow:"scroll"}}>
                                            {patientDetails && patientDetails.familyHistory.map((text, i) => {
                                                return (
                                                    <tr key={"item-" + i} className="to-do-table-row">
                                                        <td>
                                                            <InputField value={text} onChange={(e) => handlechiefComplaints(e, i, 'familyHistory')} />
                                                        </td>
                                                        <td style={{textAlign:'center'}}>
                                                            <i style={{margin:"auto",padding:"inherit",cursor:"pointer"}} class="fa fa-times" aria-hidden="true" onClick={()=>handleDeleteComplaints(i,'familyHistory')}></i>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3 no-padding form-group" >
                            <div style={{ display: "flex" }}>
                                <p style={{ fontSize: "18px", fontWeight: "500" }}>History of alcohalism tabacco or substance abuse, if any</p>
                                <i onClick={()=>handleAddchiefComplaints('alcohalHistory')} class="fa fa-plus todo-add-icon" aria-hidden="true"></i>
                            </div>
                            <div style={{boxShadow: "0px 0px 5px 2px #e3dddd",borderRadius:"5px" }}>

                                <div style={{maxHeight:"200px",overflowY:"auto"}}>
                                    <table className="table table-bordered ">
                                        <thead className="thead-light to-do-thead" style={{position:"sticky",top:-1}}>
                                            <tr>
                                                <th>Enter one per row. Press Enter when doneor delete to remove selected row. </th>
                                                <th style={{maxWidth: "30px"}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{overflow:"scroll"}}>
                                            {patientDetails && patientDetails.historyOfAlcohalism.map((text, i) => {
                                                return (
                                                    <tr key={"item-" + i} className="to-do-table-row">
                                                        <td>
                                                            <InputField value={text} onChange={(e) => handlechiefComplaints(e, i, 'alcohalHistory')} />
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            <i style={{ margin: "auto", padding: "inherit", cursor: "pointer" }} class="fa fa-times" aria-hidden="true" onClick={() => handleDeleteComplaints(i,'alcohalHistory')}></i>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {previewPdf && <div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
                        <i style={{fontSize:"28px",right:"139px",margin:"18px",position:"absolute",color:"white"}} class="fa fa-times" aria-hidden="true" onClick={(e) => PreviewPdf(e)}></i>
                            <PdfPreview patientDetails={patientDetails} patientFormData = {state.patient}/>
                        </div>}

                        <StyledHeader className="col-md-4 ml-auto">
                            <StyledAddEditPatient onClick={(e) => PreviewPdf(e)}>
                                Preview 
                            </StyledAddEditPatient>
                            <StyledAddEditPatient onClick={(e) => Submit(e)}>
                                Submit
                            </StyledAddEditPatient>
                        </StyledHeader>
                    </div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default PatientHistory 


import { CircularProgress, Grid } from "@mui/material";
import React, { useState } from "react";
import { resetDoctoPassword } from "../../utils/forms/schema.doctorProfileSettingSchema";
import FieldWrapper from "../InputField/TextFieldWrapper";
import strings from "../../constant/strings";
import { profileStyles } from "../../assets/styles/DoctorProfileSettingStyles";
import { Formik } from "formik";
import { StyledSecondaryButton } from "../../assets/styles/CommonStyles";
import { useDispatch, useSelector } from "react-redux";
import PhoneField from "../PhoneField";
import SelectionDropdown from "../control/SelectionDropdown";
import CountryField from "../../components/CountryField";
import { orgProfile } from "../../utils/forms/schema.profileSettings";
import * as mainService from "../../services/mainService";
import { toast } from "react-toastify";
import { organizationProfileAction } from "../../redux/user/organization/organizationAction";

const OrgProfile = ({ onLoading, setLoading }) => {
  const profileSettingClasses = profileStyles();
  const dispatch = useDispatch();

  const { orgProfileData, userData } = useSelector((state) => ({
    orgProfileData: state.organization.orgProfileData,
    userData: state.auth.userData,
  }));

  let initialValues = {
    org_name: orgProfileData?.org_name || "",
    name: orgProfileData?.name || "",
    address: orgProfileData?.address || "",
    phone_number: orgProfileData?.phone_number || "",
    country_code: orgProfileData?.country_code || "",
    country: orgProfileData?.country || "india",
  };

  const handleSubmit = async (values) => {
    console.log("values",values)
    setLoading(true);
    const res = await mainService.updateProfileByOrganizationAPI({
      token: userData.token,
      data: values,
    });
   
    if (res.response_type === "fail") {
      setLoading(false);
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      setLoading(false);
      dispatch(organizationProfileAction(userData.token));
      toast.success("Profile updated successfully.", {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    }
    // if (file !== undefined) {
    //   const imageExtension = file.name.split(".");
    //   handleFileUploadToS3(
    //     file,
    //     `Doctor/Profile-Image/${
    //       userData.phone_number + "." + imageExtension[1]
    //     }`
    //   );
    //   values.profile_image = `Doctor/Profile-Image/${
    //     userData.phone_number + "." + imageExtension[1]
    //   }`;
    // }
  };

  return (
    <>
      <div className={`${profileSettingClasses.mainCont}`}>
        <Grid
          container
          spacing={2}
          className={profileSettingClasses.formikCont}
          width="70%"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={orgProfile}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
              setTouched,
              handleChange,
            }) => {
              const handlePhoneNumber = (number, code) => {
                setFieldValue("phone_number", number);
                setFieldValue("country_code", code);
              };

              return (
                <>
                  <Grid item lg={6} md={6} xs={12}>
                    <FieldWrapper
                      type="text"
                      name="org_name"
                      label={`${strings.orgName}`}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <FieldWrapper
                      type="text"
                      name="name"
                      label={`${strings.ownerName}`}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <PhoneField
                      name="phone_number"
                      label="Enter mobile number"
                      value={`${values.country_code} ${values.phone_number}`}
                      onChange={(value, { dialCode }) => {
                        handlePhoneNumber(
                          value.slice(dialCode.length),
                          dialCode
                        );
                      }}
                      onBlur={() =>
                        setTouched({ ...touched, phone_number: true })
                      }
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <CountryField
                      name="country"
                      type="text"
                      placeholder={
                        orgProfileData?.country || `${strings.country}`
                      }
                      onChange={(e) => {
                        values.country = e;
                        console.log("e", e);
                      }}
                      onBlur={() => setTouched({ ...touched, country: true })}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper
                      type="text"
                      name="address"
                      label={`${strings.selectAddress}`}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <StyledSecondaryButton
                      disabled={Object.keys(errors).length > 0}
                      style={{ marginBottom: 24 }}
                      onClick={handleSubmit}
                    >
                      {onLoading ? (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={20}
                          thickness={5}
                        />
                      ) : (
                        " Update Profile"
                      )}
                    </StyledSecondaryButton>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </Grid>
      </div>
    </>
  );
};

export default OrgProfile;

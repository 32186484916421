import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {
	Link
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { StyledContainer } from '../Appointments/ViewAppointment/styles';
import { Context } from '../../../../store';
import {
	getMedicalHistoryDataAPI,
	getPatientWardRoomById
} from '../../../../api/doctor';
import { 
	getPatientInfo,
	getPatientFollowUpDetails,
	addPatientFollowUpForm,
	getIpdHistoryOfPatient,
	getAppointmentHistory,
} from '../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import HealthRecordsChart from '../../../SubAdmin/Components/HealthRecordsChart';
import PatientInfo from '../../../SubAdmin/Components/PatientInfo';

import AppointmentCard from '../../../../components/Appointments';
import MedicalHistoryComponent from '../../../../components/medicalHistory';
import ServiceList from '../../../../components/Service';
import IpdList from '../../../../components/IpdList';
import DoctorDischargeModal from '../../../../components/Discharge/DoctorDischarge';
import FollowUpCarousel from '../../../../components/Carousel';


import '../../components/doctor.css';
import '../Appointments/ViewAppointment/appointment.css';
import ECGchart from '../../../../components/EcgGraph';
import Card from '../../../../components/Card';
import MedicalReports from '../../../SubAdmin/Panel/ViewPatient/MedicalReports';

function DoctorViewIPD(props) {
	const [state, dispatch] = useContext(Context);
	const [id, setId] = React.useState(null);
	const [patientId, setPatientId] = React.useState(null);
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);
	const [modalVisible, setModalVisible] = React.useState(false);
	const [ipdHistory, setIpdHistory] = React.useState([]);
	const [permission, setPermission] = React.useState('');
	const [PatientFollowUpDetails,setPatientFollowUpDetails] = React.useState([]);
	const [patientDetails, setPatientDetails] = React.useState({});
	const [patientData, setPatientData] = React.useState({});
	const [ecgWidth, setEcgWidth] = useState();
	const [patientInfo, SetPatientInfo] = React.useState({
		dob: '',
		gender: '',
		phoneNumber: '',
		name: '',
		address: '',
		profileImage: null,
		org_name: '',
		weight: '',
		_id: null
	});


	const [appointmenthistory, setAppointmentHistory] = React.useState([]);
	const [appointment, setAppointment] = React.useState({
		OrganizationId: null,
		SecondOpinionId: {},
		amount: null,
		appointmentType: null,
		conversions: [],
		createdAt: null,
		doctorId: {},
		patientId: {},
		slot: {},
		status: 0,
		_id: "601c0dfdfcfe01d6ad88b3ed"
	});

	const [medicalHistory, setMedicalHistory] = React.useState({
		allergieData: [],
		healthConditionData: [],
		medicationData: [],
		surgeriesData: [],
		vaccinationData: []
	});

	const fetchPatientFollowUpDetails =  (id) => {
		return new Promise((resolve, reject) => {
			getPatientFollowUpDetails(id).then((res) => {
				setPatientFollowUpDetails(res.data.reverse())
				resolve('Success')
			}).catch((err) => {
				reject('Error')
				// toast.error(err.response.data.error)
			})
		})
	}

	const fetchMedicalHistory = (patientId) => {
		return new Promise((resolve,reject) => {
			getMedicalHistoryDataAPI(patientId).then(res => {
				setMedicalHistory(res.data);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				resolve('Success')
			}).catch(err => {
				reject('Error')
			})
		})
	}

	const fetchPatientInfo = (patientId) => {
		return new Promise((resolve, reject) => {
			getPatientInfo(patientId).then((res) => {
				SetPatientInfo({
					dob: res.data.dob,
					email: res.data.email,
					gender: res.data.gender,
					phoneNumber: res.data.phoneNumber,
					name: res.data.name,
					address: res.data.address,
					profileImage: res.data.profileImage,
					org_name: res.data.org_name,
					_id: res.data._id,
					weight: res.data.weight,
					bloodGroup: res.data.bloodGroup
				})
				setPatientData(res.data);
				resolve('Success')
			}).catch(err => {
				reject('Error')
				toast.error(err.response?.data?.error)
			})
		})
	}

	const fetchPatientWardRoom = (id) => {
		return new Promise((resolve, reject) => {
			getPatientWardRoomById(id).then((res) => {
				setPatientDetails(res.data);
				resolve('Success')
			}).catch((err) => {
				reject('Error')
			})
		})
	}

	const fetchIpdHistoryOfPatient = (patientId) => {
		return new Promise((resolve, reject) => {
			getIpdHistoryOfPatient(patientId).then((res) => {
				const filterIpd = res.data.filter((item)=>item._id !== props.match.params.id);
				const updateFilterIpd = filterIpd.filter((item)=>item.dischargeByDoctor !== false);
				setIpdHistory(updateFilterIpd)
				resolve('Success')
			}).catch((err) => {
				reject('Error')
			})
		})
	}

	const fetchAppointments = (patientId) => {
		return new Promise((resolve,reject) => {
			getAppointmentHistory(patientId).then((res) => {
				const todayDate = moment().add(1, 'days').format('YYYY-MM-DD')
				const upcomingAppointment = res?.data?.filter((item) => moment(item?.slot?.start)?.utc()?.format('YYYY-MM-DD') < todayDate)
				setAppointmentHistory(upcomingAppointment)
				resolve('Success')
			}).catch((err)=>{
				reject('Error')
			})
		})
	}


	useEffect(() => {
		(async function () {
			try {
				const data = await JSON.parse(localStorage.getItem('doctor'));
				if (data.permission.includes("IPD") && data.permission.includes("OPD")) {
					setPermission('ADMIN');
				} else if (data.permission.includes("IPD")) {
					setPermission('IPD');
				} else {
					setPermission('OPD');
				}
				if (props.match.params && props.match.params.id) {
					setId(props.match.params.id);
					setPatientId(props.match.params.patientId);
					loader(true);
					const promise = await Promise.allSettled([fetchPatientFollowUpDetails(props.match.params.id), 
						fetchMedicalHistory(props.match.params.patientId), fetchPatientInfo(props.match.params.patientId), 
						fetchPatientWardRoom(props.match.params.id),fetchIpdHistoryOfPatient(props.match.params.patientId),
						fetchAppointments(props.match.params.patientId)
					]);
					loader(false);
				}
			} catch {
				loader(false);
			}
		}());
	}, [props.match.params.id])

	
	useEffect(()=>{
		const k = document.getElementById('ipd-ecg-chart').offsetWidth;
		setEcgWidth(k-20)
	},[])
	

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const addPatientFollowUp = () => {
		const data = {
			diagnosis: '',
			medication: [],
			lab: [],
			radiology: [],
			nursingMorning: [],
			nursingAfternoon: [],
			nursingEvening: [],
			nursingNight: [],
			doctorMorning: [],
			doctorAfternoon: [],
			doctorEvening: [],
			doctorNight: [],
			followUpDate: new Date(),
			patient: patientId
		}
		addPatientFollowUpForm(id,data).then((res) => {
			getPatientFollowUpDetails(props.match.params.id).then((res) => {
				setPatientFollowUpDetails(res.data.reverse())
				forceUpdate();
			}).catch((err) => {
				toast.error(err.response.data.error)
			})
		}).catch((err) => { })
	}
	const openIpdPatientView = (id) => {
		props.history.push(`/doctor/view-ipd-patient/${id}/${props.match.params.patientId}`)
	}
	const todayExists = () => {
		let exists = false
		PatientFollowUpDetails && PatientFollowUpDetails.map((data) => {
			if(moment(data.followUpDate).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY") || patientDetails?.dischargeByDoctor ){
				exists =  true;
			}
		})
		return exists;
	}
	const handleClickAppointment = (data) => {
		props.history.push(`/doctor/view-appointment/${data?._id}/${data?.patientId?._id}`);
	}

	return (
		<div>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="col-md-12 row">
							<Link
								to={'/doctor/schedule'}
								style={{
									border: '1px solid #5156be',
									borderRadius: '5px',
									padding: '10px',
									marginBottom: '20px',
									color: '#5156be',
									fontSize: '17px',
									fontWeight: '500'
								}}
							>
								<i className="fa fa-arrow-left pr-2" />
								Back To Dashboard
							</Link>
							<div className='ml-auto d-flex hb-mobile-screen-ipd-active'>
									{!todayExists() && (permission === 'IPD' ||  permission === 'ADMIN') && !patientDetails?.dischargeByDoctor ?
										<div className="col-md-1">
											<button style={{ marginLeft: 'auto' }} className="doctor-active-btn doctor-w-20 mb-4 ml-auto" onClick={addPatientFollowUp}>Add </button>
										</div> : null
									}
									{!patientDetails?.dischargeByDoctor  && (permission === 'IPD' ||  permission === 'ADMIN') ? 
										<button style={{ marginLeft: 'auto' }} className="doctor-active-btn doctor-w-20 mb-4 ml-auto" onClick={() => {
											setModalVisible(true);
											document.body.style.overflowY = "hidden"
										}}>Discharge </button> :null
									}
								</div>
							</div>
							<h3 className="mb-4 mt-4">Patient IPD details</h3>

							<StyledContainer>
								<div className="content">
									<div className="container-fluid pr-0">
										<div className="container-fluid-card row d-flex col-md-12">
											<div className='col-md-9 mobile-screen-medical-history-container'>
												<div className="col-md-12">
													

												</div>
												<FollowUpCarousel
													PatientFollowUpDetails={PatientFollowUpDetails}
													// desigantion={desigantion}
													fetchPatientFollowUpDetails={fetchPatientFollowUpDetails}
													detailsId={id}
													setPatientFollowUpDetails={setPatientFollowUpDetails}
													patientDetails={patientDetails}
													doctorObservation = {true}
													permission={permission}
													todayExists={todayExists}
												/>
												<HealthRecordsChart
													showAdd={false}
													patientId={props.match.params.patientId}
													showLipidProfile={true}
												/>
												<div className="p-3 mobile-screen-card-main">
													<h2 className = "cards-main-heading mb-3">Medical History</h2>
													<MedicalHistoryComponent medicalHistory={medicalHistory} />
												</div>
											</div>
											<div className='col-md' style={{ padding: "0px" }}>
												<PatientInfo patientInfo={patientInfo} {...props} showInfoButton = {true} appointment={appointment} patientDetails={patientDetails}/>
												<ServiceList
													data={patientDetails.services}
													id={id}
													setPatientDetails={setPatientDetails}
													patientDetails={patientDetails}
													showAddButton = {(permission === 'ADMIN' || permission === 'IPD') && !patientDetails?.dischargeByDoctor  }
												/>
												<AppointmentCard
													heading='Previous appointments'
													data={appointmenthistory}
													keyItem="Previous"
													handleClickAppointment={handleClickAppointment}
												/>
												<IpdList data={ipdHistory} openIpdPatientView={openIpdPatientView}/>
												<Card className='p-2 ecg-min-height' id="ipd-ecg-chart">
													{ecgWidth && props.match.params.patientId && <ECGchart
														width={ecgWidth}
														id={props.match.params.patientId}
													/>}
												</Card>
												<MedicalReports patiendId={props.match.params.patientId} />

												{/* <IpdList data={ipdHistory} openIpdPatientView={openIpdPatientView}/> */}
											</div>
										</div>
										</div>
									</div>
									{/* <PatientInfoModal/> */}
							</StyledContainer>
							
						</div>

					</div>
				</div>
			</div>
			
            {
                modalVisible &&
               <DoctorDischargeModal id={id} setPatientDetails={setPatientDetails} patientDetails={patientDetails} setModalVisible={setModalVisible} PatientFollowUpDetails={PatientFollowUpDetails} loader={loader}/>
            }
		</div>
	)
}

export default DoctorViewIPD

import React, { useState, useEffect } from 'react';
import { ContainerStyle, LoadingContainer, NoDetailsContainer, StyledCrossIcon, StyledLogo } from '../../../assets/styles/CommonStyles';
import string from '../../../constant/strings'
import { Heading, MainContainer, TextStyle, TopContainer } from './styles';
import { useStyles } from '../../../assets/styles/SidebarStyles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { patientInvoiceListAction } from '../../../redux/user/userActions';
import Tables from '../../../components/controls/Tables';
import { PatientBillingInvoiceList } from '../../../constant/staticLists';
import strings from '../../../constant/strings';
import { dashoboardUseStyles } from '../../../assets/styles/DoctorDashboardStyles';


export default function BillingInvoice() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const dashboardClass = dashoboardUseStyles();
  const [page, setPage] = useState(1);
  const { userData, patientInvoiceList, patientAppointmentInfoListLoader } = useSelector(state => ({
    userData: state.auth.userData,
    patientInvoiceList: state.user.patientInvoiceList,
    patientAppointmentInfoListLoader: state.user.patientAppointmentInfoListLoader
  }))

  const handleCross = () => {
    history.goBack();
  }

  useEffect(() => {
    dispatch(patientInvoiceListAction({ token: userData.token }))
  }, [])

  return (
    <>
      <TopContainer>
        <img src={'/assets/img/image1.png'} alt='logo' className={classes.iconStyle} />
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="x"
          onClick={handleCross}
        />
      </TopContainer>
      <MainContainer>
        <Heading>{string.billingAndInvoice}</Heading>
        <TextStyle>{string.billingContent}</TextStyle>
       { patientAppointmentInfoListLoader  ? <LoadingContainer>{strings.loading}</LoadingContainer> : !! patientInvoiceList && patientInvoiceList.length > 0 ? <Tables
          data={patientInvoiceList?.slice((page - 1) * 10, page * 10)}
          fieldsOrder={PatientBillingInvoiceList}
          list = {patientInvoiceList}
          page={page}
          setPage={setPage}
        /> : 
        <NoDetailsContainer className={dashboardClass.noDataTextStyle}>{strings.noInvoiceAvailable}</NoDetailsContainer>}
      </MainContainer>
    </>
  )
}
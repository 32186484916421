import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { cancelAppointmentFailure, cancelAppointmentRequest, cancelAppointmentSuccess, getDoctorSlotListFailure, getDoctorSlotListRequest, getDoctorSlotListSuccess, getdoctorListFailure, getdoctorListSuccess, getpatientAppointmentListFailure, getpatientAppointmentListRequest, getpatientAppointmentListSuccess } from './patientAppointmentSlice';
import { cancelAppointmentAction, getDoctorListAction, getDoctorSlotsAction, getPatientAppointmentAction } from './patientAppointmentActions';
import { cancelAppointmentByPatientApi, getDoctorlistApi, getPatientAppointment, slotListAPI } from '../../../services/mainService';

function* getAppointmentList(action) {
  try {
    yield put(getpatientAppointmentListRequest());     
    const apiResponse = yield call(getPatientAppointment, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const medicalData = apiResponse.response.data;
        yield put(getpatientAppointmentListSuccess(medicalData));

      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(getpatientAppointmentListFailure(apiResponse.response.message))
      }
    }
  } catch (error) {  }
}

function* getDoctorList(action) {
  try {
    const apiResponse = yield call(getDoctorlistApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const listData = apiResponse.response.data;
        yield put(getdoctorListSuccess(listData));

      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(getdoctorListFailure(apiResponse.response.message))
      }
    }
  } catch (error) { }
}

function* getDoctorSlotList(action) {
  try {
     yield put(getDoctorSlotListRequest()); 
    const apiResponse = yield call(slotListAPI, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const listData = apiResponse.response.data;
        yield put(getDoctorSlotListSuccess(listData));

      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(getDoctorSlotListFailure(apiResponse.response.message))
      }
    }
  } catch (error) { }
}

function* cancelAppointmentByPatient(action) {
  try {
     yield put( cancelAppointmentRequest()); 
    const apiResponse = yield call( cancelAppointmentByPatientApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const listData = apiResponse.response.data;
        yield put( cancelAppointmentSuccess(listData));

      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(cancelAppointmentFailure(apiResponse.response.message))
      }
    }
  } catch (error) { }
}

export function* appointmentSaga() {
  yield takeLatest(getPatientAppointmentAction.type, getAppointmentList);
  yield takeLatest(getDoctorListAction.type, getDoctorList);
  yield takeLatest(getDoctorSlotsAction.type, getDoctorSlotList);
  yield takeLatest(cancelAppointmentAction.type, cancelAppointmentByPatient);
}

import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    fontWeight: 'light',
    paddingTop: "20px",
    paddingBottom: "40px"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  drView: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0px',
    borderBottom: '2px solid #D3D0CF'
  },
  patientView: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0px 20px 0px',
    borderBottom: '2px solid #D3D0CF',
    fontSize: '12px',
  },
  drDetailsSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  boldtext: {
    fontWeight: '700',
  },
  subHeading: {
    fontWeight: 'bold',
    fontSize: "14px"
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: {
    borderTop: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },
  row1: {
    width: '10%',
    padding: '0 10px',
    wordWrap: "break-word",
    whiteSpace: 'pre-wrap',

  },
  row2: {
    width: '30%',
    padding: '0 10px',
    wordWrap: "break-word",
    whiteSpace: 'pre-wrap',
  },
  row3: {
    width: '22%',
    padding: '0 10px',
    wordWrap: "break-word",
    whiteSpace: 'pre-wrap',
  },
  row4: {
    width: '20%',
    padding: '0 10px',
    wordWrap: "break-word",
    whiteSpace: 'pre-wrap',
  },
  row5: {
    width: '22%',
    padding: '0 10px',
    wordWrap: "break-word",
    whiteSpace: 'pre-wrap',
  },
  row6: {
    width: '70%',
    padding: '0 10px',
    wordWrap: "break-word",
    whiteSpace: 'pre-wrap',
  },
  row10: {
    width: '90%',
    padding: '0 10px',
    wordWrap: "break-word",
    whiteSpace: 'pre-wrap',

  },
});
const DischargePreview = (props) => {
  const { patientDetails } = props;
  return (
    <PDFViewer width="100%" height="100%">
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.drView}>
              <View>
                <Text style={{ letterSpacing: "3px", fontWeight: 'heavy', fontSize: "40px", color: 'green' }}>EMED4U</Text>
                <Text style={{}}>Health & care to your doorstep</Text>
              </View>
              <View style={{ marginLeft: "auto" }}>
                <Text style={{ color: 'black', fontWeight: 'hairline' }}>Emed4u</Text>
                <View style={{ flexDirection: 'row' }}><Text style={{ fontWeight: 'demibold' }}>Address : </Text><Text style={{}}>Chandigarh, India</Text></View>
                {/* <View style={{ flexDirection: 'row' }}><Text>Contact number : </Text><Text style={{}}>7658064535</Text></View> */}
              </View>
            </View>
            {/*patientDetails && patientDetails.dischargeDiagnosis && <View style={styles.patientView}>
              <View>
                <Text style={{ marginTop: "15px", fontSize: "16px", fontWeight: 600 }}>Final Diagnosis</Text>
              </View>
              <View style={{ flexDirection: 'row', padding: "10px", fontSize: "12px" }}>
                <View style={styles.table}>
                  <View style={[styles.row, styles.bold, styles.header]}>
                    <Text style={styles.row1}>No</Text>
                    <Text style={styles.row10}>Diagnosis and observation</Text>
                  </View>
                  {patientDetails && patientDetails.dischargeDiagnosis && patientDetails.dischargeDiagnosis.map((row, i) => (
                    <View key={i} style={styles.row} wrap={false}>
                      <Text style={styles.row1}>
                        <Text style={styles.bold}>{i + 1}</Text>
                      </Text>
                      <Text style={styles.row10}>{row}</Text>
                    </View>
                  ))}
                </View>
              </View>
                  </View> */}
            {/*
              patientDetails && patientDetails.dischargeMedication &&
              <View style={styles.patientView}>
                <View>
                  <Text style={{ marginTop: "15px", fontSize: "16px", fontWeight: 600 }}>Discharge Medication</Text>
                </View>
                <View style={{ flexDirection: 'row', padding: "10px", fontSize: "12px" }}>
                  <View style={styles.table}>
                    <View style={[styles.row, styles.bold, styles.header]}>
                      <Text style={styles.row1}>No</Text>
                      <Text style={styles.row2}>Medication Name</Text>
                      <Text style={styles.row3}>Form of Medicine</Text>
                      <Text style={styles.row4}>Strength</Text>
                      <Text style={styles.row5}>Strength type</Text>
                    </View>
                    {patientDetails && patientDetails.dischargeMedication && patientDetails.dischargeMedication.map((row, i) => (
                      <View key={i} style={styles.row} wrap={false}>
                        <Text style={styles.row1}>
                          <Text style={styles.bold}>{i + 1}</Text>
                        </Text>
                        <Text style={styles.row2}>{row.medication_name}</Text>
                        <Text style={styles.row3}>{row.medicine_type}</Text>
                        <Text style={styles.row4}>
                          <Text style={styles.bold}>{row.strength_name}</Text>
                        </Text>
                        <Text style={styles.row5}>{row.strength_type}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
           */}

            {
              patientDetails && patientDetails && <View style={styles.patientView}>
                <View>
                  <Text style={{ marginTop: "15px", fontSize: "16px", fontWeight: 600 }}>Discharge Summary</Text>
                </View>
                <View style={{ flexDirection: 'row', padding: "10px", fontSize: "12px" }}>
                  <View style={styles.table}>
                    <View style={[styles.row, styles.bold, styles.header]}>
                      <Text style={styles.row2}>Daily visiting :</Text>
                      <Text style={styles.row6}>{moment(patientDetails.dischargeDate).format('Do MMM, YYYY')}{" "}{moment(patientDetails.dischargeDate).format('hh:mm a')}</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.header]}>
                      <Text style={styles.row2}>{patientDetails.dischargeDiagnosisNotes ? 'Final diagnosis notes' : 'Summary'} :</Text>
                      <Text style={styles.row6}>{patientDetails.dischargeDiagnosisNotes}</Text>
                    </View>
                  </View>
                </View>
              </View>
            }


            <View style={styles.patientView}>
              <View>
                <Text style={{ marginTop: "15px", fontSize: "16px", fontWeight: 600 }}>Service Of IPD</Text>
              </View>
              <View style={{ flexDirection: 'row', padding: "10px", fontSize: "12px" }}>
                <View style={styles.table}>
                  <View style={[styles.row, styles.bold, styles.header]}>
                    <Text style={styles.row1}>No</Text>
                    <Text style={styles.row2}>Date & Time</Text>
                    <Text style={styles.row3}>Service</Text>
                    <Text style={styles.row4}>Charges</Text>
                    <Text style={styles.row2}>Added By</Text>
                  </View>
                  {patientDetails && patientDetails.services.map((row, i) => (
                    <View key={i} style={styles.row} wrap={false}>
                      <Text style={styles.row1}>
                        <Text style={styles.bold}>{i + 1}</Text>
                      </Text>
                      <Text style={styles.row2}>{moment(row.serviceDate).format('Do MMM, YYYY')}{" "}{moment(row.serviceDate).format('hh:mm a')}</Text>
                      <Text style={styles.row3}>{row.serviceName}</Text>
                      <Text style={styles.row4}>
                        <Text style={styles.bold}>{row.serviceAmount}</Text>
                      </Text>
                      <Text style={styles.row2}>{row.serviceAddedBy || '-'}</Text>
                    </View>
                  ))}
                </View>
              </View>
              <View style={{ marginLeft: "auto", marginTop: "30px" }}>
                {
                  patientDetails.dischargeDate !== '' &&
                  <View style={{ flexDirection: 'row', marginBottom: "10px" }}>
                    <Text style={{ marginRight: "20px", fontWeight: 'extrabold', width: "40%" }}>Discharge Date:</Text>
                    <Text>{moment(patientDetails.dischargeDate).format('Do MMM, YYYY')}{" "}{moment(patientDetails.dischargeDate).format('hh:mm a')}</Text>
                  </View>
                }
                {
                  patientDetails.totalAmountService !== '' &&
                  <View style={{ flexDirection: 'row', marginBottom: "10px" }}>
                    <Text style={{ marginRight: "20px", fontWeight: 'extrabold', width: "40%" }}>Amount:</Text>
                    <Text>{patientDetails.totalAmountService}</Text>
                  </View>
                }
                {
                  patientDetails.otherAmount !== '' &&
                  <View style={{ flexDirection: 'row', marginBottom: "10px" }}>
                    <Text style={{ marginRight: "20px", fontWeight: 'extrabold', width: "40%" }}>Other Amount:</Text>
                    <Text>{patientDetails.otherAmount}</Text>
                  </View>
                }
                {
                  patientDetails.tax !== '' &&
                  <View style={{ flexDirection: 'row', marginBottom: "10px" }}>
                    <Text style={{ marginRight: "20px", fontWeight: 'extrabold', width: "40%" }}>Tax (%): </Text>
                    <Text>{patientDetails.tax}</Text>
                  </View>
                }
                {
                  patientDetails.discount !== '' &&
                  <View style={{ flexDirection: 'row', marginBottom: "10px" }}>
                    <Text style={{ marginRight: "20px", fontWeight: 'extrabold', width: "40%" }}>Discount: </Text>
                    <Text>{patientDetails.discount}</Text>
                  </View>
                }
                {
                  patientDetails.paymentMode !== '' &&
                  <View style={{ flexDirection: 'row', marginBottom: "10px" }}>
                    <Text style={{ marginRight: "20px", fontWeight: 'extrabold', width: "40%" }}>Payment Mode: </Text>
                    <Text>{patientDetails.paymentMode}</Text>
                  </View>
                }

                {
                  patientDetails.totalPayableAmount !== '' &&
                  <View style={{ flexDirection: 'row', marginBottom: "10px", border: '1px solid black', padding: '10px' }}>
                    <Text style={{ marginRight: "20px", fontWeight: 'extrabold' }}>Payable Amount: </Text>
                    <Text>{patientDetails.totalPayableAmount}</Text>
                  </View>
                }
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: "40px" }}>
              <View>
                <Text>{patientDetails.doctor.name}</Text>
              </View>
              <View style={{ marginLeft: "auto" }}>
                <Text>Health Staff</Text>
                <Text>{patientDetails.doctor.department}</Text>
              </View>
            </View>
          </View>

        </Page>
      </Document>
    </PDFViewer>
  );
}

export default DischargePreview;
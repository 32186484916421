import React from "react";
import BillingInvoice from "../../../../pages/PatientPanel/BillingInvoice";



const SubadminBillingInvoice = () => {
  return (<>
  <BillingInvoice />
  </>);
};

export default SubadminBillingInvoice;
import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import Dialog from "@mui/material";
import DoctorSlotCreation from "../../components/ui/Doctor/DoctorSlotCreation"

const DoctorSlots = () => {
  return (
    <Grid container spacing={0}>
      <AppLayout>
        <DoctorSlotCreation />
      </AppLayout>
    </Grid>
  )
}

export default DoctorSlots;
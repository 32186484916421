import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import CardComponent from '../../components/Card';

function BillingReportLand() {
	return (
		<div>

			<Breadcrumb
				backRoute={[{id: 1, route: 'Home'}]}
				currentRoute='Billing & Reports'
				title='Billing & Reports' />

			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-lg-8">
							<CardComponent  className="p-4">
								<div className="widget about-widget">
									<h4>Billing & Reports</h4>
									<p>Keep track of your growing practice by collecting payments online. Manage online billing and revenue reports that help in tracking your practice revenue. It is an easy option to audit your practice, helping you identify what services work best for you.
									</p>
									<br />
									<h4>Online Payments and Billing</h4>
									<p>Accept payments for online and clinic services. Keep track of clinic revenue on Virtual Practice.</p>
									<br />
									<h4>Revenue from Health Services</h4>
									<p>When patients make payments for services availed, a bill is automatically emailed to them. Providers will be notified about payments received and can view billing reports to keep track of revenue generated.</p>
								</div>
							</CardComponent>
						</div>

						<div className="col-lg-4 features-img mt-1 ">
							<img src="/assets/img/land6.png" className="img-fluid" alt="Feature" />
						</div>

					</div>
				</div>
			</div>

		</div>
	)
}

export default BillingReportLand

import styled from 'styled-components';

export const StyledLangingWrapper = styled.div`

.mobile-screen-header {
  @media (max-width: 450px){
    font-size: 14px;
  }
}

  .emed4u-link {
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 20px;
    @media (max-width: 450px){
        font-size: 16px;
        margin-bottom: 20px;
    }
  }

  .emed4u-heading {
    font-weight: 500 !important;
    font-size: 64px !important;
    line-height: 94px !important;
    @media (max-width: 450px){
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 18px !important;
    }
  }

  .active-card-section {
    background-color: #19ce67;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 8px;
    
    h3, span {
        color: #fff;
    }

    button {
      background: #fff;
      color: #19ce67;  
    }
  }

  .blogEmed4u-img {
    width: 100%;
    height: 300px;
    border-top-right-radius : 10px;
    border-top-left-radius : 10px;
    img {
      width: 100%;
      height: 100%;
      border-top-right-radius : 10px;
      border-top-left-radius : 10px
    }
  }

  .blogEmed4u-content {
    padding: 30px;
    height: 270px;

    h5 {
      font-size: 20px;
    }

    button {
      margin-top: 20px;
      background: #17ce67;
      color: #fff;
      border: none;
      line-height: initial;
      text-transform: uppercase;
      border-radius: 30px;
      padding: 17px 50px 15.5px 25px;
      position: relative;
      -webkit-transition: 0.5s;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      font-size: 14px;
      font-family: "Rubik",sans-serif;
      font-weight: 500;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      font-weight: 600;
      position: relative;
      i {
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
        display: inline-block;
        height: 33px;
        width: 33px;
        line-height: 33px;
        color: #ffffff;
        border-radius: 50%;
        background-color: #8de9b2;
        -webkit-transition: 0.5s;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }
  }

  .bgapp-emed4u {
    background-image: linear-gradient(to right, #077fb7 , #28ab6b);
    .bgapp-emed4u-image {
      justify-content: center;
      text-align: center;
      padding-left: 20%;
      @media (max-width: 767px){ 
        padding-left: 0%;
        img {
          width: 100%;
        }
      }
    }
    .section-header  {
      padding-top: 5%;
      @media (max-width: 767px){ 
        padding-left: 10%;
      }
      @media (max-width: 450px){ 
        margin-bottom: 0 !important;
        padding: 0 !important;
      }
      h2 {
        color: #fff !important;
      }
    }
    .our-app-show {
      padding-left: 8%;
      @media (max-width: 767px){ 
        padding-left: 0%;
        display: grid;
        justify-content: center;
        .link-block {
          margin-top: 20px;
          @media (max-width: 450px){ 
            margin-top: 0 !important;
          }
        }
      }
      @media (max-width: 450px){ 
        display: flex !important;
      }
    }
  }

  .doctor-slider.slider {
    .full-slide-home .slick-prev {
      left: -10px !important;
    }
    .full-slide-home .slick-next {
       right: -10px !important;
    }
  }

  .lan-cer-emd {
    display: flex !important;
    @media (max-width: 767px){ 
      display: block !important;
    }

  }

  .cert-img {
    height: 200px;
    overflow: hidden;

    img {
      width: 400px;
      height: 100%;

      @media (max-width: 767px){ 
        width: 200px;
        height: 100%;
      }
    }

    @media (max-width: 767px){ 
      height: 150px;
    }
  }

  .cert-img-1 {
    height: 200px;
    overflow: hidden;

    img {
      width: 200px;
      height: 100%;

      @media (max-width: 767px){ 
        width: 200px;
        height: 100%;
      }
    }

    @media (max-width: 767px){ 
      height: 150px;
    }
  }

  .our-app-show {
    display: flex;

    .link-block-2 {
      width: 190px;
      height: 60px;
      margin-right: 20px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      border-radius: 10px;
      background-image: url(/assets/img/android.png);
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      @media (max-width: 450px){ 
        width: 80px;
        margin-right: 10px;
      }
    }
    .link-block {
      width: 190px;
      height: 60px;
      margin-right: 10px;
      padding-right: 0px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      border-radius: 10px;
      background-image: url(/assets/img/apple.png);
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      @media (max-width: 450px){ 
        width: 80px;
      }
    }
  }
  
`;

export const StyledSubTitle = styled.p`
  color: #19ce67 !important;
  font-weight: 500;
  font-size: 17px !important;
  margin-top: 0px !important;
  .text-center {
    text-align: center;
  }

`;

export const StyledCardWrapper = styled.div`
  text-align: center;
  min-height: 240px;
  padding: 25px 20px;
  height: 310px;

  &:hover {
    background-color: #19ce67;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 8px;
    
    h3, span {
        color: #fff;
    }

    button {
      background: #fff;
      color: #19ce67;  
    }
  }
`;

export const StyledCardIconWrapper = styled.div`
  margin-bottom: 20px;
`;

export const StyledCardIcon = styled.img`
  background: white;
  border-radius: 20%;
  padding: 10px;
  box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
`;

export const StyledCardTitle = styled.h3`
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: 700;
`;

export const StyledCardSubTitle = styled.span`
  color: #212529;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
`;

export const StyledCardButton = styled.button`
  margin-top: 20px;
  background: #19ce67;
  color: #ffffff;
  border: none;
  line-height: initial;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 17px 50px 15.5px 25px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  display: inline-flex;
  font-weight: 600;
  position: relative;

  i {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    display: inline-block;
    height: 33px;
    width: 33px;
    line-height: 33px;
    color: #ffffff;
    border-radius: 50%;
    background-color: #8ee9b2;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
`;

export const StyledHomeSlider = styled.div`

	.bd-home-slider {
		max-height: 80vh;
		height: 80vh;
		@media (max-width: 767px){
			max-height: 200px;
			height: 200px;
			padding: 0px;
      justify-content: unset !important;
		}

		span {
			color: #fff !important;
			@media (max-width: 767px){
				color: #6c757d!important;
			}
		}
		h2 {
			color: #fff !important;
			font-size: 40px;
      line-height: 52px;
			font-weight: 400;
			@media (max-width: 767px){
				color: #272b41!important;
        font-weight: 500 !important;
			}
      @media (max-width: 450px){
        color: #fff !important;
        font-size: 14px;
        line-height: 24px;
        width: 76%;
      }
		}
		li {
			color: #fff !important;
			@media (max-width: 767px){
				color: #757575!important;
			}
		}
	}

	.slide-image {
    justify-content: left !important;
		@media (max-width: 767px){
			padding: 0px 15px;
		}
    @media (max-width: 450px){
			flex: none;
		}
	}

  .mobile-screen-slide-image {
    @media (max-width: 450px){
      padding-left: 90px;
		}
    h2{
      @media (max-width: 450px){
        width: 100%;
      }
    }
  }

  .flex-none {
    @media (max-width: 450px){
			flex: none;
		}
  }

  .bg-img-watch-001 {
		background: url(/assets/img/watch1.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
    @media (max-width: 450px){
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
    }
	}

	.bg-img-work-001 {
		background: url(/assets/img/work1.png);
		background-repeat: no-repeat;
    background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
     @media (max-width: 450px){
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
    }
	}

	.bg-img-001 {
		background: url(/assets/img/emed4u3rd1.svg);
		background-repeat: no-repeat;
    background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-img-002 {
		background: url(/assets/img/emed4u3rd2.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-img-003 {
		background: url(/assets/img/emed4u3rd3.png);
		//background-repeat: no-repeat;
		background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-img-004 {
		background: url(/assets/img/4thbg.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100% 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-home-mobile-001 {
		// background: none !important;
		// @media (max-width: 767px){
		// 	background: url(/assets/img/emed4u3rd1.svg) !important;
		// 	background-repeat: no-repeat !important;
		// 	background-size: contain !important;
		// 	width: 100% !important;
		// }
	}

	.bg-home-mobile-002 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4u3rd2.svg) !important;
			background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-003 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4u3rd3.svg) !important;
			//background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-004 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/4thbg.png) !important;
			//background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.img-dsk-sh-book {
		display: block !important;
		@media (max-width: 767px){
			display: none !important;
		}
	}

	.img-dsk-sh-book-01 {
		@media (max-width: 767px){
			z-index: 999;
			height: 115px;
			a {
				z-index: 999;
				width: 120px;
				margin-top: 39px;
				font-size: 14px;
        @media (max-width: 450px){
          width: unset;
          margin-top: 5px;
          font-size: 12px;
        }
			}
      @media (max-width: 450px){
        height: auto;
      }
		}
	}

	.img-dsk-sh {
		display: none !important;

		img {
			width: 150%;
			height: 100%;
			margin-left: -238px;
		}
		@media (max-width: 767px){
			display: flex !important;
		}
	}
`;

import React, { useContext }  from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	postYogaAPI
} from '../../../api/website';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import SliderComponent from '../components/Silder';
import InputField from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import Card from '../../../components/Card';
import PricingComponent from '../components/Pricing';

import PhoneInput from "react-phone-number-input";

import { globalValidator } from "../../../utils";

import {
	StyledYoga,
	StyledButton,
	StyledCardButton,
	StyledCardWrapper,
	StyledCardIconWrapper,
	StyledCardIcon,
	StyledCardTitle,
	StyledCardSubTitle,
	StyledSubTitle
} from './styles';
import {Helmet} from "react-helmet";

function Yoga() {

	const [state, dispatch] = useContext(Context);

	const [nlp, setNlp] = React.useState({
		name: '',
		email: '',
		dob: new Date(),
		gender: 'Male',
		phone: '',
		message: ''
	})

	const submit = () => {
		const keys = [
      { name: "name", title: "Name", validateKey: "required" },
			{ name: "email", title: "Email", validateKey: "email" },
			{
        name: "message",
        title: "Message",
        validateKey: "required"
      },
    ];

    let valid = globalValidator(nlp, keys);

		if (valid === true) {
			loader(true);
			postYogaAPI(nlp).then(res => {
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				setNlp({
					name: '',
					email: '',
					dob: new Date(),
					gender: 'Male',
					phone: '',
					message: ''
				})
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const whyUs = [
		{id: 1, title: 'Lorem Ipsum is simply dummy text', icon: '/assets/img/specialities/specialities-02.png', description: 'Lorem Ipsum is simply dummy text, Lorem Ipsum is simply dummy text.Lorem Ipsum is simply dummy text'},
		{id: 2, title: 'Lorem Ipsum is simply dummy text', icon: '/assets/img/specialities/specialities-02.png', description: 'Lorem Ipsum is simply dummy text, Lorem Ipsum is simply dummy text.Lorem Ipsum is simply dummy text'},
		{id: 3, title: 'Lorem Ipsum is simply dummy text', icon: '/assets/img/specialities/specialities-02.png', description: 'Lorem Ipsum is simply dummy text, Lorem Ipsum is simply dummy text.Lorem Ipsum is simply dummy text'}
	];

	return (
		<StyledYoga>
			<Helmet title = {'Book online yoga appointment.'}
				htmlAttributes={{ lang: "en" }}
				meta={[
					{
						name: `description`,
						content:'Online yoga appointment for every age. Get yoga appointment within minutes',
					},
					{
						name: `keywords`,
						content:'Online yoga training, book online yoga appointmnet',
					},
				]}
     		/>
			<SliderComponent 
				className="bd-home-slider bg-img-002 " 
				className2="bd-home-slider bg-img-003 " 
				yoga={true} 
				title=""
				subTitle="A balance between mind, body and soul."
				description=""
				heading=""	
			/>	

			<section className="section mt-5 pb-4">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-9 m-auto">
              <div className="section-header text-center">
                <h2 style={{color: '#60cf68'}}>Yoga </h2>
              </div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h4 style={{fontSize: '19px', fontWeight: '500'}}>Yoga originates from ancient India and has been practiced for centuries. The word yoga is derived from the Sanskrit word “Yuj” which means “to unite”. It is a union of physical, mental and spiritual practices which aim to create a union between the individual self and universal consciousness. Such a union tends to neutralize the ego driven thoughts and behaviours, creating a sense of spiritual awakening. </h4>
						</div> <br />
						<div className="col-md-12">
							<h6 style={{fontSize: '19px', fontWeight: '500'}}>The goal of yoga is to achieve liberation from suffering which could be both physical or mental. Yoga provides an opportunity to understand the strengths and weaknesses of an individual and also helps to transform negative thoughts to a positive conception.  </h6>
						</div>
					</div>
				</div>
			</section>

			<section className="section section-specialities">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-9 m-auto">
              <div className="section-header text-center">
                <h2 style={{color: '#60cf68'}}>Basic components of Yoga </h2>
              </div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12  nlp-benifits">
							<ul>
							<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Yamas - Five universal, ethical and moral observances to live by (nonviolence, truthfulness, non-stealing, continence and non-covetousness).</li>

							<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Niyamas - Five spiritual and self-discipline observances (cleanliness, contentment, spiritual austerities, study of scriptures and surrender to the divine) </li>

							<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Asana - physical posture, originally intended only for seated meditation, but more recently adapted to encompass all physical yoga practices </li>

							<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Pranayamas: a conscious breathing exercises to detox the body. It helps to revive the inner vital energy which is also called Prana.</li>

							<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Pratyahara - Withdrawal of the senses. </li>

							<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Dharana - Single pointed concentration. </li>

							<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Dhyana – Meditation.</li>
							
							<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Samadhi - Liberation or blissful union with the Divine. </li>

							</ul>
						</div>
					</div>
				</div>
			</section>

			<section>
				<div className="container-fluid">
					
					<div className="row">
						<div className="col-md-6 col-xs-12 nlp-benifits">
							<div className="section-header text-center">
                <h2 style={{color: '#60cf68'}}>Benefits of Yoga </h2>
              </div>
							<ul className="">
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Creates a balances between your body, mind and soul. </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Activates your energy Chakras.   </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Helps in Depression, insomnia and relieves anxiety. </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Keeps your mind calm and improves brain function.</li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Increases your concentration and will power. </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Detoxes your body and regenerates your cells.</li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Increases flexibility in your spine & body.  </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Tones and strengthens the muscles. </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Increases core body strength and stamina. </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Activates blood circulation and maintains blood pressure. </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Boosts Immunity and improves thyroid function. </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Helps in weight loss and improves digestion. </li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Strengthens pelvic muscles and helps in pregnancy. </li>

							</ul>
						</div>

						<div className="col-md-6 col-xs-12 req-app-ce">
							<div className="section-header text-center">
								<h2  style={{color: '#60cf68'}}>Request Appointment</h2>
							</div>
							<div className="row">
								
								<div className="col-md-6 col-sm-12">
									<div className="form-group ">
										<label>Name</label>
										<InputField value={nlp.name} onChange={e => setNlp((user) => ({...user, name: e.target.value}))} />
									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div className="form-group ">
										<label>Date of birth</label>
										<InputField 
											type="date" 
											value={nlp.dob} 
											onChange={e => setNlp((user) => ({...user, dob: e.target.value}))}
										/>

									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div className="form-group ">
										<label>Gender</label>
										<SelectField value={nlp.gender} onChange={e => setNlp((user) => ({...user, gender: e.target.value}))} options={[{id: 1, label: 'Male', value: 'male'}, {id: 2, label: 'Female', value: 'female'}, {id: 3, label: 'Others', value: 'other'}]} />
									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div className="form-group ">
										<label>Email</label>
										<InputField value={nlp.email} onChange={e => setNlp((user) => ({...user, email: e.target.value}))} type="email" />

									</div>
								</div>

								
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12">
									<div className="row">
										<div className="col-md-12 no-padding form-group ">
											<label>Contact number</label>
											<PhoneInput
												placeholder="Enter phone number"
												international
												defaultCountry="IN"
												value={nlp.phone}
												onChange={e => setNlp((user) => ({...user, phone: e}))}
											/>
										</div>
									</div>
									
								</div>
								<div className="col-md-12 col-sm-12">
									<div className="form-group " style={{display:'grid'}}>
										<label>Message</label>
										<textarea style={{height: '145px'}} value={nlp.message} onChange={e => setNlp((user) => ({...user, message: e.target.value}))}></textarea>
									</div>
								</div>
							</div>
							<StyledButton onClick={() => submit()}>
								<StyledCardButton>
									Make Appointment     
									<i className="fa fa-chevron-right" />
								</StyledCardButton>
							</StyledButton>

						</div>

					</div>
				</div>
			</section>


			{/* <section className="section section-doctor">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-9 m-auto">
              <div className="section-header text-center">
                <h2>Why us?</h2>
              </div>
						</div>
					</div>
					<div className="row">
						{
							whyUs.map(tool => {
								return <div className="col-lg-4 mb-3" key={tool.id}>
												<Card className="text-center">
													<StyledCardWrapper>
														<StyledCardIconWrapper>
															<StyledCardIcon src={tool.icon} />
														</StyledCardIconWrapper>
														<StyledCardTitle>
															{tool.title}
														</StyledCardTitle>
														<StyledCardSubTitle>
															{tool.description}
														</StyledCardSubTitle> <br />
														
													</StyledCardWrapper>
												</Card>
											</div>
							})
						}
					</div>
				</div>
			</section> */}
{/* 			
			<section className="section section-specialities">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 m-auto">
              
						</div>
					</div>

					<div className="row">
						<div className="section-header text-center">
							<h2>Request Appointment</h2>
						</div>
						<div className="col-md-4 col-sm-12">
							<div className="form-group card-label">
								<label>Name</label>
								<InputField value={nlp.name} onChange={e => setNlp((user) => ({...user, name: e.target.value}))} />
							</div>
						</div>
						<div className="col-md-4 col-sm-12">
							<div className="form-group card-label">
								<label>Date of birth</label>
								<DatePicker selected={nlp.dob} onChange={date => setNlp((user) => ({...user, dob: date}))} />

							</div>
						</div>
						<div className="col-md-4 col-sm-12">
							<div className="form-group card-label">
								<label>Gender</label>
								<SelectField value={nlp.gender} onChange={e => setNlp((user) => ({...user, gender: e.target.value}))} options={[{id: 1, label: 'Male', value: 'Male'}, {id: 2, label: 'Female', value: 'Female'}, {id: 3, label: 'Others', value: 'Others'}]} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-sm-12">
							<div className="row">
								<div className="col-md-12 no-padding form-group card-label">
									<label>Email</label>
									<InputField value={nlp.email} onChange={e => setNlp((user) => ({...user, email: e.target.value}))} type="email" />
								</div>
							</div>

							<div className="row">
								<div className="col-md-12 no-padding form-group card-label">
									<label>Contact number</label>
									<PhoneInput
                        placeholder="Enter phone number"
                        international
                        defaultCountry="IN"
                        value={nlp.phone}
                        onChange={e => setNlp((user) => ({...user, phone: e}))}
                      />
								</div>
							</div>
							
						</div>
						<div className="col-md-6 col-sm-12">
							<div className="form-group card-label" style={{display:'grid'}}>
								<label>Message</label>
								<textarea style={{height: '145px'}} value={nlp.message} onChange={e => setNlp((user) => ({...user, message: e.target.value}))}></textarea>
							</div>
						</div>
					</div>
					<StyledButton onClick={() => submit()}>
						<StyledCardButton>
							Make Appointment     
							<i className="fa fa-chevron-right" />
						</StyledCardButton>
					</StyledButton>
				</div>
			</section> */}

			{/* <section className="section section-specialities">
				<div className="container">
					<div className="section-header text-center">
						<StyledSubTitle>Pricing</StyledSubTitle>
						<h2>Lorem Ipsum is simply dummy text</h2>
					</div> 
					<div className="row m-auto">
						<div className="col-md-8 m-auto">
							<PricingComponent />
							<PricingComponent />
						</div>
						
					</div>
				</div>
			</section> */}
		</StyledYoga>
	)
}

export default Yoga

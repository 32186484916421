import styled from 'styled-components';

export const StyledContainer = styled.div`
.content {
  background: #fff;
  .container-fluid {
	padding-left: 15px;
	padding-right: 15px;
  }
}

.btn-primary {
  background-color: #5156be;
  border: 1px solid #5156be;
}


.postion-relative {
  position: relative;
}
.remove-heath {
  position: absolute;
  right: 6px;
  top: -10px;
  background: #5156be;
  color: #fff;
  font-size: 20px;
  border: #5156be;
  border-radius: 50%;
  padding: 4px 12px;
}

a {
  cursor: pointer;
}

.health-btn-outline {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  color: #5156be;
  text-transform: uppercase;
  font-weight: 500;
  background: transparent;
  margin-right: 20px;
  cursor: pointer;
}

.health-active-btn {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 20px;
  background: #1dbe72;
  color: #fff;
  cursor: pointer;
}

.success-bmi {
  background: #b6e297;
  color: #000;
}
.under-bmi {
  background: #f8ccce;
  color: #000;
}
.over-bmi {
  background: #fcfbc2;
  color: #000;
}
.obesity-bmi {
  background: #dd8990;
  color: #000;
}

.color-white {
  color: #000 !important;
}
.color-white h5 {
  color: #000 !important;
}
.line-chart-buttons {
  color: #000;
  background: transparent;
  border: none;
}
.line-chart-buttons:hover,  .line-chart-buttons::after{
  background:#09dca4
}
.selected-group {
  background-color: #19ce67;
}
`;

export const StyledContainerReport = styled.div`
  .content {
    background: #fff;
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .btn-primary {
    background-color: #5156be;
    border: 1px solid #5156be;
  }


  .postion-relative {
    position: relative;
  }
  .remove-heath {
    position: absolute;
    right: 6px;
    top: -10px;
    background: #5156be;
    color: #fff;
    font-size: 20px;
    border: #5156be;
    border-radius: 50%;
    padding: 4px 12px;
  }

  a {
    cursor: pointer;
  }

  .health-btn-outline {
    border: 2px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #5156be;
    text-transform: uppercase;
    font-weight: 500;
    background: transparent;
    margin-right: 20px;
    cursor: pointer;
}

.health-active-btn {
    border: 2px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 20px;
    background: #1dbe72;
    color: #fff;
    cursor: pointer;
}

  .success-bmi {
    background: #b6e297;
    color: #000;
  }
  .under-bmi {
    background: #f8ccce;
    color: #000;
  }
  .over-bmi {
    background: #fcfbc2;
    color: #000;
  }
  .obesity-bmi {
    background: #dd8990;
    color: #000;
  }

  .color-white {
    color: #000 !important;
  }
  .color-white h5 {
    color: #000 !important;
  }
  .line-chart-buttons {
    color: #000;
    background: transparent;
    border: none;
  }
  .line-chart-buttons:hover,  .line-chart-buttons::after{
    background:#09dca4
  }
  .selected-group {
    background-color: #19ce67;
  }


.hb-em {
    display: block;
    margin-bottom: 15px;
  }
  
  .hb-em-input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .hb-label {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
  }
  
  .hb-label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 3px solid #0079bf;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .hb-input:checked{
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

`;

export const StyledButtonGroup = styled.div`
	.active-btn {
		background: #5156be;
		color: #fff;
    border: 2px solid #5656be;
	}
`;

export const StyledButton = styled.button`
	border: 2px solid #c8c9ee;
	border-radius: 4px;
	padding: 10px 15px;
  background: #c8c9ee;
	text-align: center;
	font-size: 15px;
	color: #5156be;
	text-transform: uppercase;
	font-weight: 500;
	background: transparent;
	margin-right: 20px;
`;
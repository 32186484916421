import React from "react";
import FieldWrapper from "../../InputField/TextFieldWrapper";
import { Grid } from "@mui/material";

const MedicalHistory = () => {
  return (
    <>
      <Grid item xs={12}>
        <FieldWrapper
          type="text"
          name="pastMedical"
          label="Significant past medical & surgical History (optional)..."
          multiline={true}
          rows={5}
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWrapper
          type="text"
          name="familyMed"
          label="Family medical History (optional) ..."
          multiline={true}
          rows={5}
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWrapper
          type="text"
          name="history"
          label="History of Alcohalism , Tobacco or Substances (optional)..."
          multiline={true}
          rows={5}
        />
      </Grid>
    </>
  );
};

export default MedicalHistory;

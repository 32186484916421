import React from "react";
import { Grid, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { SearchOutlined } from "@mui/icons-material";
import styled from "styled-components";

const StyledSeachField = styled(Grid)(() => ({
    '& .MuiTextField-root': {
        height: '40px',
        '& input': {
            height: '40px',
        },
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#5E5E5E',
        '& .MuiInputBase-root': {
            '&:before,:after': {
                border: '1px solid',
                borderImageSlice: 1,
                borderWidth: '0px 0px 1.2px',
                borderImageSource: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)'
            },
        }
    },
}))

const SearchFields = (props) => {
    const handleChange = (e) => {
        let value= e.target.value;
        props.handleValue(value);
    }
    return (
        <StyledSeachField {...props}>
            <TextField
                fullWidth
                placeholder={props.placeholder}
                variant="standard"
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                                    <SearchIcon />
                        <SearchOutlined sx={{ height: '18px', width: '18px', fill: 'url(#header-shape-gradient)' }} />
                    </InputAdornment>
                }}
                onChange={handleChange}
            />
        </StyledSeachField>)
}


export default React.memo(SearchFields);
import {DialogActions} from "@mui/material";
import styled from "styled-components"

export const StyledDialogActions = styled(DialogActions)`
display: flex;
justify-content: center;
align-items: center;
& .custom-dialog-actions {
   width: 80px;
  }
}`;

import React from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

function PatientCard(props) {
	const doctorPath = window.location.pathname;
	const history = useHistory()
	const setPatientData = () => {
		Cookies.set('setPatientInfo', JSON.stringify({
			name: props.name,
			address: props.address,
			gender: props.gender,
			dob: props.dob,
			weight: props.weight
		}), { path: '' });
	}

	return (
		<div className="card widget-profile pat-widget-profile">
			<div className="card-body">
				<div className="pro-widget-content">
					<div className="profile-info-widget">
						<a className="booking-doc-img">
							<img src={props.profileImage ? props.profileImage : '/assets/img/user.png'} alt="User Image" />
						</a>
						<div className="profile-det-info">
							<h3><a>{props.name}</a></h3>
                        
							<div className="patient-details">
								<h5><b>Patient ID :</b></h5>
								<h5>{props._id}</h5>
								{props.address ?
									<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> {props.address}</h5>
									: <div className="mb-4"></div>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="patient-info">
				<ul>
					<li>Contact number <span>{props.phoneNumber}</span></li>
					<li>Age   <span>{props.dob ? moment().diff('1981-01-01', 'years'): '-'}</span></li>
					<li>Gender  <span>{props.gender ? props.gender : '-'}</span></li>
					<li>Blood group <span>{props.bloodGroup}</span></li>
					<li>Status <span style={{color: props.status == 2 ? 'red' : '#56be73'}}>{props.status == 2 ? 'Completed' : 'Active'}</span></li>
					<li>Total appointments<span>{props.totalAppointment}</span></li>
				</ul>
				{
					props.bookAppt && (
						<button className="doctor-active-btn mt-3 doctor-w-100 doctor-button-color" onClick={() => setPatientData()}>
							<Link to={`/subuser/doctor-listing/${props._id}`}>
								Book Appt.
							</Link>
						</button>
					)
				}

					{
						!props.bookAppt && (
							<button
								className="doctor-active-btn mt-3 doctor-w-100 doctor-button-color"
								onClick={() => {
									setPatientData()
									doctorPath.includes('/doctor/patient-listing') ? 
									history.push(`/doctor/view-patient/${props.patientInfo.patient._id}`) :
									history.push(`/doctor/view-ipd-patient/${props.patientInfo._id}/${props.patientInfo.patient._id}`);
								}}
							>
								<Link
									to
								>
									{/* <button className="doctor-btn-outline mt-3 doctor-w-100"> */}
										View
									{/* </button> */}
								</Link>
							</button>
						)
					}
				
				
			</div>
		</div>
    </div>
	)
}

export default PatientCard

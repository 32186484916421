import styled from 'styled-components';

export const StyledContainer = styled.div`
.form-control[readonly] {
  background: transparent !important;
}
.content {
  background: #fff;
  .container-fluid {
	padding-left: 15px;
	padding-right: 15px;
  }
}

.btn-primary {
  background-color: #5156be;
  border: 1px solid #5156be;
}


.postion-relative {
  position: relative;
}
.remove-heath {
  position: absolute;
  right: 6px;
  top: -10px;
  background: #5156be;
  color: #fff;
  font-size: 20px;
  border: #5156be;
  border-radius: 50%;
  padding: 4px 12px;
}

a {
  cursor: pointer;
}

.health-btn-outline {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  color: #5156be;
  text-transform: uppercase;
  font-weight: 500;
  background: transparent;
  margin-right: 20px;
  cursor: pointer;
}

.health-active-btn {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 20px;
  background: #1dbe72;
  color: #fff;
  cursor: pointer;
}

.success-bmi {
  background: #b6e297;
  color: #000;
}
.under-bmi {
  background: #f8ccce;
  color: #000;
}
.over-bmi {
  background: #fcfbc2;
  color: #000;
}
.obesity-bmi {
  background: #dd8990;
  color: #000;
}

.color-white {
  color: #000 !important;
}
.color-white h5 {
  color: #000 !important;
}
.line-chart-buttons {
  color: #000;
  background: transparent;
  border: none;
}
.line-chart-buttons:hover,  .line-chart-buttons::after{
  background:#09dca4
}
.selected-group {
  background-color: #19ce67;
}
`;

export const StyledHeader = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledBackTo = styled.div`
	border: 2px solid rgb(81, 86, 190);;
	border-radius: 4px;
	padding: 10px 15px !important;
	text-align: center;
	font-size: 15px;
	color: rgb(81, 86, 190);;
	font-weight: 500;
	cursor: pointer;

	a {
		color: rgb(81, 86, 190);;
	}

	i {
		padding-right: 10px;
	}
`;

export const StyledTitle = styled.h2`
	display: flex;
	margin: auto
`;

export const StyledAddEditPatient = styled.button`
	margin-left: auto;
	cursor: pointer;
	border: none;
	color: #fff;
	text-decoration: none;
	transition: background .5s ease;
	-moz-transition: background .5s ease;
	-webkit-transition: background .5s ease;
	-o-transition: background .5s ease;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-align: center;
	background: #5156be;
	position: relative;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 600;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	line-height: 1;
	padding: 12px 30px;
`;

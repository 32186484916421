import { Answer, Question, SingleQuesWrapper } from "./styles"
import { ReactComponent as Collapse } from '../../../assets/img/collapse.svg';
import { ReactComponent as Expand } from '../../../assets/img/expand.svg';
import { useMediaQuery } from 'react-responsive'

const expandStyle = { marginTop: '40px', cursor: 'pointer' };
const Toggle = ({ condition, index, setCondition }) => !condition ? <Expand style={expandStyle} onClick={() => setCondition(index)} /> : <Collapse style={expandStyle} onClick={() => setCondition(index)} />

export const SingleQuestion = ({ question, answer, condition, index, setCondition }) => {
	const isMobile = useMediaQuery({
		query: '(min-width: 700px)'
	})
	const isDesktop = useMediaQuery({
		query: '(max-width: 1122px)'
	})
	const inBetween = isMobile && isDesktop;

	return (
		<SingleQuesWrapper>
			<div style={{cursor: 'pointer'}} className={inBetween ? "d-flex-important" : "d-flex"} onClick={() => setCondition(index)}>
				<Question>{question}</Question>
				<Toggle condition={condition} index={index} setCondition={setCondition} />
			</div>
			{condition && <Answer>{answer}</Answer>}
		</SingleQuesWrapper>
	)
}

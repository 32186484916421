import * as Yup from "yup";

export const sendMedicationSchema = Yup.object().shape({
  appointmentId: Yup.string(),
  diagnosisAndObservation: Yup.string().required(
    "Diagnosis and Observation is required"
  ),
  otherInstructions: Yup.string().required("Other Instructions is required"),
  settlePayment: Yup.string().required("Settle Payment is required"),
  formOfMedication: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Medication Name is required"),
        medicineStrength: Yup.string().required(
          "Medicine Strength is required"
        ),
        strengthType: Yup.string().required("Strength Type is required"),
        dosageQuantity: Yup.string().required("Dosage Quantity is required"),
      })
    )
    .required("You must provide at least one medication"),
});

import React, { useState } from 'react';
import { Context } from '../../../store';
import moment from 'moment';
import { isMobile } from "react-device-detect";
import { getUser } from '../../../utils';
import ChatBody from './ChatBody';
import {
  StyledContainer,
  StyledBackSection,
  StyledBackTo
} from './styles';
import { SET_COMMON_LOADING, SET_ERROR } from '../../../store/types';
import { Howl } from 'howler';
import ringtone from '../../../ringtone.mp3';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { leaveCall, muteAudioToggle, pauseVideo, resumeVideo, setUser, startBasicCall, unMuteAudioToggle } from '../../../components/Header/Agora_RTC';
import { toast } from 'react-toastify';

const apiUrl = 'https://api.emed4u.com'; //'http://localhost:5000'
// const socket = io(apiUrl);
const ringtoneSound = new Howl({
	src: [ringtone],
	loop: true,
	preload: true
})

const flexSpaceBetween = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}
const width = {width: '30%'}

function PatientChat(props) {
  const [state, dispatch] = React.useContext(Context);
  const [id, setId] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [doctorList, setDoctorList] = React.useState([]);


  const [calling, setCalling] = React.useState(false);
  const [doctorProifleImage, setDoctorProifleImage ] = React.useState('');
  const [doctorName, setDoctorName] = React.useState('');
  const [callType, setCallType] = useState('audio');
  const [channel, setChannel] = useState('');
  const [isjoin, setIsJoin] = useState(false);
  const [appid, setAppid] = useState('f5a79afbfcce4005bc94277dbd7fe04b');
  const [token, setToken] = useState('');
  const [uuid, setUuid] = useState('');
  const [currentUserId, setCurrentUserId] = useState(null);

  const [muteAudio, setMuteAudio] = useState(false);
	const [stopVideo, setStopVideo] = useState(false);

  const callRejected = () => {
		const user = {
			appointmentId: channel,
			from: 'Patient'
		};
		// socket.emit("rejectCall", user);
		setIsJoin(false);
		setCalling(false);
		ringtoneSound.stop();
		ringtoneSound.unload();
	}

  const handleClickJoin = () => {
		setCalling(false);
		ringtoneSound.stop();
		ringtoneSound.unload();
		AgoraRTC.getDevices()
			.then(devices => {
				const audioDevices = devices.filter(function (device) {
					return device.kind === "audioinput";
				});
				var selectedMicrophoneId = audioDevices[0].deviceId;
				
				const videoDevices = devices.filter(function (device) {
					return device.kind === "videoinput";
				});
				var selectedCameraId = videoDevices[0].deviceId;
				
				return Promise.all([
					AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
					AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedMicrophoneId }),
				]);
			})
			.then(res => {
				const [videoTrack, audioTrack] = res;
				let options = {
					appId: appid,
					channel: channel,
					token: token,
					uid: uuid
				}
				const val = startBasicCall(options, videoTrack, audioTrack, callType).then(res => {
				})
				setIsJoin(true)
			}).catch(e => {
				toast.error('Permission denied')
				dispatch({
					type: SET_ERROR,
					payload: 'Permission denied'
				});
			});
	}

  const handleClickLeave = () => {
		const user = {
			appointmentId: channel,
			from: 'Patient'
		};
		// socket.emit("rejectCall", user);
		leaveCall(callType)
		setIsJoin(false)
    ringtoneSound.stop();
		ringtoneSound.unload();
		setTimeout(() => {
			window.location.reload();
		}, 1000)
	}

  const loader = (value) => {
    dispatch({
      type: SET_COMMON_LOADING,
      payload: value
    });
  }

  React.useEffect(() => {
    if (document.body.className === 'chat-page') { }
    else document.body.className += ' chat-page';
    loader(true);
    const userId = getUser();
    setId(userId._id);
    loader(true);

    const user = {
      userId: userId._id
    };

    // socket.emit("join_patient", user);
    // socket.emit("join_patient_id", user);
    // socket.on("doctors_list_chat", data => {
    //   data &&
    //     setDoctorList(data)
    //   loader(false);
    // });
  }, []);


  const removeClasses = () => {
    document.body.className = "";
  }

  const onSetUserId = (item) => {
    setCurrentUser(item._id);
    const userId = getUser();
    if (item.unread && item.unread.id === userId._id) {
      const user = {
        id: item._id,
        userId: userId._id
      };
      // socket.emit("unread_patient_chat", user);
    }
  }

  const renderChat = (item, currentUser, onSetUserId, id) => (
    <a href="javascript:void(0);" className={currentUser === item._id ? 'media read-chat active' : "media"} key={item._id} onClick={() => onSetUserId(item)}>
      <div className="media-img-wrap">
        <div className="avatar">
          <img src={item.doctorId.profileImage ? item.doctorId.profileImage : '/assets/img/doctor-empty.png'} alt="User Image" className="avatar-img rounded-circle" />
        </div>
      </div>
      <div className="media-body">
        <div>
          <div className="user-name">{item.doctorId.name}</div>
          <div className="user-last-chat">{item.conversions.length > 0 ? item.conversions[item.conversions.length - 1].text : ''} </div>
        </div>
        <div>
          <div className="last-chat-time block">{item.conversions.length > 0 ? moment(item.conversions[item.conversions.length - 1].createdAt).fromNow() : ''}</div>
          {item.unread && item.unread.id === id && <div className="badge badge-danger badge-pill">!</div>}
        </div>
      </div>
    </a>
  )

  // React.useEffect(() => {
  //   socket.on('rtctoken-patient', data => {
  //     if(data) {
  //       setChannel(data.channel)
  //       setToken(data.refreshToken)
  //       setUuid(data.uuid)
  //       setDoctorProifleImage(data.doctorImage);
  //       setDoctorName(data.doctorName);
  //       setCallType(data.type)
  //       setUser('Patient');
  //     }
  //   })
  // },[])

  React.useEffect(() => {
		const data = state.callingData;
		if(data) {
			setChannel(data.channel)
			setToken(data.refreshToken)
			setUuid(data.uuid)
			setDoctorProifleImage(data.doctorImage);
			setDoctorName(data.doctorName);
			setCallType(data.type)
			setUser('Patient');
		  }
	  },[state])

  // React.useEffect(() => {
	// 	const customer = JSON.parse(localStorage.getItem('customer'));
	// 	const path = window.location.pathname;
	// 	if(customer && customer._id ) {
	// 		setCurrentUserId(customer._id);
	// 		const user = {
	// 			userId: customer._id
	// 		};
	// 		socket.emit("join_patient", user);
	// 		socket.on('rtctoken-patient', data => {
	// 			if(data) {
	// 				ringtoneSound.play();
	// 				setCalling(true);
	// 				setChannel(data.channel)
	// 				setToken(data.refreshToken)
	// 				setUuid(data.uuid)
	// 				setDoctorProifleImage(data.doctorImage);
	// 				setDoctorName(data.doctorName);
	// 				setCallType(data.type)
	// 				setUser('Patient');
	// 			}
  		// 		})

	// 		socket.on("endCall", data => {
	// 			leaveCall(callType);
	// 			setIsJoin(false);
	// 			if (data?.role == 'Doctor') {
	// 				toast.success('Doctor end the call!')
	// 				dispatch({
	// 					type: SET_ERROR,
	// 					payload: 'Doctor end the call!'
	// 				});
	// 				setTimeout(() => {
	// 					window.location.reload();
	// 				}, 1000)
	// 			}
	// 		})
	// 	}
	// }, [])

  const handleMuteAudio = () => {
		if (muteAudio) {
		  setMuteAudio(false);
		  unMuteAudioToggle()
		} else {
		  setMuteAudio(true);
		  muteAudioToggle()
		}
	  };

	const handleStopVideo = () => {
		if (stopVideo) {
		  setStopVideo(false);
		  resumeVideo()
		} else {
		  setStopVideo(true);
		  pauseVideo()
		}
	};

  return (
    <StyledContainer>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              {
                !isMobile && <div className="chat-window">

                  <div className="chat-cont-left">
                    <div className="chat-header">
                      <span>Chats</span>

                      <StyledBackSection>
                        <StyledBackTo to={'/patient/dashboard'} onClick={() => removeClasses()}>
                          <i className="fa fa-arrow-left" />
                          Back To Dashboard
                        </StyledBackTo>
                      </StyledBackSection>
                    </div>
                    <div className="chat-users-list">
                      <div className={`chat-scroll chat-content-left-side ${doctorList?.length ? '' : 'chat-user-no-data'}`}>
                          {
                            doctorList?.length ? (
                              <>
                              {doctorList.filter((item) => item?.conversions?.length)
                              .sort((a, b) => moment().diff(a?.conversions?.at(-1)?.createdAt) - moment().diff(b?.conversions?.at(-1)?.createdAt))?.map(item => renderChat(item, currentUser, onSetUserId, id))}
                              {doctorList.filter((item) => !item?.conversions?.length)
                              .sort((a, b) => moment().diff(a?.conversions?.at(-1)?.createdAt) - moment().diff(b?.conversions?.at(-1)?.createdAt))?.map(item => renderChat(item, currentUser, onSetUserId, id))}
                              </>
                            ) : (
                              <div>No chat found</div>
                            )
                          }
                      </div>
                    </div>
                  </div>

                  {/* <!-- /Chat Left --> */}

                  {/* <!-- Chat Right --> */}
                  {
                    currentUser ?
                      <ChatBody
                        id={currentUser}
                      />
                      : null
                  }

                  {
                    !currentUser &&
                    <div>
                      <div className="col-md-4 offset-4 text-center" >
                        <img
                          src="/assets/img/chatBoard.svg"
                          style={{
                            width: '100%',
                            marginTop: '50%'
                          }}
                        />
                        <p style={{ marginTop: '10px' }}>Select a chat to read messages</p>
                      </div>
                    </div>
                  }

                </div>
              }

              {
                isMobile &&
                <div>
                  {!currentUser && <div className="chat-cont-left">
                    <div className="chat-header">
                      <span>Messages</span>

                      <StyledBackSection>
                        <StyledBackTo to={'/patient/dashboard'} onClick={() => removeClasses()}>
                          <i className="fa fa-arrow-left" />
                          Back To Dashboard
                        </StyledBackTo>
                      </StyledBackSection>
                    </div>
                    <div className="chat-users-list">
                      <div className="chat-scroll">
                        {
                          doctorList && doctorList.map(item => {
                            return <a href="javascript:void(0);" className={currentUser === item._id ? 'media read-chat active' : "media"} key={item._id} onClick={() => onSetUserId(item)}>
                              <div className="media-img-wrap">
                                <div className="avatar">
                                  <img src={item.doctorId.profileImage ? item.doctorId.profileImage : '/assets/img/doctor-empty.png'} alt="User Image" className="avatar-img rounded-circle" />
                                </div>
                              </div>
                              <div className="media-body">
                                <div>
                                  <div className="user-name">{item.doctorId.name}</div>
                                  <div className="user-last-chat">{item.conversions.length > 0 ? item.conversions[item.conversions.length - 1].text : ''} </div>
                                </div>
                                <div>
                                  <div className="last-chat-time block">{item.conversions.length > 0 ? moment(item.conversions[item.conversions.length - 1].createdAt).fromNow() : ''}</div>
                                  {/* {item.msgCount && <div className="badge badge-success badge-pill">{item.msgCount}</div>} */}
                                </div>
                              </div>
                            </a>
                          })
                        }
                      </div>
                    </div>
                  </div>}
                  {
                    currentUser ?
                      <ChatBody
                        id={currentUser}
                        props={props}
                        mobile={true}
                        unSelect={() => setCurrentUser(null)}
                        className='mob-chat-right-emed'
                      />
                      : null
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {/* {calling ? (
				<div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)', paddingLeft: '0px' }}>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body" style={{ padding: '40px' }}>
								<div className="call-box incoming-box">
									<div className="call-wrapper" style={{ height: 'auto' }}>
										<div className="call-inner">
											<div className="call-user">
												<img className="call-avatar" src={doctorProifleImage ? `${doctorProifleImage}` : "/assets/img/doctor-empty.png"} alt="User Image" />
												<h4>{doctorName}</h4>
												<span>Calling ...</span>
											</div>
											<div className="call-items">
												<a href="javascript:void(0);" className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons" onClick={callRejected}>call_end</i></a>
												{callType === 'audio' ? (
													<a href="javascript:void(0);" onClick={() => handleClickJoin()} data-toggle="modal" data-target="#video_call" className="btn call-item call-start"><i className="material-icons">phone_in_talk</i></a>
												) : (
													<a href="javascript:void(0);" onClick={() => handleClickJoin()} data-toggle="modal" data-target="#video_call" className="btn call-item call-start"><i className="material-icons">videocam</i></a>
												)}
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			) : null} */}
      <div class="modal fade call-modal" id="video_call">
				<div class="modal-dialog video-call-modal modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-body">
							<div class="call-wrapper">
								<div class="call-main-row">
									<div class="call-main-wrapper">
										<div class="call-view">
											<div class="call-window">
												<div class="fixed-header">
													<div class="navbar">
														<div class="user-details">
															<div class="float-left user-img">
																<a class="avatar avatar-sm mr-2" title="Charlene Reed">
																	<img src={doctorProifleImage ? `${doctorProifleImage}` : "/assets/img/doctor-empty.png"} alt="User Image" class="rounded-circle" />
																	<span class="status online"></span>
																</a>
															</div>
															<div class="user-info float-left">
																<a><span>{doctorName}</span></a>
															</div>
														</div>
													</div>
												</div>
												<div class="call-contents">
													<div class="call-content-wrap">
														<div class="user-video">
															{
																callType === 'audio' ?
																	<img src="/assets/img/audio.png" class="img-fluid" alt="User Image" />
																	:
																	<div className='video-agora-box'></div>
															}
														</div>
														<div class="my-video">
															<ul>
																<li style={{ height: isMobile ? '50px' : '120px' }}>
																	{
																		callType === 'audio' ?
																			<img src="/assets/img/audio.png" class="img-fluid" alt="User Image" />
																			:
																			<div id='video-agora-local'></div>
																	}
																</li>
															</ul>
														</div>
													</div>
												</div>
                        <div class="call-footer" style={flexSpaceBetween}>
                          <div style={width}/>
                          <div class="call-icons">
                            <ul class="call-items">
                              {callType === 'video' && <li class="call-item">
                                <a href="javascript:void(0);" title="Enable Video" onClick={() => handleStopVideo()} data-placement="top" data-toggle="tooltip" className={stopVideo ? 'unMuteMicrophone' : 'muteMicrophone'} >
                                  {/* <i class="fas fa-video camera"></i> */}
                                  {stopVideo ? <i class="fa fa-video-slash "></i> : <i class="fa fa-video"></i>}
                                </a>
                              </li>}
                              <li class="call-item">
                                <a href="javascript:void(0);" title="Mute Audio" onClick={() => handleMuteAudio()} data-placement="top" data-toggle="tooltip" className={muteAudio ? 'unMuteMicrophone' : 'muteMicrophone'} >
                                  {/* <i class="fa fa-microphone microphone"></i> */}
                                  {muteAudio ? <i class="fa fa-microphone-slash "></i> : <i class="fa fa-microphone"></i>}
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="end-call" style={width}>
                            <a href="javascript:void(0);" class="btn call-item end-call" data-dismiss="modal" aria-label="Close" onClick={() => handleClickLeave()}>
                              <i class="material-icons">call_end</i>
                            </a>
                          </div>
                        </div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </StyledContainer>
  )
}

export default PatientChat

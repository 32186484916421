import React, { useEffect, useState } from 'react'
import { EmptyCircle, FilledCircle, H1Ttitle, Image, ImageWrapper, LightText, Line, Wrapper } from './styled';
import { TimeLine } from './utility';

const changeIndex = (type, value) => type === '-' ? value - 1 : value + 1;

const History = () => {
	const [index, setIndex] = useState(0);
	const handleBackForward = (type) => {
		setIndex((value) => {
			if(changeIndex(type, value) < 0 || changeIndex(type, value) > 4) return 0;
			else return changeIndex(type, value);
		});
	};
	
	return (
		<Wrapper>
			<H1Ttitle>Our <H1Ttitle className='colored' > History </H1Ttitle></H1Ttitle>
			<TimeLine index={index} />
		</Wrapper>
	)
}

export default History;
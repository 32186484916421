import React, { useState } from "react";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import {
  StyledCrossIcon,
  StyledSecondaryButton,
} from "../../assets/styles/CommonStyles";
import { Formik } from "formik";
import { Grid, CircularProgress } from "@mui/material";
import strings from "../../constant/strings";
import FieldWrapper from "../InputField/TextFieldWrapper";
import SelectionDropdown from "../control/SelectionDropdown";
import {
    designation,
  docDegree,
  genderList,
  language,
  occupationList,
  permission,
  status,
} from "../../constant/staticLists";
import { capitalizeFirstLetter } from "../../constant/staticHelper";
import PhoneField from "../PhoneField";
import { addSubAdminSchema } from "../../utils/forms/schema.addSubAdmin";
import * as mainService from "../../services/mainService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getSubadminListAction } from "../../redux/list/listAction";
import { designationLists, genderLists } from "../../utils/GenericFunctions";
import { medicationStyles } from "../../assets/styles/MedicationStyles";

const AddSubAdminForm = ({ setOpenModal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const medicationClasses =  medicationStyles();
  const [onLoading, setOnLoading] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    phone_number: "",
    designation: "",
    permission: "",
    status: "",
    country_code: "",
    address: "",
    gender: "",
  };

  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));

  // const genderLists = Object.keys(genderList).map((key) => ({
  //   label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
  //   level: genderList[key],
  // }));

  const handleSubmit = async (values, {resetForm}) => {
    let phone = values.country_code.replace("+", "");
    values.phone_number = values.phone_number.replace(phone, "");   
    setOnLoading(true);
    const res = await mainService.addSubAdminApi({token: userData.token, data: values});
    if (res.response_type === "fail") {
      setOnLoading(false);
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      resetForm();
      setOnLoading(false);
      setOpenModal(false);
      toast.success(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
      dispatch(getSubadminListAction(userData.token))
    }
  };

  return (
    <>
      <div>
        <Grid className={classes.secondayCont}>
          <h2 className={classes.mainCont}>{strings.addSubAdmin}</h2>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={() => setOpenModal(false)}
          />
        </Grid>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={addSubAdminSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              errors,
              handleBlur,
              values,
              setFieldValue,
              setTouched,
              touched,resetForm
            }) => {
              const handleDropdownValue = (fieldName, data) => {
                if (data) {
                  setFieldValue(fieldName, data.level);
                }
              };

              const handlePhone = (e, value) => {
                setFieldValue("country_code", value);
                setFieldValue("phone_number", e);
              };

              return (
                <>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="name"
                        type="text"
                        label={`${strings.enterFullname}`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="email"
                        type="text"
                        label={`${strings.emailAddress}`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PhoneField
                        value={initialValues.phone_number}
                        name="phone_number"
                        onChange={(e, value) => handlePhone(e, value.dialCode)}
                        onBlur={() =>
                          setTouched({ ...touched, contactNumber: true })
                        }
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="gender"
                        label={`${strings.selectGender}`}
                        list={genderLists}
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data)=>handleDropdownValue('gender',data)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="status"
                        label={`${strings.selectStatus}`}
                        list={status}
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("status", data)
                        }
                      />
                    </Grid>                    
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="designation"
                        label={`${strings.selectDesignation}`}
                        list={designationLists}
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("designation", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="permission"
                        label={`${strings.selectPermission}`}
                        list={permission}
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("permission", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="address"
                        type="text"
                        label={`${strings.selectAddress}`}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      display="flex"
                      justifyContent={"center"}
                    >
                      <StyledSecondaryButton
                        name="submit"
                        disabled={Object.keys(errors).length > 0 || onLoading}
                        onClick={handleSubmit}
                        variant="contained"
                      >                        
                        {onLoading ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                            thickness={5}
                          />
                        ) : (
                          `${strings.add}`
                        )}
                      </StyledSecondaryButton>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddSubAdminForm;

import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { toast } from 'react-toastify';
import {
	getAllPaymentMethod,
	getPatientInfoAPI,
	uploadMultipleDocuments
} from '../../../../api/patient';
import { Context } from '../../../../store';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
} from '../../../../store/types';
import { globalValidator, getUser } from "../../../../utils";

import CardComponent from '../../../../components/Card';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import Select from "react-select";
import Files from 'react-files';
import './index.css'
import {
	StyledContainer,
	StyledButtonPay,
	StyledButtonType
} from './styles';
import { symptomsArr } from '../../../../constants';
import InsideMenuBar from '../../../../components/InsideMenus';

function PatientFormBooking(props) {
	const [state, dispatch] = React.useContext(Context);
	const [paymentMethods, setPaymentMethods] = useState();
	const [filesErrorMsg, setFilesErrorMsg] = useState('');
	const allCookieData = Cookies.get();
	const doctor = JSON.parse(allCookieData.doctorInfo);

	let fileRef = React.useRef(null);
	const getPatientInfo = () => {
		loader(true);
		getPatientInfoAPI().then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			const slotAppointmentType = localStorage.getItem('slotType');
			SetPatientInfo({
				OrganizationId: res.data.OrganizationId._id,
				patientName: res.data.name,
				patientAge: _calculateAge(res.data.dob),
				patientWeight: res.data.weight,
				patientGender: res.data.gender,
				why_do_doctor: '',
				symptoms: [],
				uploadsDocuments: [],
				hypertension: false,
				diabetes: false,
				appointmentType: parseInt(slotAppointmentType) - 1,
				paymentType: 0
			})
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error);
		})
	}

	const onFilesChange = (files) => {
		fileRef.state.files= '';
		const fileSize = files.filter((item) => item.size > 5242880)
		const filesFilter = files.map((item) => item.type)
		const acceptsTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
		const filesCheckType = filesFilter;
		// eslint-disable-next-line no-extend-native
		Array.prototype.gb_inArray = function(filesCheckType, matchAll = false) {
			if (matchAll) {
				return filesCheckType.every(i => this.includes(i));
			} else {
				return filesCheckType.some(i => this.includes(i));
			}
		};
		
		const fileTypeCheck = acceptsTypes.gb_inArray(filesCheckType, true);

		if(files?.length > 5){
			setFilesErrorMsg('Maximum 5 files are allowed!')
			fileRef.state.files= '';
			return
		}
		
		if(!fileTypeCheck){
			setFilesErrorMsg('PDF or Image should be accepted!')
			fileRef.state.files= '';
			return 
		}

		if(fileSize?.length){
			setFilesErrorMsg('Please upload a file smaller than 5 MB')
			fileRef.state.files= '';
			return 
		}
		fileRef.state.files= '';
		setFilesErrorMsg('')
		SetPatientInfo((info) => ({
			...info,
			uploadsDocuments: files
		}))
	}
 
	const onFilesError = (error, file) => {
	}

	const handleChangeSymptoms = (e) => {
		SetPatientInfo(info => ({...info, symptoms: e}))
	}
	
	const [patientInfo, SetPatientInfo] = React.useState({
		OrganizationId: '',
		patientName: '',
		patientAge: '',
		patientWeight: '',
		why_do_doctor: '',
		symptoms: [],
		patientGender: '',
		uploadsDocuments: [],
		hypertension: false,
		diabetes: false,
		appointmentType: 0,
		paymentType: 0
	});

	const _calculateAge = (dateString) => { // birthday is a date
		if(dateString) {
			var today = new Date();
			var birthDate = new Date(dateString);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
					age--;
			}
			return age;
		}
    return '';
	}

	const getAllPaymentsMethods = () => {
		getAllPaymentMethod(doctor.doctorId).then(res => {
			setPaymentMethods(res?.data);
		}).catch(err => {
			loader(false);
			// toast.error(err?.response?.data?.error)
			// dispatch({
			// 	type: SET_ERROR,
			// 	payload: err?.response?.data?.error
			// });
		})
	}

	const gotoCheckout = (e) => {
		e.preventDefault();
		getAllPaymentsMethods();
		if(!paymentMethods?.length && patientInfo?.paymentType === 0){
			toast.error("Please select cash method!")
				dispatch({
					type: SET_ERROR,
					payload: "Only cash payment is accepted!"
				});
			return false
		}
    const keys = [
      { name: "patientName", title: "Name", validateKey: "required" },
			{ name: "patientAge", title: "Age", validateKey: "required" },
			{ name: "patientWeight", title: "Weight", validateKey: "required" },
			{ name: "symptoms", title: "Symptoms", validateKey: "required" },
			{ name: "why_do_doctor", title: "Why do you need doctor", validateKey: "required" }
    ];
		let valid = globalValidator(patientInfo, keys);
		if(valid === true) {
			patientInfo.symptoms = patientInfo?.symptoms?.map(item => {
				return item?.value;
			});
			Cookies.set('appointment', JSON.stringify(patientInfo), { path: '' });
			// Cookies.set('paymentMethod', JSON.stringify(patientInfo.paymentType), { path: '' });
			localStorage.setItem('paymentMethod', patientInfo.paymentType);

			const formData = new FormData();
			Object.keys(patientInfo).forEach(key => {
				if (key === "uploadsDocuments") {
					patientInfo[key].map(doc => {
						formData.append("uploadsDocuments", doc, doc.name);
					});
				}
			});

			uploadMultipleDocuments(formData).then(res => {
				localStorage.setItem('uploadDocuments', JSON.stringify(res.data));
				props.history.push('/patient/checkout-payment');
			}).catch(err => {
				toast.error(err)
				dispatch({
					type: SET_ERROR,
					payload: err
				});
			});
			// localStorage.setItem('appointment', JSON.stringify(patientInfo))
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	
	const filesRemoveOne = (file) => {
		const files = patientInfo.uploadsDocuments.filter((item) => item.id!==file.id)
		fileRef.state.files=files
		SetPatientInfo((info) => ({
			...info,
			uploadsDocuments: files
		}))
	  }
	// const gotoCheckout = () => {
		// props.history.push('/patient/checkout-payment');
	// }

	useEffect(() => {
		getAllPaymentsMethods();
		getPatientInfo();
	}, [])

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>

				<div className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12 col-lg-12 col-xl-12">
								<CardComponent className="p-4">
									<div className="row pt-2">
										<div className="p-4 col-md-3 form-group ">
											<label>Patient name <small style={{color: 'red'}}>*</small></label>
											<InputField value={patientInfo.patientName} onChange={e => SetPatientInfo((user) => ({...user, patientName: e.target.value}))}  />
										</div>
										<div className="p-4 col-md-3 form-group ">
											<label>Patient age <small style={{color: 'red'}}>*</small></label>
											<InputField value={patientInfo.patientAge} onChange={e => SetPatientInfo((user) => ({...user, patientAge: e.target.value}))}  />
										</div>
										<div className="p-4 col-md-3 form-group ">
											<label>Weight (in kgs) <small style={{color: 'red'}}>*</small></label>
											<InputField value={patientInfo.patientWeight} onChange={e => SetPatientInfo((user) => ({...user, patientWeight: e.target.value}))}  />
										</div>
										<div className="p-4 col-md-3 form-group ">
											<label>Gender <small style={{color: 'red'}}>*</small></label>
											<SelectField onChange={e => SetPatientInfo((user) => ({...user, patientGender: e.target.value}))} value={patientInfo.patientGender}  options={[{label: 'Male', value: 'male'}, {label: 'Female', value: 'female'}, {label: 'Other', value: 'other'}]}  />
										</div>
									</div>

									<div className="row pl-2 pr-2">
										<div className="col-md-12 form-group">
											<label>Symptoms <small style={{color: 'red'}}>*</small></label>
											<Select
												type="select"
												name="symptoms"
												isMulti
												closeMenuOnSelect={false}
												onChange={(e) => handleChangeSymptoms(e)}
												options={symptomsArr.map(symptom => {
													return {
														label: symptom.label,
														value: symptom.label,
														isDisabled: symptom.isDisabled
													};
												})}
												isOptionDisabled={(option) => option.isDisabled}
											/>
										</div>
									</div>

									<div className="row pl-2 pr-2">
										<div className="col-md-12 form-group">
											<label>Why do you need to cosult the doctor? <small style={{color: 'red'}}>*</small></label>
											<InputField value={patientInfo.why_do_doctor} onChange={e => SetPatientInfo((user) => ({...user, why_do_doctor: e.target.value}))}  />
										</div>
									</div>

									<div className="row pl-2 pr-2">
										<div className="col-md-8 form-group">
											<div className="row">	
												<div className="col-md-6 form-group">
													<label>Hypertension</label>
													<div className="d-flex">
														
														{/* <label className="container">Yes
															<input type="radio" name="radio1" value={patientInfo.hypertension} onChange={e => SetPatientInfo((user) => ({...user, hypertension: true}))} />
															<span className="checkmark"></span>
														</label>
														<label className="container">No
															<input type="radio" checked="checked" name="radio1" value={patientInfo.hypertension} onChange={e => SetPatientInfo((user) => ({...user, hypertension: false}))} />
															<span className="checkmark"></span>
														</label> */}

														<div class="btn-group me-2" role="group" aria-label="First group">
															<button type="button" className={patientInfo.hypertension ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, hypertension: true}))}>Yes</button>
															<button type="button" className={!patientInfo.hypertension ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, hypertension: false}))}>No</button>
														</div>

													</div>
												</div>

												<div className="col-md-6 form-group">
													<label>Diabetes</label>
													<div className="d-flex">
														
														{/* <label className="container">Yes
															<input type="radio" name="radio2" value={patientInfo.diabetes} onChange={e => SetPatientInfo((user) => ({...user, diabetes: true}))} />
															<span className="checkmark"></span>
														</label>
														<label className="container">No
															<input type="radio" checked="checked" name="radio2" value={patientInfo.diabetes} onChange={e => SetPatientInfo((user) => ({...user, diabetes: false}))} />
															<span className="checkmark"></span>
														</label> */}

														<div class="btn-group me-2" role="group" aria-label="First group">
															<button type="button" className={patientInfo.diabetes ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, diabetes: true}))}>Yes</button>
															<button type="button" className={!patientInfo.diabetes ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, diabetes: false}))}>No</button>
														</div>

													</div>
												</div>
											</div>
										</div>

										<div className="col-md-4 form-group">
											<label>Payment method</label>
											<div className="d-flex btn-group-app">
												<StyledButtonType onClick={e => SetPatientInfo((user) => ({...user, paymentType: 0}))} className={patientInfo.paymentType === 0 ? 'active-blue-btn' : ''}>
													Online pay
												</StyledButtonType>
												<StyledButtonType onClick={e => SetPatientInfo((user) => ({...user, paymentType: 1}))} className={patientInfo.paymentType === 1 ? 'active-blue-btn' : ''}>
													Cash
												</StyledButtonType>
											</div>
										</div>
									</div>

									<div className="row pl-2 pr-2">
										<div className="col-md-12 form-group">
											<label>Upload documents <small>(You can upload 5 files)</small></label>
											<Files
												ref={(fileItem)=>fileRef=fileItem}
												className='files-dropzone'
												onChange={onFilesChange}
												onError={onFilesError}
												// accepts={['image/*', '.pdf']}
												// accepts={['image/*', '.pdf']}
												multiple
												// maxFiles={5}
												// maxFileSize={10000000}
												minFileSize={0}
												clickable
											>
												<div>Drop files here or click to upload</div>
											</Files>
											{filesErrorMsg ? <p style={{color: 'red'}}>{filesErrorMsg}</p> : ''}

											{
												patientInfo.uploadsDocuments && patientInfo.uploadsDocuments.length > 0
													? 
													<div className="wardRoomList d-flex flex-wrap mt-2">
													{patientInfo.uploadsDocuments.map((file) =>

														<div className="col-md-2 col room-view mt-2">
															<div>
																<img src="/assets/img/file-uploaded.png" />
																<div
																	id={file.id}
																	className='files-list-item-remove'
																	onClick={() => filesRemoveOne(file)} // eslint-disable-line
																/>
															</div>
														</div>
																
													
														)}
													</div>
													: null
											}
										</div>
									</div>
								</CardComponent>
								
								<div class="submit-section proceed-btn text-right">
									<StyledButtonPay class="btn btn-primary submit-btn" onClick={(e) =>gotoCheckout(e)}>Proceed </StyledButtonPay>
								</div>

							</div>
						</div>
					</div>
				</div>

		</StyledContainer>
	)
}

export default PatientFormBooking

import React from 'react'

function SelectField(props) {
	const { name = "", className  } = props;
	return (
		<select name={name} className={`form-control select ${className}`} onChange={props.onChange} value={props.value} placeholder={props.placeholder} >
			<option value="" disabled selected hidden>Please Choose...</option>
			{
				props.options.length > 0 && props.options.map(item => <option key={item.id} value={item.value} disabled={item.disable}>{item.label}</option>)
			}
		</select>
	)
}

export default SelectField

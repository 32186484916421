import React from 'react'

const Support = () => {
    return <div style={{
        justifyContent: 'center',
        display: 'flex',
        height: '300px',
        paddingTop: 150
    }}>
        Coming Soon...
    </div> 
}

export default Support;
import styled from 'styled-components';

export const StyledContainer = styled.div`
  
`;

export const StyledHeader = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledBackTo = styled.div`
	border: 2px solid #5156be;
	border-radius: 4px;
	padding: 10px 15px !important;
	text-align: center;
	font-size: 15px;
	color: #5156be;
	font-weight: 500;
	cursor: pointer;

	a {
		color: #5156be;
	}

	i {
		padding-right: 10px;
	}
`;

export const StyledTitle = styled.h2`
	margin-left: auto;
`;

export const StyledAddEditPatient = styled.button`
	margin-left: auto;
	cursor: pointer;
	border: none;
	color: #fff;
	
`;

export const StyledSlots = styled.ul`
	display: grid;
	margin-top: 10px;

	li {
		width: 100%;

		a {
			background-color: #e9e9e9;
			border: 1px solid #e9e9e9;
			border-radius: 3px;
			color: #757575;
			display: block;
			font-size: 14px;
			margin-bottom: 10px;
			padding: 5px 5px;
			text-align: center;
			position: relative;
			cursor: pointer;
		}

		.selected {
			background-color: #42c0fb;
			border: 1px solid #42c0fb;
			color: #fff;
		}
	}
	
`;

export const StyledButtonPay = styled.button`
	background-color: #5156be;
	color: #fff;
	border: 1px solid #5156be;
	margin-top: 20px;
	padding: 12px 25px;
	border-radius: 5px;
	font-weight: 700;
`;
import React, { useContext, useEffect } from 'react';

import moment from 'moment';

import ReactStars from "react-rating-stars-component";

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getAllCompletedApp
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import DoctorSidebar from '../../components/Sidebar';
import CardComponent from '../../../../components/Card';


function DoctorReviews(props) {

	const [state, dispatch] = useContext(Context);

	const [appointment, setAppointment] = React.useState([]);

	useEffect(() => {
		loader(true);
		getAllCompletedApp().then(res => {
			setAppointment(res.data);
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
        <div>
            <div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
							<DoctorSidebar  doctor={props}  />
						</div>

						<div className="col-md-7 col-lg-8 col-xl-9">
							<h3>Reviews listings</h3>

							<div className="doc-review review-listing">

								<ul className="comments-list">
									{
										appointment.map(item => {
											return <li>
															<div class="comment">
																<img class="avatar rounded-circle" alt="User Image" src={item.patientId.profileImage ? item.patientId.profileImage : "/assets/img/loading-img.jpg"} />
																<div class="comment-body">
																	<div class="meta-data">
																		<span class="comment-author">{item.patientId.name}</span>
																		<span class="comment-date">Reviewed {moment(item.last_updated).startOf('day').fromNow()}</span>
																		<div class="review-count rating">
																			{/* <i class="fas fa-star filled"></i>
																			<i class="fas fa-star filled"></i>
																			<i class="fas fa-star filled"></i>
																			<i class="fas fa-star filled"></i>
																			<i class="fas fa-star"></i> */}
																			<ReactStars
																				count={5}
																				value={item.rating}
																				isHalf={true}
																				edit={false}
																				size={17}
																				activeColor="#ffd700"
																			/>
																		</div>
																	</div>
																	<p class="recommended"><i class="far fa-thumbs-up"></i> I recommend the doctor</p>
																	<p class="comment-content">
																		{item.feedback}
																	</p>
																	
																</div>
															</div>
														</li>
										})
									}
									
								</ul>

							</div>
							
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

export default DoctorReviews

import styled from "styled-components";

import { ReactComponent as RightArrow } from '../../../assets/img/right-arrow.svg';

export const ConductStudyWrapper = styled.div`
	background: #FFFFFF;
`;

export const BackgroundImage = styled.img`
	width: 100%;
`;

export const Background = styled.div`
	background: url('../../../assets/img/conduct-study-image.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-size: 100% 100%;
	width: 100%;
`;

export const ImageCardContainer = styled.div`
	position: relative;
`;

export const HoverCardWrapperTest = styled.div`
	position: absolute;
	top:0;
	left:0;
	right:0;
	width: 100%;
	height:100%;

	display: flex;
    justify-content: center;
    align-items: center;
`;


export const HandleCard = styled.div`
	width: 501px;
	height: fit-content;
	background: linear-gradient(106.69deg, rgba(255, 255, 255, 0.6) 1.18%, rgba(255, 255, 255, 0.4) 100%);
	border: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
	border-radius: 18px;
	padding: 32px 24px 28px;

	@media (min-width: 430px) and (max-width: 530px) {
		margin: 0 20px;
		padding: 3%;
		border-radius: 10px;
	}


	@media (min-width: 400px) and (max-width: 430px) {
		padding: 3%;
		border-radius: 10px;
		width: calc(100% - 5%);
	}

	@media (max-width: 400px) {
		border-radius: 6px;
		padding: 8px;
		width: calc(100% - 4%);
	}
`;

// export const HoverCardWrapper = styled.div`
// 	position: absolute;
// 	top: 26%;
// 	left: 70px;
// 	width: 501px;
// 	height: fit-content;
// 	background: linear-gradient(106.69deg, rgba(255, 255, 255, 0.6) 1.18%, rgba(255, 255, 255, 0.4) 100%);
// 	border: 1px solid rgba(255, 255, 255, 0.2);
// 	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
// 	border-radius: 18px;
// 	padding: 32px 24px 28px;

// 	@media (max-width: 400px) {
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		bottom: 0;
// 		padding: 8px 8px 6px;
// 		border-radius: 6px;
// 		width: 97%;
// 		margin: 2px 5px 0;
// 	}
// 	@media (max-width: 1000px) {
// 		position: absolute;
// 		top: 10vh;
// 		left: 0;
// 		right: 0;
// 		margin-left: auto;
// 		margin-right: auto;
// 	}

// 	@media (max-width: 768px) {
// 		top: 4vw;
// 		left: 0;
// 		right: 0;
// 		margin-left: auto;
// 		margin-right: auto;
// 		width: calc(100% - 20%);
// 	}

// 	@media (max-width: 570px) {
// 		width: calc(100% - 5%);
// 	}

// 	@media (max-width: 346px) {
// 		padding: 2%;
// 		top: 1%;
// 	}

// 	@media (min-width: 400px) and (max-width: 520px) {
// 		padding: 3%;
//     	width: calc(100% - 10%);
// 	}
// `;


export const HoverCard = styled.div`
	position: absolute;
	top: 26%;
	left: 70px;
	width: 501px;
	height: fit-content;
	background: linear-gradient(106.69deg, rgba(255, 255, 255, 0.6) 1.18%, rgba(255, 255, 255, 0.4) 100%);
	border: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
	border-radius: 18px;
	padding: 32px 24px 28px;
`;

export const CardTitle = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 42px;
	color: #FFFFFF;
	
	@media (min-width: 520px) and (max-width: 650px) {
		font-size: 24px;
		line-height: 24px;
	}

	@media (max-width: 520px) {
		font-size: 18px;
		line-height: 30px;
	}

	@media (max-width: 400px) {
		font-size: 14px;
		line-height: 17px;
	}

`;

export const CardDescription = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #FFFFFF;
	
	margin: 16px 0 0;

	@media (min-width: 520px) and (max-width: 650px) {
		font-size: 14px;
    	line-height: 20px;
	}

	@media (max-width: 520px) {
		font-size: 14px;
		line-height: 17px;
		margin: 4px 0 0;
	}

	@media (max-width: 400px) {
		font-size: 12px;
		line-height: 16px;
		margin: 1px 0 0;
	}
`;

export const CardButton = styled.div`
	width: 100%;
	height: 48px;
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	border-radius: 8px;
	margin: 40px 0 0;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;
	color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	@media (min-width: 450px) and (max-width: 830px) {
		margin: 20px 0 0px;
	}

	@media (min-width: 520px) and (max-width: 650px) {
		font-size: 14px;
	}

	@media (max-width: 520px) {
		height: 32px;
		margin: 4px 0 0;
		font-size: 12px;
    	line-height: 9px;
	}

	@media (max-width: 450px) {
		height: 32px;
		margin: 10px 0 0;
		font-size: 12px;
		line-height: 9px;
	}

	@media (max-width: 400px) {
		font-size: 12px;
		line-height: 9px;
		margin: 3px 0 0;
	}
`;

export const RightArrowIcon = styled(props => <RightArrow {...props}/>)`
    margin-left: 11px;
	&.read-now {
		width: 6px;
		margin-left: 9px;
	}


	@media (min-width: 320px) and (max-width: 650px) {
		width: 7px;
	}
`;


export const ClinicStudyTitle = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 56px;
	line-height: 72px;
	color: #2B2B2B;
	display: flex;
    justify-content: center;
    margin: 48px 0 0;
	@media (max-width: 768px) {
		font-size: 36px;
		// margin: 28px 0 40px 0;
		margin: 16px 0 16px 0;
	}
`;

export const SectionStudy = styled.div`
	padding: 100px 80px 100px;
	position: relative;
	@media (max-width: 768px) {
		padding: 0 20px 20px 20px ;
	}
`;

export const StudyHeading = styled.div`
    display: flex;
	overflow: scroll;
	padding: 10px 0 0;
	
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
	scrollbar-width: none;  /* Firefox */
	&::-webkit-scrollbar { 
		display: none;  /* Safari and Chrome */
	  }
`;
export const Border = styled.div`
    border-bottom: 1px solid #CCCCCC;
	height: 1px;
	width:100%;
	position: absolute;
	bottom:0
`;

export const TextWrapper = styled.div`
    width: 20%;
    border-bottom: ${(props) => props.border ? '5px solid #378ba0' : '1px solid #CCCCCC'};
	justify-content:center;
    display: flex;
	@media (max-width: 768px) {
		width: 200px;
		margin-right: 30px;
		border-bottom: ${(props) => props.border ? '5px solid #378ba0' : 'none'};
	}
`;

export const TagsOut = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 14px 0 0;
`;

export const TagMain = styled.div`
	background: ${(props) => props.active ? 'linear-gradient(90deg, rgba(9, 126, 183, 0.7) 0%, rgba(42, 171, 108, 0.7) 100%)' : 'transparent'};
	border-radius: 48px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	margin-right: 10px;
	margin-top: 10px;
	padding: 0px 12px;
	cursor: pointer;
	height: 33px;
	border:  ${(props) => props.active ? '1px solid transparent' : '1px solid #5E5E5E'};
`;

export const TagText = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	text-transform: capitalize;
	color:  ${(props) => props.active ? '#FFFFFF' : '#5E5E5E'};
`;


export const ConductStudyModeTitle = styled.div`
    width: max-content;
	text-align:center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 52px;
    text-align: center;
    color: #2B2B2B;
    cursor: pointer;
	text-transform: uppercase;
	@media (min-width: 760px) and (max-width: 828px) {
		font-size: 87%;
	}
	@media (min-width: 828px) and (max-width: 1000px) {
		font-size: 95%;
	}
	@media (min-width: 1000px) and (max-width: 1300px) {
		font-size: 18px;
	}
	@media (max-width: 760px) {
		font-size: 16px;
	}
    &.colored {
        background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }
`;

export const RenderItemsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 88px 0 0;

	@media (max-width: 600px) {
		display: flex;
    	justify-content: center;
		margin: 24px 0 0;
	}
`;

export const TextWithCountWrapper = styled.div`
	position: relative;
	&.single-line {
		display: flex;
		align-items: center;
	}
`;

export const TagsCount = styled.div`
	position: absolute;
	top: -2px;
    right: -15px;
	min-width: 16px;
    // height: 16px;
	// padding: 1px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	border-radius: 24px;
`;
	
export const TagsCountSingleLine = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
	color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
	background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	border-radius: 24px;
	position: initial;
	width: 16px;
	height: 16px;
`;






import { object, string, number , array} from "yup";

export const personalDetailsSchema = object({
    name: string()
        .matches(/^(?!\\s)[a-zA-Z ]*$/, "Invalid input")
        .min(3, "Minimum 3 characters required")
        .required("Name is required"),
        
    age: number()
        .typeError("Age must be a number")
        .min(0, "Age must be greater than 0")
        .max(150, "Age must be less than or equal to 150")
        .required("Age is required"),
    
    weight: number()
        .typeError("Weight must be a number")
        .min(0, "Weight must be greater than or equal to 0")
        .required("Weight is required"),
    
    gender: string().required("Gender is required"),
    symptoms: array().nullable()
        .min(1, "At least one symptom is required")
        .required("Symptoms is required"),
    documents: array().nullable()
        .min(1, "At least one document is required")
        .required("Documents is required"),
});

export const personalDetailsSchemaForSubadmin = object({
    patientId: string()
        .min(10, "Minimum 10  characters required")
        .max(30, "Maximum 30  characters required")
        .required("patientId is required"),
    symptoms: array().nullable()
        .min(1, "At least one symptom is required")
        .required("Symptoms is required"),
    documents: array().nullable()
        .min(1, "At least one document is required")
        .required("Documents is required"),
});
import React from 'react';

import { Link } from 'react-router-dom';

import {
  StyledBreadcrumb
} from './styles'

function Breadcrumb(props) {
	return (
		<StyledBreadcrumb className="breadcrumb-bar">
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-md-12 col-12">
						<nav aria-label="breadcrumb" className="page-breadcrumb">
							<ol className="breadcrumb">
								{
									props.backRoute && props.backRoute.map(item => {
										return <li key={item.id} className="breadcrumb-item"><Link to={item.path ? item.path : '/'}>{item.route}</Link></li>
									})
								}
								
								<li className="breadcrumb-item active" aria-current="page">{props.currentRoute}</li>
							</ol>
						</nav>
						<h2 className="breadcrumb-title">{props.title}</h2>
					</div>
				</div>
			</div>
		</StyledBreadcrumb>
	)
}

export default Breadcrumb

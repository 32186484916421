import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: null,
  patientAppointmentList: [],
  doctorList: [],
  doctorSlotList: [],
};

const patientAppointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    //appointment list and doctor slot list
    getpatientAppointmentListRequest(state, { payload }) {
      state.patientAppointmentList = [];
      state.loading = true;
      state.error = null;
      state.doctorSlotList = [];
    },
    getpatientAppointmentListSuccess(state, { payload }) {
      state.patientAppointmentList = payload;
      state.loading = false;
      state.error = null;
      state.doctorSlotList = [];
    },
    getpatientAppointmentListFailure(state, { payload }) {
      state.patientAppointmentList = [];
      state.loading = false;
      state.error = payload;
      state.doctorSlotList = [];
    },
    //doctor list
    getdoctorListSuccess(state, { payload }) {
      state.doctorList = payload;
      state.loading = false;
      state.error = null;
    },
    getdoctorListFailure(state, { payload }) {
      state.doctorList = [];
      state.loading = false;
      state.error = payload;
    },
     //doctor slot list
    getDoctorSlotListRequest(state) {
      state.doctorSlotList = [];
      state.loading = true;
      state.error = null;
    },
    getDoctorSlotListSuccess(state, { payload }) {
      state.doctorSlotList = payload;
      state.loading = false;
      state.error = null;
    },
    getDoctorSlotListFailure(state, { payload }) {
      state.doctorSlotList = [];
      state.loading = false;
      state.error = payload;
    },
    //cancel appointment
    cancelAppointmentRequest(state) {
      state.patientAppointmentList = [];
      state.loading = true;
      state.error = null;
    },
    cancelAppointmentSuccess(state, { payload }) {
      state.patientAppointmentList= payload;
      state.loading = false;
      state.error = null;
    },
    cancelAppointmentFailure(state, { payload }) {
      state.patientAppointmentList = [];
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { getpatientAppointmentListRequest,  getpatientAppointmentListSuccess, getpatientAppointmentListFailure,
  getdoctorListSuccess, getdoctorListFailure, getDoctorSlotListRequest, getDoctorSlotListSuccess, getDoctorSlotListFailure,
  cancelAppointmentRequest,cancelAppointmentSuccess,cancelAppointmentFailure
} = patientAppointmentSlice.actions;

export default patientAppointmentSlice.reducer;

import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import SubadminHome from "../../components/ui/Subadmin/Home";


const SubAdminDashboard = () => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        <SubadminHome />
      </AppLayout>
    </Grid>
  )
}

export default SubAdminDashboard;
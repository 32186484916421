import React, {useState} from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useStyles } from '../../pages/QRcodePage/QrcodeStyles';
import {Grid} from '@mui/material';



const textStyle= {
  "& .MuiFormControlLabel-label": {
    fontFamily:" Poppins",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left"
    
  }
}

export default function IndeterminateCheckbox({list, paramName, fun }) {
  const classes= useStyles();
  const [data, setData] = React.useState(list);

    const handleChange=(event)=> {
      const {name, checked}=event.target;
      if(name=== "Parent"){
        let temp = data.map((user)=> { return  {...user, isChecked: checked}}
       )
       setData(temp);
       
       fun(temp, paramName)
       
  
      } else {
        let temp = data.map((user) =>
          user.name === name ? { ...user, isChecked: checked } : user
        );
        setData(temp);
        fun(temp, paramName)
      }  
    }
  
   
  
    const children = (
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}  >
        <Grid container  columnSpacing={2}>
  
        { data.map((user, index) => <Grid item xs={6} md={12}> <FormControlLabel
          key={index}
          label={user.name}
          className={classes.textStyle3}
          control={<Checkbox  name= {user.name}
          checked= {user?.isChecked || false}
          onChange={handleChange}
          sx={{ "&.Mui-checked":{
            color: "rgba(42, 171, 108, 1)"
          },
        "&.MuiCheckbox-root" : {
          padding: "0px"
        }}
      }
            />}
          sx={textStyle}
        /> </Grid> )}
       </Grid>
      </Box>
    );
  
    return (
      <div>
        <FormControlLabel
          label={paramName}
          control={
            <Checkbox
              name = "Parent"
              checked= {data.filter((user) => user?.isChecked !== true).length < 1}
             onChange={handleChange}
             sx={{ "&.Mui-checked":{
              color: "rgba(42, 171, 108, 1)"
            },
          "&.MuiCheckbox-root" : {
            padding: "1px"
          }}
        } 
           />
          }
          sx={textStyle}
        />
        {children}
      </div>
    );
  }
  
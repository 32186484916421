import colors from "../../constant/colors";
import { makeStyles } from "@mui/styles";

const commonMuiStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8, // Set the border radius to 20px
      backgroundColor: colors.white3,
      //   backg: `${colors.textFieldColor}`,
    },
  },
  gradientColor: {
    background:
      "linear-gradient(90deg, rgba(9,126,183,1) 0%, rgba(42,171,108,1) 85%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  gradientBackground: {
    background:
      "linear-gradient(90deg, rgba(9,126,183,1) 0%, rgba(42,171,108,1) 85%)",
  },
  textGradientColor: {
    background: colors.textColor,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  iconStyle: {
    color: colors.grey1,
    width: 18,
    height: 18,
  },
  iconStyle40: {
    width: "40px",
    height: "40px",
  },
  customTab: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    background: colors.textColor,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  customTabIndicator: {
    background: colors.textColor,
  },
  customAutocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      background: colors.white3,
      borderRadius: "8px",
    },
    "& .MuiAutocomplete-input": { padding: 0, color: "black" },
  },
  fontText12: {
    fontFamily: "Poppins !important",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  rootSubadmin: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 15,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    transition: 'background 0.3s ease',
  },
  active: {
    background: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
  },
  deactive: {
    background: 'linear-gradient(45deg, #FF3E4D 30%, #FF6E70 90%)',
  },
  fontText14: {
    fontFamily: "Poppins !important",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "18px" /* 128.571% */,
  },
  fontText24: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
    letterSpacing: "0em",
    textAlign: "left",
  },

  rightFlex: {
    display: "flex",
    justifyContent: "right",
  },

  borderStyle: {
    borderRadius: "8px",
    border: " 2px solid var(--grey-2, #F5F5F7)",
  },
  centerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centerColumnStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
  columnStyle: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
  },

  scrollHorizontally: {
    overflowX: 'auto', 
    whiteSpace: 'nowrap', 
    maxWidth:' 100% ',
  },
  scrollVertically : {
    overflow: 'auto', 
    whiteSpace: 'nowrap', 
  },
  styleDatePicker : {
    width: '100% !important',
    "& .MuiStack-root": {
      flexDirection: "column",
    },
    '& .MuiInputBase-root': {
      background: '#F8F7F7',
    }
  }

}));

export { commonMuiStyles };

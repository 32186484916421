import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Box,
  CircularProgress,
  IconButton, // Import IconButton for counter buttons
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material"; // Import icons for the counter
import { useSelector } from "react-redux";
import axios from "axios";
import * as mainService from "../../services/mainService"
import { toast } from "react-toastify";
import useRazorpay from "react-razorpay";
import { useHistory } from "react-router-dom";
import { GET_PLANS } from "../../services/apiUrl";

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(true);
  const [wholeData, setWholeData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState("BOTH");
  const [quantities, setQuantities] = useState({}); // Store quantities for each plan
  const [Razorpay] = useRazorpay();
  const urls = GET_PLANS
  const key =process.env.REACT_APP_PAYMENT_KEY
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));
const history = useHistory()
  const pricingData = {
    yearly: {
      IPD: "$48.99/Yearly",
      OPD: "$48.99/Yearly",
      BOTH: "$48.99/Yearly",
    },
    monthly: {
      IPD: "$4.99/Monthly",
      OPD: "$4.99/Monthly",
      BOTH: "$4.99/Monthly",
    },
  };

  const plans = [
    { id: "IPD", name: "In-patient Department (IPD)" },
    { id: "BOTH", name: "Both IPD & OPD" },
    { id: "DDDD", name: "Both IPD & OPD" },
    { id: "OPD", name: "Out-patient Department (OPD)" },
  ];

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  const handleCardClick = (plan) => {
    setSelectedPlan(plan);
  };

  const handleQuantityChange = (planId, value) => {
    setQuantities((prev) => ({
      ...prev,
      [planId]: Math.max(1, (prev[planId] || 1) + value), // Ensure quantity doesn't go below 0
    }));
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          urls,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        setWholeData(response.data.data);
      } catch (error) {
        console.error("Error fetching plans:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handlePayment = async (item) => {
    const quantiesID = item.id;
    if (quantities[quantiesID]==0){
      alert("Please select a number");
    }else{
      const payload = {
        token: userData.token,
        data: {
          plan_id: item.id,
          quantity: quantities[quantiesID]||1,
        },
      };
      const Res1 = await mainService.getSubscriptionDetails(payload)
      
      if (Res1.response_type =="fail"){
        toast.error(Res1?.response?.message,{position:"bottom-center",closeButton:false,autoClose:1000,style:{width:"auto", borderRadius:8}});

    }else{
      handlePaymentRayor(Res1.response.data)
    }
    }
    
    
  };
  const handlePaymentRayor = async (params) => {
    console.log(params);
    
    const options = {
        key,
				subscription_id: params.id,
        notes: params?.notes,
				callback_url: params.short_url,
        handler: async function  (response) {
          console.log("response",response)
              history.push("/organization/dashboard")
              toast.success("Subscription Sucessfull ",{position:"bottom-center",closeButton:false,autoClose:1000,style:{width:"auto",borderRadius:8}});
    },
    };
  
    
  
    const rzp1 = new Razorpay(options);
  
    rzp1.on("payment.failed", function (response) {
      toast.error(`${response.error.source} ${response.error.description} `,{position:"bottom-center",closeButton:false,autoClose:1000,style:{width:"auto", borderRadius:8}});
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
  
    rzp1.open();
  }

  return (
    <Box
      sx={{
        background:
          "linear-gradient(135deg, rgba(9, 126, 183, 1) 0%, rgba(42, 171, 108, 1) 100%)",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="lg" sx={{ textAlign: "center", mt: 4 }}>
        {/* Toggle Button for Monthly/Yearly */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 0,
          }}
        >
          {/* <Typography mb={1} variant="body1">
            Monthly
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isYearly}
                onChange={handleToggle}
                color="primary"
              />
            }
            label="Yearly"
            labelPlacement="start"
          /> */}
        </Box>

        {/* Loading Indicator */}
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={60} />
          </Box>
        ) : (
          // Scrollable Horizontal Carousel
          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              gap: 3,
              paddingBottom: 2,
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {wholeData.items.map((plan) => (
              <Card
                key={plan.item.name}
                sx={{
                  minWidth: 300,
                  flexShrink: 0,
                  cursor: "pointer",
                  background: "#fff",
                  color:
                    selectedPlan === plan.item.name ? "inherit" : "inherit",
                  boxShadow:
                    selectedPlan === plan.item.name
                      ? "0px 8px 30px rgba(63, 81, 181, 0.3)"
                      : "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  zIndex: selectedPlan === plan.item.name ? 2 : 1,
                  transform:
                    selectedPlan === plan.item.name
                      ? "scale(1.05)"
                      : "scale(1)",
                  transition: "all 0.3s ease-in-out",
                }}
                onClick={() => handleCardClick(plan.item.name)}
              >
                <CardContent>
                  <Typography variant="h5">{plan.item.name}</Typography>
                  <Typography variant="h6" color="textSecondary">
                    {plan.item.currency.toString()}-{plan.item.amount/100}/
                    {plan.period}
                    {/* {isYearly
                      ? pricingData.yearly[plan.id]
                      : pricingData.monthly[plan.id]} */}
                  </Typography>
                  <ul
                    style={{ listStyleType: "none", padding: 0, marginTop: 16 }}
                  >
                    <li>Full control over your In patients</li>
                    <li>Full control over your In patients</li>
                    <li>Full control over your In patients</li>
                    <li>Full control over your In patients</li>
                    <li>Full control over your In patients</li>
                    <li>Full control over your In patients</li>
                  </ul>

                  {/* Quantity Counter */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 2,
                    }}
                  >
                    <IconButton
                      color="primary"
                      onClick={() => handleQuantityChange(plan.id, -1)}
                    >
                      <Remove />
                    </IconButton>
                    <Typography variant="body1" sx={{ mx: 2 }}>
                      {quantities[plan.id] || 1}
                    </Typography>
                    <IconButton
                      color="primary"
                      onClick={() => handleQuantityChange(plan.id, 1)}
                    >
                      <Add />
                    </IconButton>
                  </Box>

                  <Button
                    variant="contained"
                    style={{
                      color: "#000",
                    }}
                    sx={{
                      mt: 2,
                      background:
                        selectedPlan === plan.item.name
                          ? "linear-gradient(135deg, rgba(9, 126, 183, 1) 0%, rgba(42, 171, 108, 1) 100%)"
                          : "#FFF",
                      border: "2px solid transparent",
                      borderImage:
                        "linear-gradient(135deg, rgba(9, 126, 183, 1) 0%, rgba(42, 171, 108, 1) 100%)",
                      borderImageSlice: 1,
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      handlePayment(plan);
                    }}
                  >
                    GET STARTED
                  </Button>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Pricing;
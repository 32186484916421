import { apiPost, apiGet, apiPut, apiDelete } from "../utils";

export function getManagementDataAPI() {
	return apiGet(`/api/patient/get/management-team`);
}

export function getFAQSAPI() {
	return apiGet(`/api/patient/get/faqs`);
}

export function getTermsAndConditionAPI() {
	return apiGet(`/api/patient/get/terms`);
}

export function getPrivacyAPI() {
	return apiGet(`/api/patient/get/privacy-policy`);
}

export function postContactUsAPI(payload) {
    return apiPost("/api/patient/contactus", payload);
  }

export function postYogaAPI(payload) {
	return apiPost("/api/patient/yoga-post", payload);
}

export function postNLPAPI(payload) {
	return apiPost("/api/patient/nlp-post", payload);
}

export function getBlogsAPI() {
	return apiGet("/api/patient/get/blog");
}

export function getBlogDetailAPI(id) {
  return apiGet(`/api/patient/get/blogdetail/${id}`);
}

export function getBlogSlugDetailAPI(id) {
  return apiGet(`/api/patient/get/blogslug/${id}`);
}
import { object, number } from "yup";

export const bloodPressureSchema = object({
    systolic: number()
        .typeError("Systolic level must be a number")
        .min(0, "Systolic level must be greater than or equal to 0")
        .max(250, "Systolic level must be less than or equal to 250")
        .required("Systolic is required"),
    diastolic: number()
        .typeError("Diastolic level must be a number")
        .min(0, "Diastolic level must be greater than or equal to 0")
        .max(130, "Diastolic level must be less than or equal to 130")
        .required("Diastolic is required"),
    
});
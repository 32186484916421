export const DoctorSidebarData = [
  {
    title: "Schedule",
    path: "/doctor/schedule",
    img1: "/assets/img/schedule.svg",
    img2: "/assets/img/schedule.svg",
  },

  {
    title: "Appointment",
    path: "/doctor/appointments",
    img1: "/assets/img/appointment.svg",
    img2: "/assets/img/appointmentFilled.svg",
  },
  {
    title: "Messages",
    path: "/doctor/messages",
    img1: "/assets/img/message.svg",
    img2: "/assets/img/messageFilled.svg",
  },
  {
    title: "IPD",
    path: "/doctor/ipd",
    img1: "/assets/img/ipd.svg",
    img2: "/assets/img/ipd.svg",
  },
];

export const DoctorSidebarDataIPD = [
  {
    title: "IPD",
    path: "/doctor/ipd",
    img1: "/assets/img/IPD.svg",
    img2: "/assets/img/IPDfilled.svg",
  },
];

export const DoctorUserProfile = [
  {
    name: "Profile Settings",
    path: "/doctor/settings",
    img1: "/assets/img/profile.svg",
  },

  {
    name: "Billing & Invoices",
    path: "/doctor/billingInvoice",
    img1: "/assets/img/billing.svg",
  },
];

export const PatientSidebarData = [
  {
    title: "Home",
    path: "/patient/dashboard",
    img1: "/assets/img/FramehomeUnfilled.svg",
    img2: "/assets/img/homeFilled.svg",
  },
  {
    title: "Health",
    path: "/patient/health",
    img1: "/assets/img/health.svg",
    img2: "/assets/img/healthFilled.svg",
  },
  {
    title: "Messages",
    path: "/patient/messages",
    img1: "/assets/img/message.svg",
    img2: "/assets/img/messageFilled.svg",
  },
  {
    title: "Appointments",
    path: "/patient/appointment-listing",
    img1: "/assets/img/appointment.svg",
    img2: "/assets/img/appointmentFilled.svg",
  },
  {
    title: "Medical History",
    path: "/patient/medical-record",
    img1: "/assets/img/medical.svg",
    img2: "/assets/img/medicalFilled.svg",
  },
];

export const PatientUserProfile = [
  { name: "Generate QR", path: "/patient/qr-code", img1: "/assets/img/QR.svg" },
  {
    name: "Profile Settings",
    path: "/patient/settings",
    img1: "/assets/img/profile.svg",
  },
  {
    name: "Billing & Invoices",
    path: "/patient/billing_invoice",
    img1: "/assets/img/billing.svg",
  },
];

export const selectIconIndexAsPerRoutePatient = {
  "/patient/dashboard": 0,
  "/patient/health": 1,
  "/patient/messages": 2,
  "/patient/appointment-listing": 3,
  "/patient/medical-record": 4,
};

export const selectIconIndexAsPerRouteDoctor = {
  "/doctor/schedule": 0,
  "/doctor/appointments": 1,
  "/doctor/messages": 2,
};

export const selectIconIndexAsPerRouteOrganisation = {
  "/organization/dashboard": 0,
  "/organization/patient-list": 1,
  "/organization/doctor-list": 2,
  "/organization/sub-admin-list": 3,
};

export const OrganisationSidebarData = [
  {
    title: "Home",
    path: "/organization/dashboard",
    img1: "/assets/img/FramehomeUnfilled.svg",
    img2: "/assets/img/homeFilled.svg",
  },
  {
    title: "Patients",
    path: "/organization/patient-list",
    img1: "/assets/img/patientUnfilled.svg",
    img2: "/assets/img/patientFilled.svg",
  },
  {
    title: "Doctors",
    path: "/organization/doctor-list",
    img1: "/assets/img/doctorUnfilled.svg",
    img2: "/assets/img/doctorFilled.svg",
  },
  {
    title: "Sub-Admins",
    path: "/organization/sub-admin-list",
    img1: "/assets/img/subadminUnfilled.svg",
    img2: "/assets/img/subadminFilled.svg",
  },
  {
    title:"Subscription",
    path: "/organization/subscription",
    img1: "/assets/img/subadminUnfilled.svg",
    img2: "/assets/img/subadminFilled.svg",
  }
];

export const OrganisationProfile = [
  {
    name: "Profile Settings",
    path: "/organization/settings",
    img1: "/assets/img/profile.svg",
  },
  {
    name: "Billing & Invoices",
    path: "/organization/billing_invoice",
    img1: "/assets/img/billing.svg",
  },
];

//subadmin

export const selectIconIndexAsPerRouteSubadmin = {
  "/subadmin/home": 0,
  "/subadmin/appointments": 1,
  "/subadmin/ipd": 2,
  "/subadmin/rooms": 3,
};

export const SubadminSidebarData = [
  {
    title: "Home",
    path: "/subadmin/home",
    img1: "/assets/img/FramehomeUnfilled.svg",
    img2: "/assets/img/FramehomeUnfilled.svg",
  },

  {
    title: "Appointment",
    path: "/subadmin/appointments",
    img1: "/assets/img/appointment.svg",
    img2: "/assets/img/appointment.svg",
  },
  {
    title: "IPD",
    path: "/subadmin/ipd",
    img1: "/assets/img/ipd.svg",
    img2: "/assets/img/ipd.svg",
  },
  {
    title: "Rooms",
    path: "/subadmin/rooms",
    img1: "/assets/img/message.svg",
    img2: "/assets/img/messageFilled.svg",
  },
];

export const SubadminUserProfile = [
  {
    name: "Profile Settings",
    path: "/subadmin/settings",
    img1: "/assets/img/profile.svg",
  },
  {
    name: "Billing & Invoices",
    path: "/subadmin/billingInvoice",
    img1: "/assets/img/billing.svg",
  },
];

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orgProfileData: {},
  loading: false,
  error: null,

};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    getProfileByOrganizationRequest(state) {
      state.orgProfileData = state.orgProfileData;
      state.loading = true;
      state.error = null;
    },
    getProfileByOrganizationSuccess(state, { payload }) {
      state.orgProfileData = payload;
      state.loading = false;
      state.error = null;
    },
    getProfileByOrganizationFailure(state, { payload }) {
      state.orgProfileData = {};
      state.loading = false;
      state.error = payload;
    },

    updateProfileByOrganizationRequest(state) {
      state.orgProfileData = {};
      state.loading = true;
      state.error = null;
    },
    updateProfileByOrganizationSuccess(state, { payload }) {
      state.orgProfileData = payload;
      state.loading = false;
      state.error = null;
    },
    updateProfileByOrganizationFailure(state, { payload }) {
      state.orgProfileData = {};
      state.loading = false;
      state.error = payload;
    },

  },
});

export const {getProfileByOrganizationRequest, getProfileByOrganizationSuccess, getProfileByOrganizationFailure,
  updateProfileByOrganizationRequest, updateProfileByOrganizationSuccess, updateProfileByOrganizationFailure } = organizationSlice.actions;

export default organizationSlice.reducer;

import React from 'react';
import CardComponent from '../../../components/Card';
import moment from 'moment';
import './subusers.css';
import PatientInfoModal from '../Panel/IpdWard/Patient/component/PatientInfoModal';
import PatientDetailModal from './PatientDetailModal';
import { getPayLogo } from '../../../constants';

const PatientInfo = (props) => {
    const { patientInfo, patientDetails, showInfoButton, appointment, showBookingInfo, title = "View patient info", modalTitle="Patient info" } = props
    const [modalVisible, setModalVisible] = React.useState(false);
    const [patientDetailsModal, setPatientDetailsModal] = React.useState(false);
    const renderAppointmentAmount = () => {
        const amount = appointment?.amount.split('');
        const index = amount?.findIndex((char) => /^[0-9]$/.test(char))
        if(!index){
            return `${parseInt(appointment?.amount) + appointment?.pendingAmount}` 
        }else {
            return  `${appointment?.amount.slice(0,index)}${parseInt(appointment?.amount.slice(index)) + appointment?.pendingAmount}` 
        }
    }
    return (
        <CardComponent className="mb-4">
            <div className="widget-profile pro-widget-content">
                <div className="profile-info-widget d-flex">
                    <a className="booking-doc-img m-auto" style={{backgroundColor : 'transparent'}}>
                        <img  style={{width:'80px',height:'80px'}} src={patientInfo.profileImage ? patientInfo.profileImage : "/assets/img/loading-img.jpg"} alt="User" />
                    </a>
                    <div className="profile-det-info patient-detail-info">
                        <h3 className='m-0 pb-2 p-0'>{patientInfo.name}</h3>
                        <div className="patient-details">
                            <h5>Id : {patientInfo ? patientInfo._id : '-'}</h5>
                            <h5><i className="fas fa-birthday-cake"></i> {patientInfo.dob ? moment(patientInfo.dob).format('Do MMMM, YYYY') : '-'}</h5>
                            <h5><i class="fa fa-phone" aria-hidden="true"></i>{patientInfo.phoneNumber ? patientInfo.phoneNumber : '-'}</h5>
                            <a onClick={() => {
                                setPatientDetailsModal(true);
                                document.body.style.overflowY = 'hidden';
                            }}>{title}</a>

                        </div>
                    </div>
                </div>
                {
                    showInfoButton ?
                        <>
                            <hr />
                            <div style={{ margin: "0px 10px", width: "100%", backgroundColor: "#86efd3", borderColor: "#86efd3" }} className="btn-patient-primary submit-btn p-2 pr-4 mt-3 mb-3 ml-auto button-text-white btn-patient-light" onClick={() => {
                                setModalVisible(true);
                                document.body.style.overflowY = 'hidden';
                            }}>Show {showBookingInfo ? "Booking" : "Admit"} Informations</div>
                        </> : null
                }

            </div>
            {
                patientDetailsModal &&

                <div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '40%' }} role="document">
                        <div className="modal-content" style={{ maxHeight: "700px", overflowY: "scroll" }}>
                            <i class="fas fa-times patient-info-modal-close-icon" onClick={() => {
                                setPatientDetailsModal(false);
                                document.body.style.overflowY = 'auto';
                            }}></i>
                            <PatientDetailModal patientData={patientInfo} modalTitle={modalTitle}  />
                        </div>

                    </div>
                </div>
            }
            {
                modalVisible &&

                <div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
                    <div className="modal-dialog modal-dialog-centered mobile-screen-dialog-centered" style={{ maxWidth: '70%' }} role="document">
                        <div className="modal-content" >
                            <i class="fas fa-times patient-info-modal-close-icon p-2" onClick={() => {
                                setModalVisible(false);
                                document.body.style.overflowY = 'auto';
                            }}></i>
                            {
                                showBookingInfo ?
                                    <div className="col-md-12 p-4 mt-4 mobile-screen-dialog-main" style={{ maxHeight: "650px", overflowY: "scroll" }}>
                                        {/* <StyledContainer> */}
                                        <div>
                                            <h4 className="card-title">Patient Booking Info</h4>
                                        </div>
                                        <div className="card-body mobile-screen-card-body">
                                            <div className="booking-summary" style={{ display: 'flex' }}>
                                                <div className="booking-item-wrap col-md-12">
                                                    <ul className="booking-fee">
                                                        <li>Appointment type <span style={{ color: '#fff', background: '#5156be', padding: '2px 10px' }}>{props.appointment.slot.type === 1 ? 'Online appt.' : 'In clinic appt.'}</span></li>
                                                        <li>Appointment pay type <span style={{ color: '#fff', background: '#5156be', padding: '2px 10px' }}>{props.appointment.appointmentType === 0 ? 'Online' : 'In cash'}</span></li>
                                                        {
                                                            props.appointment.appointmentType === 1 && (
                                                                <li>Payment status <span style={{ color: '#fff', background: props.appointment.cashPaymentStatus === 0 ? 'red' : '#5156be', padding: '2px 10px' }}>{
                                                                    props.appointment.cashPaymentStatus === 0 ? 'Not paid' : 'Paid'
                                                                }</span></li>
                                                            )
                                                        }
                                                        <li>
                                                            Appointment
                                                            <span style={{ color: '#5156be' }}>
                                                                {props.appointment.slot.start ? moment(props.appointment.slot.start).utc().format('DD/MM/YYYY') : '-'} {props.appointment.slot.start ? moment(props.appointment.slot.start).utc().format('hh:mm A') : '-'}
                                                            </span>
                                                        </li>

                                                        <li>Last Updated <span style={{ color: '#5156be' }}>{props.appointment.last_updated ? moment(props.appointment.last_updated).utc().format('DD/MM/YYYY') : '-'}</span></li>
                                                        <li>Consulting Fee
                                                            <span style={{ color: '#5156be' }}>
                                                                {props.appointment.amount ? 
                                                                    renderAppointmentAmount()
                                                                    : '-'
                                                                }
                                                            </span>
                                                        </li>
                                                        {
                                                            props.appointment.appointmentType === 0 &&
                                                            <li>Payment method type <span style={{ color: '#5156be' }}>{props.appointment.paymentMode ? <><img src={getPayLogo(props.appointment.paymentMode)} height="30px" style={{ paddingRight: '10px' }} />{props.appointment.paymentMode}</> : '-'}</span></li>

                                                        }

                                                        {
                                                            props.appointment.appointmentType === 0 &&
                                                            <li>Payment method code<span style={{ color: '#5156be' }}>{props.appointment.paymentMode ? <><img src={getPayLogo(props.appointment.paymentMode)} height="30px" style={{ paddingRight: '10px' }} />{props.appointment.paymentModeCode}</> : '-'}</span></li>

                                                        }

                                                    </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <PatientInfoModal patientDetails={patientDetails} appointment={appointment}/>
                                }
                            </div>
                       
                    </div>
                </div>
            }
        </CardComponent>
    )
}

export default PatientInfo;
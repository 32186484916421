import { makeStyles } from "@mui/styles";
import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

const IPDdashoboardUseStyles = makeStyles((theme) => ({
  font16: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: fontWeights.fontWeight500,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  font14: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: fontWeights.fontWeight500,
    lineHeight: "18px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  image86: {
    height: "86px",
    width: "86px",
    borderRadius: "8px",
  },
  image160: {
    height: "160px",
    width: "174px",
    borderRadius: "8px",
  },
  cont: {
    border: "2px solid #CCCCCC",
    borderRadius: "8px",
    marginTop: "16px",
  },
  typographyText: {
    "&.MuiTypography-root" : {
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: '500',
    },
    "&.MuiGrid-item" : {
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: '500',
    }
  },
  placeText:{
    position: 'absolute',
    top: '90%',
    left: '50%',
    fontWeight: fontWeights.fontWeight700,
  },
 
}));

export { IPDdashoboardUseStyles };

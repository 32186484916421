import React, { useContext, useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router, Route, Switch, Redirect, useHistory
} from 'react-router-dom';

import Loader from "react-loader-advanced";
import { toast, ToastContainer } from 'react-toastify';

import ScrollToTop from "react-router-scroll-top";

import Store, { Context } from './store';

import { Howl } from 'howler';
import ringtone from './ringtone.mp3';
import { leaveCall, setUser, startBasicCall } from './components/Header/Agora_RTC';
import { isMobile } from 'react-device-detect';
import { SET_CALLING_DATA, SET_ERROR } from './store/types';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useSelector } from "react-redux";
import { indexRoutes } from './routes';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';

const ringtoneSound = new Howl({
  src: [ringtone],
  loop: true,
  preload: true
})

const App = () => {
  const [loader, setLoader] = React.useState(false);
  const [error, setError] = React.useState(null);
  const location = window.location.pathname;
  const renderPageNotFound = indexRoutes.filter((route) => route.path == location)
  const [currentUserId, setCurrentUserId] = useState(null);
  const [calling, setCalling] = React.useState(false);
  const [channel, setChannel] = useState('');
  const [token, setToken] = useState('');
  const [uuid, setUuid] = useState('');
  const [doctorProifleImage, setDoctorProifleImage] = useState('');
  const [doctorName, setDoctorName] = React.useState('');
  const [callType, setCallType] = useState('audio');
  const [isjoin, setIsJoin] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [appid, setAppid] = useState('f5a79afbfcce4005bc94277dbd7fe04b');
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData
  }));
  const {pathName} = useSelector(state => state.router)
  const history = useHistory()

  React.useEffect(() => {
    if(pathName !== location && +localStorage.routeToBeUpdate) {
      localStorage.routeToBeUpdate = 0
      window.location.href = pathName
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName])

  React.useEffect(() => {
    const timezoneString = new Intl.DateTimeFormat().resolvedOptions().timeZone;
   localStorage.setItem("timezone", timezoneString)
  }, [userData]);

  React.useEffect(() => {
    setError(state.error);
    setLoader(state.loading);
  }, [state]);

  React.useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('customer'));
    const path = window.location.pathname;
    if (customer && customer._id) {
      setCurrentUserId(customer._id);
      const user = {
        userId: customer._id
      };
      // socket.emit("join_patient", user);
      // socket.on('rtctoken-patient', data => {
      //   dispatch({
      //     type: SET_CALLING_DATA,
      //     payload: data
      //   });
      //   if (data) {
      //     ringtoneSound.play();
      //     setCalling(true);
      //     setChannel(data.channel)
      //     setToken(data.refreshToken)
      //     setUuid(data.uuid)
      //     setDoctorProifleImage(data.doctorImage);
      //     setDoctorName(data.doctorName);
      //     setCallType(data.type)
      //     setUser('Patient');
      //   }
      // })

      // socket.on("endCall", data => {
      //   leaveCall(callType);
      //   setIsJoin(false);
      //   if (data?.role == 'Doctor') {
      //     toast.success('Doctor end the call!')
      //     dispatch({
      //       type: SET_ERROR,
      //       payload: 'Doctor end the call!'
      //     });
      //     setTimeout(() => {
      //       window.location.reload();
      //     }, 1000)
      //   }
      // })
    }
  }, [])

  const handleClickJoin = () => {
    setCalling(false);
    ringtoneSound.stop();
    ringtoneSound.unload();
    AgoraRTC.getDevices()
      .then(devices => {
        const audioDevices = devices.filter(function (device) {
          return device.kind === "audioinput";
        });
        var selectedMicrophoneId = audioDevices[0].deviceId;

        const videoDevices = devices.filter(function (device) {
          return device.kind === "videoinput";
        });
        var selectedCameraId = videoDevices[0].deviceId;

        return Promise.all([
          AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
          AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedMicrophoneId }),
        ]);
      })
      .then(res => {
        const [videoTrack, audioTrack] = res;
        let options = {
          appId: appid,
          channel: channel,
          token: token,
          uid: uuid
        }
        const val = startBasicCall(options, videoTrack, audioTrack, callType).then(res => {
        })
        setIsJoin(true)
      }).catch(e => {
        toast.error('Permission denied')
        dispatch({
          type: SET_ERROR,
          payload: 'Permission denied'
        });
      });
  }

  const callRejected = () => {
    // const user = {
    //   appointmentId: channel,
    //   from: 'Patient'
    // };
    // socket.emit("rejectCall", user);
    setIsJoin(false);
    setCalling(false);
    ringtoneSound.stop();
    ringtoneSound.unload();
    // setTimeout(() => {
    // }, 100);
  }

  const handleClickLeave = () => {
    // const user = {
    //   appointmentId: channel,
    //   from: 'Patient'
    // };
    // socket.emit("rejectCall", user);
    leaveCall(callType)
    setIsJoin(false)
    ringtoneSound.stop();
    ringtoneSound.unload();
    setTimeout(() => {
      window.location.reload();
    }, 1000)
  }

  return (
    <>
      <ScrollToTop>
        <Switch>
          <Loader
            show={state.loading}
            message={<div className="spinner-border"></div>}
            backgroundStyle={{ position: "fixed", zIndex: 10000 }}
          >
            {indexRoutes.map((prop, key) => {
              return prop.private ? <PrivateRoute
                path={prop.path}
                exact={true}
                key={key}
                name={prop}
                component={prop.component}
                private={prop.private}
              />
                :
                <PublicRoute
                  path={prop.path}
                  exact={true}
                  key={key}
                  name={prop}
                  component={prop.component}
                />
            })}
            {
              state.error ? (
                <ToastContainer
                  position="bottom-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              ) : null
            }
          </Loader>

        </Switch>
      </ScrollToTop>
      {calling ? (
        <div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)', paddingLeft: '0px' }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body" style={{ padding: '40px' }}>
                <div className="call-box incoming-box">
                  <div className="call-wrapper" style={{ height: 'auto' }}>
                    <div className="call-inner">
                      <div className="call-user">
                        <img className="call-avatar" src={doctorProifleImage ? `${doctorProifleImage}` : "/assets/img/doctor-empty.png"} alt="User Image" />
                        <h4>{doctorName}</h4>
                        <span>Calling ...</span>
                      </div>
                      <div className="call-items">
                        <a href="javascript:void(0);" className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons" onClick={callRejected}>call_end</i></a>
                        {callType === 'audio' ? (
                          <a href="javascript:void(0);" onClick={() => handleClickJoin()} data-toggle="modal" data-target="#video_call" className="btn call-item call-start"><i className="material-icons">phone_in_talk</i></a>
                        ) : (
                          <a href="javascript:void(0);" onClick={() => handleClickJoin()} data-toggle="modal" data-target="#video_call" className="btn call-item call-start"><i className="material-icons">videocam</i></a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      ) : null}
      <ToastContainer />
    </>
  );
}

export default App;


import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
        fontWeight: 'light',
        paddingTop: "20px",
        paddingBottom: "40px"
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    drView: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px 0px',
        borderBottom: '2px solid #D3D0CF'
    },
    patientView: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0px 20px 0px',
        borderBottom: '2px solid #D3D0CF',
        fontSize: '12px',
    },
    drDetailsSection: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    boldtext: {
        fontWeight: '700',
    },
    subHeading: {
        fontWeight: 'bold',
        fontSize: "14px"
    },
    table: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },
    header: {
        borderTop: 'none',
    },
    bold: {
        fontWeight: 'bold',
    },
    // So Declarative and unDRY 👌
    row1: {
        width: '10%',
        padding: '0 10px',
        wordWrap: "break-word",
        whiteSpace: 'pre-wrap',
    },
    row2: {
        width: '30%',
        padding: '0 10px',
        wordWrap: "break-word",
        whiteSpace: 'pre-wrap',
    },
    row3: {
        width: '22%',
        padding: '0 10px',
        wordWrap: "break-word",
        whiteSpace: 'pre-wrap',
    },
    row4: {
        width: '20%',
        padding: '0 10px',
        wordWrap: "break-word",
        whiteSpace: 'pre-wrap',
    },
    row5: {
        width: '22%',
        padding: '0 10px',
        wordWrap: "break-word",
        whiteSpace: 'pre-wrap',
    },
});
const PdfPreviewServiceIpd = (props) => {
    const { payment, totalAmount, patientDetails } = props;
    return (
        <PDFViewer width="100%" height="100%">
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.drView}>
                            <View>
                                <Text style={{ letterSpacing: "3px", fontWeight: 'heavy', fontSize: "40px", color: 'green' }}>EMED4U</Text>
                                <Text style={{}}>Health & care to your doorstep</Text>
                            </View>
                            <View style={{ marginLeft: "auto" }}>
                                <Text style={{ color: 'black', fontWeight: 'hairline' }}>Emed4u</Text>
                                <View style={{ flexDirection: 'row' }}><Text style={{ fontWeight: 'demibold' }}>Address : </Text><Text style={{}}>Chandigarh, India</Text></View>
                                {/* <View style={{ flexDirection: 'row' }}><Text>Contact number : </Text><Text style={{}}>7658064535</Text></View> */}
                            </View>
                        </View>
                        <View>
                            <Text style={{marginTop:"15px"}}>Service Of IPD</Text>
                        </View>
                        <View style={styles.patientView}>
                            <View style={{flexDirection: 'row', padding: "10px", fontSize: "12px" }}>
                                <View style={styles.table}>
                                    <View style={[styles.row, styles.bold, styles.header]}>
                                        <Text style={styles.row1}>No</Text>
                                        <Text style={styles.row2}>Date & Time</Text>
                                        <Text style={styles.row3}>Service</Text>
                                        <Text style={styles.row4}>Service Type</Text>
                                        <Text style={styles.row5}>Amount</Text>
                                    </View>
                                    {payment && payment.map((row, i) => (
                                        <View key={i} style={styles.row} wrap={false}>
                                            <Text style={styles.row1}>
                                                <Text style={styles.bold}>{i + 1}</Text>
                                            </Text>
                                            <Text style={styles.row2}>{moment(row.serviceDate).format('Do MMM, YYYY')}{" "}{moment(row.serviceDate).format('hh:mm a')}</Text>
                                            <Text style={styles.row3}>{row.serviceName}</Text>
                                            <Text style={styles.row4}>
                                                <Text style={styles.bold}>{row.serviceType}</Text>
                                            </Text>
                                            <Text style={styles.row5}>{row.serviceAmount}</Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                            <View style={{marginLeft:"auto",marginTop:"30px"}}>
                               
                                {
                                totalAmount &&
                                    <View style={{ flexDirection: 'row',marginBottom:"10px" }}>
                                        <Text style={{marginRight:"20px",fontWeight:'extrabold',width:"40%"}}>Amount:</Text>
                                        <Text>{totalAmount}</Text>
                                    </View>
                                }
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: "40px" }}>
                            <View>
                                <Text>{patientDetails.doctor.name}</Text>
                            </View>
                            <View style={{ marginLeft: "auto" }}>
                                <Text>Health Staff</Text>
                                <Text>{patientDetails.doctor.department}</Text>
                            </View>
                        </View>
                    </View>

                </Page>
            </Document>
        </PDFViewer>
    );
}

export default PdfPreviewServiceIpd;
import React, { useState, useContext, useEffect }  from 'react';
import { Link } from 'react-router-dom';

import PhoneInput from "react-phone-number-input";


import CardComponent from '../../../../../components/Card'
import InputField from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';

import { toast } from 'react-toastify';
import { globalValidator, getUser } from "../../../../../utils";

import { Context } from '../../../../../store';
import {
	addPatient,
	getPatientInfo,
	updatePatientInfo,
	addPatientById
} from '../../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';

import {
	StyledContainer,
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledAddEditPatient
} from './styles';
import InsideMenuBar from '../../../../../components/InsideMenus';

function PatientAddEdit(props) {
	const [state, dispatch] = useContext(Context);

	// bloodGroup: '', address: ''
	const [patient, setPatient] = useState({name: '', email: '', phoneNumber: '', gender: 'male'});
	const [id, setId] = useState(null);

	const [patientId, setPatientId] = React.useState('');

	useEffect(() => {
		if(props.match.params && props.match.params.id) {
			setId(props.match.params.id);
			loader(true);
			getPatientInfo(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				
				setPatient({
					name: res.data.name,
					email: res.data.email,
					phoneNumber: res.data.phoneNumber,
					gender: res.data.gender
					// bloodGroup: res.data.bloodGroup,
					// address: res.data.address
				});
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}, [])

	const submitPatient = (e) => {
		e.preventDefault();
		const keyEmail = [
			{ name: "email", title: "Enter a valid Email!", validateKey: "email" },
		]
		let validEmail = globalValidator(patient, keyEmail);
		if(patient.name.length === 0) {
			toast.error('Name is required!')
			return dispatch({
				type: SET_ERROR,
				payload: 'Name is required!'
			});
		}


		if(patient.email.length === 0){
			toast.error('Email is required!')
			return dispatch({
				type: SET_ERROR,
				payload: 'Email is required!'
			});
		}

		if(validEmail == 'Please provide valid email'){
			toast.error('Enter a valid Email!')
			return dispatch({
				type: SET_ERROR,
				payload: 'Enter a valid email!'
			});
		}

		if(patient.phoneNumber === undefined || patient.phoneNumber.length === 0) {
			toast.error('Phone number is required!')
			return dispatch({
				type: SET_ERROR,
				payload: 'Phone number is required!'
			});
		}

		loader(true);
		if(id) {
			updatePatientInfo(id, patient).then(res => {
				loader(false);
				toast.success(res?.message || "Patient updated!")
				dispatch({
					type: SET_ERROR,
					payload: res?.message || "Patient updated!"
				});
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		} else {
			const org = getUser();

			let data = {
				name: patient.name, 
				email: patient.email, 
				phoneNumber: patient.phoneNumber, 
				gender: patient.gender,
				org_name: org.org_name,
				// bloodGroup: patient.bloodGroup, 
				// address: patient.address,
				password: 'Emed4u@12345#!',
				verifyed: true
			}
			addPatient(data).then(res => {
				loader(false);
				toast.success(res?.message || "Credentials are sent to patients mail!")
				dispatch({
					type: SET_ERROR,
					payload: res?.message || "Credentials are sent to patients mail!"
				});
				// , bloodGroup: '', address: ''
				setPatient({name: '', email: '', phoneNumber: '', status: '',gender: 'Male'});
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
		
	}

	const submitPatientId = (e) => {
		e.preventDefault();
		const keys = [
      { name: "patientId", title: "Patient Id", validateKey: "required" }
    ];
		let valid = globalValidator({patientId}, keys);

		if(valid === true) {
			loader(true);
			
			addPatientById({
				patientId: patientId
			}).then(res => {
				loader(false);
				toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
			
				setPatientId('');
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
			
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<StyledHeader>
									<StyledBackTo>
										<Link to={'/organization/dashboard'}>
											<i className="fa fa-arrow-left" />
											Back To Dashboard
										</Link>
									</StyledBackTo>

									<StyledTitle>
										{id ? 'Update' : 'Add'} Patient
									</StyledTitle>

									<StyledAddEditPatient  onClick={(e) => submitPatient(e)}>
											{id ? 'Update' : 'Save'} Patient
										</StyledAddEditPatient>
								</StyledHeader>

								{!id ? 
								<>
									<div className="row pt-3" style={{alignItems: 'center'}}>
										<div className="col-md-8 no-padding form-group ">
											<label>Patient Id</label>
											<InputField value={patientId} onChange={e => setPatientId(e.target.value)} />
										</div>
										<div className="col-md-2">
											<StyledAddEditPatient  onClick={(e) => submitPatientId(e)} style={{width: '100%'}}>
												Add patient
											</StyledAddEditPatient>
										</div>
									</div>
									
									<div className="row pt-3">
										<div className="col-md-12 text-center">
											<h4>Or add a patient with details</h4>
										</div>
										<div className="col-md-12 ">
											<hr />
										</div>
									</div>
								</>
								: null}
								
								<div className="row">
									<div className="col-md-12 mt-2">
										<div style={{
											background: '#fdbc6c',
											padding: '10px',
											fontSize: '16px',
											fontWeight: '500',
											borderRadius: '8px'
										}}>
											Email or phone number is required!
										</div>
									</div>
								</div>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Name</label>
										<InputField value={patient.name} onChange={e => setPatient((patient) => ({...patient, name: e.target.value}))} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Email</label>
										<InputField value={patient.email} onChange={e => setPatient((patient) => ({...patient, email: e.target.value}))} />
									</div>
								</div>

								<div className="row pt-3">

									<div className="col-md-12 no-padding form-group ">
										<label>Gender</label>
										<SelectField value={patient.gender} onChange={e => setPatient((patient) => ({...patient, gender: e.target.value}))} options={[{id: 1, label: 'Male', value: 'male'},{id: 2, label: 'Female', value: 'female'}]} />
									</div>

								</div>

								<div className="row pt-3">

									<div className="col-md-12 no-padding form-group">
										<label>Contact number</label>
										<PhoneInput
											placeholder="Enter phone number"
											international
											defaultCountry="IN"
											value={patient.phoneNumber}
											onChange={e => setPatient((user) => ({ ...user, phoneNumber: e }))}
										/>
									</div>

									{/* <div className="col-md-6 no-padding form-group ">
										<label>Contact number</label>
										<InputField value={patient.phoneNumber} onChange={e => setPatient((patient) => ({...patient, phoneNumber: e.target.value}))} />
									</div> */}
									{/* <div className="col-md-6 no-padding form-group ">
										<label>Address</label>
										<InputField value={patient.address} onChange={e => setPatient((patient) => ({...patient, address: e.target.value}))} />
									</div> */}

									</div>
								
								{/* <div className="row">
									<div className="col-md-12 text-center">
										<StyledAddEditPatient  onClick={(e) => submitPatient(e)}>
											{id ? 'Update' : 'Save'} Patient
										</StyledAddEditPatient>
									</div>
								</div> */}
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default PatientAddEdit

import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getProfileByDoctorFailure, getProfileByDoctorRequest, getProfileByDoctorSuccess, updateProfileByDoctorFailure, updateProfileByDoctorRequest, updateProfileByDoctorSuccess } from './doctorSlice';
import { getProfileByDoctorAPI, updateProfileByDoctorAPI } from '../../../services/mainService';
import { doctorProfileAction, updateDoctorProfileAction } from './doctorAction';

  function* getProfileByDoctor(action) {
    try {
       yield put(getProfileByDoctorRequest()); 
      const apiResponse = yield call(getProfileByDoctorAPI, action.payload);
     if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
         yield put(getProfileByDoctorSuccess(listData));
  
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getProfileByDoctorFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  
  function* updateProfileByDoctor(action) {
    try {
       yield put(updateProfileByDoctorRequest()); 
      const apiResponse = yield call(updateProfileByDoctorAPI, action.payload);
     if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
         yield put(updateProfileByDoctorSuccess(listData));
         getProfileByDoctor(action.payload.token)
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put( updateProfileByDoctorFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  export function* doctorSaga() {
    yield takeLatest(doctorProfileAction.type, getProfileByDoctor);
    yield takeLatest(updateDoctorProfileAction.type, updateProfileByDoctor);
   
    
  }
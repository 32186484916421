import { Grid, Link } from "@mui/material";
import React, { useMemo } from "react";
import { dashoboardUseStyles } from "../../../../assets/styles/DoctorDashboardStyles";
import strings from "../../../../constant/strings";
import { useSelector } from "react-redux";
import { previousAppointmentList } from "../../../../constant/staticLists";
import { LoadingContainer } from "../../../../assets/styles/CommonStyles";

const RenderItem = React.memo(({ label, value }) => {
  const classes = dashoboardUseStyles();
  let formattedValue = Array.isArray(value) ? value.join(", ") : value;

  // Special handling for 'strength'
  if (label === "Strength") {
    formattedValue = value?.[0]?.medicineStrength || 'N/A'; // Adjust based on the actual data shape
  }

  if (label === "Strength Type") {
    formattedValue = value?.[0]?.strengthType || 'N/A'; // Adjust based on the actual data shape
  }
  if (label === "Medication name") {
    formattedValue = value?.[0]?.name || 'N/A'; // Adjust based on the actual data shape
  }
  if (label === "Dosage quantity") {
    formattedValue = value?.[0]?.dosageQuantity || 'N/A'; // Adjust based on the actual data shape
  }
  // Special handling for 'appointment_type'
  if (label === "Appointment type") {
    formattedValue = value === 0 ? "Online" : "In-Clinic";
  }

  return (
    <Grid item xs={12} sm={12} md={6} className={classes.secondaryCont}>
      <span className={classes.labelStyle}>{label}:</span>
      <span className={`${classes.labelStyle} ${classes.valueStyle}`}>{formattedValue}</span>
    </Grid>
  );
});

const PreviousAppointments = () => {
  const classes = dashoboardUseStyles();

  const { prevAppointmentList, patientInfoListLoading } = useSelector((state) => ({
    prevAppointmentList: state.doctorAppointment.previousAppointment,
    patientInfoListLoading: state.doctorAppointment.patientInfoListLoading
  }));

  const lastAppointment = prevAppointmentList[0];
  const startDate = lastAppointment ? new Date(lastAppointment.start_time).toLocaleDateString() : 'N/A';

  const renderItems = useMemo(() => (
    Object.keys(previousAppointmentList).map((key) => {
      let value;
      if (['strength', 'dosageQuantity', 'name', 'strengthType'].includes(key)) {
        value = lastAppointment?.formOfMedication || 'N/A';
      } else if (key === "documents") {
        let documentArray = lastAppointment?.documents
        const arr = !! documentArray && documentArray.length> 0 && documentArray.map((item, index) => <Link href={item} target="_blank" className="classes.linkStyle" style={{ textDecoration: 'none' }}>Document{`${index + 1}`} {" "}</Link>)
        return <Grid item xs={12} sm={12} md={6} className={classes.secondaryCont}>
          <span className={classes.labelStyle}>Documents:</span>
          <span className={`${classes.labelStyle} ${classes.valueStyle}`}>{arr}</span>
        </Grid>

      }
      else {
        value = lastAppointment?.[key] || 'N/A';
      }
      return <RenderItem key={key} label={previousAppointmentList[key]} value={value} />;
    })
  ), [lastAppointment]);

  return (
    <Grid container spacing={0} className={classes.mainCont}>
      { patientInfoListLoading ? <LoadingContainer>{strings.loading}</LoadingContainer> : 
      <Grid container spacing={0} className={classes.headingTextCont}>
      <Grid item xs={10} className={classes.headingTextStyle}>
        {strings.personalInformation}
      </Grid>
      <Grid item xs={2} className={classes.dateTextStyle}>
        {startDate}
      </Grid>
    </Grid>}
      
      <Grid container spacing={0}>{lastAppointment && renderItems}</Grid>
    </Grid>
  )
}

export default PreviousAppointments;
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledContainer = styled.div`

    .chat-window {
        height: 100vh;
    }
    .chat-cont-left {
        background-color: #fff;s
    }
    .chat-content-left-side {
        max-height: calc(100vh - 75px);
    }
    .chat-window .chat-scroll {
        min-height: calc(100vh - 140px);
    }
    .chat-user-no-data {
        display: flex;
        justify-content: center;
        align-items: center;
    }

	@media (max-width: 767px){
		.chat-scroll {
			min-height: 400px;
			max-height: calc(100vh - 200px);
			overflow-y: auto;
		}
		.chat-footer {
			position: absolute;
			bottom: -75px;
			width: 100%;
		}
	}
`;

export const StyledBackSection = styled.div`
  width: 100%;
  margin: 20px 0;
  
`;

export const StyledBackTo = styled(Link)`
    border: 2px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #5156be;
    font-weight: 500;
    cursor: pointer;
    
    float: right;

    i {
        padding-right: 10px;
    }
`;

export const MissedMessages = styled.div`
    display: flex;
    justify-content: center;
    border-radius: 5px;
    background: #fff;
    width: 250px;
    height: 24px;
    font-size: 12px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 3%);
    align-items: center;
    margin-bottom: 12px;
`;

export const MissedWraper = styled.div`
    display: flex;
    justify-content: center;
`;

export const Icon = styled.i`
    font-size: 14px;
    margin-left: 10px;
    color: #E74848;
`;
import React, {useEffect, useContext, useState} from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	postLoginAPI
} from '../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_INFO_ORGANIZATION
} from '../../../store/types';

import { globalValidator, saveOrgUser, isLoggedIn } from "../../../utils";

import InputField from '../../../components/InputField';

import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledRightHeading,
	StyledRightSocialLogin,
	StyledSocialFB,
	StyledSocialGoogle,
	StyledRightDivider,
	StyledDividerLine,
	StyledDividerHeading,
	StyledLoginButton,
	StyledRightBottom,
	StyledBottomContent,
	StyledBottomLink,
	StyledForgotPassword,
	StyledForgotPasswordLink,
	StyledSingupNow
} from './styles';

function OrganizationLogin(props) {

	const [state, dispatch] = useContext(Context);

	const [orgUser, SetOrgUser] = useState({email: '', password: ''});

	useEffect(() => {
		if(isLoggedIn()) props.history.push('/organization/dashboard');
	}, [])

	const login = (e) => {
		e.preventDefault();
		const { email, password } = orgUser;
    const keys = [
      { name: "email", title: "Email", validateKey: "email" },
      { name: "password", title: "Password", validateKey: "password" }
    ];
		let valid = globalValidator({ email, password }, keys);

		if(valid === true) {
			loader(true);
			postLoginAPI(orgUser).then(res => {
				saveOrgUser(res.data);
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				dispatch({
					type: SET_INFO_ORGANIZATION,
					payload: res.data
				});
				props.history.push('/organization/dashboard');
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const forgotPassword = () => {
		props.history.push('/organization/forgot-password');
	}

	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
              <StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Login for organization / clinic</StyledLeftHeading>
										<StyledLeftSubHeading>
										To keep overview of your organisation ,please login with your info.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="https://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>

							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">
											{/* <StyledRightHeading>Login with Social Profile</StyledRightHeading>
											<div className="d-flex  flex-column align-items-center mb-3">
												<StyledRightSocialLogin>
													<StyledSocialFB>
														<i className="fab fa-facebook-f mr-1" />
													</StyledSocialFB>
													<StyledSocialGoogle>
														<i className="fab fa-google mr-1" />
													</StyledSocialGoogle>
												</StyledRightSocialLogin>
											</div> */}
											
											{/* <StyledRightDivider className="d-flex align-items-center my-3">
												<StyledDividerLine />
												<StyledDividerHeading className="mx-2">
													Or use your email account
												</StyledDividerHeading>
												<StyledDividerLine />
											</StyledRightDivider> */}

											<form className="form-border info-widget singin-we">
												<div className="form-group card-label">
													<label>Email</label>
													<InputField value={orgUser.email} onChange={e => SetOrgUser((user) => ({...user, email: e.target.value}))} type="email" />
												</div>

												<div className="form-group card-label">
													<label>Password</label>
													<InputField value={orgUser.password} password={true}  type="password" onChange={e => SetOrgUser((user) => ({...user, password: e.target.value}))} />
												</div>

												<StyledForgotPassword onClick={() => forgotPassword()}>
													<StyledForgotPasswordLink>
														Forgot Password ?
													</StyledForgotPasswordLink>
												</StyledForgotPassword>

												<StyledLoginButton onClick={(e) => login(e)}>
													Sign In Now
												</StyledLoginButton>

												<p style={{
														textAlign: 'center',
														margin: '0px',
														paddingTop: '20px',
														fontSize: '22px',
														fontWeight: '700'
												}}>Or</p>
											</form>

											<StyledSingupNow to={'/organization/register'}>
												<StyledBottomContent>
													{/* I don't have an account? -  */}
													{/* <StyledBottomLink to={'/organization/register'}> */}
														Sign Up Now
													{/* </StyledBottomLink>  */}
												</StyledBottomContent>
											</StyledSingupNow>											
										</div>
									
									</div>
								</div>
							</StyledRightPanel>

            </div>
          </div>
        </div>
		  </div>
	  </section>  
	)
}

export default OrganizationLogin

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledCrossIcon } from '../../assets/styles/CommonStyles';
import { Button, Link } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';
import { ContainerDiv, TopSection, } from '../../assets/styles/QRCodeStyle';
import axios from 'axios';

const SecondaryContainer = styled.div`
margin-top: 24px;
text-align: center;
`;

const StyledText = styled.input`
border-radius: 8px 0px 0px 8px;
margin: 0px;
width: 461px;
height: 40px;
padding: 8px 0px 8px 12px;
border: "1px solid #CCCCCC";
background: #F8F7F7;
outline: none;
text-decoration: none;

@media (max-width: 640px) {
    width: 250px;
}
`;

const StyledImg = styled.img`
width: 24px;
height: 24px;
cursor: pointer;
`;

const QRcodeComponent = ({ url, handleCross, setNext, isNext, checked, setChecked }) => {

  const [shortQRLink, setQRLink] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(`https://tinyurl.com/api-create.php?url=${url}` );
        console.log("response",response)
        setQRLink(response.data);
      } catch (e) {
        console.log(e);
      }
    }; 
    fetchData();
  }, []);

  const copyQR = async () => {
    try {
      await navigator.clipboard.writeText(shortQRLink);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  const downloadImage = () => {
    html2canvas(document.querySelector('#qrImage'))
      .then(function (canvas) {
        const link = document.createElement('a');
        link.download = 'react-qrcode-logo.png';
        link.href = canvas.toDataURL();
        link.click();
      });
  }

  const handleNext = () => {
    setChecked(!checked);
    setNext(!isNext);
  }

  return (
    <ContainerDiv>
      <TopSection>
        <StyledCrossIcon
          src={"/assets/img/arrow-left.png"}
          alt="leftArrow"
          onClick={handleNext} />
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="cross"
          onClick={handleCross} />
      </TopSection>
      <SecondaryContainer>
        {/* {shortQRLink=== "Error" ||  shortQRLink=== "" ? <div style={{minHeight: '300px', fontSize: '24px'}}>Loading....</div> :           
           <QRCode value= {shortQRLink}
                  size= "298"
                  logoImage={"/assets/img/emedLogo.png"}
                  logoHeight="100"
                  logoWidth="100"
                  id="qrImage" /> } */}

        <QRCode value={shortQRLink}
          size="298"
          logoImage={"/assets/img/emedLogo.png"}
          logoHeight="100"
          logoWidth="100"
          id="qrImage" />
      </SecondaryContainer>


      <SecondaryContainer>
        <StyledText type="url" value={shortQRLink} />
        <Button
          onClick={copyQR}
          sx={{
            "&.MuiButton-root": {
              color: "#fff",
              background: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
              borderRadius: "0px 8px 8px 0px",
              height: "40px"
            }
          }}>Copy link</Button>
      </SecondaryContainer>

      <SecondaryContainer style={{ cursor: 'pointer' }} >
        <StyledImg src={"/assets/img/download.png"} alt="download" />
        <Link onClick={downloadImage}
          sx={{
            "&.MuiTypography-root": {
              fontFamily: "Poppins",
              fontSize: "14px",
              textDecoration: "none",
              fontWeight: "500",
              lineHeight: "24px",
              background: 'var(--primary, linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%))',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
            }
          }}>Download QR Code</Link>
      </SecondaryContainer>


    </ContainerDiv>
  )
}

export default QRcodeComponent
import React  from 'react';
import './index.css'
import SubAdminViewPatient from './Patient/SubAdminVIewPatient';

function ViewPatient(props) {
    return (
        <div>
            <SubAdminViewPatient {...props}/>
        </div>
    )
}

export default ViewPatient

import React, { useEffect } from 'react';
import moment from 'moment';
import { Line, Bar } from "react-chartjs-2";
import { toast } from 'react-toastify';
import { Context } from '../../../store';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import {
	StyledContainer,
} from './styles';
import CardComponent from '../../../components/Card';
import { determineLabel } from '../../../graphUtils';
import {
	getBPAPI,
	getHeightAPI,
} from '../../../api/patient';
import LineChartComponent from '../../../components/Charts/LineChart'
import InsideMenuBar from '../../../components/InsideMenus';


// import ApexCharts from 'apexcharts';
// import ReactApexChart from "react-apexcharts";

function PatientReport(props) {
	const [state, dispatch] = React.useContext(Context);
	const [lineData, setBpLineData] = React.useState({
		all : [],
		currentWeek : [],
		pasWeek: []
	});
    const [resRateLineData, setResRateLineData] = React.useState({
		all : [],
		currentWeek : [],
		pasWeek: []
	});
	const [heartRateLineData, setHeartRateLineData] = React.useState({
		all : [],
		currentWeek : [],
		pasWeek: []
	});
	const [spo2LineData, setSpo2LineData] = React.useState({
		all : [],
		currentWeek : [],
		pasWeek: []
	});
	const [tempLineData, setTempLineData] = React.useState({
		all : [],
		currentWeek : [],
		pasWeek: []
	});
	const [bloodSugarLineData,setBloodSugarLineData] = React.useState({
		all : [],
		currentWeek : [],
		pasWeek: []
	})

	const [getBPData, setGetBPData] = React.useState(false);
	const [searchDate, setSearchDate] = React.useState('');
	const [copyData, setCopyData] = React.useState([]);
	const [allData, setData] = React.useState([]);

  const renderGrapData = (item) => {
    return (
      <>
        <div className='d-flex row mt-4'>
          <h3>{item.title}</h3>
          <div className="col-md-12 mt-1 row d-flex" style={{ padding: "5px" }}>
            <div className='col-md-6'>
              {item.title === 'Blood pressure' ?
                <LineChartComponent keyItem={item.id} Linedata={item.lineData.all} type='area' color={item.color} label={item.title} showfilter={false} doubleLineChart />
                :
                <LineChartComponent keyItem={item.id} Linedata={item.lineData.all} type='area' color={item.color} label={item.title} showfilter={false} determineLabel={determineLabel(item.name)} />
              }
            </div>
            <div className='col-md-6'>
              {item.title === 'Blood pressure' ?
                <LineChartComponent keyItem={item.id} Linedata={item.lineData} type='bar' color={item.color} label={item.title} showfilter={false} doubleLineChart patientReport={true} />
                :
                <LineChartComponent keyItem={item.id} Linedata={item.lineData} type='bar' color={item.color} label={item.title} showfilter={false} determineLabel={determineLabel(item.name)} patientReport={true} />
              }
            </div>
          </div>
        </div>
        <hr />
      </>
    )
  }

    React.useEffect(() => {
		loader(true)

		const script = document.createElement("script");

		script.src = "https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.min.js";
		script.async = true;

		document.body.appendChild(script);

		document.body.className = "";

		getBP("all_Bp");
		getBP("all_RespiratoryRates")
		getBP("all_Pluse")
		getBP("all_OxygenLevel")
		getBP("all_Temperature")
		getBP("all_Bsugar")
		loader(false)
	}, [])
    const getBP = (healthType, value) => {
		const type = healthType;
		let responseData = []
		loader(true);
		setGetBPData(false);

		if (type === 'bmiall') {
			getHeightAPI().then(res => {
				// toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});

				if (res.data && res.data.length > 0) {
					let filteredData = [];
					if (value) {
						filteredData = res?.data.filter(item => moment(item.createdAt).diff(value, 'day') >= 0);
						setData(filteredData);
						setSearchDate(value);
						setCopyData(filteredData)
					} else {
						setCopyData(res.data)
						setData(res.data);
					}
					setGetBPData(true);
				}
				loader(false);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
		else {
			// const startOfWeek  = moment().startOf('week').subtract(14,'days');
			const startOfWeek = moment().subtract(13, 'days');
			const data = {
				start: new Date(moment(startOfWeek)),
				end: new Date(),
			}
			getBPAPI(data, type)?.then(res => {
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});

				switch (type) {
					case 'all_Bp':
						responseData = res.data;
						break;
					default:
						responseData = res.data;
						break;
				}
				loader(false);
				if (responseData?.length) {
					let filteredData = [];	
					let paskWeekData =[]
					const startOfWeek  = moment().startOf('week');
					const startOfWeekAfter  = moment().startOf('week').subtract(6, 'days');
					const lastweek  = moment().startOf('week').subtract(13,'days');
                    // filteredData = responseData?.map((item)=>item._doc)?.filter(item => moment(new Date()).diff(moment(item.createdAt), 'day') <= moment(new Date()).format("d"));
					// paskWeekData = responseData?.map((item)=>item._doc)?.filter(item =>  moment(new Date()).diff(moment(lastweek), 'day') > moment(new Date()).diff(moment(item.createdAt), 'day') &&  moment(new Date()).diff(moment(item.createdAt), 'day') > moment(new Date()).diff(moment(lastweekEnd), 'day'));
					// allData = responseData?.map((item)=>item._doc)?.filter(item => moment(item.createdAt).isBetween(startOfWeek, lastweek));
					// filteredData = responseData?.map((item)=>item._doc)?.filter(item => moment(item.createdAt).isBetween(startOfWeek, lastweek));
					// paskWeekData = responseData?.map((item)=>item._doc)?.filter(item =>  moment(item.createdAt).isBetween(lastweekEnd, lastweek));
					filteredData = responseData?.filter(item => moment(item.createdAt).isBetween(startOfWeekAfter, moment()));
					paskWeekData = responseData?.filter(item =>  moment(item.createdAt).isBetween(lastweek, startOfWeek));
					let filteredDataReverse = filteredData.length > 0 ? filteredData.reverse() : [];
					if (type === 'all_Bp') {
						setBpLineData({
							all: responseData,
							currentWeek: filteredDataReverse,
							pasWeek: paskWeekData
						});
					}
					if (type === 'all_RespiratoryRates') {
						setResRateLineData({
							all: responseData,
							currentWeek: filteredDataReverse,
							pasWeek: paskWeekData
						});
					}
					if (type === 'all_Temperature') {
						setTempLineData({
							all: responseData,
							currentWeek: filteredDataReverse,
							pasWeek: paskWeekData
						});
					}
					if (type === 'all_OxygenLevel') {
						setSpo2LineData({
							all: responseData,
							currentWeek: filteredDataReverse,
							pasWeek: paskWeekData
						});
					}
					if (type === 'all_Pluse') {
						setHeartRateLineData({
							all: responseData,
							currentWeek: filteredDataReverse,
							pasWeek: paskWeekData
						});
					}
					if (type === 'all_Bsugar') {
						setBloodSugarLineData({
							all: responseData,
							currentWeek: filteredData,
							pasWeek: paskWeekData
						});
					}
					setGetBPData(true);
				}
				loader(false);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}
    const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
    const Graphdata = [
		{
			id: 1,
			title: 'Blood pressure',
			// value: `${findAvg(lineData.map((item)=>item.sys_blood_pressure))}/${findAvg(lineData.map((item)=>item.dia_blood_pressure))}`,
			lineData: lineData,
			name: 'all_Bp',
			color: {
				borderSysColor: '#DC143C',
				fillSysColor:'#f49cac',
				borderDiaColor:'#013b61',
				fillDiaColor:'#6181ca'
			},
			type:"area",
		},
		{
			id: 2,
			title: 'Pulse',
			// value: findAvg(heartRateLineData.map((item) => item.pluse)),
			lineData: heartRateLineData,
			name: 'all_Pluse',
			color: {
				borderColor:'#DC143C',
				fillColor:'#ffdae1'
			},
			type:"area"
		},
		{
			id: 3,
			title: 'SPO2',
			// value: findAvg(spo2LineData.map((item) => item.level)),
			lineData: spo2LineData,
			name: 'all_OxygenLevel',
			color: {
				borderColor:'#0a8484',
				fillColor:'#3feeee'
			},
			type:"area"
		},
		{
			id: 4,
			title: 'Blood Sugar',
			// value: findAvg(bloodSugarLineData.map((item) => item.how_much)),
			lineData: bloodSugarLineData,
			name: 'all_Bsugar',
			color: {
				borderColor:'#FF1493',
				fillColor:'#ffc8e6'
			},
			type:"area"
		},
		{
			id: 5,
			title: 'Temperature',
			// value: findAvg(tempLineData.map((item) => item.temperature)),
			lineData: tempLineData,
			name: 'all_Temperature',
			color: {
				borderColor:'#E066FF',
				fillColor:'#f3c4ff'
			},
			type:"bar"
		},
		{
			id: 6,
			title: 'Respiratory Rate',
			// value: findAvg(resRateLineData.map((item) => item.respiratory_rates)),
			lineData: resRateLineData,
			name: 'all_RespiratoryRates',
			color: {
				borderColor:'#0a8484',
				fillColor:'#c4f4f4'
			},
			type:"bar"
		},
	]

	// const series = [{
	// 	name: 'Last week',
	// 	data: [78]
	// }, {
	// 	name: 'Current week',
	// 	data: [88]
	// }]

	// const options = {
	// 	chart: {
	// 		type: 'bar',
	// 		height: 350
	// 	},
	// 	plotOptions: {
	// 		bar: {
	// 			horizontal: false,
	// 			columnWidth: '15%',
	// 			endingShape: 'rounded'
	// 		},
	// 	},
	// 	dataLabels: {
	// 		enabled: false
	// 	},
	// 	stroke: {
	// 		show: true,
	// 		width: 2,
	// 		colors: ['transparent']
	// 	},
	// 	xaxis: {
	// 		categories: ['Last week', 'Current Week'],
	// 	},
	// 	yaxis: {
	// 		title: {
	// 			text: 'Weekly Average'
	// 		}
	// 	},
	// 	fill: {
	// 		opacity: 1
	// 	},
	// 	tooltip: {
	// 		y: {
	// 			formatter: function (val) {
	// 				return val + " Average"
	// 			}
	// 		}
	// 	}
	// };

	// const series = [{
	// 	data: data.slice()
	//   }]

	//   const options = {
	// 	chart: {
	// 	  id: 'realtime',
	// 	  height: 350,
	// 	  type: 'line',
	// 	  animations: {
	// 		enabled: true,
	// 		easing: 'linear',
	// 		dynamicAnimation: {
	// 		  speed: 1000
	// 		}
	// 	  },
	// 	  toolbar: {
	// 		show: false
	// 	  },
	// 	  zoom: {
	// 		enabled: false
	// 	  }
	// 	},
	// 	dataLabels: {
	// 	  enabled: false
	// 	},
	// 	stroke: {
	// 	  curve: 'smooth'
	// 	},
	// 	title: {
	// 	  text: 'Dynamic Updating Chart',
	// 	  align: 'left'
	// 	},
	// 	markers: {
	// 	  size: 0
	// 	},
	// 	xaxis: {
	// 	  type: 'datetime',
	// 	  range: XAXISRANGE,
	// 	},
	// 	yaxis: {
	// 	  max: 100
	// 	},
	// 	legend: {
	// 	  show: false
	// 	},
	//   }

	//   useEffect(() => {
	// 	window.setInterval(() => {
	// 		getNewSeries(new Date(), {
	// 			min: 10,
	// 			max: 90
	// 		})
			
	// 		ApexCharts.exec('realtime', 'updateSeries', [{
	// 			data: data
	// 		}])
	// 		}, 1000)
	//   }, [])

    return (
        <StyledContainer>
            <InsideMenuBar
				props={props}
				type={'patient'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="text-center">
						<h2>Weekly report</h2>
						{/*<h4>{`Current Week (${moment().clone().startOf('isoWeek').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')})`}</h4>
						<h4> {`Previous Week (${moment().subtract(1, 'weeks').startOf('isoWeek').format('DD/MM/YYYY')} - ${moment().subtract(1, 'weeks').endOf('isoWeek').format('DD/MM/YYYY')})`}</h4>*/}
						<h4>{`Current Week (${moment().subtract(6, 'days').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')})`}</h4>
						<h4> {`Previous Week (${moment().subtract(13, 'days').format('DD/MM/YYYY')} - ${moment().subtract(7, 'days').format('DD/MM/YYYY')})`}</h4>

					</div>
					<div className="row">
						{/* <div className="col-md-12" style={{height: '800px'}}>
							<h3>Heart rate</h3>
							<div className="row">
								<div className="col-md-2">
									<ReactApexChart options={options} series={series} type="bar" height={350} />
									<ReactApexChart options={options} series={series} type="line" height={350} />
								</div>

								<div className="col-md-4">
									<div>
										<h6 style={{display: 'flex'}}>Last week : <p style={{
											'border-radius': '50%',
											'height': '15px',
											'width': '15px',
											'background': 'rgb(0 143 251)',
											'margin': '0px 5px',
											'padding': '0px',
										}}></p>78 avg</h6>

										<h6 style={{display: 'flex'}}>Current week : <p style={{
											'border-radius': '50%',
											'height': '15px',
											'width': '15px',
											'background': '#00e396',
											'margin': '0px 5px',
											'padding': '0px',
										}}></p> 88 avg</h6>
									</div>
									<h5 style={{
										    'background': '#b3b5de',
												'color': '#5156be',
												'padding': '5px',
												'border-radius': '5px',
									}}>Note : Your heart rate is the number of times each minute that your heart beats, which is normally between 60 and 100 times per minute for adults. Your pulse is a way you can feel each time your heart beats. Measuring your heart rate helps you monitor your own health and know if you’re exercising at the right level to get the most benefits to your health.</h5>
								
								<h4 style={{marginTop: '10px'}}>
									Your Heart rate is normal no need to worry :)
								</h4>
								</div>
								
							</div>
							
    
						</div> */}

						<div className="col-md-12">
              <CardComponent className="p-4 mb-4">
                {Graphdata && Graphdata.map((item) => (
                  renderGrapData(item)
                ))}
              </CardComponent>
						</div>

					</div>
				</div>
			</div>
		</StyledContainer>
    )
}

export default PatientReport

import { object, string, number, array } from "yup";

export const doctorSlotSchema = object({
    currency : string().typeError("Please enter valid currency").required("Please choose currency type")   ,
    price: number()
    .positive("The number must be positive")
    .integer("The number must be an integer")
    .typeError("Please enter valid fees")
    .required('Please enter valid fees'),
    day_of_week: array().nullable()
    .min(1, "At least one week day is required")
    .required("Symptoms is required"),
    time_slots: array().nullable()
    .min(1, "At least one slot is required")
    .required("Symptoms is required"),
    type: number()
    .required("Slot type is required"),

});
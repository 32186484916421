import React, {useEffect, useContext, useState} from 'react';

import InputField from '../../components/InputField';

import { toast } from 'react-toastify';

import { Context } from '../../store';

import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../store/types';

import { globalValidator, saveUser } from "../../utils";

import axios from "axios";

import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledRightHeading,
	StyledRightSocialLogin,
	StyledSocialFB,
	StyledSocialGoogle,
	StyledRightDivider,
	StyledDividerLine,
	StyledDividerHeading,
	StyledLoginButton,
	StyledRightBottom,
	StyledBottomContent,
	StyledBottomLink,
	StyledForgotPassword,
	StyledForgotPasswordLink
} from './styles';

function ResetPassword(props) {

	const [state, dispatch] = useContext(Context);

	const [orgUser, SetOrgUser] = useState({password: ''});

	const [type, setType] = React.useState('');

	React.useEffect(() => {
		if(props.match.path == '/reset-password') {
			setType('patient')
		} else if(props.match.path == '/doctor/reset-password') {
			setType('doctor')
		} else if (props.match.path == '/organization/reset-password') {
			setType('organization')
		}
	}, []);

	const login = (e) => {
		e.preventDefault();
		const { password } = orgUser;
    const keys = [
			{ name: "password", title: "Password", validateKey: "password" },
			// { name: "confirmPassword", title: "Confirm password", validateKey: "confirm_password" },
    ];
		let valid = globalValidator(orgUser, keys);

		if(valid === true) {
			loader(true);
			const token = props.location.search.split("=")[1];

			if(type == 'patient') {
				
				axios
        .put(
          "https://api.emed4u.com/api/patient/resetpassword",
          {
            password: password
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(function(res) {
        //   toast.success(res.message)
					dispatch({
						type: SET_ERROR,
						payload: res.message
					});
					loader(false);

          props.history.push("/login");
        })
        .catch(function(error) {
					toast.error(error)
					dispatch({
						type: SET_ERROR,
						payload: error
					});
				});
				
			} else if(type == 'doctor') {

				axios
        .put(
          "https://api.emed4u.com/api/doctor/resetpassword",
          {
            password: password
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(function(res) {
        //   toast.success(res.message)
					dispatch({
						type: SET_ERROR,
						payload: res.message
					});
					loader(false);
					
          props.history.push("/doctor/login");
        })
        .catch(function(error) {
					toast.error(error)
					dispatch({
						type: SET_ERROR,
						payload: error
					});
				});
				
			} else if(type == 'organization') {
				
				axios
        .put(
          "https://api.emed4u.com/api/organization/resetpassword",
          {
            password: password
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(function(res) {
        //   toast.success(res.message)
					dispatch({
						type: SET_ERROR,
						payload: res.message
					});
					loader(false);

          props.history.push("/organization/login");
        })
        .catch(function(error) {
					toast.error(error)
					dispatch({
						type: SET_ERROR,
						payload: error
					});
				});
				
			}
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}


	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
              <StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Reset password</StyledLeftHeading>
										<StyledLeftSubHeading>
											To keep connected with us please login with your personal info.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="http://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>

							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">

											<form className="form-border info-widget">
												<div className="form-group card-label">
													<label>New password</label>
													<InputField value={orgUser.password} onChange={e => SetOrgUser((user) => ({...user, password: e.target.value}))} password={true}  type="password" />
												</div>

												{/* <div className="form-group card-label">
													<label>Confirm password</label>
													<InputField value={orgUser.confirmPassword} onChange={e => SetOrgUser((user) => ({...user, confirmPassword: e.target.value}))} password={true}  type="password" />
												</div> */}


												<StyledLoginButton onClick={(e) => login(e)}>
													Reset password
												</StyledLoginButton>
											</form>

																		
										</div>
									
									</div>

								</div>
							
								
							</StyledRightPanel>

            </div>
          </div>
        </div>
		  </div>
	  </section>  
	)
}

export default ResetPassword

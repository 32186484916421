import {object, string, number} from 'yup';
import * as Yup from 'yup';

export const addSubAdminSchema = object({
    name : string()
    .required('Name is required'),
    email: string()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required"),
    status: string()
    .required('Please select status'),
    phone_number: number()
    .positive("The number must be positive")
    .integer("The number must be an integer")
    .typeError("Please enter a valid phone number")
    .min(10, "Minimum 10 characters required")
    .required("Mobile Number is required"),
    designation: string()
    .required('Please select designation'),
    permission: string()
    .required('Please select permission'),
    gender: string()
    .required('Please select gender'),
    address: string()
    .required('Please select address'),
})


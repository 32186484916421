import React, { useCallback, useEffect, useState } from "react";
import { Grid, Paper } from '@mui/material';

import { useStyles } from "../../../assets/styles/PatientDashboardStyles";
import strings from "../../../constant/strings";
import TabComponent from "../../../components/Tab";

import HeadingComponent from "../HeadingComponent/index.js";
import AppLayout from "../../../pages/SideBar";
import { StyledSecondaryButton } from "../../../assets/styles/CommonStyles";
import { AddOutlined } from "@mui/icons-material";
import AppointMentBooking from "../../../pages/PatientPanel/PatientAppointments/AppointMentBooking";
import AppointMentList from "../../../pages/PatientPanel/PatientAppointments/AppointMentList";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorListAction, getPatientAppointmentAction } from "../../../redux/appointment/patient/patientAppointmentActions";
import SearchFields from "../../../pages/PatientPanel/PatientAppointments/SearchFields";
import { tabs } from "../../../constant/staticLists";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";

const Appointement = ({navigate}) => {
  const classes = useStyles();
  const styleClasses = commonMuiStyles();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [bookAppointment, setBookAppointment] = useState(0);
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData
  }));

  useEffect(() => {
    getlist(tabValue);
    dispatch(getDoctorListAction(userData.token));
  }, []);

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
    getlist(newValue);
  };

  const handleValue = (value) => {
    setSearchValue(value);
  }

  const getlist = (value) => {
    let temp = 0;
    switch(value){
    
      case 0: temp = 1; break; 
      case 1: temp = 4; break;
      case 2: temp = 2; break;
    }
    dispatch(getPatientAppointmentAction({ token: userData.token, status: temp }));
  }

  const getText = useCallback(() => {
    if (bookAppointment) {
      if (step === 2) {
        return strings.appointmentDetails
      }
      if (step === 3) {
        return strings.patientInputs
      }
      if (step === 4) {
        return strings.appointmentCheckout
      }
      return strings.doctorList
    }
    return strings.appointments
  }, [step, bookAppointment])

  const confirmBooking = (value) => {
    if (value == true) {
      setBookAppointment(false);
      getlist(tabValue)
    }
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.mainCont}>
        <Grid container spacing={0} justifyContent={'space-between'} alignItems={'center'}>
          <Grid item sx={7}>
            <HeadingComponent text={{ "primary": getText(), "secondary": strings.appointmentsSecondary }} />
          </Grid>
          <Grid item sx={5}>
            {!bookAppointment && <StyledSecondaryButton
              onClick={setBookAppointment.bind(this, true)}
              sx={{
                textTransform: 'capitalize',
                width: '100% !important',
                fontWeight: 500,
                '& svg': {
                  width: '19px',
                  height: '19px',
                  marginRight: '10px'
                }
              }}
            >
              <AddOutlined />
              {strings.bookAppointment}
            </StyledSecondaryButton>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.mainCont}>
        {!bookAppointment ?
          <Paper elevation={1} sx={{ width: "100%", height: "100%" }}>
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <Grid container>
                  <TabComponent
                    tabs={tabs}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    handleTab={handleTab}
                    customTab={styleClasses.customTab}
                    customTabIndicator={styleClasses.customTabIndicator}
                  />
                </Grid>
              </Grid>
              <SearchFields item xs={4} pr={"16px"} handleValue={handleValue} placeholder={strings.searchByDoctorName} />
            </Grid> 
            <AppointMentList getlist={getlist} tabValue={tabValue} searchValue={searchValue}  bookAppointment={bookAppointment} setBookAppointment={setBookAppointment} step={step} setStep={setStep} />
          </Paper>
          : <AppointMentBooking bookAppointment={bookAppointment} setBookAppointment={setBookAppointment} step={step} setStep={setStep} confirmBooking={confirmBooking} getlist={getlist} />}
      </Grid>
    </Grid>
  )
}

const Patient = () => {
  return (
    <Grid container spacing={0}>
      <AppLayout>
        <Appointement />
      </AppLayout>
    </Grid>
  )
}

export default Patient;
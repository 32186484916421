import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import PatientList from "../../components/ui/Organisation/Patient";

const OrganisationPatientList = () => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        <PatientList />
      </AppLayout>
    </Grid>
  )
}

export default OrganisationPatientList;
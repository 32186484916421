import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getAdmittedPatientFailure, getAdmittedPatientRequest, getAdmittedPatientSuccess,  getRoomFailure, getRoomRequest, getRoomSuccess, getAdmittedPatientServiceRequest, getAdmittedPatientServiceSuccess, getAdmittedPatientServiceFailure } from './ipdSlice';
import { getAdmittedPatientApi, getAdmittedPatientServiceApi, getRoomsApi } from '../../services/mainService';
import { getAdmitedPatientAction, getAdmitedPatientServiceAction, getRoomListAction } from './ipdAction';

  function* getRoomList(action) {
    try {
       yield put(getRoomRequest()); 
      const apiResponse = yield call(getRoomsApi, action.payload);
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put(getRoomSuccess(listData));
  
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getRoomFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  function* getAdmittedPatientList(action) {
    try {
       yield put(getAdmittedPatientRequest()); 
      const apiResponse = yield call(getAdmittedPatientApi, action.payload);
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put(getAdmittedPatientSuccess(listData)); 
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getAdmittedPatientFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  function* getAdmittedPatientServiceList(action) {
    try {
       yield put(getAdmittedPatientServiceRequest()); 
      const apiResponse = yield call(getAdmittedPatientServiceApi, action.payload);
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put(getAdmittedPatientServiceSuccess(listData)); 
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getAdmittedPatientServiceFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }


  export function* ipdSaga() {
    yield takeLatest(getRoomListAction.type, getRoomList);   
    yield takeLatest(getAdmitedPatientAction.type, getAdmittedPatientList); 
    yield takeLatest(getAdmitedPatientServiceAction.type, getAdmittedPatientServiceList);
  }
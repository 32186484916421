import styled from 'styled-components';

export const StyledContainer = styled.div`
.trial-mode {
    display: grid;
    margin-top: 9px;
    text-align: center;
}
.trial-mode-pr {
    display: grid;
    margin-top: 9px;
    text-align: center;

    .tr-mode{
        background: rgb(81, 86, 190);
        border-radius: 20px;
        color: #fff;
    }
}

.tr-mode{
    background: orange;
    border-radius: 20px;
}
`;


export const StyledButtonGroup = styled.div`
    margin-bottom: 20px;

    .active-btn {
        background: #5156be;
        color: #fff;
    }
`;

export const StyledButton = styled.button`
    border: 2px solid #5156be;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #5156be;
    text-transform: uppercase;
    font-weight: 500;
    background: transparent;
    margin-right: 20px;
`;
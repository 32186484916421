import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import AppointmentOPDList from "../../components/ui/Subadmin/Home/AppointmentOPDList";
import Appointement from '../../components/ui/Patient/PatientAppointments';


const SubadminOPDAppointment = () => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        <AppointmentOPDList />
      </AppLayout>
    </Grid>
  )
}

export default SubadminOPDAppointment;
import React, { useCallback, useState } from "react";
import OPDappointment from "../../OPDappointment.js";
import HeadingComponent from "../../HeadingComponent/index.js";
import strings from "../../../../constant/strings.js";
import { Box, Grid, Modal } from "@mui/material";
import { subadminOPDAppointmentList } from "../../../../constant/staticLists.js";
import { subadminDashboardStyles } from "../../../../assets/styles/subadminDashboardStyle.js";
import {
  StyledSecondaryButton,
  modalStyle,
} from "../../../../assets/styles/CommonStyles.js";
import { AddOutlined } from "@mui/icons-material";
import AddPatient from "../../../Modal/AddPatient.js";
import AppointMentBooking from "../../../../pages/PatientPanel/PatientAppointments/AppointMentBooking.jsx";

const AppointmentOPDList = () => {
  const classes = subadminDashboardStyles();
  const [openModal, setOpenModal] = useState(false);
  const [bookAppointment, setBookAppointment] = useState(0);
  const [step, setStep] = useState(1);

  const getlist = (value) => {
    let temp = 0;
    switch (value) {
      case 0:
        temp = 1;
        break;
      case 1:
        temp = 4;
        break;
      case 2:
        temp = 2;
        break;
      case 3:
        temp = 2;
        break;
    }
    //dispatch(getPatientAppointmentAction({ token: userData.token, status: temp }));
  };

  const getText = useCallback(() => {
    if (bookAppointment) {
      if (step === 2) {
        return strings.appointmentDetails;
      }
      if (step === 3) {
        return strings.patientInputs;
      }
      if (step === 4) {
        return strings.appointmentCheckout;
      }
      return strings.doctorList;
    }
    return strings.appointmentsOpd;
  }, [step, bookAppointment]);

  const confirmBooking = (value) => {
    console.log("confirm booking clicked");
    // if (value == true) {
    //   setBookAppointment(false);
    //   getlist(tabValue)
    // }
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={7}>
          <HeadingComponent
            text={{
              primary: getText(),
              secondary: strings.appointmentOpdHeading,
            }}
          />
        </Grid>
        {!bookAppointment ? (
          <>
            <Grid
              item
              xs={5}
              mt={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <StyledSecondaryButton
                onClick={setBookAppointment.bind(this, true)}
                sx={{
                  textTransform: "capitalize",
                  width: "48% !important",
                  fontWeight: 500,
                  "& svg": {
                    width: "19px",
                    height: "19px",
                    marginRight: "5px",
                  },
                }}
              >
                <AddOutlined />
                {strings.bookAppointment}
              </StyledSecondaryButton>
              <StyledSecondaryButton
                onClick={() => setOpenModal(true)}
                sx={{
                  textTransform: "capitalize",
                  width: "48% !important",
                  fontWeight: 500,
                  "& svg": {
                    width: "19px",
                    height: "19px",
                    marginRight: "5px",
                  },
                }}
              >
                <AddOutlined />
                {strings.addPatient}
              </StyledSecondaryButton>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${classes.contBorderStyle}`}
              sx={{ minHeight: "320px" }}
              mt={4}
            >
              <OPDappointment fieldOrder={subadminOPDAppointmentList} />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} padding="16px 0px">
            <AppointMentBooking
              bookAppointment={bookAppointment}
              setBookAppointment={setBookAppointment}
              step={step}
              setStep={setStep}
              confirmBooking={confirmBooking}
              getlist={getlist}
              navigate="subadmin"
            />
          </Grid>
        )}
      </Grid>
      <Modal
        keepMounted
        open={openModal}
        scroll="paper"
        //onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={modalStyle}>
          <AddPatient setOpenModal={setOpenModal} openModal={openModal} />
        </Box>
      </Modal>
    </>
  );
};

export default AppointmentOPDList;

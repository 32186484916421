import { createAction } from "@reduxjs/toolkit";
export const getDoctorAvailableTimeAction = createAction('doctor/schedule/available-time',(payload) => {
        return {
      payload: payload,
    };
  });

  export const addSlotsDoctorAction = createAction('doctor/schedule/add-slots',(payload) => {
    return {
    payload: payload,
};
});

export const getSlotsByDoctorAction= createAction('doctor/get-slots',(payload) => {
    return {
    payload: payload,
};
});



import { object, string, number } from "yup";

export const bloodOxygenSchema = object({
    spo2Percentage: number()
    .typeError("Oxygen saturation level must be a number")
    .min(0, "Oxygen saturation level must be greater than or equal to 0")
    .max(100, "Oxygen saturation level must be less than or equal to 100")
    .test("oxygen-levels", "Invalid oxygen saturation level", (value) => {
      if (value < 80) {
        return "at risk";
      } else if (value <= 90) {
        return "low";
      } else if (value <= 100) {
        return "normal";
      }
      return false;
    }),
    readingMethod: string(),
    note: string()
});
import React, { useEffect, useState } from "react";
import { subadminDashboardStyles } from "../../../assets/styles/subadminDashboardStyle";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { useDispatch, useSelector } from "react-redux";
import TabComponent from "../../Tab";
import {
  orgIPDAppointmentList,
  subAdminIPDAppointmentListHome,
  tabsIPD,
} from "../../../constant/staticLists";
import { Box, Grid, Modal } from "@mui/material";
import Tables from "../../controls/Tables";
import HeadingComponent from "../HeadingComponent";
import strings from "../../../constant/strings";
import {
  LoadingContainer,
  NoDetailsContainer,
  StyledSecondaryButton,
  modalStyle,
} from "../../../assets/styles/CommonStyles";
import { AddOutlined } from "@mui/icons-material";
import SearchFields from "../../../pages/PatientPanel/PatientAppointments/SearchFields";
import AddPatientBySubadmin from "../../Modal/AddPatientSubadmin";
import AddPatient from "../../Modal/AddPatient";
import { getAdmitedPatientAction } from "../../../redux/ipd/ipdAction";

const IPDlist = ({ navigate, handleBackward, handleForward, setScreen, setSelectedListItem }) => {
  const dispatch = useDispatch();
  const classes = subadminDashboardStyles();
  const styleClasses = commonMuiStyles();
  const [tabValue, setTabValue] = useState(0);
  const [openModal, setOpenModal] = useState(0);
  const [formType, setFormType] = useState(0);
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { userData, admittedPatient, ipdLoading } = useSelector((state) => ({
    userData: state.auth.userData,
    admittedPatient: state.ipd.admittedPatient,
    ipdLoading: state.ipd.loading,
  }));

  useEffect(() => {
    dispatch(
      getAdmitedPatientAction({ status: tabValue, token: userData.token })
    );
  }, []);

  useEffect(() => {
    if (searchValue != 0) {
      // const matchedEnteries = admittedPatient.filter(
      //   (item) =>
      //     item.uniqueID.includes(searchValue) ||
      //     item.patientInfo.name
      //       .toLowerCase()
      //       .includes(searchValue.toLowerCase())
      // );
      // setList(matchedEnteries);
    } else {
      setList(admittedPatient);
    }
  }, [searchValue, admittedPatient]);

  const handleTab = (e, newValue) => {
    setTabValue(newValue);
    dispatch(
      getAdmitedPatientAction({ status: newValue, token: userData.token })
    );
  };

  const handleValue = (value) => {
    setSearchValue(value);
  };

  const handleModal = (formValue) => {
    setFormType(formValue);
    setOpenModal(true);
  };

  return (
    <>
      <Grid container spacing={0}>
        {navigate !== "subadminDashboard" && (
          <>
            <Grid item xs={7}>
              <HeadingComponent
                text={{
                  primary: strings.appointmentsIPD,
                  secondary: strings.appointmentOpdHeading,
                }}
              />
            </Grid>
            <Grid
              item
              xs={5}
              mt={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <StyledSecondaryButton
                onClick={() => handleModal(0)}
                sx={{
                  textTransform: "capitalize",
                  width: "48% !important",
                  fontWeight: 500,
                  "& svg": {
                    width: "19px",
                    height: "19px",
                    marginRight: "5px",
                  },
                }}
              >
                <AddOutlined />
                Admit Patient
              </StyledSecondaryButton>
              <StyledSecondaryButton
                onClick={() => handleModal(1)}
                sx={{
                  textTransform: "capitalize",
                  width: "48% !important",
                  fontWeight: 500,
                  "& svg": {
                    width: "19px",
                    height: "19px",
                    marginRight: "5px",
                  },
                }}
              >
                <AddOutlined />
                {strings.addPatient}
              </StyledSecondaryButton>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            className={`${classes.contBorderStyle}`}
            mt={navigate !== "subadminDashboard" && 2}
          >
            <Grid item xs={navigate === "subadminDashboard" ? 12 : 8}>
              <TabComponent
                tabs={tabsIPD}
                tabValue={tabValue}
                setTabValue={setTabValue}
                handleTab={handleTab}
                customTab={styleClasses.customTab}
                customTabIndicator={styleClasses.customTabIndicator}
              />
            </Grid>
            {navigate !== "subadminDashboard" && (
              <Grid item xs={4}>
                <SearchFields
                  placeholder="search by patient name"
                  handleValue={handleValue}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {ipdLoading ? (
                <LoadingContainer>{ strings.loading }</LoadingContainer>
              ) : !!list && list.length > 0 ? (
                <Tables
                  data={ navigate === "subadminDashboard" ? list.slice(0, 3) : list }
                  fieldsOrder = { navigate === "subadminDashboard" ? subAdminIPDAppointmentListHome : orgIPDAppointmentList }
                  handleForward  = { handleForward }
                  handleBackward = { handleBackward }
                  setScreen = { setScreen }
                  setSelectedListItem = {setSelectedListItem}
                />
              ) : (
                <NoDetailsContainer>{tabValue === 0 ? 'No admitted patient list' : 'No discharged patient list'}</NoDetailsContainer>           
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        keepMounted
        open={openModal}
        //onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={modalStyle}>
          {formType === 0 ? (
            <AddPatientBySubadmin
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          ) : (
            <AddPatient setOpenModal={setOpenModal} openModal={openModal} />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default IPDlist;

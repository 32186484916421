import styled from 'styled-components';
import colors from '../../../constant/colors';
import fontWeights from '../../../constant/fontWeights';
import CountryField from '../../CountryField';

export const Wrapper = styled.div`
	display: ${(props) => props.visible ? 'block' : 'none'};
	position: fixed;
	z-index: 1;
	padding-top: 25px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background: rgb(104 71 99 / 24%);
	backdrop-filter: blur(1px);
	z-index: 9999 !important;

	@media only screen and (max-width: 414px) {
		padding-top: 0px;
	}
`;

export const Modal = styled.div`
    color: white;
	margin: 1.75rem auto;
	width : auto;
	max-width: 638px;
	max-height: 672px;
	// max-width: 500px;
	// min-height: calc(100% - 3.5rem);
	background: ${colors.white};
	border-radius: 8px;
	padding: 42px 56px;
	position: relative;

	 @media only screen and (max-width: 414px) {
        /* CSS styles go here */
        max-width: 417px;
        min-width: 340px;
        min-height: 100vmax;
        max-height: 100vmax;
	 	margin: 0rem;
		 padding: 35px 32px;

       }
`;

export const CrossButton = styled.button`
	position: absolute;
	top: 16px;
	right: 16px;

	border: 0px;
	background: ${colors.white};
}
`;

const TextStyle = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: ${fontWeights.fontWeight400};
`;

export const Title = styled(TextStyle)`
	font-size: 32px;
	line-height: 42px;
	color: ${colors.black}
`;

export const Heading = styled(TextStyle)`
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	color: ${colors.black1};
	margin-top: 26px;
`;

export const SubHeading = styled(TextStyle)`
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	color: ${colors.black1};
	margin-top: 14px;
	display: flex;
	justify-content: center;
`;

export const UserWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	display: flex;
	flex-wrap: wrap;
	> * {
		margin-right: 24px;
		&:last-child {
		margin-right: 0px;
		}
	}
`;

export const ImageWrapper = styled.div`
	border: ${(props) => props.border ? '2px solid transparent' : `2px solid ${colors.grey}`};
	border-radius: 8px;
	background-image: linear-gradient(white, white), ${colors.primary};
	background-origin: border-box;
	background-clip: content-box, border-box;
    margin-top: 8px; // top right bottom left
	box-sizing: border-box;
`;

export const UserImage = styled.div`
	background: linear-gradient(90deg, rgba(9, 126, 183, 0.3) 0%, rgba(42, 171, 108, 0.3) 100%);
	border-radius: 7px;
	max-width: 247px;
	display: flex;
	justify-content: center;
	align-items: end;
	position: relative;
	cursor: pointer;
`;

export const SingleUser = styled.div`
	box-sizing: border-box;
	width: 251px;
`;

export const BottomColoredText = styled(TextStyle)`
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	background: ${(props) => props.check ? `${colors.primary}` : `${colors.blackGradient}`};
	text-decoration: ${(props) => props.check ? 'underline' : 'none'};
	margin-left: ${(props) => props.check ? '5px' : '0'};
	cursor: ${(props) => props.check && 'pointer'};
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`;

export const DisplayFlex = styled(UserWrapper)`
	justify-content: center;
	font-size: 16px;
	line-height: 24px;
	margin: 10px 0 16px;
`;

export const CheckBoxStyle = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
`;


export const Button = styled.div`
	width: 148px;
	height: 48px;
	background: ${colors.primary};
	border-radius: 8px;
	display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	color: ${colors.white};
    justify-content: center;
	margin: 24px 0 0;
	color: ${colors.white}
`;

export const BackButton = styled.div`
    display: ${(props) => props.visible ? 'block' : 'none'}; 
    position: absolute;
    top: 18px;
    left: 18px;
    cursor: pointer;
`;


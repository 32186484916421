import React from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import './index.css';
import { globalValidator } from "../../utils";
import TimePicker from 'react-time-picker';
import { toast } from 'react-toastify';
// import '../../../../../Components/subusers.css';
import InputField from '../InputField'
import { 
	addServiceWardPatient,
} from '../../api/organization';
import PdfPreviewServiceOfIpd from '../PdfPreview/PdfPreviewServiceOfIpd'

function AddService(props) {
	const { id, setPatientDetails, patientDetails, setModalVisible } = props;
    const [pdfPreviewModal,setPdfPreviewModal] = React.useState(false);
    const [payment, setPaymentData] = React.useState([]);
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [serviceDetails, setServicesDetails] = React.useState({
        serviceDate: '',
        serviceName: '',
        serviceAmount: '',
        // serviceAddedBy: JSON.parse(localStorage.getItem('subuser'))._id,
        serviceType:'',
    })

    const handleServiceSubmit = () => {
        const keys = [
            { name: "serviceDate", title: "Date", validateKey: "required" },
            { name: "serviceName", title: "Service Name", validateKey: "required" },
            { name: "serviceAmount", title: "Charges", validateKey: "required" },
        ];
        let valid = globalValidator(serviceDetails, keys);

        if (valid === true) {
            setPaymentData([...payment, serviceDetails]);
			setPatientDetails({...patientDetails,services:[...patientDetails.services,serviceDetails]})
            setServicesDetails({
                serviceDate: '',
                time: '',
                serviceName: '',
                serviceAmount: '',
                // serviceAddedBy: JSON.parse(localStorage.getItem('subuser'))._id,
                serviceType:'',
            })
			addServiceWardPatient(id,serviceDetails).then((res) => {
				setPatientDetails(res.data)
			}).catch((err) => {
			})
        } else {
            toast.error(valid)
        }
    }
    const handlechargesInput = (e) => {
        const reg = /^(0|[1-9]\d*)$/
        if (reg.test(e.target.value) || e.target.value === '') {
            setServicesDetails((serviceDetails) => ({ ...serviceDetails, [e.target.name]: e.target.value }))
        } else {
            toast.error('Please Input Only Numbers')
        }
    }
    const handleDeleteService = (id) => {
        const data = patientDetails?.services;
        data.splice(id, 1);
		setPatientDetails({...patientDetails,services:data})
        forceUpdate()
    }
    const totalAmount = () => {
        let total = []
        let sum = 0;
        patientDetails.services && patientDetails?.services?.map((item) => {
            item?.serviceAmount && total.push(parseInt(item?.serviceAmount)) 
        })

        for (let i = 0; i < total.length; i++) {
            sum += total[i];
        }
        return sum;
    }

	const handleSubmit = () => {
		addServiceWardPatient(id,payment).then((res) => {
			setPatientDetails(res.data)
		}).catch((err) => {})
	};

    const handlePreviewPdf = () => {
        setPdfPreviewModal(true);
    }
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="pt-3 no-padding form-group" >
                    <div style={{ display: "flex" }}>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Service Of IPD</p>
                        {/* <i onClick={()=>handleAddPaymentData()} class="fa fa-plus todo-add-icon" aria-hidden="true"></i> */}
                    </div>
                    <div className="row pt-3 service-opd-form">
                        <div className="col-md-2 pr-0 no-padding form-group">
                            <label>Service</label>
                            <InputField value={serviceDetails.serviceName} onChange={e => setServicesDetails((serviceDetails) => ({ ...serviceDetails, serviceName: e.target.value }))} />
                        </div>
                        <div className="col-md-2 pr-0 no-padding form-group">
                            <label>Service Type</label>
                            <InputField value={serviceDetails.serviceType} onChange={e => setServicesDetails((serviceDetails) => ({ ...serviceDetails, serviceType: e.target.value }))} />
                        </div>
                        <div className="col-md-2 pr-0 no-padding form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Date</label>
                            <DatePicker minDate={new Date()} showTimeSelect value={serviceDetails.serviceDate} className="ipdwardDatePicker" selected={serviceDetails.serviceDate} onChange={date => setServicesDetails((serviceDetails) => ({ ...serviceDetails, serviceDate: date }))} />
                        </div>
                        <div className="col-md-2.5 pl-3 no-padding form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Time</label>
                            <TimePicker
                                openClockOnFocus
                                clearIcon={false}
                                onChange={time => {
                                    if(time == null){
                                        return false
                                    }
                                    if(serviceDetails?.serviceDate){
                                        serviceDetails?.serviceDate.setHours(time.split(':')[0]);
                                        serviceDetails?.serviceDate.setMinutes(time.split(':')[1]);
                                        setServicesDetails((serviceDetails) => ({ ...serviceDetails, serviceDate: serviceDetails?.serviceDate }))
                                    }
								}}
                                value={serviceDetails?.serviceDate}
                                format="hh:mm:ss a"
                                className="admission-details-timepicker"
                            />
                        </div>
                        <div className="col-md-2 pr-0 no-padding form-group">
                            <label>Amount</label>
                            <InputField name="serviceAmount" value={serviceDetails.serviceAmount} onChange={handlechargesInput} />
                        </div>
                        <div className="col-md-1 pr-0 no-padding form-group">
                            <button style={{ marginLeft: 'auto', marginTop: '1.75rem' }} className="doctor-active-btn doctor-w-20" onClick={handleServiceSubmit}>Submit </button>
                        </div>
                    </div>
                    <div style={{ height: "200px", overflowY: "auto" }}>
                        <table className="table table-bordered ">
                            <thead className="thead-light to-do-thead" style={{ position: "sticky", top: -1 }}>
                                <tr>
                                    <th>No</th>
                                    <th>Date & Time</th>
                                    <th>Service</th>
                                    <th>Service Type</th>
                                    <th>Amount</th>
                                    <th style={{ minWidth: "30px" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ overflow: "scroll" }}>
                                {patientDetails?.services && patientDetails.services.map((data, i) => {
                                    return (
                                        <tr key={"item-" + i} className="to-do-table-row">
                                            <td>{i + 1}</td>
                                            <td style={{ flexDirection: 'row' }}>
                                                {moment(data?.serviceDate).format('Do MMM, YYYY')}{" "}{data.serviceDate && moment(data.serviceDate).format('hh:mm a')}
                                            </td>
                                            <td style={{overflowWrap: 'anywhere'}}>
                                                {data.serviceName}
                                            </td>
                                            <td style={{overflowWrap: 'anywhere'}}>
                                                {data.serviceType}
                                            </td>
                                            <td style={{overflowWrap: 'anywhere'}}>
                                                {data.serviceAmount}
                                            </td>

                                            <td style={{ textAlign: 'center', padding: '10px 15px' }}>
												{
													(data.addedByApi && data.addedByApi) === false ?
														<i style={{ margin: "auto", padding: "inherit", cursor: "pointer" }} class="fa fa-times" aria-hidden="true" onClick={() => handleDeleteService(i)}></i>
														: null
												}
                                            </td>
                                        </tr>
                                    );
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="pt-3 no-padding form-group row">
                    <div className="ml-auto col-md-9">
                        <div style={{ display: 'flex', float: 'right', padding: '10px', marginRight: '50px' }}>
                            <label style={{ marginRight: "20px", fontWeight: "600" }}>Total amount of service: </label>
                            <label>{totalAmount()}</label>
                        </div>
                    </div>
                    <div className="ml-auto col-md-3 row">
						<button className="doctor-active-btn doctor-w-20 ml-auto" onClick={() => {
                            setModalVisible(false);
                            document.body.style.overflowY = 'auto';
                        }}>Cancel </button>
                        <button disabled = {patientDetails?.services?.length === 0 ? true : false} className="doctor-active-btn doctor-w-20 ml-auto" onClick={handlePreviewPdf}>Preview </button>

                    </div>
                </div>
            </div>
            {pdfPreviewModal && <div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
                <i style={{ fontSize: "28px", right: "139px", margin: "18px", position: "absolute", color: "white" }} class="fa fa-times" aria-hidden="true" onClick={(e) => setPdfPreviewModal(false)}></i>
                <PdfPreviewServiceOfIpd totalAmount={totalAmount()} payment={patientDetails?.services} patientDetails={patientDetails}/>
            </div>}
        </div>
    )
}

export default AddService;
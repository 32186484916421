import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import { toast } from 'react-toastify';
import { Context } from '../../../store';
import {
	getDoctorsListing,
	doctorEMed4uListingAPI
} from '../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import InsideMenuBar from '../../../components/InsideMenus';
import { TextEllipsis } from './style.js';

const options = [
	{ id: 0, label: 'Id', value: 'id' },
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 2, label: 'Email', value: 'email' },
	// { id: 3, label: 'Status', value: 'status' },
	{ id: 4, label: 'Contact Number', value: 'contact' }
]

function ListingDoctors(props) {
	const [state, dispatch] = React.useContext(Context);
	const [id, setId] = useState(null);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('id');
	const [doctors, setDoctors] = useState(null);

	useEffect(() => {
		document.body.className = "";
	}, [])
	
	useEffect(() => {
		Cookies.remove('appointment', { path: '/' });
		Cookies.remove('slot', { path: '/' });
		Cookies.remove('doctorInfo', { path: '/' });
		Cookies.remove('appointment', { path: '/patient' });
		Cookies.remove('slot', { path: '/patient' });
		Cookies.remove('doctorInfo', { path: '/patient' });
		const user = JSON.parse(localStorage.getItem('customer'));
		if(user?.org) {
			loader(true);
			getDoctorsListing(user.orgData._id).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				setDoctors(res?.data);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		} else {
			loader(true);
			doctorEMed4uListingAPI().then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				setDoctors(res?.data);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}, []);

	const searchDoctor = () => {
		const user = JSON.parse(localStorage.getItem('customer'));
		if(user?.org) {
		loader(true);
		getDoctorsListing(user.orgData._id, {search: search, key: selectValue}).then(res => {
			loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				setDoctors(res?.data);
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
		}
		else {
			loader(true);
			doctorEMed4uListingAPI({search: search, key: selectValue}).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				setDoctors(res?.data);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const saveData = (e, data) => {
		e.preventDefault();
		Cookies.set('doctorInfo', JSON.stringify({
			curreny: data.curreny,
			onlinePrice: data.appointmentPrice ? data.appointmentPrice : 0,
			offlinePrice: data.clinicPrice ? data.clinicPrice : 0,
			name: data.name,
			address: data.address,
			department: data.department,
			amount: data.appointmentPrice ? data.appointmentPrice : 0,
			profileImage: data.profileImage,
			doctorId: data._id,
			data: data
		}), { path: '' });
	}
	const renderPrice = (price) => price.filter((rate) => rate).map((item)=>item).join(', ');
	const addressStyle = { display: 'flex', alignItems: 'baseline', textOverflow: 'ellipsis', overflow: 'hidden' }
	const marginStyle = { marginLeft: '16px'}
	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12">
							{/* <div className="doctor-panel-filters row col-md-12">
								<Search 
									selectValue={selectValue} 
									setSelectValue={setSelectValue} 
									search={search} 
									setSearch={setSearch} 
									searchPatient={searchDoctor} 
									name={"Doctor"}
									options={options}
								/>
							</div>	 */}
							<div className="row row-grid">
								{
									doctors ? (
										doctors?.map(item => {
											const { fifteenOnline, appointmentPrice, thirtyfiveOnline, sixtyOnline, fifteenOffline, clinicPrice, thirtyfiveOffline, sixtyOffline } = item;
											const checkOnlineRate = fifteenOnline || appointmentPrice || thirtyfiveOnline || sixtyOnline;
											const checkOfflineRate = fifteenOffline || clinicPrice || thirtyfiveOffline || sixtyOffline;
											const onlineRate = [fifteenOnline, appointmentPrice, thirtyfiveOnline, sixtyOnline];
											const offlineRate = [fifteenOffline, clinicPrice, thirtyfiveOffline, sixtyOffline];
											return (
												<div className="col-md-6 col-lg-4 col-xl-3" key={item?._id}>
													<div className="profile-widget">
														<div className="doc-img">
															<div style={{height: '180px', overflow: 'hidden'}}>
																<img className="img-fluid" alt="User Image" src={item?.profileImage ? item?.profileImage : "/assets/img/doctor-empty.png"} />
															</div>
														</div>
														<div className="pro-content">
															<h3 className="title d-flex">
																<TextEllipsis>{item?.name}</TextEllipsis> 
																<i className="fas fa-check-circle verified"></i>
															</h3>
															<p className="speciality">{item?.department}</p>
															<div className="rating" style={{display: 'flex', alignItems: 'center'}}>
																{/* <ReactStars
																	count={5}
																	value={4}
																	isHalf={true}
																	edit={false}
																	size={20}
																	activeColor="#ffd700"
																/> */}
																{/* <span className="d-inline-block average-rating">(17)</span> */}
															</div>
															<ul className="available-info">
																<li>
																	<i className="fas fa-hospital-alt"></i> {item?.OrganizationId?.org_name}
																</li>
																<li>
																	{/* <div style={addressStyle}><i className="fas fa-map-marker-alt" /> <div>{item?.address}</div></div> */}
																	<i className="fas fa-map-marker-alt"></i> {item?.address}
																</li>
																{/* <li>
																	<i className="far fa-clock"></i> Available on Fri, 22 Mar
																</li> */}
																<li style={{color: '#2c7fb7', display: 'flex', alignItems: 'baseline'}}>
																	<i className="far fa-money-bill-alt" /> <TextEllipsis>Online price :  {item?.curreny} - {checkOnlineRate && renderPrice(onlineRate)} </TextEllipsis> <i className="fas fa-info-circle" style={{ marginLeft: '5px' }} data-toggle="tooltip" title={checkOnlineRate ? `${item?.curreny} - ${renderPrice(onlineRate)}` : ''} />
																</li>
																<li style={{color: '#56be73', display: 'flex', alignItems: 'baseline'}}>
																	<i className="far fa-money-bill-alt" /> <TextEllipsis>In clinic price : {item?.curreny} - {checkOfflineRate && renderPrice(offlineRate)} </TextEllipsis> <i className="fas fa-info-circle" style={{ marginLeft: '5px' }} data-bs-toggle="tooltip" data-bs-placement="right" title={checkOfflineRate ? `${item?.curreny} - ${renderPrice(offlineRate)}` : ''} />
																</li>
															</ul>
															<div className="row row-sm">
																<div className="col-12" onClick={(e) => saveData(e, item)}>
																	<Link to={`/patient/book-appointment/${item?._id}`} className="btn book-btn">Book Now</Link>
																</div>
															</div>
														</div>
													</div>
												</div>
											)
										})
									)
									: (
										<div
										style={{
											height: '70vh',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}
										className="col-md-12 col-lg-12 col-xl-12" >
											<h4 style={{ textAlign: "center" }}>
												Sorry, There are no results to display
											</h4>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ListingDoctors;

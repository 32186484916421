export const healthParameter = [
 {name: "Blood Pressure", isChecked: false, value: 'blood_pressure'}, 
 {name: "Blood Sugar", isChecked: false, value: 'blood_sugar'}, 
 {name: "Blood Oxygen", isChecked: false, value: 'spo2'}, 
 {name: "Body Temperature", isChecked: false, value: 'body_temperature'}, 
 {name: "Heart Rate", isChecked: false, value: 'heart_rate'}, 
 {name: "Respiratory Rate", isChecked: false, value: 'respiratory_rate'},
 ]

export const MedicalHistory = [
{name: "Health Conditions", isChecked: false, value: 'health_condition'},
{name: "Medication", isChecked: false, value: 'medications'},
{name: "Surgeries", isChecked: false, value:'surguries'},
{name: "Allergies", isChecked: false, value: 'allergies'},
{name: "Vaccination", isChecked: false, value: 'vaccinations'},
{name: "Medical Report", isChecked: false, value: 'medical_report'},

] 

export const PatientDetails = [
 {name: "Name", isChecked: false, value: 'name'},
 {name: "Contact No.", isChecked: false, value: 'phoneNumber'}, 
 {name: "Dob", isChecked: false, value: 'dob'},
 {name: "Email", isChecked: false, value:'email'},
 {name: "Blood Group", isChecked: false, value: 'bloodGroup'},
]
import React from 'react';
import ReactSpeedometer from "react-d3-speedometer"

import '../LineChart/index.css'

function GaugeChart(props) {
	const { Linedata,  maxValue, segmentStops,width } = props;
	return (
		<div className="row">
			<div id="chart-radialBar" style={{width:'100%'}}>
        <div id="chart-timeline-radialBar" style={{display:'flex',justifyContent:'center'}}>
          <ReactSpeedometer
            width={width || 300}
            maxValue={maxValue}
            minValue={0}
            height={width ? (width/2)+40 : 190}
            needleHeightRatio={0.7}
            value={Linedata > maxValue ? maxValue : Linedata}
            segments={3}
            // currentValueText=" "
            customSegmentStops={segmentStops}
            segmentColors={[
              // "#bf616a",
              // "#d08770",
              // "#ebcb8b",
              "#5e9654",
              "#f1c939",
              "#d2362d"
            ]}
            customSegmentLabels={[
              {
                text: 'optimal',
                position: 'INSIDE',
                color: '#555',
                fontSize: '8px'
              },
              {
                text: `high`,
                position: 'INSIDE',
                color: '#555',
                fontSize: '8px',
              },
              {
                text: 'very high',
                position: 'INSIDE',
                color: '#555',
                fontSize: '8px'
              }
            ]}
            ringWidth={47}
            needleTransitionDuration={13333}
            needleTransition="easeElastic"
            needleColor={'#000'}
            textColor={'#d8dee9'}
          />
        </div>
      </div>
    </div>
  );
}

export default GaugeChart;

import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import DoctorList from "../../components/ui/Organisation/Doctor";

const OrganisationDoctorList = () => {
  return (
    <Grid container spacing={0}>
      <AppLayout>
        <DoctorList />
      </AppLayout>
    </Grid>
  )
}

export default OrganisationDoctorList;
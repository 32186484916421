import { makeStyles } from "@mui/styles";
import colors from "../../constant/colors";

const useStyles = makeStyles(() => ({
    titleCont:{
        background: colors.white,
        width: '100%',
        maxHeight: 56,
        boxShadow: "none",
        border: "none",
        outline: "none",
        paddingLeft: '4px',
        opacity:1
    },
    iconStyle:{
        width: 'auto',
        height: 40
    },
    
}))

export { useStyles };
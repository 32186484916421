import React from "react";
//import { Page, Text, View, Document, } from '@react-pdf/renderer';

const ViewFile = () => {
    return (
    <div height="100%">
      <iframe src= "https://emed4u-dev.s3.eu-north-1.amazonaws.com/Patient/Prescriptions/8.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAW536LWAG4WWCKOMO%2F20230911%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20230911T092626Z&X-Amz-Expires=86400&X-Amz-Signature=5220b1313b28ecd6f6ae0b0555ed052daead05faa5fd827d1a1a09b7420a2ad1&X-Amz-SignedHeaders=host" height="600px" width="100%" allowFullScreen  >
      </iframe>
  </div>)
}

export default ViewFile;
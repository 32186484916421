import { Answer, Question, SingleQuesWrapper } from "./styles"
import { ReactComponent as Collapse } from '../../../assets/img/collapse.svg';
import { ReactComponent as Expand } from '../../../assets/img/expand.svg';

const expandMobileStyle = { marginTop: '16px', cursor: 'pointer', width: '17px', height: '17px' };
const Toggle = ({ condition, index, setCondition }) => !condition ? <Expand style={expandMobileStyle} onClick={() => setCondition(index)} /> : <Collapse style={expandMobileStyle} onClick={() => setCondition(index)} />

export const SingleQuestion = ({ question, answer, condition, index, setCondition }) => {
	return (
		<SingleQuesWrapper style={{cursor: 'pointer'}} onClick={() => setCondition(index)}>
			<div className="d-flex-important"> {/* Change styling with proper format */}
				<Question condition={condition}>{question}</Question>
				<Toggle condition={condition} index={index} setCondition={setCondition} />
			</div>
			{condition && <Answer>{answer}</Answer>}
		</SingleQuesWrapper>
	)
}

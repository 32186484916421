import React, { useState } from 'react'
import SignUpModal from '../SignUpModal';
import { toast } from "react-toastify";
import * as authService from "../../../services/authService.js";
import { StyledMainModalCont } from '../../../assets/styles/CommonStyles';

const CommonSignUpModal = ({ isSignUpModal, setSignUpModal, toggleSignUp }) => {
	const [selectUser, setSelectUser] = useState('');
	const [isMapping, setMapping] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const handleSubmit = async (orgUser) => {
		let phone = orgUser.countryCode.replace("+", "");
		const phone1 = orgUser.phoneNumber.replace(phone, "");
		const data = { ...orgUser, phoneNumber: phone1 }
		setButtonLoading(true);
		const res = await authService.signupApi(orgUser);
		if (res.response_type === "fail") {
			setButtonLoading(false);
			toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
		} else {
			setSignUpModal(false);
			toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
		}
	}

	return isSignUpModal &&
		<StyledMainModalCont>
			<SignUpModal
				selectUser={selectUser}
				isMapping={isMapping}
				isSignUpModal={isSignUpModal}
				setSignUpModal={setSignUpModal}
				toggleSignUp={toggleSignUp}
				setSelectUser={setSelectUser}
				setMapping={setMapping}
				buttonLoading={buttonLoading}
				handleSubmit={handleSubmit}
			/>
		</StyledMainModalCont>
}

export default CommonSignUpModal;
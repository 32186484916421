import React, { useMemo } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import moment from 'moment';
import '../../assets/styles/calenderScheduler.css';
import DeleteSlot from "../../assets/img/edit.png";
import { scheduleStyles } from "../../assets/styles/DocScheduleStyles";

const localizer = dayjsLocalizer(dayjs);

function convertISOToLocalDate(isoString) {
  const dateObj = new Date(isoString);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const date = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  return new Date(year, month, date, hours, minutes);
}

const TimezoneInfo = () => (
  <div>
    Timezone: {moment().format('Z')}
  </div>
);

const CustomToolbar = () => {
  const classes = scheduleStyles();
  return <div style={{}} className={classes.toolbarStyle}>
    <TimezoneInfo />
  </div>
};

const SlotScheduler = ({ scheduleSelection, list, tabValue, setSlotDelete, isSlotDelete, date }) => {
  const classes = scheduleStyles();

  const viewType = useMemo(() => (scheduleSelection === "d" ? 'day' : 'week'), [scheduleSelection]);
  const lists = useMemo(() => list, [list])

  const transformedEvents = useMemo(() => {
    let filteredList = lists;
    if (tabValue === 0) {
      filteredList = lists.filter(event => event.type === 0);
    } else {
      filteredList = lists.filter(event => event.type !== 0);
    }
    return !!filteredList ? filteredList.map(event => ({
      ...event,
      start: convertISOToLocalDate(event.start_time),
      end: convertISOToLocalDate(event.end_time)
    })) : [];
  }, [list, lists, tabValue]);

  const handleDeleteSlot = (item) => {
    setSlotDelete(!isSlotDelete, item);
  }

  const CustomEvent = ({ event }) => {
    const startTime = moment(event.start_time).format('h:mm A');
    const endTime = moment(event.end_time).add(1, 'seconds').format('h:mm A')

    return (<>
      <div className={classes.customEventStyle}>
        <div className={classes.textCont}>
          <div className={classes.textStyle}>{event.status === 0 ? event.type === 0 ? "VACANT" : "IN_CLINIC" : 'BOOKED'}</div>
          <div style={{ fontSize: '0.8em' }}>
            {startTime} - {endTime}
          </div>
        </div>
        <img src={DeleteSlot} onClick={() => handleDeleteSlot(event)} style={{ width: 16, height: 16 }} />
      </div>
    </>
    );
  };

  return (
    <div style={{ height: '500px' }}>
      <Calendar
        localizer={localizer}
        events={transformedEvents}
        view={viewType}
        timeslots={1}
        step={10}
        date={new Date(date)}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '500px' }}
        eventPropGetter={(event, start, end, isSelected) => {
          return {
            style: {
              backgroundColor: event.status === 0 ? event.type === 0 ? "#EAAD11" : "#F36363" : '#2AAB6C',
              color: '#ffffff',
              border: 'none',
            },
          };
        }}
        components={{
          event: CustomEvent,
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

export default SlotScheduler;

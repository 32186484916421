import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { useField } from 'formik'

import { commonMuiStyles } from '../../assets/styles/CommonMUIStyles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

const FieldWrapper = ({ name, ...otherProps }) => {
	const [field, meta] = useField(name)
	const classes = commonMuiStyles();
	const [showPassword, setShowPassoword] = useState(false)
	const handleClickShowPassword = () => {
		setShowPassoword(!showPassword);
	}
	const configTextField = {
		fullWidth: true,
		variant: 'outlined',
		...field,
		// "named" props above apply to all
		// Textfields present.
		// "otherProps" below will be custom tailored
		// to particular Text/Date etc. Fields
		// such as label, type, id, etc.
		...otherProps
	}

	// meta object containes
	// submitForm, isSubmitting, touched, errors
	if (meta && meta.touched && meta.error) {
		configTextField.error = true
		configTextField.helperText = meta.error
	}

	return <TextField type={showPassword ? "text" : "password"} className={classes.root}  {...configTextField}
		InputProps={{
			endAdornment: (
				(name === "password" || name === "confirmPassword" || name === 'newPassword') && <InputAdornment position="end">
					<IconButton
						onClick={handleClickShowPassword}
					>
						{showPassword ? (
							<Visibility className={classes.iconStyle} />
						) : (
							<VisibilityOff className={classes.iconStyle} />
						)}
					</IconButton>
				</InputAdornment>
			),
		}} />
}

export default FieldWrapper;

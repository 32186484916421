import { Grid, Paper } from "@mui/material";
import React from "react";
import { useLipidProfileStyles } from "./style";
import ListHeading from "../ListHeading/index1";
import { useSelector } from "react-redux";
import ReactCirculargauge from "../../LipidChart/ReactCirculargauge";
import { useStyles } from "../../../assets/styles/PatientDashboardStyles";
import styled from "styled-components";

/**
 * 
 * 1) Cholestrol 0-300
 * 2) HDL_C 0-70
 * 3) LDL_C 0-200
 * 4) Triglecrides 0-500
 * 5) VLDL 0-35
 * 
 * 
 * Triglyceridas : Normal - less than 150 MG/DL, High - 150-199 MG/DL, Very high - 200-499 MG/DL
 * Cholesterol : Normal - less than 200 MG/DL, High - 200-239 MG/DL, Very high - above 240 MG/DL
 * LDL-C : Normal - less than 100 MG/DL, High - 100-129 MG/DL, Very high - above 130 MG/DL
 * VLDL : Normal : 2-30 MG/DL
 * HDL-C: Normal : above 60 MG/DL, At risk : less than 40 MG/DL
 * @returns level in human readable format on the basis of level
 */
function calculateRange() {
  if (this.key === 'VLDL') {
    if (this.value >= 0 && this.value <= 30) return 'optimal'
    return 'at risk'
  } else if (this.key === 'HDL_C') {
    if (this.value >= 60) return 'at risk'
    if (this.value >= 40 && this.value <= 59) return 'low'
    if (this.value < 40) {
      return 'optimal'
    }
  }
  if (this.value < this.lowerRange) return 'optimal'
  else if (this.value >= this.lowerRange && this.value < this.upperRange) return 'high'
  else return 'very high'
}

const colors = {
  
  optimal: "#2AAB6C",
  mid: "#EAAD11",
  low: "#EAAD11",
  high: "#EAAD11",
  'at risk': "#F36363",
  'very high': "#F36363",
}

const ValueGrid = styled(Grid, { shouldForwardProp: (prop) => prop !== 'color' })(({ theme, color }) => ({
  fontFamily: 'Poppins',
  fontStyle: "normal",
  color,
  fontSize: 24,
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 700,
  marginTop: -25,
  lineHeight: '24px'
}))

const TextGrid = styled(Grid, {})(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: "normal",
  color: "#5E5E5E",
  fontSize: 12,
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 500,
  lineHeight: '24px',
}))

const capitalize = (text, append) => {
  const appendArr = append.split(' ')
  appendArr.forEach(x => {
    x = x.charAt(0).toUpperCase() + x.slice(1, x.length)
  })
  return `Your ${text} is ${appendArr.join(' ')}`
}

export function LipidProfile({ list, className, data }) {
  const lipidClasses = useLipidProfileStyles();
  const classes = useStyles()

  const lipidProfile = useSelector((state) => state.user.lipidProfile);

  const renderList = (listItem) => {
    const level = calculateRange.call(listItem)
    const color = colors[level]
    return (
      <Paper className={lipidClasses.paper}>
        <ListHeading text={listItem} className={classes} />
        <ReactCirculargauge {...{ ...listItem, color }} />
        <ValueGrid color={color}>
          {listItem.value}
        </ValueGrid>
        <TextGrid>
          {capitalize(listItem.primaryHeading, level)}
        </TextGrid>
      </Paper>
    )
  }

  return (
    !!lipidProfile ? lipidProfile?.map((listItem) => {
      return (
        window.location.pathname === "/patient/health" ?
          <Grid item className={classes.healthParameterCont} container direction="column" xs={12} sm={6} md={4} lg={3} spacing={2}>
            <Grid item>
              {renderList(listItem)}
            </Grid>
          </Grid>
          :
          <div>
            {renderList(listItem)}
          </div>
      )
    }) : null
  )

}

import { createSlice } from '@reduxjs/toolkit';
import { loginUserOnComet } from '../../utils';

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  error: null,
  userData: {},
  token: "",
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.userData = action.payload;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.error = null;
      try {
        if (state.userData.userType) {
          loginUserOnComet(state.userData.userType, state.userData, () => { })
        }
      } catch (error) {
      }
    },
    loginFailure(state, action) {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.token = null;
    },
    doctorLogout(state) {
      state.isAuthenticated = false;
      state.token = null;
    },
    organizationlogout(state){
      state.isAuthenticated = false;
      state.token = null;
    },
    subadminlogout(state){
      state.isAuthenticated = false;
      state.token = null;
    }
  },
});

export const { loginStart, loginSuccess, loginFailure, logout ,doctorLogout,organizationlogout,subadminlogout} = authSlice.actions;

export default authSlice.reducer;

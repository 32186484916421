import styled from 'styled-components';

export const StyledPricing = styled.div`
  position: relative;
`;

export const StyledContainer = styled.div`
	// position: absolute;
	// top: 50%;
	// left: 50%;
	// margin: -190px 0 0 -468px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	color: #9f9f9f;
	font-size: 15px;
`;

export const StyledPackage = styled.div`
	box-sizing: border-box;
	width: 301px;
	height: auto;
	border: 3px solid #e8e8e8;
	border-radius: 7px;
	display: inline-block;
	padding: 24px;
	text-align: center;
	float: left;
	transition: margin-top .5s linear;
	position: relative;
	margin-right: 11px;
  
  &:hover {
		border: 3px solid #60cf68;
	}
`;

export const StyledTitle = styled.h4`
	color: #60cf68;
	font-weight: 300;
	font-size: 3rem;
	margin-top: -5px;
`;

export const StyledSubTitle = styled.p`
	margin-top: 7px;
	font-weight: bold;

	&:hover {
		color: #60cf68;
	}

	&::after {
		content: " / month per user";
		font-weight: normal;
	}
`;

export const StyledTrail = styled.div`
	font-size: .9rem;
	font-weight: 600;
	padding: 2px 21px 2px 21px;
	color: $accent-color;
	border: 1px solid #60cf68;
	display: inline-block;
	border-radius: 15px;
	background-color: white;
	position: relative;
	bottom: -20px;
	color: #60cf68;
`;

export const StyledHr = styled.hr`
	background-color: #60cf68;
	border: none;
	height: 1px;
	margin-top: 5px;
`;

export const StyledPriceListing = styled.ul`
	list-style: none;
	padding: 0;
	text-align: left;
	margin-top: 29px;
`;

export const StyledListingValue = styled.li`
	margin-bottom: 15px;
	cursor: pointer;
	i {
		text-align: center;
    display: inline-block;
    height: 25px;
    width: 25px;
    line-height: 25px;
    color: #FFFFFE;
    border-radius: 50%;
    background-color: #60cf68;
    -webkit-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-right: 8px;
	}
  &:before {
    @extend .checkIcon;
    font-size: 1.3rem;
    color: $accent-color;
    margin-right: 3px;
	}
`;

export const StyledPriceButton = styled.button`
	margin-top: 20px;
  background: #19ce67;
  color: #ffffff;
  border: none;
  line-height: initial;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 17px 50px 15.5px 25px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  display: inline-flex;
  font-weight: 600;
  position: relative;

  i {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    display: inline-block;
    height: 33px;
    width: 33px;
    line-height: 33px;
    color: #ffffff;
    border-radius: 50%;
    background-color: #8ee9b2;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

`;
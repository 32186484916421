import { object, string, number } from "yup";
import * as Yup from "yup";

export const doctorSignUpSchema = object({
  email: string()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required"),
  phone_number: number()
    .positive("The number must be positive")
    .integer("The number must be an integer")
    .typeError("Please enter a valid phone number")
    .min(10, "Minimum 10 characters required")
    .required("Mobile Number is required"),
  name: string()
    .matches(/^(?!\\s)[a-zA-Z ]*$/, "Invalid input")
    .min(3, "Minimum 3 characters required")
    .required("Name is required"),
  org_name: string()
    .matches(/^(?!\\s)[a-zA-Z ]*$/, "Invalid input")
    .min(3, "Minimum 3 characters required")
    .required("This field is required"),
  address: string()
    .matches(/^(?!\\s)[a-zA-Z ]*$/, "Invalid input")
    .min(10, "Minimum 10 characters required")
    .required("Address is required"),
  country: string().required("Country is required"),
});

// .min(8, "Minimum 7 characters required")
// .required("Password is required"),

import moment from 'moment';
import React from 'react';
import CardComponent from '../Card';
import './index.css';

const IpdList = (prop) => {
    const { data, openIpdPatientView, title = "Previous Ipd"} = prop;
  
    return (
        <CardComponent className="mb-4 appointments-chart-card">
            <div className="d-flex row ml-1 current-vitals-chart-card-heading">
                <h5 className="pt-3 pl-3">{title}</h5>
            </div>
            <hr />
            <ul className="list-unstyled pl-3 pr-3" style={{ maxHeight: "250px", overflow: "scroll" }} >
                {
                    data.length ? data.map(item => {
                        return <li
                                className="received m-0 mb-2 current-vitals-chart-card-li" 
                                style={{ alignItems: "center",cursor:'pointer' }}
                                onClick={()=>openIpdPatientView(item._id)}
                            >
                            <div class="media mt-0">
                                <div class="media-body">
                                    <div class="d-flex align-items-center">
                                        <div class="mt-0">
                                            <h5 style={{wordBreak: 'break-all'}} class="mb-1 tx-13 font-weight-sembold text-dark">
                                                {item.admissionDateTime ? moment(item.admissionDateTime).format("DD-MM-YYYY") : '' }
                                            </h5>
                                            <p class="mb-0 tx-12 text-muted">{item._id ? item._id : ''}</p>
                                        </div>
                                        <span class="ml-auto">
                                            <span class="float-end badge-success-transparent">
                                                {item.totalPayableAmount}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr />

                        </li>
                    }) : <div className="d-flex justify-content-center" style={{ marginTop: "21%", textAlign: "center", padding: "20px" }}>Sorry! There are no Ipd History to display</div>
                }
            </ul>
        </CardComponent>
    )
}
export default IpdList;
import styled from 'styled-components';
import { ReactComponent as Menu } from '../../assets/img/menu-button.svg'
import colors from '../../constant/colors';

export const StyledContainer = styled.div`


    .custom-modal {
        @media (max-width: 450px){
            z-index: 99999999;
        }
    }
    .modal-footer {
        @media (max-width: 450px){
            display: flex;
            justify-content: center;
        }
    }
    .modal-dialog-centered {
        @media (max-width: 450px){
            margin: 0!important;
        }
    }
    .modal-content {
        width: 100vw !important;
        // height: 94vh !important;
        @media screen and (min-width: 450px) and (max-width: 700px){
            width: 100% !important;
        }
        @media (max-width: 450px){
            width: 100% !important;
            height: 105vh !important;
        }
    }
    .login-modal-content {
        height: 85vh !important;
        @media (max-width: 456px){
            height: 105vh !important;
        }
    }
    .modal-h-content {
        @media (max-width: 450px){
            display: flex;
            justify-content: center;
        }
    }
    .modal-header {
        @media (max-width: 450px){
            padding: 20px 30px !important;
        }
    }
    .modal-main-content-item {
        @media (max-width: 450px){
            display: flex;
            gap: 25px;
        }
    }
    .modal-card-item {
        @media (max-width: 450px){
            display: flex;
            justify-content: center;
        }
    }
    .modal-body {
        // overflow: scroll;
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        
        &::-webkit-scrollbar {
            width: 0;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
        }
        
        .form-control {
            border-radius: 8px !important;
            background: #F8F7F7 !important;
        }
        @media (max-width: 450px){
            width: 100% !important;
            padding: 0px 10px 0 40px !important;
            overflow: scroll !important;
        }
        .no-padding {
            @media (max-width: 450px){
                padding: 0px !important;
            }
            @media screen and (min-width: 450px) and (max-width: 700px){
                padding: 5% !important;
            }
        }
    }

    .padding-remove {
        @media (max-width: 450px){
            color: #2B2B2B!important;
        }
    }

    .home-nav-bar{
        @media (max-width: 450px){
            padding: 10px 40px;    
        }
    }
    .padding-remove{
        @media (max-width: 450px){
            padding-left: 0 !important;
            font-weight: 400;
            font-size: 18px;
        }
    }
    .logo {
        margin-right: 77px;
        @media (max-width: 450px){
        margin-right: 0;
        }
    }
    @media (min-width: 450px){
        .hide-screen {
            display: none;
        }
    }

    .call-box .call-wrapper {
        height: auto !important;
        text-align: center;
        padding: 20% 0px;
    }

    .log-emed {
        border: 1px solid #ccc;
        height: 40px;
        line-height: 10px;
        padding: 15px !important;
        border-radius: 10px;
        &:before {
            border: none !important;
        }
    }

    @media only screen and (max-width: 991.98px) {
        .main-nav > li > a {
            color: #fff;
        }
        .main-nav > li .submenu li a {
            white-space: normal;
            cursor : pointer;
        }
    }

    .main-nav > li .submenu li div {
        display: block;
        padding: 10px 15px;
        clear: both;
        white-space: nowrap;
        font-size: 14px;
        color: #2d3b48;
        font-weight: 500;
    }

    .main-nav li .submenu div:hover {
        color: #09dca4;
        letter-spacing: 0.5px;
        padding-left: 20px;
    }
  
`;

export const BackButton = styled.div`
    display: ${(props) => props.visible ? 'block' : 'none'}; 
    position: absolute;
    top: 18px;
    left: 18px;
    cursor: pointer;
    color: black;
    background-color:red;
`;

export const NavBar = styled.div`
    &.landing-page-header {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        background: transparent;
        border: none;
        color: #fff;
        a {
            color: #fff;
            @media (max-width: 450px){
                color: #2B2B2B!important;
            }
        }
        .shop-icon-svg {
            color: #fff 
        }
        @media (max-width: 450px){
            position: unset !important;
        }
    }

    &.landing-page-header:hover {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        background: #fff;
        border: none;
        color: #444;
        a {
            color: #444
        }
        .shop-icon-svg {
                fill: #444 
        }
    }
`;

export const MenuHeader = styled.div`
    &.heading-navbar {
        @media (max-width: 450px){
            font-size: 16px;
            color: #5E5E5E;
            font-weight: 400;
            padding-top: 20px;
        }
    }
    @media (min-width: 450px){
        &.hide-screen {
            display: none;
        }
    }
`;

export const MobileFooterIcon = styled.div`
    &.mobile-screen-footer-icon {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
    }
    @media (min-width: 450px){
        &.hide-screen {
            display: none;
        }
    }
`;

export const MobileScreenIcon = styled.ul`
    &.mobile-screen-icon {
        @media (max-width: 450px){
            display: flex;
            gap: 25px; 
            padding: 0;
        }
      
        li {
            @media (max-width: 450px){
                list-style: none;
            } 
        }
    }
`;

export const ShopIcon = styled.div`
    &.shop-icon-svg2 {
        height: 50px;
        width: 50px;
        margin-left: 0 !important;
    }
    @media (min-width: 450px){
        display: none;
    }
`;

export const ModalContent = styled.div`
    width: 100vw !important;
    // height: 94vh !important;
    @media (max-width: 450px){
        width: 100% !important;
        height: 105vh !important;
    }
`;

export const TextArea = styled.textarea`
    border: 1px solid #dcdcdc;
    color: #333;
    font-size: 15px;
    min-height: 46px;
    padding: 6px 15px;
    border-radius: 8px !important;
    background: #F8F7F7 !important;
    resize: none;
    width: 100%;
`;

export const ModalCardItem = styled.div`
    @media (max-width: 450px){
        display: flex;
        justify-content: center;
    }
`;

export const ModalCardInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
    @media (max-width: 450px){
        display: block !important;
    }
`;

export const QuestionariesHeading = styled.div`
    display: ${(props) => props.check ? 'flex' : 'none'};
    margin: 0 0 14px;
`;

export const TextWrapper = styled.div`
    width: -moz-available;
    width: -webkit-fill-available;
    border-bottom: ${(props) => props.border ? '2px solid transparent' : '1px solid #CCCCCC'};
	background-image: linear-gradient(white, white), linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
`;

export const QuestionaryTitle = styled.div`
    width: -webkit-fill-available;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    color: #2B2B2B;
    cursor: pointer;
    &.colored {
        background: ${colors.primary};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }
`;

export const ForgetPasswordText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    line-height: 24px;
    background: ${colors.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    cursor: pointer;    
    width: fit-content;
`;

export const ForgetPasswordWrapper = styled.div`
    display: ${(props) => props.check ? 'flex' : 'none'};
    justify-content: end;
    margin: 1px 0 20px !important;
`;

export const MenuButton = styled(props => <Menu {...props}/>)`
    width: 45px;
    height: 45px;
`;
import React, { useRef, useState } from "react";
import {
  Grid,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { UploadFileOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { StyledSecondaryButton } from "../../../assets/styles/CommonStyles";
import { Formik } from "formik";
import { genderList, validExtensions } from "../../../constant/staticLists";
import { personalDetailsSchema, personalDetailsSchemaForSubadmin } from "../../../utils/forms/schema.personalDetails";
import FieldWrapper from "../../../components/InputField/TextFieldWrapper";
import strings from "../../../constant/strings";
import { capitalizeFirstLetter } from "../../../constant/staticHelper";
import { symptoms } from "../../../constant/staticLists";
import { useSelector } from "react-redux";
import {
  FileListsStyledGrid,
  PatientInputGrid,
  UploadFileGrid,
} from "../../../assets/styles/PatientInputFormStyles";
import RadioInputForm from "./RadioInputForm";
import SelectionDropdown from "../../../components/control/SelectionDropdown";
import colors from "../../../constant/colors";
import Dialogs from "../../../components/controls/Dialogs";
import CancelIcon from '@mui/icons-material/Cancel';
import axios from "axios";
import { PATIENT_UPLOAD, SUBADMIN_UPLOAD } from "../../../services/apiUrl";
import * as mainService from "../../../services/mainService"
const useStyles = makeStyles(() => ({
  customAutocomplete: {
    "& .MuiAutocomplete-inputRoot": { background: colors.white3 },
    "& .MuiAutocomplete-input": { padding: 0, height: 30 },
  },
}));


const PatientInputForm = ({ detailObject, data, navigate }) => {
  const ref = useRef();
  const classes = useStyles();
  const [filesList, setFiles] = useState(
    !!data && !!data?.documents && data?.documents.length > 0
      ? data?.documents
      : []
  );
  const [show, setShow] = useState("");
  const [open, setOpen] = useState(false);
  const { userData, patientProfileData } = useSelector((state) => ({
    patientProfileData: state.user.patientProfile || {},
    userData: state.auth.userData
  }));
  
let user = localStorage.getItem("userType")
  const genderLists = Object.keys(genderList).map((key) => ({
    label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
    level: genderList[key],
  }));

  const symptomsList = symptoms.map((symptom, index) => ({
    label: symptom.name,
    level: symptom.name,
  }));
  const url = user === 'patient'?PATIENT_UPLOAD:SUBADMIN_UPLOAD

  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const now = new Date();
    let age = now.getFullYear() - dob.getFullYear();
    const monthsDiff = now.getMonth() - dob.getMonth();
    if (monthsDiff < 0 || (monthsDiff === 0 && now.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  }
  const age = calculateAge(patientProfileData?.dob).toString();

  let initialValues = {
    name: patientProfileData.name,
    age: age,
    weight: patientProfileData.weight,
    gender: patientProfileData.gender,
    symptoms:
      !!data && !!data?.symptoms && data?.symptoms.length > 0
        ? data?.symptoms
        : [],
    documents:
      !!data && !!data?.documents && data?.documents.length > 0
        ? data?.documents
        : [],
  };

  let initialValuesForSubadmin = {
    patientId: patientProfileData.patientId,
    symptoms:
      !!data && !!data?.symptoms && data?.symptoms.length > 0
        ? data?.symptoms
        : [],
    documents:
      !!data && !!data?.documents && data?.documents.length > 0
        ? data?.documents
        : [],
  };

  const handlePatientDetail = (values) => {
    const data = {
      symptoms: values.symptoms,
      documents: values.documents,
      patientId: values.patientId
    };
    detailObject(data, 4);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          fontSize: "14px",
          lineHeight: "18px",
          color: "#2B2B2B",
          paddingBottom: "16px",
        }}
      >
        Personal Details
      </Grid>
      <PatientInputGrid item xs={12}>
        <Grid container justifyContent={"space-between"} spacing={4}>
          <Formik
            initialValues={navigate != 'subadmin' ? initialValues : initialValuesForSubadmin}
            validationSchema={navigate != 'subadmin' ? personalDetailsSchema : personalDetailsSchemaForSubadmin}
            onSubmit={(values) => handlePatientDetail(values)}
            enableReinitialize
          >
            {({ values, handleSubmit, setFieldValue, errors }) => {
              const isSymptomsSelected = values.symptoms.length > 0;
              const isDocumentsSelected = values.documents.length > 0;
              const isFormValid =
                Object.keys(errors).length === 0 &&
                isSymptomsSelected &&
                isDocumentsSelected;

              const uploadFile = async (e) => {
                const limit = 2;
                const files = e.target.files;
                const fileExtension = files[0].name.split('.').pop().toLowerCase();
                if (!validExtensions.includes(fileExtension)) {
                  setOpen(true);
                  setShow("Please select file format from pdf,png,jpeg,jpg")
                }
                else if (e.target.files[0].size / 1024 / 1024 > limit) {
                  setOpen(true);
                  setShow("The size of file cannot exceed more than 5 MB.Please try with file of less size ")
                }
                else {
                  console.log("files[0]", files[0])
                  const formData = new FormData();
                  formData.append('file', files[0]);

                  try {

                    const response = await mainService.fileUpload(url, formData,userData.token)                  
                    const temp = [...filesList, response.response.data]
                    setFiles(temp);
                    setFieldValue("documents", temp);
                  } catch (error) {
                    console.error('Error:', error);
                  }

                }
              };

              const handleFileLength = (values, fieldName) => {
                if (filesList.length < 5)
                  ref.current.click();
                else {
                  setOpen(true)
                  setShow("Cannot upload more than 5 files")
                }
              };

              const handleDropdownValue = (data, fieldName, values) => {
                if (data) {
                  if (fieldName == "gender") {
                    const ids = data.level;
                    setFieldValue("gender", ids);
                  }
                  if (fieldName == "symptoms") {
                    let arr = [];
                    data.map((item) => arr.push(item.level));
                    setFieldValue("symptoms", arr);

                  }
                } else {
                  setFieldValue("gender", "");
                  setFieldValue("symptoms", []);
                }
              }

              const removeFile = (id) => {
                let temp = values.documents.filter((item, index) => index != id)
                setFiles(temp);
                setFieldValue("documents", temp);
              }

              return (
                <>
                  {navigate === 'subadmin' ? <Grid item xs={12} md={3}>
                    <FieldWrapper
                      type="text"
                      name="patientId"
                      label={`${strings.patientId}`}
                    />
                  </Grid> :
                    <>
                      <Grid item xs={12} md={3}>
                        <FieldWrapper
                          type="text"
                          name="name"
                          disabled={true}
                          label={`${strings.patientFullName}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FieldWrapper
                          type="text"
                          name="age"
                          disabled={true}
                          label={`${strings.age}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} position={"relative"}>
                        <FieldWrapper
                          type="text"
                          name="weight"
                          label={`${strings.weight}`}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <SelectionDropdown
                          height={"48px"}
                          className={classes.customAutocomplete}
                          name="gender"
                          disabled={true}
                          value={values.gender}
                          label={`${strings.selectGender}`}
                          list={genderLists}
                          handleDropdownValue={(data) =>
                            handleDropdownValue(data, "gender")
                          }
                        />
                      </Grid>
                    </>}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontSize: "14px",
                      color: "#2B2B2B",
                    }}
                  >
                    Symptoms
                  </Grid>
                  <Grid item xs={12} mt={-4}>
                    <Stack spacing={3} sx={{ width: 500 }}>
                      <SelectionDropdown
                        height={"48px"}
                        navigate={"multiple"}
                        className={classes.customAutocomplete}
                        name="symptoms"
                        value={values.symptoms}
                        placeholdere={strings.searchByDoctorName}
                        list={symptomsList}
                        handleDropdownValue={(data) =>
                          handleDropdownValue(data, "symptoms", values.symptoms)
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "var(--black, #2B2B2B)",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                  >
                    Upload Documents(you can upload upto 5 files and file size must be less than 10mb)
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <UploadFileGrid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        onClick={handleFileLength}
                      >
                        <Box>
                          <Box>
                            <UploadFileOutlined />
                          </Box>
                          <Typography className="filetypes">
                            pdf, jpg, jpeg & png files
                          </Typography>
                          <input
                            type="file"
                            ref={ref}
                            name="documents"
                            onChange={(data) => uploadFile(data, "documents")}
                          />
                        </Box>
                      </UploadFileGrid>
                      <FileListsStyledGrid
                        item
                        xs={12}
                        sm={6}
                        md={9}
                        paddingLeft={"16px"}
                      >
                        {values.documents.map((item, index) => (
                          
                          <Box paddingBottom={"16px"}>
                            <UploadFileOutlined /> {item.name} <IconButton onClick={() => removeFile(index)} ><CancelIcon /></IconButton>
                          </Box>
                        ))}
                      </FileListsStyledGrid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <StyledSecondaryButton
                      onClick={handleSubmit}
                      disabled={!isFormValid}
                      sx={{ width: "482px", textTransform: "capitalize" }}
                    >
                      Proceed to book appointment
                    </StyledSecondaryButton>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </Grid>
        {open && <Dialogs setOpen={setOpen} open={open} content={show} />}
      </PatientInputGrid>

    </>
  );
};

export default React.memo(PatientInputForm);

import AgoraRTC from "agora-rtc-sdk-ng"
import { e } from '../utils'
import { isMobile } from "react-device-detect";

const rtc = {
  // For the local client
  client: null,
  // For the local audio and video tracks
  localAudioTrack: null,
  localVideoTrack: null,
  // control: null,
};


var localTracks = {
  videoTrack: null,
  audioTrack: null
};

// const options = {
//   // Pass your app ID here
//   appId: "<YOUR APP ID>",
//   // Set the channel name
//   channel: "demo_channel_name",
//   // Pass a token if your project enables the App Certificate
//   token: null,
//   // // If not specified, agora will automatically assign one
//   // uid: null,
// };

async function startBasicCall(options, video, audio, type, setPeerIDs) {
  // Create a local client
  rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

  // Subscribe to a remote user
  rtc.client.on("user-published", async (user, mediaType) => {
    // Subscribe to a remote user
    await rtc.client.subscribe(user, mediaType);
    setPeerIDs(user.uid)
  
    if(type === 'video') {
      if (mediaType === "video" || mediaType === "all") {
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = user.videoTrack;
        // Dynamically create a container in the form of a DIV element for playing the remote video track.
        const playerContainer = document.createElement("div");
        // Specify the ID of the DIV container. You can use the `uid` of the remote user.
        playerContainer.id = user.uid;
        // playerContainer.style.width = isMobile ? "100%" : '50%';
        playerContainer.style.width = "100%";
        playerContainer.style.height = "100%";
        // document.body.append(playerContainer);
        
        const videoBox = e('.video-agora-box')
        videoBox.appendChild(playerContainer)
  
        // Play the remote audio and video tracks
        // Pass the ID of the DIV container and the SDK dynamically creates a player in the container for playing the remote video track
        remoteVideoTrack.play(playerContainer.id);
      }
    }
    
    if (mediaType === "audio" || mediaType === "all") {
      // Get `RemoteAudioTrack` in the `user` object.
      const remoteAudioTrack = user.audioTrack;
      rtc.control = user.audioTrack;

      // Play the audio track. Do not need to pass any DOM element
      remoteAudioTrack.play();
    }
  });

  rtc.client.on("user-unpublished", user => {
    // Get the dynamically created DIV container
    const playerContainer = document.getElementById(user.uid);
    // Destroy the container
    playerContainer && playerContainer.remove();
  });

  // Join a channel
  const uid = options.uid
  await rtc.client.join(options.appId, options.channel, options.token, options.uid);

//   [options.uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
//     // join the channel
//     rtc.client.join(options.appid, options.channel, options.token, options.uid || null),
//     // create local tracks, using microphone and camera
//     AgoraRTC.createMicrophoneAudioTrack(),
//     AgoraRTC.createCameraVideoTrack()
// ]);


  if(type === 'audio') {
    rtc.localAudioTrack = audio;
    localTracks.audioTrack = audio;

    // await rtc.client.publish(Object.values(localTracks));
    await rtc.client.publish([rtc.localAudioTrack]);

  } else {
    rtc.localAudioTrack = audio;
    rtc.localVideoTrack = video;
    localTracks.videoTrack = video;
    localTracks.audioTrack = audio;

    const localPlayer = document.createElement("div");
    localPlayer.id = options.uid;
    localPlayer.style.width = "100%";
    localPlayer.style.height = "100%";
    const videoBox = e('#video-agora-local')
    videoBox.appendChild(localPlayer)
    rtc.localVideoTrack.play(localPlayer.id);
    // Publish the local audio and video tracks to the channel
    await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
  }

  // Create and publish the local tracks
  // Create an audio track from the audio captured by a microphone
  // rtc.localAudioTrack = audio  //await AgoraRTC.createMicrophoneAudioTrack();
  // Create a video track from the video captured by a camera
  // rtc.localVideoTrack = video //await AgoraRTC.createCameraVideoTrack();
  setPeerIDs(rtc.client.uid)
  return true;
}

async function leaveCall(type) {
  rtc.localAudioTrack.close();
  if(type === 'audio') {

  } else {
    rtc.localVideoTrack.close();
    const localPlayer = document.getElementById(rtc.client.uid);
    localPlayer && localPlayer.remove();

      // Traverse all remote users
    rtc.client.remoteUsers.forEach(user => {
      // Destroy the dynamically created DIV container
      const playerContainer = document.getElementById(user.uid);
      playerContainer && playerContainer.remove();
    });
  }
 try {
  // Leave the channel
  const leave_respones = await rtc.client.leave();
 } catch (err) {
  return;
 }
  
}


async function unMuteAudioToggle() {
  localTracks.audioTrack.setVolume(100);
}
async function muteAudioToggle() {
  localTracks.audioTrack.setVolume(0);
}

async function resumeVideo() {
  localTracks.videoTrack.setMuted(false);
}
async function pauseVideo() {
  localTracks.videoTrack.setMuted(true);
}


export {
  startBasicCall,
  leaveCall,
  muteAudioToggle,
  unMuteAudioToggle,
  pauseVideo,
  resumeVideo
}





import { call, put, takeLatest } from 'redux-saga/effects';
import * as authService from '../../services/authService';
import { loginAction, logoutAction, doctorLoginAction, resetPasswordAction,doctorlogoutAction, organizationLoginAction, organizationlogoutAction, subadminlogoutAction, SubadminLoginAction, resetOrgPasswordAction } from './authActions';
import { loginSuccess, loginFailure, logout ,doctorLogout, organizationlogout, subadminlogout} from './authSlice';
import { push } from '../router/routerSlice';
import { doctorloginApi, organizationloginApi } from '../../services/authService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function* loginSaga(action) {
  try {
    const apiResponse = yield call(authService.loginApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        userData.userType = "patient"
        yield put(loginSuccess(userData));
        if (userData?.termscondition === false) {
          yield put(push("/first-time-login"));
        } else {
          // userData.org not coming from api so i changed to userData?.verified
          if (userData?.verified === true) {
            toast.success(apiResponse?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
            if (userData.gender === null) {
              yield put(push("/patient/settings"));
            } else {
              yield put(push("/patient/dashboard"));
            }
          } else {
            yield put(push('/patient/medical-record'));
          }
        }
      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(loginFailure(apiResponse.response.message))
      }
    }
  }
  catch (error) {
    console.log('Error:', error);
  }
}

function* doctorLoginSaga(action) {
  try {
    const apiResponse = yield call(doctorloginApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        userData.userType = "doctor"
        yield put(loginSuccess(userData));
        toast.success(apiResponse?.response?.message, { position: "bottom-center", closeButton: false , autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(push("/doctor/schedule"));
        // if (userData?.termscondition === false) {
        //   yield put(push("/first-time-login"));
        // } else {
        //   if (userData?.verified === true) {
        //     toast.success(apiResponse?.response?.message, { position: "bottom-center", closeButton: false })
        //     yield put(push("/patient/dashboard"));
        //   } else {
        //     yield put(push('/patient/medical-record'));
        //   }
        // }
      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false , autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(loginFailure(apiResponse.response.message))
      }
    }
  }
  catch (error) {
    console.log('Error:', error);
  }
}

function* organizationLoginSaga(action) {
  try {
    //const apiResponse = yield call(authService.doctorloginApi, action.payload);
    const apiResponse = yield call(authService.organizationloginApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        userData.userType = "organization"
        yield put(loginSuccess(userData));
        toast.success(apiResponse?.response?.message, { position: "bottom-center", closeButton: false , autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(push("/organization/dashboard"));
      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false , autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(loginFailure(apiResponse.response.message))
      }
    }
  }
  catch (error) {
    console.log('Error:', error);
  }
}
function* subadminLoginSaga(action) {
  try {
    
    const apiResponse = yield call(authService.subadminloginApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const userData = apiResponse.response.data;
        console.log("userData",userData)
        userData.userType = "subadmin"
        yield put(loginSuccess(userData));
        toast.success(apiResponse?.response?.message, { position: "bottom-center", closeButton: false , autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(push("/subadmin/home"));
      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false , autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(loginFailure(apiResponse.response.message))
      }
    }
  }
  catch (error) {
    console.log('Error:', error);
  }
}

function* resePasswordSaga(action) {
  const response = yield call(authService.updateProfile, action.payload.credentials, action.payload.token);
  if (response.response_type === 'success') {   
    yield put(logout);  
    localStorage.clear()
    toast.success("Password updated successfully.", { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
    yield put(push("/"));
  } else {
    toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
  }
}


function* logoutSaga(action) {
  const response = yield call(authService.logout, action.payload);
  if (response.response_type === 'success') {   
    yield put(logout);  
    localStorage.clear()
    toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
    yield put(push("/"));
  } else {
    toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
  }
}

function* doctorlogoutSaga(action) {
  const response = yield call(authService.doctorlogout, action.payload);
  if (response.response_type === 'success') {   
    yield put(doctorLogout);
    localStorage.clear()
    toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
    yield put(push("/"));
  } else {
    toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
  }
}

function* organizationlogoutSaga(action) {
  const response = yield call(authService.organizationlogout, action.payload);
  if (response.response_type === 'success') {   
    yield put(organizationlogout);
    localStorage.clear()
    toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
    yield put(push("/"));
  } else {
    toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
  }
}

function* resetOrgPasswordSaga(action) {
  const response = yield call(authService.organizationResetprofile, action.payload.credentials, action.payload.token);
  if (response.response_type === 'success') {   
    yield put(organizationlogout);  
    localStorage.clear()
    toast.success("Password updated successfully.", { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
    yield put(push("/"));
  } else {
    toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
  }
}

function* subadminlogoutSaga(action) {
  const response = yield call(authService.subadminlogout, action.payload);
  console.log("auth",response)
  console.log("action",action)

  if (response.response_type === 'success') {   
    yield put(subadminlogout);
    localStorage.clear()
    toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
    yield put(push("/"));
  } else {
    toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
  }
}
export function* authSaga() {
  yield takeLatest(loginAction.type, loginSaga);
  yield takeLatest(logoutAction.type, logoutSaga);
  yield takeLatest(doctorLoginAction.type, doctorLoginSaga);
  yield takeLatest(resetPasswordAction.type, resePasswordSaga);
  yield takeLatest(doctorlogoutAction.type, doctorlogoutSaga);
  yield takeLatest(organizationLoginAction.type,  organizationLoginSaga);
  yield takeLatest(organizationlogoutAction.type, organizationlogoutSaga);
  yield takeLatest(SubadminLoginAction.type, subadminLoginSaga);
  yield takeLatest(subadminlogoutAction.type, subadminlogoutSaga);
  yield takeLatest(resetOrgPasswordAction.type,resetOrgPasswordSaga);
}

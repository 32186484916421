import styled from 'styled-components';

export const StyledContainer = styled.div`
.content {
  background: #fff;
  .container-fluid {
	padding-left: 15px;
	padding-right: 15px;
  }
}

.btn-primary {
  background-color: #5156be;
  border: 1px solid #5156be;
}


.postion-relative {
  position: relative;
}
.remove-heath {
  position: absolute;
  right: 6px;
  top: -10px;
  background: #5156be;
  color: #fff;
  font-size: 20px;
  border: #5156be;
  border-radius: 50%;
  padding: 4px 12px;
}

a {
  cursor: pointer;
}

.health-btn-outline {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  color: #5156be;
  text-transform: uppercase;
  font-weight: 500;
  background: transparent;
  margin-right: 20px;
  cursor: pointer;
}

.health-active-btn {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 20px;
  background: #1dbe72;
  color: #fff;
  cursor: pointer;
}

.success-bmi {
  background: #b6e297;
  color: #000;
}
.under-bmi {
  background: #f8ccce;
  color: #000;
}
.over-bmi {
  background: #fcfbc2;
  color: #000;
}
.obesity-bmi {
  background: #dd8990;
  color: #000;
}

.color-white {
  color: #000 !important;
}
.color-white h5 {
  color: #000 !important;
}
.line-chart-buttons {
  color: #000;
  background: transparent;
  border: none;
}
.line-chart-buttons:hover,  .line-chart-buttons::after{
  background:#09dca4
}
.selected-group {
  background-color: #19ce67;
}


.hb-administration-container {
	display: flex;
	position: relative;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	justify-content: center;
  }
  .hb-administration-check-circle {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  .hb-drug-admin-checkmark {
	position: absolute;
	top: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
  	border-radius: 15px;
  }
  .hb-drug-admin-checkmark {
	background-color: #ccc;
  }
  .hb-drug-admin-checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  .hb-drug-administration-check-circle-mobile-screen {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45desg);
    transform: rotate(45deg);
  }

  @media only screen and (max-width: 450px) {
    .hb-col-health-records-chart-mobile-screen {
      padding: 0 !important;
    }
    .mobile-screen-card-main-hb {
      padding-left: 0 !important; 
      padding-right: 0 !important; 
    }
  }
 
`;
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React, { useState } from "react";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import {
  StyledSecondaryButton,
  StyledCrossIcon,
} from "../../assets/styles/CommonStyles";
import strings from "../../constant/strings";
import FieldWrapper from "../InputField/TextFieldWrapper";
import * as mainService from "../../services/mainService.js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import TabComponent from "../Tab";
import SelectionDropdown from "../control/SelectionDropdown";
import {
  bloodGroup,
  bloodGroupType,
  genderList,
  occupationList,
} from "../../constant/staticLists";
import { capitalizeFirstLetter } from "../../constant/staticHelper";
import {
  addPatientIdSchema,
  addPatientSchema,
} from "../../utils/forms/schema.addPatientSchema";
import PhoneField from "../PhoneField/index.js";
import { getPatientListAction } from "../../redux/list/listAction.js";
import { commonMuiStyles } from "../../assets/styles/CommonMUIStyles.js";
import { medicationStyles } from "../../assets/styles/MedicationStyles.js";
import { string } from "yup";

const AddPatient = ({ setOpenModal, openModal }) => {
  const classes = useStyles();
  const styleClasses = commonMuiStyles();
  const medicationClasses = medicationStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [onLoading, setOnLoading] = useState(false);
  const dispatch = useDispatch();
  let user = localStorage.getItem('userType')

  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));

  const genderLists = Object.keys(genderList).map((key) => ({
    label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
    level: genderList[key],
  }));

  const bloodGroupList = Object.keys(bloodGroup).map((key) => ({
    label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
    level: bloodGroup[key],
  }));

  let initialValues = {
    name: "",
    email: "",
    gender: "",
    bloodGroup: "",
    phoneNumber: "",
    address: "",
    countryCode: "",
  };

  let initialValuesId = {
    patientId: "",
  };

  const tabs = [
    {
      id: 0,
      name: strings.manually,
    },
    {
      id: 1,
      name: strings.patientId,
    },
  ];

  const handleSubmit = async (values, { resetForm }) => {
    setOnLoading(true);
    let phone = values.countryCode.replace("+", "");
    values.phoneNumber = values.phoneNumber.replace(phone, "");
    const res = await mainService.addPatientByFormApi({
      token: userData.token,
      data: values,
    });

    if (res.response_type === "fail") {
      setOnLoading(false);
      setOpenModal(false);
      resetForm();
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      setOnLoading(false);
      setOpenModal(false);
      resetForm();
      toast.success(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
      dispatch(getPatientListAction(userData.token));
    }
  };

  const handleValue = async (values, { resetForm }) => {
    setOnLoading(true);
    const res = await mainService.addPatientByIdApi({
      token: userData.token,
      patientID: values.patientId,
    });

    if (res.response_type === "fail") {
      setOnLoading(false);
      setOpenModal(false);
      resetForm();
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      setOpenModal(false);
      setOnLoading(false);
      resetForm();
      toast.success(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
      user === 'organization' && dispatch(getPatientListAction(userData.token));
    }
  };

  return (
    <div>
      <Grid className={classes.secondayCont}>
        <h2 className={classes.mainCont}>{strings.addPatient}</h2>
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="User"
          onClick={() => setOpenModal(false)}
        />
      </Grid>
      <div>
        <Formik
          initialValues={tabValue === 0 ? initialValues : initialValuesId}
          validationSchema={
            tabValue === 0 ? addPatientSchema : addPatientIdSchema
          }
          onSubmit={tabValue === 0 ? handleSubmit : handleValue}
        >
          {({
            handleChange,
            handleBlur,
            errors,
            handleSubmit,
            values,
            touched,
            setTouched,
            setFieldValue,
            resetForm,
          }) => {
            const handleDropdownValue = (fieldName, data) => {
              setFieldValue(fieldName, data.level);
            };

            const handlePhone = (e, value) => {
              setFieldValue("countryCode", value);
              setFieldValue("phoneNumber", e);
            };

            const handleTab = (e, newValue) => {
              resetForm();
              setTimeout(() => {
                setTabValue(newValue);
              }, 0);
            };

            return (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <TabComponent
                    tabs={tabs}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    handleTab={handleTab}
                    customTab={`${styleClasses.customTab} ${classes.tabFontSize}`}
                    customTabIndicator={styleClasses.customTabIndicator}
                  />
                </Grid>
                {tabValue === 0 ? (
                  <>
                    <Grid item xs={12}>
                      <FieldWrapper
                        type="text"
                        name="name"
                        label={`${strings.patientFullName}`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="email"
                        type="text"
                        label={`${strings.patientEmail}`}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="gender"
                        label={`${strings.selectGender}`}
                        list={genderLists}
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("gender", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="bloodGroup"
                        label={`${strings.bloodGroup}`}
                        list={bloodGroupList}
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("bloodGroup", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PhoneField
                        value={initialValues.phoneNumber}
                        name="phoneNumber"
                        onChange={(e, value) => handlePhone(e, value.dialCode)}
                        onBlur={() =>
                          setTouched({ ...touched, phoneNumber: true })
                        }
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="address"
                        type="text"
                        label={`${strings.patientFullAddress}`}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="patientId"
                        type="text"
                        label={`${strings.patientUniqueId}`}
                      />
                    </Grid>
                    <Box sx={{ padding: 2 }}>
        <Typography variant="body1">
        <strong>{strings.patientUniqueId}</strong> is a combination of the first 4 letters of <strong>Name</strong>, <strong>Country Code</strong>, and <strong>Contact Number</strong>.
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
          Example: <strong>John11234567890</strong>
        </Typography>
      </Box>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  alignItems="center"
                  display="flex"
                  justifyContent={"center"}
                >
                  <StyledSecondaryButton
                    name="submit"
                    disabled={Object.keys(errors).length > 0 || onLoading}
                    onClick={handleSubmit}
                    variant="contained"
                  >
                     {onLoading ? (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={20}
                          thickness={5}
                        />
                      ) : (
                        strings.add
                      )}
                    
                  </StyledSecondaryButton>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddPatient;

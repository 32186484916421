import React, {useContext} from 'react';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Context } from '../../../store';
import InputField from '../../InputField'
import PdfPreviewInvoice from '../../PdfPreview/PdfPreviewInvoice';
import { globalValidator } from "../../../utils";
import SelectField from '../../SelectField';
import { medicineType, strengthType } from '../../../constants';

import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import { addDischargePatientByDoctor, addServiceWardPatient } from '../../../api/organization';

import '../index.css';
import { useHistory } from 'react-router-dom';

const blankMedication = {
    medication_name: '',
    medicine_type: '',
    strength_name: '',
    strength_type: '',
    // method_intake: '',
    // dos_quantity_count: '',
    dos_quantity_name: '',
    // dos_quantity_when: ''

};

const diagnosis = '';

const DoctorDischargeModal = (prop) => {
	const [state, dispatch] = useContext(Context);
    const history = useHistory();
    const { id, patientDetails, setPatientDetails,PatientFollowUpDetails, setModalVisible, loader,showPreviewModal,setShowSubModal } = prop;
	const [payment, setPaymentData] = React.useState([]);
    const [pdfPrevieModal,setPdfPreviewModal] = React.useState(false);
    const [infoMedication, setInfoMedication] = React.useState({});
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [fields, setFields] = React.useState([
        { ...blankMedication },
    ]);

    const [diagnosisFields, setdiagnosisFields] = React.useState([
        diagnosis
    ])
    
    const [serviceDetails, setServicesDetails] = React.useState({
		serviceDate: '',
        serviceName: '',
        serviceAmount: '',
        // serviceAddedBy: JSON.parse(localStorage.getItem('subuser'))._id,
		addedByApi : false,
    })
    const [dischargeData, setDischargeData] = React.useState({
        dischargeDate: '',
        notes: ''
    });
    const handlechargesInput = (e) => {
        const reg = /^(0|[1-9]\d*)$/
        if (reg.test(e.target.value) || e.target.value === '') {
            setServicesDetails((serviceDetails) => ({ ...serviceDetails, [e.target.name]: e.target.value }))
        } else {
            toast.error('Please Input Only Numbers')
        }
    }
    const handleDeleteService = (id) => {
        const data = patientDetails?.services;
        data.splice(id, 1);
        setPatientDetails({ ...patientDetails, services: data })
        setPaymentData(data);
        forceUpdate()
    }
    const handleSubmit= () => {
        const keys = [
            { name: "dischargeDate", title: "Discharge Date", validateKey: "required" },
        ];
        let valid = globalValidator(dischargeData, keys);
        if (valid === true) {
            loader(true)
            const disData = {
                ...dischargeData,
                dischargeDate: dischargeData.dischargeDate,
                dischargeMedication : fields,
                dischargeDiagnosisNotes : dischargeData.notes,
                dischargeDiagnosis : diagnosisFields
            }
            addServiceWardPatient(id, payment).then((res) => {
                addDischargePatientByDoctor(id, disData).then((resp) => {
                    loader(false)
                    setPatientDetails(resp.data);
                    setModalVisible(false);
                    toast.success("Patient is successfully discharged!")
                    dispatch({
                        type: SET_ERROR,
                        payload: "Patient is successfully discharged!"
                    });
                    history.push(`/doctor/schedule`)
                    if (showPreviewModal) {
                        setShowSubModal(true);
                    } else {
                        document.body.style.overflowY = 'auto';
                    }
                }).catch((err) => {
                    toast.error(err)
                    dispatch({
                        type: SET_ERROR,
                        payload: err.response.data.error
                    });
                    loader(false)
                })
            }).catch((err) => {
                loader(false)
            })
        } else {
            toast.error(valid)
        }
    }
    const handlePreview = () => {
        setPdfPreviewModal(true);
        const keys = [
            { name: "dischargeDate", title: "Discharge Date", validateKey: "required" },
        ];
        let valid = globalValidator(dischargeData, keys);
        if (valid === true) {
            const disData = {
                ...dischargeData,
                dischargeDate: dischargeData.dischargeDate,
                dischargeMedication : fields,
                dischargeDiagnosisNotes : dischargeData.notes,
                dischargeDiagnosis : diagnosisFields
            }
            setInfoMedication(disData);
        }
    }
    const handleServiceSubmit = () => {
        const keys = [
            { name: "serviceDate", title: "Date", validateKey: "required" },
            { name: "serviceName", title: "Service Name", validateKey: "required" },
            { name: "serviceAmount", title: "Charges", validateKey: "required" },
        ];
        let valid = globalValidator(serviceDetails, keys);

        if (valid === true) {
            setPaymentData([...payment, serviceDetails]);
            setPatientDetails({ ...patientDetails, services: [...patientDetails.services, serviceDetails] })

            setServicesDetails({
                serviceDate: '',
                time: '',
                serviceName: '',
                serviceAmount: '',
                // serviceAddedBy: JSON.parse(localStorage.getItem('doctor'))._id,
                addedByApi: false,
            })
        } else {
            toast.error(valid)
        }
    }
    const payableAmount = () => {
        return totalAmount();
         // return total + (dischargeData.otherAmount ? parseInt(dischargeData.otherAmount) : 0) + (total * (dischargeData.tax && parseInt(dischargeData.tax)) / 100) - (dischargeData.discount && parseInt(dischargeData.discount))
     }
    const totalAmount = () => {
        let total = []
        let sum = 0;
        patientDetails.services && patientDetails?.services?.map((item) => {
            item?.serviceAmount && total.push(parseInt(item?.serviceAmount)) 
        })

        for (let i = 0; i < total.length; i++) {
            sum += total[i];
        }
        return sum;
    }

    function handleAdd() {
		setFields([...fields, { ...blankMedication }]);
	}

    function handleAddDiagnosis(){
        setdiagnosisFields([...diagnosisFields,diagnosis]);
    }

    const handleDiaChange = (e, index) => {
        const updatedCats = [...diagnosisFields];
		updatedCats[index] = e.target.value;
		setdiagnosisFields(updatedCats);
    }
    const handleCatChange = (e, index, type, key) => {
		const updatedCats = [...fields];
		updatedCats[index][key] = e.target.value;
		setFields(updatedCats);
		// updatedCats[e.target.dataset.idx][e.target.className] = e.target.value;
		// setFields(updatedCats);
	};

    return (
        <>
            <div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '70%' }} role="document">
                    <div className="modal-content" style={{ maxHeight: "700px", overflowY: "scroll" }}>
                        <div className="col-md-11 service-discharge-form" style={{ padding: '30px' }}>
                            <div style={{ display: "flex" }}>
                                <p style={{ fontSize: "18px", fontWeight: "500" }}>Medical discharge</p>
                            </div>
                            {/* <div className="row pt-3">
                                <div className="col-md-2 no-padding form-group">
                                    <label>Service</label>
                                    <InputField value={serviceDetails.serviceName} onChange={e => setServicesDetails((serviceDetails) => ({ ...serviceDetails, serviceName: e.target.value }))} />
                                </div>
                                <div className="col-md-3 no-padding form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Date</label>
                                    <DatePicker minDate={new Date()} showTimeSelect value={serviceDetails.serviceDate} className="ipdwardDatePicker" selected={serviceDetails.serviceDate} onChange={date => setServicesDetails((serviceDetails) => ({ ...serviceDetails, serviceDate: date }))} />
                                </div>
                                <div className="col-md-3 no-padding form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Time</label>
                                    <TimePicker
                                        openClockOnFocus
                                        onChange={time => {
                                            if (serviceDetails.serviceDate) {
                                                serviceDetails.serviceDate.setHours(time.split(':')[0]);
                                                serviceDetails.serviceDate.setMinutes(time.split(':')[1]);
                                            }
                                            else if (time) {
                                                new Date().setHours(time.split(':')[0]);
                                                new Date().setMinutes(time.split(':')[1]);
                                            }
                                            setServicesDetails((serviceDetails) => ({ ...serviceDetails, serviceDate: serviceDetails.serviceDate }))
                                        }}
                                        value={serviceDetails.serviceDate}
                                        format="hh:mm:ss a"
                                        className="admission-details-timepicker"
                                    />
                                </div>
                                <div className="col-md-2 no-padding form-group">
                                    <label>Charges</label>
                                    <InputField name="serviceAmount" value={serviceDetails.serviceAmount} onChange={handlechargesInput} />
                                </div>
                                <div className="col-md-2 no-padding form-group">
                                    <button style={{ marginLeft: 'auto', marginTop: '1.75rem' }} className="doctor-active-btn doctor-w-20" onClick={handleServiceSubmit}>Submit </button>
                                </div>
                            </div>
                            <div style={{ height: "200px", overflowY: "auto" }}>
                                <table className="table table-bordered ">
                                    <thead className="thead-light to-do-thead" style={{ position: "sticky", top: -1 }}>
                                        <tr>
                                            <th>No</th>
                                            <th>Date & Time</th>
                                            <th>Service</th>
                                            <th>Charges</th>
                                            <th>Added By</th>
                                            <th style={{ minWidth: "30px" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ overflow: "scroll" }} key={payment}>
                                        {patientDetails?.services && patientDetails?.services.map((data, i) => {
                                            return (
                                                <tr key={"item-" + i} className="to-do-table-row">
                                                    <td>{i + 1}</td>
                                                    <td style={{ flexDirection: 'row' }}>
                                                        {moment(data.date).format('Do MMM, YYYY')}{" "}{data.serviceDate && moment(data.serviceDate).format('hh:mm a')}
                                                    </td>
                                                    <td>
                                                        {data.serviceName}
                                                    </td>
                                                    <td>
                                                        {data.serviceAmount}
                                                    </td>
                                                    <td>
                                                        {data.addedBy}
                                                    </td>
                                                    <td style={{ textAlign: 'center', padding: '10px 15px' }}>
                                                        {
                                                            (data.addedByApi && data.addedByApi) === false ?
                                                                <i style={{ margin: "auto", padding: "inherit", cursor: "pointer" }} class="fa fa-times" aria-hidden="true" onClick={() => handleDeleteService(i)}></i>
                                                                : null
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                             */}
                            <div className="col-md-10 ml-auto row mt-3">
                                <div className="col-md-4 no-padding form-group" style={{ textAlign: 'center' }}>
                                    <label style={{ fontWeight: '600' }}>Discharge Date</label>
                                </div>
                                <div className="col-md-4 no-padding form-group ml-auto row" style={{ flexDirection: 'column' }}>
                                    <DatePicker minDate={new Date()} showTimeSelect value={dischargeData.dischargeDate} className="ipdwardDatePicker" selected={dischargeData.dischargeDate} onChange={date => setDischargeData((dischargeData) => ({ ...dischargeData, dischargeDate: date }))} />
                                </div>
                                <div className="col-md-4 no-padding form-group" style={{ flexDirection: 'column' }}>
                                    <TimePicker
                                        openClockOnFocus
                                        // clearIcon={false}
                                        onChange={time => {
                                            if(time == null){
                                               return false
                                            }
                                            if (dischargeData.dischargeDate) {
                                                dischargeData.dischargeDate.setHours(time.split(':')[0]);
                                                dischargeData.dischargeDate.setMinutes(time.split(':')[1]);
                                            }
                                            else if (time) {
                                                new Date().setHours(time.split(':')[0]);
                                                new Date().setMinutes(time.split(':')[1]);
                                            }
                                            setDischargeData((dischargeData) => ({ ...dischargeData, dischargeDate: dischargeData.dischargeDate }))
                                        }}
                                        value={dischargeData.dischargeDate}
                                        format="hh:mm:ss a"
                                        className="admission-details-timepicker"
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-10 ml-auto row">
                                <div className="col-md-3 no-padding form-group ml-auto">
                                    <label style={{ fontWeight: '600' }} className="ml-auto">Amount : </label>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4 no-padding form-group ml-auto row" style={{ flexDirection: 'column' }}>
                                    <span>{totalAmount()}</span>
                                </div>
                            </div> */}
                            <div className='col-md-12 mb-2' style={{display:'flex',padding:"0px"}}>
                                <h3>Final diagnosis</h3>
                                <button style={{ marginRight: "0px" }} className="doctor-active-btn mrn-auto" onClick={() => handleAddDiagnosis()}> <i class="fa fa-plus"> </i></button>
                            </div>
                            <div className="col-md-14">
                                {
										diagnosisFields.map((val, idx) => {
											return (
												<>
													<div key={`medication_name-${idx}`} className="row">
														<div className="col-md-12 no-padding form-group ">
															<label>Diagnosis and observation:</label>
															<InputField
																placeholder="Enter diagnosis and observation"
																name={diagnosis}
																value={diagnosisFields[idx]}
																onChange={(e) => handleDiaChange(e, idx)}
															/>
														</div>
													</div>
												</>
											)
										}
										)}
									</div>
                            <div className='col-md-12' style={{display:'flex',padding:"0px"}}>
                                <h3>Discharge medication</h3>
                                <button style={{ marginRight: "0px" }} className="doctor-active-btn mrn-auto" onClick={() => handleAdd()}> <i class="fa fa-plus"> </i></button>
                            </div>

                            <div className="col-md-14">
                                {
										fields.map((val, idx) => {
											const medication_nameId = `smedication_name-${idx}`;
											const medicine_typeId = `medicine_type-${idx}`;
											const strength_nameId = `strength_name-${idx}`;
											const strength_typeId = `strength_type-${idx}`;

											return (
												<>
													<div key={`medication_name-${idx}`} className="row">
														<div className="col-md-3 no-padding form-group ">
															<label>Medication name:</label>
															<InputField
																placeholder="eg. paracetamol"
																name={medication_nameId}
																value={fields[idx].medication_name}
																onChange={(e) => handleCatChange(e, idx, 'text', 'medication_name')}
															/>
														</div>

														<div className="col-md-3 no-padding form-group ">
															<label>Form of medicine:</label>
															<SelectField
																value={fields[idx].medicine_type}
																onChange={(e) => handleCatChange(e, idx, 'select', 'medicine_type')}
																name={medicine_typeId}
																options={medicineType} />
														</div>

														<div className="col-md-3 no-padding form-group ">
															<label>Strength:</label>
															<InputField
																placeholder="eg. 100"
																name={strength_nameId}
																value={fields[idx].strength_name}
																onChange={(e) => handleCatChange(e, idx, 'text', 'strength_name')}
															/>
														</div>

														<div className="col-md-3 no-padding form-group ">
															<label>Strength type:</label>
															<SelectField
																name={strength_typeId}
																value={fields[idx].strength_type}
																onChange={(e) => handleCatChange(e, idx, 'select', 'strength_type')}
																options={strengthType} />
														</div>
														
													</div>
												</>
											)
										}
										)}
									</div>

                            <div className='col-md mb-3' style={{display:'flex',padding:"0px"}}>
                                <h3>Discharge Summary</h3>
                            </div>
                            <div className="col-md-12 ml-auto row">
                                <div className="col-md-6 no-padding form-group">
                                    <label style={{ fontWeight: '600' }} className="ml-auto">Daily visiting : </label>
                                </div>
                                <div className="col-md-6 no-padding form-group ml-auto row" style={{ flexDirection: 'column' }}>
                                    <SelectField value={dischargeData.paymentMode} onChange={e => setDischargeData((dischargeData) => ({ ...dischargeData, paymentMode: e.target.value }))} 
                                    options={
                                        PatientFollowUpDetails ? PatientFollowUpDetails.map((item,id) => {
                                            return { id: id, label: moment(item.followUpDate).format('Do MMM YYYY'), value: item.followUpDate }
                                        }) : {id:1, label: 'No Date', value: 'No Date'}
                                    }
                                        />
                                </div>
                                
                            </div>
                            <div className="col-md-12 ml-auto row">
                                <div className="col-md-6 no-padding form-group">
                                    <label style={{ fontWeight: '600' }} className="ml-auto">Final diagnosis notes : </label>
                                </div>
                                <div className="col-md-6 no-padding form-group ml-auto row" style={{ flexDirection: 'column' }}>
                                    <textarea name="notes" value={dischargeData.notes} onChange={(e) => setDischargeData((dischargeDate) => ({ ...dischargeDate, notes: e.target.value }))} rows={3} cols={50} style={{ lineHeight: "24px", padding: "10px", width: "100%", borderRadius: "5px", border: "2px solid #979797" }} />
                                </div>
                            </div>
                            <div className='col-md-12 ml-auto row'>
                                <div className="col-md-6 mb-4">
                                    <div className="col-md-14">
                                    <label style={{ fontWeight: '600' }} className="ml-auto">Radiology</label>
                                    </div>
                                    {!PatientFollowUpDetails.length ? <div className="col-md-12 no-data-div"><h4>Sorry no Data Available</h4></div> :
                                        <div className="col-md-12 lab-investigation-card">
                                            <ul className="list-unstyled" style={{ height: '100%' }}>
                                                {PatientFollowUpDetails.map((item) =>
                                                    item.radiology.map((radioItem, id) => {
                                                       return (
                                                            <li className="media received" key={id}>
                                                                <div className="media-body">
                                                                    <div className="msg-box">
                                                                        <div style={{ maxWidth: "80%" }}>
                                                                            {
                                                                                radioItem.documents && <a
                                                                                    rel='noreferrer'
                                                                                    target="_blank"
                                                                                    href={radioItem.documents}
                                                                                    download
                                                                                    style={{ color: '#5156be' }}
                                                                                >
                                                                                    Click here to show document {id + 1}
                                                                                </a>
                                                                            }
                                                                            {
                                                                                radioItem.text && <p style={{overflowWrap: 'anywhere'}}>{radioItem.text}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                )
                                                }
                                            </ul>
                                        </div>}
                                </div>
                                <div className="col-md-6 mb-4 pr-0">
                                    <div className="col-md-14">
                                        <label style={{ fontWeight: '600' }} className="ml-auto">Lab Investigation</label>
                                    </div>
                                    {!PatientFollowUpDetails.length ? <div className="col-md-12 no-data-div"><h4>Sorry no Data Available</h4></div> :
                                        <div className="col-md-12 lab-investigation-card">
                                            <ul className="list-unstyled" style={{ height: '100%' }}>
                                                {PatientFollowUpDetails.map((item) =>
                                                    item.lab.map((labItem, id) => {
                                                        return (
                                                            <li className="media received" key={id}>
                                                                <div className="media-body">
                                                                    <div className="msg-box">
                                                                        <div style={{ maxWidth: "80%" }}>
                                                                            {
                                                                                labItem.documents && <a
                                                                                    rel='noreferrer'
                                                                                    target="_blank"
                                                                                    href={labItem.documents}
                                                                                    download
                                                                                    style={{ color: '#5156be' }}
                                                                                >
                                                                                    Click here to show document {id + 1}
                                                                                </a>
                                                                            }
                                                                            {
                                                                                labItem.text && <p style={{overflowWrap: 'anywhere'}}>{labItem.text}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                )
                                                }
                                            </ul>
                                        </div>}
                                </div>
                            </div>
                            
                        </div>
                        <div className="row ml-auto mb-5 mr-5">
                            <button style={{ marginLeft: 'auto', marginTop: '1.75rem' }} className="doctor-active-btn doctor-w-20 ml-auto" onClick={handleSubmit}>Submit </button>
                            <button style={{ marginLeft: 'auto', marginTop: '1.75rem' }} className="doctor-active-btn doctor-w-20 ml-auto" onClick={handlePreview}>Preview</button>
                            <button style={{ marginLeft: 'auto', marginTop: '1.75rem' }} className="doctor-active-btn doctor-w-20 ml-auto" onClick={() => {
                                setModalVisible(false);
                                document.body.style.overflowY = 'auto';
                            }}>Cancel </button>
                        </div>
                    </div>
                </div>
                {pdfPrevieModal && <div className="modal fade call-modal show pdf-preview-modal" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
                    <i style={{ fontSize: "28px", right: "139px", margin: "18px", position: "absolute", color: "white" }} class="fa fa-times" aria-hidden="true" onClick={(e) => setPdfPreviewModal(false)}></i>
                    <PdfPreviewInvoice patientDetails={patientDetails} infoMedication={infoMedication} dischargeData={dischargeData} payment={patientDetails?.services} payableAmount={payableAmount()} totalAmount={totalAmount()} />
                </div>}
            </div>
        </>
    )
}
export default DoctorDischargeModal
export const youtube = [
    'https://www.youtube.com/watch?v=NQ6hkU5Yl-c',
    'https://www.youtube.com/watch?v=EcJrktc3RqM',
    'https://www.youtube.com/watch?v=PEPr7eK-a80',
    'https://www.youtube.com/watch?v=58tK4tdoi40',
    'https://www.youtube.com/watch?v=BErTOBcV4kc',
    'https://www.youtube.com/watch?v=yeYgdMIz_WQ',
    'https://www.youtube.com/watch?v=J0VwfLjM9sM',
    'https://www.youtube.com/watch?v=kMpAcuq_QqM',
    'https://www.youtube.com/watch?v=-FQ6DthXCU0',
    'https://www.youtube.com/watch?v=U7QQPmWyc-o',
    'https://www.youtube.com/watch?v=BeV5CD7Zl8M',
    'https://www.youtube.com/watch?v=bzX-l_yIoN4',
    'https://www.youtube.com/watch?v=ZnupK7jjQlE',
    'https://www.youtube.com/watch?v=U1VolBgFjlU',
    'https://www.youtube.com/watch?v=Jzxn8EIeUPI',
    'https://www.youtube.com/watch?v=sN9H4gJ6dFs',
    'https://www.youtube.com/watch?v=f6T2xs6O7KA',
    'https://www.youtube.com/watch?v=jmmZ1NU3r2g',
    'https://www.youtube.com/watch?v=7BdiwRtA5EU',
    'https://www.youtube.com/watch?v=TeAONHzZk-U',

]

export const youtubeEvent = [
    {
        idx: 1,
        link: 'https://www.youtube.com/watch?v=wof7OYdbye0',
        time: '2021',
        event: 'Season 1 Doctors and Upcoming Season',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 2,
        link: 'https://www.youtube.com/watch?v=lhpMDWxdJzU',
        time: '2021',
        event: 'Episode 1. Open health forum',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 3,
        link: 'https://www.youtube.com/watch?v=qTkJm71VnnA',
        time: '2021',
        event: 'Episode 2. Open health forum || Psychiatrists Webinar || What is Stigma || Mental Health',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 4,
        link: 'https://www.youtube.com/watch?v=YvzDY4Zn_d0',
        time: '2021',
        event: 'Episode 3. Open health forum',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 5,
        link: 'https://www.youtube.com/watch?v=scNudrv7-4U',
        time: '2021',
        event: 'Episode 4: Open Health Forum || ABC of Stroke || Heart and Nerve Problems',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 6,
        link: 'https://www.youtube.com/watch?v=cCQJRcZ8DU8',
        time: '2021',
        event: 'Episode 5: Open Health Forum || Live Webinar About Heart Problems',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 7,
        link: 'https://www.youtube.com/watch?v=e8Opwn46Lts',
        time: '2021',
        event: 'Episode 6. Open health forum || Live Webinar About Kids Health',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 8,
        link: 'https://www.youtube.com/watch?v=TGna1cIQR0E',
        time: '2021',
        event: 'Episode 7. Open health forum || Symptoms and Scans',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 9,
        link: 'https://www.youtube.com/watch?v=1YcVJ4UU1Lw',
        time: '2021',
        event: 'Episode 8. Open health forum || Live Webinar About COVID and Related Conditions',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 10,
        link: 'https://www.youtube.com/watch?v=VjDybfg7_r0',
        time: '2021',
        event: 'Episode 9. Open health forum || Ayurveda Homeopathy Allopathy Different Choices',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    },
    {
        idx: 11,
        link: 'https://www.youtube.com/watch?v=DfARMpPevlg',
        time: '2021',
        event: 'Episode 10. Open health forum || Women`s Health',
        location: 'Telemedicine',
        host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
    }
]
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import styled from 'styled-components';
import { resetPassword } from '../../../utils/forms/schema.profileSettings';
import { updateProfile } from '../../../services/authService';
import { patientProfile } from '../../../redux/user/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { resetPasswordAction } from '../../../redux/auth/authActions';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    height: '100%',
    '& .MuiInputBase-root': {
        paddingRight: 0,
    },
    '& .MuiFormHelperText-root': {
        color: 'red'
    }
}))

function ResetPassword({ screenWidth }) {
    const [initialValues] = useState({
        password: "",
        newPassword: "",
        newPasswordCopy: ""
    })
    const belowMedium = useMediaQuery('(max-width: 900px)')
    const [showPassword, setShowPassword] = useState({
        password: false,
        newPassword: false,
        newPasswordCopy: false
    });
    const handleClickShowPassword = (key) => setShowPassword((show) => ({ ...show, [key]: !show[key] }));

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const dispatch = useDispatch()
    const { userData } = useSelector(state => ({
        userData: state.auth.userData
    }))

    const handleSubmitForm = async ({password, newPassword}) => {
        
        dispatch(resetPasswordAction({ password, newPassword }, userData.token));
        // const response = await updateProfile({ password, newPassword }, userData.token)
        // if (response.response_type === 'success') {
        //     toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        //     dispatch(patientProfile({ token: userData.token }))
        // } else {
        //     toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        // }
    }

    return (
        <StyledGridContainer container spacing={0} justifyContent={'center'} alignItems={"flex-start"} display={'flex'} minHeight={belowMedium ? 500 : 350}>
            <Formik
                initialValues={initialValues}
                validationSchema={resetPassword}
                onSubmit={handleSubmitForm}
                enableReinitialize
            >
                {({
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    handleSubmit
                }) => (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={4} justifyContent={'center'} alignItems={"flex-start"} className='form-container'>
                                <Grid item xs={12} md={8} style={{paddingTop: '16px', marginTop: '16px'}} display={'flex'} justifyContent={'center'}>
                                    <TextField
                                        name="password"
                                        fullWidth
                                        style={{maxWidth: '512px'}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type={showPassword.password ? 'text' : 'password'}
                                        label='Current Password'
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword.bind(this, 'password')}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {!showPassword.password ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        helperText={!!errors.password && touched.password && errors.password}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
                                    <TextField
                                        name="newPassword"
                                        fullWidth
                                        style={{maxWidth: '512px'}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type={showPassword.newPassword ? 'text' : 'password'}
                                        label='New password'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword.bind(this, 'newPassword')}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {!showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        helperText={!!errors.newPassword && touched.newPassword && errors.newPassword}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
                                    <TextField
                                        name="newPasswordCopy"
                                        fullWidth
                                        style={{maxWidth: '512px'}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type={showPassword.newPasswordCopy ? 'text' : 'password'}
                                        label='Confirm New Password'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword.bind(this, 'newPasswordCopy')}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {!showPassword.newPasswordCopy ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        helperText={!!errors.newPasswordCopy && touched.newPasswordCopy && errors.newPasswordCopy}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8} sx={{ paddingTop: '16px !important' }}>
                                    <Typography sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        fontFamily: 'Poppins',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: 16,
                                        lineHeight: '24px',
                                        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                                        '-webkit-background-clip': 'text',
                                        '-webkit-text-fill-color': 'transparent',
                                        backgroundClip: 'text',
                                        textFillColor: 'transparent'
                                    }}>
                                        {/* Forgotten Password? */}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'flex-start'} marginTop={belowMedium ? '-95px' :'-16px'} sx={{
                            // marginTop: 25, paddingBottom: 25
                        }}>
                            <Button
                                disabled={Object.keys(errors).length > 0}
                                type="submit"
                                onClick={handleSubmit}
                                sx={{
                                    color: '#FFF',
                                    padding: '8px 32px',
                                    background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                                    borderRadius: '8px',
                                    height: 40,
                                    minWidth: '201px'
                                }}>
                                <Typography sx={{
                                    height: 24,
                                    fontFamily: 'Poppins',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: '24px'
                                }}>
                                    Update Password
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                )}
            </Formik>
        </StyledGridContainer>
    )
}

export default React.memo(ResetPassword)
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledContainer = styled.div`
  .postion-relative {
    position: relative;
  }
  .remove-heath {
    position: absolute;
    right: 6px;
    top: -10px;
    background: #5156be;
    color: #fff;
    font-size: 20px;
    border: #5156be;
    border-radius: 50%;
    padding: 4px 12px;
  }

  a {
    cursor: pointer;
  }

  .health-btn-outline {
    border: 2px solid #09e5ab;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #09e5ab;
    text-transform: uppercase;
    font-weight: 500;
    background: transparent;
    margin-right: 20px;
    cursor: pointer;
}

.health-active-btn {
    border: 2px solid #09e5ab;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 20px;
    background: #1dbe72;
    color: #fff;
    cursor: pointer;
}

  .success-bmi {
    background: #b6e297;
    color: #000;
  }
  .under-bmi {
    background: #f8ccce;
    color: #000;
  }
  .over-bmi {
    background: #fcfbc2;
    color: #000;
  }
  .obesity-bmi {
    background: #dd8990;
    color: #000;
  }

  .color-white {
    color: #000 !important;
  }
  .color-white h5 {
    color: #000 !important;
  }
  .line-chart-buttons {
    color: #000;
    background: transparent;
    border: none;
  }
  .line-chart-buttons:hover,  .line-chart-buttons::after{
    background:#09dca4
  }
  .selected-group {
    background-color: #19ce67;
  }

  @media only screen and (max-width: 456px) {
    .hb-graph-div-button {
        display: flex !important;
    }
    .hb-health-records-heading {
        font-size: 12px;
    }
    .hb-graph-div-button {
        font-size: 10px;
    }
    .hb-overflow-x-scroll-symtoms {
        display: flex !important;
    }
    .hb-current-vitals-div {
        display: flex;
        border-right: none;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    .hb-current-vitals-chart-card-heading {
        display: flex !important;
    }
    .hb-current-vitals-chart-card-li {
        display: flex !important;
    }
    .hb-dateTimeDiv {
        display: flex !important;
    }
    .hb-dateTimeDiv-details {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
    .hb-health-records-div {
        padding: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px !important;
    }
    .hb-health-records-main-heading {
        font-size: 24px;
    }
    .hb-container-fluid-card {
        margin: 0px !important;
    }
    .hb-overflow-x-scroll {
        padding: 0px !important;
    }
    .hb-lipid-profile-div {
        padding: 0px !important;
    }
    .hb-health-chart-card {
        font-size: 14px;
    }
}

`;

export const StyledBackSection = styled.div`
  width: 100%;
  margin-top: 20px;
  
`;

export const StyledBackTo = styled(Link)`
    border: 2px solid #56be73;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #56be73;
    font-weight: 500;
    cursor: pointer;
    
    margin-left: 30px;
    margin-right: 30px;

    i {
        padding-right: 10px;
    }
`;

import React, {useContext, useEffect,useState} from 'react';
import { StyledContainer } from './styles';
import {
	Link
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../../../../store';
import moment from 'moment';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';
import { 
	getPatientInfo,
	getIpdHistoryOfPatient,
	getAppointmentHistory,
} from '../../../../../api/organization';
import {
	getDoctorSlotsListingAPI,
	getMedicalHistoryDataAPI
} from '../../../../../api/doctor';

import '../../../../SubAdmin/Panel/IpdWard/Patient/appointment.css';
import HealthRecordsChart from '../../../../SubAdmin/Components/HealthRecordsChart';
import PatientInfo from '../../../../SubAdmin/Components/PatientInfo';
import AppointmentCard from '../../../../../components/Appointments';
import MedicalHistoryComponent from '../../../../../components/medicalHistory';
import IpdList from '../../../../../components/IpdList';
import ECGchart from '../../../../../components/EcgGraph';
import Card from '../../../../../components/Card';
import { getUser } from '../../../../../utils';
import MedicalReports from '../../../../SubAdmin/Panel/ViewPatient/MedicalReports';

function DoctorViewPatient(props) {
	const [state, dispatch] = useContext(Context);
	const [id, setId] = React.useState(null);
	const [slots, setSlots] = React.useState([]);
	const [ipdHistory, setIpdHistory] = React.useState([]);
	const [appointmenthistory, setAppointmentHistory] = React.useState([]);
	const [isPatietnInIpd, setIsPatietnInIpd] = React.useState(true)
	const [permission, setPermission] = React.useState('');
	const [ecgWidth, setEcgWidth] = useState();
 
	const [patientInfo, SetPatientInfo] = React.useState({
		dob: '',
		gender: '',
		phoneNumber: '',
		name: '',
		address: '',
		profileImage: null,
		org_name: '',
		weight: '',
		_id: null
	});

	const [medicalHistory, setMedicalHistory] = React.useState({
		allergieData: [],
		healthConditionData: [],
		medicationData: [],
		surgeriesData: [],
		vaccinationData: []
	});

	useEffect(() => {
		(async function () {
			try {
				const data = await JSON.parse(localStorage.getItem('doctor'));
				if (data.permission.includes("IPD") && data.permission.includes("OPD")) {
					setPermission('ADMIN');
				} else if (data.permission.includes("IPD")) {
					setPermission('IPD');
				} else {
					setPermission('OPD');
				}
			} catch {
				loader(false);
			}
		}());
		
		if(props.match.params && props.match.params.id) { 
			loader(true);
			
			getPatientInfo(props.match.params.id).then((res) => {
				// loader(false);
				SetPatientInfo({
					dob: res.data.dob,
					email: res.data.email,
					gender: res.data.gender,
					phoneNumber: res.data.phoneNumber,
					name: res.data.name,
					address: res.data.address,
					profileImage: res.data.profileImage,
					org_name: res.data.org_name,
					_id: res.data._id,
					weight: res.data.weight,
					bloodGroup: res.data.bloodGroup
				})
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
			})
			getIpdHistoryOfPatient(props.match.params.id).then((res) => {
				const filterIpdHistory = res.data.filter((item)=> item.dischargeByDoctor !== false);
				setIpdHistory(filterIpdHistory)
				let currentIpd = []
				res.data.map((item)=>{
					if(!item.dischargeDate){
						currentIpd.push(item)
					}
				})
				if (res.data.length) {
					setIsPatietnInIpd(currentIpd.length ? true : false)
				}
				else {
					setIsPatietnInIpd(false)
				}
			}).catch((err) => {
				loader(false)
			})
			getAppointment(props.match.params.id);
			getMedicalHistoryDataAPI(props.match.params.id).then(res => {
				setMedicalHistory(res.data);
				loader(false);
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		}
	}, [])

	useEffect(()=>{
		const k = document.getElementById('ipd-ecg-chart-view-patient').offsetWidth;
		setEcgWidth(k-20)
	},[])

	const getAppointment = (id) => {
		getAppointmentHistory(id).then((res) => {
			const todayDate = moment().add(1, 'days').format('YYYY-MM-DD')
			const upcomingAppointment = res?.data?.filter((item) => moment(item?.slot?.start)?.utc()?.format('YYYY-MM-DD') < todayDate)
			setAppointmentHistory(upcomingAppointment)
		}).catch((err)=>{ })
	}

	const openIpdPatientView = (id) => {
		props.history.push(`/doctor/view-ipd-patient/${id}/${props.match.params.id}`)
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const handleClickAppointment = (data) => {
		props.history.push(`/doctor/view-appointment/${data?._id}/${data?.patientId?._id}`);
	}

	React.useEffect(() => {
		const id = getUser()._id;
		setId(id);
		getDoctorSlotsListingAPI(id).then(res => {
			var slotMap = {};
			for (let slot of res.data) {
				const date = moment(slot.start).utc().format('DD/MM/YYYY');
				if (moment(slot.start).utc().format() > moment().format()) {
					if (slotMap[date]) {
						slotMap[date].push(slot);
					}
					else {
						slotMap[date] = [slot];
					}
				}

			}
			var result = Object.keys(slotMap).map(slotDate => {
				return {
					date: slotDate,
					slots: slotMap[slotDate]
				}
			});
			setSlots(result);
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}, []);

	const handleBookAppointment = () => {
		if (slots.length === 0) {
			toast.error('Please create slot first!')
			return dispatch({
				type: SET_ERROR,
				payload: 'Please create slot first!'
			});
		}
		props.history.push(`/doctor/book-new-appointment-form/${props.match.params.id}`);
	}

		
	return (
        <div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-12 row">
                                <Link
                                    to={'/doctor/schedule'}
                                    style={{
                                        border: '1px solid #5651be',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        marginBottom: '20px',
                                        color: '#5651be',
                                        fontSize: '17px',
                                        fontWeight: '500'
                                    }}
                                >
                                    <i className="fa fa-arrow-left pr-2" />
                                    Back To Dashboard
                                </Link>
								{!isPatietnInIpd && (permission=== 'ADMIN' || permission === 'OPD') ?
									<div className='ml-auto d-flex'>
										<div
											style={{
												border: '1px solid #5156be',
												borderRadius: '5px',
												padding: '10px',
												marginBottom: '20px',
												color: '#FFF',
												backgroundColor: "#5156be",
												fontSize: '17px',
												fontWeight: '400',
												marginRight: "20px",
												cursor: 'pointer',
											}}
											onClick={() => handleBookAppointment()}
										>
											Book an appointment
										</div>
									</div> : null
								}
                            </div>

                            <h3 className="mb-4 mt-4">Patient details </h3>
                            <StyledContainer>
                                <div className="content">
                                    <div className="container-fluid pr-0">
                                        <div className="container-fluid-card row d-flex col-md-12">
                                            <div className='col-md-9 hb-col-health-records-chart-mobile-screen'>
												<HealthRecordsChart
													showAdd={false}
													patientId={props.match.params.id}
													showLipidProfile={true}
												/>
												<div className="p-3 mobile-screen-card-main-hb">
													<h2 className = "cards-main-heading mb-3">Medical History</h2>
													<MedicalHistoryComponent medicalHistory={medicalHistory} />
												</div>
                                            </div>
                                            <div className='col-md' style={{ padding: "0px" }}>
                                                <PatientInfo patientInfo={patientInfo} />
                                                {/* <ServiceList data={patientDetails.services} /> */}
												<AppointmentCard
													heading='Previous appointments'
													data={appointmenthistory}
													keyItem="Previous"
													handleClickAppointment={handleClickAppointment}
												/>
												<IpdList data={ipdHistory} openIpdPatientView={openIpdPatientView}/>
												<Card className='p-2 ecg-min-height' id="ipd-ecg-chart-view-patient">
													{ecgWidth && patientInfo._id && <ECGchart
														width={ecgWidth}
														id={patientInfo._id}
													/>}
												</Card>
												<MedicalReports patiendId={props.match.params.id} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </StyledContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DoctorViewPatient;
import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import CardComponent from '../../components/Card';

function RemoteLand() {
	return (
		<div>

			<Breadcrumb
				backRoute={[{id: 1, route: 'Home'}]}
				currentRoute='Tele Monitoring'
				title='Tele Monitoring' />

			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-lg-8 col-xs-12">
							<CardComponent  className="p-4 mb-4">
								<div className="widget about-widget">
									<h4>Tele Monitoring In Your Virtual Clinical Practice / Clinical Institute</h4>
									<p>Ensure that your patients get the best health care. Custom care plans that monitor the patients can reduce the risk of health complications between visits. 
									</p>
									<br />
									<h4>How it Works?</h4>
									<div className="experience-box">
										<ul className="">
											<li>Create Suitable Care Plans</li>
											<li>Add Patients to your plan</li>
											<li>Collect payments for care plan</li>
											<li>Monitor subscribed patients regularly</li>
											<li>Analyze their progress on your care plans</li>
										</ul>
									</div>

									<p>Choose from an extensive list of 50+ health trackers to design specialized care plans for your patients because you understand their health best. Also, include monitoring frequency that shows how often patients need to update their parameters. It also has how frequently your team will review these health readings. You can optionally set up a monitoring team to track patient health.
									</p>
									<br />

									<p>Add subscription costs for plans that help the patients to know about your care plans during clinic visits or online consultations. Once added, your patients will be able to share their health readings with you as directed by your plans and have their health reviewed periodically to prevent any complications.
									</p>
									<br />

									<p>Define subscription costs for your health plans and collect payments online from patients subscribed to your plans. Your remote monitoring plan allows you to provide patients with personalized care on your term while also growing practice revenue. Patients can also renew their subscriptions online.
									</p>
									<br />

									<p>Track your patients' health with your remote monitoring plans, and they can share their health readings through health trackers defined in the subscribed plans. Review their health parameters and share your feedback. Patients can also ask your monitoring team questions in real-time to receive immediate intervention. Review long-term patient progress on your care plans. Patient health data can be visualized graphically to allow both your patients and the monitoring team to see the results.
									</p>
									<br />

								</div>

							</CardComponent>

						</div>

						<div className="col-lg-4 features-img mt-5 pt-5 ">
							<img src="/assets/img/land5.png" className="img-fluid" alt="Feature" />
						</div>

					</div>
				</div>
			</div>

		</div>
	)
}

export default RemoteLand

import styled from 'styled-components';

export const StyledContainer = styled.div`
  
`;


export const StyledHeading = styled.h2``;

export const StyledPatient = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledPatientSearch = styled.div`
	width: 70%;
`;

export const StyledAddPatient = styled.button`
	cursor: pointer;
	border: none;
	color: #fff;
	text-decoration: none;
	transition: background .5s ease;
	-moz-transition: background .5s ease;
	-webkit-transition: background .5s ease;
	-o-transition: background .5s ease;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-align: center;
	background: #5156be;
	position: relative;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 600;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	line-height: 1;
	padding: 12px 22px;
	width: 27%;
	margin-left: 3%;
	height: 42px;
	margin-top : 0px !important;
	margin-bottom: 0px;
	width: 100%;

	a {
		color: #fff;
	}
`;

import styled from 'styled-components';

export const StyledContainer = styled.div`
  .PhoneInput .PhoneInputInput {
    border: 1px solid #dcdcdc;
    color: #333;
    font-size: 15px;
    min-height: 46px;
    padding: 6px 15px;
    border-radius: 6px;
  }
`;

export const StyledHeader = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledBackTo = styled.div`
	border: 2px solid #5156be;
	border-radius: 4px;
	padding: 10px 15px !important;
	text-align: center;
	font-size: 15px;
	color: #5156be;
	font-weight: 500;
	cursor: pointer;

	a {
		color: #5156be;
	}

	i {
		padding-right: 10px;
	}
`;

export const StyledTitle = styled.h2`
	margin-left: auto;
`;

export const StyledAddEditDoctor = styled.button`
	margin-left: auto;
	cursor: pointer;
	border: none;
	color: #fff;
	text-decoration: none;
	transition: background .5s ease;
	-moz-transition: background .5s ease;
	-webkit-transition: background .5s ease;
	-o-transition: background .5s ease;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-align: center;
	background: #5156be;
	position: relative;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 600;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	line-height: 1;
	padding: 12px 30px;
`;

import { Grid, Tabs } from '@mui/material';
import React from "react";
import HealthParameters from '../ui/HealthParameters';
import { LipidProfile } from '../ui/LipidProfile';

export default function Lists({ list, className, duration, setDuration, navigation, data, date, setDate }) {
  return (
    <Tabs
      orientation="horizontal"
      variant="scrollable"
      value={0}
      indicatorColor={"#fff"}
      scrollButtons={false}
      allowScrollButtonsMobile
      sx={{
        width: '100%',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        overflowY: 'auto',
        '& .MuiTabs-flexContainer': {
          '& .MuiButtonBase-root': {
            opacity: 1
          }
        }
      }}
    >
    
     {navigation === "healthParameters" ? <HealthParameters className={className} list={list} duration={duration} setDuration={setDuration} date={date} setDate={setDate} />
     :
     <LipidProfile list={list} data={data} className={className} />}

    </Tabs>
  );
}


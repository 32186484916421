import { object, string, number } from "yup";
import * as Yup from "yup";

export const addPatientSchema = object({
  name: string().required("Name is required"),
  email: string()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required"),
  gender: string().required("Please select gender"),
  bloodGroup: string().required("Blood group is required"),
  phoneNumber: string().required("Contact number is required"),
  address: string().required("Address is required"),
  permission: string().required("Please select permission"),
});

export const addPatientIdSchema = object({
  patientId: string().required("Patient Id is required"),
});

export const admissionSchema = object({
  admission_date: string().required("name is required"),
  patientUniqueId: string().required('Please enter unique ID'),
  doctorId: string().required('Please select doctor'),
  roomNumber: number().required("Room number required"),
  roomType: string().required("Room type is required is required"),
  reason: string().required("Admission reason is required"),
  referernceName: string(),
  referernceContactNo: string(),
  mlcNo: string().max(10),
  firNo: string()
    .max(15, "Maximum length is 15")
    .min(10, "Minimum length is 10"),
});

export const medicalDetailsSchema = object({
  diagonosis: string()
    .min(3, "diagonosis cannot be less than 3")
    .max(100, "diagonosis should contain less than 100 workds"),
  BPSystolic: number()
    .typeError("Systolic level must be a number")
    .min(0, "Systolic level must be greater than or equal to 0")
    .max(250, "Systolic level must be less than or equal to 250"),
  BPDiastolic: number()
    .typeError("Diastolic level must be a number")
    .min(0, "Diastolic level must be greater than or equal to 0")
    .max(130, "Diastolic level must be less than or equal to 130"),
  spo2: number()
    .typeError("Oxygen saturation level must be a number")
    .min(0, "Oxygen saturation level must be greater than or equal to 0")
    .max(100, "Oxygen saturation level must be less than or equal to 100"),
  temp: number()
    .typeError("Body Temperature level must be a number")
    .max(
      107.6,
      "Temperature level in fahrenheit must be less than or equal to 107.6"
    )
    .min(
      96,
      "Temperature level in fahrenheit must be greater than or equal to 96"
    ),
  rr: number()
    .typeError("Breath must be a number")
    .min(0, "Breath must be greater than or equal to 0")
    .max(25, "Breath must be less than or equal to 25"),
  pulse: number()
    .typeError("pulse must be a number")
    .min(60, "pulse must be greater than or equal to 60")
    .max(120, "pulse must be less than or equal to 120"),
  illnessSumary: string(),
  cheifComplaints: Yup.array().of(
    Yup.object().shape({
      complaints: string(),
      duration: string(),
    })
  ),
  generalAppearce: Yup.array().of(
    Yup.object().shape({
      general: string(),
      comments: string(),
    })
  ),
});

export const medHistorySchema = object({
  signification: string(),
  familyHistory: string(),
  historyOfAlcohalism: string()
});

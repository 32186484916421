import React, {useEffect, useContext, useState} from 'react';


import PhoneInput from "react-phone-number-input";

import InputField from '../../../components/InputField';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	postRegisterAPI
} from '../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import { globalValidator, saveUser } from "../../../utils";

import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledLoginButton,
	StyledRightBottom,
	StyledBottomContent,
	StyledBottomLink,
	StyledForgotPassword,
	StyledForgotPasswordLink
} from './styles';

function RegisterPatient(props) {


	const [state, dispatch] = useContext(Context);

	const [orgUser, SetOrgUser] = useState({email: '', phoneNumber: '', password: '', name: ''});

	const login = (e) => {
		e.preventDefault();
		const { email, password, name } = orgUser;
		if(
			(orgUser.email.length === 0) 
		) {
			if(orgUser.phoneNumber === undefined || orgUser.phoneNumber.length === 0) {
				toast.error('Email or phone number is required!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Email or phone number is required!'
				});
			}
		}
		if(
			(orgUser.phoneNumber === undefined || orgUser.phoneNumber.length === 0) 
		) {
			if(orgUser.email.length === 0) {
				toast.error('Email or phone number is required!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Email or phone number is required!'
				});
			}
		}

    const keys = [
			{ name: "name", title: "Name", validateKey: "required" },
      // { name: "email", title: "Email", validateKey: "email" },
      { name: "password", title: "Password", validateKey: "password" }
    ];
		let valid = globalValidator({ email, password, name }, keys);

		if(valid === true) {
			loader(true);
			postRegisterAPI(orgUser).then(res => {
				loader(false);
				toast.success(res?.message || 'Please verfiy your email and then you login in!')
				dispatch({
					type: SET_ERROR,
					payload: res?.message || 'Please verfiy your email and then you login in!'
				});
				props.history.push('/');
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const signinRe = () => {
		props.history.push('/login');
	}

	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
              <StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Login</StyledLeftHeading>
										<StyledLeftSubHeading>
											To keep connected with us please login with your personal info.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="http://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>

							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">

											<div className="em-notes">
												<p>Signup with Email or Contact number !</p>
											</div>

											<form className="form-border info-widget">
												<div className="form-group card-label">
													<label>Name</label>
													<InputField value={orgUser.name} onChange={e => SetOrgUser((user) => ({...user, name: e.target.value}))} />
												</div>

												<div className="form-group card-label">
													<label>Email</label>
													<InputField value={orgUser.email} onChange={e => SetOrgUser((user) => ({...user, email: e.target.value}))} type="email" />
												</div>

												<div className="form-group card-label">
													<label>Contact number</label>
													<PhoneInput
														placeholder="Enter phone number"
														international
														defaultCountry="IN"
														value={orgUser.phoneNumber}
														onChange={e => SetOrgUser((user) => ({...user, phoneNumber: e}))}
													/>
												</div>


												<div className="form-group card-label">
													<label>Password</label>
													<InputField value={orgUser.password} password={true}  type="password" onChange={e => SetOrgUser((user) => ({...user, password: e.target.value}))} />
												</div>

												<StyledLoginButton onClick={(e) => login(e)}>
													Sign Up Now
												</StyledLoginButton>
											</form>

											<StyledRightBottom>
												<StyledBottomContent onClick={() => signinRe()}>
													I have an account with Emed4u? - 
													<StyledBottomLink>
														Sign In Now
													</StyledBottomLink> 
												</StyledBottomContent>
											</StyledRightBottom>											
										</div>
									
									</div>

								</div>
							
								
							</StyledRightPanel>

            </div>
          </div>
        </div>
		  </div>
	  </section>  
	)
}

export default RegisterPatient

import React, { useEffect } from "react";
import Tables from "../../controls/Tables";
import { serviceList } from "../../../constant/staticLists";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { useDispatch, useSelector } from "react-redux";
import { getAdmitedPatientServiceAction } from "../../../redux/ipd/ipdAction";

export const serviceListData = [
  { service: "HNGJUOILMBB", date_time: "12-Jan-2024, 10:00 AM", price: "100" },
  { service: "HNGJUOILMBB", date_time: "13-Jan-2024, 10:00 AM", price: "100" },
  { service: "HNGJUOILMBB", date_time: "18-Jan-2024, 10:00 AM", price: "100" },
  { service: "HNGJUOILMBB", date_time: "19-Jan-2024, 10:00 AM", price: "100" },
  { service: "HNGJUOILMBB", date_time: "20-Jan-2024, 10:00 AM", price: "100" },
];

function ServiceListing({ selectedListItem }) {
  const dispatch = useDispatch()
  const classes = commonMuiStyles();

  const { admittedPatientService, userData } = useSelector((state) => ({
    admittedPatientService: state.ipd.admittedPatientService,
    userData: state.auth.userData
  }))

  useEffect(() => {
    // dispatch(getAdmitedPatientServiceAction({admitPatientId: selectedListItem._id , token: userData.token }))
  }, [])
  
  return (
    <>
      <div className={classes.scrollVertically} style={{ height: "180px" }}>
        <Tables
          fieldsOrder={serviceList}
          data={admittedPatientService}
          navigate="scrollable"
        />
      </div>
    </>
  );
}

export default ServiceListing;

import { CardButton, CardDescription, CardTitle, ConductStudyModeTitle, HandleCard, HoverCard, HoverCardWrapper, HoverCardWrapperTest, RightArrowIcon, TagsCount, TextWithCountWrapper } from "./styles"
import { ItemCard, Date, DescriptionHeader, Description, CommonFieldStyle, FieldValue, ItemCardButton } from "./ItemCard.jsx"
// import { useHistory } from "react-router-dom"

const title1 = 'Emed4U Medical'
const title2 = 'Research'
const title = 'Emed4U Medical Research'
const description = 'In Emed4u we  also promote medical studies with our medical assisted products. Medical and scientific researchers bring the best of their studies when they are equipped with the right devices. Explore how Withings contributes to research. '

export const ConductStudyCard = ({ setQuestion }) => {
	return (
		<HoverCard>
			<CardTitle>{title1}</CardTitle>
			<CardTitle>{title2}</CardTitle>
			<CardDescription>{description}</CardDescription>
			<CardButton onClick={() => setQuestion(false)}>Conduct a study <RightArrowIcon /></CardButton>
		</HoverCard>
	)
}


export const ConductStudyCardMobile = ({setQuestion}) => {
	return (
		<HoverCardWrapperTest>
			<HandleCard>
				<CardTitle>{title}</CardTitle>
				<CardDescription>{description}</CardDescription>
				<CardButton onClick={() => setQuestion(false)}>Conduct a study <RightArrowIcon /></CardButton>
			</HandleCard>
		</HoverCardWrapperTest>
	)
}

export const HoveredCard = ({isMobile, setQuestion}) => {
	return isMobile ? <ConductStudyCard setQuestion={setQuestion}/> :  <ConductStudyCardMobile setQuestion={setQuestion}/>;
}

const description1 = 'Evaluation of Web-Based and In-Person Methods to Recruit Adults With Type 1 Diabetes for a Mobile Exercise Intervention: Prospective Observational Study'
export const Items = () => {
	return (
		<ItemCard>
			<Date>2021</Date>
			<DescriptionHeader>HIV</DescriptionHeader>
			<Description>{description1}</Description>
			<CommonFieldStyle className={'style24'}>Yale University School of Medicine</CommonFieldStyle>
			<CommonFieldStyle className={'style24'}>Areas of Interest</CommonFieldStyle>
			<FieldValue>HIV</FieldValue>
			<CommonFieldStyle>Biomarker</CommonFieldStyle>
			<FieldValue>Weight</FieldValue>
			<CommonFieldStyle>Publication Type</CommonFieldStyle>
			<FieldValue>Peer Review</FieldValue>
			<ItemCardButton>Read now <RightArrowIcon className='read-now'/></ItemCardButton>
		</ItemCard>
	)
}

export const TitleWithCount = ({title, selectedTags, checkSelected}) => {
	const count = selectedTags?.length || null;
	return (
		<TextWithCountWrapper className='single-line'>
			{(count && checkSelected) && <TagsCount>{count}</TagsCount>}
			<ConductStudyModeTitle className={(checkSelected) && 'colored'}> {title} </ConductStudyModeTitle>
				{/* 
				// For Future Reference
				{(count && checkSelected) && <TagsCountSingleLine className='single-line'>{count}</TagsCountSingleLine>} 
				*/}  
		</TextWithCountWrapper>
	)
}
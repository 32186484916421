import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as mainService from '../../services/mainService';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(135deg, rgba(9, 126, 183, 1), rgba(42, 171, 108, 1))', // Linear gradient background
  },
  contentContainer: {
    padding: '24px',
    width: '100%',
  },
  rowContainer: {
    display: 'flex',
    padding: '8px 0', // Add padding between rows for spacing
  },
  label: {
    fontWeight: 'bold',
  },
  value: {
    paddingLeft: '24px', // Align values to start from the middle of the page
  },
});

const SubscriptionPage = props => {
  const classes = useStyles();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));
  console.log("props",props)

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        console.log('Fetching subscription data');
        const payload = {
          token: userData.token,
        };
        const Res1 = await mainService.getSubscriptionOrganizationApi(payload);
        console.log('Response', Res1);
        setSubscriptionData(Res1.response.data);
      } catch (error) {
        console.error('Error fetching subscription data:', error);
      } finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };

    fetchSubscriptionData();
  }, [userData.token]);

  if (loading) {
    return (
      <Container maxWidth="md" className={classes.container}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper elevation={3} className={classes.contentContainer}>
        <Typography variant="h6" gutterBottom>
          Customer Details
        </Typography>

        <Grid container className={classes.rowContainer}>
          <Grid item xs={6}>
            <Typography className={classes.label}>Customer Name:</Typography>
          </Grid>
          <Grid item xs={6} className={classes.value}>
            <Typography>{userData.name}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.rowContainer}>
          <Grid item xs={6}>
            <Typography className={classes.label}>Email:</Typography>
          </Grid>
          <Grid item xs={6} className={classes.value}>
            <Typography>{userData.email}</Typography>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" gutterBottom>
            Configurations
          </Typography>

          <Grid container className={classes.rowContainer}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Payment Status:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.value}>
              <Typography>{subscriptionData.status}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.rowContainer}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Billing Info:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.value}>
              <Typography>{userData.address}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.rowContainer}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Payment Method:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.value}>
              <Typography>{subscriptionData.payment_method}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.rowContainer}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Auto Collection:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.value}>
              <Typography>On</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.rowContainer}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Exempt from tax:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.value}>
              <Typography>No</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" gutterBottom>
            Subscription Info
          </Typography>

          <Grid container className={classes.rowContainer}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Subscription Id:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.value}>
              <Typography>{subscriptionData?.id}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.rowContainer}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Billing On:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.value}>
              <Typography>
                {new Date(subscriptionData.start_at * 1000).toUTCString()}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.rowContainer}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Status:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.value}>
              <Typography>
                {subscriptionData.status ? 'Active' : 'Inactive'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.rowContainer}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Next Billing On:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.value}>
              <Typography>
                {new Date(subscriptionData.expire_by * 1000).toUTCString()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default SubscriptionPage;
import { Grid } from '@mui/material';
import React from 'react';
import { commonMuiStyles } from '../../assets/styles/CommonMUIStyles';
import colors from '../../constant/colors';
import { IPDdashoboardUseStyles } from '../../assets/styles/IPDDashboard';
import { renderGender } from '../../utils/GenericFunctions';

function InformationPair({data, fieldOrder, size}){
    const classes = commonMuiStyles();
    const dashboardClasses= IPDdashoboardUseStyles();
    return(<>
    {Object.entries(fieldOrder).map(([ key, label])=>
    <Grid container spacing={0}  >
        <Grid item xs={size === 'full' ? 5 : 4} className={dashboardClasses.typographyText}  sx={{color: colors.black }} >{label}</Grid>
        <Grid item xs={size === 'full' ? 7 : 8} className={`${classes.scrollHorizontally} ${dashboardClasses.typographyText}`} sx={{fontWeight: '400 !important', color: colors.grey1}}>{key === renderGender(data[key]) ? 'ABS' : data[key]}</Grid>
    </Grid>)}
    </>)

// classes.fontText14 
}

export default InformationPair;
import React from 'react'
import { BackgroundImage, CardBlock, CardContainer, Image, ImageContainer, PlayIcon, RightArrowIcon, Text, TrailButton } from './styles';
import './modal.css';
import { HMSCard, HMSCardOne, HMSCardTwo, HMSCardThree, HMSCardFour, HMSCardFive, HMSCardSix, HMSCardSeven } from './utility';
import { Link } from 'react-router-dom';

const HMS = () => {

    const data = [
        {
            id: 1,
            title: 'Use it anywhere on any device',
            text: 'Access to patient data and records anywhere on your mobile phone, tablet, or desktop PC will help in efficient patient management and treatment',
        },

        {
            id: 2,
            title: 'Highly Scalable',
            text: 'We serve and offer solutions to hospitals of all sizes. Our HMS will easily support your business when it scales up.',
        },
        {
            id: 3,
            title: 'Ease of Use:',
            text: 'Our HMS is built with customer needs in mind. It is visually appealing, interactive, and user-friendly.',
        },
        {
            id: 4,
            title: 'Experienced Support:',
            text: 'Our dedicated and skilled team will always ensure real-time issue management and resolution.',
        },
        {
            id: 5,
            title: 'Secure Backup:',
            text: 'Extensive protection from any unauthorized access or security breaches.',
        },
        {
            id: 6,
            title: 'Cost-effective:',
            text: 'We serve and offer solutions to hospitals of all sizes. Our HMS will easily support your business when it scales up.',
        },


    ]
    return <div style={{
    }} >
        <div style={{ position: 'relative' }}>
            <PlayIcon>
                <Image src="/assets/img/play-circle.png" />
            </PlayIcon>
            <BackgroundImage src="/assets/img/HMSImage1.png" />

        </div>
        <CardBlock>
            <BackgroundImage src="/assets/img/hms22.png" />
            <CardContainer><HMSCard /></CardContainer>
        </CardBlock>
        <div>
        {/* className="EMED4U-Benefits-title" */}
            <Text>How EMED4U Benefits Clinics & Hospitals ?</Text>
        </div>
        <div className="cards-block">
            <BackgroundImage src="/assets/img/hms2.png" />
            <HMSCardOne />
        </div>
        <div className="cards-block">
            <img src="/assets/img/hms3.png" className="hms-image" />
            <HMSCardTwo />
        </div>
        <div className="cards-block">
            <img src="/assets/img/hms4.png" className="hms-image" />
            <HMSCardThree />
        </div>
        <div className="cards-block">
            <img src="/assets/img/hms5.png" className="hms-image" />
            <HMSCardFour />
        </div>
        <div className="cards-block">
            <img src="/assets/img/hms6.png" className="hms-image" />
            <HMSCardFive />
        </div>
        <div className="cards-block">
            <img src="/assets/img/hms7.png" className="hms-image" />
            <HMSCardSix />
        </div>
        <div className="cards-block">
            <img src="/assets/img/hms8.png" className="hms-image" />
            <HMSCardSeven />
        </div>
        <div className="we-offer-main-block">
            <h3 className="hms-we-offer-title">We Offer</h3>
            <div className="hms-inner-block">
                {data.map((item) => {
                    return (
                        <div className="hms-check-block">
                            {/* <div> */}
                            <img src="/assets/img/hmscheck.png" className="hms-image-offer" />
                            {/* </div> */}
                            <div className="hms-inner-block-other">
                                <p className="hms-inner-title-one">{item.title}</p>
                                <p className="hms-inner-title-two">{item.text}</p>
                            </div>
                        </div>
                    )

                }
                )
                }

            </div>
            <div className="hms-button-main-screen">
                <Link style={{ whiteSpace: 'pre' }} to={'/hmspackages'}>
                    <TrailButton>
                        START FREE TRIAL NOW
                        <RightArrowIcon />
                    </TrailButton>
                </Link>

            </div>
        </div>



    </div>
}

export default HMS;
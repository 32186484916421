import React, { useContext, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getAllPatient, getDoctorSlotsListingAPI, getWardRoomCurrentPatients
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import CardComponent from '../../../../components/Card';
import PatientCard from '../../components/PatientCard';

import moment from 'moment';
import Search from '../../../../components/Search';
import InsideMenuBar from '../../../../components/InsideMenus';
import { getUser } from '../../../../utils';

const options = [
	{ id: 0, label: 'Id', value: '_id' },
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 2, label: 'Email', value: 'email' },
	{ id: 4, label: 'Contact number', value: 'phoneNumber' }
]

function DoctorPatients(props) {

	const [state, dispatch] = useContext(Context);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('_id');
	const [patients, setPatients] = React.useState([]);
	const [slots, setSlots] = React.useState([]);
	const path = window.location.pathname

	const setWardRoomCurrentPatients = (search) => {
		// const payload = {
        //     [selectValue]: search
        // }
		const payload = search || ''
		loader(true);
		getWardRoomCurrentPatients(payload).then(res => {
			setPatients(res.data);
			loader(false)
		}).catch(err => {
			loader(false)
			setPatients([]);
			toast.error(err?.response?.data?.error);
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const setAllPatients = (search) => {
		const payload = search || ''
		loader(true);
		getAllPatient(payload).then(res => {
			setPatients(res.data);
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			setPatients([]);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const getSearchPatient = (search) => {
		switch (path) {
			case '/doctor/patient-listing':
				return setAllPatients(search);
			case '/doctor/ipd-patient-listing':
				return setWardRoomCurrentPatients(search);
			default: return null;
		}
	}

	const renderTitle = () => {
		switch (path) {
			case '/doctor/patient-listing':
				return "Patients database";
			case '/doctor/ipd-patient-listing':
				return "IPD patients database";
			default: return null;
		}
	}

	useEffect(() => {
		switch (path) {
			case '/doctor/patient-listing':
				return setAllPatients();
			case '/doctor/ipd-patient-listing':
				return setWardRoomCurrentPatients();
			default: return null
		}
	}, []);

	

	const searchPatient = () => {
		loader(true);
		const payload = {
			[selectValue]: search
		}
		getSearchPatient(search.length > 0 ? payload : '');
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const fetchDocterSlots = () => {
		const id = getUser()._id;
		getDoctorSlotsListingAPI(id).then(res => {
			var slotMap = {};
			for (let slot of res.data) {
				const date = moment(slot.start).utc().format('DD/MM/YYYY');
				if (moment(slot.start).utc().format() > moment().format()) {
					if (slotMap[date]) {
						slotMap[date].push(slot);
					}
					else {
						slotMap[date] = [slot];
					}
				}

			}
			var result = Object.keys(slotMap).map(slotDate => {
				return {
					date: slotDate,
					slots: slotMap[slotDate]
				}
			});
			setSlots(result);
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	React.useEffect(() => {
		fetchDocterSlots()
	}, []);

	React.useEffect(() => {
        if(search === '') {
            getSearchPatient();
        }
    }, [search])

	React.useEffect(() => {
		getSearchPatient();
	}, [selectValue]);

	const addPatient = () => {
		fetchDocterSlots()
		if (slots.length === 0) {
			toast.error('Please create slot first!')
			return dispatch({
				type: SET_ERROR,
				payload: 'Please create slot first!'
			});
		}
		props.history.push('/doctor/add-patient-form');
	}
	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'doctor'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
							
							<div className="doctor-panel-header">
									<h3 className="mb-1">{renderTitle()}</h3>

									{/* <button className="doctor-active-btn ml-auto" onClick={() => addPatient()}>Add patient </button> */}
								
								<hr />
								{/* <h4 className="mb-3">Filters</h4> */}
								<div className="doctor-panel-filters row col-md-12">
									<Search
										selectValue={selectValue} 
										setSelectValue={setSelectValue} 
										search={search} 
										setSearch={setSearch} 
										searchPatient={searchPatient} 
										name={""}
										options={options}
									/>
									
									{ path == '/doctor/ipd-patient-listing' ? null : (
										<div className="col-md-2 mt-5 mb-0 no-padding form-group doctor-active-btn-div">
											<button className="doctor-active-btn m-0" onClick={() => addPatient()}>Add a patient</button>
										</div> 
									)}
									
								</div>
							
								{/* doctor-panel-filters */}

									

									{/* <div className="col-md-5 no-padding form-group ">
										<label>Search by Patient Id</label>
										<InputField  />
									</div> */}

									{/* <div className="col-md-5 no-padding form-group ">
										<label>Search by Contact number or Email id</label>
										<InputField  />
									</div> */}

									{/* <div className="col-md-2 no-padding form-group ">
										<label>Status</label>
										<SelectField options={[{id: 0, label: 'Status'}, {id: 1, label: 'Active', value: '0'}]} />
									</div> */}

									{/* <div className="col-md-2 no-padding form-group ">
										<button className="doctor-active-btn"><i className="fa fa-search" /> </button>
									</div>
									 */}

							</div>

							<div className="row row-grid">
								{
									patients?.length ? patients.map(item => {
										return (
											<div className="col-md-6 col-lg-4 col-xl-4" key={item.patient._id}>
												<PatientCard 
													patientInfo={item}
													profileImage={item.patient.profileImage}
													name={item.patient.name}
													_id={item.patient._id}
													address={item.patient.address}
													phoneNumber={item.patient.phoneNumber}
													gender={item.patient.gender}
													bloodGroup={item.patient.bloodGroup}
													status={item.patient.status}
													dob={item.patient.dob ? moment(item.patient.dob).format('MM-DD-YYYY') : '-'}
													totalAppointment={item.patient.count}
												/>
											</div>
										)
									}) : (
										<div style={{width: '100%'}}>
											<h5 style={{ textAlign: "center", margin: '40px 0px 30px' }}>
												Sorry! There are no results to display
											</h5>
										</div>
									)
								}
								
							</div>
						
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DoctorPatients
